import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useBreakpoint, useModalState, useOutsideClickListener, usePermissions, useTranslation } from '@hooks';
import { Button, Icon, Modal } from '@components';

import { LanguageSelect, SidebarItem, SidebarSettings, WorkspacePopover } from './components';
import {
  ArrowButton,
  Container,
  Header,
  Logo,
  Menu,
  Name,
  HeaderInner,
  Avatar,
  LogOutRow,
  IconContainer,
  InnerContainer,
  Counter,
  Circle,
  SidebarButton,
  NotificationsContainer,
  HintContainer, DocumentationContainer,
  LinkIcon,
} from './styled';
import { Colors } from '@res/theme';
import { ItemInfo } from './components/SidebarItem/styled'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarOpenedSelector, userSelector, hintModeSelector, onboardingSelector } from '@store/selectors';
import { changeLanguage, getUser, logout, setSidebarOpened } from '@store/actions/creators';
import { useLocation } from 'react-router';
import { useNavigate } from '@hooks';
import { Paths } from '@constants';

import './styles.css';
import { checkIsAllOnboardsFinished, composeHintAsk } from './composeHintAsk';
import {
  resetOnboard,
  sentOnboardParams,
  setHintMode,
  skipOnboard,
  updateOnboarding
} from '@store/actions/creators';
import { OnboardInfoHint } from '@components/lib/OnboardInfoHint';
import { config, useSpring, useTransition } from '@react-spring/web';
import { resolveDemoWorkspaceIcon } from '@components/layout/Sidebar/components/Popover/WorkspacePopover';
import { RioService } from '../../../services';
import { testId } from '@utils';

const settingsPages = [
  {
    name: 'workspaces',
    path: Paths.WORKSPACES,
    // permission: UserPermissions.UPDATE_WORKSPACE,
  },
  {
    name: 'users',
    path: Paths.USERS_SETTINGS,
    // permission: UserPermissions.REVIEW_USERS,
  },
  {
    name: 'roles',
    path: Paths.ROLES_SETTINGS,
    // permission: UserPermissions.REVIEW_ROLES,
  },
  {
    name: 'integrations',
    path: Paths.INTEGRATIONS,
  },
  {
    name: 'account',
    path: `${Paths.ACCOUNT_SETTINGS}/account`,
  }
];

const transitionConfig = {
  from: { opacity: 1, translateX: 0 },
  enter: { opacity: 1, translateX: 1 },
};

const tid = testId('sidebar');

const Sidebar = ({ routes, notificationsOpened, notifications, toggleNotifications }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { p, t, i18n } = useTranslation('sidebar');
  const cancelOnboardingModal = useModalState();
  const [accordionState, setAccordionState] = useState('');
  const containerRef = useRef(null);
  const is1024 = useBreakpoint(1440);
  const hintMode = useSelector(hintModeSelector);
  const permissions = usePermissions();
  const enableHintModal = useModalState();
  const location = useLocation();
  const opened = useSelector(sidebarOpenedSelector);
  const [logoutHovered, setLogoutHovered] = useState(false);
  const [notificationsHovered, setNotificationsHovered] = useState(false);
  const [documentationHovered, setDocumentationHovered] = useState(false);
  const [hintHovered, setHintHovered] = useState(false);
  const onboardingData = useSelector(onboardingSelector);
  const [workspacePopoverVisible, setWorkspacePopoverVisible] = useState(false);
  const user = useSelector(userSelector);

  const notificationsHoveredTransition = useTransition(notificationsHovered, transitionConfig);
  const hintmodeHoveredTransition = useTransition(hintHovered, transitionConfig);
  const documentationHoveredTransition = useTransition(documentationHovered, transitionConfig);
  const logoutHoveredTransition = useTransition(logoutHovered, transitionConfig);

  const close = useCallback(() => {
    // if (opened) {
    dispatch(setSidebarOpened({ opened: false }));
    // }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (is1024) {
          close();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, is1024]);

  const toggle = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
    dispatch(setSidebarOpened({ opened: !opened }));
  };

  useOutsideClickListener(containerRef, () => {
    if (is1024) {
      close();
    }
  }, [is1024]);

  useEffect(() => {
    if (user?.id && window._rio?.identify) {
      return window._rio?.identify(user?.id);
    }

    dispatch(getUser({
      onSuccess: (user) => {
        if (!user?.data?.id || !window._rio?.identify) {
          return;
        }

        window._rio?.identify(user?.data?.id);
      }
    }));
  }, [user?.id, window._rio?.identify]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleHintClick = () => {
    if (hintMode) {
      enableHintModal.open();
    }
  }

  const handleDenyOnboard = () => {
    dispatch(updateOnboarding(hintKey,{ skipped: true }));
    const isAllOnboardsFinished = checkIsAllOnboardsFinished({ ...onboardingData, [hintKey]: { ...onboardingData[hintKey], skipped: true }});

    if (isAllOnboardsFinished) {
      cancelOnboardingModal.open();
    } else {
      dispatch(skipOnboard());
    }
  };

  const cancelOnboard = () => {
    cancelOnboardingModal.close();
    dispatch(sentOnboardParams({ enabled: false }));
    dispatch(setHintMode(false));
    dispatch(resetOnboard());
  };

  const handleDocumentationClick = () => {
    window.open('https://retainly.app/docs/', '_blank');
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage({ ge: 'ka-GE', en: 'en-US' }[language]);
    dispatch(changeLanguage({
      language,
      onSuccess: () => {
        dispatch(getUser());
      }
    }))
  };

  const unreadNotifications = notifications && notifications.filter(n => n.read_at === null || n.read_at === undefined).length;

  const { hintKey, hintText, hintStep } = composeHintAsk(onboardingData, hintMode, location, permissions, p, user?.app?.id);

  const containerStyles = useSpring({ width: opened ? 220 : 48 });
  const arrowStyles = useSpring({ left: opened ? 211 : 39, rotateY: opened ? '180deg' : '0deg' });

  return ((
    <Container style={!is1024 ? containerStyles : undefined} data-testid={`sidebar-${opened ? 'opened' : 'closed'}`}>
      <InnerContainer style={containerStyles} ref={containerRef}>
        <Header>
          <WorkspacePopover
            visible={workspacePopoverVisible}
            onVisibleChange={setWorkspacePopoverVisible}
            opened={opened}
          >
            <HeaderInner>
              {user?.app?.is_demo ? (
                <Logo>
                  {resolveDemoWorkspaceIcon(user?.app?.name)}
                </Logo>
              ) : (
                <Logo>
                  <Avatar image={user?.app?.link}>
                    {!user?.app?.link && user?.app?.name[0]}
                  </Avatar>
                </Logo>
              )}
              <Name>{user?.app?.name}</Name>
            </HeaderInner>
          </WorkspacePopover>
          <ArrowButton
            data-testid="sidebar-toggle-btn"
            $visible={(!workspacePopoverVisible || !opened) && !workspacePopoverVisible}
            opened={opened}
            style={arrowStyles}
            onClick={toggle}
          >
            <div style={{ transform: 'scale(1.1) translateX(0px)' }}>
              <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.47339 8.97009C1.55542 8.97009 1.63745 8.953 1.71948 8.91882C1.80151 8.88464 1.87671 8.84021 1.94507 8.78552L5.82104 4.80701L1.94507 0.828491C1.80835 0.691772 1.65112 0.623413 1.47339 0.623413C1.29565 0.623413 1.13843 0.691772 1.00171 0.828491C0.878662 0.951538 0.817139 1.10535 0.817139 1.28992C0.817139 1.47449 0.878662 1.6283 1.00171 1.75134L3.38062 4.13025L4.01529 4.80701L3.42163 5.44275L1.00171 7.84216C0.878662 7.97888 0.817139 8.13611 0.817139 8.31384C0.817139 8.49158 0.878662 8.6488 1.00171 8.78552C1.07007 8.84021 1.14526 8.88464 1.22729 8.91882C1.30933 8.953 1.39136 8.97009 1.47339 8.97009Z" fill="#7C7C7C"/>
              </svg>
            </div>
          </ArrowButton>
        </Header>
        <Menu>
          <div>
            {routes.map(({ path, name, pages, icon, permission, fallback }) =>
              <SidebarItem
                key={name}
                permission={permission}
                onClose={is1024 ? close : undefined}
                accordionState={accordionState}
                setAccordionState={setAccordionState}
                opened={opened}
                pages={pages}
                fallback={fallback}
                name={name}
                title={p(name)}
                icon={icon}
                path={path}
              />
            )}
            <div style={{ width: '100%', height: '100%', background: Colors.Sidebar.BG, minWidth: 48, minHeight: 20 }} />
          </div>
          <div>
            <LanguageSelect language={user?.language} onChange={handleLanguageChange} />
            { hintMode && (
              <HintContainer onClick={handleHintClick} hovered={hintHovered || (hintText && hintKey)} onMouseEnter={() => setHintHovered(true)} onMouseLeave={() => setHintHovered(false)}>
                <IconContainer style={{ cursor: 'pointer' }}>
                  <Icon color={hintHovered || (hintText && hintKey) ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT} size={16} name="Hint-mode" />
                </IconContainer>
                <SidebarButton style={{ marginTop: '0px' }}>{t('labels.onboarding_assistant')}</SidebarButton>
                {!hintText && !hintKey && !opened && hintmodeHoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                  <ItemInfo style={{ opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`), userSelect: 'none' }}>
                    {t('labels.onboarding_assistant')}
                  </ItemInfo>
                ))}
                { hintText && hintKey && (
                  <ItemInfo onClick={e => { e.stopPropagation(); setHintHovered(false) } } mg36 l220={opened}>
                    <OnboardInfoHint hintKey={hintKey} hintText={hintText} step={hintStep} handleDeny={handleDenyOnboard} />
                  </ItemInfo>
                )}
              </HintContainer>
            )}
            {/*<WithPermissions name={UserPermissions.NOTIFICATIONS}>*/}
            <NotificationsContainer onMouseEnter={() => setNotificationsHovered(true)} onMouseLeave={() => setNotificationsHovered(false)} onClick={toggleNotifications}>
              <div style={{ display: 'flex', marginTop: '-7px' }}>
                {!!unreadNotifications ? <Circle /> : null}
                <IconContainer style={{  cursor: 'pointer' }}>
                  <Icon size={16} color={notificationsOpened ? Colors.Sidebar.ICON_ACTIVE : Colors.Sidebar.ICON_DEFAULT} name="Notifications" />
                </IconContainer>
                <SidebarButton hovered={notificationsHovered}>{p('system_notification')}</SidebarButton>
                {!opened && notificationsHoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                  <ItemInfo style={{ marginTop: 7, opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`), userSelect: 'none' }}>
                    {p('system_notification')}
                  </ItemInfo>
                ))}
                { !!unreadNotifications && <Counter><div {...tid('unread-notifications-count')}>{unreadNotifications}</div></Counter> }
              </div>
            </NotificationsContainer>
            {/*</WithPermissions>*/}
            <DocumentationContainer
              onMouseEnter={() => setDocumentationHovered(true)}
              onMouseLeave={() => setDocumentationHovered(false)}
              onClick={handleDocumentationClick}
            >
              <IconContainer style={{ cursor: 'pointer', margin: '6px 0' }}>
                <Icon size={16} color={documentationHovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT} name="Documentation" />
              </IconContainer>
              <SidebarButton style={{ marginTop: 6 }} hovered={documentationHovered}>{p('documentation')}</SidebarButton>
              {!opened && documentationHoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                <ItemInfo style={{ display: 'flex', alignItems: 'center', opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`), userSelect: 'none' }}>
                  {p('documentation')}
                  <LinkIcon>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.9995 12.6C14.9995 13.9 13.8995 15 12.4995 15H3.49951C2.09951 15 0.999512 13.9 0.999512 12.6L0.999512 3.4C0.999512 2.1 2.09951 1 3.49951 1L7.49951 1C7.77565 1 7.99951 1.22386 7.99951 1.5C7.99951 1.77614 7.77565 2 7.49951 2L3.49951 2C2.69951 2 1.99951 2.6 1.99951 3.4L1.99951 12.6C1.99951 13.4 2.69951 14 3.49951 14H12.4995C13.2995 14 13.9995 13.4 13.9995 12.6V8.8C13.9995 8.52386 14.2234 8.3 14.4995 8.3C14.7757 8.3 14.9995 8.52386 14.9995 8.8V12.6ZM14.9502 5.55C14.9502 5.85376 14.7039 6.1 14.4002 6.1C14.0964 6.1 13.8502 5.85376 13.8502 5.55V2.7L7.43594 9.11421C7.23594 9.31421 6.93594 9.31421 6.73594 9.11421C6.53594 8.91421 6.53594 8.61421 6.73594 8.41421L13.1502 2L10.3502 2C10.074 2 9.85015 1.77614 9.85015 1.5C9.85015 1.22386 10.074 1 10.3502 1L14.4502 1C14.6502 1 14.7502 1 14.8502 1.1C14.9502 1.2 14.9502 1.4 14.9502 1.5V5.55Z" fill="white"/>
                    </svg>
                  </LinkIcon>
                </ItemInfo>
              ))}
            </DocumentationContainer>
            <SidebarSettings
              pages={settingsPages}
              onClose={is1024 ? close : undefined}
              sidebarOpened={opened}
              accordionState={accordionState}
              setAccordionState={setAccordionState}
              opened={accordionState === 'settings' && opened}
              onToggle={accordionState === 'settings' ? () => setAccordionState('') : () => setAccordionState('settings')}
            />
            <LogOutRow onMouseEnter={() => setLogoutHovered(true)} onMouseLeave={() => setLogoutHovered(false)} onClick={handleLogout}>
              <div data-testid='log-out-btn'  style={{ display: 'flex', height: 36, alignItems: 'center' }}>
                <IconContainer>
                  <Icon whiteHover color={logoutHovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT} size={16} name="Sidebar-Exit" />
                </IconContainer>
                <SidebarButton style={{ marginTop: 0 }}>{p('log_out')}</SidebarButton>
                {!opened && logoutHoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                  <ItemInfo style={{ opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`), userSelect: 'none' }}>
                    {p('log_out')}
                  </ItemInfo>
                ))}
              </div>
            </LogOutRow>
          </div>
        </Menu>
      </InnerContainer>

      <Modal
        opened={enableHintModal.opened}
        onCancel={enableHintModal.close}
        onClose={enableHintModal.close}
        title={t('labels.onboarding_assistant')}
        style={{ maxWidth: '360px' }}
      >
        <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
          {p('this_icon_show_onboarding_enabled')}
        </div>
      </Modal>

      <Modal
        opened={cancelOnboardingModal.opened}
        onCancel={cancelOnboard}
        onClose={cancelOnboard}
        title={t('labels.onboarding_assistant')}
        style={{ maxWidth: '360px' }}
        actions={(
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button onClick={cancelOnboard} appearance="default" width={160}>{p('ok')}</Button>
          </div>
        )}
      >
        <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
          {p('onboarding_disabled')}
        </div>
      </Modal>
    </Container>
  ));
};

export default Sidebar;
