import { all, fork } from 'redux-saga/effects';

import appRootSaga from './app';
import userRootSaga from './user';
import contactsSaga from './contacts';
import promoCodesSaga from './promoCodes';
import segmentsSaga from './segments';
import workflowSaga from './workflow';
import templatesSaga from './templates';
import integrationsRootSaga from './integrations';
import rolesRootSaga from './roles';
import workspacesRootSaga from './workspaces';
import eventsRootSaga from './events';
import historyNotificationsSaga from './notificationHistory';
import dashboardRootSaga from './dashboard';
import accountRootSaga from './account';
import notifications from './notifications';
import campaignsRootSaga from './campaigns';
import funnelsRootSaga from './funnels';
import subscriptionGroupsRootSaga from './subscriptionGroups';
import fieldsRootSaga from './fields';
import rfmAnalyticsRootSaga from './rfmAnalytics';
import { watchRequests, watchSuccess, watchErrors } from '@store/lib/sagas/request';

export default function* rootSaga() {
  yield all([
    fork(watchRequests),
    fork(watchSuccess),
    fork(watchErrors),
    fork(appRootSaga),
    fork(userRootSaga),
    fork(contactsSaga),
    fork(promoCodesSaga),
    fork(segmentsSaga),
    fork(workflowSaga),
    fork(templatesSaga),
    fork(integrationsRootSaga),
    fork(rolesRootSaga),
    fork(workspacesRootSaga),
    fork(eventsRootSaga),
    fork(notifications),
    fork(historyNotificationsSaga),
    fork(dashboardRootSaga),
    fork(accountRootSaga),
    fork(campaignsRootSaga),
    fork(funnelsRootSaga),
    fork(subscriptionGroupsRootSaga),
    fork(fieldsRootSaga),
    fork(rfmAnalyticsRootSaga)
  ]);
}
