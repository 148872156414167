import styled from 'styled-components';
import { switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${switchStyle('state', {
    previous: `
      background: #24993E;
      color: #fff;
    `,
    current: `
      border: 1px solid #24993E;
      box-sizing: border-box;
    `,
    next: `
      border: 1px solid #909399;
      box-sizing: border-box;
      color: #909399;
    `,
  })}
`;

export const CircleInner = styled.div`
  background: #24993E;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const CircleText = styled.div`
  font-family: Rubik-Regular;
  font-size: 9px;
`;

export const Divider = styled.div`
  width: 26px;
  margin: 0 8px;
  height: 1px;
  background: #909399;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  color: #909399;
`;
