import React from 'react';

const NoteIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.3252 2.62506H10.6752L10.6752 11.3751H3.3252V2.62506ZM2.4502 2.62506C2.4502 2.14181 2.84195 1.75006 3.3252 1.75006H10.6752C11.1584 1.75006 11.5502 2.14181 11.5502 2.62506V11.3751C11.5502 11.8583 11.1584 12.2501 10.6752 12.2501H3.3252C2.84195 12.2501 2.4502 11.8583 2.4502 11.3751V2.62506ZM9.45011 4.55013H4.55011V5.25012H9.45011V4.55013ZM4.55011 6.65004H9.45011V7.35004H4.55011V6.65004ZM9.45011 8.75006H4.55011V9.45006H9.45011V8.75006Z" fill="#303133"/>
    </svg>
  );
};

export default NoteIcon;
