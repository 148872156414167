import React, { useEffect } from 'react';
import { Button, Modal } from '@components';

import { Container } from './styled';
import { useModalState, useTranslation } from '@hooks';

const ChangesNotSavedDisclaimer = ({ onClose, onSubmit, hidden, onLeave }) => {
  const { p, t } = useTranslation('templates');

  const state = useModalState();

  const handleClose = () => {
    onClose();
    state.close();
  }

  return (
     <Modal
       title={p('unsaved_changes')}
       opened={!hidden}
       onClose={onClose}
       actions={
         <>
           <Button onClick={onLeave} appearance="secondary" width={140}>
             {p('dont_save')}
           </Button>
           <Button onClick={onSubmit} appearance="primary" width={140}>
             {p('save_and_leave')}
           </Button>
         </>
       }
     >
       <Container>
         {p('want_save_current_template')}
       </Container>
     </Modal>
  );
};

export default ChangesNotSavedDisclaimer;
