import React, { useEffect, useState } from 'react';
import { moment } from '@utils';

import { useSelector, useDispatch } from 'react-redux';
import {
  emailsSelector,
  facebookHistorySelector,
  mobilePushSelector,
  notificationHistoryCountsSelector,
  promoCodesHistorySelector,
  smsSelector,
  templateSelector,
  webhookSelector,
  webpushSelector
} from '@store/selectors';

import {
  Button,
  DatePicker,
  Header,
  Input,
  HeaderPagination,
  IconButton,
  Multiselect,
  SearchSelect,
} from '@components';

import { useFilters, useTable, useTranslation } from '@hooks';

import { identity } from '@utils';

import {
  Container,
  DateFilter,
  DateInputsRow,
  FilterActions,
  FiltersCol,
  FiltersRow,
  SearchFilter,
} from '@components/lib/styled';

import {
  EMAIL_STATIC_COLS_OPTIONS,
  FACEBOOK_STATIC_COLS_OPTIONS,
  MOBILE_PUSH_STATIC_COLS_OPTIONS,
  SMS_STATIC_COLS_OPTIONS,
  tabs, VIBER_STATIC_COLS_OPTIONS,
  WEBHOOK_STATIC_COLS_OPTIONS,
  WEBPUSH_STATIC_COLS_OPTIONS,
} from './options';
import { TABS } from './constants';
import { EmailTable } from './components/EmailTable';
import { SmsTable } from './components/SmsTable';
import { WebhookTable } from "./components/WebhookTable";
import {
  getEmails,
  getSms,
  getWebhook,
  getTemplates,
  getWebpush,
  getPromoCodesNotificationHistory,
  getMobilePushes,
  getFacebookNotificationHistory,
  getNotificationHistoryCount
} from '@store/actions/creators';
import { FilterField } from './styled';
import { WebpushTable } from './components/WebpushTable';
import {
  EmailStatusOptions,
  FacebookAudiencesStatusOptions,
  MobilePushStatusOptions,
  SmsStatusOptions,
  WebhookStatusOptions,
  WebpushStatusOptions
} from '@constants';
import { WithPermissions } from '../../../../components';
import { UserPermissions } from '../../../../constants';
import { StringParam, useQueryParam } from 'use-query-params';
import PromoCodesTable from './components/PromoCodesTable/PromoCodesTable';
import { getVibers } from '@store/actions/creators';
import { vibersSelector } from '@store/selectors';
import { ViberStatusOptions } from '@constants/viberStatuses';
import { ViberTable } from './components/ViberTable';
import { MobilePushTable } from './components/MobilePushTable';
import {FacebookTable} from "./components/FacebookTable";


const NotificationHistory = () => {
  const dispatch = useDispatch();
  const emails = useSelector(emailsSelector);
  const webhook = useSelector(webhookSelector);
  const vibers = useSelector(vibersSelector);
  const templates = useSelector(templateSelector);
  const webpush = useSelector(webpushSelector);
  const facebook = useSelector(facebookHistorySelector)
  const mobilePush = useSelector(mobilePushSelector);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [webhooksTemplates, setWebhookTemplates] = useState([]);
  const [webpushTemplates, setWebpushTemplates] = useState([]);
  const [viberTemplates, setViberTemplates] = useState([]);
  const [mobilePushTemplates, setMobilePushTemplates] = useState([]);
  const sms = useSelector(smsSelector);
  const [queryTab, setQueryTab] = useQueryParam('tab', StringParam);
  const [tab, setTab] = useState(TABS.INITIAL);
  const promoCodesNotificationHistory = useSelector(promoCodesHistorySelector);
  const { t, p } = useTranslation('notification_history');
  const counts = useSelector(notificationHistoryCountsSelector);

  useEffect(() => {
    if (queryTab) {
      setTab(queryTab);
    }
  }, [])

  const loadData = (params) => {
    if (tab === TABS.EMAIL) {
      dispatch(getEmails({ ...params }));
    } else if (tab === TABS.SMS) {
      dispatch(getSms({ ...params }));
    } else if (tab === TABS.WEBHOOK) {
      dispatch(getWebhook({ ...params }));
    } else if (tab === TABS.WEBPUSH) {
      dispatch(getWebpush({ ...params }));
    } else if (tab === TABS.PROMO_CODES) {
      dispatch(getPromoCodesNotificationHistory({ ...params }));
    } else if (tab === TABS.VIBER) {
      dispatch(getVibers({ ...params }))
    } else if (tab === TABS.MOBILE_PUSH) {
      dispatch(getMobilePushes({ ...params }))
    } else if (tab === TABS.FACEBOOK) {
      dispatch(getFacebookNotificationHistory({ ...params }))
    }
  }

  const emailFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const smsFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const webpushFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const mobilePushFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const webhookFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const promoCodesFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    date_type: '',
    '$type': [],
  });

  const facebookFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
  });

  const viberFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
  });

  const getFilters = () => {
    switch (tab) {
      case TABS.EMAIL:
        return emailFilters;
      case TABS.SMS:
        return smsFilters;
      case TABS.VIBER:
        return viberFilters;
      case TABS.WEBHOOK:
        return webhookFilters;
      case TABS.WEBPUSH:
        return webpushFilters;
      case TABS.PROMO_CODES:
        return promoCodesFilters;
      case TABS.MOBILE_PUSH:
        return mobilePushFilters;
      case TABS.FACEBOOK:
        return facebookFilters;
    }
  }

  const filters = getFilters();

  const entity = {
    [TABS.EMAIL]: 'email',
    [TABS.SMS]: 'sms',
    [TABS.VIBER]: 'viber',
    [TABS.WEBHOOK]: 'webhook',
    [TABS.WEBPUSH]: 'web_push',
    [TABS.PROMO_CODES]: 'discount',
    [TABS.MOBILE_PUSH]: 'mobile_push',
    [TABS.FACEBOOK]: 'facebook',
  }[tab];

  const table = useTable({
    filtersConfig: filters,
    initialOrdering: 'created_at',
    outerFilters: filters,
    deps: [tab],
    metaDeps: [tab],
    metaDispatcher: (params) => {
      dispatch(getNotificationHistoryCount({ entity, ...params }));
    },
    requestDispatcher: (params) => {
      loadData({ ...params });
    },
  });

  useEffect(() => {
    dispatch(getTemplates());
  },[])

  useEffect(() => {
    setEmailTemplates(templates?.email?.map(({ name, id }) => ({ value: id, label: name })));
    setSmsTemplates(templates?.sms?.map(({ name, id }) => ({ value: id, label: name })));
    setWebhookTemplates(templates?.webhook?.map(({ name, id }) => ({ value: id, label: name })));
    setWebpushTemplates(templates?.web_push?.map(({ name, id }) => ({ value: id, label: name })))
    setViberTemplates(templates?.viber?.map(({ name, id }) => ({ value: id, label: name })))
    setMobilePushTemplates(templates?.mobile_push?.map(({ name, id }) => ({ value: id, label: name })))
  }, [templates])

  const [cols, setCols] = useState(tab === TABS.EMAIL ? EMAIL_STATIC_COLS_OPTIONS : SMS_STATIC_COLS_OPTIONS);
  const [colsOptions, setColsOptions] = useState(tab === TABS.EMAIL ? EMAIL_STATIC_COLS_OPTIONS : SMS_STATIC_COLS_OPTIONS);

  const handleChangeTab = (newTab) => {
    if (newTab === tab) {
      return;
    }

    setTab(newTab);
    setQueryTab(newTab);
    if (newTab === TABS.EMAIL) {
      setColsOptions(EMAIL_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.SMS) {
      setColsOptions(SMS_STATIC_COLS_OPTIONS)
    } else if (newTab === TABS.WEBHOOK) {
      setColsOptions(WEBHOOK_STATIC_COLS_OPTIONS)
    } else if (newTab === TABS.WEBPUSH) {
      setColsOptions(WEBPUSH_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.VIBER) {
      setColsOptions(VIBER_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.MOBILE_PUSH) {
      setColsOptions(MOBILE_PUSH_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.FACEBOOK) {
      setColsOptions(FACEBOOK_STATIC_COLS_OPTIONS);
    }

    table.pagination.handlers.onPerPageChange((20));
    table.pagination.handlers.onPageChange(0);
    table.discardWithoutRefresh();
  }

  const getPage = () => {
    return table.pagination.page;
  }

  const getTotal = () => {
    if (tab === TABS.EMAIL) {
      return emails?.meta?.total;
    } else if (tab === TABS.SMS) {
      return sms?.meta?.total;
    } else if (tab === TABS.WEBHOOK) {
      return webhook.meta?.total || 0;
    } else if (tab === TABS.WEBPUSH) {
      return webpush?.meta?.total;
    } else if (tab === TABS.PROMO_CODES) {
      return promoCodesNotificationHistory.meta?.total;
    } else if (tab === TABS.VIBER) {
      return vibers.meta?.total;
    } else if (tab === TABS.MOBILE_PUSH) {
      return mobilePush?.meta?.total;
    } else if (tab === TABS.FACEBOOK) {
      return facebook?.meta?.total;
    }
  }

  const getTitleInput = () => {
    if (tab === TABS.EMAIL) {
      return p('search_email');
    } else if (tab === TABS.SMS || tab === TABS.VIBER) {
      return p('search_sms');
    } else if (tab === TABS.WEBHOOK || tab === TABS.FACEBOOK) {
      return p('search_webhook');
    } else if (tab === TABS.WEBPUSH) {
      return p('search_webpush');
    } else if (tab === TABS.MOBILE_PUSH) {
      return p('search_mobile_push')
    }

    return p('search_default');
  };

  const getPermissionName = () => {
    if (tab === TABS.EMAIL) {
      return UserPermissions.REVIEW_NOTIFICATION_HISTORY_EMAIL;
    } else if (tab === TABS.SMS) {
      return UserPermissions.REVIEW_NOTIFICATION_HISTORY_SMS;
    } else if (tab === TABS.WEBHOOK) {
      return UserPermissions.REVIEW_NOTIFICATION_HISTORY_API_REQUESTS;
    } else if (tab === TABS.WEBPUSH) {
      return UserPermissions.REVIEW_NOTIFICATION_HISTORY_WEBPUSHES;
    } else if (tab === TABS.MOBILE_PUSH) {
      return UserPermissions.REVIEW_NOTIFICATION_HISTORY_MOBILE_PUSHES;
    }
    else if (tab === TABS.FACEBOOK) {
      // return UserPermissions.REVIEW_NOTIFICATION_HISTORY_MOBILE_PUSHES;
    }
  }

  const getOptionsTemplates = () => {
    switch (tab) {
      case TABS.EMAIL:
        return emailTemplates;
      case TABS.SMS:
        return smsTemplates;
      case TABS.VIBER:
        return viberTemplates;
      case TABS.WEBHOOK:
        return webhooksTemplates;
      case TABS.WEBPUSH:
        return webpushTemplates;
      case TABS.MOBILE_PUSH:
        return mobilePushTemplates;
    }
  }

  const getFilterName = () => {
    switch (tab) {
      case TABS.EMAIL:
        return 'template_id';
      case TABS.SMS:
        return 'template_id';
      case TABS.VIBER:
        return 'template_id';
      case TABS.WEBHOOK:
        return 'template_id';
      case TABS.WEBPUSH:
        return 'template_id';
      case TABS.MOBILE_PUSH:
        return 'template_id';
    }
  }

  const getStatusOptions = () => ({
    [TABS.SMS]: SmsStatusOptions,
    [TABS.EMAIL]: EmailStatusOptions,
    [TABS.WEBHOOK]: WebhookStatusOptions,
    [TABS.WEBPUSH]: WebpushStatusOptions,
    [TABS.VIBER]: ViberStatusOptions,
    [TABS.PROMO_CODES]: WebhookStatusOptions,
    [TABS.FACEBOOK]: FacebookAudiencesStatusOptions,
    [TABS.MOBILE_PUSH]: MobilePushStatusOptions
  })[tab];

  const typeOptions = [
    { label: t('labels.all'), values: [] },
    { label: t('labels.percentage'), value: 0 },
    { label: t('labels.currency'), value: 1 }
  ];

  const keyExtractor = ({ id }) => id;

  const count = counts[entity];

  return (
    <Container>
      <Header
        opened={table.filters.opened}
        tabs={tabs}
        tab={tab}
        tabTestIdPrefix="notification-history"
        onTabChange={handleChangeTab}
        title={p('title')}
        left={(
          <HeaderPagination
            onPerPageChange={table.changePerPage}
            onPageChange={table.changePage}
            perPage={table.pagination.perPage}
            page={table.pagination.page}
            loading={count?.loading}
            total={count?.total}
          />
        )}
        right={(
          <>
            <IconButton
              active={table.filters.opened}
              onClick={table.filters.toggle}
              style={{ marginRight: '10px' }}
              testId="notification-history-filters-toggle"
              icon="Filters"
            />
            {/*{(tab === TABS.EMAIL || tab === TABS.SMS) && (*/}
            {/*  <DropdownButton*/}
            {/*    style={{ marginRight: '10px' }}*/}
            {/*    title={t('labels.displaying')}*/}
            {/*    testId="notification-history-displaying"*/}
            {/*    value={cols}*/}
            {/*    options={colsOptions}*/}
            {/*    onChange={setCols}*/}
            {/*  />*/}
            {/*)}*/}
          </>
        )}
        content={(
          <FiltersCol>
            <FiltersRow>
              <SearchFilter>
                <Input
                  value={filters.filters['input_value']}
                  onChange={filters.onChange('input_value')}
                  title={getTitleInput()}
                />
              </SearchFilter>
              <DateInputsRow>
                {tab !== TABS.PROMO_CODES && (
                  <FilterField>
                    <Multiselect
                      containerStyle={{ marginLeft: '10px', marginRight: '10px' }}
                      options={getStatusOptions(tab)}
                      getParentElement={trigger => trigger.parentElement}
                      value={filters.filters['status']}
                      title={t('labels.status')}
                      placeholder={t('statuses')}
                      onChange={filters.onChange('status', identity)}
                    />
                  </FilterField>
                )}
                {tab === TABS.PROMO_CODES && tab !== TABS.FACEBOOK && (
                  <DateFilter style={{ marginRight: 12 }}>
                    <SearchSelect
                      title={t('labels.filter_by_date')}
                      containerStyle={{ marginBottom: 16 }}
                      options={[{ value: 'created_at', label: t('labels.send_date') }, { value: 'end_at', label: t('labels.active_period') }]}
                      onChange={filters.onChange('date_type', identity)}
                      value={filters.filters['date_type']}
                    />
                  </DateFilter>
                )}
                <DateFilter style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <DatePicker
                    value={filters.filters['start_date'] ? moment(filters.filters['start_date']) : undefined}
                    onChange={table.filters.onChange('start_date', (_, ds) => ds)}
                    type="date"
                    title={t('labels.date_from')}
                  />
                </DateFilter>
                <DateFilter style={{ marginRight: (tab === TABS.PROMO_CODES || tab === TABS.FACEBOOK) ? -12 : 0, display: 'flex', alignItems: 'flex-end' }}>
                  <DatePicker
                    value={filters.filters['end_date'] ? moment(filters.filters['end_date']): undefined}
                    onChange={filters.onChange('end_date', (_, ds) => ds)}
                    type="date"
                    title={t('labels.date_to')}
                  />
                </DateFilter>
                {tab !== TABS.PROMO_CODES && tab !== TABS.FACEBOOK && (
                  <FilterField>
                    {getPermissionName() ? (
                      <WithPermissions name={getPermissionName()}>
                        <Multiselect
                          getParentElement={trigger => trigger.parentElement}
                          options={getOptionsTemplates()}
                          value={filters.filters[getFilterName()]}
                          title={t('labels.template')}
                          onChange={filters.onChange(getFilterName(), identity)}
                        />
                      </WithPermissions>
                    ) : (
                      <Multiselect
                        getParentElement={trigger => trigger.parentElement}
                        options={getOptionsTemplates()}
                        value={filters.filters[getFilterName()]}
                        title={t('labels.template')}
                        onChange={filters.onChange(getFilterName(), identity)}
                      />
                    )}
                  </FilterField>
                )}
              </DateInputsRow>
            </FiltersRow>
            <FilterActions>
              <FiltersRow>
                {tab === TABS.PROMO_CODES ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FilterField>
                    <Multiselect
                      title={t('labels.type')}
                      value={filters.filters['$type']}
                      onChange={filters.onChange('$type', identity)}
                      options={typeOptions}
                      getParentElement={trigger => trigger.parentElement}
                    />
                  </FilterField>
                  <FilterField>
                    <Multiselect
                      containerStyle={{ marginLeft: '10px', marginRight: '10px' }}
                      options={getStatusOptions(tab)}
                      getParentElement={trigger => trigger.parentElement}
                      value={filters.filters['status']}
                      title={t('labels.status')}
                      placeholder={t('statuses')}
                      onChange={filters.onChange('status', identity)}
                    />
                  </FilterField>
                  </div>
                ) : <div />}
              </FiltersRow>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Button width={100} onClick={table.applyFilters} style={{ marginRight: '10px' }}>{t('actions.search')}</Button>
                <Button width={100} appearance="discard" onClick={table.discardFilters}>{t('actions.discard')}</Button>
              </div>
            </FilterActions>
          </FiltersCol>
        )}
      />
      { tab === TABS.EMAIL ? <EmailTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.SMS ? <SmsTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.WEBHOOK ? <WebhookTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.WEBPUSH ? <WebpushTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.PROMO_CODES ? <PromoCodesTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.VIBER ? <ViberTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.MOBILE_PUSH ? <MobilePushTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.FACEBOOK ? <FacebookTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
    </Container>
  )
};

export default NotificationHistory;
