import React, { useState } from 'react';

import { Container, Overlay, IconContainer } from './styled';
import { Icon } from '@components';
import Picker from 'emoji-picker-react';

const EmojiPicker = ({ onEmojiChange, ...props }) => {
  const [opened, setOpened] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    event.stopPropagation();
    onEmojiChange(emojiObject.emoji);
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
  };

  return (
    <Container>
      <IconContainer onClick={() => setOpened(true)}>
        <Icon name="smile" style={{ lineHeight: 20, height: 20, width: 20 }} size={20} color="#909399" />
      </IconContainer>
      { opened && (
            <Overlay onBlur={() => setOpened(false)} onMouseDown={handleMenuClick}>
              <Picker pickerStyle={{ zIndex: 999999 }} native onEmojiClick={onEmojiClick}/>
            </Overlay>
        )}
    </Container>
  );
}

export default EmojiPicker;


