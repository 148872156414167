import React from 'react';

import AutoResTextarea from 'react-textarea-autosize';
import { Container } from './styled';

import { Title, TitleRow } from '@components/inputs/Input/styled';

const AutoResizeTextarea = ({ testId, onChange, value, title, style, disabled, maxRows = 10 }) => {
  return (
    <Container data-testId={testId}>
      {title && (
        <TitleRow>
          <Title>{title}</Title>
        </TitleRow>
      )}
      <AutoResTextarea
        style={{ ...(style || {}) }}
        maxRows={maxRows}
        data-testid={testId}
        disabled={disabled}
        title={title}
        className="resize-textarea"
        value={value}
        onChange={onChange}
      />
    </Container>
  );
}

export default AutoResizeTextarea;


