export const RFMColorsConfig = [
  {
    dimensions: [2, 1],
    recency: '1 - 2',
    frequency: '5',
    title: 'cant_lose',
    name: "Can't Loose",
    order: 6,
    background: 'rgba(253, 235, 141, 0.5)',
    color:  {
      title: '#B6AF18',
      percentage: '#DBD32D',
      ui: '#FEF5C6',
    },
  },
  {
    dimensions: [2, 2],
    recency: '3 - 4',
    frequency: '4 - 5',
    title: 'loyal',
    name: "Loyal Customers",
    order: 1,
    background: 'rgba(54, 200, 130, 0.5)',
    color:  {
      title: '#099569',
      percentage: '#33B98F',
      ui: '#36C882',
    },
  },
  {
    dimensions: [1, 2],
    recency: '5',
    frequency: '4 - 5',
    title: 'champions',
    name: "Champions",
    order: 0,
    background: 'rgba(34, 186, 113, 0.5)',
    color:  {
      title: '#07865E',
      percentage: '#18AC7A',
      ui: '#22BA71',
    },
  },
  {
    dimensions: [2, 2],
    recency: '1 - 2',
    frequency: '3 - 4',
    title: 'at_risk',
    name: "At Risk",
    order: 7,
    background: 'rgba(238, 214, 131, 0.5)',
    color:  {
      title: '#D18352',
      percentage: '#D89D78',
      ui: '#F7EBC1',
    },
  },
  {
    dimensions: [1, 1],
    recency: '3',
    frequency: '3',
    title: 'need_attention',
    name: "Need Attention",
    order: 5,
    background: 'rgba(234, 255, 152, 0.5)',
    color:  {
      title: '#BFB92A',
      percentage: '#D6D165',
      ui: '#F5FFCB',
    },
  },
  {
    dimensions: [2, 2],
    recency: '4 - 5',
    frequency: '3',
    title: 'potential_loyalists',
    name: "Potential Loyalists",
    order: 2,
    background: 'rgba(89, 213, 154, 0.5)',
    color:  {
      title: '#139A6C',
      percentage: '#22C08B',
      ui: '#59D59A',
    },
  },
  {
    dimensions: [2, 2],
    recency: '1 - 2',
    frequency: '1 - 2',
    title: 'hibernating',
    name: "Hibernating",
    order: 9,
    background: 'rgba(237, 117, 109, 0.5)',
    color:  {
      title: '#E5453B',
      percentage: '#ED6158',
      ui: '#F6BAB6',
    },
  },
  {
    dimensions: [1, 2],
    recency: '3',
    frequency: '1 - 2',
    title: 'about_to_sleep',
    name: "About To Sleep",
    order: 8,
    background: 'rgba(255, 180, 128, 0.5)',
    color:  {
      title: '#E9782A',
      percentage: '#EE975B',
      ui: '#FFDABF',
    },
  },
  {
    dimensions: [1, 1],
    recency: '4',
    frequency: '1',
    title: 'promising',
    name: "Promising",
    order: 4,
    background: 'rgba(205, 253, 132, 0.5)',
    color:  {
      title: '#5EAC6A',
      percentage: '#78D185',
      ui: '#CDFD84',
    },
  },
  {
    dimensions: [1, 1],
    recency: '5',
    frequency: '1',
    title: 'new_customers',
    name: "New Customers",
    order: 3,
    background: 'rgba(131, 231, 159, 0.5)',
    color:  {
      title: '#21A97B',
      percentage: '#2DC994',
      ui: '#83E79F'
    },
  },
];
