import React from 'react';

import { Container } from './styled.js';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { by, extract } from '@utils';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { useTranslation } from '@hooks';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { getExitPoints } from '@utils/workflows/refactored/getters';
import uniqBy from 'lodash.uniqby';

const AnotherWorkflowNodeSettingsInfo = ({ node: outerNode, options }) => {
  const nodes = (createMultinodeUpdater(outerNode.data)?.values || []).map(v => ({ data: v }));
  const { t } = useTranslation();

  if (!nodes?.length || !nodes?.[0]?.data?.trigger_workflow_id) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const renderNode = (node, index) => {
    if (!node.data?.trigger_workflow_id) {
      return null;
    }

    const workflow = options?.workflows?.find?.(by(node.data?.trigger_workflow_id));
    const eps = uniqBy(getExitPoints(workflow?.tree_map), extract('id'));

    const analytics = outerNode.data?.analytics?.clones?.find?.((data) => data?.node === node.data?.id);

    return (
      <>
        <InfoRow style={{ marginTop: index > 0 ? 18 : 0, maxWidth: 300, display: 'flex', alignItems: 'flex-start' }}>
          <InfoTitle>
            {`${workflow?.name} (${workflow?.version})`}:
          </InfoTitle>
          <InfoText>
            {node?.data?.trigger_workflow_node_ids?.map(epId => eps?.find(e => e.id === epId)?.data?.label || 'label 123123')?.join(', ')}
          </InfoText>
        </InfoRow>
        {analytics && <AnalyticsSection analytics={analytics}/>}
      </>
    );
  }

  return (
    <Container>
      {nodes.map(renderNode)}
    </Container>
  );
}

export default AnotherWorkflowNodeSettingsInfo;


