import React from 'react';

import { Rect } from '../LargeTemplatesLoader/styled';

const rectConfig = [
  { x: 0, y: 0, rx: 10, ry: 10, width: 200, height: 50, id: 1 },
  { x: 250, y: 0, rx: 10, ry: 10, width: 200, height: 50, id: 2 },
  { x: 500, y: 0, rx: 10, ry: 10, width: 200, height: 50, id: 3 },
  { x: 750, y: 0, rx: 10, ry: 10, width: 200, height: 50, id: 4 },
  { x: 1000, y: 0, rx: 10, ry: 10, width: 200, height: 50, id: 5 },
  { x: 0, y: 100, rx: 10, ry: 10, width: 200, height: 50, id: 6 },
  { x: 250, y: 100, rx: 10, ry: 10, width: 200, height: 50, id: 7 },
  { x: 500, y: 100, rx: 10, ry: 10, width: 200, height: 50, id: 8 },
  { x: 750, y: 100, rx: 10, ry: 10, width: 200, height: 50, id: 9 },
  { x: 1000, y: 100, rx: 10, ry: 10, width: 200, height: 50, id: 10 },
  { x: 0, y: 200, rx: 10, ry: 10, width: 200, height: 50, id: 11 },
  { x: 250, y: 200, rx: 10, ry: 10, width: 200, height: 50, id: 12 },
  { x: 500, y: 200, rx: 10, ry: 10, width: 200, height: 50, id: 13 },
  { x: 750, y: 200, rx: 10, ry: 10, width: 200, height: 50, id: 14 },
  { x: 1000, y: 200, rx: 10, ry: 10, width: 200, height: 50, id: 15 },
]

const SmallTemplatesLoader = (props) => {
  return (
    <svg
      style={{
        width: '100%',
        height: '100%',
        padding: 20,
      }}
    >
      {rectConfig.map((i, index) => (
        <Rect key={index} {...i} style={{ animationDelay: `${index * 20}ms`}} />
      ))}
    </svg>
  );
}

export default SmallTemplatesLoader;


