import React from 'react';

import { Container, Title, SwitchContainer, SwitchCircle } from './styled';

const Switch = ({ title, checked, testId = '', onChange, style, disabled, large }) => {
  const handleChange = (event) => {
    if (disabled) {
      return;
    }

    onChange(!checked, event);
  };

  return (
   <Container data-testid={testId} onClick={handleChange} style={style}>
     {title && <Title>{title}</Title>}
     <SwitchContainer large={large} disabled={disabled} checked={checked} >
       <SwitchCircle large={large} checked={checked} />
     </SwitchContainer>
   </Container>
  );
};

export default Switch;
