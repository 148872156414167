import React, { useRef, useState } from 'react';
import { Menu, Tooltip } from 'antd';
import { by, moment, notify, sequence } from '@utils';

import { MoreButton, WithPermissions, WithOnboarding, Icon, OnboardingHighlight } from '@components';

import {
  Wrapper,
  InfoWrapper,
  Info,
  Title,
  Date,
  MenuItem,
  PreviewWrapper,
  Preview,
  HoverMenu,
  IconContainer,
  Placeholder,
  ViberPreview,
  ViberButton,
  ViberImage,
  MobilePushImage,
  MobpushPreview,
} from './styled';
import { UserPermissions } from '@constants';
import { foldersListSelector } from '@store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {setFolder} from '@store/actions/creators';
import { useParams } from 'react-router';
import { useTranslation } from '@hooks';
import MobilePushPreview from "@res/images/mobile-push-preview.png";

const LargeTemplateItem = ({
  title,
  date,
  workflows,
  html,
  onboard,
  id,
  allOnboard,
  status,
  testId,
  allOnboardText,
  onViewClick,
  onEditClick,
  onDuplicateClick,
  onArchive,
  refreshFolders,
  dragging,
  body,
  image_url,
  btn_name,
  onRefresh,
  onUnarchive,
  onStatisticsClick,
  onDeleteClick,
  onInfoClick,
  fromDashboard = false,
  style,
  type,
  used,
  isExample = false,
}) => {
  const { folder } = useParams();
  const [hovered, setHovered] = useState();
  const frame = useRef(null);
  const dispatch = useDispatch();
  const foldersList = useSelector(foldersListSelector);
  const { t, p } = useTranslation('templates')

  const handleClick = fn => sequence(e => e?.stopPropagation?.(), e => e?.domEvent?.stopPropagation?.(), fn);

  const showPlaceholder = frame.current?.contentDocument?.body?.textContent && frame.current?.contentDocument?.body?.textContent?.replaceAll?.('\n', '')?.trim?.() === '';

  const handleMoveToFolder = (folder_id) => () => {
    const name = foldersList.data?.find(by(+folder_id))?.name;

    dispatch(setFolder({
      id,
      type,
      folder_id,
      onSuccess: () => {
        notify('success', p('template_moved'), name || 'Default');
        refreshFolders?.();
        onRefresh?.();
      }
    }))
  };

  const archiveDisabled = !!used;
  const deleteDisabled = workflows?.[0] !== undefined;

  const isDragging = dragging?.snapshot?.isDragging;
  const draggingOver = dragging?.snapshot?.draggingOver && dragging.snapshot.draggingOver !== 'self';

  const getReviewPermission = () => {
    switch (type) {
      case 'email':
        return UserPermissions.REVIEW_EMAILS;
      case 'viber':
        return UserPermissions.REVIEW_VIBER;
      case 'mobile_push':
        return UserPermissions.REVIEW_MOBILE_PUSH;
      case 'webpush':
        return UserPermissions.REVIEW_WEBPUSHES;
    }
  }

  const getCreatePermission = () => {
    switch (type) {
      case 'email':
        return UserPermissions.CREATE_EMAILS;
      case 'viber':
        return UserPermissions.CREATE_VIBER;
      case 'mobile_push':
        return UserPermissions.CREATE_MOBILE_PUSH;
      case 'webpush':
        return UserPermissions.CREATE_WEBPUSHES;
    }
  }

  const getDeletePermission = () => {
    switch (type) {
      case 'email':
        return UserPermissions.DELETE_EMAILS;
      case 'viber':
        return UserPermissions.DELETE_VIBER;
      case 'mobile_push':
        return UserPermissions.DELETE_MOBILE_PUSH;
      case 'webpush':
        return UserPermissions.DELETE_WEBPUSHES;
    }
  }

  const getArchivePermission = () => {
    switch (type) {
      case 'email':
        return UserPermissions.ARCHIVE_EMAILS;
      case 'viber':
        return UserPermissions.ARCHIVE_VIBER;
      case 'mobile_push':
        return UserPermissions.ARCHIVE_MOBILE_PUSH;
      case 'webpush':
        return UserPermissions.ARCHIVE_WEBPUSH;
    }
  }

  const getUnarchivePermission = () => {
    switch (type) {
      case 'email':
        return UserPermissions.UN_ARCHIVE_EMAILS;
      case 'viber':
        return UserPermissions.UN_ARCHIVE_VIBER;
      case 'mobile_push':
        return UserPermissions.UN_ARCHIVE_MOBILE_PUSH;
      case 'webpush':
        return UserPermissions.UN_ARCHIVE_WEBPUSH;
    }
  }

  const renderTemplatePreview = () => {
    if (type === 'email') {
      return (
        <>
          <Preview ref={frame} srcDoc={html} scrolling="no" />
          <HoverMenu className="HoverMenu" />
          {showPlaceholder && (
            <Placeholder>
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_9107_486013)">
                  <rect width="64" height="64" rx="14" fill="white"/>
                  <rect width="64" height="64" rx="14" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M55 17.5C55 22.7467 50.7467 27 45.5 27C40.2533 27 36 22.7467 36 17.5C36 12.2533 40.2533 8 45.5 8C50.7467 8 55 12.2533 55 17.5ZM24.7656 26.6404L36.5753 44.1247L42.2889 36.3333C43.4949 34.6887 45.955 34.7008 47.1447 36.3572L67 64H50H22H-6L19.8108 26.6151C21.0108 24.877 23.5834 24.8901 24.7656 26.6404Z" fill="#F0F2F6"/>
                </g>
                <defs>
                  <clipPath id="clip0_9107_486013">
                    <rect width="64" height="64" rx="14" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </Placeholder>
          )}
        </>
      )
    }

    if (type === 'viber') {
      return (
        <ViberPreview>
          <div style={{ padding: '0 14px' }}>{body}</div>
          {image_url && <ViberImage src={image_url} />}
          {(!!btn_name && btn_name !== 'null') ? <ViberButton>{btn_name}</ViberButton> : null}
        </ViberPreview>
      )
    }

    if (type === 'mobile_push' || type === 'webpush') {
      return (
        <MobpushPreview>
          <MobilePushImage src={image_url || MobilePushPreview} />
          <div style={{ padding: '10px 14px' }}>{body}</div>
        </MobpushPreview>
      )
    }

    return null;
  }

  return (
    <WithOnboarding
      enabled={allOnboard}
      placement="right"
      onboardKey="templates"
      next
      text={allOnboardText}
    >
      <OnboardingHighlight on={allOnboard} right={-12} bottom={-12} radius={14} style={{ zIndex: 1 }}>
        <Wrapper
          $isDragging={isDragging}
          $draggingOverFolder={draggingOver}
          isExample={isExample}
          data-testid={testId}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={!isExample && +status !== 3 ? handleClick(() => onEditClick(workflows?.length)) : () => {}}
          style={{ cursor: isExample ? 'default' : 'pointer', ...(style || {}) }}
          // onboard={allOnboard}
        >
          <PreviewWrapper isExample={isExample} hovered={hovered}>
            {renderTemplatePreview()}
          </PreviewWrapper>
          <InfoWrapper>
            <div style={{ display: 'flex' }}>
              <Info>
                <Title>
                  {title}
                </Title>
                <Date>{moment(date).format('YYYY-MM-DD')}</Date>
              </Info>
              <div onClick={onInfoClick} style={{ marginLeft: 8, marginTop: 2, cursor: 'pointer' }}>
                { isExample && (
                  <Tooltip title={t('labels.description')}>
                    <IconContainer transparent>
                      <Icon name="Info-icon" color="#109DFB" size={14} />
                    </IconContainer>
                  </Tooltip>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WithPermissions name={getReviewPermission()}>
                <Tooltip title={p('view_template')}>
                  <IconContainer data-testid={`${testId}-view`} onClick={handleClick(onViewClick)} style={{ marginRight: 2 }}>
                    <Icon name="Show-icon" size={14} />
                  </IconContainer>
                </Tooltip>
              </WithPermissions>
              <WithOnboarding enabled={onboard} onboardKey="templates" text={p('duplicate_onboarding')} placement="right">
                <MoreButton
                  color="#303133"
                  iconSize={26}
                  testId={`${testId}-more`}
                  onboard={onboard}
                  onClick={e => e.stopPropagation()}
                  style={{ marginRight: '-10px' }}
                  iconStyles={{ justifyContent: 'center' }}
                  getPopupContainer={t => t.parentElement}
                  menu={
                    <Menu style={{ borderRadius: '10px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.22)', overflow: 'hidden' }}>
                      {+status !== 3 && (
                        <>
                          <Menu.Item key="d" onClick={e => e.domEvent.stopPropagation()}>
                            <WithPermissions name={getCreatePermission()}>
                              <MenuItem data-testid={`${testId}-duplicate`} onClick={handleClick(onDuplicateClick)}>{t('actions.duplicate')}</MenuItem>
                            </WithPermissions>
                          </Menu.Item>
                          {onStatisticsClick && (
                            <Menu.Item key="3" onClick={e => e.stopPropagation()}>
                              <MenuItem data-testid={`${testId}-stats`} onClick={handleClick(onStatisticsClick)}>{t('actions.statistics')}</MenuItem>
                            </Menu.Item>
                          )}
                        </>
                      )}
                      {!isExample && !fromDashboard && +status !== 3 && (
                        <WithPermissions name={UserPermissions.UPDATE_FOLDER}>
                          <div onClick={e => e.stopPropagation()}>
                            <Menu.SubMenu key="sa" disabled={folder === 'default' && foldersList.data?.filter?.(({ id }) => String(id) !== String(folder)).length === 0} popupClassName="submenu" title={t('actions.move_to_folder')}>
                              {folder !== 'default' && (
                                <Menu.Item onClick={handleClick(handleMoveToFolder(null))}>
                                  <MenuItem>{p('all_templates')}</MenuItem>
                                </Menu.Item>
                              )}
                              {foldersList.data?.filter?.(({ id }) => String(id) !== String(folder))?.map?.(({ name, id }) => (
                                <Menu.Item key={id} onClick={handleClick(handleMoveToFolder(id))}>
                                  <MenuItem>{name}</MenuItem>
                                </Menu.Item>
                              ))}
                            </Menu.SubMenu>
                          </div>
                        </WithPermissions>
                      )}
                      {(!isExample && !fromDashboard && +status === 3) && (
                        <Menu.Item key="4" onClick={e => e.domEvent.stopPropagation()}>
                          <WithPermissions name={getUnarchivePermission()}>
                            <MenuItem data-testid={`${testId}-unarchive`} onClick={handleClick(onUnarchive)}>
                              {t('actions.unarchive')}
                            </MenuItem>
                          </WithPermissions>
                        </Menu.Item>
                      )}
                      {!isExample && (
                        <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); } } key="a">
                          <WithPermissions onClick={(e) => { e.stopPropagation(); }} name={+status === 3 ? getDeletePermission() : getArchivePermission()}>
                            <MenuItem data-testid={`${testId}-delete`} disabled={+status === 3 ? deleteDisabled : archiveDisabled} onClick={handleClick(+status === 3 ? onDeleteClick : onArchive)} danger>
                              {+status === 3 ? t('actions.delete') : t('actions.archive')}
                            </MenuItem>
                          </WithPermissions>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                />
              </WithOnboarding>
            </div>
          </InfoWrapper>
        </Wrapper>
      </OnboardingHighlight>
    </WithOnboarding>
  );
};

export default LargeTemplateItem;
