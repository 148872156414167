import React, { useRef, useState } from 'react';
import { moment } from '@utils';
import { ActionButton, Button, Modal } from '@components';
import { sequence } from '@utils';
import {
  Comment,
  CommentDateLarge,
  CommentDateSmall,
  CommentHeader,
  CommentText,
  CommentUser,
  TextArea,
  MessageBox,
  ScrollContainer,
} from './styled';
import { useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import { usersListSelector } from '@store/selectors';

const NotesModal = ({
  opened,
  onClose,
  onCreateComment,
  onDeleteComment,
  comments = [],
  title = 'Customer notes',
  mapper= {
    createdAt: () => c => moment(c.actual_date['$date']['$numberLong']),
  },
}) => {
  const { p, t } = useTranslation('all_contacts_page');
  const [text, setText] = useState('');
  const textArea = useRef(null);
  const scrollContainer = useRef();
  const usersList = useSelector(usersListSelector);

  const handleTextChange = ({ target: { value } }) => {
    setText(value);
  }

  const handleCreateComment = () => {
    onCreateComment({ comment: text, onSuccess: () => {
      setText('');
      textArea.current.style.height = "";
      setTimeout(() => {
        scrollContainer.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 200);
    } });
  }

  const handleDeleteComment = (id) => () => {
    onDeleteComment({ comment_id: id });
  };

  const resizeTextarea = ({ target }) => {
    if (target.scrollHeight > 400) {
      return;
    }

    textArea.current.style.height = "30px";
    textArea.current.style.height = `${target.scrollHeight}px`;
  };

  const sorted = comments?.sort?.((a, b) => moment(mapper.createdAt(a)?.unix?.() - mapper.createdAt(b)?.unix?.()));

 return (
    <Modal
      fullScreen
      opened={opened}
      onClose={onClose}
      contentStyles={{ paddingRight: 0, paddingLeft: 0, paddingTop: 0 }}
      style={{ top: '0px', marginRight: '0px', height: '100vh', paddingBottom: '0px', paddingTop: '0px' }}
      title={title}
      actions={(
        <>
          <Button onClick={onClose} appearance="secondary" width={160}>{t('actions.close')}</Button>
          <Button onClick={handleCreateComment} width={160}>{t('actions.save')}</Button>
        </>
      )}
      additional={(
        <MessageBox>
          <TextArea ref={textArea} rows={1} value={text} onChange={sequence(resizeTextarea, handleTextChange)} />
        </MessageBox>
      )}
    >
      <ScrollContainer className="show-scrollbar">
        {sorted?.map((comment, index) => (
          <Comment style={{ width: '100%' }} key={comment.id} bordered={index < comments.length - 1}>
            <CommentHeader>
              <div>
                <CommentDateLarge>
                  {moment(comment?.actual_date)?.format?.('YYYY-MM-DD')}
                  {/*{mapper.createdAt(comment)?.format?.('YYYY-MM-DD')}*/}
                </CommentDateLarge>
                <CommentDateSmall>
                  {moment(comment?.actual_date)?.format?.('hh:mm A')}
                  {/*{mapper.createdAt(comment)?.format?.('hh:mm A')}*/}
                </CommentDateSmall>
              </div>
              <CommentUser>
                {(usersList?.data?.find?.(u => u.id === comment.user_id) ? `${usersList?.data?.find?.(u => u.id === comment.user_id)?.name} ${usersList?.data?.find?.(u => u.id === comment.user_id)?.surname}` : comment.user_id)}
              </CommentUser>
              <ActionButton
                tooltip={p('delete_note')}
                appearance="danger"
                icon="Delete-icon"
                onClick={handleDeleteComment(comment.id)}
              />
            </CommentHeader>
            <CommentText>
              {comment.comment?.split?.('\n').map(
                (part, i, arr) => <React.Fragment key={i}>{part}{arr.length -1 !== i && <br />}</React.Fragment>
              )}
            </CommentText>
          </Comment>
        ))}
        <div id="dummy" ref={scrollContainer} />
      </ScrollContainer>
    </Modal>
 );
};

export default NotesModal;
