import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateCell, Link, SortableTitle, Table, WithPermissions, ViewEmailTemplateModal } from '@components';
import {emailsLoadingSelector, emailsSelector, templateSelector} from '@store/selectors';
import EmailActions from '../EmailActions/EmailActions';
import { useModalState, useTranslation, useNavigate } from '@hooks';
import { EmailInfoModal } from '../Modals';
import { StatusCell } from '../StatusCell';
import { EmailStatuses, EmailStatusLabels, Paths, UserPermissions } from '@constants';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { IdType } from '@components/lib/styled';
import { extract } from '@utils';
const resolveStatus = (status, t) => t(EmailStatusLabels[status]);

const resolveColor = status => ({
  [EmailStatuses.SENT]: '#24993E',
  [EmailStatuses.DELIVERED]: '#24993E',
  [EmailStatuses.PROCESSED]: '#24993E',
  [EmailStatuses.FAILED]: '#C8402F',
  [EmailStatuses.CLICK]: '#24993E',
  [EmailStatuses.RESUBSCRIBED]: '#24993E',
  [EmailStatuses.OPEN]: '#909399',
  [EmailStatuses.DROPPED]: '#C8402F',
  [EmailStatuses.BLOCKED]: '#C8402F',
  [EmailStatuses.BOUNCE]: '#C8402F',
})[status];

const EmailTable = ({ keyExtractor, cols, ordering }) => {
  const emails = useSelector(emailsSelector);
  const loading = useSelector(emailsLoadingSelector);
  const emailInfoModal = useModalState();
  const templates = useSelector(templateSelector);
  const emailViewModal = useModalState();
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState({});
  const { t, p } = useTranslation('notification_history');

  const createEmailTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'email'}>{t('labels.email')}</SortableTitle>,
      dataIndex: 'email',
      className: `notification-history-cell-email`,
      key: 'email',
      render: (email) => <FlexibleText text={email} maxWidth={130}>{email}</FlexibleText>
    },
    {
      title: () => <SortableTitle name="template_id" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (id, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_EMAILS}><Link to={`${Paths.TEMPLATES_EMAIL}/code/${data.template_id}?view=1`}>{templates?.email?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      className: `notification-history-cell-date`,
      key: 'created_at',
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      className: `notification-history-cell-status`,
      key: 'status',
      render: (status) => {
        return (
          <StatusCell status={resolveStatus(status, t)} color={resolveColor(status)} />
        )
      }
    },
    // {
    //   title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
    //   dataIndex: 'r',
    //   key: 'response',
    //   render: (r, { s }) => {
    //     if (!r || r === '[]') {
    //       return null;
    //     }
    //
    //     return (
    //       <Dropdown
    //         trigger={['hover']}
    //         overlay={(
    //           <Overlay>
    //             <pre>
    //               {tryHumanizeResponse(r)}
    //             </pre>
    //           </Overlay>
    //         )}
    //       >
    //         <div>
    //           <Icon color={resolveColor(s)} name="Info-icon" size={16} />
    //         </div>
    //       </Dropdown>
    //     )
    //   },
    // },
  ];

  const createActionColumn = (callbacks) => ({
    title: () => <SortableTitle right={true}>{t('labels.actions')}</SortableTitle>,
    dataIndex: '',
    key: 'actions',
    className: `notification-history-cell-action`,
    fixed: 'right',
    render: (f, data) => {
      return (
        <EmailActions
          onEmailViewClick={() => callbacks.onEmailViewClick(data)}
          onEmailInfoClick={() => callbacks.onEmailInfoClick(data)}
        />
      )
    }
  });

  const emailExtraColumns = [
    {
      title: () => <SortableTitle>{t('labels.updated_at')}</SortableTitle>,
      dataIndex: 'updated_at',
      className: `notification-history-cell-updated_at`,
      key: 'updated_at',
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle>{t('labels.group')}</SortableTitle>,
      dataIndex: 'group',
      className: `notification-history-cell-group`,
      key: 'group',
      render: (group, { gn }) =>  <FlexibleText text={gn} maxWidth={200}><WithPermissions name={UserPermissions.REVIEW_UNSUBSCRIBE_GROUPS}><Link to={`${Paths.SUBSCRIPTION_GROUPS}/${group}`}>{gn}</Link></WithPermissions></FlexibleText>
    },
  ]

  const handleEmailInfoClick = (data) => {
    setCurrentEmail(data);
    emailInfoModal.open();
  }

  const handleEmailViewClick = (data) => {
    setCurrentEmail(data);
    emailViewModal.open();
  }

  console.log('templates', templates)

  const columns = createEmailTableColumns(ordering);

  emailExtraColumns.forEach((args) => cols.indexOf(args.key) !== -1 && columns.push(args));

  columns.push(createActionColumn({
    onEmailViewClick: handleEmailViewClick,
    onEmailInfoClick: handleEmailInfoClick,
  }));

  return (
   <>
     <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_EMAIL} hidden>
       <Table
         loading={loading}
         scroll={{ x: true }}
         rowClassName={(record) => `notification-history-row-${record.id}`}
         columns={columns}
         key={emails.data?.map?.(extract('id')).join()}
         dataSource={[...emails.data || []]}
         rowKey={keyExtractor}
       />
     </WithPermissions>
     <EmailInfoModal
       opened={emailInfoModal.opened}
       onClose={emailInfoModal.close}
       data={currentEmail || {}}
     />
     <ViewEmailTemplateModal
       opened={emailViewModal.opened}
       name={templates?.email?.find(e => e.id === currentEmail?.template_id)?.name}
       html={currentEmail.html}
       onActionClick={() => navigate(`${Paths.TEMPLATES_EMAIL}/code/${currentEmail.template_id}`)}
       onClose={emailViewModal.close}
     />
   </>
  )
}

export default  EmailTable;

