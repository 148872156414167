import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const Container = styled(animated.div)`
  position: fixed;
  height: 96vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  left: 70px;
  z-index: 10001;
  width: 275px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #DCDFE6;
  background: #F9FBFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  transition: left 300ms ease;
  // ${({ $opened }) => $opened ? '' : 'display: none;'}
  ${({ sidebarOpened }) => sidebarOpened ? 'left: 240px;' : 'left: 70px;'}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 10000;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 18px;
  line-height: 21px;
`;

export const CollapseButton = styled.div`
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: #F0F2F6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const Badge = styled.div`
  background: #E5E8ED;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 10px;
  color: #303133;
  font-family: Rubik-Regular;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
  padding: 16px 10px 10px 10px;
  background: #F9FBFF;
  z-index: 10;
  //border-bottom: 1px solid #DCDFE6;;
`;

export const ReadAll = styled.div`
  color: #109DFB;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  position: absolute;
  z-index: 12;
  top: 70px;
  right: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(220, 223, 230, 1);
`;

export const Items = styled.div`
  padding: 4px 10px 0 10px;
`;

export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 50px);
  position: relative;
`;

export const Hint = styled.div`
  color: #909399;
  cursor: pointer;
  user-select: none;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  padding: 10px 0 14px 0;
  width: 100%;
  text-align: center;
`;
