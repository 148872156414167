import styled from 'styled-components';
import { Device } from '@res';

export const Container = styled.div`
  background-color: #F0F2F6;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;

  @media screen and ${Device.desktopS} {
    padding: 20px 260px;
  }
`;

export const NotFoundContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  & > div {
    font-size: 22px;
  }
`;

