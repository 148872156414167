import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useEffect } from 'react';

const usePagination = ({ prefix = '', startFromPage = 0, defaultPerPage = 20 } = {}) => {
  const [page, setPage] = useQueryParam(prefix + 'page', withDefault(NumberParam, startFromPage));
  const [perPage, setPerPage] = useQueryParam(prefix + 'per-page', withDefault(NumberParam, defaultPerPage));

  useEffect(() => {
    if (isNaN(page)) {
      setPage(0);
    }
    if (isNaN(perPage)) {
      setPerPage(20);
    }
  }, [page, perPage]);

  return {
    page,
    perPage,
    handlers: {
      onPageChange: setPage,
      onPerPageChange: setPerPage,
    },
  };
};

export default usePagination;
