import React, { useContext } from 'react';

import { SidebarIcon } from '../SidebarIcon';
import { Container, Title } from './styled';
import { UserPermissions, WorkflowIcons } from '@constants';
import { WithPermissions } from '@components';
import { WorkflowEditorContext } from '@contexts';
import { Tooltip } from 'antd';
import { testId } from '@utils';

const SidebarItem = ({ type, title, name, onClick, expand, addOpacity }) => {
  const editor = useContext(WorkflowEditorContext);
  const handleClick = () => {
    onClick(type, name);
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({ name, type }));
    editor.setDroppable({ name, type });
    event.dataTransfer.effectAllowed = 'move';
  };

  const getPermissionName = () => {
    let permission = '';
    switch (name) {
      case 'sendSmsComponent':
        permission = UserPermissions.CREATE_SMS_NODES;
        break;
      case 'sendEmailComponent':
        permission = UserPermissions.CREATE_EMAIL_NODES;
        break;
      case 'webPushComponent':
        permission = UserPermissions.CREATE_WEBPUSH_NODES;
        break;
      case 'apiRequestComponent':
        permission = UserPermissions.CREATE_API_REQUEST_NODES;
        break;
      default:
        permission = '';
    }

    return {
      permissionName: permission,
      shouldCheck: name === 'sendSmsComponent' || name === 'sendEmailComponent' || name === 'webPushComponent' || name === 'apiRequestComponent',
    }
  }

  const { shouldCheck, permissionName } = getPermissionName();

  return (
   !shouldCheck ? (
     <Container
       {...testId(`workflow-editor-sidebar-item-${title}`)()}
       $addOpacity={addOpacity}
       $opened={expand}
       draggable={!addOpacity}
       onDragStart={handleDragStart}
       onClick={handleClick}
     >
       {expand ? (
         <SidebarIcon
           type={type}
           icon={WorkflowIcons[name]}
         />
       ) : (
         <Tooltip title={title}>
           <div />
           <SidebarIcon
             type={type}
             icon={WorkflowIcons[name]}
           />
         </Tooltip>
       )}
       <Title>
         {title}
       </Title>
     </Container>
   ) : (
     <WithPermissions name={permissionName}>
       <Container
         $addOpacity={addOpacity}
         $opened={expand}
         draggable={!addOpacity}
         onDragStart={handleDragStart}
         onClick={handleClick}
       >
         {expand ? (
           <SidebarIcon
             type={type}
             icon={WorkflowIcons[name]}
           />
         ) : (
           <Tooltip title={title}>
             <SidebarIcon
               type={type}
               icon={WorkflowIcons[name]}
             />
           </Tooltip>
         )}
         <Title>
           {title}
         </Title>
       </Container>
     </WithPermissions>
   )
  );
};

export default SidebarItem;
