import styled from 'styled-components';

export const Container = styled.div`
  width: 120px;
  background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
  padding: 0 2px;
  height: 26px;
  display: flex;
  align-items: center;
`;
