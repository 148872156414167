import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone';
import { ActionButton, Icon, Slider, WithOnboarding, OnboardingHighlight } from '@components';

import {
  Container,
  Label,
  Editor,
  ImagePlaceholder,
  Settings,
  EditButton,
} from './styled';
import { useOnboarding, useTranslation } from '@hooks';

const ImageEditor = ({ value, label, onChange, onImageChange, editable, avatarEditorRef }) => {
  const [sourceImage, setSourceImage] = useState(value);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const onboarding = useOnboarding('workspaces');
  const { p, t } =  useTranslation('common')
  const dropZoneRef = useRef();

  useEffect(() => {
    if (!!sourceImage && sourceImage !== value) {
      onImageChange?.(value);
    }
    setSourceImage(value);
  }, [value]);

  const handleDrop = (dropped) => {
    setSourceImage(dropped[0]);
    onImageChange?.(dropped[0]);
  };

  const handleRotate = () => {
    setRotate(r => r + 90);
  };

  const handleEditClick = () => {
    dropZoneRef.current?.open();
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Editor>
        <Dropzone
          onDrop={handleDrop}
          noClick={!!sourceImage}
          noKeyboard
          ref={dropZoneRef}
          style={{ width: '250px', height: '250px' }}
        >
          {({ getRootProps, getInputProps }) => (
            <div style={{ position: 'relative', height: '140px' }} {...getRootProps()}>
              {sourceImage ? (
                <div style={{ pointerEvents: !editable ? 'none' : 'auto' }}>
                  <AvatarEditor
                    ref={avatarEditorRef}
                    image={sourceImage}
                    width={120}
                    height={120}
                    border={10}
                    borderRadius={60}
                    color={[255, 255, 255, 1]}
                    scale={zoom}
                    rotate={rotate}
                  />
                </div>
              ) : (
                <ImagePlaceholder>
                  <Icon color="#fff" size={36} name="Plus-icon" />
                </ImagePlaceholder>
              )}
              <WithOnboarding enabled={onboarding.enabled(4)} text={p('also_you_can_upload_your_image')} next onboardKey="workspaces">
                  <EditButton onClick={handleEditClick}>
                    <OnboardingHighlight background="transparent" top={6} bottom={6} left={6} right={6} on={onboarding.enabled(4)}>
                      <Icon name="Edit-icon" size={14} color="#fff" />
                    </OnboardingHighlight>
                  </EditButton>
              </WithOnboarding>
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </Editor>
      <Settings disabled={!editable}>
        <Slider
          value={zoom}
          onChange={setZoom}
          max={3}
          min={1}
          step={0.1}
          style={{ borderRadius: '4px', marginRight: '12px' }}
        />
        <ActionButton size={26} iconSize={16} icon="Rotate-right" onClick={handleRotate} />
      </Settings>
    </Container>
  );
};

export default ImageEditor;
