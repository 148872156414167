import React, { useContext, useEffect, useState } from 'react';

import {Container, NoIntegrationDisclaimer, OutlinedButton, Row} from './styled';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { useTranslation } from '@hooks';
import {useDispatch, useSelector} from "react-redux";
import {
  facebookAudiencesSelector,
  myIntegrationsSelector
} from "@store/selectors";
import { ActionButton, AutoComplete, FieldsMappingEditor, Input, SearchSelect, Select, Textarea } from "@components";
import { diffFields, removeAppFromPath, testId, update } from "@utils";
import {
  getFacebookAudiences,
  getFieldSettingsList,
  myListIntegrations, updateRedirects
} from "@store/actions/creators";
import {
  FooterOptionLabel
} from "@components/lib/SegmentEditor/components/FilterEditor/components/AggregateSelect/styled";
import { AppRedirects, Paths } from "@constants";
import {useNavigate} from "react-router";
import {
  FooterOption
} from "@components/lib/WorkflowEditor/components/NodeSettingsModal/components/ViberNodeSettings/styled";
import { WorkflowEditorContext } from '@contexts';
import { useLocation } from 'react-router-dom';
import { WorkflowActionTypes } from '@store/actions/types';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';

const getAudienceActions = (p) => [
  { label: p('add_to_audience'), value: 'add' },
  { label: p('remove_from_audience'), value: 'remove' },
  { label: p('remove_from_all_audiences'), value: 'remove_from_all' },
]

const RetargetingNodeSettings = ({
  compareWithValue,
  value,
  description,
  label,
  errors,
  editable = true,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
}) => {
  const { t, p } = useTranslation('workflow_page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const facebookAudiences = useSelector(facebookAudiencesSelector);

  const selectedIntegration = (options?.facebookIntegrations || []).find(i => i.id === value.integration_id);

  useEffect(() => {
    if (value.audience_id) {
      onChange(s => ({ ...s, fields: selectedIntegration.config.fields_mapping }))
    }
  }, [value.audience_id]);

  useEffect(() => {
    dispatch(myListIntegrations());
    dispatch(getFieldSettingsList());
  }, [])

  useEffect(() => {
    if (value.integration_id) {
      dispatch(getFacebookAudiences({ app_integration_id: value.integration_id, clearAll: true }))
    }
  }, [value.integration_id])

  const audienceActions = getAudienceActions(p);

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  const handleSelectAudience = (aud, sec, opt) => {
    onChange(s => ({ ...s, audience_id: aud, audience_name: opt.label, need_create: false }));
  }

  const handleCreateNew = () => {
    const f = value.fields || [{ field: 'Gender', mapTo: 'GEN' }];
    onChange(s => ({ ...s, audience_name: '', need_create: true, fields: f }))
  }

  const handleCreateIntegrations = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.INTEGRATION_CONNECTION_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'integration_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.INTEGRATIONS}/?name=facebook_instagram_ads&type=category`)
  }

  const handleUpdateFields = (fields) => {
    onChange(s => ({ ...s, fields: update(s.fields || [], fields) }));
  };

  const handleSelectAction = (a) => {
    onChange(s => ({ ...s, action: a }));
  }

  const handleSetAudienceName = (e) => {
    onChange(s => ({ ...s, audience_name: e.target.value, audience_id: null, need_create: true }))
  }

  const handleSelectIntegration = (i) => {
    onChange(s => ({ ...s, integration_id: i }))
  }

  const handleScrollDown = () => {
    if (facebookAudiences.data.audiences.paging.next && !facebookAudiences.loading) {
      dispatch(getFacebookAudiences({ app_integration_id: value.integration_id, after: facebookAudiences.data.audiences.paging.cursor.after }))
    }
  }

  const getCustomValue = () => {
    if (value.audience_id && !facebookAudiences?.data?.audiences?.data?.find(a => a.id === value.audience_id)) {
      return value.audience_name;
    }

    return value.need_create ? t('labels.create_new_audience') : ''
  }

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            {!options.facebookIntegrations?.length && (
              <>
                <NoIntegrationDisclaimer>{p('to_set_up_retargeting_node')}</NoIntegrationDisclaimer>
                <OutlinedButton onClick={handleCreateIntegrations}>{p('integrate_facebook_audiences')}</OutlinedButton>
              </>
            )}
            {!!options.facebookIntegrations?.length ? (
              <>
                <SearchSelect
                  onChange={handleSelectIntegration}
                  label={t('labels.integration')}
                  style={{ width: '100%' }}
                  error={errors.integration_id}
                  value={value.integration_id || null}
                  options={options.facebookIntegrations?.map?.(i => ({ label: i.name, value: i.id })) || []}
                />
                {!!value.integration_id && (
                  <SearchSelect
                    onChange={handleSelectAction}
                    containerStyle={{ marginTop: 16 }}
                    style={{ width: '100%' }}
                    error={errors.action}
                    label={p('action_with_audience')}
                    value={value.action || null}
                    options={audienceActions}
                  />
                )}
                {!!value.integration_id && (
                  <SearchSelect
                    onChange={handleSelectAudience}
                    containerStyle={{ marginTop: 16 }}
                    style={{ width: '100%' }}
                    onScrollDown={handleScrollDown}
                    // onScroll={(args) => {}}
                    error={errors.audience_id}
                    label={p('audience')}
                    value={value.audience_id || null}
                    footerStyles={{ bottom: '-10px', padding: '8px 10px 0px 10px', height: 32 }}
                    options={facebookAudiences?.data?.audiences?.data?.map(a => ({ label: a.name, value: a.id })) || []}
                    customValue={getCustomValue()}
                    footerOption={(
                      <FooterOption style={{ padding: 0, border: 'none' }} onClick={handleCreateNew}>
                        <ActionButton size={22} icon="Plus-icon" />
                        <FooterOptionLabel>
                          {t('labels.create_new_audience')}
                        </FooterOptionLabel>
                      </FooterOption>
                    )}
                  />
                )}
                {!!value.need_create && (
                  <Input
                    onChange={handleSetAudienceName}
                    wrapperStyles={{ marginTop: 16 }}
                    style={{ width: '100%' }}
                    error={value.need_create ? errors.audience_name : ''}
                    title={p('new_audience_name')}
                    value={value.audience_name || ''}
                  />
                )}
              </>
            ) : null}
          </>
        )}
        matchingPreset={(
          <>
            {!!options.facebookIntegrations?.length ? <FieldsMappingEditor error={errors.fields} value={value.fields || []} onChange={handleUpdateFields} /> : null}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('retargeting-node-settings-segment-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              maxLength={40}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('retargeting-node-settings-segment-description')()}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default RetargetingNodeSettings;
