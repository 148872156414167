import React from 'react';

import { Container } from './styled';
import { useTranslation } from '@hooks';

const resolveType = (type) => {
  switch (type) {
    case 1:
      return 'types.dynamic';
    case 2:
      return 'types.custom';
    case 3:
      return 'System';
    default:
      return 'Unknown';
  }
};

const EventType = ({ type }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {t(resolveType(type))}
    </Container>
  );
};

export default EventType;
