import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
`;

export const Text = styled.div`
  font-family: Rubik-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
  margin: 30px 0;
`;
