import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { by, clamp, formatNumberString, renderProp, sumOf, zeroIfNaN } from '@utils';
import { LegendItem } from '@components';

import { Container, Svg, Title, TooltipContainer, TooltipValue } from './styled';

const TOOLTIP_HEIGHT = 40;
const TOOLTIP_WIDTH = 180;

const SEGMENT_RADIUS_INNER = 56;
const SEGMENT_RADIUS_OUTER = 100;
const TOTAL_RADIUS_INNER = 66;
const TOTAL_RADIUS_OUTER = 100;

const START_DEG = 220;

const defaultAppearance = {
  size: 200,
  segmentRadiusInner: SEGMENT_RADIUS_INNER,
  segmentRadiusOuter: SEGMENT_RADIUS_OUTER,
  totalRadiusInner: TOTAL_RADIUS_INNER,
  totalRadiusOuter: TOTAL_RADIUS_OUTER,
  startDeg: START_DEG,
  borderDeg: 0.8,
};

const PieChart = ({
  total,
  segments,
  k,
  style,
  title,
  showZeroValues = true,
  tooltipPlacement = 'left',
  appearance = defaultAppearance,
}) => {
  const ref = useRef();
  const [hovered, setHovered] = useState(null);
  const sum = segments.reduce(sumOf('value'), 0);
  const totalForCircle = Math.max(zeroIfNaN(sum), zeroIfNaN(total));
  const coef = 360 / totalForCircle;
  const ap = { ...defaultAppearance, ...appearance };

  const mappedClamped = segments.map(({ value, color, ...rest }) => ({
    ...rest,
    color,
    value,
    percentageDeg: clamp(zeroIfNaN(value * coef), 6, 360),
  })).filter(({ value }) => !!value || showZeroValues);

  const clampCoef = 360 / (mappedClamped.reduce(sumOf('percentageDeg'), 0) + (mappedClamped.length * (ap.borderDeg * 2)));

  const mapped = mappedClamped.map(s => ({
    ...s,
    percentageDeg: s.percentageDeg * clampCoef,
  }));

  const sumDeg = mapped.reduce(sumOf('percentageDeg'), ap.borderDeg * 2 * mapped.length);

  const totalStart = ap.startDeg + sumDeg + ap.borderDeg;
  const totalEnd = ap.startDeg + 360 - (ap.borderDeg * 2);
  const totalSegment = { color: '#E5E8ED', totalStart, totalEnd, id: 'total', isTotal: true };

  useEffect(() => {
    const svg = d3.select(ref.current)
      .attr('width', ap.size)
      .attr('height', ap.size)
      .style('background', 'transparent');

    svg.append('g')
      .attr('width', ap.size)
      .attr('height', ap.size)
      .attr('transform', `translate(${ap.size / 2},${ap.size / 2})`);

    const arcs = svg.select('g').selectAll('.arc')
      .data(mapped.concat(totalStart < totalEnd ? [totalSegment] : []), ({ color }) => color);

    arcs.enter()
      .append('path')
      .attr('class', 'arc')
      .attr('id', ({ id }) => `seg_${k}_${id}`)
      .merge(arcs)
      .attr('d', ({ value, color, percentageDeg, totalStart, totalEnd, isTotal }, index) => {
        const before = mapped.filter((_, i) => i < index);
        const start = ap.startDeg + before.reduce(sumOf('percentageDeg'), before.length * ap.borderDeg * 2) + ap.borderDeg;
        const endRough = start + percentageDeg - ap.borderDeg;
        const offsetStart = 360 + ap.startDeg - ap.borderDeg;
        const end = clamp(endRough, 0, offsetStart);

        const startAngle = (isTotal ? totalStart : start) * Math.PI / 180;
        const endAngle = (isTotal ? totalEnd : end) * Math.PI / 180;

        const arcGenerator = d3.arc()
          .innerRadius(isTotal ? ap.totalRadiusInner : ap.segmentRadiusInner)
          .outerRadius(isTotal ? ap.totalRadiusOuter : ap.segmentRadiusOuter)
          .cornerRadius(4)
          .startAngle(startAngle)
          .endAngle(endAngle);

        return arcGenerator();
      })
      .attr('fill', ({ color }) => color)
      .on('mouseenter', (_, { id }) => {
        setHovered(id);
      })
      .on('mouseleave', () => {
        setHovered(null);
      })
      .exit();

    return () => {
      svg.selectAll('g').remove();
    };
  }, [mapped, totalSegment]);

  const hoveredSegment = mapped.find(by(hovered));
  const hoveredEl = document.querySelector(`#seg_${k}_${hovered}`);

  const getPosition = (child, parent) => {
    if (!child) {
      return { x: 0, y: 0 };
    }

    const childRect = child?.getBoundingClientRect?.()
    const parentRect = parent?.getBoundingClientRect?.();

    if (tooltipPlacement === 'left') {
      return {
        x: (childRect?.left - parentRect?.left) - TOOLTIP_WIDTH - 10,
        y: (childRect?.top - parentRect?.top) + (childRect?.height / 2) - (TOOLTIP_HEIGHT / 2),
      };
    }

    return {
      x: (childRect?.right - parentRect?.left) + 10,
      y: (childRect?.top - parentRect?.top) + (childRect?.height / 2) - (TOOLTIP_HEIGHT / 2),
    };
  }

  const ttpos = getPosition(hoveredEl, ref.current);

  return (
    <Container $s={ap.size} style={style}>
      <Svg ref={ref}></Svg>
      {renderProp(title || (<Title>{sum}/{total}</Title>))}
      {(hovered !== null && hovered !== 'total') && (
        <TooltipContainer $x={ttpos.x} $y={ttpos.y}>
          <LegendItem name={hoveredSegment?.name} color={hoveredSegment?.color} />
          <TooltipValue>{formatNumberString(hoveredSegment?.value)}</TooltipValue>
        </TooltipContainer>
      )}
    </Container>
  );
};

export default PieChart;
