import styled from 'styled-components';
import { extract } from '@utils';

export const Container = styled.span`
  display: inline-flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  margin: 0 3px;
  padding: 3px 5px;
  border-radius: 4px;
  background: var(--background, #F0F2F6);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  color: var(--black-text, #303133);
  font-family: Rubik-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Icon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: ${extract('$bg')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
