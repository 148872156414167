import styled, { css, keyframes } from 'styled-components';
import { extract, styledProp } from '@utils';

const an = () => keyframes`
  0% {
    box-shadow: 0 0 0 0 transparent, 0 0 0 transparent;
  }
  50% {
    box-shadow: 0 0 6px rgb(0 0 0 / 30%)
  }
  100% {
    box-shadow: 0 0 0 0 transparent, 0 0 0 transparent;
  }
`;

export const Container = styled.div`
  position: relative;
  overflow: visible;
  border-radius: 9px;
  background: transparent;
  z-index: 0;
`;

const fadeIn = ({ top, bottom, left, right }) => keyframes`
  0% {
    top: ${-1 * top}px;
    left: ${-1 * left}px;
    right: ${-1 * right}px;
    bottom: ${-1 * bottom}px;
    border-width: 0px;
  }
  100% {
    top: ${-1 * top - 3}px;
    left: ${-1 * left - 3}px;
    right: ${-1 * right - 3}px;
    bottom: ${-1 * bottom - 3}px;
    border-width: 3px;
  }
`;

export const Border = styled.div`
  border-radius: ${extract('radius')}px;
  position: absolute;
  background: ${extract('color')};
  z-index: -1;
  animation: ${fadeIn} 280ms ease-in forwards, ${an} 5s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s infinite normal none running;
  
  border: 3px solid #BDD1F6;
  
  ${styledProp('sidebar', css`
    border: 2px solid #1F4999;
    border-radius: 7px;
  `)}
  
  ${styledProp('on', css``, css`
    display: none;
  `)}
`;

export const Children = styled.div`
`;
