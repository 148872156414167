import { RolesActionTypes } from '../types';
import { compose } from '@utils';
import { asCreator, withCallbacks, withPagination } from '@store/lib';

export const getRoles = compose(
  withPagination,
  asCreator(() => ({ type: RolesActionTypes.GET_ROLES })),
);

export const deleteRole = compose(
  withCallbacks,
  asCreator(({ id }) => ({ type: RolesActionTypes.DELETE_ROLE, meta: { id } }))
);

export const getPermissions = compose(
  asCreator(() => ({ type: RolesActionTypes.GET_PERMISSIONS }))
);

export const getRole = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RolesActionTypes.GET_ROLE,
    meta: { id },
  }))
)

export const updateRole = compose(
  withCallbacks,
  asCreator((args) => ({
    type: RolesActionTypes.UPDATE_ROLE,
    payload: { ...args },
  }))
)

export const getRolesList = compose(
  asCreator(() => ({
    type: RolesActionTypes.GET_ROLES_LIST,
  }))
)
