import { useDispatch, useSelector } from 'react-redux';
import { appRedirectsSelector } from '@store/selectors/app';
import { updateRedirects } from '@store/actions/creators';
import { useNavigate } from '@hooks';

const parseMapConfig = (cfg, data) => cfg.reduce(
  (acc, [from, to, transform]) => ({ ...acc, [to]: transform ? transform(data[from]) : data[from] }),
  {}
);

const useAppRedirects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirects = useSelector(appRedirectsSelector);

  return ({ trigger, fallback, action = 'push', data, fallbackAction = 'push', custom }) => {
    const { to, updateAction, mapConfig, updateMeta } = (redirects[trigger] || {});
    const route = to || fallback;

    if (updateAction) {
      dispatch({
        type: updateAction,
        payload: {
          data: mapConfig ? parseMapConfig(mapConfig, data) : data,
        },
        meta: updateMeta,
      });
    }

    dispatch(updateRedirects({ [trigger]: null }));
    if (!route && fallbackAction !== 'goBack' && action !== 'goBack' ) {
      return;
    }

    if (custom) {
      custom(route);
      return;
    }

    if ((to && action === 'replace') || (!to && fallbackAction === 'replace')) {
      navigate(route, { replace: true });
    }

    if ((to && action === 'push') || (!to && fallbackAction === 'push')) {
      navigate(route);
    }

    if (!to && fallbackAction === 'goBack') {
      navigate(-1);
    }
  };
};

export default useAppRedirects;

