import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  cursor: pointer;
  padding: 0 14px;
  display: flex;
  background: transparent;
  border: 1px solid #303133;
  border-radius: 9px;
  align-items: center;
  height: 36px;
  ${styledProp('$opened', `
    background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
    border: 1px solid #DCDFE6;
  `)}
`;

export const OverlayContent = styled.div`
  overflow-y: auto;
  max-height: calc(70vh + 4px);
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 0;

  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    border-radius: 10px;
    padding: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(240, 242, 246, 1);
    border-radius: 30px;
    padding: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #F0F2F6;
    border-radius: 30px;
    padding: 20px;
  }
`;

export const IconContainer = styled.div`
  transition: all 280ms ease;
  margin-left: 10px;
  color: #303133;
  ${styledProp('$opened', 'transform: rotateX(180deg);')}
`;

export const Locked = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 22px;
  padding: 2px 0px;
  flex: 1 1 0%;
    
  & > span:last-child {
    padding-left: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #303133;
  }
`;

export const Option = styled.div`
  padding: 2px 14px;
  display: flex;
  background: #fff;
`;
