import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { WorkflowEditorContext } from '../../../../../contexts';

import { Overlay, InfoTitle } from './styled.js';
import { NodeSettingsInfo } from './components';
import { useTranslation } from '@hooks';

const NodeSettingsDropdown = ({ children, node, options, forceClose }) => {
  const editor = useContext(WorkflowEditorContext);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    if (forceClose) {
      setVisible(false);
    }
  }, [forceClose]);

  return (
    <Dropdown
      trigger={['hover']}
      open={visible && !editor.isDragging && !forceClose}
      onOpenChange={setVisible}
      disabled={editor.isDragging}
      mouseEnterDelay={0.5}
      overlay={(
        <Overlay>
          {node?.data ? (
            <NodeSettingsInfo node={node} options={options} />
          ) : (
            <InfoTitle>{t('labels.no_settings')}</InfoTitle>
          )}
        </Overlay>
      )}
    >
      <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
        {children}
      </div>
    </Dropdown>
  );
}

export default NodeSettingsDropdown;


