import React, { useContext, useEffect, useState } from 'react';
import { ActionButton, Input, SearchSelect, Select, Textarea } from '@components';

import { Container, Row, FilterContainer, DeleteRuleButton, AddAttributeButton, Error } from './styled';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { by, diffFields, generateRandomName, testId, update, validateSegment } from '@utils';
import {
  FooterOption,
  FooterOptionLabel
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/ResourceNodeSettings/styled';
import {
  clearFilterResult, getAggregates, getFieldSettingsList,
  getSegmentsOptions,
  getSubscriptionGroups,
  listEvents,
  updateRedirects
} from '@store/actions/creators';
import { AppRedirects, Paths, SegmentsRuleTypes } from '@constants';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { WorkflowEditorContext } from '../../../../../../../contexts';
import { FilterRule } from '@components/lib/SegmentEditor/components/FilterEditor/components';
import { FiltersDropdown } from '@components/lib/SegmentEditor/components/FiltersDropdown';
import { v4 as uuid } from 'uuid';
import { useTranslation, useNavigate } from '@hooks';
import { WorkflowActionTypes } from '@store/actions/types';
import { useLocation } from 'react-router-dom';
import { segmentsOptionsSelector } from '@store/selectors';

const filterOptions = [
  { value: 'segment', label: 'labels.segment' },
  { value: 'rule', label: 'labels.attribute' },
];

const DefaultFilterNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  editable = true,
  onClearError,
  compareWithValue,
}) => {
  const { t, p } = useTranslation('workflow_page')
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const segmentOptions = useSelector(segmentsOptionsSelector);
  const templateOptions = segmentOptions.segments
    ?.map?.(({ id, name }) => ({ value: id, label: name }));
  const [displayErrors, setDisplayErrors] = useState(true);

  useEffect(() => {
    dispatch(clearFilterResult());
    dispatch(getSegmentsOptions());
    dispatch(getFieldSettingsList());
    dispatch(getSubscriptionGroups({ entity: 'EMAIL' }));
    dispatch(listEvents());
    dispatch(getAggregates());
  }, []);

  useEffect(() => {
    if (errors.query) {
      setDisplayErrors(true);
    }
  }, [errors.query]);

  useEffect(() => {
    if (!value?.rule_segment_id && value?.filter_by === 'rule') {
      onChange(s => ({ ...s, rule_segment_id: uuid() }));
    } else if (id === 'new' && value?.filter_by !== 'rule') {
      onChange(s => ({ ...s, rule_segment_id: void 0 }));
    }
  }, [value, id]);

  const handleTypeChange = (filter_by) => {
    onClearError('filter_by');
    onClearError('query');
    onChange(s => ({ ...s, filter_by }));
    if (filter_by === 'rule') {
      onChange(s => ({ ...s, filter_by, query: {}, aggregates: [], funnels: [], expressions: [] }));
    }
  };

  const handleTemplateChange = (segment_id) => {
    onClearError('segment_id');
    onChange(s => ({ ...s, segment_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleQueryChange = (updater) => {
    setDisplayErrors(false);
    onClearError('query');

    const segment = validateSegment({
      type: 'query-builder-group',
      funnels: [],
      aggregates: [],
      ...value,
      query: {
        name: 'root',
        root: true,
        children: [update(value.query?.children?.[0], updater)],
        logicalOperator: 'and'
      }
    })

    onChange(value => ({ ...value, ...segment, query: segment.query }));
  };

  const handleAggregatesChange = (updater) => {
    onClearError('query');
    onChange(v => ({ ...v, query: { ...v.query, aggregates: update(v.query?.aggregates || [], updater)} }));
  };

  const handleSelectAttribute = (field, ruleType) => {
    onClearError('query');
    const newValue = { ...value };

    const query = {
      ruleType,
        negation: false,
        name: 1,
        value: {
        type: 'scalar',
          value: '',
      },
      field: ruleType === SegmentsRuleTypes.EVENT ? field.name : field?.field,
        filters: [],
    };
    if (ruleType === SegmentsRuleTypes.ARRAY || ruleType === SegmentsRuleTypes.EVENT) {
      query.logicalOperator = 'and';
      query.type = 'common';
      if (ruleType === SegmentsRuleTypes.ARRAY) {
        query.filters = [{ field: '', operator: '', value: { type: 'scalar' } }];
      }
      if (ruleType === SegmentsRuleTypes.EVENT) {
        query.wasPerformed = true;
      }
    }

    if (ruleType === SegmentsRuleTypes.AGGREGATE && field) {
      query.aggregateId = field.id;
      query.field = field.label;
      newValue.aggregates = [...(newValue.aggregates || []), field];
    }

    if (ruleType === SegmentsRuleTypes.AGGREGATE && !field) {
      const id = uuid();
      const label = `Quick aggregate`;

      query.field = label;
      query.aggregateId = id;
      newValue.aggregates = [
        ...(newValue.aggregates || []), {
        createdId: id,
        label,
        name: generateRandomName(),
        logicalOperator: 'and',
        type: '',
        filters: [],
        attribute: null,
        aggregate: 'count',
        aggregateMeta: {},
      }];
    }

    onChange(v => ({ ...v, ...newValue, query: validateSegment({
        type: 'query-builder-group',
        funnels: [],
        name: 'New segment',
        aggregates: [],
        ...newValue,
        query: {
          name: 'root',
          root: true,
          children: [{ type: 'query-builder-rule', query }],
          logicalOperator: "and"
        }
      }).query }));
  };

  const handleDeleteRule = () => {
    onClearError('query');
    onChange(v => ({ ...v, query: {} }));
  }

  const handleCreateNew = () => {
    dispatch(updateRedirects({
      [AppRedirects.SEGMENT_CREATION]: {
        to: `${window.location.pathname}?loadCached`,
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'segment_id'], ['type', 'filter_by', v => v.toLowerCase()]],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    editor.saveCachedNodes(worker || 'new');
    navigate(`${Paths.RESOURCES_SEGMENTS}/new`);
  }

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};
  const showQueryErrors = !!errors['query'];

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <div style={{ overflowY: 'auto', maxHeight: 900 }}>
            <SearchSelect
              {...testId('default-filter-node-settings-required-filter-by')()}
              highlight={diff['filter_by'] === false}
              wrapperStyles={{ width: '100%', marginBottom: '16px' }}
              style={{ background: '#fff', width: '100%'}}
              value={value.filter_by}
              onChange={handleTypeChange}
              options={filterOptions}
              error={errors['filter_by']}
              label={t('labels.filter_by')}
              title={t('actions.select')}
            />
            {value.filter_by === 'segment' && (
              <Row style={{ alignItems: 'flex-end' }}>
                <SearchSelect
                  {...testId('default-filter-node-settings-required-filter-by-segment')()}
                  highlight={diff['segment_id'] === false}
                  disabled={!editable}
                  placeholder={t('labels.select')}
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  error={errors['segment_id']}
                  title={t('labels.segment')}
                  getPopupContainer={t => t.parentElement.parentElement.parentElement.parentElement}
                  value={value.segment_id}
                  footerOption={(
                    <FooterOption onClick={handleCreateNew}>
                      <ActionButton {...testId('default-filter-node-settings-required-filter-by-segment-add')()} size={22} icon="Plus-icon" />
                      <FooterOptionLabel>
                        {p('create_segment')}
                      </FooterOptionLabel>
                    </FooterOption>
                  )}
                  onChange={handleTemplateChange}
                  options={templateOptions || []}
                />
              </Row>
            )}
            {errors['query']?.['filters'] && (
              <Error>
                {errors['query']?.['filters']}
              </Error>
            )}
            {value.filter_by === 'rule' && (
              <>
                {!value.query?.children?.[0]?.query?.ruleType && (
                  <FiltersDropdown disableAggregateCreation onSelect={handleSelectAttribute}>
                    <AddAttributeButton {...testId('default-filter-node-settings-required-filter-by-attribute-add')()}>
                      {p('add_attribute')}
                    </AddAttributeButton>
                  </FiltersDropdown>
                )}
                {!!value.query?.children?.[0]?.query?.ruleType && (
                  <FilterContainer >
                    <FilterRule
                      {...testId('default-filter-node-settings-required-filter-by-attribute-operator')()}
                      disabled={!editable}
                      aggregateCreationDisabled
                      appearance="column"
                      funnels={[]}
                      onAggregateChange={handleAggregatesChange}
                      aggregates={value.aggregates || []}
                      showErrors={displayErrors && showQueryErrors}
                      query={value?.query?.children?.[0]?.query}
                      onChange={handleQueryChange}
                    />
                    <DeleteRuleButton {...testId('default-filter-node-settings-required-filter-by-attribute-delete')()} onClick={handleDeleteRule}>
                      {p('delete_attribute')}
                    </DeleteRuleButton>
                  </FilterContainer>
                )}
              </>
            )}
          </div>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('default-filter-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('default-filter-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default DefaultFilterNodeSettings;
