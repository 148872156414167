import React, { useEffect } from 'react';
import { Button, Modal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@hooks';
import { templatesStatisticsSelector } from '@store/selectors';
import {WebhookStatuses} from '@constants';
import { getTemplateStatistics } from '@store/actions/creators';
import { by, extract, zeroIfNaN } from '@utils';
import uniq from 'lodash.uniq';

import { Header, HeaderText, TotalText, Row, Text, Container } from './styled'
import capitalize from 'lodash.capitalize';

const ApiRequestStatisticsModal = ({ id, opened, onClose }) => {
  const dispatch = useDispatch();
  const { p, t } = useTranslation('templates');

  const statistics = useSelector(templatesStatisticsSelector);
  const s = statistics?.['webhook'] || {};
  const addCustomStatuses = (statuses, compare) => {
    return uniq(statuses.concat((compare || [])));
  }

  const summary = Object.fromEntries(addCustomStatuses(Object.values(WebhookStatuses), Object.keys(s)).map(status => [status, s[status] || 0]) || {});

  useEffect(() => {
    if (!id || !opened) {
      return;
    }
    dispatch(getTemplateStatistics({ template_id: [id], type: 'webhook' }));
  }, [id, opened]);

  const calcPercentage = (value, from) => {
    return from ? zeroIfNaN(value / from * 100).toFixed(2) : '0.00';
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={p('template_statistics')}
      statistics={true}
      actions={(
        <div style={{margin: '0 auto'}}>
          <Button onClick={onClose} appearance="secondary" width={140}>{t('actions.close')}</Button>
        </div>
      )}
    >
      <Container data-testid="templates-sms-stats-modal">
        {(summary?.total !== undefined && summary?.total !== null) && (
          <Row>
            <TotalText>{t('labels.total')}</TotalText>
            <TotalText>{summary?.total}</TotalText>
          </Row>
        )}
        {((summary?.failed !== undefined && summary?.failed !== null) || (summary?.delivered !== undefined && summary?.delivered !== null)) && (
          <Header>
            <HeaderText>{t('labels.status')}</HeaderText>
            <HeaderText>% {p('from_total')}</HeaderText>
            <HeaderText right>{p('amount')}</HeaderText>
          </Header>
        )}
        {summary?.sent !== undefined && summary?.sent !== null && (
          <Row>
            <Text>{t('statuses.sent')}</Text>
            <Text>{calcPercentage(summary?.sent, summary?.total)}</Text>
            <Text right>{summary?.sent}</Text>
          </Row>
        )}
        {summary?.failed !== undefined && summary?.failed !== null && (
          <Row>
            <Text>{p('failed')}</Text>
            <Text>{calcPercentage(summary?.failed, summary?.total)}</Text>
            <Text right>{summary?.failed}</Text>
          </Row>
        )}
        {summary?.delivered !== undefined && summary?.delivered !== null && (
          <Row>
            <Text>{p('delivered')}</Text>
            <Text>{calcPercentage(summary?.delivered, summary?.total)}</Text>
            <Text right>{summary?.delivered}</Text>
          </Row>
        )}
        {summary?.dropped !== undefined && summary?.dropped !== null && (
          <Row>
            <Text>{t('statuses.dropped')}</Text>
            <Text>{calcPercentage(summary?.dropped, summary?.total)}</Text>
            <Text right>{summary?.dropped}</Text>
          </Row>
        )}
        {/*{(summary?.open !== undefined && summary?.open !== null) && (*/}
        {/*  <Header>*/}
        {/*    <HeaderText>{t('labels.status')}</HeaderText>*/}
        {/*    <HeaderText>% {p('from_delivered')}</HeaderText>*/}
        {/*    <HeaderText right>{p('amount')}</HeaderText>*/}
        {/*  </Header>*/}
        {/*)}*/}
        {summary?.open !== undefined && summary?.open !== null && (
          <Row>
            <Text>{p('opened')}</Text>
            <Text>{calcPercentage(summary?.open, summary?.total)}</Text>
            <Text right>{summary?.open}</Text>
          </Row>
        )}
        {/*{((summary?.click !== undefined && summary?.click !== null) || (summary?.spam_report !== undefined && summary?.spam_report !== null) || (summary?.unsubscribed !== undefined && summary?.unsubscribed !== null) || (summary?.resubscribed !== undefined && summary?.resubscribed !== null)) && (*/}
        {/*  <Header>*/}
        {/*    <HeaderText>{t('labels.status')}</HeaderText>*/}
        {/*    <HeaderText>% {p('from_opened')}</HeaderText>*/}
        {/*    <HeaderText right>{p('amount')}</HeaderText>*/}
        {/*  </Header>*/}
        {/*)}*/}
        {summary?.click !== undefined && summary?.click !== null && (
          <Row>
            <Text>{p('click')}</Text>
            <Text>{calcPercentage(summary?.click, summary?.total)}</Text>
            <Text right>{summary?.click}</Text>
          </Row>
        )}
        {summary?.spam_report !== undefined && summary?.spam_report !== null && (
          <Row>
            <Text>{p('spam_report')}</Text>
            <Text>{calcPercentage(summary?.spam_report, summary?.total)}</Text>
            <Text right>{summary?.spam_report}</Text>
          </Row>
        )}
        {summary?.unsubscribed !== undefined && summary?.unsubscribed !== null && (
          <Row>
            <Text>{p('unsubscribed')}</Text>
            <Text>{calcPercentage(summary?.unsubscribed, summary?.total)}</Text>
            <Text right>{summary?.unsubscribed}</Text>
          </Row>
        )}
        {summary?.resubscribed !== undefined && summary?.resubscribed !== null && (
          <Row>
            <Text>{p('resubscribed')}</Text>
            <Text>{calcPercentage(summary?.resubscribed, summary?.total)}</Text>
            <Text right>{summary?.resubscribed}</Text>
          </Row>
        )}
        {Object.entries(summary || {}).filter(([k]) => !['resubscribed', 'unsubscribed', 'spam_report', 'click', 'opened', 'total', 'sent', 'failed', 'delivered', 'dropped'].includes(k)).map(([k, v]) => (
          <Row key={k}>
            <Text>{capitalize(k)}</Text>
            <Text>{calcPercentage(v, summary?.total)}</Text>
            <Text right>{v}</Text>
          </Row>
        ))}
      </Container>
    </Modal>
  );
};

export default ApiRequestStatisticsModal;
