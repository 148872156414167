// import ReactGA from "react-ga-gtm";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGAPageTracking = (urlMatch) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const matches = (location.pathname + location.search).includes(urlMatch);

  useEffect(() => {
    if (urlMatch && !matches) {
      const script1 = document.querySelector('#re-gtm1');
      const script2 = document.querySelector('#re-gtm2');

      try {
        if (script1) {
          document?.removeChild?.(script1);
        }
        if (script2) {
          document?.removeChild?.(script2);
        }
      } catch (e) {
        console.log(e);
      }

      return;
    }

    if ((!window.location.href.includes('local') && !window.location.href.includes('stage'))) {
      const s1 = document.querySelector('#re-gtm1');

      if (s1) {
        return;
      }

      const script1 = document.createElement('script');
      script1.id = 're-gtm1';
      script1.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M8XQPDS');`;
      const script2 = document.createElement('noscript');
      script2.id = 're-gtm2';
      script2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M8XQPDS"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.appendChild(script1);
      document.body.appendChild(script2);
      // ReactGA.initialize([
      //   { trackingId: 'GTM-M8XQPDS', debug: true },
      // ]);
    }

    setInitialized(true);
  }, [matches, urlMatch]);

  useEffect(() => {
    if (urlMatch && !matches) {
      return;
    }


    if (initialized) {
      // ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, matches, urlMatch]);
};

export default useGAPageTracking;
