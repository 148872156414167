import React from 'react';

import { Container } from './styled.js';
import { by, identity, moment, zeroIfNaN } from '@utils';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';
import { WITHOUT_VALUE_OPERATORS } from '@constants';
import { useSelector } from 'react-redux';
import { eventsListSelector, segmentsOptionsSelector } from '@store/selectors';
import { useTranslation } from '@hooks';

const DefaultFilterNodeSettingsInfo = ({ node, options }) => {
  const events = useSelector(eventsListSelector);
  const segmentOptions = useSelector(segmentsOptionsSelector);
  const resource = segmentOptions.segments?.find?.(by(node.data?.segment_id));
  const o = useSelector(segmentsOptionsSelector)
  const { p, t } = useTranslation('date_picker');
  // const eventsOptions = events?.data?.map?.(e => ({ value: e.name, label: e.label }));

  if (!node.data?.filter_by) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const resolveRule = (rule) => {
    if (events.data?.find?.(by('name', rule))) {
      return events.data?.find?.(by('name', rule))?.label;
    }

    if (segmentOptions.fields?.attribute?.find?.(by('field', rule))) {
      return segmentOptions.fields?.attribute?.find?.(by('field', rule))?.label;
    }

    if (rule === '_c') {
      return t('labels.created_at');
    }

    return rule;
  }


  const resolveValue = (v) => {
    if (!v || !!~WITHOUT_VALUE_OPERATORS.indexOf(node.data?.query?.children?.[0]?.query?.operator)) {
      return;
    }

    if (Array.isArray(v.value)) {
      return v.value.join(!!~node.data?.query?.children?.[0]?.query?.operator?.indexOf?.('between') ? ' - ' : ', ');
    }

    if (typeof v.value === 'object' && v.value !== null) {
      return resolveTitle(v.value, false, p);
    }

    return v.value;
  };

  return (
    <Container>
      <InfoRow padded>
        <InfoTitle>
          {t('labels.filter_by')}:
        </InfoTitle>
        <InfoText>
          {node.data?.filter_by === 'rule' ? t('labels.attribute') : t('labels.segment')}
        </InfoText>
      </InfoRow>
      {node.data?.filter_by === 'segment' && (
        <InfoRow>
          <InfoTitle>
            {t('labels.segment')}:
          </InfoTitle>
          <InfoText>
            {resource?.name}
          </InfoText>
        </InfoRow>
      )}
      {node.data?.filter_by === 'rule' && (
        <>
          <InfoRow padded>
            <InfoTitle>
              {t('labels.type')}:
            </InfoTitle>
            <InfoText>
              {node.data?.query?.children?.[0]?.query?.ruleType}
            </InfoText>
          </InfoRow>
          <InfoRow padded>
            <InfoTitle>
              {t('labels.attribute')}:
            </InfoTitle>
            <InfoText>
              {resolveRule(node.data?.query?.children?.[0]?.query?.field)}
            </InfoText>
          </InfoRow>
          {!!node.data?.query?.children?.[0]?.query?.operator && (
            <InfoRow padded>
              <InfoTitle>
                {t('labels.operator')}:
              </InfoTitle>
              <InfoText>
                {node.data?.query?.children?.[0]?.query?.operator}
              </InfoText>
            </InfoRow>
          )}
          {(!~WITHOUT_VALUE_OPERATORS.indexOf(node.data?.query?.children?.[0]?.query?.operator) && node.data?.query?.children?.[0]?.query?.value?.value !== undefined && node.data?.query?.children?.[0]?.query?.value?.value !== '' && node.data?.query?.children?.[0]?.query?.value?.value !== null) && (
            <InfoRow condition={node.data?.query?.children?.[0]?.query?.value} padded>
              <InfoTitle>
                {t('labels.value')}:
              </InfoTitle>
              <InfoText>
                {resolveValue(node.data?.query?.children?.[0]?.query?.value)}
              </InfoText>
            </InfoRow>
          )}
          {!!node.data?.query?.children?.[0]?.query?.filters?.length && (
            <InfoRow padded>
              <InfoTitle>
                {t('labels.filters_count')}:
              </InfoTitle>
              <InfoText>
                {node.data?.query?.children?.[0]?.query?.filters?.reduce?.((acc, { filters }) => acc + (zeroIfNaN(filters?.length) || 1), 0)}
              </InfoText>
            </InfoRow>
          )}
        </>
      )}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default DefaultFilterNodeSettingsInfo;


