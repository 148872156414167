import React from 'react';
import { ActionsContainer } from '../../styled';
import { ActionButton, Icon, TableActionsBar } from '@components';
import { Container } from './styled';
import { useTranslation } from '@hooks';

const EmailActions = ({ onEmailViewClick, onEmailInfoClick }) => {
  const { p } = useTranslation('field_settings');

  return (
   <Container>
     <TableActionsBar
      actionButtons={(
        <ActionsContainer>
          <ActionButton testId="email-action-show-preview" tooltip={p('show_preview')} icon="Show-icon" iconSize={16} onClick={onEmailViewClick}>
            <Icon name={'Template-preview'} color={'#303133'} size={18} />
          </ActionButton>
          <ActionButton testId="email-action-show-info" tooltip={p('show_info')} icon={'Info-icon'} iconSize={14} onClick={onEmailInfoClick}>
            <Icon name={'Info-icon'} color={'#303133'} size={18} />
          </ActionButton>
        </ActionsContainer>
      )}
     />
   </Container>
  )
}

export default EmailActions;
