import React from 'react';
import { by, Platform } from '@utils';
import { Slider } from 'antd';

import { e1, e10, e2, e3, e4, e5, e6, e7, e8, e9 } from '@res/images/rates';
import { Container, Emoji, EmojiImage } from './styled.js';

const rates = [
  {
    emoji: '😭',
    image: e1,
    value: 1,
  },
  {
    emoji: '😥',
    image: e2,
    value: 2,
  },
  {
    emoji: '☹️',
    image: e3,
    value: 3,
  },
  {
    emoji: '😔',
    image: e4,
    value: 4,
  },
  {
    emoji: '😏',
    image: e5,
    value: 5,
  },
  {
    emoji: '🙂',
    image: e6,
    value: 6,
  },
  {
    emoji: '☺️',
    image: e7,
    value: 7,
  },
  {
    emoji: '🥳',
    image: e8,
    value: 8,
  },
  {
    emoji: '🤩',
    image: e9,
    value: 9,
  },
  {
    emoji: '😍',
    image: e10,
    value: 10,
  },
]

const resolveImage = value => {
  const { emoji, image } = rates.find(by('value', value));

  return Platform.select({
    windows: (
      <EmojiImage $image={image} />
    ),
    fallback: (
      <Emoji>
        {emoji}
      </Emoji>
    )
  })
};

const values = Array.from({ length: 10 }, (_, i) => i + 1);

const QuestionnaireSlider = ({ value, onChange }) => {
  return (
    <Container id="questionnaire_slider">
      <Slider
        step={1}
        min={1}
        max={10}
        value={value}
        onChange={onChange}
        marks={values.reduce((acc, v) => ({
          ...acc,
          [v]: {
            label:  v === value ? resolveImage(v) : null,
            style: { top: -68 }
          }
        }), {})}
        // tipFormatter={resolveImage}
        tooltipVisible={false}
      />
    </Container>
  );
}

export default QuestionnaireSlider;


