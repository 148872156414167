import React, {useMemo} from 'react';
import {TemplatesCol, TemplatesContainer, TemplatesSection, TemplatesTitle} from "./styled";
import {OptionalItem} from "../OptionalItem";
import {EmailSection} from "../EmailSection";
import {ViberSection} from "../ViberSection";
import {MobilePushSection} from "../MobilePushSection";
import {SmsSection} from "../SmsSection";
import {WebpushSection} from "../WebpushSection";
import {WebhookSection} from "../WebhookSection";
import {useSelector} from "react-redux";
import {
  templateApiRequestSelector,
  templateEmailsSelector, templateMobilePushSelector,
  templateSmsSelector,
  templateViberSelector,
  templateWebpushSelector, userSelector
} from "@store/selectors";
import {usePageLoader, useTranslation} from "@hooks";

const DashboardTemplates = () => {
  const { p } = useTranslation('dashboard_page');
  const smsTemplates = useSelector(templateSmsSelector);
  const viberTemplates = useSelector(templateViberSelector);
  const emailTemplates = useSelector(templateEmailsSelector);
  const webpushes = useSelector(templateWebpushSelector);
  const mobilePushes = useSelector(templateMobilePushSelector);
  const apiRequests = useSelector(templateApiRequestSelector);
  const user = useSelector(userSelector);
  const loader = usePageLoader();

  const renderTemplates = useMemo(() => {
    return !!emailTemplates?.meta?.total || !!viberTemplates?.meta?.total || !!smsTemplates?.meta?.total || !!webpushes?.meta?.total || mobilePushes?.meta?.total || apiRequests?.meta?.total || user?.app?.on_boarding?.templateCreated;
  }, [emailTemplates?.meta?.total, viberTemplates?.meta?.total, smsTemplates?.meta?.total, webpushes?.meta?.total, mobilePushes?.meta?.total, apiRequests?.meta?.total, user?.app?.on_boarding?.templateCreated])

  const renderOnlyLargeTemplates = useMemo(() => {
    return !(!!smsTemplates?.meta?.total) && !(!!webpushes?.meta?.total) && !(!!apiRequests?.meta?.total);
  }, [smsTemplates?.meta?.total, webpushes?.meta?.total, apiRequests?.meta?.total])

  const shouldRender = useMemo(() => {
    return (!!user?.app?.id);
  }, [user?.app?.id]);

  return shouldRender ? (
    <TemplatesSection data-testid="dashboard-templates-section">
      <TemplatesTitle data-testid="dashboard-title-last-templates" style={renderTemplates || !renderOnlyLargeTemplates ? {} : { opacity: '0.5' }}>
        {p('last_templates')}
      </TemplatesTitle>
      <TemplatesContainer onlyEmails={renderOnlyLargeTemplates}>
        <TemplatesCol style={{ marginRight: 10, flex: 1 }}>
          <OptionalItem
            shouldRender={!!emailTemplates?.meta?.total}
            tooltip={p('tab_after_emails')}
            content={(
              <EmailSection />
            )}
          />
          <OptionalItem
            shouldRender={!!viberTemplates?.meta?.total}
            tooltip={p('tab_after_vibers')}
            content={(
              <ViberSection />
            )}
          />
          <OptionalItem
            shouldRender={!!mobilePushes?.meta?.total}
            tooltip={p('tab_after_mobilepush')}
            content={(
              <MobilePushSection />
            )}
          />
          <OptionalItem
            shouldRender={!!webpushes?.meta?.total}
            tooltip={p('tab_after_webpush')}
            content={(
              <WebpushSection />
            )}
          />
        </TemplatesCol>
        <TemplatesCol style={{ marginLeft: 10 }}>
          <SmsSection />
          <WebhookSection />
        </TemplatesCol>
      </TemplatesContainer>
    </TemplatesSection>
  ) : null;
}

export default React.memo(DashboardTemplates);
