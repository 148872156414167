import React from 'react';
import { Dropdown } from 'antd';

import { Icon, OnboardingHighlight } from '@components';

import { IconWrapper } from './styled';

const MoreButton = ({ testId, menu, hover, color, visible, onClick, iconStyles = {}, iconSize, onVisibleChange, ...props }) => {
  if (!menu) {
    return (
      <IconWrapper data-testid={testId} onboard={props.onboard} style={props.style || {}} onClick={onClick || onVisibleChange}>
        <OnboardingHighlight on={props.onboard}>
          <Icon name="More-info" size={iconSize || 36} style={{ width: '22px', ...iconStyles }} color={color} />
        </OnboardingHighlight>
      </IconWrapper>
    );
  }

  return (
    <Dropdown
      {...(onVisibleChange ? { visible } : {})}
      overlay={menu}
      trigger={onVisibleChange ? [] : [hover ? 'hover' : 'click']}
      {...props}
    >
        <IconWrapper data-testid={testId} onboard={props.onboard} style={props.style || {}} onClick={onClick || onVisibleChange}>
          <OnboardingHighlight on={props.onboard}>
            <Icon name="More-info" size={iconSize || 36} style={{ width: '22px', ...iconStyles }} color={color} />
          </OnboardingHighlight>
        </IconWrapper>
    </Dropdown>
  )
};

export default MoreButton;
