import React from 'react';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';

import { Container, Option, Icon } from './styled';
import { NoData } from '@components';

const RFMSegmentsFilterPanel = ({ onSelect }) => {
  const options = useSelector(segmentsOptionsSelector);

  const rfmAnalyticsOptions = (options?.fields?.rfmResources || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  if (!rfmAnalyticsOptions?.length) {
    return (
      <Container>
        <NoData />
      </Container>
    )
  }

  return (
    <Container>
      {rfmAnalyticsOptions.map(({ value, label }, idx) => (
        <Option key={idx} onClick={() => { onSelect(value) }}>
          {label}
          <Icon>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1_32039)">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.60065 0.266662L6.33398 3.99999L2.60065 7.73333L1.66732 6.79999L4.46732 3.99999L1.66732 1.2L2.60065 0.266662Z" fill="#303133"/>
              </g>
              <defs>
                <clipPath id="clip0_1_32039">
                  <rect width="8" height="8" fill="white" transform="translate(8) rotate(90)"/>
                </clipPath>
              </defs>
            </svg>
          </Icon>
        </Option>
      ))}
    </Container>
  );
};

export default RFMSegmentsFilterPanel;
