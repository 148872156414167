import { identity } from './functions';
import { isSameRange, moment } from './index';

export const QPOptions = {
  now: () => ({
    absolute: [moment().startOf('minute'), moment().endOf('minute')],
    relative: {
      range: {
        unit: 'minutes',
        quantity: 1,
        type: 'last',
      },
      gap: {
        unit: 'days',
        quantity: 0,
        type: 'after',
      }
    }
  }),
  today: () => ({
    absolute: [moment().startOf('day'), moment().endOf('day')],
    relative: {
      range: {
        unit: 'days',
        quantity: 1,
        type: 'last',
      },
      gap: {
        unit: 'days',
        quantity: 0,
        type: 'after',
      }
    }
  }),
  yesterday: () => ({
    absolute: [moment().add(-1, 'day').startOf('day'), moment().add(-1, 'day').endOf('day')],
    relative: {
      range: {
        unit: 'days',
        quantity: 1,
        type: 'last',
      },
      gap: {
        unit: 'days',
        quantity: 1,
        type: 'before',
      }
    }
  }),
  tomorrow: () => ({
    absolute: [moment().add(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day')],
    relative: {
      range: {
        unit: 'days',
        quantity: 1,
        type: 'next',
      },
      gap: {
        unit: 'days',
        quantity: 0,
        type: 'after',
      }
    }
  }),
};

export const QPDoubleOptions = {
  this: {
    week: () => ({
      absolute: [moment().startOf('isoWeek').startOf('day'), moment().endOf('isoWeek').endOf('day')],
      relative: {
        range: {
          unit: 'weeks',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    month: () => ({
      absolute: [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
      relative: {
        range: {
          unit: 'months',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    year: () => ({
      absolute: [moment().startOf('year').startOf('day'), moment().endOf('year').endOf('day')],
      relative: {
        range: {
          unit: 'years',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
  },
  last: {
    twenty_four_hours: () => ({
      absolute: [moment().add(-1, 'day').startOf('hour'), moment().endOf('hour')],
      relative: {
        range: {
          unit: 'hours',
          quantity: 24,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      },
    }),
    seven_days: () => ({
      absolute: [moment().add(-6, 'days').startOf('day'), moment().endOf('day')],
      relative: {
        range: {
          unit: 'days',
          quantity: 7,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    week: () => ({
      absolute: [moment().add(-1, 'week').startOf('isoWeek').startOf('day'), moment().add(-1, 'week').endOf('isoWeek').endOf('day')],
      relative: {
        range: {
          unit: 'weeks',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    month: () => ({
      absolute: [moment().add(-1, 'month').startOf('month').startOf('day'), moment().add(-1, 'month').endOf('month').endOf('day')],
      relative: {
        range: {
          unit: 'months',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    year: () => ({
      absolute: [moment().add(-1, 'year').startOf('year').startOf('day'), moment().add(-1, 'year').endOf('year').endOf('day')],
      relative: {
        range: {
          unit: 'years',
          quantity: 1,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
  },
  next: {
    twenty_four_hours: () => ({
      absolute: [moment().add(1, 'hour').startOf('hour'), moment().add(1, 'day').add(1, 'hour').endOf('hour')],
      relative: {
        range: {
          unit: 'hours',
          quantity: 24,
          type: 'next',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    seven_days: () => ({
      absolute: [moment().startOf('day'), moment().add(7, 'days').endOf('day')],
      relative: {
        range: {
          unit: 'days',
          quantity: 7,
          type: 'last',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    week: () => ({
      absolute: [moment().add(1, 'week').startOf('isoWeek').startOf('day'), moment().add(1, 'week').endOf('isoWeek').endOf('day')],
      relative: {
        range: {
          unit: 'weeks',
          quantity: 1,
          type: 'next',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    month: () => ({
      absolute: [moment().add(1, 'month').startOf('month').startOf('day'), moment().add(1, 'month').endOf('month').endOf('day')],
      relative: {
        range: {
          unit: 'months',
          quantity: 1,
          type: 'next',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
    year: () => ({
      absolute: [moment().add(1, 'year').startOf('year').startOf('day'), moment().add(1, 'year').endOf('year').endOf('day')],
      relative: {
        range: {
          unit: 'years',
          quantity: 1,
          type: 'next',
        },
        gap: {
          unit: 'days',
          quantity: 0,
          type: 'after',
        }
      }
    }),
  },
};

export const QPTimeRelationOptionLabels = {
  this: 'This',
  last: 'Last',
  next: 'Next',
}

export const QPTimeEntityOptionLabels = {
  year: 'Year',
  month: 'Month',
  week: 'Week',
  seven_days: '7 days',
  twenty_four_hours: '24 hours'
}


const isStartOf = (d, unit) => {
  return moment(d).isSame(moment(d).startOf(unit));
}

const isEndOf = (d, unit) => {
  return moment(d).isSame(moment(d).endOf(unit));
}

const resolveRangeUnit = ([d1, d2]) => {
  const [r1, r2] = [moment(d1).startOf('day'), moment(d2).endOf('day')];

  if (isStartOf(r1, 'year') && isEndOf(r2, 'year')) {
    return 'years';
  }
  if (isStartOf(r1, 'month') && isEndOf(r2, 'month')) {
    return 'months';
  }
  if (isStartOf(r1, 'isoWeek') && isEndOf(r2, 'isoWeek')) {
    return 'weeks';
  }

  return 'days';
};

export const rangeToRelative = (range) => {
  if (!range?.every?.(identity)) {
    return;
  }

  const rangeUnit = resolveRangeUnit(range);

  const gapType = moment(moment(range[0]).startOf('day')).isBefore(moment().startOf('day')) ? 'before' : 'after';
  const rangeType = (moment().isBetween(range[0], range[1]) && Math.abs(moment(range[0]).diff(moment(range[1]), rangeUnit)) === 0) ? 'last' : 'next';

  return {
    range: {
      type: rangeType,
      unit: rangeUnit,
      quantity: Math.abs(moment(range[0]).diff(moment(range[1]), rangeUnit)) + 1,
    },
    gap: {
      type: gapType,
      unit: 'days',
      quantity: Math.abs(moment().startOf(rangeUnit).diff(moment(range[0]), 'days')) + +(rangeType === 'next')
    },
  };
};

export const relativeToRange = (settings) => {
  if (!settings) {
    return;
  }

  const unit = {
    days: 'day',
    months: 'month',
    weeks: 'isoWeek',
    years: 'year',
    hours: 'hour',
    minutes: 'minute',
  }[settings.range.unit];

  const f = moment();
  const s = moment(f).add(+(settings.range.quantity - 1) * (settings.range.type === 'last' ? -1 : 1), unit);
  const [fs, ss] = [f, s].sort((a, b) => a.unix() - b.unix());

  const addGap = +settings.gap.quantity * (settings.gap.type === 'before' ? -1 : 1);
  const addRange = settings.range.type === 'next' ? 1 : 0;

  return [
    fs.startOf(unit).add(addGap, settings.gap.unit).add(addRange, unit),
    ss.endOf(unit).add(addGap, settings.gap.unit).add(addRange, unit)
  ];
};

export const restoreDefaults = (value) => {
  const absolute = value.absolute && value.absolute.map(d => moment(d));
  const relative = value.relative;

  return {
    condition_type: 'absolute',
    ...value,
    absolute: absolute || relativeToRange(value.relative) || QPOptions.today().absolute,
    relative: relative || rangeToRelative(value.absolute) || QPOptions.today().relative,
    specific: {
      range: (value.specific?.range || [null, null]).map(d => d === null ? null : moment(d)),
      ...(value.specific || {})
    }
  }
};

export const resolveSelectedDoubleQP = (range) => {
  return Object.entries(QPDoubleOptions).reduce((acc, [key, options]) => {
    if (acc) {
      return acc;
    }

    const res = Object.entries(options).find(([, value]) => isSameRange(value().absolute, range));

    if (res) {
      return [key, ...res];
    }

    return null;
  }, null)
};

export const pickRelative = ([left, right]) => {
  return QPDoubleOptions[left][right]().relative;
};
