import React, { useEffect } from 'react';
import { by, diffFields, identity, sequence, testId } from '@utils';
import { NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';
import { AutoComplete, Input, Multiselect, OperatorSelect, SearchSelect, Select, Textarea } from '@components';
import {
  DeleteFilterButton, FieldLabel, FieldRow,
  FiltersContainer
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/EventNodeSettings/styled';
import { useTranslation } from '@hooks';
import { formatTranslation } from '@utils';

import { Container, Error, AddFilterButton,
  AddFilterButtonContainer,
  FilterContainer } from './styled.js';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';

const urlOptions = [
  {
    value: 'any',
    label: 'workflow_page.any_url'
  },
  {
    value: 'matching',
    label: 'workflow_page.url_matching'
  },
];

const PageViewNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t, p, e } = useTranslation('workflow_page');
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);

  useEffect(() => {
    onChange(s => ({
      ...s,
      filter_builder: {
        logicalOperator: 'and',
      }
    }));
  }, []);

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleLinkChange = ({ target: { value: url }}) => {
    multinode.clearError(0)('url');
    onChange(s => ({ ...s, url }))
  };

  const segmentOptions = useSelector(segmentsOptionsSelector);
  const fieldsOptions = segmentOptions.fields?.attribute?.map(({ label, field }) => ({ value: field, label }));

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const handleLinkTypeChange = linkType => {
    multinode.clearError(0)('link_type');
    onChange(s => ({ ...s, linkType, url: '' }));
  };

  const handleLogicalOperatorChange = (logicalOperator) => {
    onChange(s => ({ ...s, filter_builder: { ...s.filter_builder, logicalOperator } }));
  };

  const handleAddFilter = () => {
    onChange(s => ({
      ...s, filter_builder: {
        ...(s.filter_builder || {
          logicalOperator: 'and',
        }),
        filters: [
          ...(s.filter_builder?.filters || []),
          {
            operator: 'equals',
            type: 'text',
            value: { type: 'scalar', value: '' },
          }
        ]
      }
    }));
  };

  const handleRemoveFilter = (index) => () => {
    multinode.clearError(0)('filter_builder');
    onChange(s => ({ ...s, filter_builder: {
        ...s.filter_builder,
        filters: s.filter_builder.filters.filter((_, i) => i !== index)
      }
    }))
  };

  const handleFilterFieldChange = (field, extractor = identity, clearFields = []) => index => value => {
    multinode.clearError(0)('filter_builder');
    onChange(s => ({
      ...s,
      filter_builder: {
        ...s.filter_builder,
        filters: s.filter_builder?.filters.map((f, i) => i === index ? { ...f, [field]: extractor(value), ...Object.fromEntries(clearFields.map(([field, defaultValue]) => [field, defaultValue])) } : f),
      }
    }));
  };

  const fieldOptions = options.fields?.events?.find(by('label', '_v'))?.payload?.map(({ field, label, type }) => ({ value: field, label: label, type })) || [];

  const handleFilterRuleChange = handleFilterFieldChange('field', identity, [['value', { type: 'scalar', value: '' }], ['operator', void 0]]);
  const handleFilterValueChange = handleFilterFieldChange('value', value => ({ type: 'scalar', value }));
  const handleFilterOperatorChange = handleFilterFieldChange('operator', identity, [['value', { type: 'scalar', value: '' }]]);
  const handleFilterTypeChange = handleFilterFieldChange('type');
  const handleFieldTypeChange = index => field => handleFilterTypeChange(index)(fieldOptions.find(by('value', field))?.type || 'text');

  const changeFilterOperator = (index) => (operator, type) => {
    handleFilterOperatorChange(index)(operator);
    handleFilterTypeChange(index)(type);
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SearchSelect
                {...testId('page-view-node-settings-required-link-type-select')()}
                label={t('labels.link_type')}
                wrapperStyles={{
                  width: '100%',
                  marginBottom: '16px',
                }}
                style={{
                  marginRight: '6px',
                  width: '100%',
                }}
                disabled={!editable}
                highlight={diff['link_type'] === false}
                error={multinode.getError(0)('link_type')}
                value={value.linkType}
                options={urlOptions}
                onChange={handleLinkTypeChange}
              />
              {value.linkType === 'matching' && (
                <Input
                  {...testId('page-view-node-settings-required-link-type-matching-link')()}
                  title={t('labels.link')}
                  style={{ marginBottom: 0 }}
                  highlight={diff['url'] === false}
                  error={multinode.getError(0)('url') ? p('invalid_url') : ''}
                  disabled={!editable}
                  wrapperStyles={{ marginBottom: '16px', width: '100%' }}
                  placeholder="https://yourlink.com"
                  value={value.url}
                  onChange={handleLinkChange}
                />
              )}
            </div>
            {!!value.filter_builder?.filters?.length && (
              <div style={{ marginTop: 10 }}>
                <SearchSelect
                  {...testId('page-view-node-settings-required-filters-operator')()}
                  wrapperStyles={{ width: '100%', marginBottom: 16 }}
                  label={t('labels.filters_operator')}
                  disabled={!editable}
                  style={{ background: '#F9FBFF', width: '100%' }}
                  options={[{ value: 'and', label: 'labels.matches_all' }, { value: 'or', label: 'labels.matches_any' }]}
                  value={value.filter_builder?.logicalOperator}
                  onChange={handleLogicalOperatorChange}
                />
              </div>
            )}
            {!!value.filter_builder?.filters?.length && (
              <FiltersContainer>
                {(value.filter_builder?.filters || []).map(({ field, operator, value: v, type }, index, self) => (
                  <FilterContainer $bordered={index !== self.length - 1}>
                    <DeleteFilterButton {...testId('page-view-node-settings-required-filter-delete')()} onClick={handleRemoveFilter(index)}>
                      {p('delete_filter')}
                    </DeleteFilterButton>
                    <FieldRow>
                      <FieldLabel>
                        {formatTranslation(p('field'), index + 1)}
                      </FieldLabel>
                      <AutoComplete
                        {...testId('event-node-settings-required-filter-field')()}
                        id={index}
                        smaller
                        style={{ marginBottom: 0 }}
                        fullWidth
                        error={multinode.getError(0)('filter_builder')?.[index]?.field}
                        value={field || ''}
                        disabled={!editable}
                        options={fieldOptions}
                        onChange={sequence(handleFilterRuleChange(index), handleFieldTypeChange(index))}
                      />
                    </FieldRow>
                    <OperatorSelect
                      {...testId('page-view-node-settings-required-filter-operator')()}
                      appearance="column"
                      fullWidth
                      disabled={!editable}
                      smaller
                      initialType={type}
                      type={type}
                      wrapperStyle={{ width: '100%' }}
                      value={{ operator, value: v?.value, type, validation: { errors: { value: multinode.getError(0)('filter_builder')?.[index]?.value }} }}
                      onTypeChange={handleFilterTypeChange(index)}
                      onValueChange={handleFilterValueChange(index)}
                      onOperatorChange={changeFilterOperator(index)}
                      autocomplete={type === 'numeric' ? undefined : {
                        fields: [field],
                        event: '_v',
                      }}
                    />
                    {errors['filter_builder']?.[index]?.field && <Error>{e(multinode.getError(0)('filter_builder')?.[index]?.field)}</Error>}
                  </FilterContainer>
                ))}
                {editable && (
                  <AddFilterButtonContainer $bordered>
                    <AddFilterButton {...testId('page-view-node-settings-required-filter-add')()} onClick={handleAddFilter}>{p('add_event_data_filter')}</AddFilterButton>
                  </AddFilterButtonContainer>
                )}
              </FiltersContainer>
            )}
            {(editable && !value.filter_builder?.filters?.length) && (
              <AddFilterButtonContainer>
                <AddFilterButton {...testId('page-view-node-settings-required-add-filter')()} onClick={handleAddFilter}>{p('add_event_data_filter')}</AddFilterButton>
              </AddFilterButtonContainer>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('page-view-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={multinode.getError(0)('label')}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('page-view-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        additionalSettings={(
          <Multiselect
            {...testId('page-view-node-settings-additional-message-fields')()}
            disabled={!editable}
            title={t('labels.message_fields')}
            style={{ width: '100%' }}
            options={fieldsOptions}
            getParentElement={t => t.parentElement.parentElement.parentElement}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
}

export default PageViewNodeSettings;


