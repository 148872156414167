import React, {useMemo, useState} from 'react';
import { EmailTemplatesRow, TemplatesHeader, TemplatesInner, Container } from "./styled";
import { Button, Link, NoData, WithPermissions, ViewEmailTemplateModal, EmailStatisticsModal } from "@components";
import {Paths, UserPermissions} from "@constants";
import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import { useDispatch, useSelector } from "react-redux";
import {hintModeSelector, templateEmailsSelector} from "@store/selectors";
import {archiveEmailTemplate, getEmailTemplates, setEmailDuplicate, updateOnboarding} from "@store/actions/creators";
import {RioService} from "../../../../../../services";
import {useModalState, usePermissions, useTranslation} from "@hooks";
import {notify} from "@utils";
import {EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX} from "@constants/templates";
import { useNavigate } from "react-router";

const EmailSection = () => {
  const emailTemplates = useSelector(templateEmailsSelector);
  const hintMode = useSelector(hintModeSelector);
  const { p, t } = useTranslation('dashboard_page');

  const permissions = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statisticsEmailModal = useModalState();

  const viewEmailModal = useModalState();

  const [emailViewItem, setEmailViewItem] = useState({});
  const [statisticEmailId, setEmailStatisticsId] = useState(null);

  const shouldRender = useMemo(() => {
    return emailTemplates.data?.filter(t => t.status !== 3)?.length
  }, [emailTemplates.data])

  const handleCreateEmailTemplateClick = (fromLastTemplates) => {
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
      dispatch(updateOnboarding('templates', {
        step: 1,
      }));
    } else {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
    }
    RioService.track('templates_dash');
  };

  const handleEmailArchive = (id, name) => {
    dispatch(archiveEmailTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.email_archived'), name);
        dispatch(getEmailTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleEmailStatisticsClick = (id) => {
    setEmailStatisticsId(id);
    statisticsEmailModal.open();
  };

  const handleViewEmail = (data) => {
    setEmailViewItem(data);
    viewEmailModal.open();
  };

  const handleDuplicateEmail = (data) => {
    dispatch(setEmailDuplicate({ data }));

    navigate(`${Paths.TEMPLATES_EMAIL}/${data.template_driver}/duplicate?f=default`);
  };

  const handleEditEmail = (id, driver) => {
    navigate(`${Paths.TEMPLATES_EMAIL}/${driver}/${id}`);
  };

  const handleEmailModalAction = () => {
    if (String(emailViewItem.id).includes(EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX)) {
      handleDuplicateEmail(emailViewItem);
    } else {
      handleEditEmail(emailViewItem.id, emailViewItem.template_driver);
    }
  };

  const handleCloseViewEmailModal = () => {
    setEmailViewItem({});
    viewEmailModal.close();
  };

  return (
    <Container data-testid="dashboard-templates-section-email">
      <TemplatesInner style={{ paddingBottom: 0, pointerEvents: !!emailTemplates?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_EMAIL}/list/default`} icon>{p('emails')}</Link>
          <WithPermissions name={UserPermissions.CREATE_EMAILS}>
            <Button
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px'
              }}
              testId="dashboard-templates-section-email-create"
              width="none"
              onClick={() => handleCreateEmailTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!emailTemplates.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!emailTemplates.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ display: 'flex' }} />}
          {emailTemplates.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            body,
            used,
            workers,
            status,
            template_driver,
            ...rest
          }, i) => (
            <LargeTemplateItem
              key={id}
              title={name}
              type="email"
              status={status}
              used={used}
              workflows={workers}
              testId={`email-templates-item-${i}`}
              view={!permissions.allowed(UserPermissions.UPDATE_EMAILS)}
              date={created_at}
              html={body}
              fromDashboard
              onArchive={() => handleEmailArchive(id, name)}
              onStatisticsClick={() => handleEmailStatisticsClick(id)}
              onEditClick={() => {
                if (workers.used !== 1) {
                  navigate(`${Paths.TEMPLATES_EMAIL}/${template_driver}/${id}?view=1`)
                }
              }}
              onViewClick={() => handleViewEmail({ id, name, data: body, template_driver })}
              onDuplicateClick={() => handleDuplicateEmail({
                id,
                name,
                created_at,
                body,
                data: body,
                workflows: workers,
                template_driver,
                ...rest,
              })}
            />
          ))}
        </EmailTemplatesRow>
      </TemplatesInner>

      <EmailStatisticsModal id={statisticEmailId} opened={statisticsEmailModal.opened} onClose={statisticsEmailModal.close} />

      <ViewEmailTemplateModal
        opened={viewEmailModal.opened}
        onClose={handleCloseViewEmailModal}
        onActionClick={handleEmailModalAction}
        name={emailViewItem.name}
        html={emailViewItem.data}
        isExample={Boolean(emailViewItem?.id?.includes?.(EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX))}
      />
    </Container>
  );
}

export default React.memo(EmailSection);
