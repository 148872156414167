import { WorkspacesActionTypes } from '@store/actions/types';

import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from "../../lib";
import { compose } from "redux";

export const listWorkspaces = compose(
    withPagination,
    withOrdering,
    withFilters([]),
    asCreator(() => ({
        type: WorkspacesActionTypes.LIST,
    })),
)

export const saveWorkspaces = compose(
    withCallbacks,
    asCreator(({ workspace }) => ({
        type: WorkspacesActionTypes.SAVE,
        payload: { workspace },
    }))
);

export const switchWorkspaces = compose(
    withCallbacks,
    asCreator(({ id }) => ({
        type: WorkspacesActionTypes.SWITCH,
        payload: { id },
    }))
);

export const getDemoWorkspaces = compose(
  withCallbacks,
  asCreator(() => ({
    type: WorkspacesActionTypes.GET_DEMO,
  }))
);

export const switchToDemoWorkspace = compose(
  withCallbacks,
  asCreator(({ app_id }) => ({
    type: WorkspacesActionTypes.SWITCH_TO_DEMO,
    payload: { app_id },
  }))
);

export const setViewDemoWorkspacesModalOpened = ({ opened }) => ({
  type: WorkspacesActionTypes.SET_VIEW_DEMO_MODAL_OPENED,
  payload: { opened },
});
