import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 100%;
  color: #303133;
`;




