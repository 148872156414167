import styled, { css } from 'styled-components';
import { FontSizes, LineHeights } from '@constants';

export const Container = styled.div`
  font-family: Rubik-Regular;
  display: flex;
  ${({ $variant }) => css`
    font-size: ${FontSizes[$variant]}px;
    line-height: ${LineHeights[$variant]};
  `}
`;
