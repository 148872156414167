import React from 'react';
import { ViewModes } from '@constants';
import { Icon } from '@components';

import { Container, ViewModeButton,  } from './styled.js';

const defaultViewModes = Object.values(ViewModes);

const Icons = {
  [ViewModes.DESKTOP]: 'Preview-desktop',
  [ViewModes.MOBILE]: 'Preview-mobile',
};

const ViewModeSwitch = ({ value, onChange, containerStyle }) => {
  const handleChange = (vm) => () => {
    onChange(vm);
  };

  return (
    <Container style={containerStyle}>
      {defaultViewModes.map((vm, idx) => (
        <ViewModeButton key={idx} active={value === vm} onClick={handleChange(vm)}>
        <Icon size={17} name={Icons[vm]} color={value === vm ? '#303133' : '#909399'} />
        </ViewModeButton>
      ))}
    </Container>
  );
}

export default ViewModeSwitch;


