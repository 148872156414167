import React, { useState } from 'react';

import { Container, Title, TitleContainer, ToggleButtonContainer, TooltipContainer } from './styled.js';
import { Icon } from '@components';
import { Tooltip } from 'antd';

const OpeningContainer = ({ title, defaultOpened = false, content, tooltip, showTitleIfExpanded, style }) => {
  const [opened, toggleOpen] = useState(defaultOpened);

  return (
    <Container $opened={opened} onClick={opened ? () => {} : () => toggleOpen(!opened)} style={{ ...style, height: opened ? '30%' : 'auto' }}>
      <ToggleButtonContainer style={{ transform: opened ? 'rotate(180deg)' : '' }} onClick={() => toggleOpen(!opened)}>
        <Icon style={{ lineHeight: '16px' }} name="Arrow-in-circle" size={16} />
      </ToggleButtonContainer>
      <TitleContainer style={{ display: 'flex' }}>
        <Title>{!opened || (showTitleIfExpanded && opened) ? title : ''}</Title>
        { tooltip && (
          <Tooltip title={tooltip}>
            <TooltipContainer opened={opened}>
              <Icon name="Info-icon" size={12} />
            </TooltipContainer>
          </Tooltip>
        )}
      </TitleContainer>
      { opened && content }
    </Container>
  );
}

export default OpeningContainer;


