import React from 'react';

import { Container } from './styled.js';
import { Empty } from 'antd';
import { useTranslation } from '@hooks';

const NoData = ({ description, style, emptyStyle }) => {
  const { t } = useTranslation();

  return (
    <Container style={{...style}}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={emptyStyle} description={description || t('common.no_data')} />
    </Container>
  );
}

export default NoData;


