import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isNode, useStore } from 'reactflow';
import {
  by, compose, extract,
  identity, sequence, update,
} from '@utils';

import WorkflowEditorContext from './WorkflowEditorContext';
import { WorkflowNodes, WorkflowNodeTypes } from '@constants';
import { useDispatch } from 'react-redux';
import { workflowEditorSetNodes } from '@store/actions/creators/workflowEditor';
import { iterate, toArray, toReactFlowNodesArray } from '@utils/workflows/refactored/structureParser';
import {
  appendChild, appendFinishNodes, clearStyles,
  clearTempNodes, createArrayFrom, findArray,
  insertAfter, openArrayAt, removeBranchFrom,
  removeChild, removeDataFields, unwrapArrays,
  updateNode, updateSplitters, duplicateDataToChild
} from '@utils/workflows/refactored/updaters';
import {
  getChildren, getClosestNode, getExitPointsCount,
  getLastNode, getNode, getParent, getParentsArray,
  getTempNode, isLast
} from '@utils/workflows/refactored/getters';
import { createNode } from '@utils/workflows/refactored/creators';
import { layoutFlow } from '@utils/workflows/refactored/layout';
import { useDebouncedCallback } from 'use-debounce';
import { useWorkerState } from '@contexts/WorkflowEditorContext/hooks';
import { v4 as uuidv4 } from 'uuid';
import { isConnectorsValid } from '@utils/workflows/refactored/common';
import { useTranslation } from '@hooks';

const WorkflowEditorProvider = ({ children, workflowEditable, defaultName = '' }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selection, setSelection] = useState('');
  const [openedNodeSettings, setOpenedNodeSettings] = useState('');
  const [openedNodeDiff, setOpenedNodeDiff] = useState('');
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState('');
  const [editable, setEditable] = useState(workflowEditable);
  const [droppable, setDroppable] = useState(null);
  const [sidebarOpened, setSidebarOpened] = useState(true);
  const [onUpdateListeners, setOnUpdateListeners] = useState([]);
  const nodesState = useStore(state => state.getNodes(), (a, b) => a.every((n, i) => n?.__rf?.position === b[i]?.__rf?.position));
  const { root, styles, setRootAndStyles, undo, redo, canRedo, canUndo } = useWorkerState();
  const { p } = useTranslation('workflow_page');

  const addOnUpdateListener = (listener) => {
    setOnUpdateListeners(ls => [...ls, listener]);
  };

  const removeOnUpdateListener = (ref) => {
    setOnUpdateListeners(ls => ls.filter(l => l !== ref));
  };

  const updateWithLayout = (rootUpdater, styleId, stylesUpdater, layoutFn = layoutFlow, skipUndoable = false, skipMiddlewareIfUndoable = true, additionalMiddlewareParams) => {
    let s = styleId ? { ...styles, [styleId]: update(styles[styleId] || {}, stylesUpdater) } : update(styles, stylesUpdater);
    const newRoot = appendFinishNodes(update(root, rootUpdater), { translate: p });
    const rootArr = toArray(newRoot);
    const stylesPersist = !!~rootArr.findIndex(by(styleId));

    s = layoutFn(newRoot, s);

    if (!newRoot) {
      setRootAndStyles(null, {}, skipUndoable);
      return;
    }

    if (skipMiddlewareIfUndoable ? !skipUndoable : true) {
      sequence(...onUpdateListeners)(root, newRoot, additionalMiddlewareParams);
    }

    setRootAndStyles(newRoot, () => {
      if (!stylesPersist) {
        delete s[styleId];
      }

      return clearStyles(newRoot, s);
    }, skipUndoable)
  };

  const saveCachedNodes = (id) => {
    dispatch(workflowEditorSetNodes({ id, tree_map: unwrapArrays(root), styles }));
  };

  const changeSelection = ((s) => {
    if (!s) {
      return setSelection('');
    }

    if (s.length === 1) {
      return setSelection(s[0]?.id);
    }
  });

  useEffect(() => {
    const lastId = isLast(root, selection);
    const node = nodesState.find(by(selection));

    if (!node) {
      return;
    }

    if (!lastId) {
      // actions['setSelectedElements']([node]);
      return;
    }
    const finishNode = nodesState.find(by(lastId));

    // actions['setSelectedElements']([node].concat(finishNode ? [finishNode] : []));
  }, [selection, nodesState])

  const getState = () => {
    return nodesState.map(n => update(n, { position: { x: n.position.x - 45, y: n.position.y - 35 }}))
  }

  const handleLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
  };

  const addNode = (actionType, type) => {
    const [node, style, otherStyles] = createNode({
      type,
      actionType,
      children: [],
      translate: p,
    });

    if (!root || root.type === 'end') {
      updateWithLayout(node, null, () => ({
        ...styles,
        [style.id]: style,
        ...otherStyles
      }), layoutFlow, true, false, { addEntry: true });
      setSelection(node.id);
      return;
    } else if (actionType === 'entry_point') {
      node.children = [root];
      updateWithLayout(node, null, () => ({
        ...styles,
        [style.id]: style,
        ...otherStyles
      }), layoutFlow, false, false, { addEntry: true });
      setSelection(node.id);
      return;
    }

    if (!selection) {
      return;
    }

    let r = root;
    let s = selection;
    const selectedNode = renderNodes.find(by(selection));

    if (selectedNode?.data?.name === 'array') {
      r = openArrayAt(r, selection);
      s = getLastNode(selectedNode.data.nodes).id;
    }

    if (isLast(root, selection)) {
      updateWithLayout(insertAfter(r, s, node), null, () => ({
        ...styles,
        [style.id]: style,
        ...otherStyles
      }));
    } else {
      updateWithLayout(appendChild(r, s, node), null, () => ({
        ...styles,
        [style.id]: style,
        ...otherStyles
      }));
    }

    setSelection(node.id);
  };

  const handleDragOver = useDebouncedCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    const reactFlowBounds = containerRef.current.getBoundingClientRect();

    if (!droppable) {
      return;
    }

    const { type: actionType, name: type } = droppable;
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const [node, style, otherStyles] = createNode({
      type,
      actionType,
      temp: true,
      children: [],
      translate: p,
    });

    const closest = getClosestNode(root, styles, { position });

    if (!root) {
      updateWithLayout(node, null, () => ({
        ...styles,
        [style.id]: style,
        ...otherStyles
      }));
    }

    if (!closest || actionType === 'entry_point') {
      return;
    }

    const children = getChildren(root, closest.id);

    if (children.some(({ id }) => styles[id]?.temp)) {
      return;
    }
    let r = clearTempNodes(root, styles);
    let s = closest.id;

    if (closest.type === 'array') {
      r = openArrayAt(r, closest.id);
      s = getLastNode(closest).id;
    }

    updateWithLayout(insertAfter(r, s, node), null, () => ({
      ...styles,
      [style.id]: style,
      ...otherStyles
    }), layoutFlow, true);
  }, 50, { leading: true, trailing: false, maxWait: 50 });

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    if (!droppable || !root) {
      return;
    }

    const temp = getTempNode(root, styles);

    updateWithLayout(root, null, s => Object.fromEntries(Object.entries(s).map(([key, value]) => [key, ({ ...value, temp: false })])));

    setSelection(temp.id);
    setDroppable(null);
  };

  const pasteNodes = ([n]) => {
    if (!selection) {
      return;
    }

    if (n.data.actionType === 'entry_point' && styles[root.id]?.actionType === 'entry_point') {
      return;
    }

    const [node, style, otherStyles] = createNode({ type: n.data.name, data: n.data, actionType: n.data.actionType, translate: p });
    updateWithLayout(insertAfter(root, selection, node), null, () => ({
      ...styles,
      [style.id]: style,
      ...otherStyles
    }));
  };

  const cutNodes = () => {
    deleteSelectedNode({ id: selection });
  };

  const minimize = () => {
    updateWithLayout(root => createArrayFrom(root, selection, p), null, styles);
  };

  const maximize = () => {
    updateWithLayout(root => openArrayAt(root, selection), null, styles);
  };

  const changeNodeData = (id, data, { label, description, ...stylesData } = {}) => {
    let newStyles = { ...styles, [id]: { ...(styles[id] || {}), data: stylesData, label: label || styles[id]?.label, description: description || styles[id]?.description } };
    const updated = updateNode(root, id, node => ({ ...node, data: { ...update(node.data, data), serverId: node.data.serverId } }));

    const withDuplicateData = data.name ===  WorkflowNodes.SWITCH_FILTER ? duplicateDataToChild(updated, id) : updated;
    
    const withSplitters = data.name === WorkflowNodes.AB_SPLITTER ? updateSplitters(updated, id, newStyles, (updated) => {
      newStyles = { ...updated }
    }, p) : withDuplicateData;



    const withNextNodes = data.nextNodes ? compose(...getChildren(withSplitters, id).map(child => root => {
      if (child.children[0]?.type !== data.nextNodes) {
        const [node, style, otherStyles] = createNode({
          type: data.nextNodes,
          actionType: data.nextNodesType,
          children: [],
          translate: p,
        });
        newStyles = { ...newStyles, [node.id]: style, ...otherStyles };

        return insertAfter(root, child.id, node);
      }

      return root;
    }))(withSplitters) : withSplitters;

    updateWithLayout(withNextNodes, null, { ...styles, ...newStyles }, layoutFlow, true, false, { save: true });
  };

  const deleteSelectedNode = ({ id }) => {
    const parent = getParent(root, id);

    updateWithLayout(appendFinishNodes(removeChild(root, id), { translate: p }), id, identity);

    if (parent) {
      setSelection(parent.id);
    }
  };

  const deleteBranchFromSelected = ({ id }) => {
    const parent = getParent(root, id);

    updateWithLayout(appendFinishNodes(removeBranchFrom(root, id), { translate: p }), id, styles);

    if (parent) {
      setSelection(parent.id);
    }
  };

  const resolveMinimizeStatus = () => {
    if (!editable) {
      return 'disabled';
    }

    if (selection) {
      const node = getNode(root, selection);


      if (styles[selection]?.actionType === 'entry_point') {
        return 'disabled';
      }

      return node?.type === 'array' ? 'maximize' : 'minimize';
    }

    return 'disabled';
  };

  const isClose = (p1, p2) => {
    const diffX = Math.abs(p1.x - p2.x);
    const diffY = Math.abs(p1.y - p2.y);

    return diffX < 30 && diffY < 30;
  };

  const renderNodes = useMemo(() => {
    if (!root || !styles) {
      return [];
    }

    return toReactFlowNodesArray(root, styles);
  }, [styles, nodesState]);

  const renderNodesUnwrapped = useMemo(() => {
    return toReactFlowNodesArray(unwrapArrays(root, true), layoutFlow(unwrapArrays(root, true), styles));
  }, [styles, nodesState, root]);

  const getRenderNodesUnwrapped = () => {
    return toReactFlowNodesArray(unwrapArrays(root, true), layoutFlow(unwrapArrays(root, true), styles));
  };

  const getAllArraysOpened = () => {
    let opened = root;
    let arrayAt = findArray(opened);

    while (arrayAt) {
      opened = openArrayAt(opened, arrayAt);
      arrayAt = findArray(opened);
    }

    return toReactFlowNodesArray(opened, layoutFlow(opened, styles));
  };

  const removeEdge = (id) => {
    const connector = renderNodes.find(by(id));

    updateWithLayout(root, connector.target, s => ({...s, connectorRemoved: [...(s.connectorRemoved || []), connector.source] }), (_, s) => s);
  };

  const onConnect = ({ source, target }) => {
    const targetNode = getNode(root, target);

    let removed = root;

    if (getParentsArray(root, source).some(parent => !!~parent.children.findIndex(by(target)))) {
      removed = removeBranchFrom(root, target);
    }

    const appended = appendChild(removed, source, {
      ...targetNode,
      data: { ...targetNode.data, serverId: uuidv4() },
    });

    updateWithLayout(appended, null, s => ({
      ...s,
      [targetNode.id]: {
        ...(s[targetNode.id] || {}),
        connectorRemoved: (s[targetNode.id].connectorRemoved || []).filter(id => id !== source),
      }
    }));
  };

  const dragNodes = () => {
    const state = getState();
    const ns = renderNodes.filter(isNode);

    const invalid = state.filter(sn =>
      ns.find(n => sn.id !== n.id && isClose({ x: sn?.__rf?.position?.x || 0, y: sn?.__rf?.position?.y || 0 }, n.position))
    ).map(extract('id'));

    iterate(root, ({ id }) => {
      const found = state.find(by(id));

      if (!found || found.type === WorkflowNodeTypes.FINISH) {
        return;
      }

      if (invalid.indexOf(id) !== -1 && styles[found.id]) {
        // actions['updateNodePos']({ id, pos: { x: styles[found.id].position.x + Math.random() / 1000, y: styles[found.id].position.y } });
      }
    });

    iterate(root, ({ id }) => {
      const found = state.find(by(id));

      if (!found || found.type !== WorkflowNodeTypes.FINISH) {
        return;
      }

      const parent = getParent(root, id);

      if (!!styles[parent.id]) {
        // actions['updateNodePos']({ id, pos: { x: styles[parent.id].position.x + Math.random() / 1000 + 60, y: styles[parent.id].position.y } });
      }
    });

    if (invalid.length) {
      updateWithLayout(root, null, styles, layoutFlow, true);
    }
    setIsDragging(false);
  };

  const onDrag = ((event) => {
    setIsDragging(true);
  });

  const removeTemplatesFields = (idMap) => {
    const newStyles = { ...styles };

    const onRemove = (id) => {
      newStyles[id].data.validated = false;
      newStyles[id].data.validation = { valid: false };
    };

    updateWithLayout(removeDataFields(root, idMap, onRemove), null, newStyles, layoutFlow, true, true);
  };

  const onNodeClick = (e, node) => {
    setSelection(node.id);
  };

  const exitPointsCount = getExitPointsCount(root);

  const validated = !Object.values(styles).some(style => style?.data?.validated === false);
  const entryPointSelected = !!root && styles[root.id]?.actionType === 'entry_point';
  const connectionsValid = isConnectorsValid(root, styles);

  return (
    <WorkflowEditorContext.Provider
      value={{
        fitView: reactFlowInstance?.fitView,
        instance: reactFlowInstance,
        state: {
          root,
        },
        renderNodes: renderNodes,
        renderNodesUnwrapped,
        actions: {
          undo,
          canUndo,
          canRedo,
          redo,
          minimize,
          maximize,
        },
        onLoad: handleLoad,
        addNode,
        selection,
        updateWithLayout,
        removeEdge,
        connect: onConnect,
        minimizeStatus: resolveMinimizeStatus(),
        onDrop: handleDrop,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        changeSelection,
        containerRef,
        changeNodeData,
        addOnUpdateListener,
        removeOnUpdateListener,
        deleteSelectedNode,
        deleteBranchFromSelected,
        pasteNodes,
        validated,
        cutNodes,
        dragNodes,
        onDrag,
        isDragging,
        editable,
        setEditable,
        setDroppable,
        saveCachedNodes,
        openedNodeSettings,
        setOpenedNodeSettings,
        openedNodeDiff,
        removeTemplatesFields,
        setOpenedNodeDiff,
        connectionsValid,
        entryPointSelected,
        sidebarOpened,
        onNodeClick,
        exitPointsCount,
        setSidebarOpened,
        getRenderNodesUnwrapped,
        getAllArraysOpened,
        styles,
        name, setName,
        description, setDescription,
      }}
    >
      {children}
    </WorkflowEditorContext.Provider>
  );
};

export default WorkflowEditorProvider;
