import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateCell, ViewViberTemplateModal, Link, SortableTitle, Table, WithPermissions } from '@components';
import { StatusCell } from '../StatusCell';
import { useModalState, useTranslation } from '@hooks';
import { Paths, SmsStatusLabels, UserPermissions, ViberStatuses } from '@constants';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import {templateSelector, vibersLoadingSelector, vibersSelector} from '@store/selectors';
import { ViberActions } from '../ViberActions';
import { IdType } from '@components/lib/styled';
import { extract } from '@utils';
const resolveStatus = status => SmsStatusLabels[status];

const resolveColor = status => ({
  [ViberStatuses.SENT]: '#24993E',
  [ViberStatuses.QUEUED]: '#909399',
  [ViberStatuses.DELIVERED]: '#24993E',
  [ViberStatuses.SENDING]: '#909399',
  [ViberStatuses.UNDELIVERED]: '#C8402F',
  [ViberStatuses.CLICK]: '#24993E',
  [ViberStatuses.FAILED]: '#C8402F',
})[status];

const ViberTable = ({ keyExtractor, cols, ordering }) => {
  const vibers = useSelector(vibersSelector);
  const loading = useSelector(vibersLoadingSelector);
  const templates = useSelector(templateSelector);
  const viberInfoModal = useModalState();
  const [currentViber, setCurrentViber] = useState({});
  const { t, p } = useTranslation('notification_history')

  const createViberTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => <div>
        <FlexibleText text={id} maxWidth={130}>
          <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
            <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
          </WithPermissions>
        </FlexibleText>
        <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
      </div>,
    },
    {
      title: () => <SortableTitle name="number" ordering={ordering}>{t('labels.phone')}</SortableTitle>,
      dataIndex: 'number',
      className: `notification-history-cell-phone`,
      key: 'number',
      render: (number) => <FlexibleText text={number} maxWidth={130}>{number}</FlexibleText>
    },
    {
      title: () => <SortableTitle name="template_id" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (id, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_VIBER}><Link to={`${Paths.TEMPLATES_VIBER}/${data.template_id}?view=1`}>{templates?.viber?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      key: 'created_at',
      className: `notification-history-cell-date`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)}  />
      }
    },
    // {
    //   title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
    //   dataIndex: 'r',
    //   key: 'response',
    //   render: (r, { s }) => {
    //     if (!r) {
    //       return null;
    //     }
    //
    //     return (
    //       <Dropdown
    //         trigger={['hover']}
    //         overlay={(
    //           <Overlay>
    //             <pre>
    //               {tryHumanizeResponse(r)}
    //             </pre>
    //           </Overlay>
    //         )}
    //       >
    //         <div>
    //           <Icon color={resolveStatus(s)} name="Info-icon" size={16} />
    //         </div>
    //       </Dropdown>
    //     )
    //   },
    // },
  ];

  const createActionColumn = (callbacks) => ({
    title: () => <SortableTitle right={true}>{t('labels.actions')}</SortableTitle>,
    key: 'actions',
    fixed: 'right',
    className: `notification-history-cell-actions`,
    render: (a, data) => <ViberActions onViberInfoClick={() => callbacks.onViberInfoClick(data)} />
  })

  const viberExtraColumns = [
    {
      title: () => <SortableTitle>{t('labels.updated_at')}</SortableTitle>,
      dataIndex: 'updated_at',
      key: 'updated_at',
      className: `notification-history-cell-updated_at`,
      render: (date) => <DateCell date={date} />
    },
    // {
    //   title: () => <SortableTitle>{t('labels.group')}</SortableTitle>,
    //   dataIndex: 'g',
    //   key: 'group',
    //   render: (date) => <DateCell date={date} />
    // },
  ];

  const columns = createViberTableColumns(ordering);

  const handleViberInfoClick = (data) => {
    setCurrentViber({ name: data.viber_name, title: data.title, image_url: data.image, btn_name: data.button_name, body: data.message });
    viberInfoModal.open();
  }

  viberExtraColumns.forEach((args) => cols.indexOf(args.key) !== -1 && columns.push(args));

  columns.push(createActionColumn({
    onViberInfoClick: handleViberInfoClick,
  }))

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NH_VIBER} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={vibers.data?.map?.(extract('id')).join()}
          columns={columns}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          dataSource={vibers?.data}
          rowKey={keyExtractor}
        />
        <ViewViberTemplateModal
          opened={viberInfoModal.opened}
          onClose={viberInfoModal.close}
          data={currentViber || {}}
        />
      </WithPermissions>
    </>
  )
}

export default ViberTable;

