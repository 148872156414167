import { ReducerKeys } from '@constants';

export const emailsSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].emails;

export const smsSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].sms;

export const templateSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].templates;

export const emailsLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].emails.loading;

export const smsLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].sms.loading;

export const facebookHistorySelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].facebook;

export const facebookHistoryLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].facebook.loading;

export const vibersLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].vibers.loading;

export const vibersSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].vibers;

export const webhookLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].webhook.loading;

export const webhookSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].webhook;

export const webpushLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].webpush.loading;

export const webpushSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].webpush;

export const mobilePushSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].mobilePushes;

export const mobilePushLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].mobilePushes.loading;

export const promoCodesHistorySelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].promoCodes;

export const promoCodesHistoryLoadingSelector = (store) => store[ReducerKeys.HISTORY_NOTIFICATIONS].promoCodes.loading;

export const notificationHistoryCountsSelector = store => store[ReducerKeys.HISTORY_NOTIFICATIONS].counts;
