import React from 'react';
import { useSelector } from 'react-redux';
import { fieldsSettingsListSelector, subscriptionGroupsSelector } from '@store/selectors';
import { FunnelOperatorOptions, SegmentArrayOperatorOptions, SegmentsRuleTypes } from '@constants';
import { SearchSelect, Select } from '@components';
import { by, update } from '@utils';
import { SubFilterRule } from '../SubFilterRule';
import { useTranslation } from '@hooks';

import { Container, Row } from './styled';

const ArraySelect = ({ query, onChange, fieldDisabled, funnel, showErrors, appearance }) => {
  const { p, t } = useTranslation('segments_page');
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const options = fieldSettings.data?.filter?.(f => f.type === 'array')?.map?.(f => ({ value: f.field, label: f.label }));
  const field = fieldSettings.data?.find?.(by('field', query.field));
  const nestedAggregates = field?.nested_aggregates || [];
  const filterOptions = field?.payload?.flatMap?.(({ field, label, type, payload }) =>
    [({ value: field, label, type, key: field })]
      .concat(type === 'array' ? payload.map(({ field: innerField, label: innerLabel, type }) =>
        ({ value: innerField, label: innerLabel, type, prefix: label, nested: field, key: `${field}.${innerField}` })) : [])
  ).concat(nestedAggregates.length ? nestedAggregates.map(({ label, name, id }) => ({ label, value: name,  id, type: 'aggregate' })) : []) || [];
  const filters = query.filters?.flatMap((f) => f.type === 'array' ? f.filters.map(ff => ({ ...ff, nested: f.field })) : f);

  const subscribeGroups = useSelector(subscriptionGroupsSelector);

  const autoComplete = {
    ...(field?.autocomplete || {}),
    '_i': subscribeGroups?.data?.map?.(({ id, name }) => ({ value: id, label: name })),
  }

  const handleFieldChange = (field) => {
    onChange(q => ({ ...q, query: { ...q.query, field, filters: [{ field: '', operator: '', value: { type: 'scalar' } }] } }));
  };

  const handleFiltersChange = (changeFn) => {
    const changed = update({ query: { ...query, filters }  }, changeFn);

    onChange(q => ({
      ...q,
      query: {
        ...changed.query,
        filters: changed.query.filters.reduce((acc, { nested, ...filter }) => {
          if (!nested) {
            return [...acc, filter];
          }

          const nestedFilterAt = acc.findIndex(({ field, filters }) => (field === nested) && filters);

          if (!!~nestedFilterAt) {
            return acc.map((f, i) => i === nestedFilterAt ? { ...f, filters: [...f.filters, filter] } : f);
          }

          return [
            ...acc,
            {
              type: 'array',
              field: nested,
              filters: [filter],
            }
          ]
        }, []),
      }
    }));
  };

  const handleLogicalOperatorChange = (logicalOperator) => {
    if (logicalOperator === 'and' || logicalOperator === 'or') {
      return onChange(q => ({
        ...q,
        query: {
          ...q.query,
          logicalOperator,
          ruleType: SegmentsRuleTypes.ARRAY,
          operator: undefined,
          filters: !q.query.filters?.filter(({ hidden }) => !hidden)?.length ? [{ field: '', operator: '', value: { type: 'scalar' } }] : q.query.filters,
        }
      }));
    }

    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        ruleType: SegmentsRuleTypes.LINEAR_ARRAY,
        logicalOperator: undefined,
        operator: logicalOperator,
        filters: undefined,
      }
    }));
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-array-container-${query.name}`}>
      <Row $appearance={appearance}>
        <SearchSelect
          testId={`store-segment-field-array-select-${query.name}`}
          style={{  width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
          options={options || []}
          label={p('array')}
          tooltip={(funnel && fieldDisabled) ? p('array_select_description') : ''}
          tooltipError={showErrors && query.errors?.['field']}
          showInfoIcon={funnel && fieldDisabled}
          value={query?.field}
          disabled={fieldDisabled}
          onChange={handleFieldChange}
        />
        <SearchSelect
          testId={`store-segment-field-array-operator-select-${query.name}`}
          wrapperStyles={{marginLeft: appearance !== 'column' ? 6 : 0 }}
          style={{ width: appearance !== 'column' ? 186 : 'auto', background: '#F9FBFF' }}
          label={t('labels.array_field_operator')}
          tooltipError={showErrors && query.errors?.['logicalOperator'] || query.errors?.['operator']}
          options={funnel ? FunnelOperatorOptions(p) : SegmentArrayOperatorOptions(p)}
          value={query.logicalOperator || query.operator}
          onChange={handleLogicalOperatorChange}
        />
      </Row>
      {(query.logicalOperator === 'and' || query.logicalOperator === 'or') && (
        <SubFilterRule
          testId={`store-segment-field-array-container-${query.name}-sub-filter`}
          options={filterOptions}
          value={filters}
          nestedAggregates={nestedAggregates}
          type="array"
          appearance={appearance}
          autocomplete={autoComplete}
          query={query}
          disableFirstDelete={funnel || query.logicalOperator === 'and' || query.logicalOperator === 'or'}
          showErrors={showErrors}
          ruleType={query.ruleType}
          onChange={handleFiltersChange}
        />
      )}
    </Container>
  );
};

export default ArraySelect;
