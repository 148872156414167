import React from 'react';
import { Button, Modal } from "@components";

const ConfirmationModal = ({
  appearance,
  wrapClassName = '',
  onCancel,
  onClose,
  title,
  opened,
  zIndex = undefined,
  testId = '',
  content = null,
  onSubmitClick,
  containerProps = {},
  buttonsParams = {},
  getContainer = (c) => c,
  text,
}) => {
  return (
    <Modal
      onCancel={onCancel}
      containerProps={containerProps}
      opened={opened}
      onClose={onClose}
      zIndex={zIndex}
      getContainer={getContainer}
      wrapClassName={wrapClassName}
      title={title}
      appearance={appearance}
      actions={(
        <>
          <Button testId={buttonsParams.cancelTestId || ''} onClick={onCancel} appearance={buttonsParams.cancelAppearance} width={140}>{buttonsParams.cancelLabel}</Button>
          <Button testId={buttonsParams.submitTestId || ''} appearance={buttonsParams.submitAppearance} onClick={onSubmitClick} width={140}>{buttonsParams.submitLabel}</Button>
        </>
      )}
    >
      <div data-testid={testId}>
        <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
          {content || text}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal;
