import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { redirect } from 'react-router';
import { IntegrationPresets, Paths } from '@constants';

const parseState = (state) => {
  try {
    return JSON.parse(decodeURIComponent(state));
  } catch (e) {
    return { id: 'new' };
  }
}

const FacebookRedirect = () => {
  const [state] = useQueryParam('state', StringParam);

  const parsed = parseState(state);

  return redirect(`${Paths.INTEGRATIONS}/${IntegrationPresets.FACEBOOK_ADS}/${parsed.id}${window.location.search}`);
};

export default FacebookRedirect;
