import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  //position: fixed;
  //top: 128px;
  min-width: 320px;
  max-width: 320px;
  min-height: 459px;
  max-height: 79vh;
  margin-top: 10px;
  //overflow-y: scroll;
  background: #F9FBFF;
  display: flex;
  flex-direction: column;
  flex: 0;
  border-radius: 12px;
  padding: 14px 0px 0 20px;
  border: 1px solid rgba(189, 203, 250, 1);
  
  position: relative;
    
  ${styledProp('fixed', `
     position: fixed;
     top: 0;
     max-height: 97vh;
  `)};

  @media screen and (max-width: 1289px) {
    position: fixed;
    right: 0;
    top: auto !important;
    max-height: 79vh !important;
    transition: all 370ms ease;
    min-height: 220px;

    ${styledProp('$opened', css`
      transform: translateX(-20px);
    `, css`
      transform: translateX(100%);
    `)}
  }
  
`;

export const HideButton = styled.div`
  display: none;
  @media screen and (max-width: 1289px) {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: -16px;
  background: #F9FBFF;
  border: 1px solid rgba(204, 215, 255, 1);
  border-radius: 7px 0 0 7px;
`;

export const HideIcon = styled.div`
    display: flex;
    flex: 0;
    transition: all 370ms ease;

    ${styledProp('$opened', css`
      transform: rotateY(180deg) translateX(-1px);
    `, css`
    `)}
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
`;

export const LogicalOperatorIndicator = styled.div`
  text-transform: uppercase;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  margin: 0 5px;
  cursor: pointer;
  user-select: none;
  
  ${styledProp('$blue', css`
    color: #109DFB;
    margin-left: 0;
    margin-right: 3px;
  `)}

  ${styledProp('$dragging', css`
    opacity: 0;
  `)}
`;

export const RuleIndicator = styled.div`
  font-family: Rubik-Regular;
  font-weight: 400;
  color: #109DFB;
  border: 1px solid #109DFB;
  border-radius: 50%;
  font-size: 13px;
  line-height: 120%;
  background: #fff;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormulaRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  min-height: 22px;
  margin-bottom: 14px;
  padding-bottom: 6px;
  padding-right: 6px;

  &::-webkit-scrollbar {
    height: 16px;
  }

  &::-webkit-scrollbar-track {
    height: 16px;
    background: #F0F2F6;
    border-radius: 4px;
    //padding: 3px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 10px;
    background-color: #DCDFE6;
    margin: 3px 0;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #DCDFE6;
    border-radius: 10px;
  }
`;

export const Negation = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #C8402F;
  margin-right: 3px;
  user-select: none;

  ${styledProp('$dragging', css`
    opacity: 0;
  `)}
`;

export const NoData = styled.div`
    font-family: Rubik-Regular;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #909399;
    padding: 0 44px;
    margin-top: 70px;
`;
