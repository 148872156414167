const IntegrationsActionTypes = {
  LIST: 'integrations/LIST',
  LIST_SUCCESS: 'integrations/LIST_SUCCESS',
  LIST_ERROR: 'integrations/LIST_ERROR',

  MY_LIST: 'integrations/MY_LIST',
  MY_LIST_SUCCESS: 'integrations/MY_LIST_SUCCESS',
  MY_LIST_ERROR: 'integrations/MY_LIST_ERROR',

  SAVE_CONNECTION: 'integrations/SAVE_CONNECTION',
  SAVE_CONNECTION_SUCCESS: 'integrations/SAVE_CONNECTION_SUCCESS',
  SAVE_CONNECTION_ERROR: 'integrations/SAVE_CONNECTION_ERROR',

  GET_JS_INTEGRATION_META: 'integrations/GET_JS_INTEGRATION_META',
  GET_JS_INTEGRATION_META_SUCCESS: 'integrations/GET_JS_INTEGRATION_META_SUCCESS',
  GET_JS_INTEGRATION_META_ERROR: 'integrations/GET_JS_INTEGRATION_META_ERROR',

  UPDATE_JS_INTEGRATION: 'integrations/UPDATE_JS_INTEGRATION',
  UPDATE_JS_INTEGRATION_SUCCESS: 'integrations/UPDATE_JS_INTEGRATION_SUCCESS',
  UPDATE_JS_INTEGRATION_ERROR: 'integrations/UPDATE_JS_INTEGRATION_ERROR',

  CHECK_POSTGRES_CONNECTION: 'integrations/CHECK_POSTGRES_CONNECTION',
  CHECK_POSTGRES_CONNECTION_SUCCESS: 'integrations/CHECK_POSTGRES_CONNECTION_SUCCESS',
  CHECK_POSTGRES_CONNECTION_ERROR: 'integrations/CHECK_POSTGRES_CONNECTION_ERROR',

  PREVIEW_POSTGRES_CONNECTION: 'integrations/PREVIEW_POSTGRES_CONNECTION',
  PREVIEW_POSTGRES_CONNECTION_SUCCESS: 'integrations/PREVIEW_POSTGRES_CONNECTION_SUCCESS',
  PREVIEW_POSTGRES_CONNECTION_ERROR: 'integrations/PREVIEW_POSTGRES_CONNECTION_ERROR',

  GET_POSTGRES_DATASYNC_LOG: 'integrations/GET_POSTGRES_DATASYNC_LOG',
  GET_POSTGRES_DATASYNC_LOG_SUCCESS: 'integrations/GET_POSTGRES_DATASYNC_LOG_SUCCESS',
  GET_POSTGRES_DATASYNC_LOG_ERROR: 'integrations/GET_POSTGRES_DATASYNC_LOG_ERROR',

  UPDATE_POSTGRES_INTEGRATION: 'integrations/UPDATE_POSTGRES_INTEGRATION',
  UPDATE_POSTGRES_INTEGRATION_SUCCESS: 'integrations/UPDATE_POSTGRES_INTEGRATION_SUCCESS',
  UPDATE_POSTGRES_INTEGRATION_ERROR: 'integrations/UPDATE_POSTGRES_INTEGRATION_ERROR',

  CHECK_MYSQL_CONNECTION: 'integrations/CHECK_MYSQL_CONNECTION',
  CHECK_MYSQL_CONNECTION_SUCCESS: 'integrations/CHECK_MYSQL_CONNECTION_SUCCESS',
  CHECK_MYSQL_CONNECTION_ERROR: 'integrations/CHECK_MYSQL_CONNECTION_ERROR',

  PREVIEW_MYSQL_CONNECTION: 'integrations/PREVIEW_MYSQL_CONNECTION',
  PREVIEW_MYSQL_CONNECTION_SUCCESS: 'integrations/PREVIEW_MYSQL_CONNECTION_SUCCESS',
  PREVIEW_MYSQL_CONNECTION_ERROR: 'integrations/PREVIEW_MYSQL_CONNECTION_ERROR',

  GET_MYSQL_DATASYNC_LOG: 'integrations/GET_MYSQL_DATASYNC_LOG',
  GET_MYSQL_DATASYNC_LOG_SUCCESS: 'integrations/GET_MYSQL_DATASYNC_LOG_SUCCESS',
  GET_MYSQL_DATASYNC_LOG_ERROR: 'integrations/GET_MYSQL_DATASYNC_LOG_ERROR',

  UPDATE_MYSQL_INTEGRATION: 'integrations/UPDATE_MYSQL_INTEGRATIONS',
  UPDATE_MYSQL_INTEGRATION_SUCCESS: 'integrations/UPDATE_MYSQL_INTEGRATIONS_SUCCESS',
  UPDATE_MYSQL_INTEGRATION_ERROR: 'integrations/UPDATE_MYSQL_INTEGRATIONS_ERROR',

  UPDATE_DB_INTEGRATION_CONNECTIONS_META: 'integrations/UPDATE_DB_INTEGRATION_CONNECTIONS_META',
  UPDATE_DB_INTEGRATION_CONNECTIONS_META_SUCCESS: 'integrations/UPDATE_DB_INTEGRATION_CONNECTIONS_META_SUCCESS',
  UPDATE_DB_INTEGRATION_CONNECTIONS_META_ERROR: 'integrations/UPDATE_DB_INTEGRATION_CONNECTIONS_META_ERROR',

  GENERATE_FACEBOOK_LOGIN_URL: 'integration/GENERATE_FACEBOOK_LOGIN_URL',
  GENERATE_FACEBOOK_LOGIN_URL_SUCCESS: 'integration/GENERATE_FACEBOOK_LOGIN_URL_SUCCESS',
  GENERATE_FACEBOOK_LOGIN_URL_ERROR: 'integration/GENERATE_FACEBOOK_LOGIN_URL_ERROR',

  GENERATE_FACEBOOK_TOKEN: 'integrations/GENERATE_FACEBOOK_LOGIN_TOKEN',
  GENERATE_FACEBOOK_TOKEN_SUCCESS: 'integrations/GENERATE_FACEBOOK_LOGIN_TOKEN_SUCCESS',
  GENERATE_FACEBOOK_TOKEN_ERROR: 'integrations/GENERATE_FACEBOOK_LOGIN_TOKEN_ERROR',

  REMOVE_CONNECTION_FROM_STORE: 'integrations/REMOVE_CONNECTION_FROM_STORE',
  REMOVE_CONNECTION_FROM_STORE_SUCCESS: 'integrations/REMOVE_CONNECTION_FROM_STORE_SUCCESS',
  REMOVE_CONNECTION_FROM_STORE_ERROR: 'integrations/REMOVE_CONNECTION_FROM_STORE_ERROR',

  GET_FACEBOOK_AD_ACCOUNT: 'integrations/GET_FACEBOOK_AD_ACCOUNT',
  GET_FACEBOOK_AD_ACCOUNT_SUCCESS: 'integrations/GET_FACEBOOK_AD_ACCOUNT_SUCCESS',
  GET_FACEBOOK_AD_ACCOUNT_ERROR: 'integrations/GET_FACEBOOK_AD_ACCOUNT_ERROR',

  GET_FACEBOOK_AUDIENCES: 'integrations/GET_FACEBOOK_AUDIENCES',
  GET_FACEBOOK_AUDIENCES_SUCCESS: 'integrations/GET_FACEBOOK_AUDIENCES_SUCCESS',
  GET_FACEBOOK_AUDIENCES_ERROR: 'integrations/GET_FACEBOOK_AUDIENCES_ERROR',

  GET_FACEBOOK_AUDIENCES_WITH_CLEAR_SUCCESS: 'integrations/GET_FACEBOOK_AUDIENCES_WITH_CLEAR_SUCCESS',

  SAVE_FACEBOOK_INTEGRATION_CONNECTION: 'integrations/SAVE_FACEBOOK_INTEGRATION_CONNECTION',
  SAVE_FACEBOOK_INTEGRATION_CONNECTION_SUCCESS: 'integrations/SAVE_FACEBOOK_INTEGRATION_CONNECTION_SUCCESS',
  SAVE_FACEBOOK_INTEGRATION_CONNECTION_ERROR: 'integrations/SAVE_FACEBOOK_INTEGRATION_CONNECTION_ERROR',

  CHANGE_INTEGRATION_CONNECTION_STATUS: 'integrations/CHANGE_CONNECTION_STATUS',
  CHANGE_INTEGRATION_CONNECTION_STATUS_SUCCESS: 'integrations/CHANGE_CONNECTION_STATUS_SUCCESS',
  CHANGE_INTEGRATION_CONNECTION_STATUS_ERROR: 'integrations/CHANGE_CONNECTION_STATUS_ERROR',

  DELETE_INTEGRATION_CONNECTION: 'integrations/DELETE_CONNECTION',
  DELETE_INTEGRATION_CONNECTION_SUCCESS: 'integrations/DELETE_CONNECTION_SUCCESS',
  DELETE_INTEGRATION_CONNECTION_ERROR: 'integrations/DELETE_CONNECTION_ERROR',

  REQUEST_FACEBOOK_LOGIN_URL: 'integration/REQUEST_FACEBOOK_LOGIN_URL',
  REQUEST_FACEBOOK_LOGIN_URL_SUCCESS: 'integration/REQUEST_FACEBOOK_LOGIN_URL_SUCCESS',
  REQUEST_FACEBOOK_LOGIN_URL_ERROR: 'integration/REQUEST_FACEBOOK_LOGIN_URL_ERROR',

  LOGOUT_FROM_FACEBOOK: 'integrations/LOGOUT_FROM_FACEBOOK',
  LOGOUT_FROM_FACEBOOK_SUCCESS: 'integrations/LOGOUT_FROM_FACEBOOK_SUCCESS',
  LOGOUT_FROM_FACEBOOK_ERROR: 'integrations/LOGOUT_FROM_FACEBOOK_ERROR',

  GET_FACEBOOK_USER_INFO: 'integrations/GET_FACEBOOK_USER_INFO',
  GET_FACEBOOK_USER_INFO_SUCCESS: 'integrations/GET_FACEBOOK_USER_INFO_SUCCESS',
  GET_FACEBOOK_USER_INFO_ERROR: 'integrations/GET_FACEBOOK_USER_INFO_ERROR',

  CLEAR_DB_REQUEST_PREVIEW: 'integrations/CLEAR_DB_REQUEST_PREVIEW',
};

export default IntegrationsActionTypes;
