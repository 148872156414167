import React, {useState} from "react";

import ForType from "./ForType";
import ForValue from "./ForValue";

const For = (props) => {

    return (<div>
        <ForType expression={props.expression}/>
        <ForValue expression={props.expression}/>
    </div>)

};

export default For;