import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Link, Multiselect, SearchSelect, Select, TagAutoComplete, WithOnboarding } from '@components';
import {
  emailTemplateIntegrationsSelector,
  subscriptionGroupsSelector
} from '@store/selectors';
import {
  getSubscriptionGroups,
  listIntegrations,
  myListIntegrations,
  setNewEmail,
  updateRedirects
} from '@store/actions/creators';
import { AppRedirects, Paths } from '@constants';
import { TemplatesActionTypes } from '@store/actions/types';

import { Container, NoIntegrations, Row, OpenButton, Title } from './styled';
import { useModalState, useOnboarding, useTranslation, useNavigate } from '@hooks';
import { sequence } from '@utils';
import { getFieldSettingsList } from '@store/actions/creators';
import { fieldsSettingsListSelector } from '@store/selectors';

const EmailMetaForm = ({ value, disabled, onChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const state = useModalState(true);
  const onboarding = useOnboarding('templates');
  const contactFields = useSelector(fieldsSettingsListSelector);
  const integrations = useSelector(emailTemplateIntegrationsSelector);
  const { p, t } = useTranslation('templates');
  const senders = useMemo(() => integrations.map(({ id, name }) => ({ label: name, value: id })), [integrations]);
  const receivers = useMemo(
    () => contactFields.data
      ?.sort((a, b) => a.label.localeCompare(b.label))
      ?.filter(({ type }) => type !== 'array')
      .map(({ field, label }) => ({ value: field, label })),
    [contactFields.data]
  );
  const subscriptionGroups = useSelector(subscriptionGroupsSelector);

  useEffect(() => {
    dispatch(listIntegrations());
    dispatch(myListIntegrations());
    dispatch(getSubscriptionGroups({ entity: 'EMAIL '}));
    dispatch(getFieldSettingsList());
  }, []);

  const handleSenderChange = (app_integration_id) => {
    if (onboarding.enabled(4)) {
      onboarding.nextStep();
    }

    onChange({ ...value, app_integration_id });
  };

  const handleReceiverChange = (email_fields) => {
    if (onboarding.enabled(5)) {
      onboarding.nextStep();
    }

    onChange({ ...value, email_fields });
  };

  const handleBCCChange = (bcc) => {
    if (onboarding.enabled(6)) {
      onboarding.nextStep();
    }

    onChange({ ...value, bcc });
  }

  const handleSubjectChange = ({ target: { value: subject } }) => {
    if (onboarding.enabled(7)) {
      onboarding.nextStep();
    }

    onChange({ ...value, subject });
  };

  const handleEmailGroupChange = (group_id) => {
    if (onboarding.enabled(8)) {
      onboarding.nextStep();
    }

    onChange({ ...value, group_id });
  };

  const handleAddIntegration = () => {
    dispatch(setNewEmail({ data: value }));
    dispatch(updateRedirects({
      [AppRedirects.INTEGRATION_CONNECTION_CREATION + '/message_channels_email']: {
        to: `${Paths.TEMPLATES_EMAIL}/${type}/${id}?loadCached`,
        updateAction: TemplatesActionTypes.UPDATE_NEW_EMAIL,
        mapConfig: [['id', 'app_integration_id']],
      }
    }));
    setTimeout(() => {
      navigate(`${Paths.INTEGRATIONS}?name=message_channels_email&type=sub-category`);
    }, 100);
  };

  const groupsOptions = useMemo(() => subscriptionGroups.data?.map(({ name, id }) => ({ value: id, label: name })), [subscriptionGroups]);

  return (
    <Container $opened={state.opened} onClick={!state.opened ? state.toggle : undefined}>
      <OpenButton $opened={state.opened} onClick={sequence(e => { e.stopPropagation() }, state.toggle)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3855 9.29245C11.2624 9.35399 11.2009 9.41553 11.0778 9.41553C10.9547 9.41553 10.8317 9.35399 10.7701 9.29245L8.61627 7.20014L7.93935 6.58476L7.26242 7.20014L5.17012 9.29245C5.10858 9.35399 4.9855 9.41553 4.86242 9.41553C4.73935 9.41553 4.61627 9.35399 4.55473 9.29245C4.43166 9.16937 4.37012 9.0463 4.37012 8.92322C4.37012 8.86168 4.43166 8.7386 4.55473 8.61553L8.00089 5.35399L11.3855 8.61553C11.447 8.67707 11.447 8.7386 11.5086 8.80014C11.5086 8.86168 11.5701 8.92322 11.5701 8.98476C11.5701 9.0463 11.5701 9.10783 11.5086 9.16937C11.447 9.16937 11.447 9.23091 11.3855 9.29245Z" fill="#303133"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8 0.861538C11.9425 0.861538 15.1385 4.05754 15.1385 8C15.1385 11.9425 11.9425 15.1385 8 15.1385C4.05754 15.1385 0.861538 11.9425 0.861538 8C0.861538 4.05754 4.05754 0.861538 8 0.861538ZM8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z" fill="#303133"/>
        </svg>
      </OpenButton>
      <Title $opened={state.opened}>
        {p('sending_settings')}
      </Title>
      <Row $opened={state.opened}>
        <div style={{ marginBottom: '16px' }}>
          <WithOnboarding placement="right" enabled={onboarding.enabled(4)} onboardKey="templates" text={p('choose_email_integration')}>
            <SearchSelect
              wrapperStyles={{ marginRight: '21px' }}
              // getPopupContainer={trigger => trigger.parentElement}
              label={t('labels.sender')}
              onboard={onboarding.enabled(4)}
              disabled={!!disabled}
              // title={t('labels.select')}
              options={senders || []}
              value={value.app_integration_id}
              noData={(
                <NoIntegrations>
                  {p('no_integrations_found')}
                  <Link to="#" onClick={handleAddIntegration}>{p('add_one')}</Link>
                </NoIntegrations>
              )}
              onChange={handleSenderChange}
            />
          </WithOnboarding>
        </div>
        <div style={{ }}>
          <WithOnboarding placement="right" onboardKey="templates" enabled={onboarding.enabled(5)} text={p('choose_customer_field')}>
            <Multiselect
              containerStyle={{ marginRight: '21px' }}
              title={p('send_to')}
              data-testid="email-meta-receiver"
              onboard={onboarding.enabled(5)}
              disabled={!!disabled}
              placement="bottomLeft"
              getParentElement={trigger => trigger.parentElement}
              onChange={handleReceiverChange}
              value={value.email_fields || []}
              options={receivers ? [
                ...receivers.filter(({ value }) => ~value.toLowerCase().indexOf('email')),
                ...receivers.filter(({ value }) => !~value.toLowerCase().indexOf('email')),
              ] : []}
            />
          </WithOnboarding>
        </div>
        <div style={{ marginRight: '21px' }}>
          <WithOnboarding placement="right" onboardKey="templates" enabled={onboarding.enabled(6)} next text={p('not_mandatory_field')}>
            <TagAutoComplete
              options={[]}
              onboard={onboarding.enabled(6)}
              title={p('bcc')}
              // arrowStyles={{ right: '-5px' }}
              testId="email-bbc-input"
              disabled={!!disabled}
              value={value.bcc || []}
              onChange={handleBCCChange}
          /></WithOnboarding>
        </div>
        <WithOnboarding placement="bottom" onboardKey="templates" enabled={onboarding.enabled(7)} text={p('put_the_subject')}>
          <Input
            onboard={onboarding.enabled(7)}
            disabled={!!disabled}
            wrapperStyles={{ width: '100%' }}
            title={p('subject')}
            data-testid="email-subject-input"
            value={value.subject}
            onChange={handleSubjectChange}
          />
        </WithOnboarding>
      </Row>
      <Row $opened={state.opened} style={{ marginBottom: 16 }}>
        <WithOnboarding text={p('choose_email_group')} onboardKey="templates" placement="right" enabled={onboarding.enabled(8)}>
          <SearchSelect
            onboard={onboarding.enabled(8)}
            label={p('email_group')}
            disabled={!!disabled}
            showInfoIcon={true}
            tooltip={p('transactional_emails_description')}
            value={value.group_id}
            options={groupsOptions || []}
            onChange={handleEmailGroupChange}
          />
        </WithOnboarding>
      </Row>
    </Container>
  );
};

export default EmailMetaForm;
