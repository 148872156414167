import React from 'react';
import { useSelector } from 'react-redux';
import { RFMColorsConfig, Paths, SegmentsRuleTypes } from '@constants';
import { by } from '@utils';
import {
  RFMOption,
  RFMOptionIndicator
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/ResourceNodeSettings/styled';
import { useTranslation } from '@hooks';
import { segmentsOptionsSelector } from '@store/selectors';

import { Container, Option } from './styled';
import { Description } from '@components/lib/SegmentEditor/components/FiltersDropdown/styled';

const createRFMOptionsWithColor = opts => opts.map(({ cfg, value, label }) => ({
  value,
  label: (
    <RFMOption>
      <RFMOptionIndicator $color={cfg?.color?.ui} />
      {label}
    </RFMOption>
  )
}));

const sortOption = (a, b) => a.cfg?.order - b.cfg.order;
const sortSub = (a, b) => a.rank = b.rank;

const RFMSegmentsRightPanel = ({ selectedAnalytics, onSelect }) => {
  const { t, p } = useTranslation('rfm_analytics');
  const options = useSelector(segmentsOptionsSelector);

  const opts = createRFMOptionsWithColor((((options?.fields?.rfmResources || []).find(by(selectedAnalytics)))?.resources || [])
    .filter(({ parent_segment_id }) => !parent_segment_id)
    .flatMap(({ segment_id, name: parentName, monetary_aggregate, segment_query }) => [{
      value: { id: segment_id, query: segment_query, analytics: selectedAnalytics },
      label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', parentName))?.title}`),
      cfg: RFMColorsConfig.find(by('name', parentName)),
    }].concat((((options?.fields?.rfmResources || []).find(by(selectedAnalytics)))?.resources || [])
      .filter(by('parent_segment_id', segment_id))
      .map(({ segment_id, name, segment_query }) => {
        const monetaryValues = (monetary_aggregate || []).find(by('monetary_ranks', +name[name.length - 1]));

        return {
          value: { id: segment_id, query: segment_query, analytics: selectedAnalytics },
          label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', parentName))?.title}`)} ${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
          cfg: RFMColorsConfig.find(by('name', parentName)),
          rank: +name[name.length - 1],
        };
      })
      .sort(sortSub)))
    .sort(sortOption));


  return (
    <Container>
      {opts.map(({ value, label }) => (
        <Option key={value?.id} onClick={() => { onSelect(value, SegmentsRuleTypes.RFM_SEGMENT); }}>
          {label}
        </Option>
      ))}

      {!(options?.fields?.rfmResources || []).length && (
        <Description style={{ marginTop: 80 }}>
          {p('no_rfm_created')}
          {/*<CreateAggregateButton style={{ marginTop: 12 }} onClick={() => history.push(Routes.RFM_ANALYTICS)}>{p('create_rfm')}</CreateAggregateButton>*/}
        </Description>
      )}
    </Container>
  );
};

export default RFMSegmentsRightPanel;
