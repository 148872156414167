import React, { useEffect } from 'react';

import { DeleteButton, AddExitPointButton } from './components';
import {
  Container,
  Header,
  Content,
  Title,
  Row,
  TagClose,
  TagContainer,
  Tags,
  TagText,
  NoEntryPointTooltip,
} from './styled';
import { useTranslation } from '@hooks';
import { by, extract } from '@utils';
import { getExitPoints } from '@utils/workflows/refactored/getters';
import uniqBy from 'lodash.uniqby';

const WorkflowSettings = ({ value, onChange, onDelete, options, editable }) => {
  const { p, t } = useTranslation('workflow_page');
  const workflow = (options.workflows || []).find(by(value.trigger_workflow_id));

  const exitPoints = uniqBy(getExitPoints(workflow?.tree_map), extract('id')).filter(ep => (value.trigger_workflow_node_ids || []).indexOf(ep.id) === -1).map(({ id, data }) => ({
    id,
    label: data.label || id,
  }));

  useEffect(() => {
    if (value.trigger_workflow_id && !value.trigger_workflow_node_ids?.length && exitPoints?.length) {
      onChange(s => ({
        ...s,
        trigger_workflow_node_ids: exitPoints?.map(ep => ep.id),
      }));
    }
  }, [value.trigger_workflow_id])

  const handleAddExitPoint = (exitPoint) => {
    onChange(s => ({
      ...s,
      trigger_workflow_node_ids: [...(s.trigger_workflow_node_ids || []), exitPoint],
    }));
  };

  const handleDeleteWorkflow = () => {
    onDelete();
  };

  const eps = uniqBy(getExitPoints(workflow?.tree_map), extract('id'));

  const tagRender = (v) => {
    const handleClose = () => {
      if (!editable) {
        return;
      }
      const tags = value?.trigger_workflow_node_ids?.filter(ep => ep !== v);

      onChange(s => ({
        ...s,
        trigger_workflow_node_ids: tags,
      }));
    }

    return (
      <TagContainer>
        <TagText>{eps?.find(e => e.id === v)?.data?.label}</TagText>
        <TagClose onClick={handleClose}>X</TagClose>
      </TagContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Title>
          {workflow?.name}
        </Title>
        <Row>
        <DeleteButton style={{ marginLeft: 20 }} onClick={handleDeleteWorkflow} />
        </Row>
      </Header>
      <Content $h={value.hidden}>
        {!value?.trigger_workflow_node_ids?.length && (
          <NoEntryPointTooltip>{p('before_you_add_exit_point')}</NoEntryPointTooltip>
        )}
        {value?.trigger_workflow_node_ids?.length ? (
          <Tags className="show-scrollbar">
            {value?.trigger_workflow_node_ids.map(tagRender)}
          </Tags>
        ) : null}
        {!value.hidden && (
          <AddExitPointButton
            noExitPoints={!value?.trigger_workflow_node_ids?.length}
            options={exitPoints}
            disabled={!editable}
            onSelect={handleAddExitPoint}
          />
        )}
      </Content>
    </Container>
  );
};

export default WorkflowSettings;
