import { all, takeLatest, put } from 'redux-saga/effects';
import { DashboardActionTypes } from '../../actions/types';
import { Endpoints } from '@constants';
import { createRequestAction } from '@store/lib/utils/creators';

function* changeAnalyticsOptionsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/update-dashboard-options',
    body: {
      dashboard_analytics_options: action.payload
    },
  }));
}

function* getAnalyticsEntitiesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/seg/analytics/options',
  }));
}

function* getDatabaseEfficiencySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytics/${action.payload.type}/efficiency`,
    params: {
      date_range: action.payload.range,
      ids: action.payload.ids,
      id: action.payload.id,
    },
  }));
}

function* getDatabaseEfficiencyPart2Saga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/notificator/analytics/efficiency',
    params: {
      date_range: action.payload.range,
      type: action.payload.type,
      ids: action.payload.ids,
    },
  }));
}

function* getEmailSummarySaga(action) {
  const [start_date, end_date] = action.payload.range;

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.DASHBOARD_EMAIL_SUMMARY,
    params: { start_date, end_date },
  }));
}

function* getGeneralActivityDailySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytics/${action.payload.type}/general-activity-daily`,
    params: {
      date_range: action.payload.range,
      ids: action.payload.ids,
      id: action.payload.id,
    },
  }));
}

function* getGeneralActivityHourlySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytics/${action.payload.type}/general-activity-hourly`,
    params: {
      date_range: action.payload.range,
      ids: action.payload.ids,
      id: action.payload.id,
    },
  }));
}

function* getMobilePushSummarySaga(action) {
  const [start_date, end_date] = action.payload.range;

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.DASHBOARD_MOBILE_PUSH_SUMMARY,
    params: { start_date, end_date },
  }));
}

function* getSmsSummarySaga(action) {
  const [start_date, end_date] = action.payload.range;

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.DASHBOARD_SMS_SUMMARY,
    params: { start_date, end_date },
  }));
}

function* getViberSummarySaga(action) {
  const [start_date, end_date] = action.payload.range;

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.DASHBOARD_VIBER_SUMMARY,
    params: { start_date, end_date },
  }));
}

function* getWebpushSummarySaga(action) {
  const [start_date, end_date] = action.payload.range || [];

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.DASHBOARD_WEBPUSH_SUMMARY,
    params: { start_date, end_date },
  }));
}


export default function* dashboardRootSaga() {
  yield all([
    takeLatest(DashboardActionTypes.GET_EMAIL_SUMMARY, getEmailSummarySaga),
    takeLatest(DashboardActionTypes.GET_VIBER_SUMMARY, getViberSummarySaga),
    takeLatest(DashboardActionTypes.GET_SMS_SUMMARY, getSmsSummarySaga),
    takeLatest(DashboardActionTypes.GET_GENERAL_ACTIVITY_DAILY, getGeneralActivityDailySaga),
    takeLatest(DashboardActionTypes.GET_GENERAL_ACTIVITY_HOURLY, getGeneralActivityHourlySaga),
    takeLatest(DashboardActionTypes.GET_DATABASE_EFFICIENCY, getDatabaseEfficiencySaga),
    takeLatest(DashboardActionTypes.GET_DATABASE_EFFICIENCY2, getDatabaseEfficiencyPart2Saga),
    takeLatest(DashboardActionTypes.GET_WEBPUSH_SUMMARY, getWebpushSummarySaga),
    takeLatest(DashboardActionTypes.CHANGE_ANALYTICS_OPTIONS, changeAnalyticsOptionsSaga),
    takeLatest(DashboardActionTypes.GET_ANALYTICS_ENTITIES, getAnalyticsEntitiesSaga),
    takeLatest(DashboardActionTypes.GET_MOBILE_PUSH_SUMMARY, getMobilePushSummarySaga),
  ]);
}
