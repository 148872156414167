import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { Button } from '@components';
import { by } from '@utils';

import { Overlay, Option } from './styled';
import { useTranslation } from '@hooks';

const options = [
  {
    value: 'and',
    label: 'labels.matches_all',
  },
  {
    value: 'or',
    label: 'labels.matches_any',
  },
];

const LogicalOperatorSelect = ({ value, disabled, onChange, style = {} }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleChange = (v) => {
    if (disabled) {
      return;
    }

    setVisible(false);
    onChange(v);
  };

  return (
    <Dropdown
      trigger={['click']}
      // disabled={disabled}
      open={visible}
      onOpenChange={setVisible}
      overlay={(
        <Overlay>
          {options.map(({ value: curr, label }) => (
            <Option key={label} onClick={() => handleChange(curr)}>{t(label)}</Option>
          ))}
        </Overlay>
      )}
    >
      <Button
        disabled={disabled}
        appearance={visible ? 'secondary' : 'outlined'}
        style={{ width: 'auto', paddingLeft: '12px', height: '26px', fontSize: '12px', marginBottom: '5px', ...style }}
      >
        {t(options.find(by('value', value))?.label)}
      </Button>
    </Dropdown>
  );
};

export default LogicalOperatorSelect;
