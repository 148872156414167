import React from 'react';

import { Container } from './styled.js';
import { LoaderContainer } from '@components/ui/PageLoader/styled';

const TableLoader = ({...props}) => {
  return (
    <Container>
      <div {...props}>
      <LoaderContainer $i={0}>
        <svg width="40" height="100" viewBox="0 0 40 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="url(#paint0_linear_28_38)"/>
          <defs>
            <linearGradient id="paint0_linear_28_38" x1="28.75" y1="-1.04467e-06" x2="4.62051" y2="36.7407" gradientUnits="userSpaceOnUse">
              <stop stopColor="#F4A53C"/>
              <stop offset="1" stopColor="#ED992A"/>
            </linearGradient>
          </defs>
        </svg>
      </LoaderContainer>
      <LoaderContainer $i={1}>
        <svg width="40" height="100" viewBox="0 0 40 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="url(#paint0_linear_28_41)"/>
          <defs>
            <linearGradient id="paint0_linear_28_41" x1="28.75" y1="-2.95202e-06" x2="4.6205" y2="36.7407" gradientUnits="userSpaceOnUse">
              <stop stopColor="#6EDEAD"/>
              <stop offset="1" stopColor="#3BCD9A"/>
            </linearGradient>
          </defs>
        </svg>
      </LoaderContainer>
      <LoaderContainer $i={2}>
        <svg width="40" height="100" viewBox="0 0 40 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_28_37)"/>
          <defs>
            <linearGradient id="paint0_linear_28_37" x1="20" y1="7.2" x2="20" y2="42.4" gradientUnits="userSpaceOnUse">
              <stop stopColor="#4A88FF"/>
              <stop offset="0.822917" stopColor="#5A56FD"/>
            </linearGradient>
          </defs>
        </svg>
      </LoaderContainer>
      </div>
    </Container>
  );
}

export default TableLoader;


