import React from 'react';

import { Button, ButtonsRow, Container, Text } from './styled.js';
import { useDispatch } from 'react-redux';
import {
  updateOnboarding
} from '@store/actions/creators';
import { useTranslation } from '@hooks';

const OnboardInfoHint = ({ hintText, hintKey, step, handleDeny }) => {
  const dispatch = useDispatch();
  const { p } = useTranslation('sidebar');

  const handleAccept = () => {
    dispatch(updateOnboarding(hintKey, { step }))
  };

  return (
    <Container data-testid="onboarding-hint-container">
      <Text data-testid="onboarding-hint-text">{hintText}</Text>
      <ButtonsRow>
        <Button data-testid="onboarding-hint-button-no" no onClick={handleDeny}>{p('no')}</Button>
        <Button data-testid="onboarding-hint-button-yes" onClick={handleAccept}>{p('yes')}</Button>
      </ButtonsRow>
    </Container>
  );
}

export default OnboardInfoHint;


