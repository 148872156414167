import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/selectors';
import { moment } from '@utils';
import { Icon, Link } from '@components';

import { CloseButton, Container } from './styled.js';

const TrialBar = ({ onOpenTrial }) => {
  const user = useSelector(userSelector);
  const [shown, setShown] = useState(true);

  const handleClose = () => {
    setShown(false);
  };

  if ((user?.customSubscription && !moment(user?.ownerTrialEndsAt).isBefore(moment())) || !shown || user?.subscribed) {
    return null;
  }

  if (user?.ownerTrialEndsAt === null) {
    return null;
  }

  if (!user?.ownerTrialEndsAt) {
    return (
      <Container>
        Your trial has ended
        <CloseButton style={{ right: 26 }} onClick={handleClose}>
          <Icon name="Cross-icon" size={10} color="#303133" />
        </CloseButton>
      </Container>
    );
  }

  if (moment(user?.ownerTrialEndsAt).isBefore(moment())) {
    return (
      <Container>
        Trial period has ended <Link onCLick={onOpenTrial} style={{ marginLeft: 5 }}>Activate now</Link>
        <CloseButton style={{ right: 26 }} onClick={handleClose}>
          <Icon name="Cross-icon" size={10} color="#303133" />
        </CloseButton>
      </Container>
    );
  }

  return (
    <Container>
      Trial period ends {moment(user?.ownerTrialEndsAt).fromNow()}
      <CloseButton style={{ right: 26 }} onClick={handleClose}>
        <Icon name="Cross-icon" size={10} color="" />
      </CloseButton>
    </Container>
  );
}

export default TrialBar;


