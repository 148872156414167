import React, { useEffect, useRef, useState } from 'react';
import { useOnboarding, useTranslation } from '@hooks';
import { moment, testId as createTestId } from '@utils';
import { AutoComplete, Select, TagAutoComplete, WithOnboarding } from '@components';
import {
  DATETIME_DATE_PICKER_OPERATORS, DATETIME_SELECT_UNIT_OPERATORS,
  MULTIPLE_VALUE_OPERATORS, OPERATOR_MATCHES_RANGE, OPERATOR_MATCHES_REGEXP, OPERATOR_NOT_MATCHES_REGEXP,
  SELECT_GROUPS,
  SINGLE_VALUE_OPERATORS,
  TWO_VALUE_OPERATORS,
  WITHOUT_VALUE_OPERATORS
} from "@constants";
import { OperatorMenu } from './components/OperatorMenu';
import { DateFilterPicker } from '@components/lib/SegmentEditor/components';

import { Container, Row, FieldRow, FieldLabel } from './styled';

const OperatorSelect = ({
  testId,
  align,
  labeled,
  type: outerType,
  value,
  first,
  smaller,
  disabled,
  appearance = 'default',
  field,
  autocomplete,
  className,
  additionalOptions = null,
  initialIsField = false,
  initialType = 'numeric',
  style = {},
  wrapperStyle = {},
  onOperatorChange,
  onValueChange,
  hideOperator,
  isAggregate = false,
  ...props
}) => {
  const onboarding = useOnboarding('segments');
  const [tab, setTab] = useState(initialType);
  const [fieldSelected, setFieldSelected] = useState(initialIsField);
  const { p, t } = useTranslation('segments_page');
  const selectRef = useRef();
  const [type, setType] = useState(outerType);

  useEffect(() => {
    if (!outerType || outerType === 'common') {
      return;
    }

    setType(outerType);
  }, [outerType]);

  useEffect(() => {
    if (!initialType) {
      return;
    }
    setTab(initialType);
  }, [initialType]);

  const handleAutoCompleteValueChange = (value, isField) => {
    onValueChange(value, isField);
  };

  const handleBetweenValueChange = ([nf, ns]) => {
    onValueChange([(nf === undefined ? value?.value?.[0] : nf === '' ? '' : +nf), (ns === undefined ? value?.value?.[1] : ns === '' ? '' : +ns)]);
  }

  const handleDateChange = (m) => {
    onValueChange(moment(m).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0));
  };

  const handleMultipleValueChange = (vs) => {
    onValueChange(vs, false);
  }

  const handleOperatorChange = (operator, type) => {
    selectRef?.current?.onClose();
    onOperatorChange(operator, type);
    setFieldSelected(false);
  };

  const fullWidth = align === 'column' || props.fullWidth;
  const inputType = (type === 'numeric' && value.operator !== OPERATOR_MATCHES_REGEXP && value.operator !== OPERATOR_NOT_MATCHES_REGEXP) ? 'number' : undefined;

  const tid = createTestId('operator-select');
  const resolveSecondInput = (operator) => {

    if (WITHOUT_VALUE_OPERATORS.includes(operator)) {
      return null;
    }

    if (TWO_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <>
            <FieldRow>
              <FieldLabel>
                {p('value_from')}
              </FieldLabel>
              <AutoComplete
                key="1"
                testId={`${testId}-autocomplete-two-1`}
                style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
                smaller={smaller}
                fullWidth={fullWidth}
                title={labeled && t('labels.value')}
                disabled={disabled}
                field={field}
                suggestionsOptions={autocomplete}
                type={inputType}
                value={value.value[0] || ''}
                error={value?.errors?.value?.[0] || value?.errors?.value}
                onChange={v => handleBetweenValueChange([v])}
              />
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                {p('value_to')}
              </FieldLabel>
              <AutoComplete
                key="2"
                testId={`${testId}-autocomplete-two-2`}
                style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
                smaller={smaller}
                fullWidth={fullWidth}
                field={field}
                suggestionsOptions={autocomplete}
                title={labeled && t('labels.value')}
                disabled={disabled}
                type={inputType}
                value={value.value[1] || ''}
                error={value?.errors?.value?.[1] || value?.errors?.value}
                onChange={v => handleBetweenValueChange([, v])}
              />
            </FieldRow>
          </>
        );
      }
      return (
        <>
          <AutoComplete
            key="1"
            testId={`${testId}-autocomplete-two-1`}
            style={{ margin: 0, border: 'auto', borderRadius: '13px', marginBottom: appearance !== 'column' ? 0 : 16 }}
            smaller={smaller}
            fullWidth={fullWidth}
            title={labeled && p('value_from')}
            disabled={disabled}
            suggestionsOptions={autocomplete}
            field={field}
            type={inputType}
            value={value.value[0] || ''}
            error={value?.errors?.value?.[0] || value?.errors?.value}
            onChange={v => handleBetweenValueChange([v])}
          />
          <AutoComplete
            key="2"
            testId={`${testId}-autocomplete-two-2`}
            style={{ margin: 0, border: 'auto', borderRadius: '13px', marginLeft: appearance !== 'column' ? 6 : 0 }}
            smaller={smaller}
            fullWidth={fullWidth}
            field={field}
            suggestionsOptions={autocomplete}
            title={labeled && p('value_to')}
            disabled={disabled}
            type={inputType}
            value={value.value[1] || ''}
            error={value?.errors?.value?.[1] || value?.errors?.value}
            onChange={v => handleBetweenValueChange([, v])}
          />
        </>
      );
    }

    if (MULTIPLE_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {p('values')}
            </FieldLabel>
            <TagAutoComplete
              testId={`${testId}-autocomplete-multiple`}
              disabled={disabled}
              onChange={handleMultipleValueChange}
              field={field}
              suggestionsOptions={autocomplete}
              type={inputType}
              value={value.value || []}
              smaller={smaller}
              title={labeled && t('labels.value')}
              error={value?.errors?.value}
              containerStyle={{ width: fullWidth ? '100%' : '186px' }}
              style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
            />
          </FieldRow>
        );
      }
      return (
        <TagAutoComplete
          testId={`${testId}-autocomplete-multiple`}
          disabled={disabled}
          className={className}
          onChange={handleMultipleValueChange}
          field={field}
          smaller={smaller}
          placeholder={t('actions.select')}
          suggestionsOptions={autocomplete}
          type={inputType}
          value={value.value || []}
          title={labeled && t('labels.value')}
          error={value?.errors?.value}
          containerStyle={{ width: fullWidth ? '100%' : '186px' }}
          style={{ background: disabled ? '#f5f5f5': '', display: 'flex', alignItems: 'flex-end' , margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
        />
      );
    }

    if (DATETIME_DATE_PICKER_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <DateFilterPicker
              value={value.value}
              title={labeled && t('labels.value')}
              onChange={onValueChange}
              disabled={disabled}
              tooltipError={value?.errors?.value}
              style={{ width: '100%' }}
              containerStyle={{ width: '100%' }}
              disabledTabs={operator === OPERATOR_MATCHES_RANGE ? [] : ['specific']}
              large={!smaller}
            />
          </FieldRow>
        );
      }

      return (
        <DateFilterPicker
          {...tid(`date-picker-input`)}
          value={value.value}
          title={labeled && t('labels.value')}
          onChange={onValueChange}
          tooltipError={value?.errors?.value}
          disabled={disabled}
          style={{ width: fullWidth && '100%' }}
          containerStyle={{ width: fullWidth && '100%' }}
          disabledTabs={operator === OPERATOR_MATCHES_RANGE ? [] : ['specific']}
          large={!smaller}
        />
      )
    }
    if (DATETIME_SELECT_UNIT_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <Select
              disabled={disabled}
              onChange={onValueChange}
              title={t('labels.select')}
              absoluteError
              errorStyles={{ top: 55 }}
              containerStyle={{ width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              value={value.value}
              suggestionsOptions={autocomplete}
              style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
              tooltipError={value?.errors?.value}
              options={[
                { value: 'years', label: p('year') },
                { value: 'months', label: p('month') },
                { value: 'weeks', label: p('week') },
                { value: 'year_days', label: p('day_of_year') },
                { value: 'week_days', label: p('day_of_week') },
                { value: 'days', label: p('day_of_month') },
                { value: 'days_months', label: p('day_and_month') },
                { value: 'hours', label: p('hour') },
                { value: 'minutes', label: p('minute') }
              ]}
            />
          </FieldRow>
        );
      }
      return (
        <Select
          disabled={disabled}
          onChange={onValueChange}
          label={labeled && t('labels.value')}
          title={t('labels.select')}
          absoluteError
          suggestionsOptions={autocomplete}
          errorStyles={{ top: 55 }}
          value={value.value}
          style={{ margin: 0, width: fullWidth ? '100%' : '186px', height: smaller ? 26 : undefined }}
          tooltipError={value?.errors?.value}
          options={[
            { value: 'years', label: p('year') },
            { value: 'months', label: p('month') },
            { value: 'weeks', label: p('week') },
            { value: 'year_days', label: p('day_of_year') },
            { value: 'week_days', label: p('day_of_week') },
            { value: 'days', label: p('day_of_month') },
            { value: 'days_months', label: p('day_and_month') },
            { value: 'hours', label: p('hour') },
            { value: 'minutes', label: p('minute') }
          ]}
        />
      );
    }

    if (SINGLE_VALUE_OPERATORS.includes(operator)) {
      if (smaller && appearance === 'column') {
        return (
          <FieldRow>
            <FieldLabel>
              {t('labels.value')}
            </FieldLabel>
            <AutoComplete
              testId={`${testId}-autocomplete-one`}
              style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
              smaller={smaller}
              fullWidth={fullWidth}
              altSelection={!!additionalOptions}
              field={field}
              suggestionsOptions={autocomplete}
              additionalOptions={additionalOptions}
              fieldSelected={fieldSelected}
              title={labeled ? (fieldSelected ? t('labels.field') : t('labels.value')) : null}
              type={inputType}
              disabled={disabled}
              setFieldSelected={setFieldSelected}
              operator={value.operator}
              operatorType={value.type}
              value={value.value}
              error={value?.errors?.value}
              onChange={handleAutoCompleteValueChange}
              onPlusChange={(v) => handleAutoCompleteValueChange(v, true)}
            />
          </FieldRow>
        );
      }

      return (
        <AutoComplete
          testId={`${testId}-autocomplete-one`}
          style={{ margin: 0, border: 'auto', borderRadius: '13px' }}
          smaller={smaller}
          fullWidth={fullWidth}
          altSelection={!!additionalOptions}
          field={field}
          suggestionsOptions={autocomplete}
          additionalOptions={additionalOptions}
          fieldSelected={fieldSelected}
          title={labeled ? (fieldSelected ? t('labels.field') : t('labels.value')) : null}
          type={inputType}
          disabled={disabled}
          setFieldSelected={setFieldSelected}
          operator={value.operator}
          operatorType={value.type}
          value={value.value}
          error={value?.errors?.value}
          onChange={handleAutoCompleteValueChange}
          onPlusChange={(v) => handleAutoCompleteValueChange(v, true)}
        />
      )
    }
  };

  return (
    <Container data-testid={testId} style={wrapperStyle}>
      <Row align={align || appearance}>
        <WithOnboarding text={p('choose_equals')} enabled={onboarding.enabled(4) && first} onboardKey="segments" guideLink="strong-segments-amp-funnels-strong/">
          {!hideOperator && (smaller && appearance === 'column' ? (
            <FieldRow>
              <FieldLabel>
                {t('labels.operator')}
              </FieldLabel>
              <Select
                testId={`${testId}-inner`}
                style={{
                  width: '100%',
                  background: '#F9FBFF',
                  height: smaller ? 26 : undefined,
                  ...style,
                }}
                wrapperStyles={{
                  marginBottom: align === 'column' ? '16px' : 0,
                  width: '100%',
                  height: (smaller && !labeled) ? 26 : undefined,
                }}
                ref={selectRef}
                value={value.operator}
                tooltipError={value.errors?.operator || value?.errors?.operator}
                disabled={disabled}
                label={labeled && t('labels.operator')}
                optionGroups={SELECT_GROUPS(p)}
                customOverlay={({ opened }) => (
                  <OperatorMenu
                    isAggregate={isAggregate}
                    tab={tab}
                    setTab={setTab}
                    opened={opened}
                    onChange={handleOperatorChange}
                  />
                )}
              />
            </FieldRow>
          ) : (
            <Select
              testId={`${testId}-inner`}
              style={{
                width: '186px',
                background: '#F9FBFF',
                height: smaller ? 26 : undefined,
                ...style,
              }}
              wrapperStyles={{
                marginBottom: align === 'column' || appearance === 'column' ? '16px' : 0,
                width: align === 'column' || appearance === 'column' ? 'auto' : 186,
                marginRight: '6px',
                height: (smaller && !labeled) ? 26 : undefined,
              }}
              ref={selectRef}
              value={value.operator}
              tooltipError={value.errors?.operator || value?.errors?.operator}
              disabled={disabled}
              label={labeled && t('labels.operator')}
              optionGroups={SELECT_GROUPS(p)}
              customOverlay={({ opened }) => (
                <OperatorMenu
                  tab={tab}
                  opened={opened}
                  type={type}
                  isAggregate={isAggregate}
                  setTab={setTab}
                  onChange={handleOperatorChange}
                />
              )}
            />
          ))}
        </WithOnboarding>
        {resolveSecondInput(value.operator)}
      </Row>
    </Container>
  );
};

export default OperatorSelect;
