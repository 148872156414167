import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
`;

export const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Type = styled.div`
  font-family: Rubik-Regular;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;
