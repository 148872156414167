import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { WorkflowActionTypes } from '../../actions/types';
import { Endpoints, ProgramTypes } from '@constants';
import FileDownload from 'js-file-download';
import { identity, moment } from '@utils';
import { createRequestAction } from '@store/lib/utils/creators';

function* archiveWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_ARCHIVE,
    body: {
      id: action.payload.id,
    },
  }));
}

function* deleteVersionWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_DELETE_VERSION,
    body: {
      id: action.payload.id,
    },
  }));
}

function* deleteWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: Endpoints.WORKFLOW_DELETE,
    body: {
      id: action.payload.id,
    },
  }));
}

function* downloadContactByNodeSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_NODE_DOWNLOAD,
    body: {
      node_ids: [action.payload.node_id, ...action.payload.additionalNodes],
      date_range: action.payload.range
    },
    responseType: 'blob',
    disableTimeout: true,
  }));
}

function* getContactsDownloadNodeSaga(action) {
  yield FileDownload(action.payload, ('customers.csv'))
}

function* downloadNodeAnalyticsByChannelSaga(action) {
  const params = {
    entity_id: action.payload.worker_id,
    sub_entity_id: [action.payload.node_id, ...action.payload.additionalNodes],
  };

  if (action.payload.status && !Array.isArray(action.payload.status)) {
    params.status = [action.payload.status].filter(identity);
  }

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/${action.payload.channel_type}/customers/download`,
    params,
  }));
}

function* getDownloadNodeAnalyticsByChannelSaga(action) {
  yield FileDownload(action.payload, ('customers.csv'))
}

function* exitCriterionWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_EXIT_CRITERION,
    body: {
      id: action.payload.id,
      exit_criterion: action.payload.exit_criterion
    },
  }));
}

function* getAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/version/analytic`,
    body: {
      date_range: action.payload.range,
      workflow_id: action.payload.workflow_id,
    }
  }));
}

function* getEditMetadataSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/edit`,
  }));
}

function* getEmailNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/email/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getMobilePushAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/mobile_push/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_NODE_ANALYTICS,
    body: {
      date_range: action.payload.range,
      node_id: action.payload.node_id,
    }
  }));
}

function* getProgramAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/workflow/analytic`,
    body: {
      date_range: action.payload.range,
    }
  }));
}

function* getProgramSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/edit`,
  }));
}
function* getPublishHistorySaga (action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/analytic/publish_history`,
  }));
}

function* getSmsNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/sms/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getViberNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/viber/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getWebhookNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/webhook/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getWebpushNodeAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/web_push/statuses-count-per-customer`,
    params: {
      date_range: action.payload.range,
      entity_id: action.payload.id,
      sub_entity_id: action.payload.node_id
    },
  }));
}

function* getWorkerSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/workflow/version/${action.payload.worker_id}/edit`,
  }));
}

function* getWorkflowLogsSaga(action) {
  yield put(createRequestAction(action, {
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/workflow_log`
  }));
}

function* getWorkflowOptionsSaga(action) {
  yield all([
    put(
      createRequestAction(action, {
        method: 'post',
        endpoint: Endpoints.WORKFLOW_OPTIONS,
      })
    ),
    put(
      createRequestAction(
        { ...action, meta: { ...action.meta, part: 'events' } },
        {
          method: 'get',
          endpoint: Endpoints.EVENTS,
          disableTimeout: true,
        }
      )
    ),
  ]);
}

function* listWorkflowsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.WORKFLOW,
    params: { program_type: ProgramTypes.WORKFLOW },
  }));
}

function* participationWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_PARTICIPATION,
    body: {
      id: action.payload.id,
      participation: action.payload.participation
    },
  }));
}

function* publishWorkflowSaga(action) {
  const { schedule } = action.payload;
  const minStart = moment().add(2, 'minutes');

  let start_date = schedule.start_date;
  let end_date = schedule.end_date;

  if (start_date && schedule.start_date?.isBefore?.(minStart)) {
    start_date = minStart;
  }

  if (end_date && schedule.end_date?.isBefore?.(minStart)) {
    end_date = minStart;
  }

  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_PUBLISH,
    body: {
      id: action.payload.id,
      schedule: {
        ...schedule,
        start_date,
        end_date
      },
      fake: action.payload.fake,
    },
  }));
}

function* saveWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW,
    body: {
      program: action.payload.program,
      worker: action.payload.worker
    },
  }));
}

function* unArchiveWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_UN_ARCHIVE,
    body: {
      id: action.payload.id,
    },
  }));
}

function* unPublishWorkflowSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_UN_PUBLISH,
    body: {
      id: action.payload.id,
      full_stop: action.payload.full_stop,
    },
  }));
}

function* updateQuietHoursSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/workflow/quite/hours',
    body: {
      id: action.payload.id,
      quite_hours: action.payload.quite_hours,
    }
  }))
}

function* saveWorkflowImageSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/workflow/image',
    body: {
      id: action.payload.id,
      image: action.payload.image,
    }
  }))
}

function* getWorkflowEntryCountSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/seg/customer/count',
    body: {
      filter: action.payload.filter,
    },
  }));
}

export default function* userRootSaga() {
  yield all([
    takeEvery(WorkflowActionTypes.LIST, listWorkflowsSaga),
    takeLatest(WorkflowActionTypes.SAVE, saveWorkflowSaga),
    takeLatest(WorkflowActionTypes.GET_OPTIONS, getWorkflowOptionsSaga),
    takeLatest(WorkflowActionTypes.GET_EDIT_METADATA, getEditMetadataSaga),
    takeLatest(WorkflowActionTypes.PUBLISH, publishWorkflowSaga),
    takeLatest(WorkflowActionTypes.UN_PUBLISH, unPublishWorkflowSaga),
    takeLatest(WorkflowActionTypes.DELETE_VERSION, deleteVersionWorkflowSaga),
    takeLatest(WorkflowActionTypes.DELETE, deleteWorkflowSaga),
    takeLatest(WorkflowActionTypes.ARCHIVE, archiveWorkflowSaga),
    takeLatest(WorkflowActionTypes.UN_ARCHIVE, unArchiveWorkflowSaga),
    takeLatest(WorkflowActionTypes.PARTICIPATION_WORKFLOW, participationWorkflowSaga),
    takeLatest(WorkflowActionTypes.EXIT_CRITERION_WORKFLOW, exitCriterionWorkflowSaga),
    takeEvery(WorkflowActionTypes.GET_ANALYTICS, getAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_PROGRAM_ANALYTICS, getProgramAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_NODE_ANALYTICS, getNodeAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_LOGS, getWorkflowLogsSaga),
    takeEvery(WorkflowActionTypes.GET_PUBLISH_HISTORY, getPublishHistorySaga),
    takeEvery(WorkflowActionTypes.DOWNLOAD_CONTACT_BY_NODE, downloadContactByNodeSaga),
    takeEvery(WorkflowActionTypes.DOWNLOAD_CONTACT_BY_NODE_SUCCESS, getContactsDownloadNodeSaga),
    takeEvery(WorkflowActionTypes.GET_SMS_NODE_ANALYTICS, getSmsNodeAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS, getEmailNodeAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS, getMobilePushAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS, getViberNodeAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS, getWebpushNodeAnalyticsSaga),
    takeEvery(WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS, getWebhookNodeAnalyticsSaga),
    takeLatest(WorkflowActionTypes.DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL, downloadNodeAnalyticsByChannelSaga),
    takeLatest(WorkflowActionTypes.DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL_SUCCESS, getDownloadNodeAnalyticsByChannelSaga),
    takeEvery(WorkflowActionTypes.GET_WORKFLOW_WORKER, getWorkerSaga),
    takeLatest(WorkflowActionTypes.GET_WORKFLOW_PROGRAM, getProgramSaga),
    takeLatest(WorkflowActionTypes.UPDATE_QUIET_HOURS, updateQuietHoursSaga),
    takeLatest(WorkflowActionTypes.SAVE_IMAGE, saveWorkflowImageSaga),
    takeLatest(WorkflowActionTypes.GET_ENTRY_COUNT, getWorkflowEntryCountSaga),
  ]);
}
