import React from 'react';
import { useTranslation } from '@hooks';

import { Container } from './styled';

const FiltersOperator = ({ value, onClick, disabled }) => {
  const { p } = useTranslation('segments_page');

  return (
    <Container onClick={disabled ? void 0 : onClick}>
      {p(`operator_${value}`)}
    </Container>
  );
};

export default FiltersOperator;
