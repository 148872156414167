import styled, { css } from 'styled-components';
import { styledProp, switchStyle } from '@utils';
import { WorkflowDiffTypes } from '@constants';
import { Handle } from 'reactflow';

export const Container = styled.div`
  width: 94px;
  background: transparent;
  display: flex;
  flex-direction: column;
  flex: 0;
  max-width: 94px;
  margin-right: 0;
  align-items: center;
  border-radius: 6px;
  padding-top: 5px;
  
  ${styledProp('selected', css`
    border: 1px dashed #109DFB;
  `, css`
    border: 1px solid transparent;
  `)}

  ${styledProp('temp', css`
    border: 1px solid #109DFB;
  `, css`
  `)}
  
  ${switchStyle('highlight', {
    [WorkflowDiffTypes.CREATED]: css`
      background: #c2e7cb7d;
    `,
    [WorkflowDiffTypes.UPDATED]: css`
      background: #ffc63047;
    `,
    [WorkflowDiffTypes.DELETED]: css`
      background: #fb694c4f;
    `,
  })}
  
  ${styledProp('disabled', css`
    opacity: 0.4;
  `)}
`;

export const NodeThumbnail = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
  overflow: visible;

  ${switchStyle('type', {
    'entry_point': css`
      background: linear-gradient(213.29deg, #6EDEAD 11.15%, #3BCD9A 90.5%);
    `,
    'connection_channel': css`
      background: linear-gradient(213.29deg, #F4A53C 11.15%, #ED992A 90.5%);
    `,
    'actions': css`
      background: linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%);
    `,
  })}
`;

export const Title = styled.div`
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  margin: 6px 0px;
  color: #041638;
  max-width: 130px;
  overflow: hidden;
  max-height: 40px;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  padding: 9px 0;
`;

export const Option = styled.div`
  padding: 6px 14px;
`;

export const HandleContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transition: all 280ms ease;
  position: absolute;
  border: 1px solid #fff;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  &:hover, &:active {
    transform: scale(1.3);
    background-color: #e8e8e8;
  }
`;

export const NotValidatedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  right: 18px;
  border-radius: 50%;
  background: #FFBB00;
  width: 10px;
  height: 10px;
`;

export const NodeAnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: -20px;
`;

export const StyledHandle = styled(Handle)`
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  background: #A9A7A7;
  cursor: copy !important;
  border-radius: 50%;
  transition: border-width 280ms ease, transform 280ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  ${styledProp('disabled', css`
    pointer-events: none;
  `)}

  & > * {
    opacity: 0;
    transition: all 280ms ease;
    pointer-events: none;
  }

  :hover {
    transform: scale(1.5) translateY(-1.5px);
    border-width: 0px;
    & > * {
      opacity: 1;
      //transform: scale(1.5) translateY(-0.1px);
      //transform: translateY(1.5px);
    }
  }
`;
