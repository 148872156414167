import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OperatorsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  line-height: 14px;
  color: #909399;
  width: 136px;
  height: 26px;
  background: #E5E8ED;
  border-radius: 6px;
  padding: 3px;
`;

export const Operator = styled.div`
  width: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${styledProp('selected', css`
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    color: #109DFB;
  `)}
`;


