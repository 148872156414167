import { HistoryNotificationsActionTypes } from '@store/actions/types';

import { asCreator, withFilters, withOrdering, withPagination } from '../../lib';
import { compose } from '@utils';

export const getEmails = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date', 'template_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_EMAIL })),
);

export const getVibers = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'start_date', 'end_date', 'status', 'viber_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_VIBERS }))
);

export const getMobilePushes = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'start_date', 'end_date', 'status', 'template_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_MOBILE_PUSHES }))
);

export const getSms = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date', 'template_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_SMS })),
);

export const getWebhook = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date', 'template_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_WEBHOOK })),
);

export const getTemplates = compose(
  asCreator(() => ({
      type: HistoryNotificationsActionTypes.GET_TEMPLATES
  })),
);

export const getWebpush = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date', 'template_id']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_WEBPUSH })),
);

export const getPromoCodesNotificationHistory = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date', 'date_type', '$type']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_PROMO_CODES})),
);

export const getFacebookNotificationHistory = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'status', 'end_date', 'start_date']),
  asCreator(() => ({ type: HistoryNotificationsActionTypes.GET_FACEBOOK })),
);

export const getNotificationHistoryCount = compose(
  withFilters([
    'input_value',
    'status',
    'end_date',
    'start_date',
    'template_id',
    'date_type',
    '$type'
  ]),
  asCreator(({ entity }) => ({
    type: HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT,
    payload: { entity },
    meta: { entity },
  }))
);
