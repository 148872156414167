import React, { useEffect, useMemo, useState } from 'react';

import {
  Container,
  Overlay,
  Header,
  Tab,
  TabIndicator,
  Tabs,
  Content,
  DescriptionContainer,
  Description,
  CreateAggregateButton,
  SearchContainer,
  NoDataContainer,
  FilteredContainer,
  Group,
  GroupTitle,
  GroupItem,
} from './styled.js';
import { Dropdown } from 'antd';
import { LinearFilterPanel } from './components/LinearFilterPanel';
import { AggregatesFilterPanel } from './components/AggregatesFilterPanel';
import { EventsFilterPanel } from './components/EventsFilterPanel';
import { ArrayFilterPanel } from './components/ArrayFilterPanel';
import {SEGMENT_FILTER_TYPE_MAP, GET_SEGMENT_FILTER_LABEL_MAP} from "@constants/segments";
import { SegmentsRuleTypes, UserPermissions } from '@constants';
import { Icon, Input, Modal, NoData, WithPermissions } from '@components';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  segmentOptionsAggregatesListSelector,
  eventsListSelector,
  fieldsSettingsListSelector, segmentsOptionsSelector, eventsSelector
} from '@store/selectors';
import { by, extract } from '@utils';

import {
  CustomFilterPanel
} from '@components/lib/SegmentEditor/components/FiltersDropdown/components/CustomFilterPanel';
import { useTabElement, useTranslation } from '@hooks';
import {
  RFMSegmentsFilterPanel
} from '@components/lib/SegmentEditor/components/FiltersDropdown/components/RFMSegmentsFilterPanel';
import RFMSegmentsRightPanel
  from '@components/lib/SegmentEditor/components/FiltersDropdown/components/RFMSegmentsRightPanel/RFMSegmentsRightPanel';

const FiltersDropdown = ({
  onSelect,
  hidePreview,
  children,
  defaultTab = 'linear',
  hiddenTypes = [],
  customTabs = [],
  isModal = false,
  maxWidth,
  disableAggregateCreation = false,
  style = {},
  nested = false,
}) => {
  const { t, p } = useTranslation('segments_page');
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const events = useSelector(eventsSelector);
  const aggregatesList = useSelector(segmentOptionsAggregatesListSelector);
  const [opened, setOpened] = useState(false);
  const [tab, setTab] = useState(defaultTab);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedRFMSegment, setSelectedRFMSegment] = useState(null);
  const options = useSelector(segmentsOptionsSelector);
  const te = useTabElement(tab, SEGMENT_FILTER_TYPE_MAP.concat(customTabs.map(extract('name'))).filter(t => !~hiddenTypes.indexOf(t)).map(name => ({ name })), opened)

  const rfmAnalyticsOptions = (options?.fields?.rfmResources || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const aggregates = hiddenTypes.indexOf('aggregate') === -1 ? aggregatesList : [];

  const linearFields = hiddenTypes.indexOf('linear') === -1 ? fieldSettings?.data?.filter(f => f.type !== 'array') : [];
  const arrayFields = fieldSettings?.data?.filter(f => f.type === 'array');

  const SEGMENT_FILTER_LABEL_MAP = GET_SEGMENT_FILTER_LABEL_MAP(p);

  const handleSelect = (...args) => {
    onSelect(...args);
    setSearchInputValue('');
    setOpened(false);
  };

  const handleSelectRFMSegment = (id) => {
    setSelectedRFMSegment(id);
  };

  const id = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (options?.fields?.rfmResources?.length && !selectedRFMSegment) {
      setSelectedRFMSegment(options?.fields?.rfmResources?.[0]?.id)
    }
  }, [options?.fields?.rfmResources?.length, selectedRFMSegment]);

  const renderContent = () => {
    if (!searchInputValue.trim().length) {
      return (
        <Content hidePreview={hidePreview} $isDefault={!isModal} $mw={maxWidth || 420}>
          {tab === 'linear' && <LinearFilterPanel hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'array' && <ArrayFilterPanel hideArrays={~hiddenTypes.indexOf('nested-arrays')} nested={nested} hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'event' && <EventsFilterPanel nested={nested} hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'aggregate' && <AggregatesFilterPanel hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'rfm-segment' && <RFMSegmentsFilterPanel hidePreview={hidePreview} onSelect={handleSelectRFMSegment} />}
          {customTabs.filter(t => !!t.options?.length).map(t => t.name === tab && <CustomFilterPanel hideArrays={~hiddenTypes.indexOf('nested-arrays')} hidePreview={hidePreview} onSelect={handleSelect} options={t.options} type={t.name} />)}
          {/*{tab === 'expression' && null}*/}

          {!hidePreview && (
            <DescriptionContainer>
              <Description>
                {tab === 'linear' && p('linear_description')}
                {tab === 'array' && p('array_description')}
                {tab === 'event' && p('event_description')}
                {tab === 'aggregate' && p('aggregate_description')}
                {customTabs.map(t => (t.name === tab && t.description) && p(t.description))}
              </Description>
              {(tab === 'aggregate' && !disableAggregateCreation) && (
                <WithPermissions name={UserPermissions.CREATE_AGGREGATES}>
                  <CreateAggregateButton onClick={() => handleSelect(null, SegmentsRuleTypes.AGGREGATE)}>{p('create_aggregate')}</CreateAggregateButton>
                </WithPermissions>
              )}
              {tab === 'rfm-segment' && (
                <RFMSegmentsRightPanel selectedAnalytics={selectedRFMSegment} onSelect={handleSelect} />
              )}
            </DescriptionContainer>
          )}
        </Content>
      )
    } else if (searchInputValue.trim().length) {
      return renderSearchFilters();
    }
  }

  const getFilteredArray = (array) => {
    return array?.filter(f => f.label.toLowerCase().indexOf(searchInputValue.toLowerCase()) !== -1);
  }

  const renderSearchFilters = () => {
    const filterGroups = [
      { name: p('linear'), filter: 'linear',  options: getFilteredArray(linearFields), ruleType: SegmentsRuleTypes.LINEAR },
      { name: p('arrays'), filter: 'array', options: getFilteredArray(arrayFields)?.map?.(a => ({ ...a, value: a.field })) || [], ruleType: SegmentsRuleTypes.ARRAY },
      { name: p('events'), filter: 'event', options: getFilteredArray(events.data)?.map?.(e => ({ ...e, value: e.name })) || [], ruleType: SegmentsRuleTypes.EVENT },
      { name: p('aggregates'), filter: 'aggregate', options: getFilteredArray(aggregates), ruleType: SegmentsRuleTypes.AGGREGATE },
      { name: p('rfm_segments'), filter: 'rfm_segment', options: getFilteredArray(rfmAnalyticsOptions), ruleType: SegmentsRuleTypes.RFM_SEGMENT },
      ...(customTabs || []).map(({ name, options, ruleType }) => ({ name: SEGMENT_FILTER_LABEL_MAP[name], options: getFilteredArray(options), ruleType })),
    ].filter(({ filter }) => !~hiddenTypes.indexOf(filter));

    const empty = filterGroups.filter(({ options }) => !!options.length > 0).length === 0;

    return (
      <FilteredContainer empty={empty} $isDefault={!isModal} $mw={maxWidth || 420}>
        {filterGroups.map(({ name, options, ruleType }) => options.length ? (
          <Group>
            <GroupTitle>{name}</GroupTitle>
            {options.map(({ label, ...rest }) => (
              <GroupItem onClick={() => ruleType === SegmentsRuleTypes.RFM_SEGMENT ? (setSearchInputValue(''), setSelectedRFMSegment(rest.value)) : handleSelect({ ...rest, label }, ruleType)}>{label}</GroupItem>
            ))}
          </Group>
        ) : null)}
        {empty ? <NoDataContainer><NoData /></NoDataContainer> : null}
      </FilteredContainer>
    )
  }

  if (isModal) {
    return (
      <>
        <Modal
          data-testid={'store-segment-filter-dropdown'}
          open={opened}
          style={{ maxWidth: 576, minWidth: '576px' }}
          contentStyles={{ padding: 0, borderRadius: 13 }}
          onClose={() => setOpened(false)}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Overlay hidePreview={hidePreview}>
              <Header>
                <SearchContainer>
                  <Input
                    style={{ width: '100%', height: '30px' }}
                    placeholder={t('labels.search')}
                    withSuffix
                    withPrefix
                    prefix={<Icon size={16} name="Operators-search" color="#909399" />}
                    suffix={!!searchInputValue.length ? <div style={{ cursor: 'pointer' }} onClick={() => setSearchInputValue('')}><Icon size={16} name="Clear" color="#909399" /></div> : null}
                    type="text"
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                  />
                </SearchContainer>
                {!searchInputValue.trim().length ? (
                  <Tabs disabled={!!searchInputValue.trim().length} data-testid={'store-segment-filter-dropdown-tabs'}>
                    {SEGMENT_FILTER_TYPE_MAP.concat(customTabs.map(extract('name'))).filter(t => !~hiddenTypes.indexOf(t)).map((name) => (
                      <Tab
                        data-testid={'store-segment-filter-'+name+'-tab'}
                        key={name}
                        selected={name === tab}
                        ref={te.refChanger(name)}
                        onClick={() => setTab(name)}
                      >
                        {SEGMENT_FILTER_LABEL_MAP[name]}
                      </Tab>
                    ))}
                    <TabIndicator tab={te.tabEl} $id={id} key={te.updater}/>
                  </Tabs>
                ) : null}
              </Header>
              {renderContent()}
            </Overlay>
          </div>
        </Modal>
        <Container onClick={() => setOpened(true)} style={style}>
          {children}
        </Container>
      </>
    );
  }

  return (
    <Dropdown
      data-testid={'store-segment-filter-dropdown'}
      trigger={['click', 'contextMenu']}
      open={opened}
      placement="topCenter"
      onOpenChange={setOpened}
      overlay={(
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Overlay $isDefault hidePreview={hidePreview} $mw={maxWidth || 420}>
            <Header>
              <SearchContainer>
                <Input
                  style={{ width: '100%', height: '30px' }}
                  placeholder={t('labels.search')}
                  withSuffix
                  withPrefix
                  prefix={<Icon size={16} name="Operators-search" color="#909399" />}
                  suffix={!!searchInputValue.length ? <div style={{ cursor: 'pointer' }} onClick={() => setSearchInputValue('')}><Icon size={16} name="Clear" color="#909399" /></div> : null}
                  type="text"
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                />
              </SearchContainer>
              {!searchInputValue.trim().length ? (
                <Tabs disabled={!!searchInputValue.trim().length} data-testid={'store-segment-filter-dropdown-tabs'}>
                  {SEGMENT_FILTER_TYPE_MAP.concat(customTabs.map(extract('name'))).filter(t => !~hiddenTypes.indexOf(t)).map((name) => (
                    <Tab
                      data-testid={'store-segment-filter-'+name+'-tab'}
                      key={name}
                      selected={name === tab}
                      ref={te.refChanger(name)}
                      onClick={() => setTab(name)}
                    >
                      {SEGMENT_FILTER_LABEL_MAP[name]}
                    </Tab>
                  ))}
                  <TabIndicator tab={te.tabEl} $id={id} key={te.updater}/>
                </Tabs>
              ) : null}
            </Header>
            {renderContent()}
          </Overlay>
        </div>
      )}
    >
      <Container style={style}>
        {children}
      </Container>
    </Dropdown>
  );
}

export default FiltersDropdown;


