import styled, {css} from 'styled-components';
import {styledProp} from "@utils";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: Rubik-Regular;
  font-size: 18px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 16px 0;
  border-top: 1px solid #DCDFE6;
  
  ${styledProp('first', css`
    border: none;
    color: #909399;
  `)}
`;

export const KeyContainer = styled.div`
  width: 50%;
`;

export const KeyBackground = styled.div`
  background: #E5E8ED;
  border-radius: 4px;
  width: fit-content;
  padding: 2px 6px;
`;

export const Key = styled.div`
  color: #8B8E9F;

  ${styledProp('first', css`
    color: #909399;
  `)}
`;

export const Value = styled.div`
  width: 50%;
`;