import styled, { keyframes, css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  color: #109DFB;
  margin-left: 8px;
  ${styledProp('loading', css`
    & > svg {
      animation: 600ms ${rotate} infinite linear;
    }
  `)}
`;
