import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { AccountActionTypes } from '@store/actions/types';
import { Endpoints } from '@constants';
import { createRequestAction } from '@store/lib/utils/creators';

function* cancelSubscriptionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/stripe/cancel',
    body: {
      force: false,
    },
  }));
}

function* downloadInvoiceSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/stripe/invoices/${action.meta.id}/download`,
  }));
}


function* getAccountMetricsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/account/metrics/${action.payload.entity}`,
    params: {
      date_range: action.payload.range,
    },
  }));
}

function* getSendingLimitMetricsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/metrics`,
    params: {
      range: action.payload.range,
      app_ids: action.payload.app_ids,
    },
  }))
}

function* getAppsContactsCountSaga(action) {
  yield put(createRequestAction(action, {
    endpoint: '/api/seg/customer/count-by-brands',
    method: 'post',
    body: { appIds: action.payload.appIds }
  }));
}

function* getCustomerSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/customer',
  }));
}

function* getDefaultPaymentMethodSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/customer',
  }));
}

function* getProductSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/product',
  }));
}

function* getQuantityLogSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/quantity-log',
  }));
}

function* getRegularInvoicesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/invoices/regular',
    params: { includePending: true }
  }));
}

function* getSubscriptionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.SUBSCRIPTION,
  }));
}

function* getUpcomingInvoicesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/invoices/upcoming',
  }));
}

function* resumeSubscriptionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/resume',
    params: { payment_method: action.payload.payment_method }
  }));
}

function* setupStripeIntentSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/stripe/setup-intent',
  }));
}

function* subscribeSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/stripe/subscribe',
    body: {
      payment_method: action.payload.payment_method,
      product_id: action.payload.product_id,
      price_id: action.payload.price_id,
      start_quantity: action.payload.start_quantity,
    }
  }));
}

function* updateCustomerSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/stripe/customer',
    body: {
      first_name: action.payload.first_name,
      last_name: action.payload.last_name,
      address: action.payload.billing_address,
    }
  }));
}

function* updatePaymentMethodSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/stripe/default-payment-method',
    body: {
      payment_method: action.payload.payment_method,
    }
  }));
}

export default function* accountRootSaga() {
  yield all([
    takeLatest(AccountActionTypes.GET_DEFAULT_PAYMENT_METHOD, getDefaultPaymentMethodSaga),
    takeLatest(AccountActionTypes.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga),
    takeLatest(AccountActionTypes.GET_SUBSCRIPTION, getSubscriptionSaga),
    takeLatest(AccountActionTypes.DOWNLOAD_INVOICE, downloadInvoiceSaga),
    takeLatest(AccountActionTypes.GET_CUSTOMER, getCustomerSaga),
    takeLatest(AccountActionTypes.GET_REGULAR_INVOICES, getRegularInvoicesSaga),
    takeLatest(AccountActionTypes.GET_PRODUCT, getProductSaga),
    takeLatest(AccountActionTypes.GET_UPCOMING_INVOICES, getUpcomingInvoicesSaga),
    takeLatest(AccountActionTypes.SETUP_STRIPE_INTENT, setupStripeIntentSaga),
    takeLatest(AccountActionTypes.SUBSCRIBE, subscribeSaga),
    takeLatest(AccountActionTypes.UPDATE_CUSTOMER, updateCustomerSaga),
    takeLatest(AccountActionTypes.UPDATE_PAYMENT_METHOD, updatePaymentMethodSaga),
    takeLatest(AccountActionTypes.GET_QUANTITY_LOG, getQuantityLogSaga),
    takeLatest(AccountActionTypes.RESUME_SUBSCRIPTION, resumeSubscriptionSaga),
    takeLatest(AccountActionTypes.GET_APPS_CONTACTS_COUNT, getAppsContactsCountSaga),
    takeEvery(AccountActionTypes.GET_ACCOUNT_METRICS, getAccountMetricsSaga),
    takeEvery(AccountActionTypes.GET_SENDING_LIMIT_METRICS, getSendingLimitMetricsSaga),
  ]);
}
