import React from 'react';

import { Container } from './styled';
import { useTranslation } from '@hooks';

const AddFilterButton = ({ onClick, style, filled }) => {
  const { p } = useTranslation('segments_page');

  return (
    <Container $filled={filled} style={style} onClick={onClick} data-testid={'store-segment-add-filter-button'}>
      <svg style={{ marginRight: 8 }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.375 8H9V5.625C9 5.55625 8.94375 5.5 8.875 5.5H8.125C8.05625 5.5 8 5.55625 8 5.625V8H5.625C5.55625 8 5.5 8.05625 5.5 8.125V8.875C5.5 8.94375 5.55625 9 5.625 9H8V11.375C8 11.4437 8.05625 11.5 8.125 11.5H8.875C8.94375 11.5 9 11.4437 9 11.375V9H11.375C11.4437 9 11.5 8.94375 11.5 8.875V8.125C11.5 8.05625 11.4437 8 11.375 8Z" fill="#109DFB"/>
        <path d="M8.5 1.5C4.63438 1.5 1.5 4.63438 1.5 8.5C1.5 12.3656 4.63438 15.5 8.5 15.5C12.3656 15.5 15.5 12.3656 15.5 8.5C15.5 4.63438 12.3656 1.5 8.5 1.5ZM8.5 14.3125C5.29063 14.3125 2.6875 11.7094 2.6875 8.5C2.6875 5.29063 5.29063 2.6875 8.5 2.6875C11.7094 2.6875 14.3125 5.29063 14.3125 8.5C14.3125 11.7094 11.7094 14.3125 8.5 14.3125Z" fill="#109DFB"/>
      </svg>
      {p('new_rule')}
    </Container>
  )
};

export default AddFilterButton;
