import WorkflowNodes from './nodeNames';

const WorkflowIcons = {
  [WorkflowNodes.RESOURCE]: 'Node-Resource',
  [WorkflowNodes.EVENT]: 'Node-Event',
  [WorkflowNodes.CONTACT_CREATED]: 'Node-Add-contact',
  [WorkflowNodes.DATA_CHANGE]: 'Node-Data-change',
  [WorkflowNodes.SEND_SMS]: 'Node-Send-SMS',
  [WorkflowNodes.SEND_EMAIL]: 'Node-Send-e-mail',
  [WorkflowNodes.PUSH_DISCOUNT]: 'Node-Push-discount',
  [WorkflowNodes.MOBILE_PUSH]: 'Node-Mobile-push',
  [WorkflowNodes.WEBPUSH]: 'Web-push',
  [WorkflowNodes.VIBER]: 'Viber',
  [WorkflowNodes.API_REQUEST]: 'Api-request',
  [WorkflowNodes.WAIT]: 'Node-Wait',
  [WorkflowNodes.EXCLUDE_FILTER]: 'Node-Exclusion-filter',
  [WorkflowNodes.API_FILTER]: 'Node-API-filter',
  [WorkflowNodes.QUICK_FILTER]: 'Node-Quick-filter',
  [WorkflowNodes.SWITCH_FILTER]: 'Node-Switch-filter',
  [WorkflowNodes.RETARGETING]: 'Sidebar-Contacts',
  [WorkflowNodes.AB_SPLITTER]: 'B-testing',
  [WorkflowNodes.EMAIL]: 'Node-Send-e-mail',
  [WorkflowNodes.ENTRY_ANOTHER_WF]: 'Nodes-Finish',
  [WorkflowNodes.CUSTOMER_CHANGE]: 'Node-Data-change',
  [WorkflowNodes.PAGE_VIEW]: 'Drag--drop-redactor',
};

export default WorkflowIcons;

