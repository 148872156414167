import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Colors } from '@res/theme';
import { Icon, OnboardingHighlight, WithPermissions, WithOnboarding, Text } from '@components';
import { useTranslation, useOnboarding, useNavigate } from '@hooks';

import {
  Container,
  Row,
  Col,
  IconContainer,
  Title,
  Accordion,
  AccordionRow,
  ItemInfo,
  Hoverable, ItemRow,
} from './styled';
import { AccordionArrow } from '../AccordionArrow';
import { Link } from '../Popover/styled';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingSelector } from '@store/selectors';
import { updateOnboarding } from '@store/actions/creators';
import { useTransition } from '@react-spring/web';
import { removeLeadingSlash, sequence } from '@utils';
import { TextVariants } from '@constants';

const transitionConfig = {
  from: { opacity: 1, translateX: 0 },
  enter: { opacity: 1, translateX: 1 },
};

const SidebarItem = ({ path, title, name, pages, icon, opened, accordionState, permission, setAccordionState, onClose, fallback }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { p } = useTranslation('sidebar');
  const [hovered, setHovered] = useState(false);
  const onboarding = useOnboarding('sidebar');
  const dispatch = useDispatch();
  const onboardingData = useSelector(onboardingSelector);
  const active = location.pathname.startsWith(path);

  useEffect(() => {
    if (!opened) {
      setAccordionState('');
    }
  }, [opened]);

  const getOnboardParams = (itemName) => {
    if (onboarding.enabled(1) && itemName === 'contacts') {
      return { showHint: true, hintText: p('please_go_customers'), lastHint: false }
    }

    if (onboarding.enabled(2) && itemName === 'import') {
      return { showHint: true, hintText: p('please_go_import'), lastHint: false }
    }

    if (onboarding.enabled(5) && itemName === 'segments') {
      return { showHint: true, hintText: p('please_go_segments'), lastHint: false }
    }

    if (onboarding.enabled(6) && itemName === 'templates') {
      return { showHint: true, hintText: p('please_go_templates'), lastHint: false }
    }

    if (onboarding.enabled(7) && itemName === 'email') {
      return { showHint: true, hintText: p('please_go_emails'), lastHint: false }
    }

    if (onboarding.enabled(8) && itemName === 'workflows') {
      return { showHint: true, hintText: p('please_go_workflows'), lastHint: true }
    }

    return { showHint: false, hintText: '' }
  }

  const { showHint, hintText, lastHint } = getOnboardParams(name);

  const handleClick = (p = path, noClose = false) => {
    if (pages?.length && opened) {
      return;
    }

    if (lastHint) {
      dispatch(updateOnboarding(onboardingData.sidebar.key, { step: onboardingData.sidebar.stepToGo }));
      dispatch(updateOnboarding('sidebar', { key: '' }));
      onboarding.end();
    } else if (pages?.length > 0 && showHint) {
      onboarding.nextStep();
    } else if (showHint) {
      dispatch(updateOnboarding(onboardingData.sidebar.key, { step: onboardingData.sidebar.stepToGo }));
      dispatch(updateOnboarding('sidebar', { key: '', step: 0 }));
    }

    // if (pages?.length > 0) {
    //   return;
    // }

    navigate(pages?.length && p === path ? fallback || p : p);
    if (noClose) {
      return;
    }

    onClose?.();
  }

  const handleLinkClick = (path, subShowHint) => () => {
    if (subShowHint) {
      dispatch(updateOnboarding(onboardingData.sidebar.key, { step: onboardingData.sidebar.stepToGo }));
      dispatch(updateOnboarding('sidebar', { key: '', step: null }));
    }

    navigate(path);
    onClose?.();
  }

  const hoveredTransition = useTransition(hovered, transitionConfig);

  const toggleAccordionState = () => {
    if (accordionState === path) {
      setAccordionState('');
    } else {
      setAccordionState(path);
    }
  }

  return (
    <Container data-testid={path} $opened={opened} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} $active={active} to={path} onClick={() => (opened || !pages?.length) && handleClick(path)}>
        {/*<ActiveIndicator active={active} />*/}
      <WithPermissions name={opened ? permission : void 0} placement="right">
        <Row $active={active} onClick={opened ? toggleAccordionState : () => {}}>
          {!opened ? (
            pages?.length ? (
              <div style={{ display: 'flex', backgroundColor: active ? Colors.Sidebar.SELECTED_BG : Colors.Sidebar.BG }}>
                <WithPermissions name={permission} placement="right">
                  <IconContainer style={{ zIndex: 10 }}>
                    <Icon size={16} color={active ? Colors.Sidebar.ICON_ACTIVE : (hovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT)} name={icon}/>
                  </IconContainer>
                </WithPermissions>
                {hoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                 <ItemInfo style={{ opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`) }} pad={pages?.length}>
                   <ItemRow $header={pages?.length}>
                     {title}
                   </ItemRow>
                   <Col>
                     {pages?.map?.(({ name, path, permission, isBeta }) => (
                       <ItemRow key={path} onClick={() => handleClick(path)}>
                         {permission ? (
                           <WithPermissions key={path} name={permission} placement="right">
                             <Hoverable style={{ position: 'relative' }}>
                               <Text width={140} variant={TextVariants.MENU_1_AUTO}>
                                <Link to={removeLeadingSlash(path)} style={{ userSelect: 'none' }} white={location.pathname === path} onClick={() => {}} $isBeta={isBeta}>
                                  {p(name)}
                                </Link>
                               </Text>
                             </Hoverable>
                           </WithPermissions>
                         ) : (
                           <Hoverable style={{ position: 'relative' }}>
                             <Text width={140} variant={TextVariants.MENU_1_AUTO}>
                                <Link to={removeLeadingSlash(path)} style={{ userSelect: 'none' }} white={location.pathname === path} key={path} onClick={() => {}} $isBeta={isBeta}>
                                  {p(name)}
                                </Link>
                             </Text>
                           </Hoverable>
                         )}
                       </ItemRow>
                     ))}
                   </Col>
                 </ItemInfo>
                ))}
              </div>
              ) : (
              <WithPermissions name={permission} placement="right">
                <div style={{ display: 'flex', backgroundColor: active ? Colors.Sidebar.SELECTED_BG : Colors.Sidebar.BG }} onClick={() => handleClick(path, true)}>
                  <IconContainer style={{ zIndex: 10 }}>
                    <Icon size={16} color={active ? Colors.Sidebar.ICON_ACTIVE : (hovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT)} name={icon}/>
                  </IconContainer>
                  {hoveredTransition(({ opacity, scale, translateX }, hovered) => hovered && (
                    <ItemInfo style={{ opacity, transform: translateX.to({ range: [1.0, 0.0], output: [0, -70] }).to(x => `translate3d(${x}px, 0, 0)`), userSelect: 'none' }}>
                      <Text width={140} variant={TextVariants.MENU_1_AUTO}>
                        {title}
                      </Text>
                    </ItemInfo>
                  ))}
                </div>
              </WithPermissions>
              )
          ) : (
            <IconContainer>
              <Icon size={16} color={active ? Colors.Sidebar.ICON_ACTIVE : (hovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT)} name={icon}/>
            </IconContainer>
          )}
          <Col>
          <Title notSubcategory={!pages?.length} active={active}>
            <Text width={140} variant={TextVariants.MENU_1} onClick={() => pages?.length ? '' : handleClick(path, true)}>
              <WithOnboarding
                getPopupContainer={() => document.body}
                enabled={showHint && opened}
                preventDefaultClose={!pages?.length > 0}
                onboardKey="sidebar"
                placement="bottom"
                text={hintText}
              >
                <OnboardingHighlight sidebar top={10} bottom={10} right={100} left={40} on={showHint && opened} background="transparent">
                  {title}
                </OnboardingHighlight>
              </WithOnboarding>
            </Text>
            {pages?.length && <AccordionArrow opened={accordionState === path} />}
          </Title>
            {pages?.length && (
              <Accordion $opened={accordionState === path}>
                {pages.map((({ name: subName, path: pagePath, isBeta }) => {
                  const { showHint: subShowHint, hintText: subHintText } = getOnboardParams(subName);
                  return (
                    <div key={pagePath} style={{ position: 'relative' }}>
                      {/*{(location.pathname === pagePath || (pagePath === `${Routes.CONTACTS_IMPORT}/import` && location.pathname === Routes.CONTACTS_IMPORT_HISTORY)) && <ActiveCircle opened={opened} />}*/}
                      <WithOnboarding preventDefaultClose={true} placement="bottom" getPopupContainer={() => document.body} enabled={subShowHint && accordionState === path} text={subHintText} onboardKey="sidebar">
                        <OnboardingHighlight left={-40} right={-50} top={-3} bottom={-3} sidebar background="transparent" on={subShowHint && accordionState === path}>
                          <AccordionRow data-testid={`sidebar-item-${name}-${subName}`} $opened={opened} $active={location.pathname === pagePath && accordionState === path} key={pagePath} onClick={sequence(e => { e.stopPropagation() }, handleLinkClick(pagePath, subShowHint))} $isBeta={isBeta}>
                            {p(subName)}
                          </AccordionRow>
                        </OnboardingHighlight>
                      </WithOnboarding>
                    </div>
                  )
                }))}
              </Accordion>
            )}
          </Col>
        </Row>
      </WithPermissions>
      </Container>
  );
};

export default React.memo(SidebarItem);
