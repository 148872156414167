import { compose } from '@utils';
import { asCreator, withCallbacks, withPagination } from '@store/lib';
import FieldsActionTypes from '@store/actions/types/fields';

export const getAutocomplete = compose(
  withCallbacks,
  asCreator(({ path, query, limit, filter, offset }) => ({
    type: FieldsActionTypes.GET_AUTOCOMPLETE,
    payload: { path, query, limit, offset, filter },
    meta: { path, query, limit, offset, filter }
  }))
);

export const getDynamicVariables = compose(
  withCallbacks,
  withPagination,
  asCreator(() => ({
    type: FieldsActionTypes.GET_DYNAMIC_VARIABLES,
  })),
);

export const createDynamicVariable = compose(
  withCallbacks,
  asCreator(({ name, label, default_value }) => ({
    type: FieldsActionTypes.CREATE_DYNAMIC_VARIABLE,
    payload: { name, label, default_value },
  })),
);

export const updateDynamicVariable = compose(
  withCallbacks,
  asCreator(({ id, label, default_value, name }) => ({
    type: FieldsActionTypes.UPDATE_DYNAMIC_VARIABLE,
    payload: { label, default_value, id, name },
    meta: { id },
  })),
);

export const deleteDynamicVariable = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: FieldsActionTypes.DELETE_DYNAMIC_VARIABLE,
    payload: { id },
    meta: { id }
  })),
);
