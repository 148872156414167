import styled, { css } from 'styled-components';

import { Input as ANTDInput } from 'antd';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
  overflow: visible;
  max-width: 700px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
  padding-left: 4px;
  
  ${styledProp('$nowrap', css`
    white-space: nowrap;
  `)}
`;

export const StyledInput = styled(ANTDInput)`
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 9px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: 13px;
  margin-bottom: 16px;
  box-shadow: none !important;
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}
  
  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}
  
  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus {
    box-shadow: none !important;
    border-color: #109DFB;
  }
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;

export const NumberArrowsContainer = styled.div`
  position: absolute;
  right: 6px;
  user-select: none;
  width: 14px;
  display: flex;
  flex-direction: column;
  top: 5px;
`;

export const EmojiContainer = styled.div`
  position: absolute;
  right: 20px;
  width: 10px;
  height: 10px;
  top: 28px;
`;

export const NumberArrowUp = styled.div`
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  background: #F0F2F6;
  border-radius: 4px 4px 1px 1px;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const NumberArrowDown = styled.div`
  width: 14px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0F2F6;
  border-radius: 1px 1px 4px 4px;
  cursor: pointer;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

