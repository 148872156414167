import React, { useState } from 'react';

import { MobilePushStatuses, Paths, UserPermissions, WebpushStatusLabels } from '@constants';
import { useSelector } from 'react-redux';
import {
  mobilePushLoadingSelector,
  mobilePushSelector, templateSelector,
} from '@store/selectors';
import { useModalState, useTranslation } from '@hooks';
import {
  ActionButton,
  DateCell, Icon,
  Link,
  SortableTitle,
  Table,
  TableActionsBar,
  WithPermissions,
  ViewMobilePushTemplateModal
} from '@components';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { Dropdown } from 'antd';
import { StatusCell } from '../StatusCell';
import { extract, tryHumanizeResponse } from '@utils';
import { IdType, Actions, Overlay } from '@components/lib/styled';
const resolveStatus = status => WebpushStatusLabels[status];

const resolveColor = status => ({
  [MobilePushStatuses.SENT]: '#909399',
  [MobilePushStatuses.DISPLAYED]: '#24993E',
  [MobilePushStatuses.DISMISS]: '#C8402F',
  [MobilePushStatuses.CLICK]: '#24993E',
  [MobilePushStatuses.FAILED]: '#C8402F',
  [MobilePushStatuses.OPENED]: '#24993E',
})[status];

const MobilePushTable = ({ keyExtractor, cols, ordering }) => {
  const mobilePush = useSelector(mobilePushSelector);
  const loading = useSelector(mobilePushLoadingSelector);
  const templates = useSelector(templateSelector);
  const [selectedMobilePush, selectMobilePush] = useState({
    image: '',
    mobile_push_name: '',
  });
  const mobilePushPreviewModal = useModalState(false);
  const { t, p } = useTranslation('notification_history')

  const openPreview = (data) => {
    selectMobilePush({
      name: data.mobile_push_name,
      title: data.title || '',
      subtitle: data.sub_title || '',
      message: data.message || '',
      image: data.image,
      actions: data.act,
      customerId: data.customer_id,
    });
    mobilePushPreviewModal.open();
  }

  const createMobilePushTableColumns = (ordering, callbacks) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      title: () => <SortableTitle name="mobile_push_name" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (id, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_MOBILE_PUSH}><Link to={`${Paths.TEMPLATES_MOBILE_PUSH}/${data.template_id}?view=1`}>{templates?.mobile_push?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      key: 'created_at',
      className: `notification-history-cell-date`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)} />
      }
    },
    {
      title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
      dataIndex: 'response',
      key: 'response',
      className: `notification-history-cell-response`,
      render: (r, { s }) => {
        if (!r) {
          return null;
        }

        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                 <pre>
                   {tryHumanizeResponse(r)}
                 </pre>
               </Overlay>
             )}
          >
             <div>
               <Icon color={resolveColor(s)} name="Info-icon" size={16} />
             </div>
           </Dropdown>
         )
       },
     },
    {
      title: () => <SortableTitle right>{t('labels.actions')}</SortableTitle>,
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      className: `notification-history-cell-actions`,
      render: (f, d) => {
        return (
          <Actions>
            <TableActionsBar
              actionButtons={(
                <ActionButton testId="mobile-push-action-show-preview" tooltip={p('show_preview')} onClick={() => callbacks.openPreview(d)} icon="Show-icon" />
              )}
            />
          </Actions>
        );
      }
    },
  ];

  const columns = createMobilePushTableColumns(ordering, { openPreview });

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_MOBILE_PUSHES} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={mobilePush.data?.map?.(extract('id')).join()}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          columns={columns}
          dataSource={mobilePush?.data}
          rowKey={keyExtractor}
        />
      </WithPermissions>

      <ViewMobilePushTemplateModal
        opened={mobilePushPreviewModal.opened}
        onClose={mobilePushPreviewModal.close}
        onActionClick={() => {}}
        data={selectedMobilePush}
      />
    </>
  )
}

export default MobilePushTable;


