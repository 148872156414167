import { getSmoothStepPath, BaseEdge } from 'reactflow';
import { useContext, useState } from 'react';
import { WorkflowEditorContext } from '@contexts';
import { by, testId } from '@utils';

const pathDiff = (x1, y1, x2, y2, initX = 6, initY = 6) => {
  return `${x1 - initX + x2} ${y1 - initY + y2}`;
};

const DefaultEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  deleteDisabled: deleteDisabledOuter,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}) => {
  const [edgePath] = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  const diffLines = Math.abs(sourceY - targetY) > 10;
  const [hovered, setHovered] = useState(false);
  const ctx = useContext(WorkflowEditorContext);
  const sourceNode = ctx.renderNodes.find(by(source));
  const targetNode = ctx.renderNodes.find(by(target));
  const deleteDisabled = sourceNode?.data?.name === 'splitter' || sourceNode?.data?.name === 'filter' || targetNode?.data?.name === 'end' || deleteDisabledOuter;

  const handleRemove = () => {
    ctx.removeEdge(id);
  };

  const x = (sourceX + targetX) / 2 + (diffLines ? 20 : 0);
  const y = (sourceY + targetY) / 2 - (diffLines ? 0 : 20);

  const rect = data.displayIndicator && (
    diffLines ? (
      <rect
        {...{ x, y }}
        width="4"
        height="40"
        rx="2"
        fill="#5FC1F9"
        style={{
          transformOrigin: `${x}px ${y}px`,
          transform: 'rotate(90deg)',
        }}
      />
    ) : (
      <rect {...{ x, y }} width="4" height="40" rx="2" fill="#5FC1F9"/>
    )
  );

  const cx = (sourceX + targetX) / 2 + (diffLines ? 0 : 0);
  const cy = (sourceY + targetY) / 2 - (diffLines ? 0 : 0);

  const remove = (!deleteDisabled && hovered) && (
    <g {...testId(`workflow-node-${data.title}-close`)()} className="flow-edge-remove" onClick={handleRemove}>
      <circle r="6" {...{ cx, cy }} fill="#C8402F" />
      <path d={`M${pathDiff(7.6794, 3.61351, cx, cy)}L${pathDiff(6.00003, 5.29289, cx, cy)}L${pathDiff(4.32065, 3.61351, cx, cy)}C${pathDiff(4.27203, 3.5649, cx, cy)} ${pathDiff(4.19248, 3.5649, cx, cy)} ${pathDiff(4.14387, 3.61351, cx, cy)}L${pathDiff(3.61354, 4.14384, cx, cy)}C${pathDiff(3.56493, 4.19246, cx, cy)} ${pathDiff(3.56493, 4.27201, cx, cy)} ${pathDiff(3.61354, 4.32062, cx, cy)}L${pathDiff(5.29292, 6, cx, cy)}L${pathDiff(3.61354, 7.67938, cx, cy)}C${pathDiff(3.56493, 7.72799, cx, cy)} ${pathDiff(3.56493, 7.80754, cx, cy)} ${pathDiff(3.61354, 7.85616, cx, cy)}L${pathDiff(4.14387, 8.38649, cx, cy)}C${pathDiff(4.19248, 8.4351, cx, cy)} ${pathDiff(4.27203, 8.4351, cx, cy)} ${pathDiff(4.32065, 8.38649, cx, cy)}L${pathDiff(6.00003, 6.70711, cx, cy)}L${pathDiff(7.6794, 8.38649, cx, cy)}C${pathDiff(7.72802, 8.4351, cx, cy)} ${pathDiff(7.80757, 8.4351, cx, cy)} ${pathDiff(7.85618, 8.38649, cx, cy)}L${pathDiff(8.38651, 7.85616, cx, cy)}C${pathDiff(8.43512, 7.80754, cx, cy)} ${pathDiff(8.43512, 7.72799, cx, cy)} ${pathDiff(8.38651, 7.67938, cx, cy)}L${pathDiff(6.70713, 6, cx, cy)}L${pathDiff(8.38651, 4.32062, cx, cy)}C${pathDiff(8.43512, 4.27201, cx, cy)} ${pathDiff(8.43512, 4.19246, cx, cy)} ${pathDiff(8.38651, 4.14384, cx, cy)}L${pathDiff(7.85618, 3.61351, cx, cy)}C${pathDiff(7.80757, 3.5649, cx, cy)} ${pathDiff(7.72802, 3.5649, cx, cy)} ${pathDiff(7.6794, 3.61351, cx, cy)}Z`} fill="white"/>
    </g>
  );

  return (
    <g key={id} className="flow-edge" style={{ background: 'transparent' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <path id={id} stroke="transparent" fill="transparent" strokeWidth={15} d={edgePath} style={{ cursor: 'pointer' }}></path>
      <BaseEdge path={edgePath} markerEnd={markerEnd} {...((hovered && !deleteDisabled) ? { style: { stroke: "#C8402F" } } : {})} />
      {rect}
      {remove}
    </g>
  );
};

export default DefaultEdge;
