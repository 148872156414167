import React from 'react';

import { Container } from './styled.js';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { by } from '@utils';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { useTranslation } from '@hooks';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

const emailInteractionOptions = [
  {
    label: 'workflow_page.sent',
    value: 'sent',
  },
  {
    label: 'workflow_page.delivered',
    value: 'delivered',
  },
  {
    label: 'workflow_page.open',
    value: 'open',
  },
  {
    label: 'workflow_page.click',
    value: 'click',
  }
];

const EmailNodeSettingsInfo = ({ node: outerNode, options }) => {
  const node = { data: createMultinodeUpdater(outerNode.data).values[0] };
  const { t, p } = useTranslation('workflow_page');
  const email = options?.email?.find?.(by(node.data?.email_id));

  if (!email) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.email')}:
        </InfoTitle>
        <InfoText>
          {email.name}
        </InfoText>
      </InfoRow>
      <InfoRow>
        <InfoTitle>
          {t('labels.action')}:
        </InfoTitle>
        <InfoText>
          {t(emailInteractionOptions.find(by('value', node.data?.value)).label)}
        </InfoText>
      </InfoRow>
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default EmailNodeSettingsInfo;


