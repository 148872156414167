import React from 'react';
import { WorkflowNodes } from '@constants';

import { DataChangeNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/DataChangeNodeSettingsInfo';
import { DefaultFilterNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/DefaultFilterNodeSettingsInfo';
import { WaitNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/WaitNodeSettingsInfo';
import { PushDiscountNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/PushDiscountNodeSettingsInfo';
import { SendEmailNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendEmailNodeSettingsInfo';
import { SendSMSNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendSMSNodeSettingsInfo';
import { EventNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/EventNodeSettingsInfo';
import { ResourceNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ResourceNodeSettingsInfo';
import { NewContactNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/NewContactNodeSettingsInfo';
import { ABTestingNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ABTestingNodeSettingsInfo';
import { WebpushNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/WebpushNodeSettingsInfo';
import { ApiRequestNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ApiRequestNodeSettingsInfo';
import { EmailNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/EmailNodeSettingsInfo';
import { PageViewNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/PageViewNodeSettingsInfo';
import {
  CustomerChangeNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/CustomerChangeNodeSettingsInfo';
import { ViberNodeSettingsInfo } from '../ViberNodeSettingsInfo';
import { MobilePushNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/MobilePushNodeSettingsInfo';
import RetargetingNodeSettingsInfo
  from "@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/RetargetingNodeSettingsInfo/RetrgetingNodeSettingsInfo";
import {
  AnotherWorkflowNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnotherWorkflowNodeSettingsInfo';

const NodeSettingsInfo = ({ node, ...props }) => {
  switch (node?.data?.name) {
    case WorkflowNodes.QUICK_FILTER:
    case WorkflowNodes.SWITCH_FILTER:
    case WorkflowNodes.EXCLUDE_FILTER:
      return <DefaultFilterNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.WAIT:
      return <WaitNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.PUSH_DISCOUNT:
      return <PushDiscountNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.SEND_EMAIL:
      return <SendEmailNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.ENTRY_ANOTHER_WF:
      return <AnotherWorkflowNodeSettingsInfo node={node} {...props} />
    case WorkflowNodes.DATA_CHANGE:
      return <DataChangeNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.SEND_SMS:
      return <SendSMSNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.WEBPUSH:
      return <WebpushNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.VIBER:
      return <ViberNodeSettingsInfo node={node} {...props} />
    case WorkflowNodes.MOBILE_PUSH:
      return <MobilePushNodeSettingsInfo node={node} {...props} />
    case WorkflowNodes.API_REQUEST:
      return <ApiRequestNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.EVENT:
      return <EventNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.RESOURCE:
      return <ResourceNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.CONTACT_CREATED:
      return <NewContactNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.AB_TEST:
    case WorkflowNodes.AB_SPLITTER:
      return <ABTestingNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.EMAIL:
      return <EmailNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.PAGE_VIEW:
      return <PageViewNodeSettingsInfo node={node} {...props} />;
    case WorkflowNodes.CUSTOMER_CHANGE:
      return <CustomerChangeNodeSettingsInfo node={node} {...props} />
    case WorkflowNodes.RETARGETING:
      return <RetargetingNodeSettingsInfo node={node} {...props} />
    case WorkflowNodes.API_FILTER:
    case WorkflowNodes.AB_SPLITTER:
    case WorkflowNodes.END_PATH:
    case WorkflowNodes.INCLUDE:
    case WorkflowNodes.EXCLUDE:
    default:
      return null;
  }
}

export default NodeSettingsInfo;


