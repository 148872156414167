import React, { useCallback } from 'react';
import { ExpandIconContainer, SectionContainer, SectionTitle, SectionTitleContainer } from '../Sidebar/styled';
import { SidebarItem } from '../SidebarItem';
import { useModalState, useOnboarding, useTranslation } from '@hooks';
import { Icon, OnboardingHighlight, WithOnboarding, WithPermissions } from '@components';
import { Tooltip } from 'antd';
import { testId } from '@utils';

const SidebarSection = ({ type, title, options, entryPointSelected, onItemClick, disabledTooltip, expand, preventClick }) => {
  const expandable = useModalState(true);
  const onboarding = useOnboarding('workflows');
  const { p } = useTranslation('workflow_page');

  const disabled = type === 'entry_point' ? entryPointSelected : !entryPointSelected;
  const onboardEnabled = (onboarding.enabled(2) && type === 'entry_point') || (onboarding.enabled(7) && type === 'connection_channel') || (onboarding.enabled(8) && type === 'actions');

  const handleItemClick = useCallback((type, name) => {
    if (onboardEnabled) {
      onboarding.nextStep();
    }

    if (!preventClick) {
      onItemClick(type, name);
    }
  }, [onItemClick]);

  const getOnboardText = () => {
    if (onboarding.enabled(2) && type === 'entry_point') {
      return { hintText: p('choose_entry'), hintGuide: 'workflow-creation/' };
    } else if (onboarding.enabled(7) && type === 'connection_channel') {
      return { hintText: p('choose_message'), hintGuide: 'workflow-creation/' }
    } else if (onboarding.enabled(8) && type === 'actions') {
      return { hintText: p('choose_actions'), hintGuide: 'workflow-creation/' }
    } else return '';
  }

  const { hintText, hintGuide } = getOnboardText();

  return (
    <WithOnboarding
      placement="rightTop"
      enabled={onboardEnabled}
      onboardKey="workflows"
      guideLink={hintGuide}
      zIndex={1000}
      next={(onboarding.enabled(7) && type === 'connection_channel') || (onboarding.enabled(8) && type === 'actions')}
      text={hintText}
    >
      <OnboardingHighlight top={-6} left={-6} right={-6} on={onboardEnabled}>
      <SectionContainer
        style={{ maxHeight: (disabled && !expand) ? '0px' : !disabled && expandable.opened ? '500px' : '42px' }}
        $opened={!disabled && expandable.opened}
        key={type}
      >
        <SectionTitleContainer>
          {expand && <SectionTitle>{p(title)}</SectionTitle>}
          {(disabled && expand) && (
            <Tooltip title={disabledTooltip} placement="right">
              <div>
                <Icon
                  name="Info-icon"
                  style={{ marginLeft: '5px' }}
                  size={12}
                />
              </div>
            </Tooltip>
          )}
          {(!disabled && type !== 'entry_point') && (
            <ExpandIconContainer $opened={expandable.opened} onClick={expandable.toggle}>
              <Icon name="Dropdown-arrow-icon" size={12}/>
            </ExpandIconContainer>
          )}
        </SectionTitleContainer>
        {(expandable && !disabled) && options.map(({ title, name, permissions }) => (
          <WithPermissions names={permissions}>
            <SidebarItem
              addOpacity={preventClick}
              title={p(title)}
              type={type}
              name={name}
              key={name}
              expand={expand}
              onClick={handleItemClick}
            />
          </WithPermissions>
        ))}
      </SectionContainer>
      </OnboardingHighlight>
    </WithOnboarding>
  );
};

export default SidebarSection;
