import { AppActionTypes, UserActionTypes } from '@store/actions/types';

const initialState = {
  redirects: {},
  trialBarShown: true,
  sidebarOpened: true,
  messageChannelsDisclaimerClosed: {
    workflow: false,
  },
  hintMode: true,
  onboarding: {
    skipped: 0,
    contacts: {
      step: null,
      skipped: false,
      passed: false,
    },
    contactsImport: {
      step: null,
      skipped: false,
      passed: false,
    },
    templatesWebpush: {
      step: null,
      skipped: false,
      passed: false,
    },
    templatesSms: {
      step: null,
      skipped: false,
      passed: false,
    },
    integrations: {
      step: null,
      skipped: false,
      passed: false,
    },
    segments: {
      step: null,
      skipped: false,
      passed: false,
    },
    templates: {
      step: null,
      skipped: false,
      passed: false,
    },
    workflows: {
      step: null,
      skipped: false,
      passed: false,
    },
    workspaces: {
      step: null,
      skipped: false,
      passed: false,
    },
    users: {
      step: null,
      skipped: false,
      passed: false,
    },
    sidebar: {
      key: '',
      stepToGo: 0,
      step: 0,
      skipped: false,
      passed: false,
    }
  }
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case AppActionTypes.SET_SIDEBAR_OPENED:
      return {
        ...state,
        sidebarOpened: action.payload.opened,
      };
    case AppActionTypes.UPDATE_REDIRECTS:
      return {
        ...state,
        redirects: {
          ...state.redirects,
          ...action.payload.redirects,
        },
      };
    case AppActionTypes.SET_HINT_MODE:
      return {
        ...state,
        hintMode: action.payload.enabled !== undefined ? action.payload.enabled : !state.hintMode,
        onboarding: action.payload.enabled === false ? { ...initialState.onboarding } : { ...state.onboarding },
      }
    case AppActionTypes.RESET_ONBOARD:
      return {
        ...state,
        onboarding: initialState.onboarding,
      }
    case AppActionTypes.UPDATE_ONBOARDING:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          [action.payload.key]: {
            ...state.onboarding[action.payload.key],
            ...action.payload.params,
          },
        },
      }
    case AppActionTypes.SKIP_ONBOARD:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          skipped: state.onboarding.skipped + 1,
        }
      }
    // case UserActionTypes.LOGOUT:
    //   return {
    //     ...initialState,
    //   }
    case AppActionTypes.SET_TRIAL_BAR_SHOWN:
      return {
        ...state,
        trialBarShown: action.payload.shown,
      };
    case AppActionTypes.SET_MESSAGE_CHANNELS_DISCLAIMER_CLOSED:
      return {
        ...state,
        messageChannelsDisclaimerClosed: {
          ...state.messageChannelsDisclaimerClosed,
          ...action.payload.closed
        },
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        messageChannelsDisclaimerClosed: {},
      }
    default:
      return state;
  }
};
