// const CUSTOMER_REGEX = /{{customer\.(\w+)(?:\s*\?\?\s*'([^']+)')?}}/g;
const CUSTOMER_REGEX = /{{customer\.(\w+)(?:\s*\?\?\s*'([^']*)')?}}/g;

export const findAttributes = (strings, fieldsData) => {
  const regex = CUSTOMER_REGEX;
  const attributes = [];

  for (const str of strings) {
    let match;
    while ((match = regex.exec(str))) {
      const key = match[1];
      const custom = match[2] !== undefined ? match[2] : null;
      attributes.push({ key, custom });
    }
  }

  return attributes.map(attribute => ({ custom: attribute.custom, name: fieldsData?.find(f => f.key === attribute.key)?.name || attribute.key, key: attribute.key, value: fieldsData?.find(f => f.key === attribute.key)?.value || '' }));;
}

export const replaceKeysWithValues = (arr, keys, state) => {
  const regex = CUSTOMER_REGEX;
  const replaced = {};

  keys.forEach((key) => {
    const template = state[key];

    if (template) {
      replaced[key] = template.replace(regex, (match, capturedKey) => {
        const obj = arr.find((item) => item.key === capturedKey);
        const value = obj ? (obj?.custom || '') : undefined;
        return obj?.checked ? `{{customer.${capturedKey}}}` : `{{customer.${capturedKey} ?? '${value}'}}`;
      });
    }
  });

  return replaced;
}