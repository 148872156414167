import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Option = styled.div`
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 290ms ease;
  background: #fff;
  
  &:hover {
    background: #F0F2F6;
  }
`;

export const Icon = styled.div`
  
`;
