import { useMemo } from 'react';
import {
  EmailStatusOptions, MobilePushStatusOptions,
  RFMColorsConfig,
  SmsStatusOptions,
  ViberStatusOptions,
  WebpushStatusOptions
} from '@constants';
import { extract } from '@utils';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector, subscriptionGroupsSelector } from '@store/selectors';
import get from 'lodash.get';

const createPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

const useSystemEntityOptions = (path, filter) => {
  const fieldOptions = useSelector(segmentsOptionsSelector);
  const subscribeGroups = useSelector(subscriptionGroupsSelector);

  const systemAutocompletes = useMemo(() => ({
    '_events': {
      'ri': {
        '_events': {
          '_e': {
            '_rfm_sd': (fieldOptions.fields?.rfmResources || []).map(({ name, id }) => ({ value: id, label: name })),
          },
        },
      },
      'rs': {
        '_events': {
          '_e': {
            '_rfm_sd': RFMColorsConfig.map(({ name }) => ({ value: name, label: name })),
          },
        },
      },
      'rps': {
        '_events': {
          '_e': {
            '_rfm_sd': RFMColorsConfig.map(({ name }) => ({ value: name, label: name })),
          },
        },
      },
      '_sgids': {
        '_events': {
          '_e': {
            '_em': subscribeGroups?.data?.map?.(({ id, name }) => ({ value: id, label: name })),
          },
        },
      },
      '_s': {
        '_events': {
          '_e': {
            '_em': EmailStatusOptions.filter(extract('value')),
            '_sm': SmsStatusOptions.filter(extract('value')),
            '_wp': WebpushStatusOptions.filter(extract('value')),
            '_vb': ViberStatusOptions.filter(extract('value')),
            '_mp': MobilePushStatusOptions.filter(extract('value')),
          }
        }
      },
      '_te': {
        '_events': {
          '_e': {
            '_em': fieldOptions?.fields?.email?.map?.(({ field, label }) => ({ value: field, label })),
            '_sm': fieldOptions?.fields?.sms?.map?.(({ field, label }) => ({ value: field, label })),
            '_wp': fieldOptions?.fields?.web_push?.map?.(({ field, label }) => ({ value: field, label })),
            '_vb': fieldOptions?.fields?.viber?.map?.(({ field, label }) => ({ value: field, label })),
            '_mp': fieldOptions?.fields?.mobile_push?.map?.(({ field, label }) => ({ value: field, label })),
          }
        }
      },
    },
    '_sg': {
      '_i': subscribeGroups?.data?.map?.(({ id, name }) => ({ value: id, label: name })),
    }
  }), [fieldOptions?.fields, subscribeGroups?.data]);

  return get(systemAutocompletes, createPath(path, filter)) || [];
};

export default useSystemEntityOptions;
