import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  border-bottom: 1px dashed #109DFB;
  width: fit-content;
  font-size: 13px;
  line-height: 120%;
  font-family: Rubik-Regular;

  ${styledProp('isTitle', `
     color: #909399;
     border-bottom: none;
  `)}
`;


