import styled from 'styled-components';

import { Colors } from '@res/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.div`
  margin-right: 6px;
  font-size: 10px;
  color: ${Colors.Text.SECONDARY};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
  max-height: 50vh;
  overflow-y: auto;
`;

export const Option = styled.div`
  font-size: 13px;
  padding: 7px 24px;
  cursor: pointer;
  transition: 280ms ease all;

  &:hover {
    background: rgba(208, 207, 205, 0.43);
  }
`;
