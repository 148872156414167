import React from 'react';

import { Container, StyledTextarea } from './styled.js';
import { Title, TitleRow } from '@components/inputs/Input/styled';

const Textarea = ({ title, disabled, value, onChange, autoResizable, ...props }) => {
  return (
    <Container>
      {title && (
        <TitleRow>
          <Title>{title}</Title>
        </TitleRow>
      )}
      <StyledTextarea autoResizable={autoResizable} value={value} onChange={onChange} disabled={disabled} {...props} />
    </Container>
  );
}

export default Textarea;


