import styled from 'styled-components';
import * as Colors from '@res/theme/colors';
import { styledProp } from '@utils';

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  transition: 280ms ease all;
  position: absolute;
  bottom: 0;
  
  ${styledProp('$opened', `
    background: #1E386A;
    & * {
      color: #fff !important;
    }
  `)}
`;

export const Container = styled.div`
  width: 220px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  position: relative;
  outline: none;
  border-bottom: none;
  text-decoration: none;
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 220px;
  white-space: nowrap;
  cursor: pointer;

  transition: 280ms ease all;

  &:hover {
    background-color: ${Colors.Sidebar.HOVERED_BG};
  }

  ${styledProp('$active', `
    background-color: ${Colors.Sidebar.HOVERED_BG};
  `)}

  ${styledProp('$hovered', `
    background-color: ${Colors.Sidebar.HOVERED_BG};
  `)}

  ${styledProp('$opened', `
    background-color: ${Colors.Sidebar.HOVERED_BG};
    border-top: 1px solid #385A9B;
  `)}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${({ active }) => active ? Colors.Sidebar.TEXT_CONTRAST : Colors.Sidebar.TEXT_PRIMARY};
  &:hover {
    color: ${Colors.Sidebar.TEXT_CONTRAST};
    & svg {
      stroke: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  }
  transition: 230ms all ease;
  display: flex;
  align-items: center;
  line-height: 14px;
  white-space: nowrap;
  font-size: 14px;
  height: 26px;
`;

export const ActiveIndicator = styled.div`
  opacity: ${({ active }) => Number(active)};
  transition: 300ms ease all;
  
  position: absolute;
  background: ${Colors.Sidebar.ICON_ACTIVE};
  top: 0;
  left: 0;
  width: 2px;
  height: 26px;
  border-radius: 0 2px 2px 0;
`;

export const Accordion = styled.div`
  transition: 300ms ease all;
  display: flex;
  flex-direction: column;
  max-height: ${({ $opened }) => $opened ? '800px' : '0px'};
  padding: ${({ $opened }) => $opened ? '15px 0px' : '0'};
  overflow: hidden;
`;

export const AccordionRow = styled.div`
  padding: 10px 25px;
  color: #909399;
  cursor: pointer;
  text-decoration: none;
  display: block;
  
  ${styledProp('$active', `
   background: #10254E;
  `)}
  
  &:active {
    color: #909399;
    text-decoration: none;
  }
  &:hover {
    background: #10254E;
    color: white;
  }
  
  transition: 280ms ease all;
  opacity: 0;
  
  ${styledProp('$accordionOpened', `
    opacity: 1;
  `)}
`;
