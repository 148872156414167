import React from 'react';
import { Link } from '@components';
import { useDispatch } from 'react-redux';
import { savePromoCode } from '@store/actions/creators';
import { useErrors, useModalState, usePermissions } from '@hooks';

import { EditPromoCodeModal } from '../EditPromoCodeModal';
import { Container } from './styled';
import { sequence } from '@utils';
import { UserPermissions } from '@constants';

const TitleCell = ({ title, onEditSuccess, ...promoCode }) => {
  const dispatch = useDispatch();
  const editModal = useModalState(false);
  const permissions = usePermissions();
  const { errors, handleRequestError, clear, clearError, setErrors } = useErrors();

  const handleSubmit = (fields, onSuccess) => {
    dispatch(savePromoCode({
      id: promoCode.id,
      fields,
      onSuccess: sequence(editModal.close, onEditSuccess, onSuccess),
      onError: handleRequestError
    }));
  };

  const handleOpenModal = () => {
    if (permissions.allowed(UserPermissions.UPDATE_PROMO_CODE)) {
      editModal.open();
    }
  }

  return (
    <Container>
      <Link to="#" onClick={handleOpenModal}>{title}</Link>
      <EditPromoCodeModal
        onClearError={clearError}
        onClearErrors={clear}
        errors={errors}
        onSetErrors={setErrors}
        opened={editModal.opened}
        onClose={editModal.close}
        onSubmit={handleSubmit}
        values={{ ...promoCode, name: title }}
      />
    </Container>
  );
};

export default TitleCell;
