import React from 'react';

import { Container } from './styled.js';
import { by } from '@utils';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';
import { useSelector } from 'react-redux';
import { webpushNodeAnalyticsSelector } from '@store/selectors';
import { useTranslation } from '@hooks';

const WebpushNodeSettingsInfo = ({ node, options }) => {
  const webpush = options?.['web_push']?.find?.(by(node.data?.webpush_id));
  const webpushAnalytics = useSelector(webpushNodeAnalyticsSelector);
  const { t } = useTranslation();

  if (!webpush) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('template')}:
        </InfoTitle>
        <InfoText>
          {webpush.name}
        </InfoText>
      </InfoRow>
      {(node.data.sending && !webpushAnalytics.loading) && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default WebpushNodeSettingsInfo;


