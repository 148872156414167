import {ExpressionTypes} from '@store/actions/types';
import TreeModel from 'tree-model'
import { v4 as uuidv4 } from 'uuid';

import {
    ZERO_NODE_FUNCTIONS,
    ONE_NODE_FUNCTIONS,
    TWO_NODES_FUNCTIONS,
    THREE_NODE_FUNCTIONS,
    NODE_TYPE_FUNCTION,
} from '../../pages/HomeScene/pages/ExpressionPage/constants';

const initialState = {
    name: null,
    expression: {
        id: uuidv4(),
        type: null,
        value: null
    },
    for: {
        type: 'linear',
        value: null,
        logicalOperator: 'and',
        filters: [],
    },
    selected: {
        id:null,
        type: null,
        value:null
    }
};


const tree = new TreeModel()

const nodesBasedOnFunction = (functionName) => {

    if (ZERO_NODE_FUNCTIONS.includes(functionName)) {
        return [];
    }

    if(ONE_NODE_FUNCTIONS.includes(functionName)) {
        return [
            {
                id: uuidv4(),
                type:null,
                value:null
            }
        ];
    }

    if(TWO_NODES_FUNCTIONS.includes(functionName)) {
        return [
            {
                id: uuidv4(),
                type:null,
                value:null
            },
            {
                id: uuidv4(),
                type:null,
                value:null
            }
        ];
    }

    if(THREE_NODE_FUNCTIONS.includes(functionName)) {

        return [
            {
                id: uuidv4(),
                type:null,
                value:null
            },
            {
                id: uuidv4(),
                type:null,
                value:null
            },
            {
                id: uuidv4(),
                type:null,
                value:null
            }
        ]

    }

    return [];
        // DYNAMIC (ARRAY VALUES)
        // case MIN, MAX, MOD,CLAMP,
};

const expressionReducer = (state = initialState, action ) => {
  switch (action.type) {
      case ExpressionTypes.EXPRESSION_NAME_CHANGED: {
          return {
              ...state,
              name: action.payload.value
          }
      }
      case ExpressionTypes.EXPRESSION_TYPE_SELECTED: {

          let root = tree.parse({...state.expression})

          const node = root.first((item) => {
              return item.model.id === action.payload.id
          })

          if(node === undefined) {
              return {...state};
          }

          node.model.type =  action.payload.value;
          node.model.is_root =  node.isRoot();


          return {
              ...state,
              expression: {...root.model},
              selected: {
                  type: action.payload.value,
                  value: null,
                  id: action.payload.id
              }
          }
      }
      case ExpressionTypes.EXPRESSION_VALUE_SELECTED: {

          const root = tree.parse({...state.expression})

          const node = root.first((item) => {
              return item.model.id === action.payload.id
          })

          if(node === undefined) {
              return {...state};
          }

          node.model.value =  action.payload.value;

          const childrenCountEqual = node.model.children !== undefined && nodesBasedOnFunction(action.payload.value).length === node.model.children.length

          if(!childrenCountEqual) {
              delete node.model.children
          }

          if(action.payload.type === NODE_TYPE_FUNCTION && !childrenCountEqual) {

              nodesBasedOnFunction(action.payload.value).forEach((childItem) => {
                  node.addChild(tree.parse(childItem))
              });

          }

          return {
              ...state,
              expression: {...root.model},
              selected: {...state.selected, value: null}
          }
      }
      case ExpressionTypes.EXPRESSION_CLICKED: {

          return {
              ...state,
              selected: action.payload
          }
      }
      case ExpressionTypes.EXPRESSION_RESET_TYPE: {

          const root = tree.parse({...state.expression})

          const node = root.first((item) => {
              return item.model.id === action.payload.id
          })

          if(node === undefined) {
              return {...state};
          }

          node.model.type = null
          node.model.value = null

          if(node.model.children !== undefined) {
              delete node.model.children
          }

          return {
              ...state,
              expression: {...root.model},
              selected: {
                  id:null,
                  type:null,
                  value:null
              }
          }
      }
      case ExpressionTypes.EXPRESSION_FUNCTION_ADDED: {

          const root = tree.parse({...state.expression})

          let node = root.first((item) => {
              return item.model.id === action.payload.id
          })

          let oldData = {...node.model}
          oldData.is_root = false

          node.model.id = uuidv4()
          node.model.type = NODE_TYPE_FUNCTION
          node.model.value = action.payload.value
          if(node.model.children !== undefined) {
              delete node.model.children
          }

          node.addChild(tree.parse(oldData))

          nodesBasedOnFunction(action.payload.value).forEach((childItem, index) => {
              if(index === 0) {return}
              node.addChild(tree.parse(childItem))
          });

          return {
              ...state,
              expression: {...root.model},
              selected: {
                  type: null,
                  value: null,
                  id: null
              }
          }
      }
      case ExpressionTypes.EXPRESSION_FOR_TYPE_SELECTED: {
          return {
              ...state,
              expression: {
                  id: uuidv4(),
                  type: null,
                  value: null
              },
              for: {
                  ...state.for,
                  type: action.payload.value,
                  value: null
              },
              selected: {
                  id:null,
                  type: null,
                  value:null
              }
          }
      }
      case ExpressionTypes.EXPRESSION_FOR_VALUE_SELECTED: {
          return {
              ...state,
              for: {
                  ...state.for,
                  value: action.payload.value
              }
          }
      }
      default: return state;
  }
};

export default expressionReducer;
