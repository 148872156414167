import React from 'react';
import {
  Container,
  Value
} from './styled';

const CustomFilterPanel = ({ options, type, onSelect, hideArrays, hidePreview }) => {

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-array-tab-content'}>
      {options?.filter(({ type }) => !hideArrays || type !== 'array')?.map(option => (
        <Value
          data-testid={`store-segment-filter-panel-custom-field-${option.value}`}
          onClick={() => onSelect(option, type)}
        >
          {option.label}
        </Value>
      ))}
    </Container>
  );
};

export default CustomFilterPanel;
