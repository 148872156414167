import styled, { css } from 'styled-components';
import { styledProp } from '@utils';
import { DatePicker } from 'antd';
import { Icon } from '@components';
const { RangePicker: ANTDRangePicker } = DatePicker;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  width: 36px;
  height: 36px;
  //width: 26px;
  //height: 26px;
  //
  @media screen and (max-width: 1024px) {
    width: 26px;
    height: 26px;
  }

  ${styledProp('smaller', css`
    width: 26px !important;
    height: 26px !important;
  `)}
  
  background: linear-gradient(180deg,#DCDFE6 0%,#CDD3DF 100%);
  border-radius: 9px 0px 0px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${styledProp('inverted', css`
    transform: rotate(180deg);
    background: linear-gradient(0deg,#DCDFE6 0%,#CDD3DF 100%);
  `)}
`;

export const PickerContainer = styled.div`
  background: linear-gradient(180deg,#DCDFE6 0%,#CDD3DF 100%);
  margin: 0 2px !important;
`;

export const Overlay = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 16px
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #F0F2F6;
  background: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px 20px 5px 0;
  //box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 16px
`;

export const StyledRangePicker = styled(ANTDRangePicker)`
  height: 36px !important;
  font-size: 14px !important;
  //height: 26px !important;
  //font-size: 12px !important;
  //
  @media screen and (max-width: 1024px) {
    height: 26px !important;
    font-size: 12px !important;
  }

  ${styledProp('smaller', css`
    height: 26px !important;
    font-size: 12px !important;
  `)}
  
  border-radius: 0px !important;
  & input {
    font-size: 14px !important;
    @media screen and (max-width: 1024px) {
      font-size: 12px !important;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  height: 36px !important;
  font-size: 14px !important;
  
  //height: 26px !important;
  //font-size: 12px !important;
  
  @media screen and (max-width: 1024px) {
    height: 26px !important;
    font-size: 12px !important;
  }
  
  ${styledProp('smaller', css`
    height: 26px !important;
    font-size: 12px !important;
  `)}

  border-radius: 0px !important;
  & input {
    font-size: 14px !important;
    @media screen and (max-width: 1024px) {
      font-size: 12px !important;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  &:focus-within {
    display: none;
  }
`
