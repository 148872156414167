import React from 'react';

export default (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1C0 0.447715 0.447715 0 1 0H23.7266C24.2788 0 24.7266 0.447715 24.7266 1V23.7266C24.7266 24.2788 24.2788 24.7266 23.7266 24.7266H1C0.447715 24.7266 0 24.2788 0 23.7266V1Z" fill="#F7F8FB"/>
    <path d="M35.2734 36.2734C35.2734 35.7212 35.7212 35.2734 36.2734 35.2734H59C59.5523 35.2734 60 35.7212 60 36.2734V59C60 59.5523 59.5523 60 59 60H36.2734C35.7212 60 35.2734 59.5523 35.2734 59V36.2734Z" fill="#F7F8FB"/>
    <path d="M31.7578 12.3633C31.7578 11.7691 31.7917 11.1829 31.8557 10.6055H28.2422V14.1211H31.8557C31.7917 13.5437 31.7578 12.9574 31.7578 12.3633Z" fill="#F7F8FB"/>
    <path d="M31.7578 48.6328C31.7578 48.0387 31.7917 47.4524 31.8557 46.875H28.2422V50.3906H31.8557C31.7917 49.8132 31.7578 49.227 31.7578 48.6328Z" fill="#F7F8FB"/>
    <path d="M14.1211 31.8555V28.2422H10.6055V31.8555C11.1829 31.7917 11.7691 31.7578 12.3633 31.7578C12.9574 31.7578 13.5437 31.7917 14.1211 31.8555Z" fill="#F7F8FB"/>
    <path d="M45.8789 28.1445V31.7579H49.3945V28.1445C48.8171 28.2084 48.2309 28.2423 47.6367 28.2423C47.0426 28.2423 46.4563 28.2084 45.8789 28.1445Z" fill="#F7F8FB"/>
    <path d="M12.3633 60C19.1913 60 24.7266 54.4648 24.7266 47.6367C24.7266 40.8087 19.1913 35.2734 12.3633 35.2734C5.53523 35.2734 0 40.8087 0 47.6367C0 54.4648 5.53523 60 12.3633 60Z" fill="#F7F8FB"/>
    <path d="M47.6367 24.7266C54.4648 24.7266 60 19.1913 60 12.3633C60 5.53523 54.4648 0 47.6367 0C40.8087 0 35.2734 5.53523 35.2734 12.3633C35.2734 19.1913 40.8087 24.7266 47.6367 24.7266Z" fill="#F7F8FB"/>
  </svg>
);
