import React, { useEffect, useMemo, useRef } from 'react';
import {
  SendingChartContainer,
  SendingContainer,
  SendingItemsContainer,
  SendingSection,
  SendingTitle
} from "../../styled";
import {BaseActivityLoader} from "../BaseActivitySection/components";
import {EmailSendingInfo} from "../EmailSendingInfo";
import {SmsSendingInfo} from "../SmsSendingInfo";
import {ViberSendingInfo} from "../ViberSendingInfo";
import {WebpushSendingInfo} from "../WebpushSendingInfo";
import {MobilePushSendingInfo} from "../MobilePushSendingInfo";
import {SendingChart} from "../SendingChart";
import {useDispatch, useSelector} from "react-redux";
import {
  dashboardEmailSummarySelector, dashboardMobilePushSummarySelector,
  dashboardSmsSummarySelector, dashboardSummaryLoadedSelector,
  dashboardViberSummarySelector, dashboardWebpushSummarySelector
} from "@store/selectors/dashboard";
import {identity, moment, testId} from "@utils";
import {defaultRanges} from "@constants";
import { useTranslation } from "@hooks";
import {dashboardAnalyticsOptionsSelector, userSelector} from "@store/selectors";
import {
  getDashboardEmailSummary,
  getDashboardMobilePushSummary,
  getDashboardSmsSummary,
  getDashboardViberSummary, getDashboardWebpushSummary
} from "@store/actions/creators";

const roughCompareDate = (d1, d2) => {
  return moment(d1).format('YYYY-MM-DD') === moment(d2).format('YYYY-MM-DD');
};

const resolveRangeFromOptions = (options) => {
  if (Array.isArray(options?.range)) {
    return (options.range || defaultRanges['labels.today']).map(d => moment(d));
  }

  if (options?.range?.absolute) {
    return options.range.absolute.map(d => moment(d));
  }

  if (options?.range?.relative) {
    switch (options?.range.relate) {
      case 'today':
      default:
        return defaultRanges['labels.today'];
    }
  }

  return (options?.range || defaultRanges['labels.today']).map(d => moment(d));
}

const TemplateSendingSection = () => {
  const { p, t } = useTranslation('dashboard_page');
  const smsSummary = useSelector(dashboardSmsSummarySelector);
  const viberSummaryData = useSelector(dashboardViberSummarySelector);
  const emailSummary = useSelector(dashboardEmailSummarySelector);
  const user = useSelector(userSelector);
  const webpushSummary = useSelector(dashboardWebpushSummarySelector);
  const mobilePushSummary = useSelector(dashboardMobilePushSummarySelector);
  const analyticsOptions = useSelector(dashboardAnalyticsOptionsSelector);
  const summaryLoaded = useSelector(dashboardSummaryLoadedSelector);

  const dispatch = useDispatch();

  const viberSummary = useMemo(() => {
    return {
      total: viberSummaryData?.total || 0,
      click: viberSummaryData?.data?.find(d => d.status === 'click')?.count || 0,
    }
  }, [viberSummaryData?.total, viberSummaryData?.data])

  const shouldRender = useMemo(() => {
    return !!(emailSummary?.total || smsSummary?.total || webpushSummary?.total || viberSummary?.total);
  }, [emailSummary?.total, smsSummary?.total, webpushSummary?.total, viberSummary?.total]);

  const range = useMemo(() => {
    return resolveRangeFromOptions(analyticsOptions);
  }, [analyticsOptions])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!range.every(d => !!d) || !user?.app?.id) {
      return;
    }

    dispatch(getDashboardSmsSummary({ range }));
    dispatch(getDashboardEmailSummary({ range }));
    dispatch(getDashboardViberSummary({ range }));
    dispatch(getDashboardMobilePushSummary({ range }))
    dispatch(getDashboardWebpushSummary({ range }));
  }, [(range || []).map(d => moment(d).toISOString()).join('.'), user?.app?.id]);

  const resolveRangeTitle = ([r11, r12], t = identity) => {
    return t(Object.entries(defaultRanges).find(([_, [r21, r22]]) => roughCompareDate(r11, r21) && roughCompareDate(r12, r22))?.[0])
      || `${moment(r11).format('YYYY-MM-DD')} - ${moment(r12).format('YYYY-MM-DD')}`;
  };

  const sendingData = useMemo(() => [
    {
      name: 'emails',
      value: emailSummary?.click / emailSummary?.total * 100,
      background: 'linear-gradient(213.29deg, #6EDEAD 11.15%, #3BCD9A 90.5%)',
      color: '#24993E',
    },
    {
      name: 'sms',
      value: smsSummary?.click / smsSummary?.total * 100,
      background: 'linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%)',
      color: '#17A8FD',
    },
    {
      name: 'viber',
      value: viberSummary?.click / viberSummary?.total * 100,
      background: '#7C529C',
      color: '#7C529C',
    },
    {
      name: 'web_push',
      value: webpushSummary?.click / webpushSummary?.total * 100,
      background: 'linear-gradient(213.29deg, #F4A53C 11.15%, #ED992A 90.5%)',
      color: '#ED992A',
    },
    {
      name: 'mobile_push',
      value: mobilePushSummary?.click / mobilePushSummary?.total * 100,
      background: '#FFD35F',
      color: '#FFD35F',
    },
  ], [mobilePushSummary?.click, mobilePushSummary?.total, webpushSummary?.click, webpushSummary?.total, viberSummary?.click, viberSummary?.total, smsSummary?.click, smsSummary?.total, emailSummary?.click, emailSummary?.total]);

  const tid = testId('dashboard');

  return shouldRender ? (
    <SendingSection data-testid="dashboard-sending-section">
      <SendingTitle>
        {p('sending')} {resolveRangeTitle(range, t).toLowerCase()}
      </SendingTitle>
      <SendingContainer>
        <BaseActivityLoader {...tid(`sending-loader`)} active={!summaryLoaded} />
        <SendingItemsContainer>
          <EmailSendingInfo />
          <SmsSendingInfo/>
          <ViberSendingInfo />
          <WebpushSendingInfo />
          <MobilePushSendingInfo />
        </SendingItemsContainer>
        <SendingChartContainer>
          <SendingChart data={sendingData} />
        </SendingChartContainer>
      </SendingContainer>
    </SendingSection>
  ) : null;
}

export default React.memo(TemplateSendingSection);
