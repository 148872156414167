import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #109DFB;
  border-radius: 7px;
  padding: 6px 0;
  font-family: Rubik-Regular;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #109DFB;
  user-select: none;
  cursor: pointer;
  
  ${styledProp('$d', css`
    opacity: .5;
  `)}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  padding: 10px 0;
`;

export const Option = styled.div`
  padding: 6px 14px;
  cursor: pointer;
`;

export const NoFilters = styled.div`
  font-family: Rubik-Regular;
  color: #CBCBCC;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
`;
