import React from 'react';
import { ActionButton, TableActionsBar } from '@components';
import { Container } from './styled';
import { useTranslation } from '@hooks';

const SmsActions = ({ onViberInfoClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TableActionsBar
        actionButtons={(
          <ActionButton testId="viber-action-show-preview" tooltip={t('field_settings.show_preview')} icon={'Show-icon'} iconSize={14} onClick={onViberInfoClick} />
        )}
      />
    </Container>
  )
}

export default SmsActions;
