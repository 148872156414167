import React, { useCallback, useState } from 'react';
import WorkflowDiffContext from './WorkflowDiffContext';
import { isNode } from 'reactflow';

const WorkflowDiffProvider = ({ children, invertDiff }) => {
  const [nodes, setNodes] = useState([]);
  const [instance, setInstance] = useState(null);
  const [openedNodeDiff, setOpenedNodeDiff] = useState('');

  const handleLoad = useCallback((rfi) => {
    setInstance(rfi);
  }, [setInstance]);

  return (
    <WorkflowDiffContext.Provider
      value={{
        setNodes,
        nodes,
        invertDiff,
        instance,
        onLoad: handleLoad,
        openedNodeDiff,
        setOpenedNodeDiff
      }}
    >
      {children}
    </WorkflowDiffContext.Provider>
  )
};

export default WorkflowDiffProvider;
