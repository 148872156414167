import React from 'react';

import { Container } from './styled.js';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { by } from '@utils';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { resolveEventName } from '@utils/events';
import { useTranslation } from '@hooks';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

const EventNodeSettingsInfo = ({ node: outerNode, options }) => {
  const nodes = (createMultinodeUpdater(outerNode.data)?.values || []).map(v => ({ data: v }));
  const { t } = useTranslation();

  if (!nodes?.length || !nodes?.[0]?.data?.event_id) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const renderNode = (node, index) => {
    if (!node.data?.event_id) {
      return null;
    }

    const event = options?.events?.find?.(by(node.data?.event_id));

    const analytics = outerNode.data?.analytics?.clones?.find?.((data) => data?.node === node.data?.id);

    return (
      <>
        <InfoRow style={{ marginTop: index > 0 ? 18 : 0 }}>
          <InfoTitle>
            {t('labels.event') + ' ' + (index + 1)}:
          </InfoTitle>
          <InfoText>
            {resolveEventName(event?.name)}
          </InfoText>
        </InfoRow>
        {!!node.data?.filter_builder?.filters?.length && (
          <InfoRow>
            <InfoTitle>
              {t('labels.filters_count')}:
            </InfoTitle>
            <InfoText>
              {node.data?.filter_builder?.filters?.length}
            </InfoText>
          </InfoRow>
        )}
        {analytics && <AnalyticsSection analytics={analytics}/>}
      </>
    );
  }

  return (
    <Container>
      {nodes.map(renderNode)}
    </Container>
  );
}

export default EventNodeSettingsInfo;


