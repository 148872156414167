import React from 'react';
import { Button, Modal } from '@components';

import { Container, InfoContainer, InfoText, InfoTextTitle } from './styled';
import { Paths } from '@constants';
import { InfoRow } from '../InfoRow';
import { UserPermissions } from '../../../../../../constants';
import { useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import { templateSelector } from '@store/selectors';

const createSmsDetails = (data) => {
  const { template_id, customer_id, number, message, created_at, updated_at, status, group, entity_id, external_id, integration, response, meta, sms_name, _id } = data;
  return [
    { attribute: 'labels.sms_id', value: template_id },
    { attribute: 'labels.customer_id', value: customer_id, link: `${Paths.CONTACT}/${customer_id}`, permission: UserPermissions.REVIEW_CONTACTS },
    { attribute: 'labels.phone', value: number },
    { attribute: 'labels.message', value: message },
    { attribute: 'labels.template', value: sms_name, link: `${Paths.TEMPLATES_SMS}/${template_id}`, permission: UserPermissions.REVIEW_SMS },
    { attribute: 'labels.send_date', value: created_at, date: true },
    { attribute: 'labels.updated_at', value: updated_at, date: true },
    { attribute: 'labels.status', value: status, status: true },
    { attribute: 'labels.group', value: group },
    { attribute: 'labels.entity_id', value: entity_id, link: true },
    { attribute: 'labels.external_id', value: external_id, link: true },
    { attribute: 'labels.integration', value: integration },
    { attribute: 'labels.response', value: response },
    { attribute: 'labels.meta', value: meta },
  ]
};

const SmsInfoModal = ({ opened, onClose, data }) => {
  const modalInfo = createSmsDetails(data);
  const templates = useSelector(templateSelector);
  const { t, p } = useTranslation('notification_history')

  return (
    <Modal
      opened={opened}
      contentStyles={{  maxHeight: '60vh' }}
      onClose={onClose}
      bigger
      title={`Sms ${templates?.sms?.find(s => s.id === data.template_id)?.name}`}
      centerFooter={true}
      actions={(
        <>
          <Button onClick={onClose} appearance="secondary" width={160}>{t('actions.close')}</Button>
        </>
      )}
    >
      <Container>
        <InfoContainer>
          <InfoTextTitle first={true}>{t('labels.attribute')}</InfoTextTitle>
          <InfoText first={true}>{t('labels.value')}</InfoText>
        </InfoContainer>
        { modalInfo.map((props) =>  <InfoRow {...props} key={props.attribute} />)}
      </Container>
    </Modal>
  );
};

export default SmsInfoModal;
