import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  min-width: 271px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
`;

export const Header = styled.div`
  background: #F9FBFF;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  height: 62px;
  //padding: 15px 25px 13px 14px;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid rgba(189, 203, 250, 1);
  padding: 14px;
`;

export const TabOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px 38px;
  position: relative;
`;

export const TabOption = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  
  ${styledProp('selected', css`
    color: #109DFB;
    text-decoration-line: underline;
  `)}
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  margin-top: 2px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 120%;

  ${styledProp('selected', css`
    color: #109DFB;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #109DFB;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ $tab: tab }) => {
    return css`
      width: ${tab?.getBoundingClientRect()?.width}px;
      left: ${tab?.offsetLeft}px;
    `;
  }}
`;

export const Content = styled.div`
  display: flex;
`;

export const Title = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;

export const Item = styled.div`
  margin-top: 15px;
  cursor: pointer;
  font-size: 13px;
  line-height: 120%;
`;

export const DynamicColumn = styled.div`
  border-right: 1px solid #DCDFE6;
  padding: 18px 20px;
  width: 50%;
  max-height: 212px;
  min-width: 168px;
  overflow-y: scroll;
`;

export const CommonColumn = styled.div`
  width: 50%;
  padding: 18px 20px;
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;
