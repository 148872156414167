import React from 'react';
import { diffFields } from '@utils';
import { NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';
import { ActionButton, Input, Multiselect, SearchSelect, Textarea } from '@components';

import { Container, FieldCol, AddButton, RemoveFieldButton, FieldRow, Error, SettingsContainer } from './styled';
import { useTranslation } from '@hooks';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';

const resolveValue = v => {
  if (typeof v === 'boolean') {
    return String(v);
  }

  return v;
};

const CustomerChangeNodeSettings = ({
  value,
  onChange,
  label,
  description,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t, p } = useTranslation('workflow_page');
  const segmentOptions = useSelector(segmentsOptionsSelector);
  const fieldsOptions = segmentOptions.fields?.attribute?.map(({ label, field }) => ({ value: field, label }));
  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const handleAddField = () => {
    const newField = { field: '', value: '' };
    onClearError('field_values');
    onChange(s => ({ ...s, field_values: s.field_values ? [...s.field_values, newField] : [newField] }));
  };

  const handleRemoveField = (index) => () => {
    onClearError(`field_values_${index}`);
    onChange(s => ({ ...s, field_values: s.field_values.filter((_, i) => i !== index)}));
  };

  const handleFieldChange = (index) => (field) => {
    onClearError(`field_values_${index}`);
    onChange(s => ({
      ...s,
      field_values: s.field_values.map((f, i) => i !== index ? f : {
        ...f,
        field,
      }),
    }));
  };

  const handleValueChange = (index) => ({ target: { value } }) => {
    onClearError(`field_values_${index}`);
    onChange(s => ({
      ...s,
      field_values: s.field_values.map((f, i) => i !== index ? f : {
        ...f,
        value,
      }),
    }));
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <SettingsContainer className="show-scrollbar">
            {(value.field_values || []).map(({ field, value }, index) => (
              <FieldRow>
                <FieldCol>
                    <SearchSelect
                      highlight={diff['field_id'] === false}
                      disabled={!editable}
                      style={{ width: '100%', marginBottom: '16px' }}
                      value={field}
                      error={errors[`field_values_${index}`]}
                      placeholder={t('actions.select')}
                      getPopupContainer={t => t.parentElement.parentElement.parentElement}
                      onChange={handleFieldChange(index)}
                      options={fieldsOptions || []}
                      title={t('labels.field')}
                    />
                    <Input
                      value={resolveValue(value)}
                      error={errors[`field_values_${index}` && ' ']}
                      onChange={handleValueChange(index)}
                      title={t('labels.value')}
                    />
                </FieldCol>
                <RemoveFieldButton>
                  <ActionButton
                    appearance="danger"
                    icon="Delete-icon"
                    disabled={!editable}
                    onClick={handleRemoveField(index)}
                    tooltip={p('remove_filter')}
                  />
                </RemoveFieldButton>
              </FieldRow>
            ))}
            <AddButton onClick={handleAddField}>
              <ActionButton size={22} icon="Plus-icon" style={{ marginRight: 12 }} />
              {p('add_attribute')}
            </AddButton>
            {errors['field_values'] && <Error>{errors['field_values']}</Error>}
          </SettingsContainer>
        )}
        descriptionSettings={(
          <>
            <Input
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        additionalSettings={(
          <Multiselect
            highlight={diff['meta'] === false}
            disabled={!editable}
            title={t('labels.message_fields')}
            getParentElement={t => t.parentElement.parentElement.parentElement.parentElement.parentElement}
            style={{ width: '100%' }}
            options={fieldsOptions}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
};

export default CustomerChangeNodeSettings;
