// MATH

export const ABS = 'abs';
export const CEIL = 'ceil';
export const EXP = 'exp';
export const POW = 'pow';
export const SQRT = 'sqrt';
export const FLOOR = 'floor';
export const MAX = 'max';
export const MIN = 'min';
export const MOD = 'mod';
export const ROUND = 'round';
export const LOG = 'log';
export const LOG1P = 'log1p';
export const LOG2 = 'log2';
export const LOG10 = 'log10';
export const CLAMP = 'clamp';
export const ADD = 'add';
export const DIVIDE = 'divide';
export const MULTIPLY = 'multiply';
export const SUBTRACT = 'subtract';
export const TO_FIXED = 'to_fixed';

// LOGICAL


export const COND = 'cond';
export const EQUAL = 'equal';
export const NOT_EQUAL = 'not_equal';
export const GREATER = 'greater';
export const LESS = 'less';
export const GREATER_OR_EQUAL = 'greater_or_equal';
export const LESS_OR_EQUAL = 'less_or_equal';
export const IF_NULL = 'if_null';

    // STRING

export const CONCAT = 'concat';
export const TO_LOWER_CASE = 'to_lower_case';
export const TO_UPPER_CASE = 'to_upper_case';


    // CAST

export const TO_NUMBER = 'to_number';
export const TO_STRING = 'to_string';
export const TO_TIMESTAMP = 'to_timestamp';

    // DATE

export const ADD_DAY = 'add_day';
export const ADD_YEAR = 'add_year';
export const ADD_WEEK = 'add_week';
export const ADD_MONTH = 'add_month';
export const ADD_HOUR = 'add_hour';
export const WEEK_OF_YEAR = 'week_of_year';
export const WEEK_OF_MONTH = 'week_of_month';
export const MONTH = 'month';
export const DAY_OF_MONTH = 'day_of_month';
export const DAY_OF_WEEK = 'day_of_week';
export const DAY_OF_YEAR = 'day_of_year';
export const NOW = 'now';

export const MATH_FUNCTIONS = [
  ABS,
    CEIL,
    EXP,
    POW,
    SQRT,
    FLOOR,
    MAX,
    MIN,
    MOD,
    ROUND,
    LOG,
    LOG1P,
    LOG2,
    LOG10,
    CLAMP,
    ADD,
    DIVIDE,
    MULTIPLY,
    SUBTRACT,
    TO_FIXED,
];

export const DATE_FUNCTIONS = [
    ADD_DAY,
    ADD_YEAR,
    ADD_WEEK,
    ADD_MONTH,
    ADD_HOUR,
    WEEK_OF_YEAR,
    WEEK_OF_MONTH,
    MONTH,
    DAY_OF_MONTH,
    DAY_OF_WEEK,
    DAY_OF_YEAR,
    NOW,
];

export const LOGICAL_FUNCTIONS = [
    COND,
    EQUAL,
    NOT_EQUAL,
    GREATER,
    LESS,
    GREATER_OR_EQUAL,
    LESS_OR_EQUAL,
    IF_NULL,
];

export const CAST_FUNCIONS = [
    TO_NUMBER,
    TO_STRING,
    TO_TIMESTAMP,
];

export const STRING_FUNCTIONS = [
    CONCAT,
    TO_LOWER_CASE,
    TO_UPPER_CASE,
];


export const FUNCTIONS = [
    ...MATH_FUNCTIONS,...LOGICAL_FUNCTIONS,...CAST_FUNCIONS,...STRING_FUNCTIONS,...DATE_FUNCTIONS
];

export const NODE_TYPE_FUNCTION = 'function';

export const NODE_TYPE_CONSTANT = 'constant';

export const NODE_TYPE_LINEAR = 'linear';

export const NODE_TYPE_ARRAY = 'array';

export const NODE_TYPE_EVENT = 'event';

export const ZERO_NODE_FUNCTIONS = [NOW]

export const ONE_NODE_FUNCTIONS = [TO_UPPER_CASE, TO_LOWER_CASE,EXP,LOG, LOG1P, LOG10, LOG2,ABS,CEIL,SQRT, FLOOR, ROUND, TO_NUMBER, TO_STRING, TO_TIMESTAMP, WEEK_OF_YEAR,  WEEK_OF_MONTH, DAY_OF_WEEK, DAY_OF_MONTH, DAY_OF_YEAR, MONTH]

export const TWO_NODES_FUNCTIONS = [EQUAL, NOT_EQUAL,GREATER, LESS, GREATER_OR_EQUAL, LESS_OR_EQUAL,IF_NULL, CONCAT,ADD, DIVIDE, MULTIPLY, SUBTRACT,TO_FIXED,POW, MIN, MAX, MOD,CLAMP,  ADD_DAY, ADD_MONTH, ADD_WEEK, ADD_YEAR, ADD_HOUR]

export const SEPARATED_FUNCTIONS = [MULTIPLY, ADD, SUBTRACT, DIVIDE, EQUAL, NOT_EQUAL, LESS_OR_EQUAL, LESS, GREATER, GREATER_OR_EQUAL]

export const THREE_NODE_FUNCTIONS = [COND]