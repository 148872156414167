import React, { useCallback, useEffect, useMemo, useState } from 'react';
import capitalize from 'lodash.capitalize';
import equal from 'lodash.isequal'
import { Button, Modal } from '@components';
import { useModalState, useTranslation } from '@hooks';
import {
  isSameRange,
  restoreDefaults,
  resolveSelectedDoubleQP,
  relativeToRange,
  rangeToRelative,
  pickRelative,
  QPOptions,
  moment,
  QPDoubleOptions,
  testId,
} from '@utils';

import {
  DateTimeDisplay,
  QuickPickDoubleSelect,
  QuickPickButton,
  RelativeRangeSettings,
  SpecificRangeSettings
} from './components';
import {
  Container,
  TabsContainer,
  IconContainer,
  Tab,
  TabIndicator,
  QuickPicksContainer,
  ActionsContainer,
  DisabledTab,
  Error,
  Title,
  TitleRow,
} from './styled';
import { Tooltip } from 'antd';
import { DateRange } from 'react-date-range';

const getTabs = (p) => [
  {
    name: 'absolute',
    label: p('absolute'),
  },
  {
    name: 'relative',
    label: p('relative'),
  },
  {
    name: 'specific',
    label: p('specific'),
  },
];

export const resolveTitle = (value, isLifetime, p) => {
  if (!value) {
    return isLifetime ? p('lifetime') : p('select_date');
  }

  if (value.condition_type === 'specific') {
    return p('specific');
  }

  if (value.condition_type === 'absolute') {
    return isLifetime ?
      value.absolute.map(d => moment(d).format('DD-MM-YY')).join('  -  ')
      : value.absolute.map(d => moment(d).format('DD-MM-YY [at] HH:mm:ss')).join('  -  ');
  }

  if (value.condition_type === 'relative') {
      const qp = Object.entries({ ...QPOptions }).find(([_, v]) => equal(v().relative, value.relative));

      if (qp) {
        return capitalize(qp[0]);
      }

      const doubleQp = Object.entries(QPDoubleOptions)
        .flatMap(([key, value]) => Object.entries(value)
          .map(([k, v]) => [`${p(key)} ${p(k)}`, v])
        ).find(([_, v]) => equal(v().relative, value.relative));

      if (doubleQp) {
        return doubleQp[0];
      }

      return `${value.relative.range.type} ${value.relative.range.quantity} ${value.relative.range.unit}${value.relative.gap.quantity ? `, ${value.relative.gap.type} ${value.relative.gap.quantity} ${value.relative.gap.unit}`: ''}`;
  }
};

const DateFilterPicker = ({
  value,
  onChange,
  onSetToLifetime,
  large,
  hiddenTabs = [],
  style,
  containerStyle = {},
  title,
  error,
  tooltipError,
  disabled = false,
  disabledTabs = []
}) => {
  const modal = useModalState();
  const { p, t, e } = useTranslation('date_picker');
  const [state, setState] = useState(restoreDefaults(value || {}));
  const doubleQuickPick = resolveSelectedDoubleQP(state.absolute);
  const [[leftQP, rightQP], setDoubleQP] = useState(doubleQuickPick || ['this', 'week']);

  const tabs = getTabs(p);

  useEffect(() => {
    setState(restoreDefaults(value || {}));
  }, [value?.condition_type]);

  useEffect(() => {
    const doubleQuickPick = resolveSelectedDoubleQP(state.absolute);

    if (!doubleQuickPick) {
      return;
    }

    setDoubleQP(doubleQuickPick);
  }, [state.absolute]);

  const tab = state.condition_type || 'absolute';

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector(`#date-picker-tabs-${tab}`)
      const indicator = document.querySelector('#date-picker-indicator');

      const rect = el?.getBoundingClientRect?.();
      const parent = el?.parentElement?.getBoundingClientRect?.();

      if (indicator) {
        indicator.style.width = `${rect?.width + 24}px`;
        indicator.style.left = `${rect?.left - parent?.left - 12}px`;
      }
    }, 1)
  }, [modal.opened, tab])

  const handleApply = () => {
    onChange(state);
    modal.close();
  };

  const handleCancel = () => {
    setState(restoreDefaults(value || {}));
    modal.close();
  };

  const handleSetToLifetime = () => {
    onSetToLifetime();
    modal.close();
  };

  const handleTabChange = (tab) => {
    setState(s => ({ ...s, condition_type: tab }));
  };

  const handleRangeChange = ([f, s], startOfPeriod = 'day') => {
    const left = f?.startOf?.(startOfPeriod);
    const right = s?.endOf?.(startOfPeriod);

    setState(s => ({
      ...s,
      absolute: [left, right],
      relative: rangeToRelative([left, right]),
    }));
  };

  const handleSelect = useCallback((r) => {
    const start = moment(r.selection.startDate).startOf('day');
    const end = moment(r.selection.endDate).endOf('day');

    setState(s => ({
      ...s,
      absolute: [start, end],
      relative: rangeToRelative([start, end]),
    }));
  }, []);

  const handleQP = (generate) => {
    const { relative, absolute  } = generate();
    setState(s => ({
      ...s,
      absolute,
      relative,
    }));
  }

  const handleRelativeSettingsChange = (settings) => {
    setState(s => ({
      ...s,
      absolute: relativeToRange(settings),
      relative: settings,
    }));
  };

  const handleDoubleQuickPickLeft = (name) => {
    const right = Object.keys(QPDoubleOptions[name]).includes(rightQP) ? rightQP : Object.keys(QPDoubleOptions[name])[0];
    const range = QPDoubleOptions[name][right]().absolute;

    setState(s => ({
      ...s,
      absolute: range,
      relative: pickRelative([name, right]),
    }));
    setDoubleQP([name, right]);
  };

  const handleDoubleQuickPickRight = (name) => {
    const left = leftQP || 'this';
    const range = QPDoubleOptions[left][name]().absolute;

    setState(s => ({
      ...s,
      absolute: range,
      relative: pickRelative([left, name]),
    }));
    setDoubleQP([left, name]);
  };

  const filteredTabs = tabs.filter(({ name }) => !~hiddenTabs.indexOf(name));

  const absoluteRangeState = useMemo(() => {
    return [{ key: 'selection', startDate: state.absolute[0].toDate(), endDate: state.absolute[1].toDate() }];
  }, [state.absolute?.[0]?.toDate?.() + state.absolute?.[1]?.toDate?.()])

  const tid = testId('date-filter-picker');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...containerStyle }}>
      {title && (
        <TitleRow>
          <Title>{title}</Title>
        </TitleRow>
      )}
      {disabled ? (
        <Tooltip title={t('tooltips.select_field_with_date')}>
          <Container data-testid="date-range-picker-value-container" disabled={disabled} $large={large} $lighter={!onSetToLifetime} style={style}>
            {/*{title && <Title>{title}</Title>}*/}
            {capitalize(resolveTitle(value, !!onSetToLifetime, p))}
            <IconContainer $large={large} $lighter={!onSetToLifetime}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.02201 4.44444C4.70291 4.44444 4.44423 4.18576 4.44423 3.86667V2.84444H3.55534C2.67168 2.84444 1.95534 3.56079 1.95534 4.44444V6.22222H14.0442V4.44444C14.0442 3.56079 13.3279 2.84444 12.4442 2.84444H11.5555V3.86667C11.5555 4.18576 11.2968 4.44444 10.9777 4.44444C10.6586 4.44444 10.4 4.18576 10.4 3.86667V2.84444H5.59978V3.86667C5.59978 4.18576 5.3411 4.44444 5.02201 4.44444ZM10.4 1.77778H5.59978V0.577778C5.59978 0.25868 5.3411 0 5.02201 0C4.70291 0 4.44423 0.25868 4.44423 0.577778V1.77778H3.55534C2.08258 1.77778 0.888672 2.97168 0.888672 4.44444V12.4444C0.888672 13.9172 2.08258 15.1111 3.55534 15.1111H12.4442C13.917 15.1111 15.1109 13.9172 15.1109 12.4444V4.44444C15.1109 2.97169 13.917 1.77778 12.4442 1.77778H11.5555V0.577778C11.5555 0.25868 11.2968 0 10.9777 0C10.6586 0 10.4 0.25868 10.4 0.577778V1.77778ZM1.95534 7.28889V12.4444C1.95534 13.3281 2.67168 14.0444 3.55534 14.0444H12.4442C13.3279 14.0444 14.0442 13.3281 14.0442 12.4444V7.28889H1.95534Z" fill="#909399"/>
              </svg>
            </IconContainer>
            {error && <Error>{e(error)}</Error>}
          </Container>
        </Tooltip>
      ) : (
        <Container data-testid="date-range-picker-value-container" disabled={disabled} $large={large} $lighter={!onSetToLifetime} $error={!!tooltipError} style={style} onClick={modal.open}>
          {/*{title && <Title>{title}</Title>}*/}
          {capitalize(resolveTitle(value, !!onSetToLifetime, p))}
          <IconContainer $large={large} $lighter={!onSetToLifetime}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.02201 4.44444C4.70291 4.44444 4.44423 4.18576 4.44423 3.86667V2.84444H3.55534C2.67168 2.84444 1.95534 3.56079 1.95534 4.44444V6.22222H14.0442V4.44444C14.0442 3.56079 13.3279 2.84444 12.4442 2.84444H11.5555V3.86667C11.5555 4.18576 11.2968 4.44444 10.9777 4.44444C10.6586 4.44444 10.4 4.18576 10.4 3.86667V2.84444H5.59978V3.86667C5.59978 4.18576 5.3411 4.44444 5.02201 4.44444ZM10.4 1.77778H5.59978V0.577778C5.59978 0.25868 5.3411 0 5.02201 0C4.70291 0 4.44423 0.25868 4.44423 0.577778V1.77778H3.55534C2.08258 1.77778 0.888672 2.97168 0.888672 4.44444V12.4444C0.888672 13.9172 2.08258 15.1111 3.55534 15.1111H12.4442C13.917 15.1111 15.1109 13.9172 15.1109 12.4444V4.44444C15.1109 2.97169 13.917 1.77778 12.4442 1.77778H11.5555V0.577778C11.5555 0.25868 11.2968 0 10.9777 0C10.6586 0 10.4 0.25868 10.4 0.577778V1.77778ZM1.95534 7.28889V12.4444C1.95534 13.3281 2.67168 14.0444 3.55534 14.0444H12.4442C13.3279 14.0444 14.0442 13.3281 14.0442 12.4444V7.28889H1.95534Z" fill="#909399"/>
            </svg>
          </IconContainer>
          {tooltipError && (
            <Error smaller={!large} left>
              <Tooltip title={e(tooltipError)} placement="bottom">
                <div>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="7" fill="#C8402F"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
                  </svg>
                </div>
              </Tooltip>
            </Error>
          )}
        </Container>
      )}
      <Modal
        opened={modal.opened}
        onClose={handleCancel}
        style={{ maxWidth: 560, minWidth: '560px' }}
        contentStyles={{ padding: 0, borderRadius: 13 }}
      >
        {filteredTabs.length > 1 && (
          <TabsContainer>
            {filteredTabs.map(({ name, label }) => (
              <Tab key={name} $selected={name === tab} id={`date-picker-tabs-${name}`} onClick={() => handleTabChange(name)}>{label}</Tab>
            ))}
            <TabIndicator id="date-picker-indicator" $selected={tab} />
          </TabsContainer>
        )}
        {~disabledTabs.indexOf(tab) ? (
          <DisabledTab>
            {p('disabled_Description')}
          </DisabledTab>
        ) : (
          <>
            {(tab === 'absolute' || tab === 'relative') && (
              <QuickPicksContainer>
                <QuickPickButton
                  title={p('now')}
                  data-testid="date-filter-pick-button-now"
                  selected={isSameRange(QPOptions.now().absolute, state.absolute)}
                  value={QPOptions.now}
                  onSelect={handleQP}
                />
                <QuickPickButton
                  title={p('today')}
                  data-testid="date-filter-pick-button-today"
                  value={QPOptions.today}
                  selected={isSameRange(QPOptions.today().absolute, state.absolute)}
                  onSelect={handleQP}
                />
                <QuickPickButton
                  title={p('yesterday')}
                  data-testid="date-filter-pick-button-yesterday"
                  value={QPOptions.yesterday}
                  selected={isSameRange(QPOptions.yesterday().absolute, state.absolute)}
                  onSelect={handleQP}
                />
                <QuickPickButton
                  title={p('tomorrow')}
                  data-testid="date-filter-pick-button-tomorrow"
                  value={QPOptions.tomorrow}
                  selected={isSameRange(QPOptions.tomorrow().absolute, state.absolute)}
                  onSelect={handleQP}
                />
                <QuickPickDoubleSelect
                  leftOptions={Object.keys(QPDoubleOptions).map(key => ({ value: key, label: p(key) }))}
                  leftValue={leftQP}
                  rightOptions={Object.keys(QPDoubleOptions[leftQP]).map(key => ({ value: key, label: p(key) }))}
                  rightValue={rightQP}
                  selected={!!doubleQuickPick}
                  onLeftChange={handleDoubleQuickPickLeft}
                  onRightChange={handleDoubleQuickPickRight}
                />
              </QuickPicksContainer>
            )}
            {(tab === 'relative') && (
              <RelativeRangeSettings
                value={state.relative}
                onChange={handleRelativeSettingsChange}
              />
            )}
            {(tab === 'absolute' || tab === 'relative') && (
              <>
                {/*<PopupContainer ref={popupContainerRef} />*/}
                <DateRange
                  months={2}
                  ranges={absoluteRangeState}
                  direction="horizontal"
                  onChange={handleSelect}
                />
                <DateTimeDisplay
                  {...tid(`select-time`)}
                  disabled={tab === 'relative'}
                  onChange={r => handleRangeChange(r, 'second')}
                  range={state.absolute}
                />
              </>
            )}
            {(tab === 'specific') && (
              <>
                <SpecificRangeSettings value={state} onChange={setState} />
              </>
            )}
          </>
        )}
        <ActionsContainer>
          {onSetToLifetime ? <Button appearance="outlined" onClick={handleSetToLifetime} style={{ flex: 0 }}>{p('set_to_lifetime')}</Button> : <div />}
          <div style={{ display: 'flex'}}>
            <Button {...tid(`cancel`)} appearance="secondary" style={{ marginRight: 10 }} width={80} onClick={handleCancel}>{t('actions.cancel')}</Button>
            <Button {...tid(`apply`)} appearance="primary" width={80} onClick={handleApply}>{t('actions.apply')}</Button>
          </div>
        </ActionsContainer>
      </Modal>
    </div>
  );
};

export default DateFilterPicker;
