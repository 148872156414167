import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container  = styled.div`
  display: flex;
   ${styledProp('$opened', `
    padding: 5px 14px;
  `, `
    padding: 5px 0px 5px 10px;
    width: 42px;
  `)}
     ${styledProp('$addOpacity', `
    opacity: 0.7;
  `, `
    opacity: 1;
  `)}
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  align-items: center;
  cursor: pointer;
  transition: all 280ms ease;

  &:hover {
    background-color: #eeeeee;
  }
`;

export const Title = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
