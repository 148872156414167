import styled from 'styled-components';
import { Device } from '@res';

export const Container = styled.div`
  background-color: #FAFAFA;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;

  @media screen and ${Device.desktopS} {
    padding: 20px 260px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 16px 21px;
  flex-direction: column;
`;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchFilter = styled.div`
  width: 325px;
`;

export const FilterField = styled.div`
  width: 162px;
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  padding: 7px 10px;
  background: #FFFAED;
  border: 1px solid #FFDC7B;
  border-radius: 7px;
`;

export const DisclaimerHint = styled.div`
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #303133;
  font-family: Rubik-Regular;
`;

export const TitleContainer = styled.div`
  position: relative;
  font-family: Rubik-Regular;
  font-size: 20px;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: -14px;
  top: -2px;
`;
