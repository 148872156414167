import styled from 'styled-components';

export const Container = styled.div`
  background: #F9FBFF;
  border: 1px solid #C1CEFC;
  border-radius: 7px;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #109DFB;
  padding: 10px 0;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;
