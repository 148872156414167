import { ContactsActionTypes } from '../types';
import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '../../lib';
import { compose } from '@utils';

export const getContacts = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'start_date', 'end_date']),
  asCreator(({ fields, aggregates }) => ({ type: ContactsActionTypes.GET, payload: { fields, aggregates } })),
);

export const getContactsImportHistory = compose(
  withPagination,
  withOrdering,
  withFilters(['user_id', 'status', 'name', 'end_date', 'start_date']),
  asCreator(() => ({
    type: ContactsActionTypes.GET_IMPORT_HISTORY,
  }))
);

export const deleteAllCustomers = compose(
  withCallbacks,
  asCreator(() => ({
    type: ContactsActionTypes.DELETE_ALL,
  }))
)

export const uploadContactsFile = compose(
  withCallbacks,
  asCreator(({ create, update, file, name }) => ({
    type: ContactsActionTypes.UPLOAD_FILE,
    payload: { file, name },
    meta: { create, update },
  })),
);

export const startContactsImport = compose(
  withCallbacks,
  asCreator(({ fields }) => ({
    type: ContactsActionTypes.START_IMPORT,
    payload: { fields },
  }))
);

export const getFieldSettings = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', '$type']),
  asCreator(({ paginate = true, target }) => ({
    type: ContactsActionTypes.GET_FIELD_SETTINGS,
    payload: { paginate: +paginate, target }
  }))
);

export const getCustomerFieldInfo = compose(
  withPagination,
  withCallbacks,
  asCreator(({ customerId, id, subArrayIndex, subArrayId }) => ({
    type: ContactsActionTypes.GET_CUSTOMER_FIELD_INFO,
    payload: { customerId, id, subArrayIndex, subArrayId },
  }))
)

export const getEventList = compose(
    withPagination,
    withOrdering,
    withFilters(['input_value', 'start_date', 'end_date', 'user_id', '$type']),
    asCreator(() => ({ type: ContactsActionTypes.GET_EVENT_LIST }))
);

export const getFieldSettingsList = compose(
  asCreator(() => ({ type: ContactsActionTypes.GET_FIELD_LIST }))
)

export const getImportProgress = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_IMPORT_PROGRESS,
    payload: { id },
  }))
);

export const getContactInfo = compose(
  withFilters(['fields']),
  asCreator(({ id, fields }) => ({
    type: ContactsActionTypes.GET_CONTACT_INFO,
    payload: { fields },
    meta: { id },
  }))
);

export const getContactEvents = compose(
  withPagination,
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_EVENT,
    meta: { id },
  }))
);

export const getContactEventTotal = compose(
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_EVENT_TOTAL,
    meta: { id },
  }))
);

export const deleteContact = compose(
  withCallbacks,
  asCreator(({ id, customer_id }) => ({
    type: ContactsActionTypes.DELETE_CONTACT,
    payload: { id, customer_id },
  })),
);

export const createContactComment = compose(
  withCallbacks,
  asCreator(({ id, comment }) => ({
    type: ContactsActionTypes.CREATE_COMMENT,
    payload: { id, comment },
  }))
);

export const deleteContactComment = compose(
  withCallbacks,
  asCreator(({ id, customer_id }) => ({
    type: ContactsActionTypes.DELETE_COMMENT,
    payload: { id, customer_id },
  }))
);

export const createContact = compose(
  withCallbacks,
  asCreator(({ fields, validate_id = true }) => ({
    type: ContactsActionTypes.CREATE_CONTACT,
    payload: { fields },
    meta: { validate_id }
  })),
);

export const createNewContactFields = compose(
  withCallbacks,
  asCreator(({ validate_id, new_fields }) => ({
    type: ContactsActionTypes.CREATE_NEW_CONTACT_FIELDS,
    payload: { new_fields },
    meta: { validate_id }

  }))
)

export const downloadImportFile = ({ id, name }) => ({
  type: ContactsActionTypes.DOWNLOAD_IMPORT_FILE,
  payload: { id },
  meta: { name },
});

export const deleteMultipleContacts = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: ContactsActionTypes.DELETE_MULTIPLE,
    payload: { ids },
  }))
);

export const deleteContactField = compose(
  withCallbacks,
  asCreator(({ field }) => ({
    type: ContactsActionTypes.DELETE_FIELD_SETTINGS,
    payload: { field },
  }))
)

export const updateContactField = compose(
  withCallbacks,
  asCreator(({ id, label, field, description, payload }) => ({
    type: ContactsActionTypes.UPDATE_CONTACT_FIELD,
    payload: { label, field, description, payload },
    meta: { id }
  }))
);

export const changeImportStep = ({ step }) => ({
  type: ContactsActionTypes.CHANGE_IMPORT_STEP,
  payload: { step }
});

export const updateImportFieldsConfiguration = ({ configuration }) => ({
  type: ContactsActionTypes.UPDATE_IMPORT_FIELDS_CONFIGURATION,
  payload: configuration
});

export const getContactEmailInfo = compose(
  withPagination,
  withOrdering,
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_EMAIL_INFO,
    meta: { id },
  }))
);

export const getContactInvolvement = compose(
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_INVOLVEMENT,
    meta: { id },
  }))
);

export const getContactAudits = compose(
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_AUDITS,
    meta: { id },
  }))
);

export const getContactSmsInfo = compose(
  withPagination,
  withOrdering,
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_SMS_INFO,
    meta: { id },
  }))
);

export const getContactPushInfo = compose(
  withPagination,
  withOrdering,
  asCreator(({ id }) => ({
    type: ContactsActionTypes.GET_CONTACT_PUSH_INFO,
    meta: { id },
  }))
);

export const getEmailSummary = compose(
  asCreator(({ id, range, email_id }) => ({
    type: ContactsActionTypes.GET_EMAIL_SUMMARY,
    payload: { range },
    meta: { id, email_id },
  }))
);

export const getSmsSummary = compose(
  asCreator(({ id, range, sms_id }) => ({
    type: ContactsActionTypes.GET_SMS_SUMMARY,
    payload: { range },
    meta: { id, sms_id },
  }))
);

export const getWebpushSummary = compose(
  asCreator(({ id, template_id }) => ({
    type: ContactsActionTypes.GET_WEBPUSH_SUMMARY,
    payload: { template_id, id },
    meta: { id, template_id },
  }))
);

export const getWebhookSummary = compose(
  asCreator(({ id, template_id }) => ({
    type: ContactsActionTypes.GET_WEBHOOK_SUMMARY,
    payload: { template_id },
    meta: { id, template_id },
  })),
);

export const setCols = ({ cols }) => ({
  type: ContactsActionTypes.SET_COLS,
  payload: { cols },
});

export const setColsOptions = ({ colsOptions, keepOrder = false }) => ({
  type: ContactsActionTypes.SET_COLS_OPTIONS,
  payload: { colsOptions },
  meta: { keepOrder },
});

export const downloadContacts = compose(
  withCallbacks,
  asCreator(({ fields, ids, filters = {} }) => ({
    type: ContactsActionTypes.DOWNLOAD_CONTACTS,
    payload: { fields, ids },
  })),
);

export const getIndexCount = compose(
  withCallbacks,
  withFilters(['input_value', 'start_date', 'end_date']),
  asCreator(({ page = 'index', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page },
  }))
);

export const getSegmentExampleCount = compose(
  withCallbacks,
  withFilters(['input_value']),
  asCreator(({ page = 'segment', store, ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page, customPage: store || page }
  }))
);

export const getSegmentExampleAllCount = compose(
  withCallbacks,
  asCreator(({ page = 'segment', customPage = 'segment-all', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page, customPage }
  }))
);

export const getSegmentExampleFilteredCount = compose(
  withCallbacks,
  asCreator(({ page = 'segment', customPage = 'segment-filtered', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page, customPage }
  }))
);

export const getFunnelPreviewCount = compose(
  withCallbacks,
  withFilters(['input_value']),
  asCreator(({ page = 'analytic_funnel', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page },
  }))
);

export const getAggregatesExampleCount = compose(
  withCallbacks,
  withFilters(['input_value']),
  asCreator(({ page = 'aggregate', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page },
  }))
);

export const getUnsubscribeGroupCount = compose(
  withCallbacks,
  withFilters(['input_value']),
  asCreator(({ page = 'unsubscribe_group', ...payload }) => ({
    type: ContactsActionTypes.GET_CUSTOMERS_COUNT,
    payload: { ...payload },
    meta: { page },
  }))
);

export const reorderFields = compose(
  withCallbacks,
  asCreator(({ fields }) => ({
    type: ContactsActionTypes.REORDER_FIELDS,
    payload: { fields: fields.filter(({ field }) => field !== 'id') },
    meta: { fields: fields.filter(({ field }) => field !== 'id') }
  }))
);

export const changeFieldDisplayingStatus = compose(
  withCallbacks,
  asCreator(({ field, type, active }) => ({
    type: ContactsActionTypes.CHANGE_FIELD_DISPLAYING_STATUS,
    payload: { field, type, active },
    meta: { field, type, active }
  }))
);

export const getAggregatesList = compose(
  withCallbacks,
  asCreator(() => ({
    type: ContactsActionTypes.GET_AGGREGATES_LIST,
  }))
);
