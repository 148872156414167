import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  padding: 8px 0;
    // margin-left: ${({ level }) => (level) * 24}px;
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  border-bottom-left-radius: 4px;
  border: 1px solid #DCDFE6;
  border-top-width: 0;
  border-right-width: 0;
  width: 18px;
  ${styledProp('isFirst', `
    top: 0;
  `, `
    top: -26px;
  `)};
  bottom: calc(100% - 19px);
  left: 8px;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #303133;
  margin-right: 12px;
  padding-left: 4px;
`;

export const Value = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #909399;
`;

export const InfoContainer = styled.div`
  display: flex;
  padding: 0 0 0 30px;
  ${styledProp('nesting', `
    flex-direction: column;
  `)}
`;

export const AdditionalLine = styled.div`
  width: 1px;
  background: #e6dcdc;
  height: calc(100% - 28px);
  bottom: 20px;
  position: absolute;
  left: 8px;
`;
