import React from 'react';
import { identity } from '@utils';

import { Container, Label, StyledCheckbox } from './styled';

const MultiselectGroup = ({ options, testId = '', value = [], onChange, max, translate = identity, ...props }) => {
  const disabled = props.disabled || value.length >= max;

  const handleChange = (v) => () => {
    if (!!~value.indexOf(v)) {
      return onChange(value.filter(vv => vv !== v));
    }

    onChange([...value, v]);
  };

  return (
    <Container data-testid={testId} style={props.style}>
      {options.map(({ value: curr, label }, idx) => (
        <StyledCheckbox
          key={idx}
          data-testid={`dashboard-settings-value-${curr}`} checked={~value.indexOf(curr)} disabled={disabled && !~value.indexOf(curr)} onChange={handleChange(curr)}>
          <Label>{translate(label)}</Label>
        </StyledCheckbox>
      ))}
    </Container>
  );
};

export default MultiselectGroup;
