import { all, takeLatest, put } from 'redux-saga/effects';
import { RFMAnalyticsActionTypes} from '@store/actions/types';

import {createRequestAction} from "@store/lib/utils/creators";

function* archiveRFMAnalyticsSaga (action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/rfm/archive',
    body: {
      id: action.payload.id,
    }
  }))
}

function* createRFMAnalyticsSaga (action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/rfm',
    body: {
      ...action.payload,
    },
  }))
}

function* deleteRFMAnalyticsSaga (action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: '/api/rfm/delete',
    body: {
      id: action.payload.id,
    }
  }))
}

function* editRFMAnalyticsRangeSaga (action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/rfm/aggregate',
    params: {
      rfm_id: action.payload.id,
      rfm_range_id: action.payload.range_id
    },
  }))
}

function* editRFMAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/rfm/${action.payload.id}/edit`,
  }))
}

function* getRFMAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/rfm`,
  }))
}

function* getRFMListSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/rfm/list`,
  }))
}

function* renameRFMAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/rfm/rename`,
    body: {
      id: action.payload.id,
      name: action.payload.name,
    }
  }))
}

function* stopRFMANAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'put',
    endpoint: `/api/rfm/stop`,
    body: {
      id: action.payload.id,
    },
  }))
}

function* unarchiveRFMAnalyticsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/rfm/un_archive`,
    body: {
      id: action.payload.id,
    }
  }))
}

export default function* rfmAnalyticsRootSaga() {
  yield all([
    takeLatest(RFMAnalyticsActionTypes.GET_RFM_ANALYTICS, getRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.GET_RFM_LIST, getRFMListSaga),
    takeLatest(RFMAnalyticsActionTypes.CREATE_RFM_ANALYTICS, createRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS, editRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.STOP_RM_ANALYTICS, stopRFMANAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.DELETE_RFM_ANALYTICS, deleteRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.ARCHIVE_RFM_ANALYTICS, archiveRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.UNARCHIVE_RFM_ANALYTICS, unarchiveRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.RENAME_RFM_ANALYTICS, renameRFMAnalyticsSaga),
    takeLatest(RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_RANGE, editRFMAnalyticsRangeSaga),
  ]);
}
