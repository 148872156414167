import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  height: calc(100vh - 60px);
`;

export const TabsContainer = styled.div`
  display: flex;
  //justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  justify-content: flex-end;
  user-select: none;
  position: relative;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #303133;
  position: absolute;
  left: 0;
  bottom: 0;
  
  ${styledProp('relative', `
    position: relative;
    font-size: 14px;
    padding: 10px 20px;
  `)}

  ${styledProp('bottom', `
    bottom: -42px;
  `)}
`;

export const ResizableContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
`;

export const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 2px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const ApiRequestHeadersEditorContainer = styled.div`
  width: 100%;
  height: 30%;
  margin-right: 2px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 2px;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const ApiRequestBodyEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 2px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  //margin-top: 2px;
`;

export const ApiRequestEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: 2px;
  flex-direction: column;
  margin-top: 50px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const Preview = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  
  ${styledProp('resizing', css`
    pointer-events: none;
  `, css`
    pointer-events: auto;
  `)}
`;

export const TextPreview = styled.div`
  white-space: break-spaces;
  width: 100%;
  padding: 20px;
`;

export const PreviewInner = styled.div`
  width: ${({ isMobileMode }) => (isMobileMode ? '320px' : '100%')};
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  background-color: #fff;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
`;

export const ExampleDataContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: ${({ drawerVisible }) => (drawerVisible ? 1 : -1)};
  border: 1px solid #DCDFE6;
  border-radius: 10px;

  .EmailSampleData-drawer {
    position: absolute;
    width: 100% !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  left: -18px;
  bottom: 0;
  z-index: 10;
  cursor: col-resize;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 30px;
  margin-right: ${({ isLeft }) => (isLeft ? '2px' : '0px')};
  background-color: #fff;
  border: 1px solid #dcdfe6;
  border-top-left-radius: ${({ isLeft }) => (isLeft ? '4px' : '0px')};
  border-top-right-radius: ${({ isLeft }) => (isLeft ? '0px' : '4px')};
  border-bottom-left-radius: ${({ isLeft }) => (isLeft ? '4px' : '0px')};
  border-bottom-right-radius: ${({ isLeft }) => (isLeft ? '0px' : '4px')};
`;

