import React from 'react';
import { StatusTitle } from '../../styled';

const StatusCell = ({ status, color }) => {
  return (
    <StatusTitle color={color}>{status}</StatusTitle>
  )
};

export default StatusCell;
