import React from 'react';

import { Container } from './styled.js';
import { by } from '@utils';
import { useSelector } from 'react-redux';
import { mobilePushNodeAnalyticsSelector, viberNodeAnalyticsSelector } from '@store/selectors';
import { useTranslation } from '@hooks';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';

const MobilePushNodeSettingsInfo = ({ node, options }) => {
  const mobilePush = options?.mobile_push?.find?.(by(node.data?.mobile_push_id));
  const mobilePushAnalytics = useSelector(mobilePushNodeAnalyticsSelector);
  const { t } = useTranslation();

  if (!mobilePush) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.template')}:
        </InfoTitle>
        <InfoText>
          {mobilePush.name}
        </InfoText>
      </InfoRow>
      {(node.data.sending && !mobilePushAnalytics.loading) && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default MobilePushNodeSettingsInfo;


