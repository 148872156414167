import React from 'react';
import { Badge, Items, Row } from '@components/lib/NotificationsList/styled';
import { NotificationItem } from '@components/lib/NotificationsList/components/NotificationItem';
import { useTransition, config } from '@react-spring/web';
import { testId } from '@utils';

const tid = testId('sidebar-notifications')

const NotificationsGroup = ({ group, name, onRead, opened, onClose }) => {
  return (
    <>
      <Row>
        <Badge>{name}</Badge>
      </Row>
      <Items {...tid('container')}>
        {group.map((item, i) => (
          <NotificationItem
            // style={style}
            item={item}
            key={i}
            tid={tid(`item-${i}`)}
            onClose={onClose}
            date={item.created_at}
            isLast={i === group.length - 1}
            readNotification={onRead}
          />
        ))}
      </Items>
    </>
  );
};

export default NotificationsGroup;
