import React from 'react';
import { Dropdown, Tooltip } from 'antd';
import { Link, UnderlinedText } from '@components';
import { Paths } from '@constants';

import { Container, WorkflowsContainer, DraftStatus } from './styled';
import { useTranslation } from '@hooks';

const resolveStatus = (status) => {
  switch (status) {
    case 0:
      return 'statuses.draft';
    case 1:
      return 'statuses.used';
    default:
      return '';
  }
};

const EventStatus = ({ status, workflows }) => {
  const workflowItems = Object.entries(workflows || {}).filter(([key]) => !isNaN(+key)).map(([, wf]) => wf);
  const { t } = useTranslation();

  return (
    <Container>
      {workflowItems.length ? (
        <Dropdown trigger={['hover']} placement="bottomCenter" overlay={(
          <WorkflowsContainer>
            {workflowItems.map(({ name, id }) => <Link to={`${Paths.EDIT_WORKFLOW}/${id}/editor`}>{name}</Link>)}
          </WorkflowsContainer>
        )}>
          <div>
            <UnderlinedText text={t(resolveStatus(1))} />
          </div>
        </Dropdown>
      ) : (
        <Tooltip title={t('labels.not_used')}>
          <DraftStatus>
            {t(resolveStatus(0))}
          </DraftStatus>
        </Tooltip>
      )}
    </Container>
  );
};

export default EventStatus;
