import { by, dist } from '@utils';
import { iterate, toArray } from '@utils/workflows/refactored/structureParser';
import { WorkflowNodes } from '@constants';

export const getClosestNode = (root, styles, { position }) => {
  const nodes = toArray(root).filter(({ type, id }) => type !== WorkflowNodes.END_PATH && !styles[id]?.temp);

  const sorted = nodes.sort((a, b) => dist(styles[a.id]?.position || {}, position || {}) - dist(styles[b.id]?.position || {}, position || {}));

  return sorted.shift();
};

export const getTempNode = (root, styles) => {
  if (styles[root.id]?.temp) {
    return root;
  }

  for (const child of (root.children || [])) {
    const found = getTempNode(child, styles);

    if (found) {
      return found;
    }
  }
};

export const getParent = (root, id) => {
  if (root?.children?.some?.(by(id))) {
    return root;
  }

  for (const child of (root.children || [])) {
    const result = getParent(child, id);
    if (result) {
      return result
    }
  }
};

export const getChildren = (root, id, res = []) => {
  if (root.id === id) {
    res.push(...root.children);
  }

  if (res.length) {
    return res;
  }

  for (const child of (root.children || [])) {
    getChildren(child, id, res);
  }

  return res;
};

export const isLast = (root, id) => {
  let isLast = false;
  iterate(root, ({ children, id: curr }) => {
    if (curr === id) {
      isLast = !children?.length || ((children.length === 1 && children[0].type === WorkflowNodes.END_PATH) ? children?.[0]?.id : false);
    }
  });

  return isLast;
};

export const getNextNodes = (root, id) => {
  return getChildren(root, id).flatMap(child => toArray(child)).filter(({ type }) => type !== WorkflowNodes.END_PATH);
}

export const getLength = (root, skipEndPath = true) => {
  if (!root || (skipEndPath && root.type === WorkflowNodes.END_PATH)) {
    return 0;
  }

  return (root.children || []).reduce((acc, child) => acc + getLength(child), 1)
};

export const getNode = (root, id) => {
  if (!root || root.id === id) {
    return root;
  }

  for (const child of root.children || []) {
    const found = getNode(child, id);

    if (found) {
      return found;
    }
  }

  return null;
};

export const getLastNode = (root) => {
  if (!(root.children || []).filter(({ type }) => type !== 'end').length) {
    return root;
  }

  return getLastNode(root.children[0]);
}

export const getParentsArray = (root, fromId) => {
  const res = [];
  let id = fromId;

  while (getParent(root, id)) {
    const parent = getParent(root, id);

    res.push(parent);
    id = parent.id;
  }

  return res;
};

export const getExitPointsCount = (root) => {
  let count = 0;

  for (const child of (root?.children || [])) {
    if (child.type === WorkflowNodes.END_PATH) {
      count += 1;
    }

    count += getExitPointsCount(child);
  }

  return count;
};

export const getExitPoints = (root) => {
  const ep = [];

  for (const child of (root?.children || [])) {
    if (child.type === WorkflowNodes.END_PATH) {
      ep.push(child);
    }

    ep.push(...getExitPoints(child));
  }

  return ep;
};

export const isRealtime = (root) => {
  return !!~[
    WorkflowNodes.EMAIL,
    WorkflowNodes.EVENT,
    WorkflowNodes.PAGE_VIEW,
    WorkflowNodes.DATA_CHANGE,
    WorkflowNodes.CONTACT_CREATED,
    WorkflowNodes.ENTRY_ANOTHER_WF,
  ].indexOf(root?.type)
}
