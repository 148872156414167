import { ReducerKeys } from '@constants';

export const appRedirectsSelector = store => store[ReducerKeys.APP].redirects;

export const trialBarShownSelector = store => store[ReducerKeys.APP].trialBarShown;

export const sidebarOpenedSelector = store => store[ReducerKeys.APP].sidebarOpened;

export const hintModeSelector = store => store[ReducerKeys.APP].hintMode;

export const onboardingSelector = store => store[ReducerKeys.APP].onboarding;

export const skippedOnboardsSelector = store => store[ReducerKeys.APP].onboarding.skipped;

export const messageChannelsDisclaimerClosedSelector = store => store[ReducerKeys.APP].messageChannelsDisclaimerClosed;
