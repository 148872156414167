import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div``;

export const Text = styled.div``;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Button = styled.div`
  width: 62px;
  height: 26px;
  padding: 6px 20px;
  line-height: 14px;
  cursor: pointer;
  font-size: 12px;
  background: linear-gradient(180deg, #4B88FF 18%, #5A56FD 106%);
  color: #FFFFFF;
  border-radius: 7px;
  margin-right: 0;

  ${styledProp('no', `
    color: #109DFB;
    background: transparent;
    border: 1px solid #109DFB;
    margin-right: 10px;
  `)}
`;


