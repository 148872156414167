import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '@store/selectors';
import { Container } from './styled.js';
import { resendRegistrationEmail } from '@store/actions/creators';
import { notify } from '@utils';

const ActivateBar = ({ onResend }) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);

  if (user?.email_verified || hidden) {
    return null;
  }

  const handleResend = () => {
    dispatch(resendRegistrationEmail({
      email: user?.email,
      onSuccess: () => {
        notify('success', 'Success', 'Email has been resent successfully');
        setHidden(true);
      },
      onError: ({ response }) => {
        if (!response?.data) {
          return;
        } else if (Object.values(response.data.errors).length) {
          notify('error', 'Error', Object.values(response.data.errors)[0]);
        }
      },
    }))
  }

  return (
    <Container>
      Please confirm your email address and {<div style={{ marginLeft: 4, color: '#909399' }}>verify your account to send messages.</div>} {<div style={{ cursor: 'pointer', marginLeft: 4, color: '#109DFB', textDecoration: 'underline' }} onClick={handleResend}>Resend confirmations email</div>}
    </Container>
  );
}

export default ActivateBar;

