import React from 'react';

import { Container, Event } from './styled.js';
import { useSelector } from 'react-redux';
import { eventsListSelector } from '@store/selectors';
import { SegmentsRuleTypes } from '@constants';
import { NoData } from '@components';
import { Suffix } from '@components/inputs/Select/styled';
import { getEventOptions, resolveDropdownFieldType } from '@utils';
import { useTranslation } from '@hooks';

const EventsFilterPanel = ({ onSelect, hidePreview, nested }) => {
  const events = useSelector(eventsListSelector);
  const eventFields = nested ? getEventOptions(events) : events?.data?.map(e => ({ ...e, value: e.name }));
  const { p } = useTranslation('segments_page');

  if (!eventFields?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-event-tab-content'}>
      {eventFields?.map((e, idx) => (
        <div key={idx} style={{ display: 'flex' }}>
          <Event data-testid={`store-segment-filter-panel-event-field-${e.name}`} onClick={() => onSelect(e, SegmentsRuleTypes.EVENT)}>{e.prefix ? <div style={{ color: '#909399' }}>{e.prefix}.</div> : ''}{e.label}</Event>
          {nested && <Suffix>{resolveDropdownFieldType(e.prefix, 'event', p)}</Suffix>}
        </div>
      ))}
    </Container>
  );
}

export default EventsFilterPanel;


