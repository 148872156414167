import React from 'react';
import { Icon } from '@components';

import { Container } from './styled';

const SidebarIcon = ({ type, icon }) => {
  return (
   <Container type={type}>
     <div>
       <Icon size={12} color="#fff" name={icon} />
     </div>
   </Container>
  );
};

export default SidebarIcon;
