import { useSelector } from 'react-redux';
import { loggedInSelector, workspacesListSelector } from '@store/selectors';
import { by } from '@utils';
import { useEffect, useMemo } from 'react';

const useCurrentWorkspace = () => {
  const user = useSelector(loggedInSelector);

  return user?.app;
}

export default useCurrentWorkspace;
