import { compose } from '@utils';
import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '@store/lib';
import { EventsActionTypes } from '@store/actions/types';

export const listEvents = compose(
  withCallbacks,
  withOrdering,
  withPagination,
  withFilters(['input_value', '$type', 'user_id', 'start_date', 'end_date', 'used']),
  asCreator(({ paginate }) => ({
    type: EventsActionTypes.LIST,
    payload: { paginate },
  })),
);

export const createEvent = compose(
  withCallbacks,
  asCreator(({ name, id, payload, label, description }) => ({
    type: EventsActionTypes.CREATE,
    payload: { name, id, payload, label, description },
  })),
);

export const editEvent = compose(
  withCallbacks,
  asCreator(() => ({
    type: EventsActionTypes.EDIT,
  })),
);

export const deleteEvent = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: EventsActionTypes.DELETE,
    payload: { id },
  }))
);
