import React from 'react';

import { Container, Indicator } from './styled';

const LegendItem = ({ name, style, color }) => {
  return (
    <Container style={style}>
      <Indicator $c={color} />
      {name}
    </Container>
  );
};

export default LegendItem;
