import React, { useEffect, useMemo } from 'react';

import {
  Caption,
  Chart,
  ChartContainer,
  Container,
  LegendRow,
  Title,
  YIndicator,
  YIndicatorLine,
  LegendContainer,
  LegendItem,
  LegendCircle,
  LegendName, BarContainer, BarTitle, Bar
} from './styled.js';
import { clamp, createScaleIndicators, extract, findScaleIndicators, zeroIfNaN } from '@utils';
import { useTranslation } from '@hooks';

const getFixed = arr => Math.max(...arr.map(({ n }) => {
  const str = String((+n).toFixed(6)).split('.').pop();

  return str.length - str.replace(/^0+/, '').length;
}))

const SendingChart = ({ data }) => {
  const { p, t } = useTranslation('dashboard_page');
  const max = Math.max(...data.map(extract('value')).map(x => zeroIfNaN(x)));
  const generatedSI = findScaleIndicators(max, 100, 6);

  const si = useMemo(() => {
    return generatedSI.length ? generatedSI : [{ n: .2, h: .2 }, { n: .4, h: .4 }, { n: .6, h: .6 }, { n: .8, h: .8 }, { n: 1, h: 1 }];
  }, [data]);
  const f = clamp(getFixed(si) - 1, 2, 5);
  const fixed = f === 5 ? 2 : f;

  const toFixed = (n) => {
    return n === 0 ? (0).toFixed(2) : n.toFixed(fixed)
  };

  return (
    <Container>
      <LegendRow>
        <Title>{p('comparison')}</Title>
        <LegendContainer>
          {data.map(({ name, background }, index, arr) => (
            <LegendItem key={background} style={{ marginRight: index === arr.length - 1 ? '0px' : '16px' }}>
              <LegendCircle color={background}/>
              <LegendName>
                {p(name)}
              </LegendName>
            </LegendItem>
          ))}
        </LegendContainer>
      </LegendRow>
      <ChartContainer>
        <Chart>
          {si.map(({ n, h }) => (
            <YIndicatorLine percentage={h} key={n}>
              <YIndicator>{(n * 100).toFixed(fixed)}%</YIndicator>
              <YIndicator right>{(n * 100).toFixed(fixed)}%</YIndicator>
            </YIndicatorLine>
          ))}
          {data.map(({ background, color, value }) => (
            <BarContainer key={background}>
              <Bar max={max} value={value} color={background}>
                <BarTitle color={color}>{toFixed(zeroIfNaN(value))}%</BarTitle>
              </Bar>
            </BarContainer>
          ))}
        </Chart>
      </ChartContainer>
      <Caption>{t('labels.clicks')}</Caption>
    </Container>
  );
}

export default React.memo(SendingChart);


