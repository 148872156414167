import styled, { css } from 'styled-components';

import { styledProp } from '@utils';
import * as Colors from "@res/theme/colors";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${styledProp('small', css`
    width: 166px;
    margin: 0 12px 12px 0;
  `, css`
    flex: 1;
    min-width: 214px;
    max-width: 300px;
  `)}
  padding: 12px;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 166px;
  ${styledProp('small', css`
    max-width: 120px;
  `, css`
    max-width: 166px;
  `)}
`;

export const Title = styled.div`
  font-size: 13px;
  color: ${Colors.Text.PRIMARY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Date = styled.p`
  font-size: 10px;
  color: ${Colors.Text.SECONDARY};
  margin: 0;
`;

export const MenuItem = styled.p`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;
  ${({ disabled }) => disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
`;
