import React from 'react';

import Mark from '@res/images/check-mark.svg';
import LaterMark from '@res/images/setup-later.svg';

import { Container, LeftSide, RightSide, Step, StepContainer, StepIcon, IconContainer } from './styled.js';

//@TODO refactor me plz

const ProgressBar = ({ contactsExist, segmentsExist, integrationsExist, templatesExist, workflowsExist, skipIntegrations }) => {
  const renderStepIcon = (exist, skip, skipIntegrations) => {
    return (
      <Step exist={exist} later={(skip && !exist) || skipIntegrations}>
        {skipIntegrations && <IconContainer later={skipIntegrations}><StepIcon src={LaterMark} alt=""/></IconContainer>}
        {exist && !skipIntegrations && <IconContainer><StepIcon src={Mark} alt="" /></IconContainer>}
        {skip && !exist && !skipIntegrations && <IconContainer later={skip}><StepIcon src={LaterMark} alt=""/></IconContainer>}
      </Step>
    )
  };

  return (
    <Container>
      <StepContainer>
        <LeftSide green />
        {renderStepIcon(contactsExist, segmentsExist || integrationsExist || templatesExist || workflowsExist)}
        <RightSide green={contactsExist || segmentsExist || integrationsExist || templatesExist || workflowsExist} />
      </StepContainer>
      <StepContainer>
        <LeftSide green={segmentsExist || contactsExist || integrationsExist || templatesExist || workflowsExist} />
        {renderStepIcon(segmentsExist, integrationsExist || templatesExist || workflowsExist)}
        <RightSide green={segmentsExist || integrationsExist || templatesExist || workflowsExist} />
      </StepContainer>
      <StepContainer>
        <LeftSide green={segmentsExist || integrationsExist || templatesExist || workflowsExist} later={skipIntegrations} />
        {renderStepIcon(integrationsExist, templatesExist || workflowsExist, skipIntegrations)}
        <RightSide green={integrationsExist || templatesExist || workflowsExist} />
      </StepContainer>
      <StepContainer>
        <LeftSide green={integrationsExist || templatesExist || workflowsExist || skipIntegrations} />
        {renderStepIcon(templatesExist, workflowsExist)}
        <RightSide green={templatesExist || workflowsExist} />
      </StepContainer>
      <StepContainer>
        <LeftSide green={templatesExist} />
        {renderStepIcon(workflowsExist)}
        <RightSide green={workflowsExist} />
      </StepContainer>
    </Container>
  );
}

export default ProgressBar;


