import React, {useMemo} from 'react';
import {Paths} from "@constants";
import {SendingInfo} from "../SendingInfo";
import {useSelector} from "react-redux";
import {dashboardMobilePushSummarySelector, dashboardWebpushSummarySelector} from "@store/selectors/dashboard";
import {zeroIfNaN} from "@utils";
import {useTranslation} from "@hooks";

const MobilePushSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const mobilePushSummary = useSelector(dashboardMobilePushSummarySelector);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  const mobilePushClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(mobilePushSummary?.click / mobilePushSummary?.total * 100),
      count: mobilePushSummary?.click
    };
  }, [mobilePushSummary?.click, mobilePushSummary?.total]);

  return (
    <SendingInfo
      total={mobilePushSummary?.total}
      to={`${Paths.NOTIFICATIONS}?tab=mobile-push`}
      link={p('mobile_push')}
      clicks={mobilePushClicks}
      testId="dashboard-webpush-sendings"
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(MobilePushSendingInfo);
