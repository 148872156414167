import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
`;

export const FiltersMenu = styled.div`
  position: relative;
  border-top: 1px solid #DCDFE6;
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
`;

export const FiltersTitleContainer = styled.div`
  position: absolute;
  top: -8px;
  background: transparent;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

export const FiltersTitle = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  padding: 0px 10px;
  background: #fff;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 80px;
  padding: 16px 20px;
`;

export const FilterButton = styled.div`
  background: #F9FBFF;
  border: 1px dashed #109DFB;
  border-radius: 9px;
  user-select: none;
  cursor: pointer;
  padding: 10px 16px;
  font-size: 12px;
  line-height: 14px;
  color: #109DFB;
  font-family: Rubik-Regular;
`;

