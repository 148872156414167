import React, { useState } from 'react';

import { Button, Company, CompanyIcon, Container, Content, Footer, Image, Text, TextCol, Title, Arrow } from './styled.js';
import { useTranslation } from '@hooks';

const WindowsPushPreview = ({ opened, title, icon, onToggle, image, text, company, buttons }) => {
  const { p, t } = useTranslation('templates');
  const [hovered, setHovered] = useState(false);
  return (
    <Container onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      { image && <Image image={image} /> }
      <Content>
        <Arrow show={hovered}>
          <svg width="17" height="17" viewBox="0 0 31 31" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.2 5a1.11 1.11 0 0 0-1.58 0 1.12 1.12 0 0 0 0 1.58l8.04 8.04H1.11c-.62 0-1.11.5-1.11 1.12 0 .62.5 1.12 1.11 1.12h26.55l-8.04 8.04a1.14 1.14 0 0 0 0 1.58 1.1 1.1 0 0 0 1.58 0l9.96-9.95a1.1 1.1 0 0 0 0-1.57L21.2 5.01z" fill="white"></path>
          </svg>
        </Arrow>
        <CompanyIcon image={icon} />
        <TextCol>
          <Title>
            {title}
          </Title>
          <Text>
            {text}
          </Text>
          <Company>
            {p('chrome_example')}
          </Company>
        </TextCol>
      </Content>
      <Footer>
        {buttons.map(({ text }) => text && (
          <Button>
            {text}
          </Button>
        ))}
      </Footer>
    </Container>
  );
}

export default WindowsPushPreview;


