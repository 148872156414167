import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  RadioButton,
  RadioGroup,
  Input,
  WithPermissions,
  SearchSelect
} from '@components';
import { Row } from "../NodeSettingsModal/components/PushDiscoundNodeSettings/styled";
import { useTranslation, useErrors } from '@hooks';
import { testId } from '@utils';
import { UserPermissions } from '@constants';

import { Container, Title } from './styled';

const durationUnitOptions = [
  {
    value: 'hours',
    label: 'labels.hours',
  },
  {
    value: 'days',
    label: 'labels.days',
  },
];

const defaultParticipation = {
  type: 2,
};

const ParticipationSettingsModal = ({ opened, onConfirm, onClose, workflowParticipation = null, disabled = false }) => {
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [participation, setParticipation] = useState("1");
  const errors = useErrors(['type', 'value', 'error']);
  const { t, p } = useTranslation('workflow_page');

  useEffect(() => {
      setParticipation(workflowParticipation || defaultParticipation);
  }, [workflowParticipation]);


  useEffect(() => {
    setDisabledEdit(disabled)
  }, [disabled]);


  const handleClose = () => {
    setParticipation(defaultParticipation);
    errors.setError('error', '');
    errors.setError('value', '');
    errors.setError('type', '');
    onClose();
  };

  const handleSave = () => {
    const e = [
      ['value', +participation.duration_value < 0 && p('duration_positive')],
      ['type', !participation.duration_type && p('choose_type')],
      ['value', !participation.duration_value && p('choose_a_value')],
    ];

    if (participation.type === '2' && e.filter(([_, e]) => !!e).length) {
      return e.filter(([_, e]) => !!e).forEach(([k, v]) => errors.setError(k, v));
    }

    onConfirm(participation);
  };


  const handleChangeParticipation = (type) => {
    setParticipation(p => ({
      type: +type,
      duration_value: +type === 1 ? p.duration_value : null,
      duration_type: +type === 1 ? p.duration_type : null,
      times: +type !== 2 ? 1 : null,
    }))
  };

  const handleValueChange = ({ target: { value }}) => {
    errors.setError('value', '');

    if (errors.errors['error'] && +value > 0) {
      errors.setError('error', '');
    }

    setParticipation(p => ({
      ...p,
      duration_value: +value,
    }));
  };

  const handleUnitTypeChange = (type) => {
    errors.setError('type', '');
    setParticipation(p => ({
      ...p,
      duration_type: type,
    }));
  };

  return (
    <Modal
      title={p('participation_settings')}
      opened={opened}
      bigger
      contentStyles={{ paddingTop: '25px' }}
      onClose={handleClose}
      centerFooter={disabledEdit}
      actions={disabledEdit ? (
          <>
            <Title>{p('participation_title')}</Title>
          </>
      ) :(
        <>
          <Button {...testId('participation-settings-modal-cancel')()} onClick={handleClose} appearance="secondary" width={160}>{t('actions.cancel')}</Button>
          <WithPermissions name={UserPermissions.UPDATE_WORKFLOWS}>
            <Button {...testId('participation-settings-modal-save')()} onClick={handleSave} width={160}>{t('actions.save')}</Button>
          </WithPermissions>
        </>
      )}
    >
      <Container>

        <RadioGroup disabled={disabledEdit} value={String(participation.type)} onChange={handleChangeParticipation}>
          <RadioButton
            {...testId('participation-settings-modal-any-time')()}
            style={{ marginBottom: '18px' }}
            name="2"
            title={p('part_any')}
          />
          <RadioButton
            {...testId('participation-settings-modal-after')()}
            style={{ marginBottom: '18px' }}
            name="3"
            title={p('part_after')}
          />

          <Row style={{ marginBottom: '18px' }}>
            <Input
              {...testId('participation-settings-modal-quantity')()}
              min={0}
              key={workflowParticipation}
              disabled={String(participation.type) !== "3" || disabledEdit}
              value={participation.duration_value}
              type="number"
              wrapperStyles={{ marginLeft: '10px', width: '140px' }}
              onChange={handleValueChange}
              onFocus={() => {handleChangeParticipation(3)}}
              error={errors.errors['value']}
            />
            <SearchSelect
              {...testId('participation-settings-modal-period')()}
              disabled={String(participation.type) !== "3" || disabledEdit }
              wrapperStyles={{ width: '100%', marginLeft: '10px' }}
              style={{ width: '160px' }}
              options={durationUnitOptions}
              error={errors.errors['error'] || errors.errors['type']}
              onChange={handleUnitTypeChange}
              value={participation.duration_type}
            />
          </Row>
          <RadioButton
            {...testId('participation-settings-modal-once')()}
            style={{ marginBottom: '18px' }}
            name="1"
            title={p('part_once')}
          />
        </RadioGroup>
      </Container>
    </Modal>
  );
};

export default ParticipationSettingsModal;
