import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
`;

export const Option = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  transition: background-color 290ms ease;
  background: #fff;
  width: 100%;

  //&:hover {
  //  background: #F0F2F6;
  //}
`;
