import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';

import { Button, Modal } from '@components';

import { Container, Header, HeaderItem, LinkItem, Link, Status, SwitchWrapper } from './styled';
import {useDispatch} from "react-redux";
import { loadTemplatesLinks } from "@store/actions/creators";
import { useErrors, useTranslation } from "@hooks";

const EmailTemplateLinksModal = ({ opened, onClose, onSave, links, template, onTrackingChange }) => {
  const { p, t } = useTranslation('templates');
  const [validatedLinks, setValidatedLinks] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const errors = useErrors();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTemplatesLinks({
      links,
      onSuccess: (data) => {
        setValidatedLinks(data.validatedLinks)
      },
      onError: errors.handleRequestError,
    }));
  }, [])

  useEffect(() => {
    let isMounted = true;

    const validate = async () => {
      setLoading(true);

      if (!isMounted) {
        return;
      }

      dispatch(loadTemplatesLinks({
        links: links.map(({ url }) => url),
        onSuccess: (data) => {
          const validated = data.validatedLinks;
          const linksMap = validated.reduce((acc, { url, status }) => ({ ...acc, [url]: status }), {});
          setValidatedLinks(links.map((link) => ({ ...link, status: linksMap[link.url] })));
          setError(false);
        },
        onError: (e) => {
          errors.handleRequestError(e);
          setError(true);
        }
      }))

      setLoading(false);
    };

    if (opened && links.length) {
      validate();
    }

    return () => {
      isMounted = false;
    };
  }, [opened, (links || []).join('.')]);

  const disabled = validatedLinks.length ? validatedLinks.filter(vf => !vf.status && vf.track).length : true;

  return (
    <Modal
      appearance="default"
      title={p('links_checking')}
      opened={opened}
      bigger
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} appearance="secondary" width={140}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={onSave} appearance="default" width={140} disabled={isError || disabled}>
            {isLoading ? `${p('validating')}...` : t('actions.save')}
          </Button>
        </>
      }
    >
      <Container>
        <Header>
          <HeaderItem width={60}>{p('link')}</HeaderItem>
          <HeaderItem width={25} withPadding>
            {t('labels.status')}
          </HeaderItem>
          <HeaderItem width={15} alignEnd>
            {p('tracking')}
          </HeaderItem>
        </Header>
        {validatedLinks.map((link) => (
          <LinkItem key={link.url}>
            <Link>{link.url}</Link>
            <Status isSuccess={link.status}>{link.status ? p('successful') : p('unsuccessful')}</Status>
            <SwitchWrapper>
              <Switch
                defaultChecked={link.track}
                checked={link.track}
                onChange={() => onTrackingChange(link.url)}
                style={{ backgroundColor: link.track ? '#19B645' : '#CCC' }}
              />
            </SwitchWrapper>
          </LinkItem>
        ))}
      </Container>
    </Modal>
  );
};

export default EmailTemplateLinksModal;
