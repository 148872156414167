import {useDispatch, useSelector} from "react-redux";

import {
    expressionClicked,
    expressionResetType
} from '@store/actions/creators';

import {NODE_TYPE_LINEAR} from "../../constants";
import AddFunction from "../AddFunction";

const Linear = (props) => {

    const dispatch = useDispatch();

    let styles = {position:"relative",margin: "20px"};

    if(!props.expression.is_root) {
        styles = {...styles, ...{border: "1px solid red", borderRadius:"30px", padding:"5px 15px"}}
    }

    return <span key={props.expression.id}
                 style={styles}
                 onClick={(e) => {dispatch(expressionClicked(props.expression)); e.stopPropagation()}}
    >
         <i style={{color:"red",scale:1.5,right:"-10%", top:"-25%",position:"absolute", "width":"10px", "heilght":"10px"  }} onClick={(e) => {dispatch(expressionResetType(props.expression)); e.stopPropagation()}}>&#10006;</i>
        {props.expression.value}
        <AddFunction key={"addFunction"+props.expression.id} expression={props.expression}/>
    </span>

};

export default Linear;