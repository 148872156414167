import React from 'react';

import { Container, Line, InfoContainer, Title, Value, AdditionalLine, ValueContainer, CopyButton } from './styled';
import { CopyHandle } from '@components';
import { tryMongoDateObject, moment } from '@utils';
import { useTranslation } from '@hooks';

const stringify = (v) => {
  if (v?.['$undefined']) {
    return 'Unknown';
  }

  return !v ? JSON.stringify(v) : String(tryMongoDateObject(v, m => moment(m).format('YYYY-MM-DD HH:mm:ss')));
};

const DetailsTree = ({ label, value, isArray, isArrayChild, isSecond, level = 0, isFirst, isLast, onCopy, path, resolveCopyData }) => {
  const nesting = typeof value === 'object' && value !== null && !value['$date'];
  const { p, t } = useTranslation('');

  return (
    <Container level={level}>
      {level > 0 && (
        <Line
          isArray={isArray}
          shorter={isArrayChild && isSecond}
          invert={isArrayChild && isFirst}
          isFirst={isFirst}
          level={level}
          straight={isArrayChild && !isFirst && !isLast}
          nesting={nesting}
        />
      )}
      <InfoContainer nesting={nesting} level={level}>
        {(level > 0 && nesting && !isLast) && <AdditionalLine isArray={isArray} nesting={nesting} level={level}/>}
        {(label && !isArray) && <Title>{label}</Title>}
        {nesting ? (
          Object.keys(value).map((key, index, arr) => (
            <DetailsTree
              isFirst={index === 0}
              key={key}
              label={key}
              isArray={Array.isArray(value)}
              isArrayChild={isArray}
              value={value[key]}
              path={`${path ? '.' : ''}${key}`}
              onCopy={onCopy}
              resolveCopyData={resolveCopyData}
              level={level + 1}
              isSecond={index === 1}
              isLast={index === arr.length - 1}
            />
          ))
        ) : (
          <ValueContainer>
            <Value>{stringify(value)}</Value>
            {onCopy && (
              <CopyButton onClick={() => { onCopy(path) }}>
                {p('templates.events_show_value_copy')}
              </CopyButton>
            )}
            {resolveCopyData && (
              <CopyHandle data={resolveCopyData(path)}>
                <CopyButton>{p('templates.events_show_value_copy')}</CopyButton>
              </CopyHandle>
            )}
          </ValueContainer>
        )}
      </InfoContainer>
    </Container>
  );
};

export default DetailsTree;
