import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { ActionButton, DateCell, Icon, Link, SortableTitle, Table, WithPermissions } from '@components';
import {
  facebookHistoryLoadingSelector,
  facebookHistorySelector,
} from '@store/selectors';
import { StatusCell } from '../StatusCell';
import {
  FacebookAudiencesStatuses,
  FacebookAudiencesStatusLabels,
  Paths,
  UserPermissions,
} from '@constants';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { Dropdown, Tooltip } from 'antd';
import {useModalState, useTranslation} from '@hooks';
import ViewMappingModal from "./components/ViewMappingModal";
import { extract, tryHumanizeResponse } from '@utils';
import { IdType, Overlay } from '@components/lib/styled';
const resolveStatus = status => FacebookAudiencesStatusLabels[status];

const resolveColor = status => ({
  [FacebookAudiencesStatuses.SUCCESS]: '#24993E',
  [FacebookAudiencesStatuses.FAILED]: '#C8402F',
})[status];

const FacebookTable = ({ keyExtractor, cols, ordering }) => {
  const facebook = useSelector(facebookHistorySelector);
  const loading = useSelector(facebookHistoryLoadingSelector);
  const sowMappingModal = useModalState();
  const [selectedAudience, selectAudience] = useState({});
  const { t, p } = useTranslation('notification_history');

  const handleShowMapping = (data) => {
    selectAudience(data);
    sowMappingModal.open();
  }

  const resolveAction = (ak) => ak === 'add' ? t('workflow_page.add_to_audience') : (ak === 'remove' ? t('workflow_page.remove_from_audience') : t('workflow_page.remove_from_all_audiences'));

  const createFacebookTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      className: `notification-history-cell-date`,
      key: 'created_at',
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle>{t('labels.action')}</SortableTitle>,
      dataIndex: 'action_key',
      className: `notification-history-cell-action`,
      key: 'action_key',
      render: (key) => <div>{resolveAction(key)}</div>
    },
    {
      title: () => <SortableTitle>{t('labels.audience_name')}</SortableTitle>,
      dataIndex: 'audience_name',
      className: `notification-history-cell-audience`,
      key: 'audience_name',
    },
    {
      title: () => <SortableTitle>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)} />
      }
    },
    {
      title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
      dataIndex: 'response',
      key: 'response',
      className: `notification-history-cell-response`,
      render: (r, { s }) => {
        if (!r) {
          return null;
        }

        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                <pre>
                  {tryHumanizeResponse(r)}
                </pre>
              </Overlay>
            )}
          >
            <div>
              <Icon color={resolveColor(s)} name="Info-icon" size={16} />
            </div>
          </Dropdown>
        )
      },
    },
    {
      title: () => <SortableTitle right={true}>{t('labels.actions')}</SortableTitle>,
      key: 'actions',
      fixed: 'right',
      className: `notification-history-cell-actions`,
      render: (a, data) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            testId="facebook-action-show-mapping"
            tooltip={p('show_mapping')}
            onClick={() => handleShowMapping(data)}
            icon="Show-icon"
          />
        </div>
      )
    },
  ];

  const columns = createFacebookTableColumns(ordering);

  const facebookExtraColumns = [
    {
      title: () => <SortableTitle>{t('labels.updated_at')}</SortableTitle>,
      dataIndex: 'ut',
      className: `notification-history-cell-updated_at`,
      key: 'updated_at',
      render: (date) => <DateCell date={date} />
    },
  ]

  facebookExtraColumns.forEach((args) => cols.indexOf(args.key) !== -1 && columns.push(args));

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_FACEBOOK} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={facebook.data?.map?.(extract('id')).join()}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          columns={columns}
          dataSource={facebook.data}
          rowKey={keyExtractor}
        />
      </WithPermissions>

      <ViewMappingModal
        opened={sowMappingModal.opened}
        onClose={sowMappingModal.close}
        mapping={selectedAudience?.mapping_fields}
      />
    </>
  )
}

export default FacebookTable;

