import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { Popover } from '@components/layout/Sidebar/components/Popover';
import { Icon, Text, WithPermissions } from '@components';
import { Colors } from '@res/theme';
import { AccordionArrow } from '@components/layout/Sidebar/components/AccordionArrow';
import {
  Accordion,
  Container,
  AccordionRow,
  Col,
  IconContainer,
  Row,
  Title,
  AccordionContainer,
} from './styled';
import { useTranslation, useModalState, useOnboarding, useNavigate } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { hintModeSelector, onboardingSelector } from '@store/selectors';
import { sentOnboardParams, setHintMode, updateOnboarding } from '@store/actions/creators';
import { Switch } from '../../../../controls';
import { WithOnboarding } from '@components';
import { OnboardingHighlight } from '../../../../lib';
import { Paths, TextVariants } from '@constants';
import { ConfirmationModal } from "@components";

const SidebarSettings = ({ opened, onClose, pages, onToggle, sidebarOpened }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { p, t } = useTranslation('sidebar');
  const active = location.pathname.indexOf('settings') !== -1 || location.pathname.indexOf('integrations') !== -1 || location.pathname.indexOf('workspaces') !== -1 || location.pathname.indexOf('account') !== -1;
  const [hovered, setHovered] = useState(false);
  const onboarding = useOnboarding('sidebar');
  const onboardingData = useSelector(onboardingSelector);
  const closeHintModal = useModalState();
  const dispatch = useDispatch();
  const hintMode = useSelector(hintModeSelector);

  const toggleHintMode = () => {
    if (hintMode) {
      closeHintModal.open();
      return;
    }

    enableHintMode();
  }

  const enableHintMode = () => {
    dispatch(setHintMode(true));
    dispatch(sentOnboardParams({ enabled: true }));
  }

  const getOnboardParams = (onboardPath = '') => {
    if (onboarding.enabled(3) && !onboardPath) {
      return { showHint: true, hintText: p('please_go_to_settings_tab') }
    }

    if (onboardPath === Paths.INTEGRATIONS && onboarding.enabled(4)) {
      return { showHint: true, hintText: p('please_go_tp_integrations') }
    }

    return { showHint: false, hintText: '' }
  }

  const { showHint, hintText } = getOnboardParams();

  const handleToggle = () => {
    if (showHint) {
      onboarding.nextStep();
    }
    onToggle();
  }

  const handleClick = (path) => () => {
    if (path === Paths.INTEGRATIONS && onboarding.enabled(4)) {
      dispatch(updateOnboarding(onboardingData.sidebar.key, { step: onboardingData.sidebar.stepToGo }));
      dispatch(updateOnboarding('sidebar', { key: '', step: null }));
    }

    navigate(path);
    onClose?.();
  }

  const handleCancelOnboarding = () => {
    dispatch(setHintMode(false));
    dispatch(sentOnboardParams({ enabled: false }));
    closeHintModal.close()
  }

  return (
   <Container>
     <AccordionContainer $opened={opened}>
       <WithOnboarding enabled={showHint && sidebarOpened} text={hintText} onboardKey="sidebar">
         <OnboardingHighlight left={-10} top={-5} bottom={-5} right={-40} background="transparent" sidebar on={showHint && sidebarOpened}>
       <Accordion $opened={opened}>
         {pages.map((({ name, path, permission }) => {
           const { showHint: subShowHint, hintText: subHintText } = getOnboardParams(path);
           return permission ? (
             <WithPermissions key={path} name={permission}>
               <WithOnboarding preventDefaultClose={true} placement="bottom" onboardKey="sidebar" enabled={subShowHint && sidebarOpened} text={subHintText}>
                 <OnboardingHighlight left={-30} right={-30} top={-10} bottom={-10} sidebar on={subShowHint && sidebarOpened} background="transparent">
                   <AccordionRow data-testid={path} $active={location.pathname === path} $accordionOpened={opened} key={path} onClick={handleClick(path)}>
                     {p(name)}
                   </AccordionRow>
                 </OnboardingHighlight>
               </WithOnboarding>
             </WithPermissions>
           ) : (
             <WithOnboarding key={path} preventDefaultClose={true} placement="bottom" onboardKey="sidebar" enabled={subShowHint && sidebarOpened} text={subHintText}>
               <OnboardingHighlight left={-15} right={-30} top={-5} bottom={-5} sidebar on={subShowHint && sidebarOpened} background="transparent">
                 <AccordionRow data-testid={path} $active={location.pathname === path} $accordionOpened={opened} key={path} onClick={handleClick(path)}>
                   {p(name)}
                 </AccordionRow>
               </OnboardingHighlight>
             </WithOnboarding>
           )
         }))}
         <AccordionRow $accordionOpened={opened}>
           <div style={{ display: 'flex' }}>
             <div>{t('labels.onboarding_assistant')}</div>
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <Switch
                 testId="sidebar-onboarding-toggle"
                 checked={hintMode === true}
                 defaultChecked={hintMode}
                 onChange={() => toggleHintMode(!hintMode)}
                 style={{ backgroundColor: hintMode ? '#19B645' : '#CCC', marginLeft: '8px', borderRadius: '9px', width: '26px', height: '14px' }}
               />
             </div>
           </div>
         </AccordionRow>
       </Accordion>
       <Row $active={active} $opened={opened} $hovered={hovered} style={{ alignItems: 'center' }}>
         {!opened ? (
           <Popover
             larger
             isSettings={true}
             setHovered={setHovered}
             overlayClassName="stt"
             title={p('settings')}
             hintMode={hintMode}
             setHintMode={toggleHintMode}
             pages={pages}
           >
             <IconContainer>
               <Icon size={16} color={active ? Colors.Sidebar.ICON_ACTIVE : (hovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT)} name="Settings"/>
             </IconContainer>
           </Popover>
         ) : (
           <IconContainer>
             <Icon size={16} color={active ? Colors.Sidebar.ICON_ACTIVE : (hovered ? Colors.Sidebar.ICON_HOVER : Colors.Sidebar.ICON_DEFAULT)} name="Settings"/>
           </IconContainer>
         )}
         <Col onClick={handleToggle}>
           <Title active={active}>
             <Text variant={TextVariants.MENU_1}>
               {p('settings')}
             </Text>
             {pages?.length && <AccordionArrow opened={opened} />}
           </Title>
         </Col>
       </Row>
         </OnboardingHighlight>
       </WithOnboarding>
     </AccordionContainer>

     <ConfirmationModal
       onClose={closeHintModal.close}
       opened={closeHintModal.opened}
       title={t('labels.onboarding_assistant')}
       appearance="default"
       content={(
         <>
           <div>
             {p('do_you_want_to_turn_off_onboarding')}
           </div>
           <div>
             {p('it_can_be_turned_any_time')}
           </div>
         </>
       )}
       onCancel={closeHintModal.close}
       onSubmitClick={handleCancelOnboarding}
       buttonsParams={{
         cancelAppearance: 'secondary',
         submitAppearance: 'default',
         cancelLabel: p('dont_turn_off'),
         submitLabel: p('turn_off'),
       }}
     />
   </Container>
  );
};

export default SidebarSettings;
