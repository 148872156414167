import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Line = styled.div`
  height: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 1px;
  flex: 1;
  background: #7C8C9E;
`;

export const Progress = styled.div`
  width: 1px;
  background: #fff;
  position: absolute;
  top: 0;
  transition: all 280ms ease;
`;

export const Circle = styled.div`
  border-radius: 50%;
  transition: 280ms ease all;
  z-index: 10;
  background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%);

  ${styledProp('$checked', css`
    width: 17px;
    height: 17px;
  `, css`
    & > svg {
      transition: 280ms ease all;
      opacity: 0;
    }
    ${styledProp('$curr', css`
    width: 25px;
    height: 25px;
    background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%);

  `, css `
    background: #7C8C9E;
    width: 9px;
    height: 9px;
  `)}
  `)}

  display: flex;
  justify-content: center;
  align-items: center;
`;
