import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { styledProp } from '@utils';

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 5px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 14px;
  & > * {
    width: 190px;
  }
`;

export const SectionContainer = styled.div`
  overflow-y: hidden;
  transition: all 280ms ease;
  ${styledProp('$opened', `
    max-height: 500px;
  `, `
    max-height: 42px;
  `)}
  
  ${styledProp('$onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 7px;
  `)}
`;

export const SectionTitleContainer = styled.div`
  padding: 14px 16px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: #909399;
  align-items: center;
  position: relative;
`;

export const SectionTitle = styled.div``;

export const Header = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 1px solid #DCDFE6;
  font-size: 14px;
  line-height: 100%;
  color: #303133;
  min-height: 36px;
`;

export const ExpandIconContainer = styled.div`
  color: #909399;
  cursor: pointer;
  margin-left: auto;
  ${styledProp('$opened', `
    transform: rotate(180deg);
  `, `
    transform: rotate(0deg);
  `)}
`;

export const IconContainer = styled.div`
  position: sticky;
  cursor: pointer;
  color: black;
  right: 0;
  background: #fff;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
