import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage'
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { ReducerKeys } from '@constants';

const migrations = {
  0: (state) => {
    return {
      ...state,
    }
  },
  24: (state) => {
    return {
      ...state,
      [ReducerKeys.DASHBOARD]: {
        ...state[ReducerKeys.DASHBOARD],
        analyticsEntities: {
          loading: false,
        },
      },
      [ReducerKeys.WORKFLOW]: {
        workers: {
          loading: false,
        },
        workflows: {
          loading: false,
        },
        program: {
          loading: true,
        }
      },
      [ReducerKeys.WORKSPACES]: {
        demo: {
          loading: false,
        }
      },
      [ReducerKeys.USER]: {
        ...state[ReducerKeys.USER],
        dashboard_analytics_options: {
          loading: false,
          ids: ['base.n', 'base.a', 'base.t'],
          type: 'base',
          range: {
            relative: 'today'
          }
        },
        analyticsEntities: {
          segments: [],
          funnels: [],
        },
      },
      [ReducerKeys.APP]: {
        ...state[ReducerKeys.APP],
        onboarding: {
          skipped: 0,
          contacts: {
            step: null,
            skipped: false,
            passed: false,
          },
          contactsImport: {
            step: null,
            skipped: false,
            passed: false,
          },
          templatesWebpush: {
            step: null,
            skipped: false,
            passed: false,
          },
          templatesSms: {
            step: null,
            skipped: false,
            passed: false,
          },
          integrations: {
            step: null,
            skipped: false,
            passed: false,
          },
          segments: {
            step: null,
            skipped: false,
            passed: false,
          },
          templates: {
            step: null,
            skipped: false,
            passed: false,
          },
          workflows: {
            step: null,
            skipped: false,
            passed: false,
          },
          workspaces: {
            step: null,
            skipped: false,
            passed: false,
          },
          users: {
            step: null,
            skipped: false,
            passed: false,
          },
          sidebar: {
            key: '',
            stepToGo: 0,
            step: 0,
            skipped: false,
            passed: false,
          }
        }
      },
      [ReducerKeys.INTEGRATIONS]: {
        ...state[ReducerKeys.INTEGRATIONS],
        facebookUserInfo: {

        },
      },
      [ReducerKeys.FIELDS]: {
        ...state[ReducerKeys.FIELDS],
        dynamic_variables: {
          loading: false,
          data: [],
        },
      },
      [ReducerKeys.ACCOUNT]: {
        ...state[ReducerKeys.ACCOUNT],
        metrics: {},
      },
      [ReducerKeys.CAMPAIGNS]: {
        campaignsCalendar: {
          loading: false,
        }
      },
      [ReducerKeys.SUBSCRIPTION_GROUPS]: {
        ...state[ReducerKeys.SUBSCRIPTION_GROUPS],
        counts: {
          loading: false
        }
      }
    }
  }
}

const persistConfig = {
  key: 'primary',
  version: 24,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
}

const finalReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const ownCompose = compose(applyMiddleware(sagaMiddleware));
export const store = createStore(finalReducer, composeWithDevTools(ownCompose));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
