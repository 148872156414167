import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CircleContainer = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #909399;
  box-sizing: border-box;
  border-radius: 42px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
    ${styledProp('disabled', `
  background: #909399;
  `, `
  background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%);
  `)}
  border-radius: 42px;
  transition: 280ms ease all;
  
  ${styledProp('selected', `
    opacity: 1;
    transform: scale(1);
  `, `
    opacity: 0;
    transform: scale(0.5);
  `)}
`;

export const Title = styled.div`
  ${styledProp('disabled', `
  color: #909399;
  `, `
  color: #303133;
  `)}
  font-size: 13px;
  line-height: 120%;
`;
