import styled from 'styled-components';
import { extract } from '@utils';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  height: 100vh;
  width: 100%;
  margin-right: 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 500px;
  flex-direction: column;
  padding-top: 20vh;
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 90.83%);
  align-items: flex-start;

  @media (max-width: 1920px) {
    min-width: 720px;
  }

  @media (max-width: 1440px) {
    min-width: 600px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 98px;
  padding-right: 98px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

export const Image = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center / cover no-repeat url(${extract('url')});
`;

export const Hint = styled.div`
  color: #7C8C9E;
  font-size: 15px;
  line-height: 117%;
  margin-top: 5px;
  font-family: Rubik-Light;
  font-weight: bold;
`;

export const Title = styled.div`
  font-size: 68px;
  line-height: 110%;
  font-family: Rubik-Light;
`;

export const GuideLink = styled.a`
  color: #109DFB;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 16px;
  line-height: 130%;
  cursor: pointer;
  position: absolute;
  font-family: Rubik-Light;
  font-weight: bold;
  bottom: 5vh;
  //width: 500px;
  //margin-left: auto;
  //margin-right: auto;
`;

export const Content = styled.div`
  font-size: 24px;
  margin-top: 50px;
  line-height: 117%;
`;

export const RetainlyLink = styled.div``;

export const Actions = styled.div``;

export const SubTitle = styled.div``;

export const GuidesLink = styled.div``;

