import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHotjar = () => {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'hotjar_connection';
    if (!~window.location.href.indexOf('stage') && !~window.location.href.indexOf('local')) {
      script.innerHTML = `
       (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2996848,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); 
      `;
      document.body.appendChild(script);
    }

    return () => {
      try {
        document.body.removeChild(script)
      } catch (e) {}
    };
  }, [location]);
};

export default useHotjar;
