import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  padding: 20px 20px 50px 20px;
  
  ${styledProp('$appearance', css`
    flex-direction: column;
    width: 100%;
    padding: 16px 10px 10px 10px;
  `)}
`;
