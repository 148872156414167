import React, { useState } from 'react';

import {
  CompanyRow,
  Container,
  TextCol,
  Button,
  Title,
  Text,
  Divider,
  Footer,
  HoveredContainer,
  Company,
  Image,
  TextRow,
  CompanyIcon,
  Options,
  CloseButton,
} from './styled.js';
import { useTranslation } from '@hooks';

const MacOSPushPreview = ({ opened, style = {}, title, icon, onToggle, image, text, company, buttons }) => {
  const { p, t } = useTranslation('templates');
  const [hovered, setHovered] = useState(false);
  return (
    <Container onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={style}>
      <CloseButton show={hovered}>
        <svg style={{ transform: 'scale(1.2)' }} width="8" height="8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.15 8.09a.125.125 0 010-.177l4.632-4.63a.75.75 0 00-1.062-1.061L8.089 6.85a.125.125 0 01-.177 0L3.28 2.22A.75.75 0 102.22 3.283l4.63 4.631a.125.125 0 010 .177l-4.63 4.632a.749.749 0 00.53 1.28.75.75 0 00.53-.22l4.632-4.631a.125.125 0 01.177 0l4.63 4.631a.75.75 0 001.062-1.06L9.15 8.09z" fill="#303133"></path>
        </svg>
      </CloseButton>
      <CompanyRow>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <svg style={{ marginRight: '6px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5197_307976)">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.98467 9.57509C6.31763 9.57401 5.67824 9.30851 5.20663 8.83679C4.73502 8.36507 4.46968 7.72562 4.46875 7.05859C4.46952 6.39135 4.73488 5.75166 5.20663 5.2798C5.67838 4.80794 6.31802 4.54243 6.98525 4.5415C7.65258 4.54228 8.29237 4.80772 8.76424 5.27959C9.23612 5.75147 9.50156 6.39125 9.50233 7.05859C9.50141 7.72582 9.2359 8.36545 8.76404 8.8372C8.29218 9.30896 7.65248 9.57431 6.98525 9.57509H6.98467Z" fill="#4285F4"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M8.04556 10.0224C7.52063 10.2129 6.9551 10.2633 6.40475 10.1686C5.85441 10.0739 5.33826 9.83732 4.90723 9.48227C4.44253 9.099 4.09521 8.59258 3.90506 8.02102L3.90389 8.01752L1.13014 3.21436C0.135435 4.7364 -0.221926 6.58814 0.134978 8.37102C0.310241 9.2717 0.662389 10.1287 1.17104 10.8924C1.6797 11.656 2.33476 12.3113 3.09831 12.8201C3.90001 13.359 4.80542 13.7246 5.75656 13.8934L8.04556 10.023V10.0224Z" fill="#4AAE48"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M13.2785 3.91249C12.7538 2.85692 11.9727 1.94985 11.0067 1.27433C10.0406 0.598801 8.92059 0.176407 7.74906 0.0458181C6.57753 -0.0847705 5.39197 0.0806194 4.30091 0.526845C3.20985 0.97307 2.24817 1.68587 1.50391 2.59999L3.84657 6.65765C3.94299 5.90637 4.30723 5.21509 4.87239 4.71079C5.43756 4.20649 6.1657 3.92304 6.92307 3.91249H13.2785Z" fill="#EA3939"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M6.95736 14.0003C8.71319 14.0003 10.3938 13.3417 11.6882 12.1464C12.9876 10.9497 13.7833 9.30378 13.9142 7.54217C13.9895 6.52229 13.8414 5.49825 13.4802 4.5415H8.89753C9.27931 4.84351 9.58745 5.22835 9.79865 5.66694C10.0099 6.10552 10.1186 6.58638 10.1167 7.07317C10.1121 7.78888 9.86949 8.48272 9.42719 9.04542L6.50586 13.9863C6.65636 13.9956 6.80803 14.0003 6.95736 14.0003V14.0003Z" fill="#FED14B"/>
            </g>
            <defs>
              <clipPath id="clip0_5197_307976">
                <rect width="14" height="14" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <Company>
            {p('google_chrome')}
          </Company>
        </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            { opened && (
              <HoveredContainer>
                <svg style={{ transform: 'scale(1.3)' }} width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.6" cx="1" cy="1" r="1" fill="#303133"/>
                  <circle opacity="0.6" cx="4" cy="1" r="1" fill="#303133"/>
                  <circle opacity="0.6" cx="7" cy="1" r="1" fill="#303133"/>
                </svg>
              </HoveredContainer>
            )}
            { (hovered || opened) && (<HoveredContainer onClick={onToggle}>
              <svg style={{ transform: opened ? 'scale(1.3) rotate(90deg)' : 'scale(1.3)', transition: 'transform 0.2s ease 0s' }} width="8" height="8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M11.75 8a1.15 1.15 0 01-.39.864L5.784 13.77a.924.924 0 01-1.22-1.386l4.876-4.29a.125.125 0 000-.188l-4.876-4.29a.924.924 0 011.22-1.386l5.574 4.904A1.156 1.156 0 0111.75 8z" fill="#303133"></path>
                </g>
              </svg>
            </HoveredContainer>) }
          </div>
        { !opened && !hovered && <div style={{ color: '#838383', fontSize: 10 }}>{p('now')}</div> }
      </CompanyRow>
      { image && <Image image={image} $opened={opened} /> }
      <TextRow>
        <TextCol>
          <Title>{title}</Title>
          <Text>{p('example_com')}</Text>
          <Text>{text}</Text>
        </TextCol>
        {!opened && !hovered && <CompanyIcon image={icon} />}
        { !opened && hovered && (
          <div style={{ height: '34px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignSelf: 'flex-end' }}>
            <Options>
              <div style={{ fontSize: 12 }}>Options</div>
              <svg style={{ transform: 'scale(1.3) rotate(90deg)', marginLeft: '4px' }} width="8" height="8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path d="M11.75 8a1.15 1.15 0 01-.39.864L5.784 13.77a.924.924 0 01-1.22-1.386l4.876-4.29a.125.125 0 000-.188l-4.876-4.29a.924.924 0 011.22-1.386l5.574 4.904A1.156 1.156 0 0111.75 8z" fill="#303133"></path>
                </g>
              </svg>
            </Options>
          </div>
        )}
      </TextRow>
      <Footer $opened={opened}>
        {buttons.map(({ text }) => text && (
          <>
            <Divider/>
            <Button>
              {text}
            </Button>
          </>
        ))}
      </Footer>
    </Container>
  );
}

export default MacOSPushPreview;


