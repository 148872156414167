import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  height: 150px;
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  background: #fff;
  cursor: pointer;

  transition: all 290ms ease;
  
  ${styledProp('$selected', css`
    //z-index: 99;
    //width: calc(100% + 2px);
    //height: 152px;
  `, css`
    
  `)}
  
  &:hover {
    z-index: 100;
    box-shadow: 1px 1px 10px 6px rgba(193, 206, 252, 0.3);
    border-radius: 8px;
    transform: scale(1.05);
  }
  
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 290ms ease;
  position: relative;

  ${styledProp('$selected', css`
    border: 2px solid #109DFB;
  `, css`
    border: 2px solid transparent;
  `)}
`;

export const SelectedIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Name = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  user-select: none;
`;

export const IconContainer = styled.div`
`;
