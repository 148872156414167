import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #DCDFE6;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;
