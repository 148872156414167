import styled from 'styled-components';
import { Text } from '@components';
import { TextVariants } from '@constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px 20px 16px 20px;
`;

export const Header = styled.div`
  margin-bottom: 34px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const Title = styled(Text).attrs({ variant: TextVariants.PARAGRAPH_2, width: 35 })`
  color: #303133;
`;

export const Total = styled(Text).attrs({ variant: TextVariants.MENU_1, skipSkeleton: true })`
  color: #303133;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoPercentage = styled(Text).attrs({ variant: TextVariants.MENU_1, skipSkeleton: true })`
  color: #24993E;
  margin-right: 4px;
`;

export const InfoCount = styled(Text).attrs({ variant: TextVariants.MENU_1, skipSkeleton: true })`
  color: #909399;
`;
