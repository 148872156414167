import React from 'react';

import { Rect } from './styled'

const rectConfig = [
  { x: 0, y: 0, rx: 10, ry: 10, width: 300, height: 150, id: 1 },
  { x: 350, y: 0, rx: 10, ry: 10, width: 300, height: 150, id: 2 },
  { x: 700, y: 0, rx: 10, ry: 10, width: 300, height: 150, id: 3 },
  { x: 1050, y: 0, rx: 10, ry: 10, width: 300, height: 150, id: 4 },
  { x: 0, y: 200, rx: 10, ry: 10, width: 300, height: 150, id: 5 },
  { x: 350, y: 200, rx: 10, ry: 10, width: 300, height: 150, id: 6 },
  { x: 700, y: 200, rx: 10, ry: 10, width: 300, height: 150, id: 7 },
  { x: 1050, y: 200, rx: 10, ry: 10, width: 300, height: 150, id: 8 },
  { x: 0, y: 400, rx: 10, ry: 10, width: 300, height: 150, id: 9 },
  { x: 350, y: 400, rx: 10, ry: 10, width: 300, height: 150, id: 10 },
  { x: 700, y: 400, rx: 10, ry: 10, width: 300, height: 150, id: 11 },
  { x: 1050, y: 400, rx: 10, ry: 10, width: 300, height: 150, id: 12 },
]

const LargeTemplatesLoader = (props) => {
  return (
    <svg
      style={{
        width: '100%',
        height: '100%',
        padding: 20,
      }}
    >
      {rectConfig.map((i, index) => (
        <Rect key={index} {...i} style={{ animationDelay: `${index * 20}ms`}} />
      ))}
    </svg>
  );
}

export default LargeTemplatesLoader;


