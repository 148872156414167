import {useDispatch, useSelector} from "react-redux";

import {
    expressionClicked,
    expressionResetType
} from '@store/actions/creators';

import {
    NODE_TYPE_CONSTANT,
    NODE_TYPE_FUNCTION,
    NODE_TYPE_LINEAR,
    SEPARATED_FUNCTIONS,
} from "../../constants";
import Expression from "./../../components/Expression";
import {v4 as uuidv4} from "uuid";
import React from "react";
import AddFunction from "../AddFunction";
import {
    FUNCTIONS,
    ABS,
    CEIL,
    EXP,
    POW,
    SQRT,
    FLOOR,
    MAX,
    MIN,
    MOD,
    ROUND,
    LOG,
    LOG1P,
    LOG2,
    LOG10,
    CLAMP,
    ADD,
    DIVIDE,
    MULTIPLY,
    SUBTRACT,
    TO_FIXED,
    COND,
    EQUAL,
    NOT_EQUAL,
    GREATER,
    LESS,
    GREATER_OR_EQUAL,
    LESS_OR_EQUAL,
    IF_NULL,
    CONCAT,
    TO_LOWER_CASE,
    TO_UPPER_CASE,
    TO_NUMBER,
    TO_STRING,
    TO_TIMESTAMP,
    ADD_DAY,
    ADD_YEAR,
    ADD_WEEK,
    ADD_MONTH,
    ADD_HOUR,
    WEEK_OF_YEAR,
    WEEK_OF_MONTH,
    MONTH,
    DAY_OF_MONTH,
    DAY_OF_WEEK,
    DAY_OF_YEAR,
    NOW,
} from '../../constants';
import {
    Abs, Add, AddDay, AddHour, AddMonth, AddWeek, AddYear,
    Ceil,
    Clamp, Concat, Cond, DayOfMonth, DayOfWeek, DayOfYear, Divide, Equal,
    Exp,
    Floor, Greater, GreaterOrEqual, IfNull, Less, LessOrEqual,
    Log,
    Log10,
    Log1p,
    Log2,
    Max,
    Min,
    Mod, Month, Multiply, NotEqual, Now,
    Pow,
    Round,
    Sqrt, Subtract, ToFixed, ToLowerCase, ToNumber, ToString, ToTimestamp, ToUpperCase, WeekOfMonth, WeekOfYear
} from "../FunctionPresenter";

const Function = (props) => {

    const dispatch = useDispatch();

    const renderFunctionPresenter = (expression) => {
        console.log(expression.value)
        switch (expression.value){
            case ABS: return <Abs expression={expression} />;
            case CEIL: return <Ceil expression={expression} />;
            case EXP: return <Exp expression={expression} />;
            case POW: return <Pow expression={expression} />;
            case SQRT: return <Sqrt expression={expression} />;
            case FLOOR: return <Floor expression={expression} />;
            case MAX: return <Max expression={expression} />
            case MIN: return <Min expression={expression} />;
            case MOD: return <Mod expression={expression} />;
            case ROUND: return <Round expression={expression} />;
            case LOG: return <Log expression={expression} />
            case LOG1P: return <Log1p expression={expression} />
            case LOG2: return <Log2 expression={expression} />
            case LOG10: return <Log10 expression={expression} />
            case CLAMP: return <Clamp expression={expression} />
            case ADD: return <Add expression={expression} />
            case DIVIDE: return <Divide expression={expression} />
            case MULTIPLY: return <Multiply expression={expression} />
            case SUBTRACT: return <Subtract expression={expression} />
            case TO_FIXED: return <ToFixed expression={expression} />
            case COND: return <Cond expression={expression} />
            case EQUAL: return <Equal expression={expression} />
            case NOT_EQUAL: return <NotEqual expression={expression} />
            case GREATER: return <Greater expression={expression} />
            case LESS: return <Less expression={expression} />
            case GREATER_OR_EQUAL: return <GreaterOrEqual expression={expression} />
            case LESS_OR_EQUAL: return <LessOrEqual expression={expression} />
            case IF_NULL: return <IfNull expression={expression} />
            case CONCAT: return <Concat expression={expression} />
            case TO_LOWER_CASE: return <ToLowerCase expression={expression} />
            case TO_UPPER_CASE: return <ToUpperCase expression={expression} />
            case TO_NUMBER: return <ToNumber expression={expression} />
            case TO_STRING: return <ToString expression={expression} />
            case TO_TIMESTAMP: return <ToTimestamp expression={expression} />
            case ADD_DAY: return <AddDay expression={expression} />
            case ADD_YEAR: return <AddYear expression={expression} />
            case ADD_WEEK: return <AddWeek expression={expression} />
            case ADD_MONTH: return <AddMonth expression={expression} />
            case ADD_HOUR: return <AddHour expression={expression} />
            case WEEK_OF_YEAR: return <WeekOfYear expression={expression} />
            case WEEK_OF_MONTH: return <WeekOfMonth expression={expression} />
            case MONTH: return <Month expression={expression} />
            case DAY_OF_MONTH: return <DayOfMonth expression={expression} />
            case DAY_OF_WEEK: return <DayOfWeek expression={expression} />
            case DAY_OF_YEAR: return <DayOfYear expression={expression} />
            case NOW: return <Now expression={expression} />
            default: return "";
        }
    }

    // render base element
    // check children exists
    // render Expression as children recursively

            let styles = {position:"relative",margin: "20px"};

            if(!props.expression.is_root) {
                styles = {...styles, ...{border: "1px solid red", borderRadius:"30px", padding:"5px 15px"}}
            }

            const placeFunctionAsPrefix = !SEPARATED_FUNCTIONS.includes(props.expression.value)

            return <span key={props.expression.id}
                         style={styles}

                         onClick={(e) => {dispatch(expressionClicked(props.expression)); e.stopPropagation()}}
            >
        <i style={{color:"red",scale:1.5,right:"-10%", top:"-25%",position:"absolute", "width":"10px", "heilght":"10px"  }} onClick={(e) => {dispatch(expressionResetType(props.expression)); e.stopPropagation()}}>&#10006;</i>
                {placeFunctionAsPrefix ? renderFunctionPresenter(props.expression) : ''}
                {props.expression.children !== undefined ? props.expression.children.map((child, index) => {
                    return (<span><Expression key={"expression"+child.id} expression={child} for={props.for}/>
                        {props.expression.children.length !== index+1 && !placeFunctionAsPrefix ? renderFunctionPresenter(props.expression) : ''}
                        <AddFunction key={"addFunction"+ props.expression.id} expression={props.expression}/>
                    </span> )
                }) : ''}
    </span>

};

export default Function;