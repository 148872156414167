import React from 'react';

import { Container } from './styled.js';

const MacOSBackground = () => {
  return (
    <Container>
      <svg width="380" height="20" viewBox="0 0 380 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="1" width="380" height="18" fill="#F8F9FA"/>
        <path d="M295.978 16.7613C296.867 15.8744 297.573 14.8205 298.054 13.6602C298.534 12.4999 298.781 11.256 298.779 10C298.781 8.74405 298.534 7.50016 298.054 6.33984C297.573 5.17952 296.867 4.12564 295.978 3.23877L295.182 4.03414C295.966 4.81668 296.589 5.74659 297.013 6.77042C297.437 7.79424 297.655 8.89181 297.653 10C297.653 12.3299 296.708 14.4393 295.182 15.9659L295.978 16.7613V16.7613Z" fill="#303133"/>
        <path d="M294.387 15.1706C295.067 14.4923 295.607 13.6864 295.974 12.7991C296.342 11.9118 296.53 10.9605 296.529 10.0001C296.53 9.03964 296.342 8.08842 295.974 7.2011C295.607 6.31377 295.067 5.50783 294.387 4.82959L293.592 5.62496C294.167 6.19888 294.624 6.88084 294.935 7.63167C295.246 8.3825 295.405 9.18739 295.404 10.0001C295.405 10.8129 295.246 11.618 294.935 12.369C294.624 13.12 294.168 13.8022 293.593 14.3763L294.387 15.1706Z" fill="#303133"/>
        <path d="M294.278 10.0002C294.279 10.6651 294.148 11.3237 293.894 11.938C293.64 12.5524 293.266 13.1103 292.795 13.5799L292 12.7846C292.366 12.4193 292.657 11.9853 292.854 11.5074C293.052 11.0296 293.154 10.5174 293.153 10.0002C293.153 8.91343 292.712 7.92905 292 7.2158L292.795 6.42043C293.266 6.89001 293.64 7.448 293.894 8.06233C294.148 8.67667 294.279 9.33523 294.278 10.0002ZM290.875 5.50018C290.875 5.39433 290.845 5.29068 290.788 5.20112C290.732 5.11157 290.651 5.03975 290.556 4.99393C290.461 4.9481 290.354 4.93013 290.249 4.94207C290.144 4.95401 290.044 4.99538 289.962 5.06143L287.303 7.18768H284.688C284.538 7.18768 284.395 7.24694 284.29 7.35243C284.184 7.45792 284.125 7.60099 284.125 7.75018V12.2502C284.125 12.3994 284.184 12.5424 284.29 12.6479C284.395 12.7534 284.538 12.8127 284.688 12.8127H287.303L289.962 14.9389C290.044 15.005 290.144 15.0463 290.249 15.0583C290.354 15.0702 290.461 15.0522 290.556 15.0064C290.651 14.9606 290.732 14.8888 290.788 14.7992C290.845 14.7097 290.875 14.606 290.875 14.5002V5.50018Z" fill="#303133"/>
        <path d="M309.99 13.9403H308.995V7.2483L307 7.99186V7.08005L309.835 6H309.99V13.9403Z" fill="#303133"/>
        <path d="M315.924 13.9403H314.928V7.2483L312.933 7.99186V7.08005L315.768 6H315.924V13.9403Z" fill="#303133"/>
        <path d="M318.673 13.4138C318.673 13.2402 318.723 13.0954 318.823 12.9796C318.927 12.8639 319.08 12.806 319.283 12.806C319.487 12.806 319.64 12.8639 319.743 12.9796C319.85 13.0954 319.904 13.2402 319.904 13.4138C319.904 13.5803 319.85 13.7196 319.743 13.8318C319.64 13.9439 319.487 14 319.283 14C319.08 14 318.927 13.9439 318.823 13.8318C318.723 13.7196 318.673 13.5803 318.673 13.4138ZM318.679 8.62144C318.679 8.44776 318.729 8.30303 318.829 8.18725C318.932 8.07146 319.085 8.01357 319.289 8.01357C319.492 8.01357 319.645 8.07146 319.749 8.18725C319.856 8.30303 319.909 8.44776 319.909 8.62144C319.909 8.78788 319.856 8.92718 319.749 9.03935C319.645 9.15152 319.492 9.2076 319.289 9.2076C319.085 9.2076 318.932 9.15152 318.829 9.03935C318.729 8.92718 318.679 8.78788 318.679 8.62144Z" fill="#303133"/>
        <path d="M324.291 13.9403H323.296V7.2483L321.3 7.99186V7.08005L324.136 6H324.291V13.9403Z" fill="#303133"/>
        <path d="M332 6.60244L328.774 13.9403H327.736L330.951 6.86296H326.736V6.03799H332V6.60244Z" fill="#303133"/>
        <path d="M270.709 11.9072C269.721 11.0477 268.431 10.5273 267.018 10.5273C265.605 10.5273 264.314 11.0477 263.325 11.909C263.297 11.9336 263.281 11.9679 263.278 12.0046C263.276 12.0413 263.287 12.0776 263.311 12.1059L263.944 12.86C263.995 12.9197 264.084 12.9268 264.144 12.8758C264.91 12.2008 265.916 11.793 267.018 11.793C268.12 11.793 269.126 12.2008 269.892 12.874C269.952 12.925 270.041 12.918 270.092 12.8582L270.725 12.1041C270.774 12.0461 270.767 11.9582 270.709 11.9072ZM272.773 9.44453C271.214 8.14551 269.208 7.36328 267.018 7.36328C264.828 7.36328 262.822 8.14551 261.261 9.44453C261.233 9.46862 261.215 9.50301 261.211 9.54023C261.208 9.57744 261.22 9.61444 261.244 9.64316L261.876 10.3973C261.926 10.457 262.015 10.4641 262.073 10.4148C263.414 9.30039 265.139 8.62891 267.018 8.62891C268.897 8.62891 270.621 9.30039 271.961 10.4148C272.021 10.4641 272.109 10.457 272.158 10.3973L272.791 9.64316C272.84 9.5834 272.833 9.49375 272.773 9.44453V9.44453ZM274.824 7.00117C272.69 5.25039 269.959 4.19922 266.983 4.19922C264.026 4.19922 261.312 5.23633 259.183 6.96602C259.169 6.97777 259.157 6.99232 259.148 7.00883C259.139 7.02534 259.133 7.04346 259.132 7.06214C259.13 7.08081 259.132 7.09965 259.137 7.11756C259.143 7.13547 259.152 7.15208 259.164 7.16641L259.797 7.92051C259.846 7.97852 259.934 7.9873 259.992 7.93984C261.903 6.39121 264.334 5.46484 266.983 5.46484C269.651 5.46484 272.098 6.40527 274.014 7.97324C274.074 8.02246 274.162 8.01367 274.211 7.95391L274.844 7.1998C274.895 7.14004 274.886 7.05039 274.824 7.00117ZM265.875 14.6758C265.875 14.9741 265.994 15.2603 266.205 15.4713C266.416 15.6823 266.702 15.8008 267 15.8008C267.299 15.8008 267.585 15.6823 267.796 15.4713C268.007 15.2603 268.125 14.9741 268.125 14.6758C268.125 14.3774 268.007 14.0913 267.796 13.8803C267.585 13.6693 267.299 13.5508 267 13.5508C266.702 13.5508 266.416 13.6693 266.205 13.8803C265.994 14.0913 265.875 14.3774 265.875 14.6758Z" fill="#303133"/>
        <path d="M352.212 15.3516L348.155 11.2937C348.784 10.4797 349.125 9.48438 349.125 8.4375C349.125 7.18438 348.636 6.00937 347.752 5.12344C346.867 4.2375 345.689 3.75 344.438 3.75C343.186 3.75 342.008 4.23906 341.123 5.12344C340.237 6.00781 339.75 7.18438 339.75 8.4375C339.75 9.68906 340.239 10.8672 341.123 11.7516C342.008 12.6375 343.184 13.125 344.438 13.125C345.484 13.125 346.478 12.7844 347.292 12.1562L351.35 16.2125C351.362 16.2244 351.376 16.2338 351.392 16.2403C351.407 16.2467 351.424 16.2501 351.441 16.2501C351.457 16.2501 351.474 16.2467 351.49 16.2403C351.505 16.2338 351.519 16.2244 351.531 16.2125L352.212 15.5328C352.224 15.5209 352.234 15.5068 352.24 15.4912C352.247 15.4757 352.25 15.459 352.25 15.4422C352.25 15.4254 352.247 15.4087 352.24 15.3931C352.234 15.3776 352.224 15.3635 352.212 15.3516V15.3516ZM346.913 10.9125C346.25 11.5734 345.372 11.9375 344.438 11.9375C343.503 11.9375 342.625 11.5734 341.963 10.9125C341.302 10.25 340.938 9.37187 340.938 8.4375C340.938 7.50313 341.302 6.62344 341.963 5.9625C342.625 5.30156 343.503 4.9375 344.438 4.9375C345.372 4.9375 346.252 5.3 346.913 5.9625C347.573 6.625 347.938 7.50313 347.938 8.4375C347.938 9.37187 347.573 10.2516 346.913 10.9125Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M244.758 4.0515C244.958 3.96173 245.195 3.99094 245.366 4.12643L248.794 6.8537C248.925 6.95733 249 7.11085 249 7.27273C249 7.43461 248.925 7.58813 248.794 7.69176L245.893 10L248.794 12.3082C248.925 12.4119 249 12.5654 249 12.7273C249 12.8891 248.925 13.0427 248.794 13.1463L245.366 15.8736C245.195 16.0091 244.958 16.0383 244.758 15.9485C244.557 15.8587 244.429 15.6662 244.429 15.4545V11.1646L241.937 13.1463C241.695 13.3392 241.334 13.3079 241.132 13.0765C240.93 12.845 240.963 12.5011 241.206 12.3082L244.107 10L241.206 7.69176C240.963 7.49891 240.93 7.15496 241.132 6.92354C241.334 6.69212 241.695 6.66085 241.937 6.8537L244.429 8.83543V4.54546C244.429 4.33381 244.557 4.14127 244.758 4.0515ZM245.571 11.1646L247.536 12.7273L245.571 14.29V11.1646ZM245.571 8.83543V5.71003L247.536 7.27273L245.571 8.83543Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M362.6 6H361V7.6H362.6V6ZM375 6H364V7.6H375V6ZM361 9H362.6V10.6H361V9ZM371.6 9H364V10.6H371.6V9ZM361 12H362.6V13.6H361V12ZM374 12H364V13.6H374V12Z" fill="#303133"/>
        <rect width="380" height="1" fill="#DEE1E4"/>
        <rect y="19" width="380" height="1" fill="#DEE1E4"/>
      </svg>
    </Container>
  );
}

export default MacOSBackground;


