const WorkflowActionTypes = {
  LIST: 'workflow/LIST',
  LIST_SUCCESS: 'workflow/LIST_SUCCESS',
  LIST_ERROR: 'workflow/LIST_ERROR',

  GET_OPTIONS: 'workflow/GET_OPTIONS',
  GET_OPTIONS_SUCCESS: 'workflow/GET_OPTIONS_SUCCESS',
  GET_OPTIONS_ERROR: 'workflow/GET_OPTIONS_ERROR',

  SAVE: 'workflow/SAVE',
  SAVE_SUCCESS: 'workflow/SAVE_SUCCESS',
  SAVE_ERROR: 'workflow/SAVE_ERROR',

  SAVE_IMAGE: 'workflow/SAVE_IMAGE',
  SAVE_IMAGE_SUCCESS: 'workflow/SAVE_IMAGE_SUCCESS',
  SAVE_IMAGE_ERROR: 'workflow/SAVE_IMAGE_ERROR',

  GET_ENTRY_COUNT: 'workflow/GET_ENTRY_COUNT',
  GET_ENTRY_COUNT_SUCCESS: 'workflow/GET_ENTRY_COUNT_SUCCESS',
  GET_ENTRY_COUNT_ERROR: 'workflow/GET_ENTRY_COUNT_ERROR',

  CLEAR_ENTRY_COUNT: 'workflow/CLEAR_ENTRY_COUNT',

  PUBLISH: 'workflow/PUBLISH',
  PUBLISH_SUCCESS: 'workflow/PUBLISH_SUCCESS',
  PUBLISH_ERROR: 'workflow/PUBLISH_ERROR',

  UN_PUBLISH: 'workflow/UN_PUBLISH',
  UN_PUBLISH_SUCCESS: 'workflow/UN_PUBLISH_SUCCESS',
  UN_PUBLISH_ERROR: 'workflow/UN_PUBLISH_ERROR',

  UPDATE_QUIET_HOURS: 'workflow/UPDATE_QUIET_HOURS',
  UPDATE_QUIET_HOURS_SUCCESS: 'workflow/UPDATE_QUIET_HOURS_SUCCESS',
  UPDATE_QUIET_HOURS_ERROR: 'workflow/UPDATE_QUIET_HOURS_ERROR',

  GET_EDIT_METADATA: 'workflow/GET_EDIT_METADATA',
  GET_EDIT_METADATA_SUCCESS: 'workflow/GET_EDIT_METADATA_SUCCESS',
  GET_EDIT_METADATA_ERROR: 'workflow/GET_EDIT_METADATA_ERROR',

  GET_WORKFLOW_PROGRAM: 'workflow/GET_PROGRAM',
  GET_WORKFLOW_PROGRAM_SUCCESS: 'workflow/GET_PROGRAM_SUCCESS',
  GET_WORKFLOW_PROGRAM_ERROR: 'workflow/GET_PROGRAM_ERROR',

  GET_WORKFLOW_WORKER: 'workflow/GET_WORKER',
  GET_WORKFLOW_WORKER_SUCCESS: 'workflow/GET_WORKER_SUCCESS',
  GET_WORKFLOW_WORKER_ERROR: 'workflow/GET_WORKER_ERROR',

  UPDATE_WORKFLOW_WORKER: 'workflow/UPDATE_WORKER',
  UPDATE_WORKFLOW_WORKER_NODE: 'workflow/UPDATE_WORKER_NODE',
  CLEAR_WORKFLOW_WORKER: 'workflow/CLEAR_WORKER',

  DELETE_VERSION: 'workflow/DELETE_VERSION',
  DELETE_VERSION_SUCCESS: 'workflow/DELETE_VERSION_SUCCESS',
  DELETE_VERSION_ERROR: 'workflow/DELETE_VERSION_ERROR',

  DELETE: 'workflow/DELETE',
  DELETE_SUCCESS: 'workflow/DELETE_SUCCESS',
  DELETE_ERROR: 'workflow/DELETE_ERROR',

  ARCHIVE: 'workflow/ARCHIVE',
  ARCHIVE_SUCCESS: 'workflow/ARCHIVE_SUCCESS',
  ARCHIVE_ERROR: 'workflow/ARCHIVE_ERROR',

  UN_ARCHIVE: 'workflow/UN_ARCHIVE',
  UN_ARCHIVE_SUCCESS: 'workflow/UN_ARCHIVE_SUCCESS',
  UN_ARCHIVE_ERROR: 'workflow/UN_ARCHIVE_ERROR',

  PARTICIPATION_WORKFLOW: 'workflow/PARTICIPATION_WORKFLOW',
  PARTICIPATION_WORKFLOW_SUCCESS: 'workflow/PARTICIPATION_WORKFLOW_SUCCESS',
  PARTICIPATION_WORKFLOW_ERROR: 'workflow/PARTICIPATION_WORKFLOW_ERROR',

  EXIT_CRITERION_WORKFLOW: 'workflow/EXIT_CRITERION_WORKFLOW',
  EXIT_CRITERION_WORKFLOW_SUCCESS: 'workflow/EXIT_CRITERION_WORKFLOW_SUCCESS',
  EXIT_CRITERION_WORKFLOW_ERROR: 'workflow/EXIT_CRITERION_WORKFLOW_ERROR',

  GET_ANALYTICS: 'workflow/GET_ANALYTICS',
  GET_ANALYTICS_SUCCESS: 'workflow/GET_ANALYTICS_SUCCESS',
  GET_ANALYTICS_ERROR: 'workflow/GET_ANALYTICS_ERROR',
  CLEAR_ANALYTICS: 'workflow/CLEAR_ANALYTIC',

  GET_PROGRAM_ANALYTICS: 'workflow/GET_PROGRAM_ANALYTICS',
  GET_PROGRAM_ANALYTICS_SUCCESS: 'workflow/GET_PROGRAM_ANALYTICS_SUCCESS',
  GET_PROGRAM_ANALYTICS_ERROR: 'workflow/GET_PROGRAM_ANALYTICS_ERROR',

  GET_NODE_ANALYTICS: 'workflow/GET_NODE_ANALYTICS',
  GET_NODE_ANALYTICS_SUCCESS: 'workflow/GET_NODE_ANALYTICS_SUCCESS',
  GET_NODE_ANALYTICS_ERROR: 'workflow/GET_NODE_ANALYTICS_ERROR',

  GET_LOGS: 'workflow/GET_LOGS',
  GET_LOGS_SUCCESS: 'workflow/GET_LOGS_SUCCESS',
  GET_LOGS_ERROR: 'workflow/GET_LOGS_ERROR',

  GET_PUBLISH_HISTORY: 'workflow/GET_PUBLISH_HISTORY',
  GET_PUBLISH_HISTORY_SUCCESS: 'workflow/GET_PUBLISH_HISTORY_SUCCESS',
  GET_PUBLISH_HISTORY_ERROR: 'workflow/GET_PUBLISH_HISTORY_ERROR',

  SET_DEFAULT_ENTRY_POINT: 'workflow/SET_DEFAULT_ENTRY_POINT',
  SET_DEFAULT_NODES: 'workflow/SET_DEFAULT_NODES',
  CLEAR_DEFAULT_ENTRY_POINT: 'workflow/CLEAR_DEFAULT_ENTRY_POINT',

  DOWNLOAD_CONTACT_BY_NODE: 'workflow/DOWNLOAD_CONTACT_BY_NODE',
  DOWNLOAD_CONTACT_BY_NODE_SUCCESS: 'workflow/DOWNLOAD_CONTACT_BY_NODE_SUCCESS',
  DOWNLOAD_CONTACT_BY_NODE_ERROR: 'workflow/DOWNLOAD_CONTACT_BY_NODE_ERROR',

  GET_SMS_NODE_ANALYTICS: 'workflow/GET_SMS_NODE_ANALYTIC',
  GET_SMS_NODE_ANALYTICS_SUCCESS: 'workflow/GET_SMS_NODE_ANALYTIC_SUCCESS',
  GET_SMS_NODE_ANALYTICS_ERROR: 'workflow/GET_SMS_NODE_ANALYTIC_ERROR',

  GET_EMAIL_NODE_ANALYTICS: 'workflow/GET_EMAIL_NODE_ANALYTICS',
  GET_EMAIL_NODE_ANALYTICS_SUCCESS: 'workflow/GET_EMAIL_NODE_ANALYTICS_SUCCESS',
  GET_EMAIL_NODE_ANALYTICS_ERROR: 'workflow/GET_EMAIL_NODE_ANALYTICS_ERROR',

  GET_VIBER_NODE_ANALYTICS: 'workflow/GET_VIBER_NODE_ANALYTICS',
  GET_VIBER_NODE_ANALYTICS_SUCCESS: 'workflow/GET_VIBER_NODE_ANALYTICS_SUCCESS',
  GET_VIBER_NODE_ANALYTICS_ERROR: 'workflow/GET_VIBER_NODE_ANALYTICS_ERROR',

  GET_WEBPUSH_NODE_ANALYTICS: 'workflow/GET_WEBPUSH_NODE_ANALYTICS',
  GET_WEBPUSH_NODE_ANALYTICS_SUCCESS: 'workflow/GET_WEBPUSH_NODE_ANALYTICS_SUCCESS',
  GET_WEBPUSH_NODE_ANALYTICS_ERROR: 'workflow/GET_WEBPUSH_NODE_ANALYTICS_ERROR',

  GET_MOBILE_PUSH_NODE_ANALYTICS: 'workflow/GET_MOBILE_PUSH_NODE_ANALYTICS',
  GET_MOBILE_PUSH_NODE_ANALYTICS_SUCCESS: 'workflow/GET_MOBILE_PUSH_NODE_ANALYTICS_SUCCESS',
  GET_MOBILE_PUSH_NODE_ANALYTICS_ERROR: 'workflow/GET_MOBILE_PUSH_NODE_ANALYTICS_ERROR',

  GET_WEBHOOK_NODE_ANALYTICS: 'workflow/GET_WEBHOOK_NODE_ANALYTICS',
  GET_WEBHOOK_NODE_ANALYTICS_SUCCESS: 'workflow/GET_WEBHOOK_NODE_ANALYTICS_SUCCESS',
  GET_WEBHOOK_NODE_ANALYTICS_ERROR: 'workflow/GET_WEBHOOK_NODE_ANALYTICS_ERROR',

  DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL: 'workflow/DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL',
  DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL_SUCCESS: 'workflow/DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL_SUCCESS',
  DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL_ERROR: 'workflow/DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL_ERROR',

  CLEAR_WORKFLOW_LOGS: 'workflow/CLEAR_LOGS',
};

export default WorkflowActionTypes;
