import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionButton,
  Input,
  Multiselect,
  SearchSelect,
  WithOnboarding,
  OnboardingHighlight,
  Textarea,
  SliderTabs,
} from '@components';
import { by, diffFields, extract, removeAppFromPath, testId } from '@utils';
import { updateRedirects } from '@store/actions/creators';
import { AppRedirects, RFMColorsConfig, Paths } from '@constants';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowEditorContext } from '../../../../../../../contexts';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { Container, FooterOption, FooterOptionLabel, RFMOption, RFMOptionIndicator } from './styled';
import { useOnboarding, useTranslation, useNavigate } from '@hooks';
import { useLocation } from 'react-router-dom';
import { WorkflowActionTypes } from '@store/actions/types';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { segmentsOptionsSelector } from '@store/selectors';

const getTabs = (p) => [
  { name: 'my_segments', label: p('my_segments') },
  { name: 'rfm_segments', label: p('rfm_segments') },
];

const tryParse = monetaryAggregate => {
  try {
    return JSON.parse(monetaryAggregate);
  } catch (e) {
    return monetaryAggregate;
  }
};

const createRFMOptionsWithColor = opts => opts.map(({ cfg, value, label }) => ({
  value,
  label: (
    <RFMOption>
      <RFMOptionIndicator $color={cfg?.color?.ui} />
      {label}
    </RFMOption>
  )
}));

const filterExtractor = children => children?.props?.children?.[1] || "";

const sortOption = (a, b) => a.cfg?.order - b.cfg.order;
const sortSub = (a, b) => a.rank = b.rank;

const ResourceNodeSettings = ({
  value: outerValue,
  onChange: outerOnChange,
  label,
  description,
  options,
  errors,
  onLabelChange,
  onClearError,
  onDescriptionChange,
  editable = true,
  compareWithValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const segmentOptions = useSelector(segmentsOptionsSelector);
  const onboarding = useOnboarding('workflows');
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t, p } = useTranslation('workflow_page')
  const tabs = getTabs(p);
  const multinode = createMultinodeUpdater(outerValue, outerOnChange, errors, onClearError);
  const value = multinode.values[0];
  const onChange = multinode.changeNode(0);

  const [tabValues, setTabValues] = useState(Object.fromEntries(tabs.map(extract('name')).map(name => [name, {}])));
  const rfmAnalyticsOptions = (options.rfmResources || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const selectedAnalytics = (options.rfmResources || []).find(by(value?.rfm_analytics));
  const rfmSegmentOptions = createRFMOptionsWithColor((selectedAnalytics?.resources || [])
    .filter(({ parent_segment_id }) => !parent_segment_id)
    .map(({ segment_id, name }) => ({
      value: segment_id,
      label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
      cfg: RFMColorsConfig.find(by('name', name)),
    }))
    .sort(sortOption));

  const selectedRFMSegment = (selectedAnalytics?.resources || []).find(by('segment_id', value.parent_segment_id));
  const rfmSubsegmentOptions = createRFMOptionsWithColor([
    {
      value: value.parent_segment_id,
      label: t('labels.all'),
      cfg: RFMColorsConfig.find(by('name', selectedRFMSegment?.name)),
      rank: 0,
    },
    ...(selectedAnalytics?.resources || [])
      .filter(by('parent_segment_id', value.parent_segment_id))
      .map(({ segment_id, name }) => {
        const monetaryValues = (tryParse(selectedRFMSegment.monetary_aggregate) || []).find(by('monetary_ranks', +name[name.length - 1]));

        return {
          value: segment_id,
          label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', selectedRFMSegment.name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
          cfg: RFMColorsConfig.find(by('name', selectedRFMSegment.name)),
          rank: +name[name.length - 1],
        };
      })
      .sort(sortSub)]);

  useEffect(() => {
    setTimeout(() => {
      onChange(s => ({ ...s, resource_type: 'SEGMENT' }));
    }, 100)
  }, []);

  useEffect(() => {
    if (!value.tab) {
      onChange(s => ({ ...s, tab: 'my_segments' }));
    }
  }, [value]);

  const handleTypeChange = (resource_type) => {
    multinode.clearError(0)('resource_type');
    onChange(s => ({ ...s, resource_type }));
  };

  const handleTemplateChange = (segment_id) => {
    if (onboarding.enabled(5)) {
      onboarding.nextStep();
    }

    multinode.clearError(0)('segment_ids');
    onChange(s => ({ ...s, segment_ids: [segment_id] }));
  }

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const resourceOptions = segmentOptions?.segments
    ?.map(({ id, name }) => ({ value: id, label: name }));

  const fieldsOptions = segmentOptions.fields?.attribute?.map(({ label, field }) => ({ value: field, label }));

  const handleFieldsChange = (message_fields) => {
    onChange(s => ({ ...s, meta: message_fields }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.SEGMENT_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'segment_ids', id => [id]], ['type', 'resource_type']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.RESOURCES_SEGMENTS}/new`);
  };

  const handleTabChange = (t) => {
    setTabValues(tvs => ({ ...tvs, [value.tab]: {
        rfm_analytics: value.rfm_analytics,
        parent_segment_id: value.parent_segment_id,
        segment_ids: value.segment_ids,
      }
    }));

    onChange(s => ({ ...s, tab: t, segment_id: void 0, parent_segment_id: void 0, rfm_analytics: void 0, ...tabValues[t] }));
  };

  const handleRFMAnalyticsChange = (rfm_analytics) => {
    multinode.clearError(0)('rfm_analytics');
    onChange(s => ({ ...s, rfm_analytics }));
  };

  const handleParentResourceIdChange = (segment_id) => {
    multinode.clearError(0)('parent_segment_id');
    multinode.clearError(0)('segment_ids');
    onChange(s => ({ ...s, segment_ids: [segment_id], parent_segment_id: segment_id }));
    if (onboarding.enabled(5)) {
      onboarding.nextStep();
    }
  };

  const handleSubsegmentChange = (segment_id) => {
    multinode.clearError(0)('segment_ids');
    onChange(s => ({ ...s, segment_ids: [segment_id] }));
  };

  const handleSegmentTabClick = () => {
    if (onboarding.enabled(4)) {
      onboarding.nextStep();
    }
  }

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <WithOnboarding zIndex={1} enabled={onboarding.enabled(4)} onboardKey="workflows" text={p('choose_type_segment')} placement="topLeft" next={true}>
              <OnboardingHighlight on={onboarding.enabled(4)}>
                <SliderTabs
                  onClick={handleSegmentTabClick}
                  tabs={tabs}
                  tab={value.tab}
                  localize={t}
                  // style={{ marginBottom: '12px' }}
                  onTabChange={handleTabChange}
                  style={{ width: '100%'}}
                  tabStyle={{ width: '100%', borderRadius: 7 }}
                />
              </OnboardingHighlight>
            </WithOnboarding>
            {/*<Select*/}
            {/*  highlight={diff['resource_type'] === false}*/}
            {/*  disabled={!editable}*/}
            {/*  getPopupContainer={t => t.parentElement.parentElement.parentElement}*/}
            {/*  style={{ width: '100%' }}*/}
            {/*  error={errors['resource_type']}*/}
            {/*  wrapperStyles={{marginBottom: '16px', width: '100%'}}*/}
            {/*  value={value.resource_type}*/}
            {/*  onChange={handleTypeChange}*/}
            {/*  options={typeOptions || []}*/}
            {/*  label="Type"*/}
            {/*  title="Select"*/}
            {/*/>*/}
            {(value.resource_type && value.tab !== 'rfm_segments') && (
              <WithOnboarding zIndex={1} enabled={onboarding.enabled(5)} onboardKey="workflows" text={p('choose_segment')} placement="topLeft" next={true}>
                <SearchSelect
                  {...testId('resource-node-settings-search-segment')()}
                  highlight={diff['segment_ids'] === false}
                  disabled={!editable}
                  getPopupContainer={t => t.parentElement.parentElement.parentElement}
                  error={multinode.getError(0)('segment_ids')}
                  style={{ width: '100%' }}
                  value={value.segment_ids?.[0]}
                  onChange={handleTemplateChange}
                  options={resourceOptions || []}
                  footerOption={(
                    <FooterOption onClick={handleCreateNew}>
                      <ActionButton {...testId('resource-node-settings-create-segment')()} size={22} icon="Plus-icon" />
                      <FooterOptionLabel>
                        {p('create_segment')}
                      </FooterOptionLabel>
                    </FooterOption>
                  )}
                  placeholder={t('actions.select')}
                  title={t('labels.resource')}
                />
              </WithOnboarding>
            )}
            {(value.resource_type && value.tab === 'rfm_segments') && (
              <>
                <WithOnboarding zIndex={1} enabled={onboarding.enabled(5)} onboardKey="workflows" text={p('choose_rfm_and_segment')} placement="topLeft" next={true}>
                  <SearchSelect
                    {...testId('resource-node-settings-search-segment')()}
                    highlight={diff['rfm_analytics'] === false}
                    disabled={!editable}
                    getPopupContainer={t => t.parentElement.parentElement.parentElement}
                    error={multinode.getError(0)('rfm_analytics')}
                    style={{ width: '100%' }}
                    value={value.rfm_analytics}
                    onChange={handleRFMAnalyticsChange}
                    options={rfmAnalyticsOptions || []}
                    placeholder={t('actions.select')}
                    title={t('labels.rfm_analysis')}
                  />
                </WithOnboarding>
                {!!value.rfm_analytics && (
                  <SearchSelect
                    {...testId('resource-node-settings-search-segment')()}
                    highlight={diff['parent_segment_id'] === false}
                    disabled={!editable}
                    getPopupContainer={t => t.parentElement.parentElement.parentElement}
                    error={multinode.getError(0)('parent_segment_id')}
                    style={{ width: '100%' }}
                    containerStyle={{ marginTop: 16 }}
                    value={value.parent_segment_id}
                    onChange={handleParentResourceIdChange}
                    options={rfmSegmentOptions || []}
                    renderOptions
                    filterExtractor={filterExtractor}
                    placeholder={t('actions.select')}
                    title={t('labels.rfm_segment')}
                  />
                )}
                {!!value.parent_segment_id && (
                  <SearchSelect
                    {...testId('resource-node-settings-search-segment')()}
                    highlight={diff['segment_ids'] === false}
                    disabled={!editable}
                    getPopupContainer={t => t.parentElement.parentElement.parentElement}
                    error={multinode.getError(0)('segment_ids')}
                    style={{ width: '100%' }}
                    containerStyle={{ marginTop: 16 }}
                    value={value.segment_ids?.[0]}
                    renderOptions
                    filterExtractor={filterExtractor}
                    onChange={handleSubsegmentChange}
                    options={rfmSubsegmentOptions || []}
                    placeholder={t('actions.select')}
                    title={t('labels.rfm_subsegment')}
                  />
                )}
              </>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('resource-node-settings-segment-name')()}
              highlight={diff['label'] === false}
              error={multinode.getError(0)('label')}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              maxLength={40}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('resource-node-settings-segment-description')()}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
        onboarding={{
          required: {
            enabled: false,
            text: p('required_node_settings'),
            next: true,
          },
          additional: {
            enabled: onboarding.enabled(5),
            text: p('optional_node_settings'),
            next: true,
          },
        }}
        additionalSettings={(
          <Multiselect
            {...testId('resource-node-settings-message-fields-select')()}
            highlight={diff['meta'] === false}
            disabled={!editable}
            title={p('message_fields')}
            style={{ width: '100%' }}
            options={fieldsOptions}
            value={value.meta || []}
            onChange={handleFieldsChange}
          />
        )}
      />
    </Container>
  );
};

export default ResourceNodeSettings;
