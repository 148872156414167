import React , {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    addNodeToExpression
} from '@store/actions/creators';
import {v4 as uuidv4} from "uuid";

import {NODE_TYPE_CONSTANT, NODE_TYPE_LINEAR, NODE_TYPE_FUNCTION, FUNCTIONS} from "../constants";
import {expressionFunctionAdded} from "../../../../../store/actions/creators";
import {
    expressionSelector
} from '@store/selectors';

const AddFunction = (props) => {

    const dispatch = useDispatch();
    const expression = useSelector(expressionSelector);
    const [active, setValue] = useState(false);


    if(expression.selected.id !== props.expression.id) {
        return '';
    } else {
        console.log(props.expression.id)
    }

    if(!active) {
        return (<span /*style={{border:"1px solid green", borderRadius:"50%", padding:"5px 10px"}}*/ onClick={(e) => setValue(!active)}>+</span>)
    }

    return <select name="" id="" onChange={(e) => {dispatch(expressionFunctionAdded({id: props.expression.id, value:e.target.value}) ); setValue(!active) }}>
        <option value={null}>---</option>
        {FUNCTIONS.map(val => <option value={val}>{val}</option>)}
    </select>
};

export default AddFunction;