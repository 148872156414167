import React, { useEffect, useRef } from 'react';
import { by, extract, update } from '@utils';
import { Switch, LogicalOperatorSelect } from '@components';
import { useModalState, useTranslation } from '@hooks';
import { EventPresets } from '@constants/eventPresets';

import { DeleteButton, EventFilter, FiltersOperator, AddFilterButton } from './components';
import { Container, Header, Footer, Content, Title, Row, FooterCaption, FooterIcon } from './styled';
import { OPERATOR_MATCHES_RANGE, OPERATOR_ONE_OF } from '@constants';
import { ConfirmationModal } from "@components";

const EventSettings = ({ value, className, options, onChange, onDelete, editable, errors, onClearError }) => {
  const { p, t } = useTranslation('workflow_page');

  const eventOptions = options?.events?.map((event) => {

    return {
      ...event,
      value: event.id,
      system: event.type === 3,
      field: event.id,
    }
  });
  const event = eventOptions.find(by('value', value.event_id));
  const eventTitle = event?.label;
  const isSystem = event?.type === 3;
  const fieldOptions = event?.payload?.map?.(({ field, label, type }) => ({ value: field, label: label, type })) || [];
  const simplifyModal = useModalState(false);
  const modalContainer = useRef(null);

  const preset = EventPresets.find(({ events }) => !!~events.indexOf(value.event_name));

  const handleSimplifiedChange = (checked) => {
    if (!checked && (value.filter_builder?.filters || []).some(f => f.operator !== OPERATOR_ONE_OF && f.operator !== OPERATOR_MATCHES_RANGE)) {
      return simplifyModal.open();
    }

    onChange(s => ({ ...s, simplified: !checked, hidden: false }));
  };

  const handleSimplifyFiltersConfirm = () => {
    onChange(s => ({
      ...s,
      simplified: true,
      hidden: false,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).map(f => f.operator === OPERATOR_ONE_OF || f.operator === OPERATOR_MATCHES_RANGE ? f : ({
          ...f,
          operator: f.type === 'date' || f.type === 'datetime' ? OPERATOR_MATCHES_RANGE : OPERATOR_ONE_OF,
          value: { type: 'scalar', value: '' }
        })),
      },
    }));
    simplifyModal.close();
  };

  const handleToggleFilters = () => {
    onChange(s => ({ ...s, hidden: !s.hidden }));
  };

  const handleToggleOperator = () => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        logicalOperator: s.filter_builder?.logicalOperator === 'and' ? 'or' : 'and',
      }
    }));
  };

  const handleLogicalOperatorChange = (lo) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        logicalOperator: lo,
      }
    }));
  }

  const handleDeleteFilter = (index) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).filter((_, i) => i !== index),
      }
    }));
  };

  const handleAddFilter = (field, type) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).concat({
          field,
          operator: type === 'date' || type === 'datetime' ? OPERATOR_MATCHES_RANGE : OPERATOR_ONE_OF,
          type,
          value: { type: 'scalar', value: '' }
        }),
      }
    }));
  };

  const handleDeleteEvent = () => {
    onDelete();
  };

  const handleFilterChange = (index) => (updater) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).map((f, i) => i === index ? update(f, updater) : f),
      }
    }));
  };

  useEffect(() => {
    if (!errors('filter_builder')) {
      return;
    }
    if ((errors('filter_builder') || []).some(extract('value')) && value.hidden) {
      onChange(s => ({ ...s, hidden: false }));
    }
  }, [errors('filter_builder'), value.hidden]);

  return (
    <Container ref={modalContainer}>
      <Header>
        <Title>
          {eventTitle}
        </Title>
        <Row>
        {preset && (
          <>
            <Switch
              title={p('advanced_filters')}
              checked={!value.simplified}
              disabled={!editable}
              onChange={handleSimplifiedChange}
            />
            <ConfirmationModal
              opened={simplifyModal.opened}
              onClose={simplifyModal.close}
              title={t('segments_page.switching_filters')}
              appearance="default"
              text={t('segments_page.switching_filters_description')}
              onCancel={simplifyModal.close}
              getContainer={(c) => modalContainer.current || c}
              onSubmitClick={handleSimplifyFiltersConfirm}
              buttonsParams={{
                cancelAppearance: 'secondary',
                submitAppearance: 'default',
                cancelLabel: t('actions.cancel'),
                submitLabel: t('segments_page.switch_filters'),
              }}
            />
          </>
        )}
        <DeleteButton style={{ marginLeft: 20 }} onClick={handleDeleteEvent} />
        </Row>
      </Header>
      <Content $h={value.hidden}>
        {(!value.simplified && !value.hidden && (value.filter_builder?.filters || []).length > 1) && (
          <LogicalOperatorSelect
            disabled={!editable}
            style={{ marginBottom: 10 }}
            value={value.filter_builder.logicalOperator}
            onChange={handleLogicalOperatorChange}
          />
        )}
        {(value.filter_builder?.filters || []).flatMap((filter, index) => [
          (index !== 0 && !value.simplified && !value.hidden && (value.filter_builder?.filters || []).length > 0) && (
            <FiltersOperator disabled={!editable} value={value.filter_builder.logicalOperator} onClick={handleToggleOperator} />
          ),
          (
            <EventFilter
              event={event}
              value={filter}
              index={index}
              editable={editable}
              isSystem={isSystem}
              errors={errors}
              className={className}
              light={((value.filter_builder?.filters || []).length === 1) && !value.simplified}
              onClearError={onClearError}
              isHidden={value.hidden}
              isSimplified={value.simplified}
              onChange={handleFilterChange(index)}
              onDelete={handleDeleteFilter}
            />
          )
        ])}
        {!value.hidden && (
          <AddFilterButton
            noFilters={(value.filter_builder?.filters || []).length === 0}
            disabled={!editable}
            options={fieldOptions}
            style={{ marginTop: (value.filter_builder?.filters || []).length ? 20 : 0 }}
            onSelect={handleAddFilter}
          />
        )}
      </Content>
      <Footer onClick={handleToggleFilters} $d={(value.filter_builder?.filters || []).length > 0}>
        <FooterCaption>
          {value.hidden ? p('show_filters') : p('hide_filters')}
        </FooterCaption>
        <FooterIcon $r={value.hidden}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3848 9.29245C11.2617 9.35399 11.2002 9.41553 11.0771 9.41553C10.954 9.41553 10.8309 9.35399 10.7694 9.29245L8.61554 7.20014L7.93862 6.58476L7.26169 7.20014L5.16938 9.29245C5.10785 9.35399 4.98477 9.41553 4.86169 9.41553C4.73862 9.41553 4.61554 9.35399 4.554 9.29245C4.43092 9.16937 4.36938 9.0463 4.36938 8.92322C4.36938 8.86168 4.43092 8.7386 4.554 8.61553L8.00015 5.35399L11.3848 8.61553C11.4463 8.67707 11.4463 8.7386 11.5078 8.80014C11.5078 8.86168 11.5694 8.92322 11.5694 8.98476C11.5694 9.0463 11.5694 9.10783 11.5078 9.16937C11.4463 9.16937 11.4463 9.23091 11.3848 9.29245Z" fill="#909399"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8 0.861538C11.9425 0.861538 15.1385 4.05754 15.1385 8C15.1385 11.9425 11.9425 15.1385 8 15.1385C4.05754 15.1385 0.861538 11.9425 0.861538 8C0.861538 4.05754 4.05754 0.861538 8 0.861538ZM8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z" fill="#909399"/>
          </svg>
        </FooterIcon>
      </Footer>
    </Container>
  );
};

export default EventSettings;
