import React, {useEffect, useState} from 'react';

import { Container } from './styled';
import { Button, Modal, Select } from '@components';
import { EmailStatusOptions, SmsStatusOptions, WebhookStatusOptions, MobilePushStatusOptions, WebpushStatusOptions } from '@constants';
import { ViberStatusOptions } from '@constants/viberStatuses';
import {useErrors, useTranslation} from '@hooks';
import { sequence } from '@utils';

const options = {
  email: EmailStatusOptions,
  sms: SmsStatusOptions,
  webpush: WebpushStatusOptions,
  viber: ViberStatusOptions,
  'mobpush': MobilePushStatusOptions,
  'apirequest': WebhookStatusOptions,
}

const DownloadWorkflowContactsModal = ({ opened, onClose, onDownload, channelType }) => {
  const [status, setStatus] = useState('');
  const errors = useErrors('status');
  const { t, p } = useTranslation('workflow_page');

  useEffect(() => {
    setStatus('');
    errors.clearError('status');
  }, [opened])

  const handleClose = () => {
    onClose();
    setStatus('');
  };

  const handleConfirm = () => {
    if (!status) {
      errors.setError('status', t('templates.required_field'))
      return;
    }

    onDownload(status);
    handleClose();
  };

  const handleSetStatus = (s, g, o) => {
    if (!!o.values) {
      setStatus(o.values);
      return;
    }

    setStatus(s);
  }

  return (
    <Modal
      title={t('actions.download_to_csv')}
      opened={opened}
      onClose={onClose}
      actions={(
        <>
          <Button onClick={onClose} appearance="secondary" width={140}>{t('actions.cancel')}</Button>
          <Button onClick={handleConfirm} width={140}>{t('actions.download')}</Button>
        </>
      )}
    >
      <Container>
        <Select
          label={t('actions.select')}
          title={t('labels.status')}
          error={errors.errors.status}
          customValue={Array.isArray(status) ? t('statuses.all_statuses') : null}
          options={[ ...(options[channelType] || [])]}
          onChange={sequence(handleSetStatus, () => errors.clearError('status'))}
          placeholder={t('statuses')}
          containerStyle={{ width: '100%' }}
          wrapperStyles={{ width: '100%' }}
          style={{ width: '100%' }}
          value={status}
        />
      </Container>
    </Modal>
  );
};

export default DownloadWorkflowContactsModal;
