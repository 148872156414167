import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #109DFB;
  border-radius: 9px;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 17px;
  color: #109DFB;
  margin-top: 10px;
  cursor: pointer;
  
  ${styledProp('$filled', css`
    background: #F1F6FF;
  `)}
`;
