const ExpressionTypes = {
  EXPRESSION_NAME_CHANGED: '/expression/EXPRESSION_NAME_CHANGED',
  EXPRESSION_VALUE_SELECTED: '/expression/EXPRESSION_VALUE_SELECTED',
  EXPRESSION_TYPE_SELECTED: '/expression/EXPRESSION_TYPE_SELECTED',
  EXPRESSION_CLICKED: '/expression/EXPRESSION_CLICKED',
  EXPRESSION_RESET_TYPE: '/expression/EXPRESSION_RESET_TYPE',
  EXPRESSION_FUNCTION_ADDED: '/expression/EXPRESSION_FUNCTION_ADDED',
  EXPRESSION_FOR_TYPE_SELECTED: '/expression/EXPRESSION_FOR_TYPE_SELECTED',
  EXPRESSION_FOR_VALUE_SELECTED: '/expression/EXPRESSION_FOR_VALUE_SELECTED'
};

export default ExpressionTypes;