import React from 'react';

import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hintModeSelector, onboardingSelector } from '@store/selectors';
import { useOnboarding, useModalState, useTranslation } from '@hooks';
import { GuideLink, NextButton } from './styled';
import { updateOnboarding } from '@store/actions/creators';
import { Modal, Button } from '@components';
import { ConfirmationModal } from "@components";

const WithOnboarding = ({ text, children, enabled, getPopupContainer, className, guideLink, onboardKey, placement, preventDefaultClose = false, close, next, zIndex, nextFallback, ...props }) => {
  const hintMode = useSelector(hintModeSelector);
  const onboarding = useOnboarding(onboardKey);
  const dispatch = useDispatch();
  const onboardingData = useSelector(onboardingSelector);
  const disableOnboardByKeyModal = useModalState(false);
  const { p, t } = useTranslation('sidebar');

  const handleCancelOnboarding = () => {
    dispatch(updateOnboarding(onboardKey, { skipped: true, passed: true, step: null }));
    disableOnboardByKeyModal.close();
  }

  const renderTitle = () => {
    const handleNextClick = () => {
      onboarding.nextStep();

      if (nextFallback) {
        nextFallback();
      }
    }

    const handleCloseClick = () => {
      if (!preventDefaultClose) {
        onboarding.nextStep();
      } else {
        dispatch(updateOnboarding(onboardingData.sidebar.key, { step: 1 }));
        dispatch(updateOnboarding(onboardKey, { key: '', step: null }))
      }
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <div className="oht-text">{text}</div>
        {guideLink && (<div style={{ display: 'flex' }}>
          <div className="oht-text">{p('look')}</div>
          <GuideLink target="_blank" rel="noopener noreferrer" href={`https://retainly.app/docs/${guideLink}`}>{p('detailed_guide')}</GuideLink>
        </div>)}
        {next && <NextButton onClick={handleNextClick}>{p('next')}</NextButton>}
        {close && <NextButton onClick={onboarding.end}>{p('ok')}</NextButton>}
        <div onClick={disableOnboardByKeyModal.open} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #109DFB', borderRadius: '50%', color: '#109DFB', background: '#FFFFFF', width: '16px', height: '16px', textAlign: 'center', position: 'absolute', right: '7px', top: '7px' }}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3733 6.86372L4.5733 4.00008L7.43693 1.13644C7.5642 1.00917 7.5642 0.754625 7.43693 0.563716C7.30966 0.436444 7.05511 0.436444 6.8642 0.563716L4.00057 3.42735L1.13693 0.627353C1.00966 0.50008 0.755114 0.50008 0.564205 0.627353C0.436932 0.754625 0.436932 1.00917 0.564205 1.20008L3.42784 4.00008L0.564205 6.86372C0.436932 6.99099 0.436932 7.24553 0.564205 7.43644C0.691477 7.56371 0.946023 7.56371 1.13693 7.43644L4.00057 4.57281L6.8642 7.43644C6.99148 7.56371 7.24602 7.56371 7.43693 7.43644C7.5642 7.24553 7.5642 6.99099 7.3733 6.86372Z" fill="#109DFB"/>
          </svg>
        </div>
      </div>
    )
  };

  if (enabled && hintMode) {
    return (
      <>
        <Tooltip
          visible={true}
          placement={placement || 'top'}
          getPopupContainer={() => document.body}
          overlayClassName={`oht ${className || ''}`}
          zIndex={zIndex || 9999}
          title={renderTitle}
          {...props}
        >
          {children}
        </Tooltip>
        {disableOnboardByKeyModal.opened ? (
          <ConfirmationModal
            opened={disableOnboardByKeyModal.opened}
            onClose={disableOnboardByKeyModal.close}
            wrapClassName="onboard-disable-modal"
            title={t('labels.onboarding_assistant')}
            appearance="danger"
            text={p('do_you_really_want_disable_onboard')}
            onCancel={disableOnboardByKeyModal.close}
            onSubmitClick={handleCancelOnboarding}
            buttonsParams={{
              cancelAppearance: 'secondary',
              submitAppearance: 'danger',
              cancelLabel: p('dont_turn_off'),
              submitLabel: p('turn_off'),
            }}
          />
        ) : null}
      </>
    )
  }

  return children;
}

export default WithOnboarding;


