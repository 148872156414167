import styled, { css } from 'styled-components';
import { styledProp } from '@utils';
import { Select } from 'antd';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 13px;
  height: 36px;
  border: 1px solid #DCDFE6;
  background: #fff;
  width: 162px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 9px;
  overflow: visible;

  ${styledProp('disabled', css`
    cursor: default;
    pointer-events: none;
    background: #f5f5f5;
    color: rgba(0,0,0,0.3);
  `)}

  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
    borderRadius: 9px !important;
  `)}

  ${styledProp('error', css`
    border-color: #C8402F !important;
    color: #C8402F !important;
  `)}

  ${styledProp('hasValue', '', css`
    color: #909399;
  `)}

  & > .hover {
    transition: all 280ms ease;
    opacity: 0;
    pointer-events: none;
  }

  &:hover .hover {
    opacity: 1;
  }
`;

export const HoverWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: visible;
  z-index: 2000;

  ${styledProp('visible', `
  
  `, `
    opacity: 0 !important;
  `)}
`;

export const HoverContainer = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 10px 14px;
  bottom: 0;
  transform: translateY(calc(100% + 6px));
  left: 0;
  display: flex;
  flex-direction: column;
  min-width: 162px;
  z-index: 2000;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
`;

export const StyledSelect = styled(Select)`
  border: none !important;
  min-width: 110px !important;
  ${styledProp('smaller', css`
    height: 26px !important;
    max-height: 26px !important;
    min-height: 26px !important;
  `, css`
    height: 36px !important;
  `)}

  & .ant-select-overflow {
    max-height: 32px !important;
    max-width: 204px !important;
    overflow-x: hidden !important;

  }

  & .ant-select-selector {
    border: none !important;
    width: 100% !important;

    max-width: 204px !important;
    ${styledProp('smaller', css`
      height: 26px !important;
      max-height: 26px !important;
      min-height: 26px !important;
    `, css`
      height: 36px !important;
    `)}
    overflow-x: hidden !important;
  }

  & .ant-select-arrow {
    margin-top: 0 !important;
    right: -2px !important;
    ${styledProp('smaller', css`
      top: 10px !important;
    `, css`
      top: 12px !important;
    `)}
  }
`;

export const InputContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 9px;
  height: 36px;
  overflow: hidden;
  padding: 0 7px 0 7px;
  position: relative;
  margin-right: 20px;
  ${styledProp('error', css`
    border-color: #C8402F !important;
    color: #C8402F !important;
  `)}
  
  ${styledProp('smaller', css`
    height: 26px !important
  `, css`
    height: 36px !important;
  `)}
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  padding: 14px 10px 10px 10px;
  border-radius: 10px;
  min-width: 186px;
  max-width: 220px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const OverlayTitle = styled.div`
  font-family: Rubik-Regular;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  margin-bottom: 10px;
`;

export const OverlayValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const OverlayTag = styled.div`
  display: flex;
  align-items: center;
`;
