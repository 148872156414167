import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  min-width: 380px;
  width: 380px;
  
  
  ${styledProp('$integrated', css`
    width: 100%;
    display: flex;
    flex-direction: column;
    

  `, css`
    margin-bottom: 15px;
    margin-left: 5px;
    margin-top: 23px;
  `)}
`;

export const ContentTitle = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const Content = styled.div`
  background: #F9FBFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 539px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}

  ${styledProp('$integrated', css`
    //width: 380px;
    border: none;
    background: transparent;
    box-shadow: none;
  `)}
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #109DFB;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  padding: 14px;
`;

export const TabOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px 38px;
  position: relative;
`;

export const TabOption = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  
  ${styledProp('selected', css`
    color: #109DFB;
    text-decoration-line: underline;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #109DFB;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ tab }) => {
    const el = document.querySelector(`#esd-tab-${tab}`);
    
    return css`
      width: ${el?.getBoundingClientRect()?.width}px;
      left: ${el?.offsetLeft}px;
    `;
  }}
`;
