import styled, { css } from 'styled-components';
import { childHeight, styledProp } from '@utils';

export const Container = styled.div`
  padding-right: 20px;
  margin-top: 20px;
  min-height: 320px;
  overflow: visible;
  padding-bottom: 20px;
  
  & > div {
    overflow-y: visible;
  }
`;

export const GroupContainer = styled.div`
  position: relative;
  overflow: visible;
  height: 1px;
  background: transparent;
  flex: 1;
  pointer-events: none;
  margin-top: 0px;
  ${styledProp('$prevGroup', css`
    margin-top: 13px !important;
  `)}
`;

export const GroupBackground = styled.div`
  border: 2px solid #109DFB;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  
  ${({ $children, $height, $dragOver }) => {
    return css`
    height: ${$height}px;
  `
  }}

  ${({ $depth }) => css`
    left: ${10 * $depth}px;
    right: ${10 * $depth}px;
  `}
  
  top: 0;

  &:before {
    content: "";
    display: block;
    height: 2px;
    top: -2px;
    left: 8px;
    right: 8px;
    position: absolute;
    background: #F9FBFF;
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    bottom: -2px;
    left: 8px;
    right: 8px;
    position: absolute;
    background: #F9FBFF;
  }
`;

export const FilterRow = styled.div`
  padding: 14px 18px !important;
  padding-left: 18px !important;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  flex: 1;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
  cursor: grab;
  align-items: center;
  user-select: none;
  
  ${({ $depth }) => css`
    margin: 8px ${10 * $depth}px;
  `}
  ${styledProp('$nextGroup', css`
    //margin-bottom: 13px !important; 
  `)}
  ${styledProp('$prevGroup', css`
    margin-top: 13px !important;
  `)}
`;

export const FiltersContainer = styled.div`
  padding: 8px 7px;
  border: 2px solid #109DFB;
  border-radius: 10px;
  position: relative;
  margin-top: 12px;
  
  &:before {
    content: "";
    display: block;
    height: 6px;
    top: -3px;
    left: 8px;
    right: 8px;
    position: absolute;
    background: #F9FBFF;
  }

  &:after {
    content: "";
    display: block;
    height: 6px;
    bottom: -3px;
    left: 8px;
    right: 8px;
    position: absolute;
    background: #F9FBFF;
  }
`;

export const DnDIcon = styled.div`
  display: flex;
  flex: 0;
  flex-shrink: 1;
`;

export const RuleName = styled.div`
  font-family: Rubik-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #109DFB;
  margin-right: 10px;
`;

export const FilterTitle = styled.div`
  font-family: Rubik-Regular;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #303133;
`;

export const OperatorRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
