import React from 'react';

import { Container, Title, HTMLInput, Error, InputContainer } from './styled.js';
import { useTransition, useTranslation } from '@hooks';
import { renderProp, sequence } from '@utils';

const Input = ({ title, error, containerStyle, renderRight, ...props }) => {
  const focusTransition = useTransition();
  const { e } = useTranslation();

  const handleFocus = sequence(props.onFocus, focusTransition.show);
  const handleBlur = sequence(props.onBlur, focusTransition.hide);

  return (
    <Container hasError={!!error} style={containerStyle} black={props.blackLabel}>
      {!!title && (
        <Title black={props.blackLabel}>{title}</Title>
      )}
      <InputContainer>
        <HTMLInput
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {renderProp(renderRight)}
      </InputContainer>
      {error && <Error>{e(error)}</Error>}
    </Container>
  );
}

export default Input;


