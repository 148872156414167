import styled, { css } from 'styled-components';
import { styledProp } from '@utils';
import * as Colors from '@components';

export const Container = styled.div`
  padding: 0 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #dcdfe6;
`;

export const TableCell = styled.p`
  width: ${({ widthPercent }) => `${widthPercent}%`};
  padding: ${({ withPadding }) => (withPadding ? '0 4px' : '0')};
  margin: 0;
  color: #303133;
  font-size: 13px;
  text-align: ${({ alignEnd }) => (alignEnd ? 'right' : 'left')};
  
  ${styledProp('$header', css`
    color: #909399;
  `)}
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyButton = styled.button`
  padding: 0;
  font-size: 13px;
  color: #109DFB;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const CreateButton = styled.div`
  display: flex;
  background: #303133;
  border-radius: 49px;
  height: 36px;
  padding: 12px 14px;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  position: fixed;
  align-self: center;
  bottom: 30px;
  user-select: none;
  cursor: pointer;
`;

export const MenuItem = styled.p`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;

  ${styledProp('disabled', `
    opacity: .5;
    cursor: not-allowed;
    // pointer-events: none;
  `)}
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 9px;
  `)}
`;
