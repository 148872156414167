import { moment } from '@utils';

export const defaultRanges = {
  'labels.today': [
    moment().startOf('day'),
    moment().endOf('day')
  ],
  'labels.yesterday': [
    moment().startOf('day').add(-1, 'days'),
    moment().endOf('day').add(-1, 'days')
  ],
  'labels.this_week': [
    moment().startOf('week').startOf('day'),
    moment().endOf('week').endOf('day')
  ],
  'labels.last_week': [
    moment().startOf('week').add(-1, 'week'),
    moment().endOf('week').add(-1, 'week')
  ],
  'labels.this_month': [
    moment().startOf('month').startOf('day'),
    moment().endOf('month').endOf('day')
  ],
  'labels.last_month': [
    moment().startOf('month').add(-1, 'month').startOf('day'),
    moment().endOf('month').add(-1, 'month').endOf('day')
  ],
  'labels.this_year': [
    moment().startOf('year').startOf('day'),
    moment().endOf('year').endOf('day')
  ],
  'labels.last_year': [
    moment().startOf('day').startOf('year').add(-1, 'year'),
    moment().endOf('day').endOf('year').add(-1, 'year')
  ],
};
