import React from 'react';

import { Container, StyledLink } from './styled';
import { Icon } from '@components';
import { hashAppId, removeAppFromPath } from '@utils';
import { useCurrentWorkspace } from '@hooks';

const Link = ({ underline, icon, width, variant, ...props }) => {
  const currentApp = useCurrentWorkspace();
  const resolveTo = () => {
    if (!props.to) {
      return '#';
    }

    if (props.to?.startsWith?.('/') && currentApp?.id) {
      return `/app/${hashAppId(currentApp?.id)}${removeAppFromPath(props.to)}`;
    }

    return props.to;
  };

  return (
    <Container {...{width, variant}}>
      <StyledLink $underline={underline} {...props} to={resolveTo()} />
      {icon && (
        <Icon
          name="Back-arrow"
          size={10}
          style={{ marginLeft: '5px', transform: 'rotate(180deg)', lineHeight: 10 }}
          color="#109DFB"
        />
      )}
    </Container>
  )
};

export default Link;
