import React, { useContext, useEffect, useState } from 'react';
import omit from 'lodash.omit';
import { Button, EditTemplateModal, Modal, WithPermissions, WithOnboarding } from '@components';

import { Container, ActionsRow } from './styled';
import { NodeSettings } from './components';
import { by, testId, validateNode } from '@utils';
import { Paths, UserPermissions } from '@constants';
import { useParams} from 'react-router';
import { WorkflowNodes } from '../../../../../constants';
import { Tooltip } from 'antd';
import { Icon } from '../../../../ui';
import { WorkflowEditorContext } from '@contexts';
import { useModalState, useTranslation, useNavigate, useOnboarding } from '@hooks';

const info = {
  [WorkflowNodes.PUSH_DISCOUNT]: 'push_discount_settings_info',
};

const NodeSettingsModal = ({ opened, data = {}, onSave, onClose, options, editable }) => {
  const editor = useContext(WorkflowEditorContext);
  const [nodeData, setNodeData] = useState(data);
  const [styles, setStyles] = useState(editor.styles[data.id]?.data || {});
  const [label, setLabel] = useState(data.label);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState(data.description);
  const editTemplateModal = useModalState();
  const navigate = useNavigate();
  const onboarding = useOnboarding('workflows');

  const { t, p } = useTranslation('workflow_page')

  useEffect(() => {
    setNodeData(data);
  }, [data]);

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    setDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    if (!editor.styles[data.id]?.data) {
      return;
    }

    setStyles(editor.styles[data.id]?.data || {});
  }, [editor.styles[data.id]]);

  const handleClearError = (field) => {
    setErrors(e => omit(e, field));
  };

  const handleChangeLabel = (label) => {
    handleClearError('label');
    setLabel(label);
  };

  const handleSave = () => {
    const { validated, validation, ...data } = validateNode({ ...nodeData, label, description, ...styles });

    if (!validation.valid) {
      return setErrors(validation.errors);
    }

    onSave(data, { ...styles, validated, validation, label, description });

    if (onboarding.enabled(6)){
      onboarding.nextStep();
    }
  };

  const saveDraft = () => {
    onSave({ ...nodeData }, { ...styles, label, description });
  }

  const handleDiscard = () => {
    setNodeData(data);
    setStyles(editor.styles[data.id]?.data || {})
    onClose();
  }

  const resolveTemplate = () => {
    switch (nodeData.name) {
      case WorkflowNodes.SEND_EMAIL:
        return options.email.find(by(nodeData.email_id));
      case WorkflowNodes.VIBER:
        return options.viber.find(by(nodeData.viber_id));
      case WorkflowNodes.SEND_SMS:
        return options.sms.find(by(nodeData.sms_id));
      case WorkflowNodes.API_REQUEST:
        return options.webhook.find(by(nodeData.api_request_id));
      case WorkflowNodes.WEBPUSH:
        return options.web_push.find(by(nodeData.webpush_id));
      case WorkflowNodes.MOBILE_PUSH:
        return options.mobile_push.find(by(nodeData.mobile_push_id));
      default:
        return null;
    }
  };

  const handleEditTemplateConfirm = () => {
    switch (nodeData.name) {
      case WorkflowNodes.SEND_EMAIL:
        return navigate(`${Paths.TEMPLATES_EMAIL}/vce/${nodeData.email_id}?view=0&ff=1`);
      case WorkflowNodes.VIBER:
        return navigate(`${Paths.TEMPLATES_VIBER}/${nodeData.viber_id}?view=0&ff=1`);
      case WorkflowNodes.SEND_SMS:
        return navigate(`${Paths.TEMPLATES_SMS}/${nodeData.sms_id}?view=0&ff=1`);
      case WorkflowNodes.API_REQUEST:
        return navigate(`${Paths.TEMPLATES_API_REQUEST}/${nodeData.api_request_id}?view=0&ff=1`);
      case WorkflowNodes.WEBPUSH:
        return navigate(`${Paths.TEMPLATES_WEBPUSH}/${nodeData.webpush_id}?view=0&ff=1`);
      case WorkflowNodes.MOBILE_PUSH:
        return navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/${nodeData.mobile_push_id}?view=0&ff=1`);
      default:
        return null;
    }
  };

  const handleEditTemplate = () => {
    if (resolveTemplate()?.workers?.length) {
      return editTemplateModal.open();
    }

    handleEditTemplateConfirm();
  };

  const getPermissionName = () => {
    let permission = '';
    switch (data.name) {
      case WorkflowNodes.SEND_SMS:
        permission = UserPermissions.UPDATE_SMS_NODES;
        break;
      case WorkflowNodes.SEND_EMAIL:
        permission = UserPermissions.UPDATE_EMAIL_NODES;
        break;
      case WorkflowNodes.WEBPUSH:
        permission = UserPermissions.UPDATE_WEBPUSH_NODES;
        break;
      case WorkflowNodes.MOBILE_PUSH:
        permission = UserPermissions.UPDATE_MOBILE_PUSH_NODES;
        break;
      case WorkflowNodes.API_REQUEST:
        permission = UserPermissions.UPDATE_API_REQUEST_NODES;
        break;
      case WorkflowNodes.VIBER:
        permission = UserPermissions.UPDATE_VIBER_NODES;
        break;
      case WorkflowNodes.PUSH_DISCOUNT:
        permission = UserPermissions.UPDATE_DISCOUNT_NODES;
        break;
      default:
        permission = UserPermissions.UPDATE_WORKFLOWS;
    }

    return {
      permissionName: permission,
      shouldCheck: permission && id && (!!~[WorkflowNodes.SEND_SMS, WorkflowNodes.SEND_EMAIL, WorkflowNodes.WEBPUSH, WorkflowNodes.MOBILE_PUSH, WorkflowNodes.API_REQUEST, WorkflowNodes.VIBER, WorkflowNodes.PUSH_DISCOUNT].indexOf(data.name)),
    }
  };

  const { shouldCheck, permissionName } = getPermissionName();

  return (
    <>
      <Modal
        opened={opened && !editTemplateModal.opened}
        onClose={onClose}
        fullScreen
        right
        title={info[data.name] ? (
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <span>{data.label} {p('settings')}</span>
            <Tooltip title={p(info[data.name])}>
              <div style={{ marginLeft: 8 }}>
                <Icon color="#fff" name="Info-icon" size={18} />
              </div>
            </Tooltip>
          </div>
        ) : `${data.label} ${p('settings')}`}
        style={{ top: '0px', maxHeight: '100vh', paddingBottom: '0px' }}
        contentStyles={{ padding: 0, height: '100%' }}
        actionsStyle={{ paddingLeft: 0, paddingRight: 0 }}
        actions={(
          <ActionsRow>
            <Button {...testId('node-settings-modal-cancel')()} appearance="secondary" onClick={handleDiscard} width={160}>{t('actions.cancel')}</Button>
            { shouldCheck ? (
              <WithPermissions name={permissionName}>
                <WithOnboarding zIndex={1} enabled={onboarding.enabled(6)} onboardKey="workflows" text={p('press_save_btn')} placement="topLeft" next={true}>
                  <Button {...testId('node-settings-modal-save')()} disabled={!editable} onClick={handleSave} width={160}>{t('actions.save')}</Button>
                </WithOnboarding>
              </WithPermissions>
            ) : (
              <WithOnboarding zIndex={1} enabled={onboarding.enabled(6)} onboardKey="workflows" text={p('press_save_btn')} placement="topLeft" next={true}>
                <Button {...testId('node-settings-modal-save')()} disabled={!editable} onClick={handleSave} width={160}>{t('actions.save')}</Button>
              </WithOnboarding>
            )
            }
          </ActionsRow>
        )}
      >
        <Container>
          <NodeSettings
            onClearError={handleClearError}
            errors={errors}
            editable={editable}
            onSaveDraft={saveDraft}
            type={data.name}
            value={nodeData}
            styles={styles}
            onStylesChange={setStyles}
            description={description}
            label={label}
            onEditTemplate={handleEditTemplate}
            onLabelChange={handleChangeLabel}
            onDescriptionChange={setDescription}
            onChange={setNodeData}
            options={options}
          />
        </Container>
      </Modal>
      <EditTemplateModal
        opened={editTemplateModal.opened}
        onClose={toWorkflow => {
          if (toWorkflow) {
            handleDiscard();
          }
          editTemplateModal.close();
        }}
        onSubmit={handleEditTemplateConfirm}
        workflows={resolveTemplate()?.workers || []}
      />
    </>
  );
};

export default NodeSettingsModal;
