import React, { useState } from 'react';
import { renderProp } from '@utils';

import { Container, TitleRow, StyledDatePicker, Title } from './styled';
import { Icon } from '@components';
import { Error } from '@components/inputs/Select/styled';
import { Dropdown, Tooltip } from 'antd';
import { useModalState, useTranslation } from '@hooks';
import moment from 'moment';
import { Overlay } from '@components/inputs/RangePicker/styled';
import { Calendar } from 'react-date-range';

const DatePicker = ({
  title,
  renderRightTitle,
  error,
  tooltipError,
  containerStyle,
  oldCalendar = false,
  getPopupContainer = () => document.body,
  disabled = false,
  testId,
  ...props
}, ref) => {
  const { e } = useTranslation();
  const pickerModal = useModalState();

  const handleSelect = (v) => {
    props.onChange(moment(v), moment(v).format('YYYY-MM-DD'));
    pickerModal.close();
  }

  const handleChange = (v, ...any) => {
    if (!v) {
      return props.onChange(undefined);
    }

    return props.onChange(v, ...any);
  };

  return (
    <Container data-testid={testId + '-container'} style={containerStyle}>
      {title && (
        <TitleRow>
          <Title>{title}</Title>
          {renderProp(renderRightTitle)}
        </TitleRow>
      )}
      <Dropdown
        placement="bottomCenter"
        trigger={['click']}
        className={oldCalendar ? 'old-date-picker' : ''}
        onOpenChange={pickerModal.toggle}
        open={pickerModal.opened}
        overlay={oldCalendar ? (<></>) : (
          <Overlay>
            <Calendar
              date={props.value ? moment(props.value).toDate() : new Date()}
              onChange={handleSelect}
            />
          </Overlay>
        )}
      >
        <StyledDatePicker
          getPopupContainer={getPopupContainer}
          disabled={disabled}
          popupClassName={oldCalendar ? 'old-date-picker-comp' : 'date-picker-comp'}
          error={error || tooltipError}
          placeholder=""
          inputProps={{ autoComplete: 'asdasdasda' }}
          autoComplete="asdasdas"
          suffixIcon={(
            <Icon name="Calendar1" size={16} color="#909399"  />
          )}
          {...props}
          ref={ref}
          onChange={handleChange}
        />
      </Dropdown>
      {tooltipError && (
        <Tooltip title={e(tooltipError)} placement="bottom">
          <svg style={{ marginLeft: '6px', position: 'absolute', top: title ? 38 : 10, right: 42 }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill="#C8402F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
          </svg>
        </Tooltip>
      )}
      {error && <Error>{e(error)}</Error>}
    </Container>
  );
};

export default React.forwardRef(DatePicker);
