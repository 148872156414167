import React from 'react';
import { Tooltip } from 'antd';
import { Icon, Switch } from '@components';

import { Container, Title } from './styled';
import { UnderlinedText } from '../../../../../../text';
import { useTranslation } from '@hooks';

const StrictOrderSwitch = ({ testId,checked, onChange }) => {
  const { p } = useTranslation('funnel_analytics');

  return (
    <Container data-testid={testId}>
      <Title>
        <UnderlinedText text={p('strict_order')} style={{ fontSize: 14, lineHeight: '100%'}} />
        <Tooltip title={p('strict_order_description')}>
          <div style={{ display: 'flex', alignSelf: 'center' }}>
            <Icon style={{ marginLeft: 4, marginRight: 8 }} name="Info-icon" color="#909399" size={12} />
          </div>
        </Tooltip>
      </Title>
      <Switch testId="strict-order-switch" checked={checked} onChange={onChange} />
    </Container>
  );
};

export default StrictOrderSwitch;
