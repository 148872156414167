import React from 'react';
// import 'zone.js';
// import 'zone.js/plugins/zone-patch-message-port';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { persistor, store } from '@store';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.min.js';
import './index.css';
import { createRoutesFromElements, RouterProvider, Routes } from 'react-router';
import { createBrowserRouter, Route } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
  if (window.location.host.indexOf('dev') !== -1) {
    window.document.title = 'retainly.app-dev';
  } else if (window.location.host.indexOf('stage') !== -1) {
    window.document.title = 'retainly.app-stage';
  } else {
    window.document.title = 'retainly.app';
  }

  Sentry.init({
    dsn: "https://01c5cab9b94099c8ee4c96602154ab53@sentry.velitech.com/14",
    integrations: [new Integrations.BrowserTracing()],
    environment: !!~window.location.host.indexOf('stage') ? 'stage' : 'production',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === 'development') {
  window.document.title = 'retainly.app-local';
}

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'production') {
  window.document.title = 'retainly.app';
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/*" element={<App />} errorElement={null} />
));

createRoot(document.getElementById('root')).render((
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
