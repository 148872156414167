import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { by, moment } from '@utils';
import {
  useModalState,
  usePermissions,
  useTable,
  useTranslation
} from '@hooks';
import {
  Button,
  DateCell,
  DatePicker,
  Header,
  HeaderPagination,
  Icon,
  IconButton,
  Input,
  Link,
  Multiselect,
  SortableTitle,
  Table,
  UserSelect, WithPermissions, CreateEventModal, EventType, NameCell, TableActions, EventStatus
} from '@components';
import {
  eventsListSelector,
  eventsLoadingSelector,
  myIntegrationsSelector
} from '@store/selectors';
import { listEvents, listIntegrations, myListIntegrations } from '@store/actions/creators';
import { extract, identity } from '@utils';
import { Paths, UserPermissions } from '@constants';

import {
  Container,
  FiltersContainer,
  FiltersRow,
  FilterField,
  Row,
  SearchFilter,
  Disclaimer,
  DisclaimerHint,
  TitleContainer,
  IconContainer
} from './styled';
import { Tooltip } from 'antd';

const typeOptions = [
  {
    label: 'types.dynamic',
    value: '1',
  },
  {
    label: 'types.custom',
    value: '2',
  },
  {
    label: 'System',
    value: '3',
  },
];

const EventsPage = () => {
  const dispatch = useDispatch();
  const { p, t } = useTranslation('events_page');
  const createModal = useModalState(window.location.search?.indexOf('defaultCreate') !== -1);
  const events = useSelector(eventsListSelector);
  const loading = useSelector(eventsLoadingSelector);
  const permissions = usePermissions();
  const connections = useSelector(myIntegrationsSelector);
  const connection = connections?.data?.find?.(by('integration_key', 'javascript'));
  const showDisclaimer = !connection?.config?.domains;
  const table = useTable({
    filtersConfig: {
      name: '',
      '$type': [],
      user_id: [],
      start_date: '',
      end_date: '',
    },
    initialOrdering: 'created_at',
    requestDispatcher: (params) => {
      dispatch(listEvents(params));
    },
  });

  useEffect(() => {
    dispatch(listIntegrations());
    dispatch(myListIntegrations());
  }, []);

  const handleOpenModal = () => {
    if (permissions.allowed(UserPermissions.CREATE_EVENTS)) {
      createModal.open();
    }
  }

  const columns = [
    {
      title: () => <SortableTitle name="name" ordering={table.ordering}>{t('labels.name')}</SortableTitle>,
      dataIndex: 'name',
      key: 'name',
      render: (name, { payload, label }) => <NameCell dataTestId={`events-label-${name}`} name={label || name} payload={Array.isArray(payload) ? payload.length && payload : payload} />,
    },
    // {
    //   title: () => <SortableTitle>{t('labels.id')}</SortableTitle>,
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: () => <SortableTitle>{t('labels.type')}</SortableTitle>,
      dataIndex: 'type',
      key: 'type',
      render: type => <EventType type={type} />
    },
    {
      title: () => <SortableTitle name="created_at" ordering={table.ordering}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      key: 'date',
      render: d => <DateCell date={d} />
    },
    {
      title: () => <SortableTitle>{t('labels.user')}</SortableTitle>,
      dataIndex: 'user_name',
      key: 'user',
      render: (name, { user_id }) => (name || user_id) ? <Link to={`${Paths.USERS_SETTINGS}/${user_id}`}>{name}</Link> : p('automatically')
    },
    {
      title: () => <SortableTitle>{t('labels.status')}</SortableTitle>,
      dataIndex: 'used',
      key: 'status',
      render: (status, { workers }) => <EventStatus status={status} workflows={workers} />
    },
    {
      title: () => <SortableTitle right>{t('labels.actions')}</SortableTitle>,
      dataIndex: 'id',
      key: 'actions',
      render: (id, { type, workers, name, user_id }) => <TableActions name={name} canDelete={(name || user_id) && type !== 3} id={id} type={type} workflows={workers} onRefresh={table.applyFilters} />
    },
  ];

  return (
    <Container>
      <Header
        title={(
          <TitleContainer>
            {p('title')}
            <Tooltip trigger={['hover']} placement="right" title={p('events_description')}>
              <IconContainer>
                <Icon size={14} color="#109DFB" style={{ marginLeft: 8 }} name="Info-icon" />
              </IconContainer>
            </Tooltip>
          </TitleContainer>
        )}
        left={(
          <HeaderPagination
            onPerPageChange={table.changePerPage}
            page={table?.pagination?.page}
            onPageChange={table.changePage}
            perPage={table.pagination.perPage}
            total={events?.meta?.total}
          />
        )}
        right={(
          <>
            <IconButton
              active={table.filters.opened}
              onClick={table.filters.toggle}
              style={{ marginRight: '10px' }}
              icon="Filters"
            />
            <WithPermissions name={UserPermissions.CREATE_EVENTS}>
              <Button
                icon={<Icon style={{ marginRight: '4px' }} size={16} name="Add-new" />}
                width={107}
                data-testid="events-create-btn"
                onClick={handleOpenModal}
              >
                <div>{t('actions.create')}</div>
              </Button>
            </WithPermissions>
          </>
        )}
        opened={table.filters.opened}
        content={(
          <FiltersContainer>
            <FiltersRow>
              <SearchFilter>
                <Input
                  value={table.filters.filters['name']}
                  onChange={table.filters.onChange('name')}
                  title={t('labels.search_by_name')}
                />
              </SearchFilter>
              <Row>
                <FilterField style={{ marginRight: '21px' }}>
                  <Multiselect
                    placeholder={t('labels.all')}
                    title={t('labels.type')}
                    options={typeOptions.map(({ value, label }) => ({ value, label: t(label) }))}
                    value={table.filters.filters['$type']}
                    onChange={table.filters.onChange('$type', identity)}
                  />
                </FilterField>
                <FilterField style={{ marginRight: 21 }}>
                  <DatePicker
                    containerStyle={{ marginRight: 0 }}
                    style={{ width: '100%' }}
                    value={table.filters.filters['start_date'] ? moment(table.filters.filters['start_date']) : undefined}
                    onChange={table.filters.onChange('start_date', (_, ds) => ds)}
                    type="date"
                    title={t('labels.date_from')}
                  />
                </FilterField>
                <FilterField>
                  <DatePicker
                    containerStyle={{ marginRight: 0 }}
                    style={{ width: '100%' }}
                    value={table.filters.filters['end_date'] ? moment(table.filters.filters['end_date']) : undefined}
                    onChange={table.filters.onChange('end_date', (_, ds) => ds)}
                    type="date"
                    title={t('labels.date_to')}
                  />
                </FilterField>
              </Row>
            </FiltersRow>
            <FiltersRow style={{ alignItems: 'flex-end' }}>
              <FilterField style={{ marginBottom: '16px' }}>
                <UserSelect
                  isMulti
                  placeholder={t('labels.all')}
                  title={t('labels.user')}
                  value={table.filters.filters['user_id']}
                  onChange={table.filters.onChange('user_id', identity)}
                />
              </FilterField>
              <Row style={{ marginBottom: '16px' }}>
                <Button width={100} onClick={table.applyFilters} style={{ marginRight: '10px' }}>{t('actions.search')}</Button>
                <Button width={100} appearance="discard" onClick={table.discardFilters}>{t('actions.discard')}</Button>
              </Row>
            </FiltersRow>
          </FiltersContainer>
        )}
      />
      {showDisclaimer && (
        <Disclaimer>
          <svg style={{ marginRight: 8 }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.9836 1.51552C8.84938 1.23601 8.63892 1.00011 8.37647 0.834993C8.11402 0.669879 7.81026 0.582275 7.50019 0.582275C7.19012 0.582275 6.88636 0.669879 6.62391 0.834993C6.36145 1.00011 6.15099 1.23601 6.01677 1.51552L1.28244 10.8284C0.716021 11.9403 1.43177 13.4167 2.76527 13.4167H12.2345C13.5686 13.4167 14.2832 11.9409 13.7179 10.8284L8.9836 1.51552ZM7.50019 4.66669C7.6549 4.66669 7.80327 4.72815 7.91267 4.83755C8.02206 4.94694 8.08352 5.09531 8.08352 5.25002V8.16669C8.08352 8.3214 8.02206 8.46977 7.91267 8.57917C7.80327 8.68857 7.6549 8.75002 7.50019 8.75002C7.34548 8.75002 7.1971 8.68857 7.08771 8.57917C6.97831 8.46977 6.91685 8.3214 6.91685 8.16669V5.25002C6.91685 5.09531 6.97831 4.94694 7.08771 4.83755C7.1971 4.72815 7.34548 4.66669 7.50019 4.66669ZM7.50019 9.62502C7.6549 9.62502 7.80327 9.68648 7.91267 9.79588C8.02206 9.90527 8.08352 10.0536 8.08352 10.2084V10.5C8.08352 10.6547 8.02206 10.8031 7.91267 10.9125C7.80327 11.0219 7.6549 11.0834 7.50019 11.0834C7.34548 11.0834 7.1971 11.0219 7.08771 10.9125C6.97831 10.8031 6.91685 10.6547 6.91685 10.5V10.2084C6.91685 10.0536 6.97831 9.90527 7.08771 9.79588C7.1971 9.68648 7.34548 9.62502 7.50019 9.62502Z" fill="#FFBB00"/>
          </svg>
          <DisclaimerHint>
            {p('sdk_hint')}
          </DisclaimerHint>
        </Disclaimer>
      )}
      <WithPermissions name={UserPermissions.REVIEW_EVENTS} hidden>
        <Table
          loading={loading}
          columns={columns}
          dataSource={events?.data || []}
          rowKey={extract('id')}
        />
      </WithPermissions>
      <CreateEventModal
        opened={createModal.opened}
        onClose={createModal.close}
        onRefresh={table.refresh}
      />
    </Container>
  );
};

export default EventsPage;
