import React from 'react';
import { Button, Modal } from '@components';
import { useTranslation } from '@hooks';

import { Container, Title } from './styled';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/selectors';

const CustomSubscriptionExpiredModal = ({ opened, onClose }) => {
  const { t, p } = useTranslation('dashboard_page');
  const user = useSelector(userSelector);

  return (
    <Modal title={p('subscription_expired')} {...{ opened, onClose }}>
      <Container>
        <Title>{p(user?.isOwner ? 'custom_subscription_expired_long_owner' : 'custom_subscription_expired_long')}</Title>
        <Button width={160} onClick={onClose}>{t('actions.ok_thanks')}</Button>
      </Container>
    </Modal>
  );
};

export default CustomSubscriptionExpiredModal;
