import styled from 'styled-components';

export const WebhookPreview = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  padding-bottom: 4px;
  border-bottom: 1px dashed #109DFB;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  justify-self: left;
  align-self: flex-start;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
  padding: 20px;
  max-width: 400px;
`;

export const Url = styled.div`
  font-family: Rubik-Regular;
  font-size: 12px;
  line-height: 14px;
  color: #303133;
  margin-bottom: 6px;
  padding-left: 4px;
  
  & > span {
    font-size: 12px;
    line-height: 14px;
    color: #909399;
  }
`;

export const UrlOverlay = styled.div`
  position: relative;
  display: flex;  
  align-items: center;
  cursor: pointer;
  //height: 32px;
  overflow: hidden;
`;

export const UrlTooltip = styled.div`
  padding: 8px 10px;
  color: #fff;
  font-family: Rubik-Regular;
  font-size: 12px;
  line-height: 100%;
  flex: 1;
  max-width: 220px;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
`;

export const IconContainer = styled.div`
  border-left: 1px solid #65676A;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
`;
