import React, {useEffect, useState} from 'react';
import {
  Button,
  Modal,
  SearchSelect,
  WithPermissions
} from '@components';

import { segmentsOptionsSelector, workflowOptionsSelector } from '@store/selectors';
import { by, testId } from '@utils';

import {
  Container,
  Title
} from './styled';
import {useSelector} from "react-redux";
import {Row} from "../NodeSettingsModal/components/PushDiscoundNodeSettings/styled";
import { Checkbox } from 'antd';
import { useTranslation } from '@hooks';
import { UserPermissions } from '@constants';

const ExitCriterionSettingsModal = ({ opened, onConfirm, onClose, workflowExitResource, exitCreteriaId, disabled = false }) => {
  const options = useSelector(workflowOptionsSelector);
  const [type, setType] = useState(null);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [exitCriterion, setTExitCriterion] = useState(null);
  const [notCriterion, setNotCriterion] = useState("1");
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(true);
  const { p, t } = useTranslation('workflow_page')
  const segmentOptions = useSelector(segmentsOptionsSelector);

  useEffect(() => {
    if (exitCreteriaId) {
      setType('SEGMENT');
      setNotCriterion(null);
      setTExitCriterion(exitCreteriaId)
    } else {
      setType(null);
      setNotCriterion("1");
      setTExitCriterion(null)
    }
  }, [exitCreteriaId]);

  useEffect(() => {
    setDisabledEdit(disabled)
  }, [disabled]);


  const handleChangeNotCriterion = ({ target: { checked } }) => {
    setChecked(checked)
    if (checked) {
      setType(null);
      setTExitCriterion(null)
      setError('')
    }
    setNotCriterion(String(+checked));
  }

  const handleClose = () => {
    if (workflowExitResource) {
      setType(workflowExitResource.resourcable_type);
      setNotCriterion(null);
      setTExitCriterion(workflowExitResource.resourcable_type)
    } else {
      setNotCriterion("1");
      setType(null)
      setTExitCriterion(null)
    }
    onClose();
    setError("")
  };

  const handleSave = () => {
    if (!exitCriterion && !checked){
      return setError(p('choose_exit_criteria'))
    }
    onConfirm([{ type: 'segment', segment_id: exitCriterion }]);
  };

  const handleTemplateChange = (exit_criterion) => {
    setTExitCriterion(exit_criterion);
    setNotCriterion(null);
    setError('');
  }

  const resourceOptions = segmentOptions.segments
      ?.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <Modal
      title={p('exit_program_settings')}
      opened={opened}
      bigger
      contentStyles={{ paddingTop: '25px' }}
      onClose={handleClose}
      centerFooter={disabledEdit}
      actions={disabledEdit ? (
          <>
            <Title>{p('to_change_settings_create_new_version')}</Title>
          </>
      ) :(
          <>
            <Button {...testId('exit-criteria-modal-cancel')()} onClick={handleClose} appearance="secondary" width={160}>{t('actions.cancel')}</Button>
            <WithPermissions name={UserPermissions.UPDATE_WORKFLOWS}>
              <Button {...testId('exit-criteria-modal-save')()} onClick={handleSave} width={160}>{t('actions.save')}</Button>
            </WithPermissions>
          </>
      )}
    >
      <Container>
        <Row style={{ alignItems: 'flex-end', }}>
          <SearchSelect
            {...testId('exit-criteria-modal-segment-select')()}
            value={exitCriterion}
            style={{ width: '220px' }}
            disabled={+notCriterion}
            onChange={handleTemplateChange}
            options={resourceOptions || []}
            label={t('labels.type')}
            title={t('labels.segment')}
            error={error}
          />

          <WithPermissions name={UserPermissions.UPDATE_WORKFLOWS}>
            <Checkbox
              {...testId('exit-criteria-modal-no-criteria')()}
              style={{ padding: "0px 0px 8px 20px", marginTop: 22 }}
              checked={+notCriterion}
              onChange={handleChangeNotCriterion}
            >
              {p('there_is_no_exit_criteria')}
            </Checkbox>
          </WithPermissions>
        </Row>
      </Container>
    </Modal>
  );
};

export default ExitCriterionSettingsModal;
