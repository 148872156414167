import React, { useEffect, useState } from 'react';

import {
  Container,
  CopyButton, CreateButton,
  TableCell,
  TableRow,
  MenuItem,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicVariablesSelector } from '@store/selectors/fields';
import {
  createDynamicVariable,
  deleteDynamicVariable,
  getDynamicVariables,
  updateDynamicVariable
} from '@store/actions/creators/fields';
import { notify, sequence } from '@utils';
import { CopyHandle, MoreButton, WithPermissions } from '@components';
import { useErrors, useModalState, useTranslation } from '@hooks';
import {
  CreateDynamicVariableModal
} from '@components/modals/TemplatesGeneralAttributesDrawer/components/DynamicAttributes/components';
import { Menu } from 'antd';
import { UserPermissions } from '@constants';
import { Colors } from '@res';

const DynamicAttributes = () => {
  const { t, p } = useTranslation('templates');
  const dynamicVariables = useSelector(dynamicVariablesSelector);
  const dispatch = useDispatch();
  const createModal = useModalState();
  const updateModal = useModalState();
  const [updateValue, setUpdateValue] = useState(null);
  const errors = useErrors();

  useEffect(() => {
    dispatch(getDynamicVariables());
  }, []);

  const resolveCopyData = (key, name) => {
    return `{{dynamic.${name}}}`;
  };

  const handleCreate = (value, cb) => {
    dispatch(createDynamicVariable({
      ...value,
      onError: errors.handleRequestError,
      onSuccess: () => {
        dispatch(getDynamicVariables());
        createModal.close();
        cb?.();
        notify('success', t('labels.success'), p('dynamic_variable_created'));
      },
    }));
  };

  const handleUpdateClick = (v) => {
    setUpdateValue(v);
    updateModal.open();
  };

  const handleUpdate = (value, cb) => {
    dispatch(updateDynamicVariable({
      ...value,
      onError: errors.handleRequestError,
      onSuccess: () => {
        dispatch(getDynamicVariables());
        updateModal.close();
        cb?.();
        notify('success', t('labels.success'), p('dynamic_variable_updated'));
      }
    }))
  };

  const handleDelete = (id) => () => {
    dispatch(deleteDynamicVariable({
      id,
      onSuccess: () => {
        dispatch(getDynamicVariables());
        notify('success', t('labels.success'), p('dynamic_variable_deleted'))
      },
    }))
  };

  return (
    <Container>
      <TableRow>
        <TableCell widthPercent={30} $header>
          {t('labels.label')}
        </TableCell>
        <TableCell widthPercent={50} $header>
          {t('labels.default_value')}
        </TableCell>
        <TableCell widthPercent={20} alignEnd $header>
          {t('labels.action')}
        </TableCell>
      </TableRow>
      <WithPermissions name={UserPermissions.REVIEW_DYNAMIC_ATTRIBUTES} hidden>
        {dynamicVariables.map(({ name, default_value, id, label, used }) => (
          <TableRow key={id}>
            <TableCell widthPercent={30}>{label}</TableCell>
            <TableCell widthPercent={50} withPadding>
              {default_value}
            </TableCell>
            <TableCell widthPercent={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} alignEnd>
              <CopyHandle align="flex-end" data={resolveCopyData(id, name)}>
                <CopyButton>{p('copy')}</CopyButton>
              </CopyHandle>
              <MoreButton
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginLeft: 4
                }}
                iconSize={22}
                iconStyles={{ height: '22px' }}
                menu={(
                  <Menu style={{ borderRadius: '10px' }}>
                    <Menu.Item key="1">
                      <WithPermissions name={UserPermissions.UPDATE_DYNAMIC_ATTRIBUTES}>
                        <MenuItem onClick={() => handleUpdateClick({ name, default_value, id, label })}>{t('actions.edit_default_value')}</MenuItem>
                      </WithPermissions>
                    </Menu.Item>
                    <Menu.Item disabled={used} key="2" onClick={handleDelete(id)}>
                      <MenuItem style={{ color: Colors.Text.DANGER }}>
                        <WithPermissions name={UserPermissions.DELETE_DYNAMIC_ATTRIBUTES}>
                          {t('actions.delete_attribute')}
                        </WithPermissions>
                      </MenuItem>
                    </Menu.Item>
                  </Menu>
                )}>
              </MoreButton>
            </TableCell>
          </TableRow>
        ))}
      </WithPermissions>
      <CreateButton onClick={createModal.open}>
        <svg style={{ marginRight: 9 }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.875 8H8.5V5.625C8.5 5.55625 8.44375 5.5 8.375 5.5H7.625C7.55625 5.5 7.5 5.55625 7.5 5.625V8H5.125C5.05625 8 5 8.05625 5 8.125V8.875C5 8.94375 5.05625 9 5.125 9H7.5V11.375C7.5 11.4437 7.55625 11.5 7.625 11.5H8.375C8.44375 11.5 8.5 11.4437 8.5 11.375V9H10.875C10.9437 9 11 8.94375 11 8.875V8.125C11 8.05625 10.9437 8 10.875 8Z" fill="white"/>
          <path d="M8 1.5C4.13438 1.5 1 4.63438 1 8.5C1 12.3656 4.13438 15.5 8 15.5C11.8656 15.5 15 12.3656 15 8.5C15 4.63438 11.8656 1.5 8 1.5ZM8 14.3125C4.79063 14.3125 2.1875 11.7094 2.1875 8.5C2.1875 5.29063 4.79063 2.6875 8 2.6875C11.2094 2.6875 13.8125 5.29063 13.8125 8.5C13.8125 11.7094 11.2094 14.3125 8 14.3125Z" fill="white"/>
        </svg>
        Add dynamic attribute
      </CreateButton>
      <WithPermissions name={UserPermissions.CREATE_DYNAMIC_ATTRIBUTES}>
        <CreateDynamicVariableModal
          opened={createModal.opened || updateModal.opened}
          onClose={sequence(createModal.close, updateModal.close)}
          isUpdate={updateModal.opened}
          value={updateValue}
          errors={errors}
          onConfirmCreate={handleCreate}
          onConfirmUpdate={handleUpdate}
        />
      </WithPermissions>
    </Container>
  );
};

export default DynamicAttributes;
