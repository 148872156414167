import en_GB from 'antd/lib/locale-provider/en_GB';
import ka_GE from 'antd/lib/locale-provider/ka_GE';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/selectors';

const resolveLocale = l => ({
  'en': en_GB,
  'ge': ka_GE,
})[l] || en_GB;

const useANTDLocale = () => {
  const user = useSelector(userSelector);

  return resolveLocale(user?.language);
};

export default useANTDLocale;
