import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 20px;
`;

export const Header = styled.div`
  background: #F9FBFF;
  border-bottom: 1px solid #DCDFE6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 64px;
  padding: 0 20px;
`;

export const Footer = styled.div`
  border-top: 1px solid #DCDFE6;
  padding: 14px 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${styledProp('$d', css`
    display: flex;
  `, css`
    display: none;
  `)}
`;

export const FooterCaption = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #8B8E9F;
  margin-right: 6px;
`;

export const FooterIcon = styled.div`
  display: flex;
  flex: 0;
  transition: all 290ms ease;
  
  ${styledProp('$r', css`
    transform: rotate(180deg);
  `, css`
  `)}
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 18px;
  line-height: 21px;
  color: #109DFB;
`;

export const Content = styled.div`
  padding: 20px;
  
  ${styledProp('$h', css`
    display: flex;
    flex-wrap: wrap;
  `)}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
