import { useState } from 'react';
import { mapEntries, notify, pipe } from '@utils';
import useTranslation from './useTranslation';

const joinArrays = v => mapEntries(v, ([vk, vv]) => [vk, Array.isArray(vv) ? vv.join(', ') : vv]);

const replace = [
  'name',
  'program.name',
  'schedule.month_day',
  'schedule.start_date',
  'schedule.end_date',
  'permission',
  'app integration id',
  'app integration name',
  'ad account id',
  'group id'
];

export const translateError = (error, translate) => {
  const replacer = pipe(...replace.map(searchValue => m => m.replaceAll(searchValue, translate(searchValue))));

  try {
    const { k, v } = JSON.parse(error);

    return replacer(translate(k, v && joinArrays(v)));
  } catch (e) {

    return replacer(translate(error));
  }
};
const mapErrors = (errors, translate) => {
  return mapEntries(errors, ([key, subErrors]) => {
    const error = Array.isArray(subErrors) ? subErrors[0] : subErrors;

    if (typeof error === 'object' && subErrors !== null) {
     return mapErrors(error, translate);
    }


    if (!error) {
      return [key, error];
    }

    return [key, translateError(error, translate)];
  });
};

const useErrors = (notifyErrors = [], notifyDefaultStatuses = []) => {
  const [errors, setErrors] = useState({});
  const { e, t } = useTranslation('');

  const setError = (name, message) => {
    setErrors(es => ({ ...es, [name]: translateError(message, e) }));
  };

  const clearError = (field) => {
    setErrors(es => ({ ...es, [field]: '' }))
  };

  const clear = () => {
    setErrors({});
  }

  const handleRequestError = (error) => {
    if (error.response?.data?.errors) {
      const { errors: apiErrors } = error.response?.data;
      setErrors(mapErrors(apiErrors, e));

      const [, [message]] = Object.entries(apiErrors).find(([err]) => ~notifyErrors.indexOf(err)) || [, []];

      if (message) {
        notify('error', t('labels.error'), translateError(message, e));
      }
    } else {
      setErrors({});
    }

    if (notifyDefaultStatuses.indexOf(error.response?.status) !== -1) {
      notify('error', t('labels.error'), translateError(error.response?.data?.message, e));
    }
  };

  return {
    errors,
    setError,
    clear,
    setErrors,
    clearError,
    handleRequestError,
    isEmpty: () => Object.values(errors).every(e => !e),
  }
};

export default useErrors;
