import { all, takeLatest, put } from 'redux-saga/effects';
import { PromoCodesActionTypes } from '../../actions/types';

import { Endpoints } from "@constants";
import { createRequestAction } from "@store/lib/utils/creators";

function* archivePromoCodeSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/promo_code/archive',
      body: { id: action.payload.id },
    })
  );
}

function* deletePromoCodeSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_PROMO_CODE,
      body: { id: action.meta.id },
    })
  );
}

function* unArchivePromoCodeSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/promo_code/un_archive',
      body: { id: action.payload.id },
    })
  );
}

function* savePromoCodeSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.PROMO_CODES,
      body: {
        id: action.meta.id,
        ...action.payload.fields,
      },
    })
  );
}

function* publishPromoCodeSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.PUBLISH_PROMO_CODE,
      body: { id: action.meta.id },
    })
  );
}

function* getPromoCodesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.PROMO_CODES,
    })
  );
}

function* getEditMetadataSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `${Endpoints.PROMO_CODES}/${action.meta.id}/edit`,
    })
  );
}

export default function* promoCodesRootSaga() {
  yield all([
    takeLatest(PromoCodesActionTypes.GET, getPromoCodesSaga),
    takeLatest(PromoCodesActionTypes.GET_EDIT_METADATA, getEditMetadataSaga),
    takeLatest(PromoCodesActionTypes.DELETE, deletePromoCodeSaga),
    takeLatest(PromoCodesActionTypes.PUBLISH, publishPromoCodeSaga),
    takeLatest(PromoCodesActionTypes.SAVE, savePromoCodeSaga),
    takeLatest(PromoCodesActionTypes.ARCHIVE, archivePromoCodeSaga),
    takeLatest(PromoCodesActionTypes.UN_ARCHIVE, unArchivePromoCodeSaga),
  ]);
}
