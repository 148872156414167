import React, { useEffect } from 'react';
import { useParams, useNavigate as useReactRouterNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { hashAppId, isSameAppId, removeAppFromPath } from '@utils';
import { useNavigate } from '@hooks';
import { demoWorkspacesSelector, loggedInSelector } from '@store/selectors';
import {
  changeImportStep,
  getDemoWorkspaces,
  getUser,
  listWorkspaces,
  switchToDemoWorkspace,
  switchWorkspaces
} from '@store/actions/creators';
import { Paths } from '@constants';
import { StringParam, useQueryParam } from 'use-query-params';

const SwitchAppPage = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const nativeNavigate = useReactRouterNavigate();
  const user = useSelector(loggedInSelector);
  const dispatch = useDispatch();
  const demoWorkspaces = useSelector(demoWorkspacesSelector);
  const [redirect] = useQueryParam('r', StringParam);

  useEffect(() => {
    if (user?.app?.id && !isSameAppId(user?.app?.id, appId)) {
      const id = (user?.app_options || []).find(id => isSameAppId(id, appId));
      const demoId = (demoWorkspaces?.data || []).find(({ id }) => isSameAppId(id, appId))?.id;

      if (demoId) {
        return dispatch(switchToDemoWorkspace({
          app_id: demoId,
          onError: () => {
            navigate(Paths.DASHBOARD, { replace: true });
          },
          onSuccess: () => {
            dispatch(listWorkspaces());
            dispatch(getDemoWorkspaces());
            nativeNavigate(`/app/${hashAppId(demoId)}${decodeURIComponent(redirect)}`, { replace: true });
          }
        }));
      }

      if (!id) {
        return nativeNavigate(`/app/${hashAppId(user?.app?.id)}${removeAppFromPath(window.location.pathname + window.location.search)}`, { replace: true });
      }

      dispatch(switchWorkspaces({
        id,
        onError: () => {
          navigate(Paths.DASHBOARD, { replace: true });
        },
        onSuccess: () => {
          dispatch(changeImportStep({ step: 'upload' }));
          nativeNavigate(`/app/${hashAppId(id)}${decodeURIComponent(redirect)}`, { replace: true });
        }
      }));
    }
  }, [appId, user?.app?.id, redirect]);

  return "Switching to another workspace..."
};

export default SwitchAppPage;
