import styled, { css } from 'styled-components';
import { Device } from '@res';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageContent = styled.div`
  overflow-x: hidden;
  width: 100%;
  transition: width 280ms ease;

  @media screen and ${Device.laptopL} {
    ${styledProp('sidebarOpened', css`
      width: calc(100vw - 220px);
    `, css`
      width: calc(100vw - 48px);
    `)}  
  }

  @media screen and ${Device.laptopL} {  
    ${styledProp('$hideSidebar', css`
      width: 100vw;
    `)}
  }
  
  & > div {
  }
`;
