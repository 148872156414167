import { ApiService } from '../../../services';
import { call, select, put, race, take, actionChannel, fork } from 'redux-saga/effects';
import { accessTokenSelector } from '@store/selectors';
import { UserActionTypes } from '@store/actions/types';
import { resolveFilters } from '@utils';
import { Paths } from '@constants';

let refreshTokenInProgress = false;

function* refreshToken() {
  if (!refreshTokenInProgress) {
    refreshTokenInProgress = true;
    yield put({ type: UserActionTypes.REFRESH_TOKEN });
  }

  const { failure } = yield race({
    success: take(UserActionTypes.REFRESH_TOKEN_SUCCESS),
    failure: take(UserActionTypes.REFRESH_TOKEN_ERROR),
  });

  refreshTokenInProgress = false;

  if (failure) {
    window.location.href = Paths.LOGIN;
    yield put({ type: UserActionTypes.LOGOUT_SUCCESS });
  }
}

export function* watchRequests() {
  const requestChannel = yield actionChannel(action => action.type.endsWith('_REQUEST'));

  while (true) {
    const action = yield take(requestChannel);
    yield fork(handleRequest, action);
  }
}

function* handleRequest(action) {
  const { method = 'get', endpoint, params, headers, body, onUploadProgress, skipRefresh, responseType, disableTimeout = true } = action.payload;
  const accessToken = yield select(accessTokenSelector);

  try {
    const { data, error } = yield call(
      ApiService.call,
      method,
      endpoint,
      body,
      {
        ...(params || {}),
        ...resolveFilters(action?.filters || {}),
        ...(action?.pagination || {}),
        ...(action?.ordering || {}),
      },
      {
        ...(headers || {}),
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
      responseType,
      onUploadProgress,
      disableTimeout,
    );

    if (error) {
      if (error?.response?.status === 401 && !skipRefresh) {
        yield* refreshToken();
        yield put(action);
        return;
      }

      yield put({ type: action.type.replace('_REQUEST', '_ERROR'), payload: { error, data }, meta: action.meta, callbacks: action.callbacks });
    } else {
      yield put({ type: action.type.replace('_REQUEST', '_SUCCESS'), payload: data, callbacks: action.callbacks, meta: action.meta });
    }
  } catch (error) {
    if (error?.response?.status === 401 && !skipRefresh) {
      yield* refreshToken();
      yield put(action);
      return;
    }

    yield put({ type: action.type.replace('_REQUEST', '_ERROR'), payload: error, meta: action.meta, callbacks: action.callbacks });
  }
}

export function* watchSuccess() {
  const requestChannel = yield actionChannel(action => action.type.endsWith('_SUCCESS'));

  while (true) {
    const action = yield take(requestChannel);
    const { payload, callbacks, meta } = action;

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess, payload, meta);
    }
  }
}

export function* watchErrors() {
  const requestChannel = yield actionChannel(action => action.type.endsWith('_ERROR'));

  while (true) {
    const action = yield take(requestChannel);
    const { payload, callbacks, meta } = action;

    if (callbacks?.onError) {
      yield call(callbacks.onError, payload, meta);
    }
  }
}
