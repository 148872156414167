import React from 'react';
import { Slider as ANTDSlider } from 'antd';

import { Container } from './styled.js';

const Slider = ({ value, onChange, min, max, step, ...props }) => {
  return (
    <Container style={props.style}>
      <ANTDSlider
        step={step}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        {...props}
        style={{
          width: '100%',
          height: '0px',
        }}
        trackStyle={{
          background: '#303133',
          height: '2px',
          borderRadius: '0px',
        }}
        handleStyle={{
          background: '#303133',
          width: '10px',
          marginTop: '-4px',
          height: '10px',
          border: 'none',
        }}
      />
    </Container>
  );
}

export default Slider;


