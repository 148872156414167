import { AppActionTypes } from '../types';
import { compose } from '@utils';
import { asCreator, withCallbacks } from '@store/lib';
export const pingApi = compose(
  withCallbacks,
  asCreator(() => ({
    type: AppActionTypes.PING_API,
  }))
);

export const updateRedirects = ({ ...redirects }) => ({
  type: AppActionTypes.UPDATE_REDIRECTS,
  payload: { redirects },
});

export const checkAppLink = compose(
  withCallbacks,
  asCreator(({ hash, email, type, systemType }) => ({
    type: AppActionTypes.CHECK_APP_LINK,
    payload: { hash, email, type, systemType }
  })),
);

export const setTrialBarShown = shown => ({
  type: AppActionTypes.SET_TRIAL_BAR_SHOWN,
  payload: { shown }
});

export const setSidebarOpened = ({ opened }) => ({
  type: AppActionTypes.SET_SIDEBAR_OPENED,
  payload: { opened }
});

export const setHintMode = (enabled) => ({
  type: AppActionTypes.SET_HINT_MODE,
  payload: { enabled },
});

export const skipOnboard = () => ({
  type: AppActionTypes.SKIP_ONBOARD,
});

export const resetOnboard = () => ({
  type: AppActionTypes.RESET_ONBOARD,
});

export const sentOnboardParams = compose(
  withCallbacks,
  asCreator((params) => ({
    type: AppActionTypes.SENT_ONBOARD_PARAMS,
    payload: { params },
  })),
);

export const updateOnboarding = (key, params) => ({
  type: AppActionTypes.UPDATE_ONBOARDING,
  payload: { key, params },
});

export const setMessageChannelsDisclaimerClosed = ({ closed }) => ({
  type: AppActionTypes.SET_MESSAGE_CHANNELS_DISCLAIMER_CLOSED,
  payload: { closed },
});
