import React from 'react';
import { SearchSelect, Select } from '@components';
import { WorkflowNodeItems } from '@constants';
import { useTranslation } from '@hooks';

const createOptions = (p) => WorkflowNodeItems
  .filter(({ type }) => type !== 'entry_point')
  .map(({ type, options, title, }) => ({
    name: type,
    options: options.map(({ title, name }) => ({ value: name, label: p(title) })),
    title,
  }));

const NodeSelect = ({ ...props }) => {
  const { t, p } = useTranslation('workflow_page');

  return (
    <SearchSelect
      label={p('next_nodes')}
      style={{ width: '100%', background: '#fff' }}
      wrapperStyles={{ marginTop: '16px' }}
      optionGroups={createOptions(p)}
      onGroupsVisibilityChange={() => {}}
      groupsVisibility={{ 'connection_channel': true, 'actions': true }}
      {...props}
    />
  );
};

export default NodeSelect;
