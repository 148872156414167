import styled, { css } from 'styled-components';
import { switchStyle, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #fff;
  z-index: 10000;
  border-radius: 15px 15px 13px 13px;
  overflow: visible;

  ${styledProp('fullScreen', ``, `
    max-height: 95vh;
  `)}
`;

export const Title = styled.div`
  display: flex;
  ${styledProp('fullScreen', ``, `
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  `)}
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 30px;
  border-radius: 13px 13px 0 0;
  line-height: 21px;
  color: #fff;
  position: relative;
  font-family: Rubik-Regular;
  font-size: 18px;
  ${switchStyle('appearance', {
    default: `
      background: #4986FD;
    `,
    danger: `
      background: #C8402F;
    `,
  })}
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 14px;
  padding-right: 12px;
`;

export const Content = styled.div`
  ${styledProp('statistics', css`
    padding: 18px 20px 0 20px;
  `, css`
    padding: 30px 20px 0 20px;
  `)}
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const ActionsRow = styled.div`
  display: flex;  
  ${styledProp('centerFooter', `
  justify-content: center;
  `, `
   justify-content: space-between;
  `)}
  
  padding: 0 20px 12px 20px;
`;
