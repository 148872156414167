import React from 'react';
import { Dropdown } from 'antd';
import { Link, UnderlinedText } from '@components';

import { Container, WorkflowsContainer } from './styled';
import { Paths } from '@constants';
import { useTranslation } from '@hooks';

const resolveStatus = (status, draft, t) => {
  switch (status) {
    case 0:
      return draft || t('statuses.draft');
    case 1:
      return t('statuses.used');
    default:
      return '';
  }
};

const SegmentStatus = ({ status, workflows, draft }) => {
  const { p, t } = useTranslation('segments_page');

  const workflowItems = Object.entries(workflows).filter(([key]) => !isNaN(+key)).map(([, wf]) => wf)?.filter(w => w.type === 2);
  const campaigns = Object.entries(workflows).filter(([key]) => !isNaN(+key)).map(([, wf]) => wf)?.filter(w => w.type === 1);

  return (
    <Container draft={!workflowItems.length && !campaigns.length}>
      {(workflowItems.length || campaigns.length) ? (
        <Dropdown trigger={['hover']} placement="bottomCenter" overlay={(
          <WorkflowsContainer>
            {workflowItems.map(({ name, id }) => <Link key={id} to={`${Paths.EDIT_WORKFLOW}/${id}/editor`}>{name}</Link>)}
            {campaigns.map(({ name, id, s }) => <Link key={id} to={`${Paths.CAMPAIGNS}/${id}/${s === 'Design' ? 'editor' : 'analytic'}`}>{name}</Link>)}
          </WorkflowsContainer>
        )}>
          <div>
            <UnderlinedText text={resolveStatus(1, draft, t)} />
          </div>
        </Dropdown>
      ) : (
        resolveStatus(0, draft, t)
      )}
    </Container>
  );
};

export default React.memo(SegmentStatus);
