import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div``;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;

export const AddFilterButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  
  ${styledProp('$bordered', css`
    border-top: 1px solid #DCDFE6;
  `)}
`;

export const AddFilterButton = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #109DFB;
  
  cursor: pointer;
`;

export const LogicalOperatorContainer = styled.div`
  margin-top: 10px;
  & > * {
    transform: translateX(-50%);
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 10px;
  
  ${styledProp('$bordered', css`
    border-bottom: 1px solid #DCDFE6;
  `)}

  & > .rfb {
    opacity: 0;
    pointer-events: none;
    transition: all 280ms ease;
  }

  &:hover > .rfb {
    opacity: 1;
    pointer-events: auto;
  }
`;
