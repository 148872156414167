export const resolveEventName = (name) => {
  switch (name) {
    case '_fs':
      return 'First session';
    case '_ss':
      return 'Start session';
    case '_es':
      return 'End session';
    case '_em':
      return 'Email';
    case '_vb':
      return 'Viber'
    case '_mp':
      return 'Mobile push';
    case '_wp':
      return 'Web push';
    case '_v':
      return 'Page view';
    case '_sm':
      return 'Sms';
    case '_rfm_sd':
      return 'Monitor Segment Transition';
    default:
      return name;
  }
};
