import React from 'react';

import { CodeEditor, HTMLEditor, EmailMetaForm } from './components';
import { Container } from './styled';

const EmailTemplateEditor = ({ type, value, onChange, previewData, disabled, loaded, errors, id }) => {
  const template = { ...value, previewBody: previewData?.data };

  return (
    <Container>
      <EmailMetaForm disabled={disabled} value={value} onChange={onChange} errors={errors} />
      {type === 'code' && (
        <CodeEditor templateType="email" value={template} onChange={onChange} errors={errors.errors} />
      )}
      {type === 'vce' && (
        <HTMLEditor loaded={loaded} onChange={onChange} value={template} onChange={onChange} id={id} errors={errors.errors} />
      )}
    </Container>
  );
};

export default EmailTemplateEditor;
