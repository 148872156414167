import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  align-self: center;
  margin-bottom: 10px;
`;

export const VariantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TextArea = styled.textarea`
  border: 1px solid rgba(220, 223, 230, 0.37);
  border-radius: 9px;
  resize: none;
  height: 60px;
  color: #fff;
  background: #1F1F24;
  outline: none;
  transition: 280ms ease all;
  padding: 10px;

  &:focus {
    border: 1px solid #DCDFE6;
  }
`;

export const TitleHint = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #909399;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  margin-bottom: 25px;
`;
