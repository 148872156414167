import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 16px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const SelectionPreview = styled.div`
  width: 100%;
  background: #F9FBFF;
  border: 1px dashed #109DFB;
  border-radius: 9px;
  padding: 8px 8px 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
`;

export const PreviewItem = styled.div`
  display: flex;
  background: #1E386A;
  border-radius: 4px 1px 1px 4px;
  position: relative;
  flex: 0;
  padding: 2px 6px;
  color: #fff;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  height: 20px;
  margin-right: 27px;
  margin-bottom: 8px;
  word-break: keep-all;
  white-space: nowrap;
`;

export const PreviewClose = styled.div`
  background: #1E386A;
  position: absolute;
  border-radius: 1px 4px 4px 1px;
  right: -21px;
  padding: 5px;
  top: 0;
  cursor: pointer;
`;
