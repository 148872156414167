import React from 'react';

import { Block } from './components';
import { Container, Col, AnimatedContainer } from './styled.js';
import { GettingStartedTitle } from '../../styled';
import { ProgressBar } from './components/ProgressBar';
import { useTranslation } from '@hooks';

const GettingStarted = ({ blocks, step, skipIntegrations, onSkip }) => {
  const { p } = useTranslation('dashboard_page');
  const displayBlocks = Object.entries(blocks)
    .map(([, cfg]) => cfg)
    .sort((a, b) => a.priority - b.priority);

  if (!displayBlocks[0] || step === 'done') {
    return null;
  }

  const getProgressParams = () => {
    return {
      integrationsExist: displayBlocks.find(b => b.key === 'integrations').exist,
      contactsExist: displayBlocks.find(b => b.key === 'contacts').exist,
      templatesExist: displayBlocks.find(b => b.key === 'templates').exist,
      workflowsExist: displayBlocks.find(b => b.key === 'workflows').exist,
      segmentsExist: displayBlocks.find(b => b.key === 'segments').exist,
      skipIntegrations,
    }
  }

  return (
    <AnimatedContainer>
      <GettingStartedTitle data-testid="dashboard-title-follow-steps">
        {p('follow_steps')}
      </GettingStartedTitle>
      <ProgressBar {...getProgressParams()} />
      <Container>
        {!!displayBlocks.length && (
          <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'space-around' }}>
            {displayBlocks.map((b, index, arr) => {
              return (
                <Block
                  {...b}
                  displayText={arr.length < 3}
                  highlight={step === b.key}
                  position={index + 3}
                  blockKey={b.key}
                  step={step}
                  small={step !== b.key}
                  medium={b.key === 'integrations' && skipIntegrations}
                  exist={b.exist}
                  onSkip={onSkip}
                  style={{
                    marginRight: index < arr.length - 1 ? '10px' : '0'
                  }}
                />
              )
            })}
          </div>
        )}
      </Container>
    </AnimatedContainer>
  );
}

export default GettingStarted;


