import React from 'react';

import { Container, ColorIndicator } from './styled.js';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { by } from '@utils';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { useTranslation } from '@hooks';
import { RFMColorsConfig } from '@constants';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';

const resolveType = type => type?.toLowerCase?.();

const tryParse = monetaryAggregate => {
  try {
    return JSON.parse(monetaryAggregate);
  } catch (e) {
    return monetaryAggregate;
  }
};

const ResourceNodeSettingsInfo = ({ node: outerNode, options }) => {
  const node = { data: createMultinodeUpdater(outerNode.data).values[0] };
  const type = resolveType(node.data?.resource_type);
  const segmentOptions = useSelector(segmentsOptionsSelector);
  const resource = segmentOptions.segments?.find(by(node.data?.segment_ids?.[0]));
  const { t } = useTranslation();

  const selectedAnalytics = (options.rfmResources || []).find(by(node.data?.rfm_analytics));
  const selectedRFMSegment = (selectedAnalytics?.resources || []).find(by('segment_id', node.data?.parent_segment_id));
  const selectedRFMSubSegment = (selectedAnalytics?.resources || []).find(by('segment_id', node.data?.segment_ids?.[0]));
  const monetaryValues = (tryParse(selectedRFMSegment?.monetary_aggregate) || []).find(by('monetary_ranks', +selectedRFMSubSegment?.name?.[selectedRFMSubSegment?.name?.length - 1]));
  const subsegmentName =`${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', selectedRFMSegment?.name))?.title}`)}.${(+monetaryValues?.min).toFixed(2)} - ${(+monetaryValues?.max).toFixed(2)} revenue`;

  if (!type && !resource && !selectedAnalytics) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      {type && (
        <InfoRow padded>
          <InfoTitle>
            {t('labels.type')}:
          </InfoTitle>
          <InfoText>
            {type}
          </InfoText>
        </InfoRow>
      )}
      {resource?.name && (
        <InfoRow>
          <InfoTitle>
            {t('labels.segment')}:{' '}
          </InfoTitle>
          <InfoText>
            {resource.name}
          </InfoText>
        </InfoRow>
      )}
      {(selectedAnalytics && selectedRFMSubSegment) && (
        <InfoRow padded>
          <InfoTitle>
            {t('labels.rfm_analysis')}:{' '}
          </InfoTitle>
          <InfoText>
            {selectedAnalytics.name}
          </InfoText>
        </InfoRow>
      )}
      {(selectedRFMSegment && selectedRFMSubSegment) && (
        <InfoRow padded>
          <InfoTitle>
            {t('labels.rfm_segment')}:{' '}
          </InfoTitle>
          <InfoText>
            <ColorIndicator $color={RFMColorsConfig.find(by('name', selectedRFMSegment.name))?.color?.ui} />
            {t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', selectedRFMSegment.name))?.title}`)}
          </InfoText>
        </InfoRow>
      )}
      {selectedRFMSubSegment && (
        <InfoRow padded>
          <InfoTitle>
            {t('labels.rfm_subsegment')}:{' '}
          </InfoTitle>
          <InfoText>
            <ColorIndicator $color={RFMColorsConfig.find(by('name', selectedRFMSegment.name))?.color?.ui} />
            {node.data?.segment_ids?.[0] !== node.data?.parent_segment_id ? subsegmentName : t('labels.all')}
          </InfoText>
        </InfoRow>
      )}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default ResourceNodeSettingsInfo;


