import styled, { css } from 'styled-components';
import { Select as ANTDSelect } from 'antd';
import { styledProp } from '@utils';

const { Option: ANTDOption } = ANTDSelect;

export const Container = styled.div`
    overflow: visible;
    position: relative;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

export const Select = styled(ANTDSelect)`
  height: 36px;
  width: 162px;

  ${styledProp('smaller', `
      height: 26px !important;
      width: 140px !important;
    `)}
  
  & > div {
    //min-width: 162px;
    padding: 0 13px;
    height: 36px !important;
    border: 1px solid #DCDFE6 !important;
    background: #fff;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9px !important;

    ${styledProp('onboardHighlighted', `
      border: 3px solid #BDD1F6 !important;
    `)}

    ${styledProp('hasError', `
      margin-bottom: 0px;
      color: #C8402F;
      border-color: #C8402F !important;
    `)}

    ${styledProp('highlight', `
      border-color: #ffc630 !important;
    `)}

    ${styledProp('smaller', `
      border-radius: 7px !important;
      height: 26px !important;
      font-size: 12px;
      min-width: 140px;
    `)}
  }
`;

export const Option = styled(ANTDOption)``;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  //margin-bottom: 7px;
  //font-size: 13px;
  //padding-left: 4px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  color: #909399; 
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;  
  line-height: 120%;
  color: #C8402F;
  
  ${styledProp('$a', css`
      position: absolute;
  `, css`
  `)}
`;
