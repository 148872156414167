import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  
  &:hover .g-cont {
    background: #C8402F;
    ${styledProp('disabled', css`
      background: #DCDFE6;
    `)}
  }
  
  ${styledProp('disabled', css`
    opacity: 0.3;
  `)}
`;

export const GContainer = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  transition: 330ms ease all;
  align-content: space-between;
  margin-right: 12px;
  background: #DCDFE6;
`;

export const GSquareSmall = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 1px;
  background: #fff;
`;

export const GSquareBig = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background: #fff;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #303133;
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;
