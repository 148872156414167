import React from 'react';
import { shortNumber } from '@utils';
import { Icon } from '@components';
import { WorkflowNodes } from '@constants';

import { Container, In, Out, InText, OutText, IconContainer } from './styled.js';

const NodeAnalytics = ({ data, type, testId = '' }) => {
  return (
    <Container data-testid={`${testId}-analytics`}>
      <In>
        <InText data-testid={`${testId}-analytics-in`}>{shortNumber(data.in)}</InText>
      </In>
      <IconContainer>
        <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.33727 3.89285C4.55422 3.67589 4.55422 3.32414 4.33727 3.10718L1.4206 0.190511C1.20364 -0.0264467 0.634926 0.190511 0.634926 0.190511L0.634926 6.80952C0.634926 6.80952 1.20364 7.02648 1.4206 6.80952L4.33727 3.89285Z" fill="#909399"/>
        </svg>
      </IconContainer>
      <Out>
        <OutText data-testid={`${testId}-analytics-out`}>
            {type === WorkflowNodes.WAIT && data.out === 0 && (
                <Icon size={11} style={{marginRight: '2px'}} name="Launch"/>
            )}
            {shortNumber(data.out)}
        </OutText>
      </Out>
    </Container>
  );
}

export default NodeAnalytics;


