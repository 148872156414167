import { ReducerKeys } from '@constants';

const SUB_CATEGORY_EMAIL_KEY = 'message_channels_email';
const SUB_CATEGORY_SMS_KEY = 'message_channels_sms';
const SUB_CATEGORY_WEBPUSH_KEY = 'message_channels_web_push';
const SUB_CATEGORY_MOBILE_PUSH_KEY = 'message_channels_mobile_push';
const SUB_CATEGORY_VIBER_KEY = 'message_channels_viber';

const defaultOrder = [
  'javascript',
  'retainly_api',
  'retainly_webpushes',
  'fake_mobile_pushes',
  'fake_webpushes',
  'fake_mobile_pushes',
  'fake_viber',
  'fake_sms',
  'fake_emails',
];

export const integrationsSelector = (store) => store[ReducerKeys.INTEGRATIONS].integrations;

export const integrationCategoriesSelector = (store) => store[ReducerKeys.INTEGRATIONS].categories;

export const integrationsSubCategoriesSelector = (store) => store[ReducerKeys.INTEGRATIONS].sub_categories;

export const myIntegrationsSelector = (store) => store[ReducerKeys.INTEGRATIONS].my_integrations;

export const facebookAudiencesSelector = (store) => store[ReducerKeys.INTEGRATIONS].facebookAudiences;

export const connectionsMetaSelector = (store) => store[ReducerKeys.INTEGRATIONS].connectionsMeta;

export const emailTemplateIntegrationsSelector = (store) => {
  const { my_integrations: myIntegrations, integrations } = store[ReducerKeys.INTEGRATIONS];

  if (!myIntegrations.data?.length || !integrations?.length) {
    return [];
  }

  const emailIntegrationKeys = integrations
    .filter(({ sub_category_key }) => sub_category_key === SUB_CATEGORY_EMAIL_KEY)
    .map(({ key }) => key);

  return myIntegrations.data.filter(({ integration_key, enabled }) => emailIntegrationKeys.includes(integration_key) && enabled).sort((a, b) => defaultOrder.indexOf(b.key) - defaultOrder.indexOf(a.key));
};

export const smsTemplateIntegrationsSelector = (store) => {
  const { my_integrations: myIntegrations, integrations } = store[ReducerKeys.INTEGRATIONS];

  if (!myIntegrations.data?.length || !integrations?.length) {
    return [];
  }

  const smsIntegrationKeys = integrations
    .filter(({ sub_category_key }) => sub_category_key === SUB_CATEGORY_SMS_KEY)
    .map(({ key }) => key);

  return myIntegrations.data.filter(({ integration_key, enabled }) => smsIntegrationKeys.includes(integration_key) && enabled).sort((a, b) => defaultOrder.indexOf(b.key) - defaultOrder.indexOf(a.key));
};

export const webpushTemplateIntegrationsSelector = (store) => {
  const { my_integrations: myIntegrations, integrations } = store[ReducerKeys.INTEGRATIONS];

  if (!myIntegrations.data?.length || !integrations?.length) {
    return [];
  }

  const webpushIntegrationKeys = integrations
    .filter(({ sub_category_key }) => sub_category_key === SUB_CATEGORY_WEBPUSH_KEY)
    .map(({ key }) => key);

  return myIntegrations.data.filter(({ integration_key, enabled }) => webpushIntegrationKeys.includes(integration_key) && enabled).sort((a, b) => defaultOrder.indexOf(b.key) - defaultOrder.indexOf(a.key));
};

export const mobilePushTemplateIntegrationsSelector = (store) => {
  const { my_integrations: myIntegrations, integrations } = store[ReducerKeys.INTEGRATIONS];

  if (!myIntegrations.data?.length || !integrations?.length) {
    return [];
  }

  const mobilePushIntegrationKeys = integrations
    .filter(({ sub_category_key }) => sub_category_key === SUB_CATEGORY_MOBILE_PUSH_KEY)
    .map(({ key }) => key);

  return myIntegrations.data.filter(({ integration_key, enabled }) => mobilePushIntegrationKeys.includes(integration_key) && enabled).sort((a, b) => defaultOrder.indexOf(b.key) - defaultOrder.indexOf(a.key));
};

export const viberTemplateIntegrationsSelector = (store) => {
  const { my_integrations: myIntegrations, integrations } = store[ReducerKeys.INTEGRATIONS];

  if (!myIntegrations.data?.length || !integrations?.length) {
    return [];
  }

  const viberIntegrationKeys = integrations
    .filter(({ sub_category_key }) => sub_category_key === SUB_CATEGORY_VIBER_KEY)
    .map(({ key }) => key);

  return myIntegrations.data.filter(({ integration_key, enabled }) => viberIntegrationKeys.includes(integration_key) && enabled).sort((a, b) => defaultOrder.indexOf(b.key) - defaultOrder.indexOf(a.key));
};

export const jsIntegrationMetaSelector = store => store[ReducerKeys.INTEGRATIONS].jsMeta;

export const dbRequestPreviewSelector = store => store[ReducerKeys.INTEGRATIONS].dbRequestPreview;

export const postgresDatasyncLogSelector = store => store[ReducerKeys.INTEGRATIONS].postgresDatasyncLog;

export const mySqlDatasyncLogSelector = store => store[ReducerKeys.INTEGRATIONS].mySqlDatasyncLog;

export const facebookUserInfoSelector = store => store[ReducerKeys.INTEGRATIONS].facebookUserInfo;
