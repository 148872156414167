import styled, { css } from 'styled-components';
import { extract, styledProp } from '@utils';

export const Container = styled.div`
  transition: all 330ms ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  top: 32px;
  right: 20px;
  left: 62px;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 8px 8px 8px;
  min-height: 42px;
`;

export const TitleContainer = styled.div`

`;

export const Title = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 17px;
`;

export const Image = styled.div`
  background: url(${extract('image')}) center / cover no-repeat;
  transition: 330ms ease all;
  margin: 8px 0 10px 0;

  ${styledProp('$opened', css`
    height: 160px;
  `, css`
    height: 0px;
    margin-bottom: 0;
  `)}
`;


export const CompanyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0 8px;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgb(245, 245, 245);
  box-shadow: rgb(0 0 0 / 14%) 0px 3px 13px 1px, rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  transition: opacity 0.1s ease 0s;
  border-radius: 50%;
  left: -11px;
  top: -11px;
  width: 18px;
  height: 18px;
  
  ${styledProp('show', css`
    opacity: 1;
  `, css`
    opacity: 0;
  `)}
`;

export const Content = styled.div``;

export const Text = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  line-height: 17px;
`;

export const TextCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  overflow: hidden;
`;

export const Footer = styled.div`
  transition: all 330ms ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  
  ${styledProp('$opened', css`
    max-height: 200px;
  `, css`
    max-height: 0px;
  `)}
`;

export const Options = styled.div`
  display: flex;
  border-radius: 6px;
  padding: 2px 5px;
  align-items: center;
  background-color: rgb(220, 220, 220);
  color: rgb(121, 121, 121);
`;

export const CompanyIcon = styled.div`
  background: url(${extract('image')}) center / contain no-repeat;
  width: 34px;
  height: 34px;
  min-height: 34px;
  min-width: 34px;
  margin-left: 20px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-self: flex-end;
`;

export const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
`;

export const Divider = styled.div`
  width: 93%;
  height: 1px;
  background: #F3F3F3;
  margin-left: 10px;
`;

export const HoveredContainer = styled.div`
  width: 20px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  
  :hover {
    background: rgb(220, 220, 220);
  }
`;

export const Company = styled.div`
  font-family: Helvetica;
  font-size: 12px;
  color: #838383;
`;
