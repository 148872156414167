import { useState } from 'react';

const useModalState = (initialState = false) => {
  const [opened, setOpened] = useState(initialState);

  return {
    opened,
    setOpened,
    open: () => setOpened(true),
    close: () => setOpened(false),
    toggle: () => setOpened(o => !o),
  };
};

export default useModalState;
