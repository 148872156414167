import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  border: 1px solid #909399;
  border-radius: 39px;
  padding: 9px 20px;
  cursor: pointer;
  transition: all 280ms ease;
  display: flex;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 12px;
  align-self: flex-start;
  min-width: 45%;

  ${styledProp('selected', css`
    border-color: #4986FD;
    color: #4986FD;
  `)}
`;

export const Label = styled.div`
  transition: all 280ms ease;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #909399;

  ${styledProp('selected', css`
    color: #4986FD;
  `)}
`;
