import styled, { css } from 'styled-components';

import { styledProp } from '@utils';
import * as Colors from "@res/theme/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 165px;
  min-width: 240px;
  padding: 0 0 0 0;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  transition: all 330ms ease;
  ${styledProp('isExample', '', css`
    &:hover {
      background: #F9FBFF;
    }
  `)}
    
  ${styledProp('onboard', `
      border: 3px solid #BDD1F6;
      border-radius: 9px;
  `)}
  
  ${styledProp('$isDragging', css`
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  `)}

  ${styledProp('$draggingOverFolder', css`
    transform: scale(0.3);
  `)}
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 12px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  ${styledProp('isExample', '', css`
    &:hover {
      background: #F9FBFF;
    }
  `)}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
  border-radius: 6px;
    
  ${styledProp('transparent', `
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
  `)}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  max-width: 170px;
  font-size: 13px;
  color: ${Colors.Text.PRIMARY};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;;
`;

export const Date = styled.p`
  font-size: 10px;
  color: ${Colors.Text.SECONDARY};
  margin: 0;
`;

export const MenuItem = styled.p`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;
    ${({ disabled }) => disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
`;

export const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 116px;
  overflow: hidden;
  justify-content: flex-start;
  display: flex;

  ${styledProp('hovered', css`
  .HoverMenu {
    opacity: 1;
    transform: scale(1);

    & > div {
      transform: scale(1);
    }
  }
`)}

  &:hover {
    .HoverMenu {
      opacity: 1;
      transform: scale(1);

      & > div {
        transform: scale(1);
      }
    }
  }
`;

export const Preview = styled.iframe`
  width: 100%;
  border: none;
  border-radius: 8px 8px 0 0;
  position: absolute;
  //transform: scaleX(0.8) translateX(-9%);
`;

export const HoverMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  //background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  & > div {
    transition: 330ms ease all;
    transform: scale(0.85);
  }
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  transition: all 330ms ease;
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  background: #F0F2F6;
`;

export const ViberImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  margin-top: 15px;
`;

export const ViberPreview = styled.div`
  position: absolute;
  padding: 14px 0px 4px 0px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  line-height: 120%;
  height: 100%;
  background: #EFEFEF;
  border-radius: 8px 8px 0 0;
`;

export const ViberButton = styled.div`
  background: #7062C8;
  border-radius: 58px;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
  padding: 6px;
  margin: 10px 14px 10px 14px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MobilePushImage = styled.img`
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 300px;
`;

export const MobpushPreview = styled.div`
  position: absolute;
  //padding: 14px 0px 4px 0px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  line-height: 120%;
  height: 100%;
  background: #EFEFEF;
  border-radius: 8px 8px 0 0;
`;
