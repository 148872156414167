import React from 'react';
import { useSelector } from 'react-redux';
import { DateCell, Icon, Link, SortableTitle, Table, WithPermissions } from '@components';
import TextDetails from '../TextDetails/TextDetails';
import {templateSelector, webhookLoadingSelector, webhookSelector} from '@store/selectors';
import { StatusCell } from '../StatusCell';
import { Paths, UserPermissions, WebhookStatuses, WebhookStatusLabels, } from '@constants';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { Dropdown } from 'antd';
import { useTranslation } from '@hooks';
import { extract, notify, tryHumanizeResponse } from '@utils';
import { IdType, Overlay } from '@components/lib/styled';
import capitalize from 'lodash.capitalize';

import { BodyContainer, CopyButton } from './styled';
import * as clipboard from 'clipboard-polyfill';
const resolveStatus = status => WebhookStatusLabels[status] || capitalize(status);

const resolveColor = status => ({
  [WebhookStatuses.SENT]: '#24993E',
  [WebhookStatuses.DELIVERED]: '#24993E',
  [WebhookStatuses.DROPPED]: '#C8402F',
})[status] || '#24993E';

const resolveBody = (body) => {
  try {
    return JSON.stringify(JSON.parse(body), null ,2);
  } catch (e) {
    return body;
  }
};

const WebhookTable = ({ keyExtractor, cols, ordering }) => {
  const webhook = useSelector(webhookSelector);
  const loading = useSelector(webhookLoadingSelector);
  const templates = useSelector(templateSelector);
  const { t, p } = useTranslation('notification_history');

  const handleCopyBody = (body) => async () => {
    await (new clipboard.writeText(body));
    notify('success', 'Copied', 'Request body');
  }

  const createWebhookTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      )
    },
    {
      title: () => <SortableTitle ordering={ordering} name="url">{t('labels.url')}</SortableTitle>,
      dataIndex: 'url',
      key: 'url',
      className: `notification-history-cell-url`,
      render: (url) => <FlexibleText text={url} maxWidth={130}>{url}</FlexibleText>
    },
    {
      title: () => <SortableTitle ordering={ordering} name="body">{t('labels.body')}</SortableTitle>,
      dataIndex: 'body',
      className: `notification-history-cell-body`,
      key: 'body',
      render: (body) => <WithPermissions name={UserPermissions.REVIEW_API_REQUESTS}><TextDetails text={(
        <BodyContainer>
          <CopyButton onClick={handleCopyBody(body)}>Copy</CopyButton>
          <code>
            {resolveBody(body)}
          </code>
        </BodyContainer>
      )} /></WithPermissions>,
    },
    {
      title: () => <SortableTitle name="template_id" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (id, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_API_REQUESTS}><Link to={`${Paths.TEMPLATES_API_REQUEST}/${data.template_id}?view=1`}>{templates?.webhook?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      key: 'created_at',
      className: `notification-history-cell-date`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)} />
      }
    },
    {
      title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
      dataIndex: 'response',
      key: 'response',
      className: `notification-history-cell-response`,
      render: (r, { s }) => {
        if (!r || r === '[]') {
          return null;
        }

        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                <pre>
                  {tryHumanizeResponse(r)}
                </pre>
              </Overlay>
            )}
          >
            <div>
              <Icon color={resolveColor(s)} name="Info-icon" size={16} />
            </div>
          </Dropdown>
        )
      },
    },
  ];

  const columns = createWebhookTableColumns(ordering);

  const webhookExtraColumns = [
    {
      title: () => <SortableTitle>{t('labels.updated_at')}</SortableTitle>,
      dataIndex: 'updated_at',
      className: `notification-history-cell-updated_at`,
      key: 'updated_at',
      render: (date) => <DateCell date={date} />
    },
  ]

  webhookExtraColumns.forEach((args) => cols.indexOf(args.key) !== -1 && columns.push(args));

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_API_REQUESTS} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={webhook.data?.map?.(extract('id')).join()}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          columns={columns}
          dataSource={webhook.data}
          rowKey={keyExtractor}
        />
      </WithPermissions>
    </>
  )
}

export default WebhookTable;

