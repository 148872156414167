import React, { memo, useMemo } from 'react';
import { useStore } from 'reactflow';

const defaultColors = {
  dots: '#81818a',
  lines: '#eee',
};

const createGridLinesPath = (size, strokeWidth, stroke) => {
  return <path stroke={stroke} strokeWidth={strokeWidth} d={`M${size / 2} 0 V${size} M0 ${size / 2} H${size}`} />;
};

const createGridDotsPath = (size, fill) => {
  return <circle cx={size} cy={size} r={size} fill={fill} />;
};


const Background = ({
  variant = 'dots',
  gap = 15,
  size = 0.4,
  color,
  style,
  className,
}) => {
  const [x, y, scale] = useStore((s) => s.transform);
  // when there are multiple flows on a page we need to make sure that every background gets its own pattern.
  const patternId = useMemo(() => `pattern-${Math.floor(Math.random() * 100000)}`, []);

  const scaledGap = gap * scale;
  const xOffset = (x + 45 * scale) % scaledGap;
  const yOffset = (y + 35 * scale) % scaledGap;

  const isLines = variant === 'lines';
  const bgColor = color ? color : defaultColors[variant];
  const path = isLines ? createGridLinesPath(scaledGap, size, bgColor) : createGridDotsPath(size * scale, bgColor);

  return (
    <svg
      className={`${className} react-flow__background`}
      style={{
        ...style,
        width: '100%',
        height: '100%',
      }}
    >
      <pattern
        id={patternId}
        x={xOffset}
        y={yOffset}
        width={scaledGap}
        height={scaledGap}
        patternUnits="userSpaceOnUse"
      >
        {path}
      </pattern>
      <rect x="0" y="0" width="100%" height="100%" fill={`url(#${patternId})`} />
    </svg>
  );
};

Background.displayName = 'Background';

export default memo(Background);

