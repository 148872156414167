import React from 'react';
import { useSelector } from 'react-redux';
import { SegmentsRuleTypes } from '@constants';

import { Aggregate, Container } from './styled.js';
import { NoData } from '@components';
import { segmentOptionsAggregatesListSelector } from '@store/selectors';

const AggregatesFilterPanel = ({ onSelect, hidePreview }) => {
  const aggregates = useSelector(segmentOptionsAggregatesListSelector);

  if (!aggregates?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-aggregate-tab-content'}>
      {aggregates?.map((a, idx) => <Aggregate key={idx} onClick={() => onSelect(a, SegmentsRuleTypes.AGGREGATE)}>{a.label}</Aggregate>)}
      {/*<Aggregate onClick={() => onSelect(null, SegmentsRuleTypes.AGGREGATE)}>+ Create aggregate</Aggregate>*/}
    </Container>
  );
}

export default AggregatesFilterPanel;


