import React, {useEffect, useState} from 'react';
import { Container, ExpiredCaption, ExpiredTitle } from './styled';
import logo from '@res/images/logo.svg';
import { Button, Input } from '@components';
import { AppLinkTypes, Paths } from '@constants';
import { Image, InnerContainer } from './styled';
import { changePasswordAfterReset, checkAppLink, getUser } from '@store/actions/creators';
import { useErrors, useModalState, useQuery, useTranslation, useNavigate } from "@hooks";
import {useDispatch} from "react-redux";
import {UserActionTypes} from "@store/actions/types";
import { extract } from '@utils';

const passwordValidation = [
  {
    tooltip: 'min 6 - max 32 characters',
    matcher: v => v?.length < 33,
  },
  {
    error: 'At least 6 characters required',
    matcher: v => v?.length > 5,
  },
  {
    error: 'No more than 32 characters allowed',
    matcher: v => v?.length < 33,
  },
  {
    error: 'No spaces allowed',
    matcher: v => !v?.includes(' '),
  },
  {
    error: 'Only latin characters are allowed',
    matcher: v => /^[a-zA-Z0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]+$/.test(v),
  },
  {
    tooltip: '1 lowercase(s) (a-z)',
    error: 'At least one lowercase letter is required',
    matcher: v => /[a-z]/.test(v),
  },
  {
    tooltip: '1 uppercase(s) (A-Z)',
    error: 'At least one uppercase letter is required',
    matcher: v => /[A-Z]/.test(v),
  },
  {
    tooltip: '1 number(s) (0-9)',
    error: 'At least one number required',
    matcher: v => /\d/.test(v),
  },
  {
    tooltip: '1 special character(s) (#)',
    error: 'At least one special character required',
    matcher: v => /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(v),
  },
];

const ResetPasswordFormPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const linkExpiredModal = useModalState();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const { t, p } = useTranslation('login_page');
  const errors = useErrors([], [429]);

  useEffect(() => {
    if (!query.get('email') || !query.get('hash')) {
      navigate(Paths.LOGIN);
      return;
    }
    dispatch(checkAppLink({
      email: query.get('email') || '',
      hash: query.get('hash') || '',
      type: AppLinkTypes.RESET,
      systemType: 'retainly',
      onSuccess: ({ status }) => {
        if (!status) {
          linkExpiredModal.open();
        }
      },
      onError: ({ response }) => {
        if (response.status === 422) {
          linkExpiredModal.open();
        }
      }
    }))
  }, []);

  const handleChange = (onChangeFn) => ({ target }) => {
    errors.clear();
    onChangeFn(target.value);
  };

  const handleSubmit = () => {
      if (newPassword !== repeatPassword) {
        errors.setError('repeatPassword', 'Passwords don\'t match');
        return;
      }

    const passwordError = passwordValidation
      .filter(extract('error'))
      .find(({ matcher }) => !matcher(newPassword))?.error;

    if (passwordError) {
      errors.setError('password', passwordError);
      return;
    }

      dispatch(
          changePasswordAfterReset({
              hash: query.get('hash'),
              email: query.get('email'),
              password: newPassword,
              type: 'retainly',
              onSuccess: (data) => {
                dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: data })
                dispatch(getUser());
                navigate(Paths.DASHBOARD);
              },
              onError: errors.handleRequestError,
          })
      );
  };

  return (
    <Container>
      <Image src={logo} alt="logo" />
      {linkExpiredModal.opened ? (
        <InnerContainer>
          <ExpiredTitle>Reset password error</ExpiredTitle>
          <ExpiredCaption>Reset link is no longer active. <br />Please try again</ExpiredCaption>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button width={150} style={{ marginRight: 20 }} appearance="secondary" onClick={() => navigate(Paths.LOGIN)}>Go to login</Button>
            <Button width={150} style={{ marginLeft: 20 }} onClick={() => navigate(Paths.RESET_PASSWORD)}>Reset password</Button>
          </div>
        </InnerContainer>
      ) : (
        <InnerContainer>
          <h2>Create new password</h2>
          <>
            <Input
              style={{ width: '258px' }}
              title='New password'
              error={errors.errors['password']}
              type="password"
              onChange={handleChange(setNewPassword)}
            />
            <Input
              style={{ width: '258px' }}
              title='Repeat password'
              error={errors.errors['repeatPassword']}
              type="password"
              onChange={handleChange(setRepeatPassword)}
            />
            <Button onClick={handleSubmit}>Submit</Button>
          </>
        </InnerContainer>
      )}
    </Container>
  );
};

export default ResetPasswordFormPage;
