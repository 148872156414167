import React from 'react';
import { useDispatch } from 'react-redux';
import { useModalState, useTranslation, useNavigate } from '@hooks';
import { ActionButton, TableActionsBar, WithPermissions } from '@components';
import { OPERATOR_MATCHES_RANGE, OPERATOR_ONE_OF, Paths, UserPermissions, WorkflowNodes } from '@constants';

import { Container } from './styled';
import { workflowEditorSetNodes } from '@store/actions/creators/workflowEditor';
import { createNode } from '@utils/workflows/refactored/creators';
import { unwrapArrays } from '@utils/workflows/refactored/updaters';
import { EventPresets } from '@constants/eventPresets';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationModal } from "@components";
import { notify, sequence } from "@utils";
import { deleteEvent } from "@store/actions/creators";

const resolveDisabledTooltip = (type, used, p) => {
  if (used) {
    return p('event_used_cant_be_deleted');
  }

  switch (type) {
    case 1:
      return p('dynamic_events_cant_be_deleted');
    case 3:
      return p('system_events_cant_be_deleted');
    default:
      return p('this_event_cant_be_deleted');
  }
}

const TableActions = ({ id, type, name, workflows, onRefresh, onDelete, onShowInfo, canDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteModal = useModalState(false);
  const { p, t } = useTranslation('field_settings');

  const handleCreateWorkflow = () => {
    const preset = EventPresets.find(({ events }) => !!~events.indexOf(name));

    const [tree_map, style] = createNode({
      type: WorkflowNodes.EVENT,
      actionType: 'entry_point',
      translate: s => t('workflow_page.' + s),
      data: {
        event_id: id,
        event_name: name,
        validated: true,
        simplified: !!preset,
        hidden: false,
        filter_builder: {
          logicalOperator: 'and',
          filters: (preset?.presetFields || []).map(field => ({
            field,
            operator: field === '_d' ? OPERATOR_MATCHES_RANGE : OPERATOR_ONE_OF,
            type: field === '_d' ? 'datetime' : 'text',
            value: { type: 'scalar', value: '' },
          }))
        },
      },
    });

    dispatch(workflowEditorSetNodes({
      id: 'new',
      tree_map: unwrapArrays(tree_map),
      styles: {
        [style.id]: style,
      }
    }))
    navigate(Paths.CREATE_WORKFLOW);
  };

  const handleDeleteEvent = (id) => {
    dispatch(deleteEvent({
      id,
      onSuccess: sequence(onRefresh, deleteModal.close, () => {
        notify('success', t('labels.success'), p('event_deleted_successfully'));
      }),
    }))
  }

  const infoDisabled = false;

  return (
    <Container>
      <TableActionsBar
        testId={'actions-bar'}
        actionButtons={(
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', backgroundColor: '#fafafa' }}>
            {/*<ActionButton*/}
            {/*  testId={'info-action'}*/}
            {/*  tooltip={infoDisabled ? 'Empty field' : 'Show info'}*/}
            {/*  style={{ marginRight: '6px' }}*/}
            {/*  disabled={infoDisabled}*/}
            {/*  onClick={onShowInfo}*/}
            {/*  icon="Info-icon"*/}
            {/*/>*/}
            <WithPermissions name={UserPermissions.CREATE_WORKFLOWS}>
              <ActionButton
                testId={'create-workflow-action'}
                style={{ marginRight: '6px' }}
                tooltip={p('create_workflow')}
                onClick={handleCreateWorkflow}
                icon="Actions-Workflow"
              />
            </WithPermissions>
            <WithPermissions name={UserPermissions.DELETE_EVENTS}>
              <ActionButton
                testId={'delete-action'}
                tooltipProps={{ placement: 'topRight', zIndex: 999 }}
                tooltip={type === 1 || type === 3 || workflows?.[0] !== undefined ? resolveDisabledTooltip(type, workflows?.[0] !== undefined, p) : t('actions.delete')}
                disabled={type === 1 || workflows?.[0] !== undefined || !canDelete}
                onClick={deleteModal.open}
                appearance="danger"
                icon="Delete-icon"
              />
            </WithPermissions>
          </div>
        )}
      />
      <ConfirmationModal
        onClose={deleteModal.close}
        opened={deleteModal.opened}
        title={p('delete_event')}
        testId='delete-field-modal'
        appearance="danger"
        text={p('sure_want_to_delete_this_event')}
        onCancel={deleteModal.close}
        onSubmitClick={() => handleDeleteEvent(id)}
        buttonsParams={{
          cancelAppearance: 'secondary',
          cancelTestId: 'delete-field-cancel',
          submitAppearance: 'danger',
          cancelLabel: t('actions.cancel'),
          submitLabel: t('actions.delete'),
          submitTestId: 'delete-field-submit',
        }}
      />
    </Container>
  );
};

export default TableActions;
