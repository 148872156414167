import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useKeydown, useModalState, useTranslation } from '@hooks';
import ReactFlow, { applyEdgeChanges, applyNodeChanges, isEdge, isNode, useKeyPress } from 'reactflow';
import { Platform, notify, by, extract } from '@utils';
import * as clipboard from 'clipboard-polyfill';
import WorkflowEditorContext from '../../../contexts/WorkflowEditorContext/WorkflowEditorContext';
import { WorkflowEdgeTypes, WorkflowNodeTypes } from '@constants';

import {
  ActionsBar,
  DeleteNodeModal,
  FinishNode,
  Node,
  NodeArray,
  Sidebar,
  SplitterNode,
  Background,
  NodeSettingsModal,
  NodeDiffModal,
  DefaultEdge,
} from './components';
import { Container, EditorContainer, Editor } from './styled';
import omit from 'lodash.omit';
import { WorkflowDiffContext } from '../../../contexts';
import { useDispatch, useSelector } from 'react-redux';
import { workflowOptionsSelector } from '@store/selectors';
import { isLast } from '@utils/workflows/refactored/getters';
import { WorkflowActionTypes } from '@store/actions/types';
import { useLocation } from 'react-router-dom';

const nodeTypes = {
  [WorkflowNodeTypes.DEFAULT]: Node,
  [WorkflowNodeTypes.ARRAY]: NodeArray,
  [WorkflowNodeTypes.FINISH]: FinishNode,
  [WorkflowNodeTypes.SPLITTER]: SplitterNode
};

const edgeTypes = {
  [WorkflowEdgeTypes.DEFAULT]: DefaultEdge
};

const defaultViewport = { x: 145, y: 145, zoom: 1 };

const snapGrid = [110, 110];

const flowStyle = { flex: 1 };

const WorkflowEditor = ({ setShowHeader, showHeader, zoomPanHandlerRef, disabledSidebar = false, page = 'edit', children, preview }) => {
  const editor = useContext(WorkflowEditorContext);
  const diff = useContext(WorkflowDiffContext);
  const options = useSelector(workflowOptionsSelector);
  const [toRemove, setToRemove] = useState(null);
  const deleteNodeModal = useModalState();
  const { p } = useTranslation('workflow_page');
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const dispatch = useDispatch();
  const ref = useRef({ addEventListener: () => {}, removeEventListener: () => {} });
  const deletePressed = useKeyPress(['Backspace', 'Meta+d']);

  const copyNodes = [editor.selection];

  const selectedName = editor.editable && editor.renderNodes.find(by(editor.selection))?.data?.name;
  const canDelete = editor.selection && editor.editable && selectedName !== 'ab' && selectedName !== 'exclude' && selectedName !== 'include';
  const canCopy = editor.selection && editor.editable && selectedName !== 'ab' && selectedName !== 'exclude' && selectedName !== 'include' && selectedName !== 'array';

  const openDeleteModal = () => {
    if (!editor.selection || !editor.editable || !canDelete) {
      return;
    }

    deleteNodeModal.open();
  }

  useEffect(() => {
    openDeleteModal();
    setToRemove({ id :editor.selection });
  }, [deletePressed]);

  const handleCopy = async () => {
    if (!editor.editable || !canCopy) {
      return;
    }

    await (new clipboard.writeText(JSON.stringify(copyNodes.map(id => editor.renderNodes.find(by(id))))));
  };

  const handleCut = async () => {
    if (!editor.editable || !canCopy) {
      return;
    }

    await (new clipboard.writeText(JSON.stringify(copyNodes.map(id => editor.renderNodes.find(by(id))))));

    editor.cutNodes(editor.selection);
  };

  const handlePaste = async () => {
    if (!editor.editable) {
      return;
    }

    try {
      const nodes = JSON.parse(await clipboard.readText());
      editor.pasteNodes(nodes);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUndo = () => {
    if (!editor.actions.canUndo) {
      return;
    }

    editor.actions.undo();
  }

  const handleRedo = () => {
    if (!editor.actions.canRedo) {
      return;
    }

    editor.actions.redo();
  };

  useKeydown([
      ...Platform.select({
        macos: [
          [['command', 'c'], handleCopy],
          [['command', 'v'], handlePaste],
          [['command', 'x'], handleCut],
          [['command', 'z'], handleUndo],
          [['command', 'shift', 'z'], handleRedo]
        ],
        fallback: [
          [['ctrl', 'c'], handleCopy],
          [['ctrl', 'v'], handlePaste],
          [['ctrl', 'x'], handleCut],
          [['ctrl', 'z'], handleUndo],
          [['ctrl', 'shift', 'z'], handleRedo],
        ]
      }),
  ], [editor.actions.redo], document.body, !editor.openedNodeSettings);

  const handleElementsRemove = (toRemove) => {
    if (!editor.editable) {
      return;
    }
    setToRemove(toRemove[0]);
    openDeleteModal();
  };

  const handleConfirmRemoveNode = () => {
    const tr = toRemove || { id: editor.selection };

    editor.deleteSelectedNode(tr);
    setToRemove([]);
    deleteNodeModal.close();
    notify('success', p('node_deleted'), editor.renderNodes.find(by(tr?.id))?.data?.label);
  };

  const handleConfirmRemoveBranch = () => {
    const tr = toRemove || { id: editor.selection };

    editor.deleteBranchFromSelected(tr);
    setToRemove([]);
    deleteNodeModal.close();
    notify('success', p('nodes_deleted'), `${p('from')} ${editor.renderNodes.find(by(tr?.id))?.data?.label}`);
  };

  const handleDragNodeEnd = (event, node) => {
    editor.dragNodes(event, node);
  };

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const elements = editor.renderNodes.map(
    n => isNode(n) ? ({ ...n, position: { x: n.position.x, y: n.position.y }}) : n
  );

  useEffect(() => {
    setNodes(elements.filter(el => isNode(el)));
    setEdges(elements.filter(el => isEdge(el)));
  }, [elements.map(extract('id')).join('.')]);

  const openedNodeSettingsData = editor.renderNodes?.find(by(editor.openedNodeSettings))?.data;
  const openedNodeDiffData = editor.renderNodes?.find(by(editor.openedNodeDiff))?.data;

  const handleSettingsChange = (data, styles) => {
    editor.changeNodeData(editor.openedNodeSettings, data, styles);
    editor.setOpenedNodeSettings('');
  };

  const defaultOpened = editor.renderNodes?.find(n => n?.data?.defaultOpened);

  useEffect(() => {
    if (defaultOpened) {
      editor.setOpenedNodeSettings(defaultOpened.id);
      if (!worker) {
        return editor.changeNodeData(defaultOpened.id, {
          ...defaultOpened?.data,
          defaultOpened: false,
          children: (defaultOpened?.data?.children || []).map(child => ({
            ...child,
            data: {
              ...(child?.data || {}),
              defaultOpened: false,
            }
          }))
        });
      }
      dispatch({
        type: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        meta: { node: defaultOpened.id, worker: worker || 'new' },
        payload: { data: {
            ...(defaultOpened.data || {}),
            defaultOpened: false,
            children: (defaultOpened?.data?.children || []).map(child => ({
              ...child,
              data: {
                ...(child?.data || {}),
                defaultOpened: false,
              }
            }))
          }
        }
      })
    }
  }, [defaultOpened]);

  return (
    <Container switchStyle={!showHeader ? 'hidden' : page} ref={ref}>
      {!preview && (
        <Sidebar
          entryPointSelected={editor.entryPointSelected}
          onItemClick={editor.addNode}
          disabled={disabledSidebar}
          opened={editor.sidebarOpened}
          onOpenedChange={editor.setSidebarOpened}
        />
      )}
      {children}
      <EditorContainer>
        {!preview && (
          <ActionsBar
            reactFlowInstance={editor.instance}
            onFitView={editor.fitView}
            isFullScreen={!showHeader}
            onRedo={editor.actions.redo}
            onUndo={editor.actions.undo}
            zoomPanHandlerRef={zoomPanHandlerRef}
            canDelete={canDelete}
            canRedo={editor.actions.canRedo && editor.editable}
            canUndo={editor.actions.canUndo && editor.editable}
            canCopy={copyNodes && editor.editable && canCopy}
            onCopy={handleCopy}
            onFullScreen={setShowHeader}
            onPaste={handlePaste}
            onDelete={() => { handleElementsRemove([{ id: editor.selection }]) }}
            minimizeStatus={editor.minimizeStatus}
            onMinimize={editor.minimizeStatus === 'minimize' ? editor.actions.minimize : editor.actions.maximize}
          />
        )}
        <Editor ref={editor.containerRef}>
          <ReactFlow
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            style={flowStyle}
            onNodeClick={editor.onNodeClick}
            onNodeDragStart={editor.onNodeClick}
            onMouseMove={editor.onDrop}
            onInit={editor.onLoad}
            onDragOver={editor.onDragOver}
            onDragLeave={editor.onDragLeave}
            onConnect={editor.connect}
            snapGrid={snapGrid}
            defaultViewport={defaultViewport}
            snapToGrid={true}
            deleteKeyCode={null}
            nodesDraggable={editor.editable}
            onNodeDragStop={handleDragNodeEnd}
            onNodeDrag={editor.onDrag}
            fitView={preview}
            connectionLineType="smoothstep"
            onElementsRemove={handleElementsRemove}
            onSelectionChange={editor.changeSelection}
          >
            <Background
              variant={preview ? "dots" : "lines"}
              gap={110}
              color={preview ? '#FAF7EF' : '#F0F2F6'}
              size={preview ? 0 : 1}
              style={{ background: preview ? '#FAF7EF' : void 0 }}
            />
          </ReactFlow>
        </Editor>
      </EditorContainer>
      {editor.openedNodeSettings && (
        <NodeSettingsModal
          data={openedNodeSettingsData}
          editable={editor.editable}
          type={openedNodeSettingsData?.type}
          options={options}
          onSave={handleSettingsChange}
          opened={editor.openedNodeSettings}
          onClose={() => editor.setOpenedNodeSettings('')}
        />
      )}
      <NodeDiffModal
        options={options}
        invertDiff={diff.invertDiff}
        source={omit(openedNodeDiffData, 'compareWithNode')}
        origin={openedNodeDiffData?.compareWithNode?.data || {}}
        sourceLabel={openedNodeDiffData?.label}
        sourceDescription={openedNodeDiffData?.description}
        opened={editor.openedNodeDiff}
        onClose={() => editor.setOpenedNodeDiff('')}
        originLabel={openedNodeDiffData?.compareWithNode?.data?.label}
        originDescription={openedNodeDiffData?.compareWithNode?.data?.description}
      />
      <DeleteNodeModal
        toRemove={toRemove}
        opened={deleteNodeModal.opened}
        onClose={deleteNodeModal.close}
        deleteBranchDisabled={toRemove && isLast(editor.state.root, toRemove.id)}
        onDeleteNode={handleConfirmRemoveNode}
        onDeleteBranch={handleConfirmRemoveBranch}
      />
    </Container>
  );
};

export default WorkflowEditor;
