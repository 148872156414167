import styled, { css } from 'styled-components';
import { DatePicker } from 'antd';
import { styledProp } from '@utils';

const { RangePicker: ANTDRangePicker } = DatePicker;

export const Container = styled.div`
  padding: 6px 8px;
  background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
  border-radius: 7px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-family: Rubik-regular;
  font-size: 12px;
  line-height: 14px;
  justify-content: space-between;
  color: #303133;
  height: 26px;
  position: relative;
  
  ${styledProp('$large', css`
    height: 36px;

    @media screen and (max-width: 1024px) {
      height: 26px;
    }
  `)}

  ${styledProp('$lighter', css`
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 9px;
    padding: 0 0 0 13px;
    font-size: 13px;
    line-height: 120%;
    min-width: 186px;
    
    ${styledProp('$error', css`
      border-color: #C8402F;
    `)}
  `)}

  ${styledProp('disabled', css`
    cursor: default;
    pointer-events: none;
    background: #f5f5f5;
    color: rgba(0,0,0,0.3);
  `)}

  & .ant-modal {
    width: 560px !important;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  font-size: 13px;
  padding-left: 4px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
  ${styledProp('transparentLabel', `opacity: 0`)}
`;

export const IconContainer = styled.div`
  margin-left: 16px;
  border-radius: 0 7px 7px 0;
  
  ${styledProp('$lighter', css`
    background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;
  `)}
`;

export const TabsContainer = styled.div`
  display: flex;
  padding: 16px 82px;
  justify-content: space-between;
  border-bottom: 1px solid #BDCBFA;
  background: #F9FBFF;
  border-radius: 10px 10px 0 0;
  position: relative;
`;

export const Tab = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
  user-select: none;
  cursor: pointer;

  ${styledProp('$selected', css`
    color: #109DFB;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  height: 3px;
  border-radius: 3px 3px 0 0;
  background: #109DFB;
  bottom: 0;
  transition: 330ms ease all;
`;

export const QuickPicksContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  background: #F9FBFF;
  border-bottom: 1px solid #BDCBFA;
`;

export const ActionsContainer = styled.div`
  display: flex;
  padding: 12px 20px;
  border-top: 1px solid #DCDFE6;
  justify-content: space-between;
`;

export const PopupContainer = styled.div`
  position: relative;
  height: 325px;
  width: 100%;

  ${styledProp('$smaller', css`
    overflow: visible;
    width: 560px;
    transform: scale(0.81667) translateX(-63px) translateY(-36px)!important;
    margin-bottom: -56px;

    & > div {
      overflow: visible;
    }
  `)}

  & > div {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: visible;
    
    & > div {
      width: 100%;
      height: 100%;
    }
  }
  
  & .ant-picker-dropdown.ant-picker-dropdown-range {
    padding-top: 0px !important; 
    padding-bottom: 0px !important;
    width: 100%;
    height: 100%;
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    box-shadow: none !important;
    
    & > div {
      width: 100%;
      height: 100%;
      box-shadow: none !important;
    }
    
    & .ant-picker-range-arrow {
      display: none;
    }
    
    & .ant-picker-panel-container {
      box-shadow: none !important;
    }
    
    & .ant-picker-panel {
      border-bottom: none !important;
    }
  }
`;

export const StyledRangePicker = styled(ANTDRangePicker)`
  height: 0px !important;
  border: none !important;
  max-height: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
  overflow: hidden !important;
`;

export const DisabledTab = styled.div`
  padding: 40px 100px;
  font-size: 16px;
  font-family: Rubik-Regular;
  line-height: 130%;
  text-align: center;
  color: #909399;
`;

export const Error = styled.div`
  position: absolute;
  right: 9px;
  bottom: 6px;

  ${styledProp('smaller', css`
    bottom: 0;
  `)}

  ${styledProp('left', css`
    right: 44px;
  `)}

  ${styledProp('$ll', css`
    right: 32px;
  `)}
`;
