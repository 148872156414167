import React from 'react';

import { Container, GContainer, GSquareSmall, GSquareBig, TextContainer, Title, Description } from './styled';
import { Tooltip } from 'antd';
import { useTranslation } from '@hooks';

const DeleteAction = ({ disabled, deleteBranch, onClick, style, testId = '' }) => {
  const { t, p } = useTranslation('workflow_page');

  if (disabled) {
    return (
      <Tooltip title={p('no_other_nodes')}>
        <Container disabled={true} style={style}>
          <GContainer className="g-cont">
            {!deleteBranch ? (
              <GSquareBig />
            ) : (
              <>
                <GSquareSmall />
                <GSquareSmall />
                <GSquareSmall />
                <GSquareSmall />
              </>
            )}
          </GContainer>
          <TextContainer>
            <Title>
              {deleteBranch ? p('delete_branch') : p('delete_node')}
            </Title>
            <Description>
              {deleteBranch ? p('delete_branch_description') : p('delete_node_description')}
            </Description>
          </TextContainer>
        </Container>
      </Tooltip>
    )
  }

  return (
    <Container style={style} onClick={onClick} data-testid={testId}>
      <GContainer className="g-cont">
        {!deleteBranch ? (
          <GSquareBig />
        ) : (
          <>
           <GSquareSmall />
           <GSquareSmall />
           <GSquareSmall />
           <GSquareSmall />
          </>
        )}
      </GContainer>
      <TextContainer>
        <Title>
          {deleteBranch ? p('delete_branch') : p('delete_node')}
        </Title>
        <Description>
          {deleteBranch ? p('delete_branch_description') : p('delete_node_description')}
        </Description>
      </TextContainer>
    </Container>
  );
};

export default DeleteAction;
