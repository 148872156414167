import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomersCountSelector, rfmAnalyticsItemsSelector, segmentsOptionsSelector } from '@store/selectors';
import { RFMColorsConfig } from '@constants';
import { by, extract, formatNumberByK, zeroIfNaN } from '@utils';
import { useTranslation } from '@hooks';

import { Container, Customers, Description, DescriptionContainer, Title } from './styled';
import { editRFMAnalytics, getSegmentExampleCount } from '@store/actions/creators';

const getConfig = (name, parentName) => {
  return RFMColorsConfig.find(by('name', parentName || name));
};

const formatRange = (a, b) => {
  if (a === b) {
    return zeroIfNaN(a);
  }

  return `${zeroIfNaN(a)} - ${zeroIfNaN(b)}`;
};

const formatSubNameValues = (a, b) => {
  if (a === b) {
    return a;
  }

  return `${a} - ${b}`;
};

const RFMSegmentSelect = ({ query, onChange, appearance }) => {
  const { t, p } = useTranslation('rfm_analytics');
  const dispatch = useDispatch();
  const options = useSelector(segmentsOptionsSelector);
  const resource = (options?.fields?.rfmResources || []).flatMap(extract('resources')).find(by('segment_id', query.segment_id));
  const analyticsItems = useSelector(rfmAnalyticsItemsSelector);
  const analyticsData = analyticsItems?.[query.analytics]?.data || {};
  const rangeMeta = analyticsData.ranges?.find?.(({ resources }) => !!~resources?.findIndex?.(by('segment_id', query.segment_id)));
  const parent = (options?.fields?.rfmResources || []).flatMap(extract('resources')).find(by('segment_id', resource?.parent_segment_id));
  const cfg = getConfig(resource?.name, parent?.name);
  const rank = +resource?.name[resource?.name.length - 1];
  const monetaryData = (parent?.monetary_aggregate || []).find(by('monetary_ranks', rank));
  const matrix = (rangeMeta?.matrix || []).find(by('segment', parent?.name || resource?.name));
  const subName = `${formatSubNameValues((+monetaryData?.min)?.toFixed?.(2), (+monetaryData?.max)?.toFixed?.(2))} ${p('revenue').toLowerCase()}`;

  const count = useSelector(createCustomersCountSelector(`rfm-${query.segment_id}`))?.total;

  useEffect(() => {
    dispatch(editRFMAnalytics({ id: query.analytics }))
  }, [query.analytics]);

  useEffect(() => {
    dispatch(getSegmentExampleCount({
      page: 'rfm',
      store: `rfm-${query.segment_id}`,
      segmentId: query.segment_id,
    }));
  }, [query.segment_id])

  const [primaryDescription, ...secondaryPartsDescription] = p(`segment_description_${cfg?.title}`, {
    frequency_min: matrix?.recency_min || matrix?.recency_min,
    time_range: formatRange(matrix?.recency_min, matrix?.recency_max),
    frequency_range: formatRange(matrix?.frequency_min, resource?.frequency_max),
  }).split('.');

  return (
    <Container>
      <Title $c={cfg?.color?.ui}>
        {p('rfm_segment')}<span>{p(`segment_title_${cfg?.title}`)}{!!parent && '.'}&nbsp;{!!parent && subName} ({analyticsData?.name})</span>
      </Title>
      <DescriptionContainer>
        <Description>
          <span>{primaryDescription}</span>
          <br />
          <span>{secondaryPartsDescription.join('.')}</span>
        </Description>
        <Customers>
          {p(+(!!parent ? monetaryData?.count : matrix?.user_id_count) === 1 ? 'count_customer' : 'count_customers', { count })}
        </Customers>
      </DescriptionContainer>
    </Container>
  );
};

export default RFMSegmentSelect;
