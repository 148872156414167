import React from 'react';
import { useTranslation } from '@hooks';

import { Container } from './styled';
import { FiltersDropdown } from '@components/lib/SegmentEditor/components/FiltersDropdown';
import { SegmentsRuleTypes } from '@constants';

const AddEventButton = ({ options, onSelect }) => {
  const { p } = useTranslation('workflow_page');

  const mapOptions = (event) => {

    return {
      ...event,
      value: event.id,
      system: event.type === 3,
      field: event.id,
    }
  }

  const customEvents = options?.events?.filter?.(({ type }) => type !== 3)?.map?.(mapOptions) || [];
  const systemEvents = options?.events?.filter?.(({ type }) => type === 3)?.map?.(mapOptions) || [];
  const tabs = [
    {
      name: 'system-events',
      description: 'system-events-description',
      options: systemEvents,
      ruleType: SegmentsRuleTypes.EVENT,
    },
    {
      name: 'custom-events',
      description: 'custom-events-description',
      options: customEvents,
      ruleType: SegmentsRuleTypes.EVENT,
    },
  ];

  const handleSelect = () => {

  };

  return (
    <FiltersDropdown
      style={{ width: '100%' }}
      maxWidth={480}
      hiddenTypes={Object.values(SegmentsRuleTypes)}
      customTabs={tabs}
      onSelect={onSelect}
      defaultTab="system-events"
    >
      <Container>
        {p('add_event')}
      </Container>
    </FiltersDropdown>
  );
};

export default AddEventButton;
