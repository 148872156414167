import React, { useEffect, useState } from 'react';

import { Container } from './styled.js';
import { DetailsTree } from '@components/lib/EventDetailsModal/components';

const ApiRequestPreview = ({ template }) => {
  const [viewData, setViewData] = useState(null);
  useEffect(() => {
    try {
      const parsedBody = JSON.parse(template.previewBody || template.body);
      const parsedHeaders = JSON.parse(template.previewHeaders || template.headers);
      setViewData({ headers: parsedHeaders, body: parsedBody });
    } catch (e) {}
  }, [template]);

  return (
    <Container>
      <DetailsTree value={viewData} />
    </Container>
  );
}

export default ApiRequestPreview;


