import React from 'react';
import { Navigate } from 'react-router';
import { useCurrentWorkspace } from '@hooks';
import { hashAppId, removeAppFromPath } from '@utils';
import { Paths } from '@constants';

const AppRedirect = () => {
  const app = useCurrentWorkspace();

  if (!app) {
    return <Navigate to={Paths.LOGIN} replace/>;
  }

  return <Navigate to={`/app/${hashAppId(String(app.id))}${removeAppFromPath(window.location.pathname + window.location.search)}`} replace />
};

export default AppRedirect;
