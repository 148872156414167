import React, { useEffect, useRef, useState } from 'react';

import { Container, Content, Tabs, Tab, TabIndicator, TabOptionsContainer, TabOption } from './styled.js';
import { Platforms } from '@utils/platform';
import {by, findAttributes, replaceKeysWithValues} from '@utils';
import {
  EmailSampleData,
  OnboardingHighlight,
  TemplatesGeneralAttributesDrawer,
  WebPushPreview,
  WithOnboarding,
  ToggleButton
} from '@components';
import { useModalState, useOnboarding } from '@hooks';
import { useTranslation } from '@hooks';
import {FallbackValuesModal} from "@components/modals/FallbackValuesModal";
import {useSelector} from "react-redux";
import { emailSampleDataSelector, fieldsSettingsListSelector } from "@store/selectors";

const getTabs = (p) => [
  {
    name: 'desktop',
    title: p('desktop'),
  },
  {
    name: 'mobile',
    title: p('mobile'),
  },
];

const getTabOptions = (p) => ({
  ['desktop']: [
    {
      platform: Platforms.WINDOWS,
      title: p('windows'),
    },
    {
      platform: Platforms.MACOS,
      title: p('macos'),
    },
  ],
  ['mobile']: [
    {
      platform: Platforms.ANDROID,
      title: p('android'),
    },
    {
      platform: Platforms.IOS,
      title: p('ios'),
    },
  ]
});

const WebpushPreview = ({ template, onChange, previewData, icon, image, buttonsOptions, integrated }) => {
  const fieldSettings = useSelector(fieldsSettingsListSelector)?.data || [];
  const fields = fieldSettings.map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const [platform, setPlatform] = useState(Platforms.MACOS);
  const attributesModal = useModalState();
  const discountModal = useModalState();
  const fallbackModal = useModalState();
  const container = useRef(null);
  const onboarding = useOnboarding('templatesWebpush');
  const { p, t } = useTranslation('templates');

  const tabs = getTabs(p);
  const TabOptions = getTabOptions(p);

  const handleTabClick = (tab) => () => {
    setPlatform(TabOptions[tab][0].platform);
  };

  const handleChangeTab = (t) => {
    if (t === 'data_example' && onboarding.enabled(6)) {
      onboarding.nextStep();
    }
  }

  const handleToggleAttributes = () => {
    discountModal.close();
    attributesModal.toggle();
  };

  const handleToggleDiscount = () => {
    attributesModal.close();
    discountModal.toggle();
  };

  const handlePlatformChange = (platform) => () => {
    setPlatform(platform);
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['body', 'title'], template);
    onChange?.(t => ({ ...t, ...replaced }));
  }

  const tab = Object.entries(TabOptions).find(([, options]) => options.some(by('platform', platform))).shift();

  return (
    <Container $integrated={integrated}>
      {/*<ContentTitle>Preview</ContentTitle>*/}
      {/*<SliderTabs />*/}
      {!integrated && (
        <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>
          {(template.body?.indexOf('{{customer.') !== -1 || template.title.indexOf('{{customer.') !== -1) && (
            <ToggleButton
              testId="webpush-fallback-toggle"
              opened={fallbackModal.opened}
              style={{ marginRight: 4 }}
              fallback
              onToggle={fallbackModal.open}
            >
              {p('fallback_values')}
            </ToggleButton>
          )}
          <WithOnboarding onboardKey="templatesWebpush" enabled={onboarding.enabled(7)} text={p('it_will_allow_to_review_user_attributes')} next placement="leftTop">
            <OnboardingHighlight right={-12} radius={13} on={onboarding.enabled(7)}>
              <ToggleButton
                testId="email-attributes-toggle"
                opened={attributesModal.opened}
                style={{ marginRight: 12 }}
                onToggle={handleToggleAttributes}
              >
                {p('customer_attributes')}
              </ToggleButton>
            </OnboardingHighlight>
          </WithOnboarding>
          <ToggleButton
            testId="email-discount-toggle"
            style={{ marginRight: 10 }}
            opened={discountModal.opened}
            onToggle={handleToggleDiscount}
          >
            {p('general_attributes')}
          </ToggleButton>
        </div>
      )}
      <WithOnboarding onboardKey="templatesWebpush" enabled={onboarding.enabled(6)} text={p('you_may_see_webpush_preview')} next placement="leftTop">
        <OnboardingHighlight radius={13} on={onboarding.enabled(6)}>
          <Content $integrated={integrated} ref={container} id="drawer-container">
            <Tabs>
              {tabs.map(({ name, title }) => (
                <Tab
                  key={name}
                  id={`wpp-tab-${name}`}
                  selected={name === tab}
                  onClick={handleTabClick(name)}
                >
                  {title}
                </Tab>
              ))}
              <TabIndicator tab={tab} />
            </Tabs>
            <TabOptionsContainer>
              {TabOptions[tab].map(({ platform: p, title }, idx) => (
                <TabOption key={idx} selected={platform === p} onClick={handlePlatformChange(p)}>{title}</TabOption>
              ))}
            </TabOptionsContainer>
            <WebPushPreview
              platform={platform}
              integrated={integrated}
              title={!template.title ? template.title : (previewData.title || template.title)}
              text={!template.body ? template.body : (previewData.body || template.body)}
              icon={icon || ""}
              image={image || ""}
              company="Chrome"
              buttons={buttonsOptions}
            />
            <EmailSampleData
              onClose={attributesModal.close}
              opened={attributesModal.opened}
              width="100%"
              smaller
              getContainer={t => container.current}
              onVisibilityChanged={attributesModal.setOpened}
            />
            <TemplatesGeneralAttributesDrawer
              onClose={discountModal.close}
              width="100%"
              hiddenTabs={['subscription']}
              opened={discountModal.opened}
              onVisibilityChanged={discountModal.setOpened}
            />

            <FallbackValuesModal
              opened={fallbackModal.opened}
              onClose={fallbackModal.close}
              testId="fallback-modal"
              onSubmit={handleSubmitFallbacks}
              fallbacks={findAttributes([template.body, template.title], fields)}
            />
          </Content>
        </OnboardingHighlight>
      </WithOnboarding>
    </Container>
  );
}

export default WebpushPreview;


