import dagre from 'dagre';
import { isNode } from 'reactflow';
import { by, compose, getBranchFrom } from '@utils';
import { appendFinishNodes } from '@utils/workflows';
import { WorkflowNodeTypes } from '@constants';

const width = 94;
const height = 70;

export const layoutNodes = (elements, direction = 'LR') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep: 16, align: 'UL', nodesep: 40 });

  elements.forEach((el) => {
    if (isNode(el)) {
       dagreGraph.setNode(el.id, { width, height });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      el.position = {
        x: nodeWithPosition.x - width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - height / 2,
      };
    }

    return el;
  });
};

const toClosestWithGap = (v, gap) => {
  const diff1 = v % gap;
  const diff2 = 110 - Math.abs(diff1);
  const left = v - diff1;
  const right = v + diff2 * (v < 0 ? -1 : 1);

  return Math.abs(diff1) < Math.abs(diff2) ? left : right;
};

export const getClosestPosition = ({ x, y }, gap = 110) => {
  return {
    x: toClosestWithGap(x, gap),
    y: toClosestWithGap(y, gap),
  };
}

export const layoutBranchFrom = (n, ns, dir = 'LR') => {
  const branch = getBranchFrom(n, ns);
  const other = ns.filter(({ id }) => !branch.find(by(id)));

  return [...other, layoutNodes(branch, dir)];
};

export const updateNodes = (ns, updater) => {
  const filtered = ns.filter(node => {
    if (node.type === WorkflowNodeTypes.FINISH) {
      return false;
    }

    if (node.target && ns.find(by(node.target))?.type === WorkflowNodeTypes.FINISH) {
      return false;
    }

    return true;
  });

  const updated = typeof updater === 'function' ? updater(filtered) : updater;

  return appendFinishNodes(updated);
};

export const updateNodesWithLayout = compose(layoutNodes, updateNodes);
export const updateBranchWithLayoutFrom = (n, ns, updater) => layoutBranchFrom(n, updateNodes(ns, updater));
