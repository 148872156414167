import React from 'react';
import { useTranslation } from '@hooks';

import { AddFilterButton } from '../AddFilterButton';
import { Container, FolderIcon, Description } from './styled';
import { FiltersDropdown } from '@components/lib/SegmentEditor/components/FiltersDropdown';

const AddFilterPanel = ({ altButton, ...props }) => {
  const { p } = useTranslation('segments_page');

  if (altButton) {
    return (
      <FiltersDropdown
        {...props}
      >
        <AddFilterButton style={{ height: 30, width: '100%', }} />
      </FiltersDropdown>
    )
  }

  return (
    <Container>
      <FolderIcon>
        <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.538 5.37661H17.3802L12.4276 0.321531C12.3645 0.25858 12.2816 0.223202 12.1953 0.222412H1.46244C0.715106 0.222412 0.111328 0.866687 0.111328 1.66415V28.3362C0.111328 29.1337 0.715106 29.778 1.46244 29.778H32.538C33.2853 29.778 33.8891 29.1337 33.8891 28.3362V6.81835C33.8891 6.02089 33.2853 5.37661 32.538 5.37661Z" fill="#D7ECFF"/>
        </svg>
      </FolderIcon>
      <Description>
        {p('add_segment_rule_description')}
      </Description>
      <FiltersDropdown
        {...props}
      >
        <AddFilterButton />
      </FiltersDropdown>
    </Container>
  );
};

export default AddFilterPanel;
