import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { Icon } from '@components';

import { Container, FooterRow, HeaderRow, InfoIcon, Title, CompareContainer, Percentage, Values, CompareIcon } from './styled';
import { zeroIfNaN } from '@utils';

const RateCard = ({
  icon,
  color,
  title,
  total,
  compareTotal,
  actual,
  compare,
  tooltip,
  inverted,
  small,
}) => {
  const percentage = zeroIfNaN(actual / total * 100);
  const comparePercentage = zeroIfNaN(compare / compareTotal * 100);
  const ref = useRef(null);

  const diff = (percentage - comparePercentage);

  return (
    <Container $s={small} ref={ref}>
      <HeaderRow>
        {!small && (
          <Icon name={icon} size={32} color={color}/>
        )}
        <Title $s={small} $c={color}>{title}</Title>
        <InfoIcon $s={small}>
          <Tooltip title={tooltip} getPopupContainer={c => ref.current || c}>
            <div>
              <Icon name="Info-icon" size={16} color="#109DFB" />
            </div>
          </Tooltip>
        </InfoIcon>
      </HeaderRow>
      <FooterRow $s={small}>
        {!!compare && (
          <CompareContainer diff={diff} inverted={inverted}>
            <CompareIcon>
              {diff > 0 && !inverted ? (
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.18484 1.14716C5.58349 0.58615 6.41651 0.58615 6.81516 1.14716L11.806 8.17076C12.2765 8.83287 11.8031 9.75 10.9909 9.75H1.00914C0.196887 9.75 -0.276507 8.83287 0.19398 8.17076L5.18484 1.14716Z" fill="#24B277"/>
                </svg>
              ) : (
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.81516 9.35284C6.41651 9.91385 5.58349 9.91385 5.18484 9.35284L0.19398 2.32924C-0.276507 1.66713 0.196887 0.75 1.00914 0.75H10.9909C11.8031 0.75 12.2765 1.66713 11.806 2.32924L6.81516 9.35284Z" fill="#C8402F"/>
                </svg>
              )}
            </CompareIcon>
            {Math.abs(diff).toFixed(2)}%
          </CompareContainer>
        )}
        <Percentage $s={small}>
          {percentage.toFixed(2)}%
        </Percentage>
        <Values>
          {zeroIfNaN(actual)} of {zeroIfNaN(total)}
        </Values>
      </FooterRow>
    </Container>
  );
};

export default RateCard;
