import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SettingsContainer = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 20px;

  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 7px;
  `)}
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #109DFB;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  padding: 14px;
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #109DFB;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ $tab: tab }) => {
    return css`
      width: ${tab?.getBoundingClientRect()?.width}px;
      left: ${tab?.offsetLeft}px;
    `;
  }}
`;



