import React from 'react';
import { Icon } from '@components';
import { Handle } from 'reactflow';

import { Wrapper, Container, PointerContainer, ABText, NodeAnalyticsContainer, StyledHandle } from './styled';
import { WorkflowNodes } from '@constants';
import { NodeAnalytics } from '@components/lib/WorkflowEditor/components';
import { testId } from '@utils';

const SplitterNode = ({ id, data, selected }) => {
  const renderLabel = () => {
    switch (data.actionType) {
      case WorkflowNodes.EXCLUDE:
        return (
          <Icon name="Cross-icon-for-actions" size={18} color="#C8402F" />
        );
      case WorkflowNodes.INCLUDE:
        return (
          <Icon name="Checkbox-check" size={18} color="#24993E" />
        );
      case WorkflowNodes.AB_TEST:
        return (
          <ABText>
            {data.value}%
          </ABText>
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <PointerContainer selected={selected}>
        <Container {...testId(`workflow-node-splitter-${data.actionType}`)()} name={data.actionType} key={id}>
          {data.analytics && (
            <NodeAnalyticsContainer>
              <NodeAnalytics data={data.analytics} />
            </NodeAnalyticsContainer>
          )}
          <StyledHandle
              type="source"
              position="right"
              id="b"
              isConnectable
              style={{ top: '50%', background: '#A9A7A7' }}
          >
            <Icon name="Plus-icon" size={4} color="#fff" style={{ lineHeight: 4, transform: 'translateY(0.3px)'  }} />
          </StyledHandle>
          <StyledHandle
              type="target"
              position="left"
              id="a"
              isConnectable
              style={{ top: '50%', background: '#A9A7A7', opacity: 0, left: '0px', pointerEvents: 'none' }}
          >
            <Icon name="Plus-icon" size={4} color="#fff" style={{ lineHeight: 4, transform: 'translateY(0.3px)'  }} />
          </StyledHandle>
          {renderLabel()}
        </Container>
      </PointerContainer>
     </Wrapper>
  );
};

export default SplitterNode;
