import { ReducerKeys } from '@constants';
import { createSelector } from 'reselect';
import get from 'lodash.get';

export const fieldsAutocompleteSelector = store => store[ReducerKeys.FIELDS].autocomplete;

const resolveSelectorPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

export const createFieldAutocompleteSelector = (path, filter) => createSelector(
  fieldsAutocompleteSelector,
  (ac) => {
    return get(ac, resolveSelectorPath(path, filter));
  }
);

export const dynamicVariablesSelector = store => store[ReducerKeys.FIELDS].dynamic_variables?.data || [];
