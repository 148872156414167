import React, { useEffect, useRef, useState } from 'react';
import { StyledTimePicker } from './styled';

const TimePicker = ({ active, smaller, value, onChange, ...props }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (active) {
      ref.current.focus();
      setOpen(true);
    }
  }, [active]);

  return (
    <StyledTimePicker
      placement="topLeft"
      ref={ref}
      open={open}
      onOpenChange={setOpen}
      value={value}
      $smaller={smaller}
      format={smaller ? 'hh:mm' : 'hh:mm:ss'}
      onChange={onChange}
      $active={active}
      {...props}
    />
  );
};

export default TimePicker;
