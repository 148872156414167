import { by, extract, zeroIfNaN } from '@utils';
import {
  AppRedirects,
  EmailStatuses,
  MobilePushStatuses, Paths,
  SmsStatuses, ViberStatuses,
  WebhookStatuses,
  WebpushStatuses,
  WorkflowNodes
} from '@constants';
import omit from 'lodash.omit';
import {getIntervalRange, getNextLaunches} from "../date";

export const getDisabledTypes = (type) => {
  if (type === WorkflowNodes.RESOURCE) {
    return [2];
  }
  if (~[
    WorkflowNodes.PAGE_VIEW,
    WorkflowNodes.EVENT,
    WorkflowNodes.CONTACT_CREATED,
    WorkflowNodes.DATA_CHANGE,
    WorkflowNodes.EMAIL
  ].indexOf(type)) {
    return [0, 1];
  }

  return [];
};

export const getChronTime = schedule => ({
  every_minute: `*/${schedule['minute']} * * * *`,
  hourly: `0 ${schedule['minute'] || 0} */${schedule['hour']} * * *`,
  daily: `0 ${schedule['minute'] || 0} ${schedule['hour'] || 0} */${schedule['day']} * *`,
  weekly: `0 ${schedule['minute'] || 0} ${schedule['hour'] || 0} * * ${schedule['week_day'].join()}`,
  monthly: `0 ${schedule['minute'] || 0} ${schedule['hour'] || 0} ${schedule['month_day']} * *`,
  custom: schedule['cron_time'],
})[schedule.active_tab];

export const Localizations = {
  sent: 'Sent',
  open: 'Opened',
  delivered: 'Delivered',
  failed: 'Failed',
  enroute: 'Enroute',
  processed: 'Processed',
  dropped: 'Dropped',
  deferred: 'Deffered',
  bounce: 'Bounced',
  blocked: 'Blocked',
  click: 'Clicked',
  spam_report: 'Spam reported',
  unsubscribe: 'Unsubscribed',
  queued: 'Queued',
  undelivered: 'Undelivered',
  dismiss: 'Dismissed',
  resubscribed: 'Resubscribed',
  unsubscribed: 'Unsubscribed',
};

export const funnelNodesConversionConfig = [
  {
    name: 'sent',
    statuses: ['sent', 'processed', 'enroute', 'queued'],
  },
  {
    name: 'failed',
    hide: true,
    negative: true,
    statuses: ['failed', 'dropped', 'deferred', 'bounce', 'blocked', 'undelivered', 'dismissed']
  },
  {
    name: 'delivered',
    positive: true,
    statuses: ['delivered'],
  },
  {
    name: 'open',
    positive: true,
    statuses: ['open'],
  },
  {
    name: 'click',
    positive: true,
    statuses: ['click'],
  },
  {
    name: 'spam_report',
    negative: true,
    hide: true,
    statuses: ['spam_report'],
  },
  {
    name: 'unsubscribed',
    negative: true,
    hide: true,
    statuses: ['unsubscribed'],
  },
  {
    name: 'resubscribed',
    positive: true,
    hide: true,
    statuses: ['resubscribed'],
  },
];

const createStatusesFilter = (type) => {
  switch (type) {
    case WorkflowNodes.SEND_SMS:
      return s => !!~Object.values(SmsStatuses).indexOf(s);
    case WorkflowNodes.SEND_EMAIL:
      return s => !!~Object.values(EmailStatuses).indexOf(s);
    case WorkflowNodes.MOBILE_PUSH:
      return s => !!~Object.values(MobilePushStatuses).indexOf(s);
    case WorkflowNodes.WEBPUSH:
      return s => !!~Object.values(WebpushStatuses).indexOf(s);
    case WorkflowNodes.API_REQUEST:
      return s => !!~Object.values(WebhookStatuses).indexOf(s);
    case WorkflowNodes.VIBER:
      return s => !!~Object.values(ViberStatuses).indexOf(s);
    default:
      return () => true;
  }
}

export const getLaunchRange = (launch, schedule) => ({
  every_minute: getIntervalRange(launch, 'minute'),
  hourly: getIntervalRange(launch, 'hour'),
  daily: getIntervalRange(launch, 'day'),
  weekly: getIntervalRange(launch, 'week'),
  monthly: getIntervalRange(launch, 'month'),
  custom: getNextLaunches(schedule['cron_time'] || '0 0 0 1 * *', launch, 2),
})[schedule.active_tab];

export const parseFunnelAnalytics = (s, type) => {
  const matchesType = createStatusesFilter(type);
  const sending = s.reduce((acc, { count, status }) => ({ ...acc, [status]: count }), { sent: 0, open: 0, click: 0, delivered: 0, processed: 0 });
  let parsed = funnelNodesConversionConfig
    .filter(({ statuses }) => (statuses || []).some(matchesType))
    .reduce((acc, {
        name,
        label,
        statuses,
        ...rest
      }, priority) => {
      const value = statuses.reduce((acc, status) => sending[status] ? acc + sending[status] : acc, 0);
      const minValue = Math.min(...[...(Object.values(acc).flatMap(extract('value'))), 0]);

      if (isNaN(value)) {
        return acc;
      }

      return {
        ...acc,
        [name]: {
          ...rest,
          priority,
          statuses: statuses.reduce((acc, status) => sending[status] ? ({ ...acc, [status]: sending[status] }) : acc, {}),
          value: value < minValue ? minValue : value,
        }
      }
    }, {});
  if (parsed.open?.value > parsed.delivered?.value) {
    parsed = omit(parsed, ['delivered']);
  }
  if (parsed.sent?.value < parsed.delivered?.value) {
    parsed.sent.value = parsed.delivered?.value;
  }
  const conversion = Object.fromEntries(Object.entries(parsed).filter(([, { hide }]) => !hide));
  const localizations = Object.entries(parsed)
    .flatMap(([key, { statuses }]) => Object.keys(statuses).concat(key))
    .reduce((acc, key) => ({
      ...acc,
      [key]: Localizations[key]
    }), {});

  return {
    sending: parsed,
    conversion,
    localizations
  };
};

export const resolveCampaignType = (channelNode) => {
  return {
    isEmail: channelNode.data.name === WorkflowNodes.SEND_EMAIL,
    isSms: channelNode.data.name === WorkflowNodes.SEND_SMS,
    isWebpush: channelNode.data.name === WorkflowNodes.WEBPUSH,
    isMobilePush: channelNode.data.name === WorkflowNodes.MOBILE_PUSH,
    isViber: channelNode.data.name === WorkflowNodes.VIBER,
  }
}

export const getNodeKeyByChannel = (channelNode) => {
  const { isEmail, isSms, isWebpush, isMobilePush, isViber } = resolveCampaignType(channelNode);

  if (isEmail) {
    return { optionKey: 'emails', nodeKey: 'email_id' };
  }
  if (isSms) {
    return { optionKey: 'sms', nodeKey: 'sms_id' };
  }
  if (isWebpush) {
    return { optionKey: 'web_push', nodeKey: 'webpush_id' };
  }
  if (isViber) {
    return { optionKey: 'viber', nodeKey: 'viber_id' };
  }
  if (isMobilePush) {
    return { optionKey: 'mobile_push', nodeKey: 'mobile_push_id' };
  }
}

export const resolveCampaignNameByType = (options, channelNode) => {
  const { optionKey, nodeKey } = getNodeKeyByChannel(channelNode);

  return options?.[optionKey]?.find(by(channelNode.data[nodeKey]))?.name;
}

export const getAppRedirectCreationByChannel = (channelNode) => {
  const name = channelNode?.data?.name;

  if (name === WorkflowNodes.SEND_EMAIL) {
    return AppRedirects.EMAIL_TEMPLATE_CREATION;
  }
  if (name === WorkflowNodes.SEND_SMS) {
    return AppRedirects.SMS_TEMPLATE_CREATION;
  }
  if (name === WorkflowNodes.WEBPUSH) {
    return AppRedirects.WEB_PUSH_TEMPLATE_CREATION;
  }
  if (name === WorkflowNodes.VIBER) {
    return AppRedirects.VIBER_TEMPLATE_CREATION;
  }
  if (name === WorkflowNodes.MOBILE_PUSH) {
    return AppRedirects.MOBILE_PUSH_TEMPLATE_CREATION;
  }
  if (name === WorkflowNodes.API_REQUEST) {
    return AppRedirects.API_REQUEST_TEMPLATE_CREATION;
  }
}

export const resolveChannelLabel = (channelNode, t) => {
  const { isEmail, isSms, isWebpush, isMobilePush, isViber } = resolveCampaignType(channelNode);

  if (isEmail) {
    return t('labels.email')
  }
  if (isSms) {
    return t('labels.sms')
  }
  if (isWebpush) {
    return t('labels.webpush')
  }
  if (isViber) {
    return t('labels.viber')
  }
  if (isMobilePush) {
    return t('labels.mobile_push')
  }
}

export const resolveCreationNewLink = (channelNode) => {
  const { isEmail, isSms, isWebpush, isMobilePush, isViber } = resolveCampaignType(channelNode);

  if (isEmail) {
    return `${Paths.TEMPLATES_EMAIL}/list/default?defaultCreate`;
  }
  if (isSms) {
    return `${Paths.TEMPLATES_SMS}/new?f=default`;
  }
  if (isWebpush) {
    return `${Paths.TEMPLATES_WEBPUSH}/new?f=default`;
  }
  if (isViber) {
    return `${Paths.TEMPLATES_VIBER}/new?f=default`;
  }
  if (isMobilePush) {
    return `${Paths.TEMPLATES_MOBILE_PUSH}/new?f=default`;
  }
}

export const resolveChannelLink = (channelNode) => {
  const { isEmail, isSms, isWebpush, isMobilePush, isViber } = resolveCampaignType(channelNode);

  if (isEmail) {
    return `${Paths.TEMPLATES_EMAIL}/code/${channelNode.data.email_id}`;
  }
  if (isSms) {
    return `${Paths.TEMPLATES_SMS}/${channelNode.data.sms_id}`;
  }
  if (isWebpush) {
    return `${Paths.TEMPLATES_WEBPUSH}/${channelNode.data.webpush_id}`;
  }
  if (isViber) {
    return `${Paths.TEMPLATES_VIBER}/${channelNode.data.viber_id}`;
  }
  if (isMobilePush) {
    return `${Paths.TEMPLATES_MOBILE_PUSH}/${channelNode.data.mobile_push_id}`;
  }
}

export const getConversionValue = (conversion) => {
  const values = Object.values(conversion);
  const first = [...values].shift();
  const last = [...values].pop();

  return zeroIfNaN(last?.value / first?.value * 100).toFixed(2);
};
