import { all, takeLatest, put } from 'redux-saga/effects';
import { HistoryNotificationsActionTypes } from '../../actions/types';
import { Endpoints } from '@constants';
import { createRequestAction } from '@store/lib/utils/creators';

function* getEmailsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_EMAIL,
  }));
}

function* getFacebookSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_FACEBOOK,
  }));
}

function* getMobilePushesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_MOBILE_PUSHES,
  }));
}

function* getPromoCodesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/notificator/discount',
  }));
}

function* getSmsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_SMS,
  }));
}

function* getTemplateSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_TEMPLATES,
  }));
}

function* getVibersSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_VIBER,
  }));
}

function* getWebhookSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_WEBHOOK,
  }));
}

function* getWebpushSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_WEBPUSH,
  }));
}

function* getNotificationHistoryCountSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/${action.payload.entity}/count`,
  }));
}

export default function* contactsRootSaga() {
  yield all([
    takeLatest(HistoryNotificationsActionTypes.GET_EMAIL, getEmailsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_SMS, getSmsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_WEBHOOK, getWebhookSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_VIBERS, getVibersSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_TEMPLATES, getTemplateSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_WEBPUSH, getWebpushSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_MOBILE_PUSHES, getMobilePushesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_PROMO_CODES, getPromoCodesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_FACEBOOK, getFacebookSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT, getNotificationHistoryCountSaga),
  ]);
}
