import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const TotalText = styled.div`
  font-size: 16px;
  line-height: 130%;
  color: #303133;
  text-align: right;
  padding-bottom: 16px;
`;

export const Header = styled.div`
  background: #F0F2F6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px; 
  margin-bottom: 16px;
`;

export const Text = styled.div`
  flex: 1;
  margin-bottom: 16px;
  
  ${styledProp('right', css`
    text-align: right;
    max-width: 70px;
  `)}
`;

export const HeaderText = styled.div`
  font-family: Rubik-Regular;
  flex: 1;
  font-size: 13px;
  line-height: 120%;
  color: #909399;

  ${styledProp('right', css`
    text-align: right;
    max-width: 70px;
  `)}
`;
