import React from 'react';
import { MoreButton, WithPermissions } from '@components';
import { ActionsRow, MenuItem } from '../../styled';
import { useModalState } from '@hooks';
import { UserPermissions } from '@constants';
import {Menu, Tooltip} from 'antd';
import { Colors } from '@res';
import { useDispatch } from 'react-redux';
import {archivePromoCode, deletePromoCode, unArchivePromoCode} from '@store/actions/creators';
import { useTranslation } from '@hooks';
import { notify, testId } from '@utils';
import { ConfirmationModal } from "@components";

const TableActions = ({ id, used, status, workers, updateTable, onRefresh, name }) => {
  const deleteModal = useModalState(false);
  const dispatch = useDispatch();
  const { p, t } = useTranslation('promo_codes_page');

  // const handlePublish = (id) => () => {
  //   dispatch(publishPromoCode({
  //     id,
  //     onSuccess: () => {
  //       notify('success', 'Success', 'Promo code was successfully published.');
  //     },
  //   }));
  // };

  const handleArchive = () => {
    if (used) {
      return;
    }

    dispatch(archivePromoCode({
      id,
      onSuccess: ()=>{
        onRefresh();
        updateTable?.();
        notify('success', p('promo_code_archived'), name);
      }
    }));
  };

  const handleUnArchive = () => {
    dispatch(unArchivePromoCode({
      id,
      onSuccess: ()=>{
        onRefresh();
        updateTable?.();
        notify('success', p('promo_code_unarchived'), name);
      }
    }));
  };

  const handleDeletePromoCode = () => {
    dispatch(deletePromoCode({
      id,
      onSuccess: () => {
        deleteModal.close();
        onRefresh();
        notify('success', p('promo_code_deleted'), name);
      }
    }));
  }

  return (
    <ActionsRow>
      {/*<ActionButton*/}
      {/*  disabled={Number(status) === 2}*/}
      {/*  tooltip="Publish"*/}
      {/*  onClick={handlePublish(id)}*/}
      {/*  icon="Publish-icon"*/}
      {/*/>*/}
      {status !== 'Active' && (<MoreButton
          style={{
            display: 'flex',
            justifyContent: 'end'
          }}
          iconSize={22}
          iconStyles={{ height: '22px' }}
          menu={(
            <Menu style={{ borderRadius: '10px' }}>
              {status !== 3 && (
                <Menu.Item disabled={used} key="0">
                  <WithPermissions name={UserPermissions.ARCHIVE_PROMO_CODES}>
                    <MenuItem data-testid="promo-codes-actions-archive" onClick={handleArchive} style={{ color: Colors.Text.DANGER, opacity: used ? '0.5' : '1' }}>{t('actions.archive')}</MenuItem>
                  </WithPermissions>
                </Menu.Item>
              )}
              {[3].includes(+status) && (
                <Menu.Item key="1">
                  <WithPermissions name={UserPermissions.UN_ARCHIVE_PROMO_CODES}>
                    <MenuItem data-testid="promo-codes-actions-un-arcive" onClick={handleUnArchive}>{t('actions.unarchive')}</MenuItem>
                  </WithPermissions>
                </Menu.Item>
              )}
              {[3].includes(+status) && (
                <Menu.Item disabled={workers?.[0] !== undefined} key="2">
                  <WithPermissions name={UserPermissions.DELETE_PROMO_CODE}>
                    { workers?.[0] !== undefined ? (
                      <Tooltip title={p('cant_delete')}>
                        <MenuItem data-testid="promo-codes-actions-delete" style={{color: Colors.Text.DANGER}} onClick={workers?.[0] !== undefined ? () => {} : deleteModal.open}  >
                          {t('actions.delete')}
                        </MenuItem>
                      </Tooltip>
                    ) : (
                        <MenuItem style={{color: Colors.Text.DANGER}} onClick={workers?.[0] !== undefined ? () => {} : deleteModal.open}  >
                          {t('actions.delete')}
                        </MenuItem>
                      )
                    }
                  </WithPermissions>
                </Menu.Item>
              )}
            </Menu>
          )}>
        </MoreButton>
      )}
      <ConfirmationModal
        onClose={deleteModal.close}
        opened={deleteModal.opened}
        title={p('delete_promo_code')}
        appearance="danger"
        {...testId('promo-codes-delete-confirm')()}
        text={p('sure_want_to_delete')}
        onCancel={deleteModal.close}
        onSubmitClick={handleDeletePromoCode}
        buttonsParams={{
          cancelAppearance: 'secondary',
          submitAppearance: 'danger',
          cancelLabel: t('actions.cancel'),
          submitLabel: t('actions.delete'),
        }}
      />
    </ActionsRow>
  );
};

export default TableActions;
