import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const FiltersContainer = styled.div`
  background: #F0F2F6;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 4px 0 0 0;
`;

export const DeleteFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #C8402F;
  width: 100%;
  cursor: pointer;
  user-select: none;
  margin-bottom: 14px;
`;

export const FieldRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 6px;
`;

export const FieldLabel = styled.div`
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  margin-right: 6px;
  color: #909399;
`;

export const EventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AddEventButtonContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const AddEventButtonBackground = styled.div`
  position: absolute;
  top: -24px;
  bottom: -30px;
  left: -20px;
  right: -20px;
  background: #fff;
  border-bottom: 1px solid #DCDFE6;
`;

