export const SystemEvents = {
  EMAIL: '_em',
  SMS: '_sm',
  WEBPUSH: '_wp',
  PAGE_VIEW: '_v',
  START_SESSION: '_ss',
  END_SESSION: '_es',
  FIRST_SESSION: '_fs',
  VIBER: '_vb',
  MOBILE_PUSH: '_mp',
  RFM_SEGMENT_TRANSITION: '_rfm_sd'
};
