import React from 'react';
import { Button, Modal } from '@components';

import { Container } from './styled';
import { useModalState, useTranslation } from '@hooks';

const LeavePageConfirmationModal = ({ hidden, onClose, onSubmit, target }) => {
  const { p, t } = useTranslation('templates');
  const state = useModalState();

  const handleClose = () => {
    onClose();
    state.close();
  };

  return (
    <Modal
      title={p('leave_confirmation')}
      opened={!hidden}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose} appearance="secondary" width={140}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={onSubmit} appearance="primary" width={140}>
            {t('actions.leave')}
          </Button>
        </>
      }
    >
      <Container>
        <div>{p('sure_you_want_to_leave', { target: target || p('workflow') })}</div>
      </Container>
    </Modal>
  );
};

export default LeavePageConfirmationModal;
