import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Modal, Multiselect, SearchSelect, Select } from '@components';
import { fieldsSettingsListSelector } from '@store/selectors';
import { by, extract, notify } from '@utils';
import { useErrors } from '@hooks';

import { Container, Actions, PreviewClose, PreviewItem, SelectionPreview } from './styled';
import {
  downloadContacts,
  exportFunnelCustomers,
  exportSegmentCustomers
} from '@store/actions/creators';
import { sequence } from '@utils';
import { useTranslation } from '@hooks';

const DownloadContactsModal = ({ opened, onClose, ids, id, filters, name, query, funnelId, funnels, aggregates, funnel, step, steps, drillDownOptions, onStepChange }) => {
  const isSegment = !!(query || id);
  const { t, p } = useTranslation('segments_page');
  const [fields, setFields] = useState(['id']);
  const fieldOptions = useSelector(fieldsSettingsListSelector);
  const dispatch = useDispatch();
  const errors = useErrors(['fields', 'query']);
  const [loading, setLoading] = useState(false);
  const [filterDrillValue, setFilterDrillValue] = useState(undefined);

  // useEffect(() => {
  //   if (fieldOptions.data?.length && isSegment) {
  //     setFields(fieldOptions.data.map(extract('field')))
  //   }
  // }, [fieldOptions]);

  const handleClose = () => {
    onClose();
    setFields(['id']);
    errors.clear();
  };

  const handleRemove = (field) => () => {
    setFields(fs => fs.filter(f => f !== field));
    if (fields.filter(f => f !== field).length <= 5 || isSegment) {
      errors.clear();
    }
  };

  const handleConfirm = () => {
    if ((fields.length && !isSegment) > 5) {
      return errors.setError('f', p('max_five_field'));
    }
    errors.clearError('f');

    setLoading(true);

    if (funnel) {
      return dispatch(exportFunnelCustomers({
        ...funnel,
        filterDrillValue,
        mainFunnel: {
          ...funnel.mainFunnel,
          steps: funnel.mainFunnel.steps.slice(0, step + 1),
        },
        fields,
        onError: sequence(errors.handleRequestError, ({ response: { status } }) => {
          setLoading(false);
          if (status >= 500) {
            notify('error', t('labels.error'), p('file_too_large'));
          }
        }),
        onSuccess: () => {
          setLoading(false);
          handleClose();
          notify('success', t('labels.success'), p('customers_downloaded'))
        }
      }));
    }

    if (funnelId) {
      return dispatch(exportFunnelCustomers({
        id: funnelId,
        fields,
        filterDrillValue,
        onError: sequence(errors.handleRequestError, ({ response: { status } }) => {
          setLoading(false);
          if (status >= 500) {
            notify('error', t('labels.error'), p('file_too_large'));
          }
        }),
        onSuccess: () => {
          setLoading(false);
          handleClose();
          notify('success', t('labels.success'), p('customers_downloaded'))
        }
      }));
    }

    if (query) {
      return dispatch(exportSegmentCustomers({
        query,
        name,
        fields,
        aggregates,
        funnels,
        onError: sequence(errors.handleRequestError, ({ response: { status } }) => {
          setLoading(false);
          if (status >= 500) {
            notify('error', t('labels.error'), p('file_too_large'));
          }
        }),
        onSuccess: () => {
          setLoading(false);
          handleClose();
          notify('success', t('labels.success'), p('customers_processing'))
        }
      }));
    }

    if (id) {
      return dispatch(exportSegmentCustomers({
        fields,
        id,
        name,
        onError: sequence(errors.handleRequestError, ({ response: { status } }) => {
          setLoading(false);
          if (status >= 500) {
            notify('error', t('labels.error'), p('file_too_large'));
          }
        }),
        onSuccess: () => {
          setLoading(false);
          handleClose();
          notify('success', t('labels.success'), p('customers_processing'))
        }
      }));
    }

    dispatch(downloadContacts({
      fields,
      filters,
      ids: ids.length ? ids : undefined,
      onError: sequence(errors.handleRequestError, ({ response: { status } }) => {
        setLoading(false);
        if (status >= 500) {
          notify('error', t('labels.error'), p('file_too_large'));
        }
      }),
      onSuccess: () => {
        setLoading(false);
        handleClose();
        notify('success', t('labels.success'), p('customers_downloaded'))
      }
    }))
  };

  const handleChangeFields = (f) => {
    if (f.length > 5) {
      return;
    }

    setFields(f);
  }

  return (
    <Modal
      title={t('actions.download_customers_to_csv')}
      onClose={handleClose}
      opened={opened}
      style={{ maxWidth: 360 }}
    >
      <Container>
        {!!fields.length && (
          <SelectionPreview>
            {fields.map((field, idx) => (
              <PreviewItem key={idx} data-testid={`selected-field-${field}`}>
                {(fieldOptions.data || []).find(by('field', field))?.label}
                <PreviewClose onClick={handleRemove(field)} data-testid={`selected-field-${field}-remove`}>
                  <Icon size={10} name="Cross-icon" color="#fff" />
                </PreviewClose>
              </PreviewItem>
            ))}
          </SelectionPreview>
        )}
        <Multiselect
          options={(fieldOptions.data || []).filter(({ type }) => type !== 'array').map(({ field, label }) => ({ value: field, label }))}
          value={fields}
          error={errors.errors['f']}
          containerStyle={{ width: '100%' }}
          style={{ width: '100%' }}
          onChange={handleChangeFields}
          title={p('select_fields_title')}
          placeholder={t('labels.select')}
        />
        {steps && (
          <SearchSelect
            options={steps}
            value={step}
            containerStyle={{ width: '100%', marginTop: 16 }}
            style={{ width: '100%' }}
            onChange={onStepChange}
            label={t('labels.choose_point')}
          />
        )}
        {!!drillDownOptions?.length && (
          <SearchSelect
            options={drillDownOptions}
            containerStyle={{ width: '100%', marginTop: 16 }}
            style={{ width: '100%' }}
            onChange={setFilterDrillValue}
            value={filterDrillValue}
            label={t('labels.drill_down_value')}
          />
        )}
        <Actions>
          <Button testId="download-contact-modal-cancel" appearance="secondary" style={{ width: 140 }} onClick={handleClose}>{t('actions.cancel')}</Button>
          <Button testId="download-contact-modal-confirm" style={{ width: 140 }} loading={loading} onClick={handleConfirm}>{t('actions.download')}</Button>
        </Actions>
      </Container>
    </Modal>
  );
};

export default DownloadContactsModal;
