import React, { useMemo, useState } from 'react';
import { moment } from '@utils';
import { Button, Icon } from '@components';

import { DateRangePicker, Calendar } from 'react-date-range'

import {
  Container,
  ActionButton,
  StyledRangePicker,
  PickerContainer,
  Overlay,
  ActionsContainer, StyledIcon, StyledDatePicker,
} from './styled.js';
import { Dropdown } from 'antd';
import { useModalState, useTranslation } from '@hooks';
import { momentlessRanges } from '@constants';

function isDateMidnight(date) {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
}

const RangePicker = ({
  value,
  onChange,
  containerStyle,
  smaller,
  defaultValue = [moment(), moment()],
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const pickerModal = useModalState();

  const handleMoveRangeLeft = () => {
    if (props.picker) {
      return onChange(value.map(d => moment(d).add(-1, props.picker)));
    }

    const [fromDate, toDate] = value;
    const diff = Math.abs(fromDate.diff(toDate, 'days'));

    onChange(value.map(d => moment(d).add(-diff || -1, 'days')));
  };

  const handleMoveRangeRight = () => {
    if (props.picker) {
      return onChange(value.map(d => moment(d).add(1, props.picker)));
    }

    const [fromDate, toDate] = value;
    const diff = Math.abs(fromDate.diff(toDate, 'days'));

    onChange(value.map(d => moment(d).add(diff || 1, 'days')));
  };

  const handleChange = (date) => {
    if (!Array.isArray(date)) {
      onChange([moment(date).startOf(props.picker), moment(date).endOf(props.picker)]);
    } else {
      onChange(date);
    }
  };

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    const isMidnight = isDateMidnight(ranges.selection.endDate);
    if (!isMidnight) {
      setSelectionRange(ranges.selection);
    } else {
      const endDate = new Date(ranges.selection.endDate);

      endDate.setDate(endDate.getDate() + 1);
      endDate.setMinutes(endDate.getMinutes() - 1);

      setSelectionRange({
        ...ranges.selection,
        endDate,
      });
    }
  }

  function convertDateToMoment(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return moment([year, month, day, hour, minute, second]);
  }

  const handleSave = () => {
    onChange([convertDateToMoment(new Date(selectionRange.startDate)), convertDateToMoment(new Date(selectionRange.endDate))]);
    pickerModal.close();
  }

  const handleSelectPicker = (date) => {
    if (date.selection) {
      onChange([moment(date.selection.endDate).startOf(props.picker), moment(date.selection.endDate).endOf(props.picker)]);
      pickerModal.close();
      return;
    }
    onChange([moment(date).startOf(props.picker), moment(date).endOf(props.picker)]);
    pickerModal.close();
  }

  const absoluteRangeState = useMemo(() => {
    return [selectionRange];
  }, [selectionRange])

  return (
    <Container className="custom-range-picker" style={containerStyle}>
      <ActionButton smaller={smaller} onClick={handleMoveRangeLeft}>
        <Icon style={{ lineHeight: '10px' }} name="Back-arrow1" size={10} />
      </ActionButton>
      <PickerContainer className={`range-picker-${props.picker}`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {props.picker ? (
          <>
            <Dropdown
              placement="bottomCenter"
              trigger={['click']}
              onOpenChange={pickerModal.toggle}
              open={pickerModal.opened}
              overlay={props.picker === 'week' || props.picker === 'day' ? (
                <Overlay>
                  {props.picker === 'week' ? (
                    <DateRangePicker
                      months={1}
                      ranges={[{ startDate: moment(value?.[0]).toDate(), endDate: moment(value?.[1]).toDate(), key: 'selection' }]}
                      onChange={handleSelectPicker}
                    />
                  ) : (
                    <Calendar
                      date={value?.[0] ? moment(value?.[0]).toDate() : new Date()}
                      onChange={handleSelectPicker}
                    />
                  )}
                </Overlay>
              ) : <></>}
            >
              <StyledDatePicker
                value={value?.[0]}
                style={{ backgroundColor: '#E5E8ED !important' }}
                onChange={handleChange}
                bordered={false}
                className="srp"
                data-testid={`range-picker`}
                smaller={smaller}
                suffixIcon={(
                  <StyledIcon className="clr" name="Calendar1" size={12} color="#909399"  />
                )}
                {...props}
              />
            </Dropdown>
          </>
        ) : (
          <Dropdown
            placement="bottomCenter"
            trigger={['click']}
            onOpenChange={pickerModal.toggle}
            open={pickerModal.opened}
            overlay={(
              <Overlay>
                <DateRangePicker
                  ranges={absoluteRangeState}
                  months={2}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  direction="horizontal"
                  onChange={handleSelect}
                  staticRanges={momentlessRanges(t)}
                />
                <ActionsContainer>
                  <div style={{ display: 'flex' }}>
                    <Button onClick={pickerModal.close} width={100} appearance="secondary">{t('actions.cancel')}</Button>
                    <Button onClick={handleSave} style={{ marginLeft: 10 }} width={100}>{t('actions.apply')}</Button>
                  </div>
                </ActionsContainer>
              </Overlay>
            )}
          >
            <StyledRangePicker
              value={props.picker ? value?.[0] : value}
              style={{ backgroundColor: '#E5E8ED !important' }}
              smaller={smaller}
              disabled
              data-testid={`range-picker`}
              onChange={r => handleChange(r)}
              suffixIcon={(
                <Icon name="Calendar1" size={12} color={hover ? 'transparent' : '#909399'}  />
              )}
              bordered={false}
              {...props}
            />
          </Dropdown>
        )}
      </PickerContainer>
      <ActionButton smaller={smaller} inverted onClick={handleMoveRangeRight}>
        <Icon style={{ lineHeight: '10px' }} name="Back-arrow1" size={10} />
      </ActionButton>
    </Container>
  );
}

export default RangePicker;


