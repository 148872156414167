import React, { useEffect, useState } from 'react';

import { Container } from './styled.js';
import { Tooltip } from 'antd';

const FlexibleText = ({ children = '', gray = false, maxWidth, text = '', hideTooltip = false, testId, ...props }) => {
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    const fakeBlock = document.createElement('div');
    fakeBlock.style.position = "absolute";
    fakeBlock.style.left = "-999em";
    fakeBlock.style.opacity = '0';
    fakeBlock.innerHTML = text;
    document.body.appendChild(fakeBlock);

    setHidden(fakeBlock.clientWidth > maxWidth);

    document.body.removeChild(fakeBlock);
  }, [children, maxWidth]);

  return (
    <>
      { (hidden && !hideTooltip) ? (
        <Tooltip title={text}>
          <Container data-testid={testId} {...props} gray={gray} maxWidth={maxWidth}>
            {children}
          </Container>
        </Tooltip>
      ) : (
        <Container data-testid={testId} {...props} gray={gray} maxWidth={maxWidth}>
          {children}
        </Container>
      )}
    </>
  );
}

export default FlexibleText;


