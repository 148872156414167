import React, { useState } from 'react';
import { moment, sequence, testId, zeroIfNaN } from '@utils';
import { Input, Select, TimePicker, Textarea, SearchSelect, DatePicker, Switch } from '@components';
import { diffFields } from '@utils';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import { Container, Row, Switcher } from './styled';
import { useTranslation } from '@hooks';
import { Checkbox } from 'antd';

const actionOptions = [
  {
    value: 0,
    label: 'workflow_page.wait_day',
  },
  {
    value: 1,
    label: 'workflow_page.wait_duration'
  }
];

const daysOptions = [
  {
    value: 1,
    label: 'labels.monday',
  },
  {
    value: 2,
    label: 'labels.tuesday',
  },
  {
    value: 3,
    label: 'labels.wednesday',
  },
  {
    value: 4,
    label: 'labels.thursday',
  },
  {
    value: 5,
    label: 'labels.friday',
  },
  {
    value: 6,
    label: 'labels.saturday',
  },
  {
    value: 0,
    label: 'labels.sunday',
  },
];

const durationUnitOptions = [
  {
    value: 'minutes',
    label: 'labels.minutes',
  },
  {
    value: 'hours',
    label: 'labels.hours',
  },
  {
    value: 'days',
    label: 'labels.days',
  },
];

const WaitNodeSettings = ({
  value,
  label,
  description,
  onChange,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  editable = true,
  compareWithValue,
}) => {
  const { t, p } = useTranslation('workflow_page');
  const [showSendAt, setShowSendAt] = useState(value.type === 1 && !!value.send_at);

  const handleActionChange = (type) => {
    onClearError('type');
    onChange(s => ({ ...s, type }));

    if (type === 1) {
      onChange(s => ({ ...s, send_at: null }));
      setShowSendAt(false);
    }

    if (type === 0 && !value.send_at) {
      onChange(s => ({ ...s, send_at: moment().set('hours', 0).set('minutes', 0).set('seconds', 0) }));
    }
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleDaysChange = ({ target: { value: days } }) => {
    onClearError('days');
    onClearError('duration');
    onChange(s => ({ ...s, days, duration: +(days * 60 * 24 + zeroIfNaN(s.hours) * 60 + zeroIfNaN(s.minutes)) }));
  };

  const handleMinutesChange = ({ target: { value: minutes } }) => {
    onClearError('minutes');
    onClearError('duration');
    onChange(s => ({ ...s, minutes, duration: +(zeroIfNaN(s.days) * 60 * 24 + zeroIfNaN(s.hours) * 60 + +minutes) }));
  };

  const handleHoursChange = ({ target: { value: hours } }) => {
    onClearError('hours');
    onClearError('duration');
    onChange(s => ({ ...s, hours, duration: +(zeroIfNaN(s.days) * 60 * 24 + +(hours * 60) + zeroIfNaN(s.minutes)) }));
  };

  const handleDayChange = (day) => {
    onClearError('day');
    onChange(s => ({ ...s, day }));
  };

  const handleTimeChange = (send_at) => {
    onClearError('send_at');

    if (!send_at) {
      return onChange(s => ({ ...s, send_at: moment().set('hours', 0).set('minutes', 0).set('seconds', 0) }));
    }
    onChange(s => ({ ...s, send_at }));
  };

  const handleChangeShowSendAt = (checked) => {
    if (!checked) {
      onChange(s => ({ ...s, send_at: null }));
    }
    setShowSendAt(checked);
  }

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <SearchSelect
              {...testId('wait-node-settings-select-action')()}
              highlight={diff['type'] === false}
              error={errors['type']}
              disabled={!editable}
              style={{ width: '100%' }}
              onChange={handleActionChange}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              value={value.type + ''}
              options={actionOptions}
              label={t('labels.action')}
            />
            {value.type === 0 && (
              <SearchSelect
                {...testId('wait-node-settings-select-day')()}
                label={t('labels.day')}
                highlight={diff['day'] === false}
                error={errors['day']}
                disabled={!editable}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                style={{ width: '100%' }}
                wrapperStyles={{ marginTop: '16px' }}
                value={value.day + ''}
                onChange={handleDayChange}
                options={daysOptions}
              />
            )}
            {value.type === 1 && (
              <>
                <Row style={{ alignItems: 'flex-end', marginTop: '16px' }}>
                  <Input
                    {...testId('wait-node-settings-select-time-days')()}
                    highlight={diff['duration'] === false}
                    error={errors['duration']}
                    disabled={!editable}
                    type="number"
                    min={0}
                    title={t('labels.days')}
                    wrapperStyles={{ width: '100%', marginRight: 12 }}
                    containerStyle={{ width: '100%', marginRight: 12 }}
                    absoluteError
                    onChange={handleDaysChange}
                    value={value.days}
                  />
                  <Input
                    {...testId('wait-node-settings-select-time-hours')()}
                    highlight={diff['duration'] === false}
                    error={errors['duration'] ? ' ' : ''}
                    containerStyle={{ width: '100%' }}
                    wrapperStyles={{ width: '100%' }}
                    disabled={!editable}
                    type="number"
                    min={0}
                    title={t('labels.hours')}
                    absoluteError
                    onChange={handleHoursChange}
                    value={value.hours}
                  />
                </Row>
                <Row>
                  <Input
                    {...testId('wait-node-settings-select-time-minutes')()}
                    highlight={diff['duration'] === false}
                    disabled={!editable}
                    type="number"
                    error={errors['duration'] ? ' ' : ''}
                    min={0}
                    wrapperStyles={{ width: 'calc(50% - 8px)' }}
                    title={t('labels.minutes')}
                    absoluteError
                    onChange={handleMinutesChange}
                    value={value.minutes}
                  />
                </Row>
              </>
            )}
            {(value.type === 0 || value.type === 1) && (
              <div>
                {value.type === 1 && <Switcher><Switch checked={showSendAt} onChange={handleChangeShowSendAt} /></Switcher>}
                <TimePicker
                  {...testId('wait-node-settings-select-time-picker')()}
                  highlight={diff['send_at'] === false}
                  disabled={!editable || (value.type === 1 && !showSendAt)}
                  error={errors['send_at']}
                  style={{ marginTop: '16px' }}
                  value={typeof value.send_at === 'string' ? moment(value.send_at) : value.send_at}
                  onChange={handleTimeChange}
                  onSelect={handleTimeChange}
                  title={t('labels.send_at')}
                />
              </div>
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('wait-node-settings-description-settings-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('wait-node-settings-description-settings-description')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default WaitNodeSettings;
