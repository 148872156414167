import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 21px;
  line-height: 14px;
  
  & > span {
    cursor: pointer;
  }
  
  & > div {
    cursor: pointer;
    line-height: 14px;
    padding: 0 10px;
    color: #109DFB;
  }

  font-family: Rubik-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 4px 0;
`;

export const PerPageOption = styled.div`
  padding: 7px 14px;
  cursor: pointer;

  ${styledProp('selected', `
    color: #109DFB;
  `, `
    color: #303133;
  `)}
  &:hover {
    background: rgba(220, 223, 230, 0.37);
  }
`;
