import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  background: #E5E8ED;
  border-radius: 7px;
  display: flex;
  padding: 4px;
  height: 36px;

  @media screen and (max-width: 1024px) {
    height: 26px;
    padding: 2px;
  }

  ${styledProp('absolute', `
    position: absolute;
    width: auto;
    right: 0;
    top: -50px;
  `)}
`;

export const Tab = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  user-select: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  cursor: pointer;
  transition: 280ms ease all;
  padding: 7px 20px;

  @media screen and (max-width: 1024px) {
    padding: 4px 6px;
  }

  ${styledProp('selected', `
    background: #fff;
    color: #109DFB;
  `, `
    background: #E5E8ED;
    box-shadow: none;
    color: #909399;
  `)}
`;
