import { Paths, UserPermissions } from '@constants';
import { hashAppId } from '@utils';
export const composeHintAsk = (onboarding, hintMode, location, permissions, p, userId) => {
  if (!onboarding.contactsImport.step && hintMode && !onboarding.contactsImport.skipped && !onboarding.contactsImport.passed && location.pathname.indexOf(`/app/${hashAppId(userId)}${Paths.CONTACTS_IMPORT}`) !== -1) {
    return { hintKey: 'contactsImport', hintText: p('need_a_help_with_customers_import'), hintStep: 1 }
  }

  if (!onboarding.contacts.step && hintMode && !onboarding.contacts.skipped && !onboarding.contacts.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.CONTACTS_ALL}`) {
    return { hintKey: 'contacts', hintText: p('need_help_with_customers'), hintStep: 1 }
  }

  if (!onboarding.integrations.step && hintMode && !onboarding.integrations.skipped && !onboarding.integrations.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.INTEGRATIONS}`) {
    return { hintKey: 'integrations', hintText: p('need_help_with_integrations'), hintStep: (permissions.allowed(UserPermissions.JS_INTEGRATION) && permissions.allowedProduct(UserPermissions.JS_INTEGRATION)) ? 1 : 4 }
  }

  if (!onboarding.segments.step && hintMode && !onboarding.segments.skipped && !onboarding.segments.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.RESOURCES_SEGMENTS}`) {
    return { hintKey: 'segments', hintText: p('do_you_need_help_with_segments'), hintStep: 1 }
  }

  if (!onboarding.templates.step && hintMode && !onboarding.templates.skipped && !onboarding.templates.passed && location.pathname.indexOf(`/app/${hashAppId(userId)}${Paths.TEMPLATES_EMAIL}`) !== -1) {
    return { hintKey: 'templates', hintText: p('do_you_need_help_with_email_templates'), hintStep: 1 }
  }

  if (!onboarding.templatesSms.step && hintMode && (permissions.allowed(UserPermissions.CREATE_SMS) && permissions.allowedProduct(UserPermissions.CREATE_SMS)) && !onboarding.templatesSms.skipped && !onboarding.templatesSms.passed && location.pathname.indexOf(`/app/${hashAppId(userId)}${Paths.TEMPLATES_SMS}`) !== -1) {
    return { hintKey: 'templatesSms', hintText: p('do_you_need_help_with_sms_templates'), hintStep: 1 }
  }

  if (!onboarding.templatesWebpush.step && hintMode && (permissions.allowed(UserPermissions.CREATE_WEBPUSHES) && permissions.allowedProduct(UserPermissions.CREATE_WEBPUSHES)) && !onboarding.templatesWebpush.skipped && !onboarding.templatesWebpush.passed && location.pathname.indexOf(`/app/${hashAppId(userId)}${Paths.TEMPLATES_WEBPUSH}`) !== -1) {
    return { hintKey: 'templatesWebpush', hintText: p('do_you_need_help_with_webpush_templates'), hintStep: 1 }
  }

  if (!onboarding.workflows.step && hintMode && !onboarding.workflows.skipped && !onboarding.workflows.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.WORKFLOWS}`) {
    return { hintKey: 'workflows', hintText: p('do_you_need_help_with_workflow'), hintStep: 1 }
  }

  if (!onboarding.workspaces.step && hintMode && (permissions.allowed(UserPermissions.CREATE_WORKSPACE) && permissions.allowedProduct(UserPermissions.CREATE_WORKSPACE)) && !onboarding.workspaces.skipped && !onboarding.workspaces.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.WORKSPACES}`) {
    return { hintKey: 'workspaces', hintText: p('do_you_need_help_with_workspaces'), hintStep: 1 }
  }

  if (!onboarding.users.step && hintMode && !onboarding.users.skipped && !onboarding.users.passed && location.pathname === `/app/${hashAppId(userId)}${Paths.USERS_SETTINGS}`) {
    return { hintKey: 'users', hintText: p('do_you_need_help_with_users'), hintStep: 1 }
  }

  return { hintKey: null, hintText: '', hintStep: null }
}

export const checkIsAllOnboardsFinished = (onboarding) => {
  const { contacts, contactsImport, integrations, segments, templates, templatesSms, templatesWebpush, workflows, workspaces, users } = onboarding;

  return ((contacts.skipped || contacts.passed)
    && (contactsImport.skipped || contactsImport.passed)
    && (integrations.skipped || integrations.passed)
    && (segments.skipped || segments.passed)
    && (templates.skipped || templates.passed)
    && (templatesSms.skipped || templatesSms.passed)
    && (templatesWebpush.skipped || templatesWebpush.passed)
    && (workflows.skipped || workflows.passed)
    && (workspaces.skipped || workspaces.passed)
    && (users.skipped || users.passed));
}
