import React, { useContext } from 'react';

import { Container, FooterOption, FooterOptionLabel } from './styled';
import { SearchSelect, Input, ActionButton, Textarea, IconButton } from '@components';
import {Row} from "../SendEmailNodeSettings/styled";
import {NodeSettingsAccordion} from "../../../NodeSettingsAccordion";
import { by, diffFields, removeAppFromPath, testId, update } from '@utils';
import { useLocation } from 'react-router';
import { AppRedirects, Paths } from '@constants';
import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { useDispatch } from 'react-redux';
import { WorkflowEditorContext } from '../../../../../../../contexts';
import { WorkflowActionTypes } from '@store/actions/types';
import { useTranslation, useNavigate } from '@hooks';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

const ViberNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  onEditTemplate,
  editable = true,
  compareWithValue,
}) => {
  const templateOptions = options?.viber?.filter(t => t.status !== 3)?.map?.(({ id, name }) => ({ value: id, label: name }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t, p } = useTranslation('workflow_page');
  const viber = options?.viber?.find?.(by(value.viber_id));

  const handleTemplateChange = (viber_id) => {
    onClearError('viber_id');
    onChange(s => ({ ...s, viber_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.VIBER_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'viber_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_VIBER}/new`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <Row style={{ alignItems: 'flex-end' }}>
              <SearchSelect
                {...testId('viber-node-settings-required-template')()}
                highlight={diff['viber_id'] === false}
                disabled={!editable}
                error={errors['viber_id']}
                containerStyle={{ width: '100%' }}
                style={{ width: '100%' }}
                title={t('labels.template')}
                value={value.viber_id}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                placeholder={t('actions.select')}
                onChange={handleTemplateChange}
                options={templateOptions || []}
                footerOption={(
                  <FooterOption onClick={handleCreateNew}>
                    <ActionButton {...testId('viber-node-settings-required-template-add')()} size={22} icon="Plus-icon" />
                    <FooterOptionLabel>
                      {p('create_template')}
                    </FooterOptionLabel>
                  </FooterOption>
                )}
              />
              <IconButton
                {...testId('viber-node-settings-required-template-edit')()}
                icon="Edit-icon"
                style={{ marginLeft: 6 }}
                onClick={onEditTemplate}
                disabled={!value.viber_id}
              />
            </Row>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={viber?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('viber-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              maxLength={40}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('viber-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              style={{ height: '150px' }}
              wrapperStyles={{ height: '150px' }}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default ViberNodeSettings;
