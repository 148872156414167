import styled from 'styled-components';
import { switchStyle } from '@utils';

export const Container = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;

  ${switchStyle('status', {
    0: `
      color: #909399;
    `,
    1: `
      color: #303133;
    `,
    2: `
      color: #303133;
      border-bottom: 1px dashed #109DFB;
    `,
  })}

`;
