import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 2px;
  //background-color: #DCDFE6;
  margin-bottom: 27px;
  overflow: visible;
`;

export const Step = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 3px solid #24B277;
  //background-color: #FAFAFA;
  position: relative;
  top: -10px;
  
  ${styledProp('exist', css`
    background-color: #24B277;
  `)};
  
  ${styledProp('later', css`
    //background-color: transparent !important;
    background: linear-gradient(213.29deg, #F4A53C 11.15%, #ED992A 90.5%) !important;
    border: 3px solid #F4A53C;
  `)}
`;

export const StepContainer = styled.div`
  flex: 1;
  display: flex;
  //justify-content: center;
  height: 2px;
  //background-color: #24B277;
`;

export const StepIcon = styled.img`
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  ${styledProp('later', css`
    border: 2px solid white;
    border-radius: 50%;
  `)}
`;

export const RightSide = styled.div`
  height: 2px;
  width: 50%;
  background-color: #DCDFE6;
  
  ${styledProp('green', css`
    background-color: #24B277;
  `)}
`;

export const LeftSide = styled.div`
  height: 2px;
  width: 50%;
  background-color: #DCDFE6;

  ${styledProp('green', css`
    background-color: #24B277;
  `)}
`;




