import styled from 'styled-components';
import { extract } from '@utils';
import { Text } from '@components';
import { TextVariants } from '@constants';

export const Title = styled(Text).attrs({ variant: TextVariants.HEADING_1, width: 300 })`
  display: flex;
  font-family: Rubik-Regular;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
`;

export const EntityLink = styled.div`
  font-family: Rubik-Regular;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  color: #109DFB;
  max-width: 360px;
  //width: 360px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const EntitiesLink = styled.div`
  font-family: Rubik-Regular;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
  border-bottom: 1px dashed #109DFB;
  //max-width: 360px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const Overlay = styled.div`
  position: relative;
  margin-top: 10px;
  min-width: 120px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  padding: 16px 14px;
  padding-bottom: 2px;
`;

export const Triangle = styled.div`
  position: absolute;
  top: -10px;
  display: flex;
  flex: 0;
  right: 50%;
  transform: translateX(50%);
`;

export const SegmentTitle = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
`;

export const LegendCircle = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background: ${extract('$color')};
`;

export const SegmentRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
