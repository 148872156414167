import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as DropdownAntd } from 'antd';

import { Icon } from '@components';

import { Overlay, Option, Label, Wrapper } from './styled';

const SmallSelect = ({ value, options, onChange }) => {
  const [opened, setOpened] = useState(false);

  const handleChange = (item) => {
    onChange(item);
    setOpened(false);
  };

  return (
    <DropdownAntd
      trigger={['click']}
      onVisibleChange={setOpened}
      placement="bottomCenter"
      overlay={
        <Overlay>
          {options.map((item) => (
            <Option key={item.value} onClick={() => handleChange(item)}>
              {item.label}
            </Option>
          ))}
        </Overlay>
      }
    >
      <Wrapper>
        <Label>{options.find((item) => item.value === value)?.label || 'Select'}</Label>
        <Icon
          name="Dropdown-arrow"
          size={8}
          style={{ transform: `rotate(${opened ? '180deg' : '0deg'})`, transition: '0.2s' }}
        />
      </Wrapper>
    </DropdownAntd>
  );
};

SmallSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

SmallSelect.defaultProps = {
  value: null,
  options: [],
};

export default SmallSelect;
