import React, { useEffect, useRef, useState } from 'react';

import {
  CommonColumn,
  Container,
  Content,
  DynamicColumn,
  Header,
  Item,
  Tab,
  TabIndicator,
  Tabs,
  Title,
  TabContainer,
} from './styled.js';
import { Icon } from '@components';
import { COMMON_OPTIONS, SELECT_GROUPS, OPERATOR_EXISTS, OPERATOR_NOT_EXISTS } from '@constants';
import { useTranslation } from '@hooks';
import { testId } from '@utils';

const getTabs = (p) => [
  {
    names: ['numeric'],
    title: p('numeric'),
    iconName: 'Numeric-operator',
  },
  {
    names: ['text'],
    title: p('text'),
    iconName: 'Text-operator',
  },
  {
    names: ['boolean'],
    title: p('boolean'),
    iconName: 'Boolean-operator',
  },
  {
    names: ['datetime', 'date'],
    title: p('date'),
    iconName: 'Date-operator',
  },
];

const OperatorMenu = ({ onChange, type, tab, setTab, opened, isAggregate }) => {
  const { p } = useTranslation('segments_page');
  const tabs = getTabs(p);

  const refs = useRef(Object.fromEntries(tabs.map(({ names: [name] }) => [name, null])));

  useEffect(() => {
    if (!~(tabs.find(({ names }) => names.indexOf(tab)).names || []).indexOf(type) && type) {
      setTab(type);
    }
  }, [type]);

  const handleChange = (operator, type) => {
    onChange(operator, type);
  }
  const [updater, setUpdater] = useState(false);

  useEffect(() => {
    setTimeout(() => setUpdater(u => !u), 300);
  }, [opened]);

  const CommonOptions = isAggregate ? COMMON_OPTIONS.map(o => ({ ...o, label: p(o.value) })).filter(o => o.value !== OPERATOR_NOT_EXISTS && o.value !== OPERATOR_EXISTS) : COMMON_OPTIONS;

  const tid = testId('segment-filter-operator');
  const [tabEl, setTabEl] = useState(null);

  useEffect(() => {
    if (!refs.current[tabs.find(({ names }) => !!~names.indexOf(tab))?.names?.[0]]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tabs.find(({ names }) => !!~names.indexOf(tab)).names[0]]);
  }, [updater, tab, refs.current[tabs.find(({ names }) => !!~names.indexOf(tab))?.names?.[0]], opened]);

  return (
    <Container>
      <Header>
        <Tabs>
          {tabs.map(({ names, title, iconName }, idx) => (
            <TabContainer key={idx} onClick={() => setTab(names[0])}>
              <Icon name={iconName} size={16} color={!!~names.indexOf(tab) ? '#109DFB' : '#303133'} />
              <Tab
                {...tid(`tab-${title}`)}
                id={`om-tab-${names[0]}`}
                ref={rr => refs.current[names[0]] = rr}
                selected={!!~names.indexOf(tab)}
              >
                {title}
              </Tab>
            </TabContainer>
          ))}
          <TabIndicator key={String(updater)} $tab={tabEl} />
        </Tabs>
      </Header>
      <Content>
        <DynamicColumn {...tid(`operators`)}>
          {SELECT_GROUPS(p).map(({ name, types, title, hide, options }) => (!!~types.indexOf(tab) && !hide) ? (
            <div key={name}>
              <Title>{title} {p('operators')}</Title>
              {options.map(({ value, label }) => (<Item key={label} onClick={() => handleChange(value, name)}>{label}</Item>))}
            </div>
          ) : null)}
        </DynamicColumn>
        <CommonColumn {...tid(`common-operators`)}>
          <>
            <Title>{p('common_operators')}</Title>
            {CommonOptions.map(({ name, value, label }) => <Item key={name} onClick={() => handleChange(value, name)}>{p(value)}</Item>)}
          </>
        </CommonColumn>
      </Content>
    </Container>
  );
}

export default OperatorMenu;


