import React from 'react';

import { Container } from './styled.js';
import { by } from '@utils';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';
import { useSelector } from 'react-redux';
import { smsNodeAnalyticsSelector } from '@store/selectors';
import { useTranslation } from '@hooks';

const SendSMSNodeSettingsInfo = ({ node, options }) => {
  const sms = options?.sms?.find?.(by(node.data?.sms_id));
  const smsNodeAnalytics = useSelector(smsNodeAnalyticsSelector);
  const { t } = useTranslation();

  if (!sms) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.template')}:
        </InfoTitle>
        <InfoText>
          {sms.name}
        </InfoText>
      </InfoRow>
      {(node.data.sending && !smsNodeAnalytics.loading) && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default SendSMSNodeSettingsInfo;


