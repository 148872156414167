import React from 'react';
import { Icon } from '@components';

import {
  Container,
  StepContainer,
  TitleRow,
  StepIndicator,
  Title,
  Content,
  Dummy,
  CurrentIndicator,
  NextIndicator,
} from './styled';

const resolveStepStatus = (position, step) => {
  if (position === step) {
    return 'current';
  }
  if (position > step) {
    return 'next';
  }
  if (position < step) {
    return 'passed';
  }
};

const VerticalStepper = ({ steps, step, onStepChange }) => {

  const handleStepChange = () => {

  };

  return (
    <Container>
      {steps.map(({ position, title, content }) => {
        const status = resolveStepStatus(position, step);

        return (
          <StepContainer $status={status}>
            <TitleRow $status={status} onClick={status === 'passed' ? () => onStepChange(position) : undefined}>
              <StepIndicator $status={status}>
                {status === 'passed' && <Icon name="Edit-icon" size={12} color="#909399" />}
                {status === 'current' && <CurrentIndicator $status={status}/>}
                {status === 'next' && <NextIndicator>{position + 1}</NextIndicator>}
              </StepIndicator>
              <Title>{title}</Title>
            </TitleRow>
            <Content $status={status} $last={position === steps.length - 1}>
              <Dummy />
              {content}
              <Dummy style={{ height: 22 }} />
            </Content>
          </StepContainer>
        );
      })}
    </Container>
  );
};

export default VerticalStepper;
