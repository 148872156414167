import { DashboardActionTypes, UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const initialState = {
  smsSummary: {
    loading: false,
  },
  emailSummary: {
    loading: false,
  },
  viberSummary: {
    loading: false,
  },
  webpushSummary: {
    loading: false,
  },
  mobilePushSummary: {
    loading: false,
  },
  generalActivityDaily: {
    loading: false,
  },
  generalActivityHourly: {
    loading: false,
  },
  databaseEfficiency: {
    loading: false,
  },
  analyticsEntities: {
    loading: false,
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.GET_EMAIL_SUMMARY:
      return {
        ...state,
        emailSummary: {
          ...state.emailSummary,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_EMAIL_SUMMARY_SUCCESS:
      return {
        ...state,
        emailSummary: {
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_EMAIL_SUMMARY_ERROR:
      return {
        ...state,
        emailSummary: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_VIBER_SUMMARY:
      return {
        ...state,
        viberSummary: {
          ...state.emailSummary,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_VIBER_SUMMARY_SUCCESS:
      return {
        ...state,
        viberSummary: {
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_VIBER_SUMMARY_ERROR:
      return {
        ...state,
        viberSummary: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_SMS_SUMMARY:
      return {
        ...state,
        smsSummary: {
          ...state.smsSummary,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_SMS_SUMMARY_SUCCESS:
      return {
        ...state,
        smsSummary: {
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_SMS_SUMMARY_ERROR:
      return {
        ...state,
        smsSummary: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_MOBILE_PUSH_SUMMARY:
      return {
        ...state,
        mobilePushSummary: {
          ...state.mobilePushSummary,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_MOBILE_PUSH_SUMMARY_SUCCESS:
      return {
        ...state,
        mobilePushSummary: {
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_MOBILE_PUSH_SUMMARY_ERROR:
      return {
        ...state,
        mobilePushSummary: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_WEBPUSH_SUMMARY:
      return {
        ...state,
        webpushSummary: {
          ...state.webpushSummary,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_WEBPUSH_SUMMARY_SUCCESS:
      return {
        ...state,
        webpushSummary: {
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_WEBPUSH_SUMMARY_ERROR:
      return {
        ...state,
        webpushSummary: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_DAILY:
      return {
        ...state,
        generalActivityDaily: {
          ...state.generalActivityDaily,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_DAILY_SUCCESS:
      return {
        ...state,
        generalActivityDaily: {
          data: action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_DAILY_ERROR:
      return {
        ...state,
        generalActivityDaily: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_HOURLY:
      return {
        ...state,
        generalActivityHourly: {
          ...state.generalActivityHourly,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_HOURLY_SUCCESS:
      return {
        ...state,
        generalActivityHourly: {
          data: action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_GENERAL_ACTIVITY_HOURLY_ERROR:
      return {
        ...state,
        generalActivityHourly: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_DATABASE_EFFICIENCY:
    case DashboardActionTypes.GET_DATABASE_EFFICIENCY2:
      return {
        ...state,
        databaseEfficiency: {
          ...state.databaseEfficiency,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_DATABASE_EFFICIENCY_SUCCESS:
      return {
        ...state,
        databaseEfficiency: {
          ...state.databaseEfficiency,
          ...action.payload,
          loading: false,
        },
      };
    case DashboardActionTypes.GET_DATABASE_EFFICIENCY2_SUCCESS:
      return {
        ...state,
        databaseEfficiency: {
          ...state.databaseEfficiency,
          ...action.payload,
        },
      };
    case DashboardActionTypes.GET_DATABASE_EFFICIENCY_ERROR:
      return {
        ...state,
        databaseEfficiency: {
          loading: false,
        },
      };
    case DashboardActionTypes.GET_ANALYTICS_ENTITIES:
      return {
        ...state,
        analyticsEntities: {
          ...state.analyticsEntities,
          loading: true,
        },
      };
    case DashboardActionTypes.GET_ANALYTICS_ENTITIES_SUCCESS:
      return {
        ...state,
        analyticsEntities: {
          ...state.analyticsEntities,
          loading: false,
          ...action.payload,
        },
      };
    case DashboardActionTypes.GET_ANALYTICS_ENTITIES_ERROR:
      return {
        ...state,
        analyticsEntities: {
          ...state.analyticsEntities,
          loading: false,
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
}

export default dashboardReducer;
