import { all, takeLatest, put } from 'redux-saga/effects';
import { RolesActionTypes } from '../../actions/types';

import { Endpoints } from "@constants";
import { createRequestAction } from "@store/lib/utils/creators";

function* deleteRoleSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.ROLES}/${action.meta.id}`,
    })
  );
}

function* getPermissionsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.PERMISSIONS,
    })
  );
}

function* getRolesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.ROLES,
    })
  );
}

function* getRoleSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `${Endpoints.ROLE}/${action.meta.id}`,
    })
  );
}

function* updateRoleSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.ROLES,
      body: {
        ...action.payload,
      },
    })
  );
}

function* getRolesListSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.ROLES_LIST,
    })
  );
}

export default function* rolesRootSaga() {
  yield all([
    takeLatest(RolesActionTypes.GET_ROLES, getRolesSaga),
    takeLatest(RolesActionTypes.GET_ROLE, getRoleSaga),
    takeLatest(RolesActionTypes.GET_PERMISSIONS, getPermissionsSaga),
    takeLatest(RolesActionTypes.DELETE_ROLE, deleteRoleSaga),
    takeLatest(RolesActionTypes.UPDATE_ROLE, updateRoleSaga),
    takeLatest(RolesActionTypes.GET_ROLES_LIST, getRolesListSaga),
  ]);
}
