import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const AnimatedContainer = styled.div`
  opacity: 0;
  padding: 0 30px;
  transform: scale(0.9);
  animation: ${fadeIn} ease-in 300ms forwards;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  //flex-direction: column;
`;
