import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
  //padding: 5px 18px;
  border-radius: 8px;
  margin-bottom: 3px;
  justify-content: space-between;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  margin: 5px 0;
  border-radius: 10px;
`;
