import styled from 'styled-components';
import * as Colors from '@res/theme/colors';
import { Device } from '@res';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 90.83%);;
`;

export const InnerContainer = styled.div`
  background: ${Colors.Common.APP_BG};
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  width: 400px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  align-items: center;
  
  & > h2 {
    font-family: Rubik-Regular;
    font-size: 20px;
    line-height: 23.7px;
    margin-bottom: 30px;
  }

  & > h4 {
    font-family: Rubik-Regular;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 30px;
    width: 258px;
  }

  & > h5 {
    font-family: Rubik-Regular;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #303133;
    margin-bottom: 60px;
    width: 258px;
  }
`;

export const Image = styled.img`
  width: 171px;
  height: 38px;
  margin-bottom: 40px;
  margin-top: 50px;

  @media screen and ${Device.laptopL} {
    width: 207px;
    height: 46px;
    margin-bottom: 70px;
    margin-top: 80px;
  }

  @media screen and ${Device.desktopS} {
    width: 270px;
    height: 60px;
    margin-bottom: 111px;
    margin-top: 120px;
  }

  @media screen and ${Device.desktop} {
    width: 270px;
    height: 60px;
    margin-bottom: 111px;
    margin-top: 120px;
  }
`;

export const Caption = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 15.6px;
  display: flex;
  margin-top: auto;
  
  & > span {
    color: #109DFB;
  }
`;
