import React, { useCallback, useRef } from 'react';
import { NotificationItem } from '@components/lib/NotificationsList/components/NotificationItem';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, markAsRead } from '@store/actions/creators';
import { sidebarOpenedSelector } from '@store/selectors';
import { useTransition, config } from '@react-spring/web';
import { useOutsideClickListener, useTranslation } from '@hooks';

import { CollapseButton, Container, Title, Divider, Header, ReadAll, Hint, Scroll, Overlay } from './styled.js';
import { NotificationsGroup } from '@components/lib/NotificationsList/components/NotificationsGroup';
import { testId } from '@utils';

const tid = testId('sidebar-notifications');

const NotificationsList = ({ notifications, opened, close }) => {
  const dispatch = useDispatch();
  const sidebarOpened = useSelector(sidebarOpenedSelector);
  const ref = useRef(null);
  const transition = useTransition(opened, {
    from: { opacity: 0, scale: 0.8 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0.8 },
    config: config.stiff,
  });
  const { t, p } = useTranslation('dashboard_page');

  useOutsideClickListener(ref, (e) => {
    e.stopPropagation();
    close();
  });

  const handleReadAll = () => {
    const unread = notifications.filter(n => (n.read_at === null || n.read_at === undefined)).map(n => n.id);
    if (!unread || !unread.length) return;

    dispatch(markAsRead({
      ids: unread,
      onSuccess: () => dispatch(getNotifications()),
    }));
  }

  const handleReadNotification = useCallback((notification) => {
    if (notification.read_at !== null && notification.read_at !== undefined) return;

    dispatch(markAsRead({
      ids: [notification.id],
      onSuccess: () => dispatch(getNotifications()),
    }));
  }, [dispatch, markAsRead]);

  const sortedTest = notifications.sort((a, b) => +moment(b.created_at) - +moment(a.created_at)).map(el => ({ ...el, date: +moment(el.created_at)  }));

  const getDateGroups = () => {
    const groups = {};

    for (const item of sortedTest) {
      const groupCategory = moment(item.date).calendar().split(' at').shift();

      if (!groups[groupCategory]) {
        groups[groupCategory] = [item];
      } else {
        groups[groupCategory].push(item);
      }
    }

    return groups;
  }

  const groups = getDateGroups();

  return transition((style, opened) => opened && (
    <>
      <Container
        ref={ref}
        style={{
          ...style,
          transform: style.opacity.to({  range: [1.0, 0.0], output: [0, -50] }).to(x => `translate3d(${x}px,0,0)` )
        }}
        $opened={opened}
        sidebarOpened={sidebarOpened}
      >
        <Header>
          <Title>{p('notifications')}</Title>
          <CollapseButton onClick={close} {...tid('close')}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.20697 9.20671C8.9746 9.44074 8.59625 9.44126 8.36323 9.20787L4.99997 5.83932L1.63654 9.20804C1.40337 9.44158 1.02499 9.44172 0.791637 9.20837C0.558285 8.97502 0.558434 8.59664 0.791969 8.36347L4.16068 5.00004L0.79197 1.63661C0.558434 1.40344 0.558285 1.02506 0.791637 0.791706C1.02499 0.558354 1.40337 0.558503 1.63654 0.792038L4.99997 4.16075L8.36604 0.794683C8.5978 0.56292 8.97357 0.56292 9.20533 0.794683C9.43709 1.02644 9.43709 1.40221 9.20533 1.63397L5.83926 5.00004L9.20549 8.36627C9.4374 8.59819 9.43807 8.97398 9.20697 9.20671Z" fill="#909399"/>
            </svg>
          </CollapseButton>
        </Header>
        <Divider />
          <ReadAll onClick={handleReadAll} {...tid('read-all')} >
            {p('read_all')}
          </ReadAll>
          <Scroll>
            {Object.entries(groups).map(([name, group], index) => (
              <NotificationsGroup
                opened={opened}
                key={index}
                onClose={close}
                name={name}
                group={group}
                onRead={handleReadNotification}
              />
            ))}
            <Hint>{p('no_older_notifications')}</Hint>
          </Scroll>
      </Container>
      <Overlay />
    </>
  ));
}

export default NotificationsList;


