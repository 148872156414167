import React from 'react';
import { Menu } from 'antd';
import { useTransition, config } from '@react-spring/web';
import { useModalState } from '@hooks';
import { MoreButton } from '@components';

import { Container, ActionButtons, InnerContainer } from './styled';

const TableActionsBar = ({ testId, actionButtons, mw = 80, hovered, moreTestId = '', moreActions }) => {
  const state = useModalState();

  const transition = useTransition(state.opened || hovered, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    config: config.stiff,
  });

  return (
    <Container $mw={mw} data-testid={testId} onMouseEnter={state.open} onMouseLeave={state.close}>
      {transition(({ opacity, scale }, shown) => shown && (
        <InnerContainer style={{ opacity }}>
          <ActionButtons style={{ transform: scale.to({  range: [1.0, 0.0], output: [0, 20] }).to(x => `translate3d(${x}px,0,0)` ) }}>
            {actionButtons}
          </ActionButtons>
          {moreActions && (
            <MoreButton
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginRight: 4
              }}
              hover
              testId={'more-btn'}
              iconSize={22}
              iconStyles={{ height: '22px' }}
              menu={(
                <Menu style={{ borderRadius: '10px', overflow: 'hidden' }}>
                  {moreActions}
                </Menu>
              )}>
            </MoreButton>
          )}
        </InnerContainer>
      ))}
      <MoreButton
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
        hover
        testId={'more-btn'}
        iconSize={22}
        iconStyles={{ height: '22px' }}
        menu={null}>
      </MoreButton>
    </Container>
  );
};

export default TableActionsBar;
