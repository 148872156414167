import React, { useContext } from 'react';

import { Container } from './styled';
import { SearchSelect, Input, ActionButton, Textarea, IconButton } from '@components';
import {NodeSettingsAccordion} from "../../../NodeSettingsAccordion";
import { by, diffFields, removeAppFromPath, testId, update } from '@utils';
import {
  FooterOption,
  FooterOptionLabel
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/WebpushNodeSettings/styled';
import { AppRedirects, Paths } from '@constants';
import { useLocation } from 'react-router';
import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { useDispatch } from 'react-redux';
import { WorkflowEditorContext } from '../../../../../../../contexts';
import { WorkflowActionTypes } from '@store/actions/types';
import { useTranslation, useNavigate } from '@hooks';
import { DynamicVariablesSettings } from '@components/lib/WorkflowEditor/components';

const ApiRequestNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  onEditTemplate,
  editable = true,
  compareWithValue,
}) => {
  const templateOptions = options?.webhook?.filter(t => t.status !== 3)?.map?.(({ id, name }) => ({ value: id, label: name }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t, p } = useTranslation('workflow_page');
  const api_request = options?.webhook?.find?.(by(value.api_request_id));

  const handleTemplateChange = (api_request_id) => {
    onClearError('api_request_id');
    onChange(s => ({ ...s, api_request_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.API_REQUEST_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'api_request_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_API_REQUEST}/new`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SearchSelect
              {...testId('api-request-node-settings-required-template')()}
              highlight={diff['api_request_id'] === false}
              disabled={!editable}
              error={errors['api_request_id']}
              containerStyle={{ width: '100%' }}
              style={{ width: '100%' }}
              title={t('labels.template')}
              placeholder={t('actions.select')}
              value={value.api_request_id}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              onChange={handleTemplateChange}
              options={templateOptions || []}
              footerOption={(
                <FooterOption onClick={handleCreateNew}>
                  <ActionButton {...testId('api-request-node-settings-required-template-add')()} size={22} icon="Plus-icon" />
                  <FooterOptionLabel>
                    {p('create_template')}
                  </FooterOptionLabel>
                </FooterOption>
              )}
            />
            <IconButton
              {...testId('api-request-node-settings-required-template-edit')()}
              icon="Edit-icon"
              style={{ marginLeft: 6 }}
              onClick={onEditTemplate}
              disabled={!value.api_request_id}
            />
          </div>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={api_request?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('api-request-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('api-request-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default ApiRequestNodeSettings;
