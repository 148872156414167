import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 14px;
  line-height: 100%;
  color: #109DFB;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #DCDFE6;
`;
