import { SegmentNodeTypes } from '@constants';
import { v4 as uuidv4 } from 'uuid';
import { findRule, isGroup } from '@utils';

export const replaceGroup = (query, groupName, newGroup) => {
  if (query.query.name === groupName) {
    return newGroup;
  }

  if (query.type === SegmentNodeTypes.QUERY_GROUP) {
    return {
      ...query,
      query: {
        ...query.query,
        children: query.query.children.map(child => replaceGroup(child, groupName, newGroup)),
      }
    }
  }

  return query;
};

export const flatGroups = (group, nesting = false) => {
  if (group.query.children.length <= 1 && nesting) {
    return group.query.children.flatMap(g => isGroup(g) ? flatGroups(g, true) : [g]);
  }

  return {
    ...group,
    query: {
      ...group.query,
      children: group.query.children.flatMap((child, i, self) => {
        if (isGroup(child)) {
          if (child.query.children.length <= 1 || child.query.logicalOperator === group.query.logicalOperator || self.length <= 1) {
            return child.query.children.flatMap(g => isGroup(g) ? flatGroups(g, true) : [g]);
          }
        }

        return isGroup(child) ? flatGroups(child, true) : [child];
      })
    }
  };
};

export const moveRule = (query, source, destination) => {
  const sourceGroup = findRule(query, source.parentId);
  const rule = sourceGroup.query.children[source.index];

  const updatedSource = {
    ...sourceGroup,
    query: {
      ...sourceGroup.query,
      name: uuidv4(),
      children: sourceGroup.query.children.filter((_, i) => i !== source.index),
    },
  };

  if (source.parentId === destination.parentId) {
    const updatedSourceWithAdded = {
      ...updatedSource,
      query: {
        ...updatedSource.query,
        name: uuidv4(),
        children: [
          ...updatedSource.query.children.slice(0, destination.index),
          { ...rule },
          ...updatedSource.query.children.slice(destination.index),
        ],
      },
    };

    return replaceGroup(query, source.parentId, updatedSourceWithAdded);
  }

  const updatedQuery = replaceGroup(query, source.parentId, updatedSource);
  const destinationGroup = findRule(updatedQuery, destination.parentId);
  const updatedDestination = {
    ...destinationGroup,
    query: {
      ...destinationGroup.query,
      children: [
        ...destinationGroup.query.children.slice(0, destination.index),
        { ...rule },
        ...destinationGroup.query.children.slice(destination.index),
      ],
      name: uuidv4()
    }
  };

  return replaceGroup(updatedQuery, destination.parentId, updatedDestination);
}
