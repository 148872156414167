import styled, { css } from 'styled-components';
import { Popover } from 'antd';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { extract, styledProp } from '@utils';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  
  ${styledProp('isSettings', `
    background: #1E386A;
    border-radius: 0 8px 8px 0;
  `)}
`;

export const StyledPopover = styled(Popover)`
`;

export const Link = styled(RouterLink)`
  color: #909399;
  text-decoration: none;
  font-size: 12px;

  ${styledProp('active', `
    background: #10254E;
  `)}
  
  ${styledProp('white', `
    color: #fff;
  `)}

  ${styledProp('isFirst', `
    border-top-right-radius: 8px;
  `)}

  ${styledProp('isLast', `
    border-bottom-right-radius: 8px;
  `)}
  
  ${styledProp('isSettings', `
    &:hover {
      background: #10254E;
    }
  `)}

  ${styledProp('larger', `
    color: #fff;
    padding: 10px 12px;
    font-size: 14px;
    min-width: 180px;
  `)}

  ${styledProp('$isBeta', `
    &:after {
      content: "Beta";
      background: #109DFB;
      border-radius: 4px;
      color: #FFFFFF;
      font-family: 'Rubik-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      padding: 2px 4px 1px;
      margin-left: 6px;
    }
  `)}
  
  &:active {
    color: #909399;
    text-decoration: none;
  }
  &:hover {
    color: white;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
  min-width: 180px;
  font-size: 16px;
  line-height: 100%;
  font-family: Rubik-Regular;
  color: #FFFFFF;
  cursor: pointer;
  transition: 280ms ease all;
  
  &:hover {
    color: #109DFB;
  }
  
  ${styledProp('isCurrent', `
    color: #109DFB;
  `)}
`;

export const Divider = styled.div`
  background: transparent;
  height: 1px;
  width: 100%;
  margin: 4.5px 0;
`;

export const Image = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  background: center / contain no-repeat url("${extract('image')}");
  background-size: cover;
  background-color: #fff;
`;

export const DemoActiveCaption = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #909399;
`;

export const ViewDemoButton =styled.div`
  font-family: Rubik-Regular;
  font-size: 14px;
  line-height: 17px;
  color: #909399;
  transition: 280ms ease all;
  cursor: pointer;
  padding: 8px;

  & path {
    fill: #909399;
    transition: 280ms ease all;
  }

  &:hover {
    color: #109DFB;

    & path {
      fill: #109DFB;
    }
  }

  ${styledProp('$active', css`
    color: #109DFB;
    
    & path {
      fill: #109DFB;
    }
  `)}
`;

