import ejs from 'ejs';

function request(method, url, data, callback) {
  const req = new XMLHttpRequest();
  req.onreadystatechange = function () {
    if (req.readyState === 4 && req.status === 200) {
      callback(req.responseText);
    } else if (req.readyState === 4 && req.status !== 200) {
      console.error(
        "Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values"
      );
    }
  };
  req.open(method, url, true);
  if (method !== "GET") {
    req.setRequestHeader("content-type", "application/json");
  }
  req.send(data);
}

export function initStripo(options) {
  const apiRequestData = {
    emailId: options.emailId,
    appId: options.appId
  };
  const script = document.createElement("script");
  script.id = "stripoScript";
  script.type = "text/javascript";
  script.src = "https://plugins.stripo.email/static/latest/stripo.js";
  script.onload = function () {
    window.Stripo.init({
      onDataChanged: options.onChange,
      unSubscribeLink: "{{unsubscribe_url}}",
      socialNetworks: [],
      mergeTags: [
        {
          category: "MailChimp",
          entries: [
            {
              label: "First Name",
              value: "*|FNAME|*"
            }
          ]
        }
      ],
      specialLinks: [
        {
          category: "Subscription",
          entries: [
            { label: "Unsubscribe", value: "{{unsubscribe_url}}" },
            { label: "Global unsubscribe", value: "{{global_unsubscribe_url}}" },
            { label: "Subscribe", value: "{{subscribe_url}}" },
          ]
        },
        {
          category: "Other",
          entries: [
            {
              label: "Some special link",
              value: "https://some.special.link.url"
            }
          ]
        }
      ],
      settingsId: "stripoSettingsContainer",
      previewId: "stripoPreviewContainer",
      codeEditorButtonId: "codeEditor",
      undoButtonId: "undoButton",
      redoButtonId: "redoButton",
      locale: "en",
      html: ejs.render(options.html),
      css: ejs.render(options.css),
      apiRequestData: apiRequestData,
      userFullName: "Plugin Demo User",
      versionHistory: {
        changeHistoryLinkId: "changeHistoryLink",
        onInitialized: function (lastChangeIndoText) {
          // $("#changeHistoryContainer").show();
        }
      },
      getAuthToken: function (callback) {
        request(
          "POST",
          "https://plugins.stripo.email/api/v1/auth",
          JSON.stringify({
            pluginId: options.pluginId,
            secretKey: options.secretKey
          }),
          function (data) {
            callback(JSON.parse(data).token);
          }
        );
      }
    });
  };
  document.body.appendChild(script);
}

export function loadDemoTemplate(callback) {
  request(
    "GET",
    "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.html",
    null,
    function (html) {
      request(
        "GET",
        "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.css",
        null,
        function (css) {
          callback({ html: html, css: css });
        }
      );
    }
  );
}
