import React, { useContext } from 'react';
import CheckboxGroupContext from '@components/inputs/CheckboxGroup/CheckboxGroupContext';
import { Checkbox } from 'antd';
import { sequence } from '@utils';

const InGroupCheckbox = ({ name, ...props }) => {
  const context = useContext(CheckboxGroupContext);

  const handleChange = ({ target: { checked } }) => {
    context.onChange(name, checked);
  }

  return (
    <Checkbox
      {...props}
      checked={context.value.indexOf(name) !== -1}
      onChange={sequence(props.onChange, handleChange)}
    />
  );
};

export default InGroupCheckbox;
