import { compose } from '@utils';
import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '@store/lib';
import { FunnelsActionTypes } from '@store/actions/types';

export const listFunnels = compose(
  withCallbacks,
  withPagination,
  withOrdering,
  withFilters(['input_value', 'user_id', 'start_date', 'end_date']),
  asCreator(() => ({
    type: FunnelsActionTypes.LIST,
  })),
);

export const storeFunnel = compose(
  withCallbacks,
  asCreator(({ ...funnel }) => ({
    type: FunnelsActionTypes.STORE,
    payload: funnel,
  })),
);

export const getFunnel = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: FunnelsActionTypes.GET,
    payload: { id },
  })),
);

export const previewFunnel = compose(
  withCallbacks,
  asCreator(({ analytic_type, ...funnel }) => ({
    type: FunnelsActionTypes.PREVIEW,
    payload: { analytic_type: !!~['analytic_general', 'analytic_filtered'].indexOf(analytic_type) ? 'conversion' : analytic_type, ...funnel },
    meta: { type: analytic_type, id: funnel.id || 'new' },
  })),
);

export const exportFunnelCustomers = compose(
  withCallbacks,
  asCreator(({ id, fields, ...funnel }) => ({
    type: FunnelsActionTypes.EXPORT_CUSTOMERS,
    payload: { id, fields, ...funnel }
  }))
);
