import React, { useState } from 'react';
import { useTranslation } from '@hooks';
import { Dropdown } from 'antd';

import { Container, Overlay, Option } from './styled';
import { NoData } from '@components';

const AddExitPointButton = ({ options, onSelect, disabled, noExitPoints, style }) => {
  const { p } = useTranslation('segments_page');
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      disabled={disabled}
      trigger={['click']}
      overlay={(
        <Overlay>
          {options?.length ? options.map(({ label, id }) => (
            <Option key={id} onClick={() => (onSelect(id), setOpen(false))}>
              {label}
            </Option>
          )) : <NoData />}
        </Overlay>
      )}
    >
      <Container $d={disabled} style={style}>
        {noExitPoints ? p('choose_exit_point') : p('add_exit_point')}
      </Container>
    </Dropdown>
  );
};

export default AddExitPointButton;
