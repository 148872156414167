import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 16px 0;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-bottom: 40px;
  color: #303133;
`;

