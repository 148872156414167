import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const Switcher = styled.div`
  position: absolute;
  right: 25px;
  margin-top: 15px;
`;
