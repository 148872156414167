import { moment } from '@utils';
import { UserActionTypes, WorkflowActionTypes, WorkspacesActionTypes } from '../actions/types';
import { updateNode } from '@utils/workflows/refactored/updaters';

const initialState = {
  workflows: {
    loading: true,
  },
  program: {
    loading: true,
  },
  workers: {
    loading: true,
  },
  options: {
    loading: false,
  },
  analytics: {
    loading: false,
  },
  programAnalytics: {
    loading: false,
  },
  nodeAnalytics: {
    loading: false,
  },
  loading: false,
  editMetadata: {},
  publishHistory: {
    loading: false,
  },
  workflowLogs: {},
  defaultNodes: [],
  smsNodeAnalytics: {
    data: {},
    loading: false,
  },
  emailNodeAnalytics: {
    data: {},
    loading: false,
  },
  webpushNodeAnalytics: {
    data: {},
    loading: false,
  },
  webhookNodeAnalytics: {
    data: {},
    loading: false,
  },
  viberNodeAnalytics: {
    data: {},
    loading: false,
  },
  mobilePushAnalytics: {
    data: {},
    loading: false,
  },
  entry_count: {

  },
};

const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM:
      return {
        ...state,
        program: {
          loading: true,
          ...state.program,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM_SUCCESS:
      return {
        ...state,
        program: {
          ...action.payload.data,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM_ERROR:
      return {
        ...state,
        program: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            loading: true,
          },
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT_SUCCESS:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            ...action.payload,
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT_ERROR:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.CLEAR_ENTRY_COUNT:
      return {
        ...state,
        entry_count: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER:
      return {
        ...state,
        workers: {
          ...state.workers,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER_SUCCESS:
      const shouldUpdate = !state.workers[action.payload.data.id] || !(moment(state.workers[action.payload.data.id].updated_at).isAfter(moment(action.payload.data.updated_at)));

      if (!shouldUpdate) {
        return {
          ...state,
          workers: {
            ...state.workers,
            loading: false,
          },
        };
      }

      return {
        ...state,
        workers: {
          ...state.workers,
          [action.payload.data.id]: action.payload.data,
          loading: false,
        },
      };
    case WorkflowActionTypes.UPDATE_WORKFLOW_WORKER:
      const { id, tree_map, styles } = action.payload;

      return {
        ...state,
        workers: {
          ...state.workers,
          [id]: {
            ...(state.workers[id] || {}),
            tree_map,
            cached: true,
            styles: {
              ...(state.workers[id]?.styles || {}),
              styles,
            },
          },
        },
      };
    case WorkflowActionTypes.CLEAR_WORKFLOW_WORKER:
      return {
        ...state,
        workers: {
          ...state.workers,
          [action.payload.id]: undefined,
        },
      };
    case WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE:
      const { worker, node } = action.meta;

      return {
        ...state,
        workers: {
          ...state.workers,
          [worker]: {
            ...(state.workers[worker] || {}),
            cached: true,
            tree_map: updateNode(
              (state.workers[worker]?.tree_map || {}),
              node,
              node => ({ ...node, data: { ...node.data, defaultOpened: true, ...action.payload.data } })
            ),
          },
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER_ERROR:
      return {
        ...state,
        workers: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA:
      return {
        ...state,
        editMetadata: {
          ...state.editMetadata,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA_SUCCESS:
      return {
        ...state,
        editMetadata: {
          ...action.payload.data,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA_ERROR:
      return {
        ...state,
        editMetadata: {
          ...state.editMetadata,
          loading: false,
        },
      };
    case WorkflowActionTypes.LIST:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          loading: true,
        },
      };
    case WorkflowActionTypes.LIST_SUCCESS:
      return {
        ...state,
        workflows: {
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.LIST_ERROR:
      return {
        ...state,
        workflows: {
          loading: false
        },
      };
    case WorkflowActionTypes.GET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_OPTIONS_SUCCESS:
    
      if (action.meta?.part === 'events') {
        return {
          ...state,
          options: {
            ...state.options,
            events: action.payload.data,
            loading: true,
          },
        };
      }

      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_OPTIONS_ERROR:
      return {
        ...state,
        options: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          [action.meta.node_id]: {
            ...(state.nodeAnalytics[action.meta.node_id] || {}),
            ...action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          loading: false
        },
      };
    case WorkflowActionTypes.CLEAR_ANALYTICS:
      return {
        ...state,
        analytics: {
          loading: false,
        },
        nodeAnalytics: {
          loading: false,
        },
      }
    case WorkflowActionTypes.GET_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_ANALYTICS_ERROR:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: false
        },
      };
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS_SUCCESS:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS_ERROR:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          loading: false
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY_SUCCESS:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY_ERROR:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          loading: false,
        },
      };
    case WorkflowActionTypes.SET_DEFAULT_ENTRY_POINT:
      return {
        ...state,
        defaultNodes: [action.payload.node],
      };
    case WorkflowActionTypes.SET_DEFAULT_NODES:
      return {
        ...state,
        defaultNodes: action.payload.nodes,
      };
    case WorkflowActionTypes.CLEAR_DEFAULT_ENTRY_POINT:
      return {
        ...state,
        defaultNodes: [],
      };
    case WorkflowActionTypes.GET_LOGS:
      return {
        ...state,
        workflowLogs: {
          ...state.workflowLogs,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        workflowLogs: {
          ...state.workflowLogs,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_LOGS_ERROR:
      return {
        ...state,
        workflowLogs: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          data: {
            ...state.smsNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          data: {
            ...state.smsNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          loading: false,
          data: {
            ...state.emailNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          data: {
            ...state.emailNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          data: {
            ...state.webpushNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          data: {
            ...state.webpushNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          loading: false,
          data: {
            ...state.webhookNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          data: {
            ...state.webhookNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          loading: false,
          data: {
            ...state.mobilePushAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          data: {
            ...state.mobilePushAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          loading: false,
          data: {
            ...state.viberNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          data: {
            ...state.viberNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.CLEAR_WORKFLOW_LOGS:
      return {
        ...state,
        workflowLogs: {},
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default workflowReducer;
