import styled, { css } from 'styled-components';
import { Button as ANTButton } from 'antd';
import { switchStyle, styledProp } from '@utils';

import { Colors } from '@res/theme';

export const StyledButton = styled(ANTButton)`
  outline: none;
  border: none;
  border-radius: 9px;
  font-family: Rubik-Regular;
  height: 36px;
  line-height: 14px;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  
  & > span {
    //max-height: 36px;
    display: block;
  }
  
  ${switchStyle('appearance', {
    default: css`
      background: ${Colors.Common.BUTTON_DEFAULT};
    `,
    'default-outlined':css`
      background: transparent !important;
      border: 1px solid #109DFB;
      color: #109DFB !important;
            
      &:hover {
        opacity: 0.8 !important;
        background: transparent !important;
        border: 1px solid #109DFB !important;
        color: #109DFB;
      }
    `,
    removeFile: `
     background: #FFFFFF !important;
     color: #303133 !important;
     border: none !important;
    `,
    danger: css`
      background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%) !important;
      border: 1px solid #EFD5D1;
      color: #C8402F;
      
      &:hover {
        color: #C8402F;
      }
      
      &:focus {
        color: #C8402F;
      }
    `,
    'danger-outlined': css`
      background: transparent !important;
      border: 1px solid #C8402F !important;
      color: #C8402F !important;
            
      &:hover {
        opacity: 0.8 !important;
        background: transparent !important;
        border: 1px solid #C8402F !important;
        color: #C8402F;
      }

      &:disabled {
        opacity: 0.6 !important;
        color: #C8402F !important;

        &:hover {
          color: #C8402F !important;
        }
      }
    `,
    secondary: css`
      background: linear-gradient(180deg, #DCDFE6 21.88%, #C8CDDA 100%) !important;
      color: #303133;
      border: 1px solid transparent !important;
      
      &:hover {
        color: #303133;
      }
      
      &:active {
        color: #303133;
      }
      
      &:focus {
        color: #303133;
      }
    `,
    'add-entity': css`
      border-radius: 9px;
      background-color: #CAD1E0 !important;
      background: #CAD1E0 !important;
      color: #303133 !important;
    `,
    outlined: css`
      border: 1px solid #303133;
      border-radius: 9px;
      background-color: transparent !important;
      background: transparent !important;
      color: #303133;

      &:hover {
        color: #303133;
        border: 1px solid #303133 !important;
      }
      
      &:active {
        color: #303133;
        border: 1px solid #303133 !important;
      }
      
      &:focus {
        color: #303133;
        border: 1px solid #303133 !important;
      }
    `,
    discard: css`
      border: none;
      border-radius: 9px;
      background-color: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%) !important;
      background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%) !important;
      color: #303133;

      &:hover {
        color: #303133;
        border: none;
      }
      
      &:active {
        color: #303133;
        border: none;
      }
      
      &:focus {
        color: #303133;
        border: none;
      }
    `,
    'outlined-colored': css`
      border: 1px solid #109DFB;
      border-radius: 9px;
      background-color: transparent !important;
      background: transparent !important;
      color: #109DFB !important;
      
      &[disabled] {
        color: #109DFB !important;
      }

      &:hover {
        color: #109DFB !important;
        &[disabled] {
          color: #109DFB !important;
        }
        border: 1px solid #109DFB !important;
      }
      
      &:active {
        color: #109DFB !important;
        &[disabled] {
          color: #109DFB !important;
        }
        border: 1px solid #109DFB !important;
      }
      
      &:focus {
        color: #109DFB !important;
        &[disabled] {
          color: #109DFB !important;
        }
        border: 1px solid #109DFB !important;
      }
    `,
  })}
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
  `)}

  &:hover, &:active {
    outline: none;
    border: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
