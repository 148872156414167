import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
`;

export const ProgressIndicator = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  transition: 330ms ease all;
  background: #F0F2F6;
  border-radius: 4px;

  ${({ total, invalid }) => {
    if (+total === 100) {
      return css`
        background: #B2DCBC;
        color: #24993E;
      `;
    }
    if (invalid) {
      return css`
        color: #C8402F;
        background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%);
      `;
    }
    if (total !== 100) {
      return css`
        background: #F0F2F6;
        color: #909399;
      `;
    }
  }}
`;

export const Description = styled.div`
  text-align: center;
  margin-top: 6px;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;
