import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateCell, Icon, Link, SortableTitle, Table, WithPermissions } from '@components';
import TextDetails from '../TextDetails/TextDetails';
import {smsLoadingSelector, smsSelector, templateSelector} from '@store/selectors';
import { SmsActions } from '../SmsActions';
import { StatusCell } from '../StatusCell';
import { useModalState, useTranslation } from '@hooks';
import SmsInfoModal from '../Modals/SmsInfoModal';
import { Paths, SmsStatuses, SmsStatusLabels, UserPermissions } from '@constants';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { Dropdown } from 'antd';
import { extract, tryHumanizeResponse } from '@utils';
import { IdType, Overlay } from '@components/lib/styled';
const resolveStatus = (status, t) => t(SmsStatusLabels[status]);

const resolveColor = status => ({
  [SmsStatuses.SENT]: '#24993E',
  [SmsStatuses.QUEUED]: '#909399',
  [SmsStatuses.DELIVERED]: '#24993E',
  [SmsStatuses.SENDING]: '#909399',
  [SmsStatuses.UNDELIVERED]: '#C8402F',
  [SmsStatuses.CLICK]: '#24993E',
  [SmsStatuses.FAILED]: '#C8402F',
})[status];

const SmsTable = ({ keyExtractor, cols, ordering }) => {
  const sms = useSelector(smsSelector);
  const loading = useSelector(smsLoadingSelector);
  const smsInfoModal = useModalState();
  const templates = useSelector(templateSelector);
  const [currentSms, setCurrentSms] = useState({});
  const { t, p } = useTranslation('notification_history')

  const createSmsTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      className: `notification-history-cell-id`,
      key: 'customer_id',
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      )
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'number'}>{t('labels.phone')}</SortableTitle>,
      dataIndex: 'number',
      className: `notification-history-cell-phone`,
      key: 'number',
      render: (number) => <FlexibleText text={number} maxWidth={130}>{number}</FlexibleText>
    },
    {
      title: () => <SortableTitle>{t('labels.sms_text')}</SortableTitle>,
      dataIndex: 'message',
      className: `notification-history-cell-sms`,
      key: 'message',
      render: (text) => <WithPermissions name={UserPermissions.REVIEW_SMS}><TextDetails text={text} /></WithPermissions>,
    },
    {
      title: () => <SortableTitle name="template_id" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (id, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_SMS}><Link to={`${Paths.TEMPLATES_SMS}/${data.template_id}?view=1`}>{templates?.sms?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      className: `notification-history-cell-date`,
      key: 'created_at',
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={resolveStatus(status, t)} color={resolveColor(status)}  />
      }
    },
    {
      title: () => <SortableTitle>{t('labels.response')}</SortableTitle>,
      dataIndex: 'response',
      className: `notification-history-cell-response`,
      key: 'response',
      render: (r, { s }) => {
        if (!r) {
          return null;
        }

        return (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <Overlay>
                <pre>
                  {tryHumanizeResponse(r)}
                </pre>
              </Overlay>
            )}
          >
            <div>
              <Icon color={resolveColor(s)} name="Info-icon" size={16} />
            </div>
          </Dropdown>
        )
      },
    },
  ];

  const columns = createSmsTableColumns(ordering);

  const createActionColumn = (callbacks) => ({
    title: () => <SortableTitle right={true}>{t('labels.actions')}</SortableTitle>,
    key: 'actions',
    className: `notification-history-cell-actions`,
    fixed: 'right',
    render: (a, data) => <SmsActions onSmsInfoClick={() => callbacks.onSmsInfoClick(data)} />
  })


  const smsExtraColumns = [
    {
      title: () => <SortableTitle>{t('labels.updated_at')}</SortableTitle>,
      dataIndex: 'ut',
      key: 'updated_at',
      className: `notification-history-cell-updated_at`,
      render: (date) => <DateCell date={date} />
    },
    // {
    //   title: () => <SortableTitle>{t('labels.group')}</SortableTitle>,
    //   dataIndex: 'group',
    //   key: 'group',
    //   render: (date) => <DateCell date={date} />
    // },
  ];

  const handleSmsInfoClick = (data) => {
    setCurrentSms(data);
    smsInfoModal.open();
  }

  smsExtraColumns.forEach((args) => cols.indexOf(args.key) !== -1 && columns.push(args));

  columns.push(createActionColumn({
    onSmsInfoClick: handleSmsInfoClick,
  }))

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_SMS} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={sms.data?.map?.(extract('id')).join()}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          columns={columns}
          dataSource={sms.data}
          rowKey={keyExtractor}
        />
      </WithPermissions>
      <SmsInfoModal
        opened={smsInfoModal.opened}
        onClose={smsInfoModal.close}
        data={currentSms || {}}
      />
    </>
  )
}

export default SmsTable;

