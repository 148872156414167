const TemplatesActionTypes = {
  GET_SMS: 'templates/sms/GET',
  GET_SMS_SUCCESS: 'templates/sms/GET_SUCCESS',
  GET_SMS_ERROR: 'templates/sms/GET_ERROR',

  GET_SINGLE_SMS: 'templates/sms/GET_SINGLE',
  GET_SINGLE_SMS_SUCCESS: 'templates/sms/GET_SINGLE_SUCCESS',
  GET_SINGLE_SMS_ERROR: 'templates/sms/GET_SINGLE_ERROR',
  CLEAR_SINGLE_SMS: 'templates/sms/CLEAR_SINGLE_SMS',
  CLEAR_SINGLE_WEBPUSH: 'templates/sms/CLEAR_SINGLE_WEBPUSH',
  CLEAR_SINGLE_VIBER: 'templates/viber/CLEAR_SINGLE',

  CREATE_SMS: 'templates/sms/CREATE',
  CREATE_SMS_SUCCESS: 'templates/sms/CREATE_SUCCESS',
  CREATE_SMS_ERROR: 'templates/sms/CREATE_ERROR',

  GET_MOBILE_PUSH_SUMMARY: 'templates/GET_MOBILE_PUSH_SUMMARY',
  GET_MOBILE_PUSH_SUMMARY_SUCCESS: 'templates/GET_MOBILE_PUSH_SUMMARY_SUCCESS',
  GET_MOBILE_PUSH_SUMMARY_ERROR: 'templates/GET_MOBILE_PUSH_SUMMARY_ERROR',

  EDIT_SMS: 'templates/sms/EDIT',
  EDIT_SMS_SUCCESS: 'templates/sms/EDIT_SUCCESS',
  EDIT_SMS_ERROR: 'templates/sms/EDIT_ERROR',

  DELETE_SMS: 'templates/sms/DELETE',
  DELETE_SMS_SUCCESS: 'templates/sms/DELETE_SUCCESS',
  DELETE_SMS_ERROR: 'templates/sms/DELETE_ERROR',

  GET_EMAILS: 'templates/email/GET',
  GET_EMAILS_SUCCESS: 'templates/email/GET_SUCCESS',
  GET_EMAILS_ERROR: 'templates/email/GET_ERROR',

  GET_MOBILE_PUSH: 'templates/mobile-push/GET',
  GET_MOBILE_PUSH_SUCCESS: 'templates/mobile-push/GET_SUCCESS',
  GET_MOBILE_PUSH_ERROR: 'templates/mobile-push/GET_ERROR',

  CREATE_EMAIL: 'templates/email/CREATE',
  CREATE_EMAIL_SUCCESS: 'templates/email/CREATE_SUCCESS',
  CREATE_EMAIL_ERROR: 'templates/email/CREATE_ERROR',

  CREATE_MOBILE_PUSH: 'templates/mobile-push/CREATE',
  CREATE_MOBILE_PUSH_SUCCESS: 'templates/mobile-push/CREATE_SUCCESS',
  CREATE_MOBILE_PUSH_ERROR: 'templates/mobile-push/CREATE_ERROR',

  GET_WEBPUSH: 'templates/webpush/GET',
  GET_WEBPUSH_SUCCESS: 'templates/webpush/GET_SUCCESS',
  GET_WEBPUSH_ERROR: 'templates/webpush/GET_ERROR',

  CREATE_WEBPUSH: 'templates/webpush/CREATE',
  CREATE_WEBPUSH_SUCCESS: 'templates/webpush/CREATE_SUCCESS',
  CREATE_WEBPUSH_ERROR: 'templates/webpush/CREATE_ERROR',

  DELETE_WEBPUSH: 'templates/webpush/DELETE',
  DELETE_WEBPUSH_SUCCESS: 'templates/webpush/DELETE_SUCCESS',
  DELETE_WEBPUSH_ERROR: 'templates/webpush/DELETE_ERROR',

  GET_SINGLE_MOBILE_PUSH_TEMPLATE: 'templates/mobile-push/GET_SINGLE_MOBILE_PUSH_TEMPLATE',
  GET_SINGLE_MOBILE_PUSH_TEMPLATE_SUCCESS: 'templates/mobile-push/GET_SINGLE_MOBILE_PUSH_TEMPLATE_SUCCESS',
  GET_SINGLE_MOBILE_PUSH_TEMPLATE_ERROR: 'templates/mobile-push/GET_SINGLE_MOBILE_PUSH_TEMPLATE_ERROR',

  GET_SINGLE_WEBPUSH: 'templates/webpush/GET_SINGLE_WEBPUSH',
  GET_SINGLE_WEBPUSH_SUCCESS: 'templates/webpush/GET_SINGLE_WEBPUSH_SUCCESS',
  GET_SINGLE_WEBPUSH_ERROR: 'templates/webpush/GET_SINGLE_WEBPUSH_ERROR',

  GET_TEMPLATE_PREVIEW_INFO: 'templates/GET_PREVIEW',
  GET_TEMPLATE_PREVIEW_INFO_SUCCESS: 'templates/GET_PREVIEW_SUCCESS',
  GET_TEMPLATE_PREVIEW_INFO_ERROR: 'templates/GET_PREVIEW_ERROR',

  EDIT_WEBPUSH: 'templates/webpush/EDIT',
  EDIT_WEBPUSH_SUCCESS: 'templates/webpush/EDIT_SUCCESS',
  EDIT_WEBPUSH_ERROR: 'templates/webpush/EDIT_ERROR',

  GET_API_REQUEST: '/templates/api-request/GET',
  GET_API_REQUEST_SUCCESS: '/templates/api-request/GET_SUCCESS',
  GET_API_REQUEST_ERROR: '/templates/api-request/GET_ERROR',

  GET_SINGLE_API_REQUEST: '/templates/api-request/GET_SINGLE',
  GET_SINGLE_API_REQUEST_SUCCESS: '/templates/api-request/GET_SINGLE_SUCCESS',
  GET_SINGLE_API_REQUEST_ERROR: '/templates/api-request/GET_SINGLE_ERROR',

  CREATE_API_REQUEST: '/templates/api-request/CREATE',
  CREATE_API_REQUEST_SUCCESS: '/templates/api-request/CREATE_SUCCESS',
  CREATE_API_REQUEST_ERROR: '/templates/api-request/CREATE_ERROR',

  DELETE_API_REQUEST: '/templates/api-request/DELETE',
  DELETE_API_REQUEST_SUCCESS: '/templates/api-request/DELETE_SUCCESS',
  DELETE_API_REQUEST_ERROR: '/templates/api-request/DELETE_ERROR',

  GET_EMAIL_SAMPLE_DATA: 'templates/email/GET_EMAIL_SAMPLE_DATA',
  GET_EMAIL_SAMPLE_DATA_SUCCESS: 'templates/email/GET_EMAIL_SAMPLE_DATA_SUCCESS',
  GET_EMAIL_SAMPLE_DATA_ERROR: 'templates/email/GET_EMAIL_SAMPLE_DATA_ERROR',

  DELETE_MOBILE_PUSH: 'templates/mobile-push/DELETE_MOBILE_PUSH',
  DELETE_MOBILE_PUSH_SUCCESS: 'templates/mobile-push/DELETE_MOBILE_PUSH_SUCCESS',
  DELETE_MOBILE_PUSH_ERROR: 'templates/mobile-push/DELETE_MOBILE_PUSH_ERROR',

  ARCHIVE_MOBILE_PUSH_TEMPLATE: 'templates/mobile-push/ARCHIVE_MOBILE_PUSH_TEMPLATE',
  ARCHIVE_MOBILE_PUSH_TEMPLATE_SUCCESS: 'templates/mobile-push/ARCHIVE_MOBILE_PUSH_TEMPLATE_SUCCESS',
  ARCHIVE_MOBILE_PUSH_TEMPLATE_ERROR: 'templates/mobile-push/ARCHIVE_MOBILE_PUSH_TEMPLATE_ERROR',

  UNARCHIVE_MOBILE_PUSH_TEMPLATE: 'templates/mobile-push/UNARCHIVE_MOBILE_PUSH_TEMPLATE',
  UNARCHIVE_MOBILE_PUSH_TEMPLATE_SUCCESS: 'templates/mobile-push/UNARCHIVE_MOBILE_PUSH_TEMPLATE_SUCCESS',
  UNARCHIVE_MOBILE_PUSH_TEMPLATE_ERROR: 'templates/mobile-push/UNARCHIVE_MOBILE_PUSH_TEMPLATE_ERROR',

  GET_SINGLE_MOBILE_PUSH: 'templates/mobile-push/GET_SINGLE',
  GET_SINGLE_MOBILE_PUSH_SUCCESS: 'templates/mobile-push/GET_SINGLE_SUCCESS',
  GET_SINGLE_MOBILE_PUSH_ERROR: 'templates/mobile-push/GET_SINGLE_ERROR',
  SET_SINGLE_MOBILE_PUSH: 'templates/mobile-push/SET_SINGLE',
  CLEAR_SINGLE_MOBILE_PUSH: 'templates/mobile-push/CLEAR_SINGLE_EMAIL',

  DELETE_EMAIL: 'templates/email/DELETE',
  DELETE_EMAIL_SUCCESS: 'templates/email/DELETE_SUCCESS',
  DELETE_EMAIL_ERROR: 'templates/email/DELETE_ERROR',

  GET_SINGLE_EMAIL: 'templates/email/GET_SINGLE',
  GET_SINGLE_EMAIL_SUCCESS: 'templates/email/GET_SINGLE_SUCCESS',
  GET_SINGLE_EMAIL_ERROR: 'templates/email/GET_SINGLE_ERROR',
  SET_SINGLE_EMAIL: 'templates/email/SET_SINGLE',
  CLEAR_SINGLE_EMAIL: 'templates/email/CLEAR_SINGLE_EMAIL',

  SET_MOBILE_PUSH_DUPLICATE: 'templates/mobile-push/SET_MOBILE_PUSH_DUPLICATE',
  CLEAR_MOBILE_PUSH_DUPLICATE: 'templates/mobile-push/CLEAR_MOBILE_PUSH_DUPLICATE',

  SET_EMAIL_DUPLICATE: 'templates/email/SET_DUPLICATE',
  CLEAR_EMAIL_DUPLICATE: 'templates/email/CLEAR_DUPLICATE',

  SET_SMS_DUPLICATE: 'templates/sms/SET_DUPLICATE',
  CLEAR_SMS_DUPLICATE: 'templates/sms/CLEAR_DUPLICATE',

  SET_WEBPUSH_DUPLICATE: 'templates/sms/SET_WEBPUSH_DUPLICATE',
  CLEAR_WEBPUSH_DUPLICATE: 'templates/sms/CLEAR_WEBPUSH_DUPLICATE',

  SET_NEW_EMAIL: 'templates/email/SET_NEW',
  SET_NEW_SMS: 'templates/sms/SET_NEW',
  SET_NEW_MOBILE_PUSH: 'templates/mobile-push/SET_NEW',
  SET_NEW_WEBPUSH: 'templates/webpush/SET_NEW',
  SET_NEW_VIBER: 'templates/viber/SET_NEW',
  UPDATE_NEW_EMAIL: 'templates/email/UPDATE_NEW',
  UPDATE_NEW_SMS: 'templates/sms/UPDATE_NEW',
  UPDATE_NEW_WEBPUSH: 'templates/webpush/UPDATE_NEW',

  DELETE_VIBER: 'templates/viber/DELETE',
  DELETE_VIBER_SUCCESS: 'templates/viber/DELETE_SUCCESS',
  DELETE_VIBER_ERROR: 'templates/viber/DELETE_ERROR',

  GET_VIBERS: 'templates/vibers/GET',
  GET_VIBERS_SUCCESS: 'templates/vibers/GET_SUCCESS',
  GET_VIBERS_ERROR: 'templates/vibers/GET_ERROR',

  CREATE_VIBER: 'templates/vibers/CREATE',
  CREATE_VIBER_SUCCESS: 'templates/vibers/CREATE_SUCCESS',
  CREATE_VIBER_ERROR: 'templates/vibers/CREATE_ERROR',

  EDIT_EMAIL: 'templates/email/EDIT',
  EDIT_EMAIL_SUCCESS: 'templates/email/EDIT_SUCCESS',
  EDIT_EMAIL_ERROR: 'templates/email/EDIT_ERROR',

  GET_SINGLE_VIBER: 'templates/viber/GET',
  GET_SINGLE_VIBER_SUCCESS: 'templates/viber/GET_SUCCESS',
  GET_SINGLE_VIBER_ERROR: 'templates/viber/GET_ERROR',

  VALIDATE_EMAIL_TEMPLATE_NAME: 'templates/email/VALIDATE_NAME',
  VALIDATE_EMAIL_TEMPLATE_NAME_SUCCESS: 'templates/email/VALIDATE_NAME_SUCCESS',
  VALIDATE_EMAIL_TEMPLATE_NAME_ERROR: 'templates/email/VALIDATE_NAME_ERROR',

  VALIDATE_SMS_TEMPLATE_NAME: 'templates/sms/VALIDATE_NAME',
  VALIDATE_SMS_TEMPLATE_NAME_SUCCESS: 'templates/sms/VALIDATE_NAME_SUCCESS',
  VALIDATE_SMS_TEMPLATE_NAME_ERROR: 'templates/sms/VALIDATE_NAME_ERROR',

  VALIDATE_WEBPUSH_TEMPLATE_NAME: 'templates/webpush/VALIDATE_NAME',
  VALIDATE_WEBPUSH_TEMPLATE_NAME_SUCCESS: 'templates/webpush/VALIDATE_NAME_SUCCESS',
  VALIDATE_WEBPUSH_TEMPLATE_NAME_ERROR: 'templates/webpush/VALIDATE_NAME_ERROR',

  VALIDATE_WEBHOOK_TEMPLATE_NAME: 'templates/webhook/VALIDATE_NAME',
  VALIDATE_WEBHOOK_TEMPLATE_NAME_SUCCESS: 'templates/webhook/VALIDATE_NAME_SUCCESS',
  VALIDATE_WEBHOOK_TEMPLATE_NAME_ERROR: 'templates/webhook/VALIDATE_NAME_ERROR',

  SEND_TEST_EMAIL: 'templates/email/SEND_TEST',
  SEND_TEST_EMAIL_SUCCESS: 'templates/email/SEND_TEST_SUCCESS',
  SEND_TEST_EMAIL_ERROR: 'templates/email/SEND_TEST_ERROR',

  SEND_TEST_SMS: 'templates/sms/SEND_TEST',
  SEND_TEST_SMS_SUCCESS: 'templates/sms/SEND_TEST_SUCCESS',
  SEND_TEST_SMS_ERROR: 'templates/sms/SEND_TEST_ERROR',

  SEND_TEST_WEB_PUSH: 'templates/webpush/SEND_TEST',
  SEND_TEST_WEB_PUSH_SUCCESS: 'templates/webpush/SEND_TEST_SUCCESS',
  SEND_TEST_WEB_PUSH_ERROR: 'templates/webpush/SEND_TEST_ERROR',

  SET_EMAIL_PREVIEW: 'templates/email/GET_PREVIEW',
  SET_SMS_PREVIEW: 'templates/sms/GET_PREVIEW',
  SET_WEBPUSH_PREVIEW: 'templates/webpush/GET_PREVIEW',
  SET_MOBILE_PUSH_PREVIEW: 'templates/mobile-push/GET_PREVIEW',
  SET_API_REQUEST_PREVIEW: 'templates/api-request/GET_PREVIEW',
  SET_VIBER_PREVIEW: 'templates/viber/GET_PREVIEW',

  CLEAR_EMAIL_PREVIEW: 'templates/email/CLEAR_PREVIEW',
  CLEAR_SMS_PREVIEW: 'templates/sms/CLEAR_PREVIEW',
  CLEAR_WEBPUSH_PREVIEW: 'templates/webpush/CLEAR_PREVIEW',
  CLEAR_API_REQUEST_PREVIEW: 'templates/api-request/CLEAR_PREVIEW',
  CLEAR_VIBER_PREVIEW: 'templates/viber/CLEAR_PREVIEW',
  CLEAR_MOBILE_PUSH_PREVIEW: 'templates/mobile-push/CLEAR_PREVIEW',

  GET_SUBSCRIPTION_GROUPS: 'templates/GET_SUBSCRIPTION_GROUPS',
  GET_SUBSCRIPTION_GROUPS_SUCCESS: 'templates/GET_SUBSCRIPTION_GROUPS_SUCCESS',
  GET_SUBSCRIPTION_GROUPS_ERROR: 'templates/GET_SUBSCRIPTION_GROUPS_ERROR',

  ARCHIVE_VIBER_TEMPLATE: 'templates/viber/ARCHIVE',
  ARCHIVE_VIBER_TEMPLATE_SUCCESS: 'templates/viber/ARCHIVE_SUCCESS',
  ARCHIVE_VIBER_TEMPLATE_ERROR: 'templates/viber/ARCHIVE_ERROR',

  UNARCHIVE_VIBER_TEMPLATE: 'templates/viber/UNARCHIVE',
  UNARCHIVE_VIBER_TEMPLATE_SUCCESS: 'templates/viber/UNARCHIVE_SUCCESS',
  UNARCHIVE_VIBER_TEMPLATE_ERROR: 'templates/viber/UNARCHIVE_ERROR',

  ARCHIVE_EMAIL_TEMPLATE: 'templates/email/ARCHIVE',
  ARCHIVE_EMAIL_TEMPLATE_SUCCESS: 'templates/email/ARCHIVE_SUCCESS',
  ARCHIVE_EMAIL_TEMPLATE_ERROR: 'templates/email/ARCHIVE_ERROR',

  UNARCHIVE_EMAIL_TEMPLATE: 'templates/email/UNARCHIVE',
  UNARCHIVE_EMAIL_TEMPLATE_SUCCESS: 'templates/email/UNARCHIVE_SUCCESS',
  UNARCHIVE_EMAIL_TEMPLATE_ERROR: 'templates/email/UNARCHIVE_ERROR',

  ARCHIVE_SMS_TEMPLATE: 'templates/sms/ARCHIVE',
  ARCHIVE_SMS_TEMPLATE_SUCCESS: 'templates/sms/ARCHIVE_SUCCESS',
  ARCHIVE_SMS_TEMPLATE_ERROR: 'templates/sms/ARCHIVE_ERROR',

  UNARCHIVE_SMS_TEMPLATE: 'templates/sms/UNARCHIVE',
  UNARCHIVE_SMS_TEMPLATE_SUCCESS: 'templates/sms/UNARCHIVE_SUCCESS',
  UNARCHIVE_SMS_TEMPLATE_ERROR: 'templates/sms/UNARCHIVE_ERROR',

  ARCHIVE_WEBPUSH_TEMPLATE: 'templates/webpush/ARCHIVE',
  ARCHIVE_WEBPUSH_TEMPLATE_SUCCESS: 'templates/webpush/ARCHIVE_SUCCESS',
  ARCHIVE_WEBPUSH_TEMPLATE_ERROR: 'templates/webpush/ARCHIVE_ERROR',

  UNARCHIVE_WEBPUSH_TEMPLATE: 'templates/webpush/UNARCHIVE',
  UNARCHIVE_WEBPUSH_TEMPLATE_SUCCESS: 'templates/webpush/UNARCHIVE_SUCCESS',
  UNARCHIVE_WEBPUSH_TEMPLATE_ERROR: 'templates/webpush/UNARCHIVE_ERROR',

  ARCHIVE_WEBHOOK_TEMPLATE: 'templates/api-request/ARCHIVE',
  ARCHIVE_WEBHOOK_TEMPLATE_SUCCESS: 'templates/api-request/ARCHIVE_SUCCESS',
  ARCHIVE_WEBHOOK_TEMPLATE_ERROR: 'templates/api-request/ARCHIVE_ERROR',

  UNARCHIVE_WEBHOOK_TEMPLATE: 'templates/api-request/UNARCHIVE',
  UNARCHIVE_WEBHOOK_TEMPLATE_SUCCESS: 'templates/api-request/UNARCHIVE_SUCCESS',
  UNARCHIVE_WEBHOOK_TEMPLATE_ERROR: 'templates/api-request/UNARCHIVE_ERROR',

  GET_FOLDERS: 'templates/GET_FOLDERS',
  GET_FOLDERS_SUCCESS: 'templates/GET_FOLDERS_SUCCESS',
  GET_FOLDERS_ERROR: 'templates/GET_FOLDERS_ERROR',

  CREATE_FOLDER: 'templates/CREATE_FOLDER',
  CREATE_FOLDER_SUCCESS: 'templates/CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_ERROR: 'templates/CREATE_FOLDER_ERROR',

  GET_VIBER_SUMMARY: 'contacts/GET_VIBER_SUMMARY',
  GET_VIBER_SUMMARY_SUCCESS: 'contacts/GET_VIBER_SUMMARY_SUCCESS',
  GET_VIBER_SUMMARY_ERROR: 'contacts/GET_VIBER_SUMMARY_ERROR',

  SET_FOLDER: 'templates/SET_FOLDER',
  SET_FOLDER_SUCCESS: 'templates/SET_FOLDER_SUCCESS',
  SET_FOLDER_ERROR: 'templates/SET_FOLDER_ERROR',

  SET_EMAIL_FOLDER: 'templates/SET_EMAIL_FOLDER',
  SET_EMAIL_FOLDER_SUCCESS: 'templates/SET_EMAIL_FOLDER_SUCCESS',
  SET_EMAIL_FOLDER_ERROR: 'templates/SET_EMAIL_FOLDER_ERROR',

  SET_VIBER_FOLDER: 'templates/SET_VIBER_FOLDER',
  SET_VIBER_FOLDER_SUCCESS: 'templates/SET_VIBER_FOLDER_SUCCESS',
  SET_VIBER_FOLDER_ERROR: 'templates/SET_VIBER_FOLDER_ERROR',

  SET_SMS_FOLDER: 'templates/SET_SMS_FOLDER',
  SET_SMS_FOLDER_SUCCESS: 'templates/SET_SMS_FOLDER_SUCCESS',
  SET_SMS_FOLDER_ERROR: 'templates/SET_SMS_FOLDER_ERROR',

  DELETE_FOLDER: 'templates/DELETE_FOLDER',
  DELETE_FOLDER_SUCCESS: 'templates/DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_ERROR: 'templates/DELETE_FOLDER_ERROR',

  LOAD_TEMPLATES_LINK: 'templates/LOAD_TEMPLATES_LINK',
  LOAD_TEMPLATES_LINK_SUCCESS: 'templates/LOAD_TEMPLATES_LINK_SUCCESS',
  LOAD_TEMPLATES_LINK_ERROR: 'templates/LOAD_TEMPLATES_LINK_ERROR',

  GET_FOLDERS_LIST: 'templates/GET_FOLDERS_LIST',
  GET_FOLDERS_LIST_SUCCESS: 'templates/GET_FOLDERS_LIST_SUCCESS',
  GET_FOLDERS_LIST_ERROR: 'templates/GET_FOLDERS_LIST_ERROR',

  GET_TEMPLATE_STATISTICS: 'templates/GET_STATISTICS',
  GET_TEMPLATE_STATISTICS_SUCCESS: 'templates/GET_STATISTICS_SUCCESS',
  GET_TEMPLATE_STATISTICS_ERROR: 'templates/GET_STATISTICS_ERROR',
};

export default TemplatesActionTypes;
