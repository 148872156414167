export const ReducerKeys = {
  APP: 'app',
  USER: 'user',
  CONTACTS: 'contacts',
  PROMO_CODES: 'promo-codes',
  SEGMENTS: 'segments',
  WORKFLOW: 'workflow',
  TEMPLATES: 'templates',
  INTEGRATIONS: 'integrations',
  ROLES: 'roles',
  WORKSPACES: 'workspaces',
  HISTORY_NOTIFICATIONS: 'history-notifications',
  NOTIFICATIONS: 'notifications',
  EVENTS: 'events',
  DASHBOARD: 'dashboard',
  WORKFLOW_EDITOR: 'workflowEditor',
  ACCOUNT: 'account',
  CAMPAIGNS: 'campaigns',
  EXPRESSION: 'expression',
  FUNNELS: 'funnels',
  SUBSCRIPTION_GROUPS: 'subscription-groups',
  FIELDS: 'fields',
  RFM_ANALYTICS: 'rfm-analytics',
};
