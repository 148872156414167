import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
`;

export const Label = styled.div`
  font-size: 13px;
  line-height: 100%;
  color: #909399;
`;

export const Editor = styled.div`
  display: flex;
  padding-top: 13px;
  width: 100%;
  justify-content: center;
  position: relative;
`;

export const ImagePlaceholder = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(218.82deg, rgba(255, 255, 255, 0.2) 14.31%, rgba(255, 255, 255, 0) 39.98%), radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 87.5%, rgba(255, 255, 255, 0.09) 100%), linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
`;

export const Settings = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  transition: 280ms ease all;
  ${styledProp('disabled', `
    opacity: 0;
    pointer-events: none;
  `)}
`;

export const EditButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  background: #109DFB;
  bottom: 16.28px;
  right: 16.28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 9px;
    
  `)}
`;
