import React from 'react';
import { Dropdown, Tooltip } from 'antd';
import { useTranslation } from '@hooks';

import {
  Container,
  ConnectionLine,
  IconContainer,
  WorkflowsList,
  AddWorkflowButton,
  WorkflowListTitle
} from './styled';
import { Link } from '@components';
import { Paths } from '@constants';

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.8316 5.39283C9.05613 5.17587 9.05613 4.82412 8.8316 4.60716C7.77386 3.58511 6.75193 2.50845 5.63958 1.53707C5.38656 1.31612 5 1.4993 5 1.83523L5 8.16476C5 8.50068 5.38656 8.68387 5.63958 8.46291C6.75192 7.49153 7.77386 6.41488 8.8316 5.39283Z" fill="white"/>
    <rect x="1" y="4" width="7" height="2" fill="white"/>
  </svg>
);

const plus = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_21845_651278)">
      <path d="M11.3277 5.9977C11.329 6.37134 11.0263 6.67482 10.6526 6.67444L6.67162 6.67036L6.6757 10.6517C6.67608 11.0256 6.37314 11.3288 5.99931 11.3288C5.62547 11.3288 5.32253 11.0256 5.32291 10.6517L5.32699 6.67036L1.34563 6.67444C0.971794 6.67482 0.66854 6.37188 0.66854 5.99805C0.66854 5.62422 0.971794 5.32127 1.34563 5.32165L5.32699 5.32573L5.32699 1.34436C5.32699 0.973054 5.628 0.672049 5.99931 0.672049C6.37062 0.672049 6.67162 0.973055 6.67162 1.34437L6.67162 5.32573L10.6533 5.32573C11.0248 5.32573 11.3264 5.6262 11.3277 5.9977Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_21845_651278">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const NextWorkflowTrigger = ({ workflows, onCreate }) => {
  const { t, p } = useTranslation('workflow_page');

  return (
    <Container>
      <ConnectionLine />
      <IconContainer $a={!!workflows.length} onClick={!workflows.length ? onCreate : void 0}>
        {workflows.length ? (
          <Dropdown
            trigger={['hover']}
            overlay={(
              <WorkflowsList>
                <WorkflowListTitle>
                  {p('started_workflows')}
                </WorkflowListTitle>
                {workflows.map(({ name, version, program_id, id }) => (
                  <Link to={`${Paths.EDIT_WORKFLOW}/${program_id}/editor?w=${id}`}>{name} ({version})</Link>
                ))}
                <AddWorkflowButton onClick={onCreate}>
                  <svg style={{ marginRight: 6 }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="22" height="22" rx="6" fill="url(#paint0_linear_21845_651630)"/>
                    <path d="M16.5691 10.9978C16.5702 11.3066 16.32 11.5575 16.0112 11.5573L11.5539 11.5538L11.5574 16.0112C11.5577 16.3203 11.3072 16.5709 10.9982 16.5709C10.6892 16.5709 10.4388 16.3203 10.439 16.0112L10.4425 11.5538L5.98502 11.5573C5.67601 11.5575 5.42537 11.3071 5.42537 10.998C5.42537 10.689 5.67601 10.4386 5.98502 10.4388L10.4425 10.4423L10.4425 5.98485C10.4425 5.67794 10.6913 5.42914 10.9982 5.42914C11.3051 5.42914 11.5539 5.67794 11.5539 5.98485V10.4423H16.0116C16.3187 10.4423 16.568 10.6907 16.5691 10.9978Z" fill="#303133"/>
                    <defs>
                      <linearGradient id="paint0_linear_21845_651630" x1="11" y1="0" x2="11" y2="22" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#DCDFE6"/>
                        <stop offset="1" stopColor="#CDD3DF"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  {p('new_workflow')}
                </AddWorkflowButton>
              </WorkflowsList>
            )}
          >
            {arrow}
          </Dropdown>
        ) : (
          <Tooltip placement="right" title={p('start_another_workflow_from_this_node')}>
            {plus}
          </Tooltip>
        )}
      </IconContainer>
    </Container>
  );
};

export default NextWorkflowTrigger;
