import { useMemo, useState } from 'react';
import useUndo from 'use-undo';
import { update } from '@utils';

const useWorkerState = () => {
  const [
    { present: pointer },
    {
      set: setPointer,
      undo,
      redo,
      canUndo,
      canRedo,
    },
  ] = useUndo(0);
  const [rootsState, setRootsState] = useState([null]);
  const [stylesState, setStylesState] = useState([{}]);

  const root = useMemo(() => rootsState[pointer], [pointer, rootsState[pointer]]);
  const styles = useMemo(() => stylesState[pointer], [pointer, stylesState[pointer]]);

  const updateRoot = (updater) => {
    setRootsState(rs => rs.map((r, i) => i === pointer ? update(r, updater) : r));
  };

  const updateStyles = (updater) => {
    setStylesState(ss => ss.map((s, i) => i === pointer ? update(s, updater) : s));
  };

  const updateRootAndStyles = (rootUpdater, stylesUpdater) => {
    updateRoot(rootUpdater);
    updateStyles(stylesUpdater);
  };

  const setRootAndStyles = (rootUpdater, stylesUpdater, skip) => {
    if (skip) {
      return updateRootAndStyles(rootUpdater, stylesUpdater);
    }

    const updatedRoot = update(root, rootUpdater);
    const updatedStyles = update(styles, stylesUpdater);
    const newPointer = pointer + 1;

    setRootsState(rs => {
      rs[newPointer] = updatedRoot;
      return rs;
    });
    setStylesState(ss => {
      ss[newPointer] = updatedStyles;
      return ss;
    });
    setPointer(newPointer);
  };


  return {
    undo,
    redo,
    canUndo,
    canRedo,
    root,
    styles,
    updateRoot,
    updateStyles,
    setRootAndStyles,
  }
};

export default useWorkerState;
