import React from 'react';

import { Container } from './styled';

const AccordionArrow = ({ opened }) => {
 return (
   <Container $opened={opened}>
    <svg width="8" height="8" stroke="#909399" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fillRule="evenodd" clipRule="evenodd" d="M7.73334 2.59998L4.00001 6.33331L0.266673 2.59998L1.20001 1.66665L4.00001 4.46665L6.8 1.66665L7.73334 2.59998Z" fill="white"/>
    </svg>
   </Container>
 );
};

export default AccordionArrow;
