import React from 'react';
import { ActionButton, WithOnboarding, OnboardingHighlight } from '@components';

import { ZoomSlider } from '../ZoomSlider';
import { Container, Row } from './styled';
import { HotkeysDropdown } from '@components/lib/WorkflowEditor/components/ActionsBar/components';
import { Platform, testId } from '@utils';
import { useOnboarding, useTranslation } from '@hooks';

const ActionsBar = ({
  reactFlowInstance,
  onFitView,
  onUndo,
  onRedo,
  canUndo,
  canDelete,
  canRedo,
  onDelete,
  onCopy,
  onPaste,
  isFullScreen,
  canCopy,
  onFullScreen,
  zoomPanHandlerRef,
  minimizeStatus,
  onMinimize,
}) => {
  const onboarding = useOnboarding('workflows');
  const { t, p } = useTranslation('workflow_page');

  return (
    <Container>
      <WithOnboarding placement="bottom" enabled={onboarding.enabled(9)} text={p('zoom_settings')} next onboardKey="workflows" getPopupContainer={t => t.parentNode.parentNode}>
        <OnboardingHighlight on={onboarding.enabled(9)}>
        <Row onboard={onboarding.enabled(9)} style={{ marginLeft: 18, marginRight: 13 }}>
          <ZoomSlider ref={zoomPanHandlerRef} instance={reactFlowInstance} />
          <ActionButton
            {...testId('workflow-actionsbar-fit-to-workflow')()}
            icon="Fit-to-workflow-icon"
            tooltip={p('fit_to_workflow')}
            onClick={onFitView}
            size={26}
            iconSize={16}
            style={{ marginLeft: '16px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-full-screen')()}
            icon={isFullScreen ? 'Close-full-screen-icon' : 'Open-full-screen-icon'}
            tooltip={isFullScreen ? p('quit_full_screen') : p('full_screen')}
            onClick={onFullScreen}
            size={26}
            iconSize={16}
            style={{ marginLeft: '16px', background: '#D3DBE7' }}
          />
        </Row>
        </OnboardingHighlight>
      </WithOnboarding>
      <WithOnboarding placement="bottom" enabled={onboarding.enabled(10)} text={p('actions_selected')} next onboardKey="workflows" getPopupContainer={t => t.parentNode.parentNode}>
        <OnboardingHighlight on={onboarding.enabled(10)}>
        <Row style={{ marginRight: 18, marginLeft: 13 }}>
          <ActionButton
            {...testId('workflow-actionsbar-undo')()}
            icon="Step-back-icon"
            tooltip={p('undo') + ' ⌘Z'}
            onClick={onUndo}
            disabled={!canUndo}
            size={26}
            iconSize={16}
            style={{ marginRight: '8px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-redo')()}
            icon="Step-forward-icon"
            tooltip={p('redo') + ' ⌘⇧Z'}
            onClick={onRedo}
            disabled={!canRedo}
            size={26}
            iconSize={16}
            style={{ marginRight: '16px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-minimize-maximize')()}
            icon={minimizeStatus === 'maximize' ? 'Maximize-branch' : 'Minimize-branch'}
            tooltip={minimizeStatus === 'maximize' ? p('maximize') : p('minimize')}
            disabled={minimizeStatus === 'disabled'}
            onClick={onMinimize}
            size={26}
            iconSize={16}
            style={{ marginRight: '8px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-duplicate')()}
            icon="Duplicate"
            tooltip={p('copy') + ' ⌘C'}
            onClick={onCopy}
            disabled={!canCopy}
            size={26}
            iconSize={16}
            style={{ marginRight: '8px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-paste')()}
            icon="Paste"
            tooltip={p('paste') + ' ⌘V'}
            onClick={onPaste}
            size={26}
            iconSize={16}
            style={{ marginRight: '8px', background: '#D3DBE7' }}
          />
          <ActionButton
            {...testId('workflow-actionsbar-delete')()}
            icon="Delete-icon"
            tooltip={p('delete')}
            appearance="danger"
            disabled={!canDelete}
            onClick={onDelete}
            size={26}
            iconSize={16}
            style={{ marginRight: '16px', background: 'linear-gradient(180deg,#EFD5D1 13.54%,#F2C0B9 100%)' }}
          />
          <HotkeysDropdown
            config={Platform.select({
              macos: [
                {
                  label: p('move_canvas'),
                  keys: ['Click', 'drag'],
                },
                {
                  label: p('copy'),
                  keys: ['command', 'c'],
                },
                {
                  label: p('paste'),
                  keys: ['command', 'v'],
                },
                {
                  label: p('cut'),
                  keys: ['command', 'x'],
                },
                {
                  label: p('undo'),
                  keys: ['command', 'z'],
                },
                {
                  label: p('redo'),
                  keys: ['command', 'shift', 'z'],
                },
              ],
              fallback: [
                {
                  label: p('move_canvas'),
                  keys: ['Click', 'drag'],
                },
                {
                  label: p('copy'),
                  keys: ['control', 'c'],
                },
                {
                  label: p('paste'),
                  keys: ['control', 'v'],
                },
                {
                  label: p('cut'),
                  keys: ['control', 'x'],
                },
                {
                  label: p('undo'),
                  keys: ['control', 'z'],
                },
                {
                  label: p('redo'),
                  keys: ['control', 'shift', 'z'],
                },
              ]
            })}
          >
            <ActionButton
              {...testId('workflow-actionsbar-info')()}
              icon="Info-icon"
              style={{ background: '#D3DBE7' }}
              onClick={() => {}}
              size={26}
              iconSize={16}
            />
          </HotkeysDropdown>
        </Row>
        </OnboardingHighlight>
      </WithOnboarding>
    </Container>
  );
};

export default ActionsBar;
