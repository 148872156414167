import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: false,
    ns: ['translation', 'errors'],
    defaultNS: ['translation', 'errors'],
    // lng: 'en-US',
    load: 'currentOnly',
    interpolation: {escapeValue: false},
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    }
  });

export default i18n;
