import React from 'react';

import { Container, Overlay, PerPageOption } from './styled';
import { Icon } from '@components';
import { Dropdown } from 'antd';
import { zeroIfNaN } from '@utils';

const PER_PAGE_OPTIONS = [
  10,
  20,
  50,
  100,
];

const HeaderPagination = ({ page, disablePerPageOptions, onPageChange, perPage, onPerPageChange, style = {}, total, loading, testId, ...props }) => {
  const f = zeroIfNaN((page * perPage) > total ? ((total / perPage).toFixed(0) - 1) * perPage : page * perPage);
  const s = f + perPage > total ? (total || 0) : f + perPage;

  const leftDisabled = zeroIfNaN(page) === 0;
  const rightDisabled = (zeroIfNaN(page) + 1) * perPage >= total;

  const handleLeftClick = () => {
    if (leftDisabled || loading) {
      return;
    }

    onPageChange(zeroIfNaN((page) - 1));
  };

  const handleRightClick = () => {
    if (rightDisabled || loading) {
      return;
    }

    onPageChange(zeroIfNaN(page) + 1);
  };

  const handleOptionClick = (o) => () => {
    const maxPage = (total / perPage).toFixed(0) - 1;
    if (page > perPage) {
      onPageChange(maxPage);
    }

    onPerPageChange(o);
  };

  return (
    <Container style={style}>
      <div key={`left-${page}`} onClick={handleLeftClick} >
        <Icon
          data-testid={'pagination-arrow-left'}
          color={leftDisabled ? '#5FC1F988' : '#109DFB'}
          name="Back-arrow"
          size={14}
        />
      </div>
      <Dropdown
        disabled={disablePerPageOptions}
        overlay={(
          <Overlay>
            {PER_PAGE_OPTIONS.map(o => (
              <PerPageOption data-testid={`pagination-per-page-option-${o}`} key={o} onClick={handleOptionClick(o)}>
                {o}
              </PerPageOption>
            ))}
          </Overlay>
        )}
        {...props}
      >
        <div data-testid={'pagination-per-page-count-dropdown'}>
          {f + 1} - {s}
        </div>
      </Dropdown>
      <div key={`right-${page}`} onClick={handleRightClick}>
        <Icon
          data-testid={'pagination-arrow-right'}
          color={'#109DFB'}
          style={{ transform: 'rotate(180deg)', marginRight: '12px', opacity: rightDisabled ? '.5' : '1' }}
          name="Back-arrow"
          size={14}
        />
      </div>
      {!!total && (<>of {zeroIfNaN(total)?.toLocaleString?.('ru-RU')}</>)}
    </Container>
  );
};

export default HeaderPagination;
