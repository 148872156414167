import styled, { css } from 'styled-components';
import { switchStyle } from '@utils';

export const Container = styled.div`
  margin-top: 5px;
  display: flex;
  position: relative;
  ${switchStyle('switchStyle', {
    hidden: `
      height: calc(100vh - 40px);
    `,
    create: `
         height: calc(100vh - 100px);
      `,
    edit: `
         height: calc(100vh - 146px);
      `,
    preview: css`
      //height: auto;
    `
  })}
  flex: 1;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Editor = styled.div`
  width: 100%;
  flex: 1;
  height: calc(100vh - 146px);
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  pointer-events: none;
`;
