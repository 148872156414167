import React from 'react';

export default (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1C0 0.447715 0.447715 0 1 0H48.4531C49.0054 0 49.4531 0.447715 49.4531 1V48.4531C49.4531 49.0054 49.0054 49.4531 48.4531 49.4531H1C0.447715 49.4531 0 49.0054 0 48.4531V1Z" fill="#F7F8FB"/>
    <path d="M70.5469 71.5469C70.5469 70.9946 70.9946 70.5469 71.5469 70.5469H119C119.552 70.5469 120 70.9946 120 71.5469V119C120 119.552 119.552 120 119 120H71.5469C70.9946 120 70.5469 119.552 70.5469 119V71.5469Z" fill="#F7F8FB"/>
    <path d="M63.5156 24.7266C63.5156 23.5383 63.5834 22.3657 63.7113 21.2109H56.4844V28.2422H63.7113C63.5834 27.0874 63.5156 25.9148 63.5156 24.7266Z" fill="#F7F8FB"/>
    <path d="M63.5156 97.2656C63.5156 96.0773 63.5834 94.9048 63.7113 93.75H56.4844V100.781H63.7113C63.5834 99.6265 63.5156 98.4539 63.5156 97.2656Z" fill="#F7F8FB"/>
    <path d="M28.2422 63.7111V56.4844H21.2109V63.7111C22.3657 63.5834 23.5383 63.5156 24.7266 63.5156C25.9148 63.5156 27.0874 63.5834 28.2422 63.7111Z" fill="#F7F8FB"/>
    <path d="M91.7578 56.2891V63.5159H98.7891V56.2891C97.6343 56.4168 96.4617 56.4845 95.2734 56.4845C94.0852 56.4845 92.9126 56.4168 91.7578 56.2891Z" fill="#F7F8FB"/>
    <path d="M24.7266 120C38.3827 120 49.4531 108.93 49.4531 95.2734C49.4531 81.6173 38.3827 70.5469 24.7266 70.5469C11.0705 70.5469 0 81.6173 0 95.2734C0 108.93 11.0705 120 24.7266 120Z" fill="#F7F8FB"/>
    <path d="M95.2734 49.4531C108.93 49.4531 120 38.3827 120 24.7266C120 11.0705 108.93 0 95.2734 0C81.6173 0 70.5469 11.0705 70.5469 24.7266C70.5469 38.3827 81.6173 49.4531 95.2734 49.4531Z" fill="#F7F8FB"/>
  </svg>
);
