import React, { useEffect, useState } from 'react';

import { Container, Row } from './styled';
import { IconButton, Input, Switch, Textarea } from '@components';
import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';
import {v4 as uuidv4} from "uuid";
import { ABPercentageIndicator } from './components';
import { NodeSelect } from '../../../NodeSelect';
import { diffFields, testId, zeroIfNaN } from '@utils';
import { useTranslation } from '@hooks';

const ABTestingNodeSettings = ({
  value,
  styles,
  onStylesChange,
  label,
  description,
  onChange,
  onLabelChange,
  onDescriptionChange,
  errors,
  compareWithValue,
  editable = true
}) => {
  const { t, p } = useTranslation('workflow_page')
  const [addNodes, setAddNodes] = useState(!!value.nextNodes);
  const [indicateInvalid, setIndicateInvalid] = useState(false);

  useEffect(() => {
    const sum = (styles?.segments || []).reduce((acc, { value: v }) => acc + v, 0);
    if (errors?.segments && sum !== 100) {
      setIndicateInvalid(true);
    } else {
      setIndicateInvalid(false);
    }
  }, [errors, styles?.segments]);

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleAddSegment = () => {
    onStylesChange(s => ({ ...s, segments: (s?.segments || []).concat({ id: uuidv4(), label: 'Example', value: 0 }) }));
  };

  const handleRemoveSegment = (id) => () => {
    onStylesChange(s => ({
      ...s,
      segments: (s?.segments || []).filter(ss => ss.id !== id),
    }));
  };

  const handleSegmentChange = id => ({ target: { value }}) => {
    onStylesChange(s => ({ ...s, segments: (s?.segments || []).map(ss => ss.id === id ? { ...ss, value: zeroIfNaN(+value) } : ss) }));
  };

  const handleSegmentNameChange = (id) => ({ target: { value } }) => {
    onStylesChange(s => ({ ...s, segments: (s?.segments || []).map(ss => ss.id === id ? { ...ss, label: value } : ss) }));
  };

  const handleNextNodesChange = (nextNodes, nextNodesType) => {
    onStylesChange(s => ({ ...s, nextNodes, nextNodesType }));
  };

  const segments = styles?.segments || [];
  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            {segments.map(({ id, label, value }, index) => (
              <Row key={id} style={{ alignItems: 'flex-end' }}>
                <Input
                  {...testId('ab-testing-required-settings-percentage-value')()}
                  disabled={!editable}
                  style={{ width: '90px' }}
                  title={t('labels.value_percentage')}
                  type="number"
                  value={value}
                  min={0}
                  onChange={handleSegmentChange(id)}
                />
                <Input
                  {...testId('ab-testing-required-settings-name')()}
                  disabled={!editable}
                  style={{ width: '100%' }}
                  wrapperStyles={{ width: '100%', marginLeft: '10px' }}
                  title={t('labels.name')}
                  maxLength={40}
                  value={label}
                  onChange={handleSegmentNameChange(id)}
                />
                <div style={{ marginBottom: '16px', marginLeft: '10px' }}>
                  <IconButton
                    {...testId('ab-testing-required-settings-remove-segment')()}
                    disabled={index < 2 || !editable}
                    appearance="danger"
                    onClick={handleRemoveSegment(id)}
                    icon="Delete-icon"
                  />
                </div>
              </Row>
            ))}
            <IconButton
              {...testId('ab-testing-required-settings-add-segment')()}
              disabled={!editable}
              onClick={handleAddSegment}
              appearance="secondary"
              icon="Plus-icon"
            />
            <ABPercentageIndicator invalid={indicateInvalid} segments={styles.segments || []} />
            <Switch
              {...testId('ab-testing-required-settings-auto-add-nodes')()}
              disabled={!editable}
              title={p('add_next_nodes')}
              checked={addNodes}
              onChange={setAddNodes}
              style={{ marginTop: '28px' }}
            />
            {addNodes && (
              <NodeSelect {...testId('ab-testing-required-settings-select-next-nodes')()} disabled={!editable} value={styles.nextNodes} onChange={handleNextNodesChange} />
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('ab-testing-description-settings-name')()}
              highlight={diff['label'] === false}
              disabled={!editable}
              value={label}
              error={errors['label']}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
              maxLength={40}
            />
            <Textarea
              {...testId('ab-testing-description-settings-description')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default ABTestingNodeSettings;
