import styled from 'styled-components';

export const Container = styled.div``;

export const NextButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 26px;
  border: 1px solid #109DFB;
  border-radius: 7px;
  background: transparent;
  color: #109DFB;
  line-height: 14px;
  font-size: 12px;
  //padding: 6px 29px;
  margin-top: 10px;
  cursor: pointer;
  white-space: nowrap;
`;

export const GuideLink = styled.a`
  margin-left: 5px;
  color: #303133;
  text-decoration: underline !important;
  
  &:hover {
    color: #303133 !important;
    opacity: .8;
  }
`;


