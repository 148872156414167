import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Hint = styled.div`
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  
  & > span {
    word-break: break-all;
  }
  
  margin-bottom: 60px;
`;

export const ResendLink = styled.div`
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  font-family: Rubik-SemiBold;
  letter-spacing: 0.01em;
  color: #7C8C9E;
  cursor: pointer;
  border-bottom: 1px solid #7C8C9E;
  padding-bottom: 4px;
  margin-top: 42px;
  margin-bottom: 0px;
  
  ${styledProp('enabled', css``, css`
    opacity: 0;
  `)}
`;
