import React from 'react';
import { Tooltip } from 'antd';

import { StyledButton, ErrorContainer, FallbackIconContainer } from './styled';
import { useTranslation } from '@hooks';
import {Icon} from "@components";

const ToggleButton = ({ opened, onToggle, type, children, fallback, testId = "", error, ...props }) => {
  const { e, p } = useTranslation('templates');
  return (
    <>
      <StyledButton
        $opened={opened}
        $error={error}
        data-testid={testId}
        width="auto"
        fallback={fallback}
        onClick={onToggle}
        {...props}
      >
        {children}
        {error && (
          <ErrorContainer>
            <Tooltip title={e(error)} placement="bottom">
              <svg style={{ marginLeft: '6px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="#C8402F"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
              </svg>
            </Tooltip>
          </ErrorContainer>
        )}
      </StyledButton>
      {fallback && (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
          <Tooltip title={p('set_up_fallback')}>
            <FallbackIconContainer><Icon size={10} name="info-symbol-1" color="#000000" /></FallbackIconContainer>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ToggleButton;
