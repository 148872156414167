import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ZoomButton = styled.div`
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D3DBE7;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:first-child {
    border-radius: 7px 0px 0px 7px;
    margin-right: 2px;
  }
  
  &:last-child {
    border-radius: 0px 7px 7px 0px;
    margin-left: 2px;
  }
`;
