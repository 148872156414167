import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container  = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  padding-bottom: 16px;
`;

export const ItemTitle = styled.div`
  border-radius: 9px;
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 100%;
  color: #909399;
`;

export const Category = styled.div`
  padding: 7px 0;
  transition: all 280ms ease;
  overflow: hidden;
  
  ${styledProp('$opened', `
    max-height: 600px;
  `, `
    max-height: 28px;
  `)}
`;

export const CategoryName = styled.div`
  font-size: 14px;
  line-height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  transition: all 280ms ease;
  
  ${styledProp('selected', `
    color: #109DFB;
  `, `
    color: #303133;
  `)}
`;

export const Count = styled.div`
  font-size: 10px;
  transform: translateY(-4px);
`;

export const DropdownIcon = styled.div`
  align-self: center;
  margin-left: 6px;
  transition: all 280ms ease;

  ${styledProp('selected', `
    transform: rotate(180deg);
  `, `
    transform: rotate(0deg);
  `)}
`;

export const Option = styled.div`
  font-size: 14px;
  line-height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  padding-top: 14px;
  padding-left: 20px;

  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}

  ${styledProp('selected', `
    color: #109DFB;
  `, `
    color: #303133;
  `)}
`;
