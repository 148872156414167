import { AccountActionTypes, UserActionTypes } from '@store/actions/types';

const initialState = {
  subscription: {
    loading: false,
  },
  customer: {
    loading: false,
  },
  product: {
    loading: false,
  },
  paymentMethod: {
    loading: false,
  },
  quantityLog: {
    loading: false,
    data: [],
  },
  appsContactsCount: {
    loading: false,
  },
  regularInvoices: {
    loading: false,
  },
  metrics: {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case AccountActionTypes.GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload,
          loading: false,
        },
      };
    case AccountActionTypes.GET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscription: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_PRODUCT:
      return {
        ...state,
        product: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
          loading: false,
        },
      };
    case AccountActionTypes.GET_PRODUCT_ERROR:
      return {
        ...state,
        product: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          ...action.payload,
          loading: false,
        },
      };
    case AccountActionTypes.GET_DEFAULT_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        paymentMethod: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_CUSTOMER:
      return {
        ...state,
        customer: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
          loading: false,
        },
      };
    case AccountActionTypes.GET_CUSTOMER_ERROR:
      return {
        ...state,
        customer: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_QUANTITY_LOG:
      return {
        ...state,
        quantityLog: {
          loading: true,
          data: [],
        },
      };
    case AccountActionTypes.GET_QUANTITY_LOG_SUCCESS:
      return {
        ...state,
        quantityLog: {
          loading: false,
          data: action.payload,
        },
      };
    case AccountActionTypes.GET_QUANTITY_LOG_ERROR:
      return {
        ...state,
        quantityLog: {
          loading: false,
          data: [],
        },
      };
    case AccountActionTypes.GET_APPS_CONTACTS_COUNT:
      return {
        ...state,
        appsContactsCount: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_APPS_CONTACTS_COUNT_SUCCESS:
      return {
        ...state,
        appsContactsCount: {
          loading: false,
          ...action.payload,
        },
      };
    case AccountActionTypes.GET_APPS_CONTACTS_COUNT_ERROR:
      return {
        ...state,
        appsContactsCount: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_REGULAR_INVOICES:
      return {
        ...state,
        regularInvoices: {
          loading: true,
        },
      };
    case AccountActionTypes.GET_REGULAR_INVOICES_SUCCESS:
      return {
        ...state,
        regularInvoices: {
          loading: false,
          data: action.payload,
        },
      };
    case AccountActionTypes.GET_REGULAR_INVOICES_ERROR:
      return {
        ...state,
        regularInvoices: {
          loading: false,
        },
      };
    case AccountActionTypes.GET_ACCOUNT_METRICS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [action.meta.entity]: {
            ...(state.metrics[action.meta.entity] || {}),
            loading: true,
          },
        },
      };
    case AccountActionTypes.GET_ACCOUNT_METRICS_SUCCESS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [action.meta.entity]: {
            ...(state.metrics[action.meta.entity] || {}),
            ...action.payload,
            loading: false,
          },
        },
      };
    case AccountActionTypes.GET_ACCOUNT_METRICS_ERROR:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [action.meta.entity]: {
            ...(state.metrics[action.meta.entity] || {}),
            loading: false,
          },
        },
      };
    case AccountActionTypes.GET_SENDING_LIMIT_METRICS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          sending_limit: {
            ...(state.metrics.sending_limit || {}),
            loading: true,
          },
        },
      };
    case AccountActionTypes.GET_SENDING_LIMIT_METRICS_SUCCESS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          sending_limit: {
            ...(state.metrics.sending_limit || {}),
            ...action.payload,
            loading: false,
          },
        },
      };
    case AccountActionTypes.GET_SENDING_LIMIT_METRICS_ERROR:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          sending_limit: {
            ...(state.metrics.sending_limit || {}),
            loading: false,
          },
        },
      };
    case UserActionTypes.LOGOUT:
      return {
        ...initialState,
      };
    default: {
      return {
        ...state
      };
    }
  }
};

export default accountReducer;
