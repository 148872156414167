import React from 'react';

import CheckboxGroupContext from './CheckboxGroupContext';

const CheckboxGroup = ({ value, onChange, children }) => {

  const handleChange = (name, checked) => {

    if (checked) {
      onChange(value.concat(name));
      return;
    }

    onChange(value.filter(v => v !== name));
  };

  return (
    <CheckboxGroupContext.Provider
      value={{ value, onChange: handleChange }}
    >
      {children}
    </CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroup;
