import React from 'react';

const PartyIcon = () => {
  return (
    <svg style={{ overflow: 'visible' }} width="273" height="273" viewBox="0 0 273 273" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1382_47208)">
        <g opacity="0.4" filter="url(#filter0_f_1382_47208)">
          <circle cx="133.5" cy="137.5" r="125.5" fill="url(#paint0_radial_1382_47208)"/>
        </g>
        <path d="M78.7602 212.683L194.367 159.082L111.761 76.4767L58.1601 192.032C57.3914 193.877 54.4705 201.307 62.4133 208.379C71.0223 216.014 76.9154 213.452 78.7602 212.683Z" fill="url(#paint1_linear_1382_47208)"/>
        <path d="M100.182 101.433L91.5731 120.137C88.8059 126.85 137.898 176.403 165.621 172.406L194.369 159.083C160.291 174.814 95.9801 110.555 100.182 101.433Z" fill="url(#paint2_linear_1382_47208)"/>
        <path d="M82.7589 139.149L80.7604 143.402L73.9962 157.955C73.9449 163.695 103.513 193.211 122.012 192.596L145.482 181.732C120.064 186.139 79.838 145.349 82.7589 139.149Z" fill="url(#paint3_linear_1382_47208)"/>
        <path d="M58.7227 190.904C61.1824 195.824 73.2247 207.815 83.2173 210.633L108.019 199.155L108.173 199.052C92.9024 206.073 67.0754 180.041 66.6142 173.994L58.7227 190.904Z" fill="url(#paint4_linear_1382_47208)"/>
        <path opacity="0.75" d="M78.7602 212.683L194.367 159.082L111.761 76.4767L58.1601 192.032C57.3914 193.877 54.4705 201.307 62.4133 208.379C71.0223 216.014 76.9154 213.452 78.7602 212.683Z" fill="url(#paint5_radial_1382_47208)"/>
        <path opacity="0.75" d="M78.7602 212.683L194.367 159.082L111.761 76.4767L58.1601 192.032C57.3914 193.877 54.4705 201.307 62.4133 208.379C71.0223 216.014 76.9154 213.452 78.7602 212.683Z" fill="url(#paint6_radial_1382_47208)"/>
        <path d="M151.27 76.8867C149.886 79.7051 147.17 80.1151 145.172 79.5002L148.451 72.9922C148.451 72.9922 152.141 73.8121 152.244 71.4036L152.397 74.7857L151.27 76.8867Z" fill="url(#paint7_linear_1382_47208)"/>
        <path d="M160.444 41.016C160.444 41.016 162.801 39.9398 164.287 36.8652C165.773 33.7906 158.445 28 158.445 28L155.012 33.9956C155.012 33.9956 160.802 37.7876 159.009 39.0687C157.164 40.3498 160.444 41.016 160.444 41.016Z" fill="url(#paint8_linear_1382_47208)"/>
        <path d="M220.4 90.3124C221.476 80.8835 215.122 77.7576 212.816 78.0139C212.816 78.0139 212.406 74.9392 216.506 73.9656C219.222 73.3507 226.14 79.4999 224.346 86.4691L220.4 90.3124Z" fill="url(#paint9_linear_1382_47208)"/>
        <path d="M195.34 158.365C189.601 164.104 166.438 150.166 143.532 127.311C120.678 104.456 106.739 81.2425 112.479 75.5032C118.218 69.7639 141.38 83.7022 164.286 106.557C187.141 129.463 201.028 152.625 195.34 158.365Z" fill="url(#paint10_linear_1382_47208)"/>
        <path d="M193.395 154.572C189.039 158.927 168.234 145.194 146.968 123.877C125.702 102.559 111.917 81.8053 116.273 77.4495C120.628 73.0938 141.433 86.8272 162.7 108.145C183.966 129.462 197.751 150.216 193.395 154.572Z" fill="url(#paint11_linear_1382_47208)"/>
        <path d="M164.234 36.8655C164.234 36.8655 161.569 42.5536 161.21 43.1685C159.878 45.4232 156.137 46.1919 149.424 45.2695C142.711 44.3471 140.764 44.7058 140.456 44.7571C140.149 44.8596 136.203 41.6824 136.203 41.6824L136.562 38.454C136.562 38.454 137.484 37.2242 140.559 37.6341C146.042 38.454 162.184 41.17 164.234 36.8655Z" fill="url(#paint12_linear_1382_47208)"/>
        <path d="M177.56 132.025C182.94 125.876 187.962 130.693 191.139 126.286L185.708 120.751C182.531 125.107 177.919 121.007 172.23 127.003L177.56 132.025Z" fill="url(#paint13_linear_1382_47208)"/>
        <path d="M119.191 57.4147C126.622 61.8217 120.011 67.8685 124.213 71.3018L130.004 66.1262C125.802 62.6928 131.849 56.7485 124.418 52.3416L119.191 57.4147Z" fill="url(#paint14_linear_1382_47208)"/>
        <path d="M197.849 80.2183C198.054 80.1671 198.157 78.9373 201.18 76.785C204.203 74.6328 211.019 77.9636 211.89 78.4761C212.812 78.9885 212.044 87.4438 212.044 87.4438C212.044 87.4438 203.537 78.3736 199.284 80.4746C195.031 82.5243 197.849 80.2183 197.849 80.2183Z" fill="url(#paint15_linear_1382_47208)"/>
        <path d="M198.98 101.28C198.98 101.28 198.672 104.611 197.135 106.148C194.368 108.864 189.961 104.765 184.375 106.251C184.375 106.251 186.937 99.7427 187.193 99.2303C187.193 99.2303 189.448 97.8467 191.754 99.179C194.111 100.46 196.161 100.87 197.596 100.101C199.031 99.3328 198.98 101.28 198.98 101.28Z" fill="url(#paint16_linear_1382_47208)"/>
        <path d="M213.995 86.6231C213.995 86.6231 208.512 89.1341 207.794 89.4928C207.077 89.8515 205.437 77.0918 209.383 75.8107C213.38 74.5296 216.096 74.0684 216.506 74.0171C216.916 73.9146 209.639 74.6833 213.995 86.6231Z" fill="#FFBF00"/>
        <path d="M197.132 106.095C199.387 103.738 202.461 100.254 202.308 99.28C201.59 93.0283 196.517 79.2436 201.18 76.7327C201.18 76.7327 196.824 79.8073 195.492 80.7297C194.16 81.6521 193.186 84.1118 193.289 88.1601C193.545 95.2317 199.182 103.994 197.132 106.095Z" fill="#FFBF00"/>
        <path d="M181.554 55.5178C177.814 81.806 151.73 106.916 151.73 106.916L155.266 112.296C155.266 112.296 183.655 82.7284 187.294 56.3377L181.554 55.5178Z" fill="url(#paint17_linear_1382_47208)"/>
        <path d="M133.438 44.5517C132.823 45.679 134.616 47.575 138.921 52.8019C143.225 58.0288 154.397 71.8647 151.22 76.8866L154.653 69.8149C154.653 69.8149 154.704 64.1781 151.322 58.695C145.993 50.086 135.795 40.8109 136.564 38.5049L133.438 44.5517Z" fill="url(#paint18_linear_1382_47208)"/>
        <path d="M156.292 124.544C157.453 124.544 158.393 123.603 158.393 122.442C158.393 121.282 157.453 120.341 156.292 120.341C155.132 120.341 154.191 121.282 154.191 122.442C154.191 123.603 155.132 124.544 156.292 124.544Z" fill="url(#paint19_linear_1382_47208)"/>
        <path d="M185.554 37.224C186.714 37.224 187.655 36.2833 187.655 35.123C187.655 33.9626 186.714 33.022 185.554 33.022C184.394 33.022 183.453 33.9626 183.453 35.123C183.453 36.2833 184.394 37.224 185.554 37.224Z" fill="url(#paint20_linear_1382_47208)"/>
        <path d="M165.824 67.7144C166.984 67.7144 167.925 66.7738 167.925 65.6134C167.925 64.4531 166.984 63.5125 165.824 63.5125C164.663 63.5125 163.723 64.4531 163.723 65.6134C163.723 66.7738 164.663 67.7144 165.824 67.7144Z" fill="url(#paint21_linear_1382_47208)"/>
        <path d="M178.277 106.198C179.437 106.198 180.378 105.258 180.378 104.097C180.378 102.937 179.437 101.996 178.277 101.996C177.116 101.996 176.176 102.937 176.176 104.097C176.176 105.258 177.116 106.198 178.277 106.198Z" fill="url(#paint22_linear_1382_47208)"/>
        <path d="M232.289 101.074C233.449 101.074 234.389 100.133 234.389 98.9731C234.389 97.8127 233.449 96.8721 232.289 96.8721C231.128 96.8721 230.188 97.8127 230.188 98.9731C230.188 100.133 231.128 101.074 232.289 101.074Z" fill="url(#paint23_linear_1382_47208)"/>
        <path d="M218.914 145.4C220.074 145.4 221.015 144.459 221.015 143.299C221.015 142.138 220.074 141.198 218.914 141.198C217.753 141.198 216.812 142.138 216.812 143.299C216.812 144.459 217.753 145.4 218.914 145.4Z" fill="url(#paint24_linear_1382_47208)"/>
        <path d="M210.148 118.343C211.308 118.343 212.249 117.402 212.249 116.242C212.249 115.082 211.308 114.141 210.148 114.141C208.988 114.141 208.047 115.082 208.047 116.242C208.047 117.402 208.988 118.343 210.148 118.343Z" fill="url(#paint25_linear_1382_47208)"/>
        <path d="M221.679 61.2564C222.839 61.2564 223.78 60.3158 223.78 59.1554C223.78 57.9951 222.839 57.0544 221.679 57.0544C220.519 57.0544 219.578 57.9951 219.578 59.1554C219.578 60.3158 220.519 61.2564 221.679 61.2564Z" fill="url(#paint26_linear_1382_47208)"/>
        <path d="M198.109 68.1234C199.269 68.1234 200.21 67.1828 200.21 66.0224C200.21 64.8621 199.269 63.9214 198.109 63.9214C196.948 63.9214 196.008 64.8621 196.008 66.0224C196.008 67.1828 196.948 68.1234 198.109 68.1234Z" fill="url(#paint27_linear_1382_47208)"/>
        <path d="M180.12 151.139C201.54 133.562 237.821 129.924 237.821 129.924L238.999 121.827C238.999 121.827 196.057 126.952 173.305 146.681C175.764 148.372 178.019 149.858 180.12 151.139Z" fill="url(#paint28_linear_1382_47208)"/>
        <path d="M126.367 100.511C127.802 102.458 129.391 104.405 131.082 106.455C135.642 96.4113 134.669 88.5197 134.669 88.5197L129.083 85.0352C129.647 91.2357 127.751 97.18 126.367 100.511Z" fill="url(#paint29_linear_1382_47208)"/>
      </g>
      <defs>
        <filter id="filter0_f_1382_47208" x="-66" y="-62" width="399" height="399" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="37" result="effect1_foregroundBlur_1382_47208"/>
        </filter>
        <radialGradient id="paint0_radial_1382_47208" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.5 137.5) rotate(90) scale(100.5)">
          <stop stopColor="#5A56FD"/>
          <stop offset="0.46875" stopColor="#5B57FF" stopOpacity="0.54"/>
          <stop offset="0.921875" stopColor="#1F1F24"/>
        </radialGradient>
        <linearGradient id="paint1_linear_1382_47208" x1="51.376" y1="92.6201" x2="142.834" y2="201.003" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#FBE07A"/>
          <stop offset="1" stopColor="#FF40BF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1382_47208" x1="101.082" y1="100.553" x2="176.98" y2="176.45" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#9F00E8"/>
          <stop offset="1" stopColor="#7000A3"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1382_47208" x1="80.0449" y1="141.872" x2="132.683" y2="194.51" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#9F00E8"/>
          <stop offset="1" stopColor="#7000A3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1382_47208" x1="64.1304" y1="176.407" x2="97.4426" y2="209.719" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#9F00E8"/>
          <stop offset="1" stopColor="#7000A3"/>
        </linearGradient>
        <radialGradient id="paint5_radial_1382_47208" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(100.934 128.886) rotate(119.598) scale(52.5041 16.074)">
          <stop stopColor="white" stopOpacity="0.5"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint6_radial_1382_47208" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(137.829 175.952) rotate(151.7) scale(83.229 25.9582)">
          <stop stopColor="#440063" stopOpacity="0.75"/>
          <stop offset="1" stopColor="#420061" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint7_linear_1382_47208" x1="151.658" y1="74.9726" x2="164.236" y2="70.9555" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD2719"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint8_linear_1382_47208" x1="163.354" y1="43.7626" x2="157.722" y2="29.7712" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD2719"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint9_linear_1382_47208" x1="225.993" y1="86.4942" x2="205.016" y2="73.0533" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint10_linear_1382_47208" x1="172.246" y1="59.0823" x2="125.378" y2="206.848" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#FBE07A"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint11_linear_1382_47208" x1="187.635" y1="93.0011" x2="115.706" y2="143.45" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBE07A"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint12_linear_1382_47208" x1="129.733" y1="41.7282" x2="152.964" y2="41.3763" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD2719"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint13_linear_1382_47208" x1="184.422" y1="121.841" x2="178.836" y2="131.865" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9214"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint14_linear_1382_47208" x1="119.158" y1="44.7893" x2="127.606" y2="68.9589" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80DAFE"/>
          <stop offset="1" stopColor="#008EE6"/>
        </linearGradient>
        <linearGradient id="paint15_linear_1382_47208" x1="205.484" y1="91.756" x2="203.55" y2="67.3749" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint16_linear_1382_47208" x1="185.011" y1="102.033" x2="198.482" y2="104.215" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint17_linear_1382_47208" x1="175.75" y1="56.4638" x2="155.766" y2="138.706" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#E100FF"/>
          <stop offset="1" stopColor="#7000A3"/>
        </linearGradient>
        <linearGradient id="paint18_linear_1382_47208" x1="136.446" y1="42.9877" x2="151.23" y2="67.6266" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9214"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint19_linear_1382_47208" x1="152.274" y1="110.617" x2="160.252" y2="134.083" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint20_linear_1382_47208" x1="179.144" y1="24.615" x2="192.285" y2="46.2034" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBE07A"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint21_linear_1382_47208" x1="165.51" y1="64.4508" x2="168.795" y2="76.8875" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80DAFE"/>
          <stop offset="1" stopColor="#008EE6"/>
        </linearGradient>
        <linearGradient id="paint22_linear_1382_47208" x1="176.861" y1="100.108" x2="178.866" y2="105.844" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80DAFE"/>
          <stop offset="1" stopColor="#008EE6"/>
        </linearGradient>
        <linearGradient id="paint23_linear_1382_47208" x1="233.813" y1="94.0683" x2="230.058" y2="106.036" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint24_linear_1382_47208" x1="218.294" y1="129.874" x2="219.468" y2="155.452" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCB4B"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <linearGradient id="paint25_linear_1382_47208" x1="208.771" y1="112.272" x2="210.776" y2="118.008" gradientUnits="userSpaceOnUse">
          <stop offset="0.2544" stopColor="#9F00E8"/>
          <stop offset="1" stopColor="#7000A3"/>
        </linearGradient>
        <linearGradient id="paint26_linear_1382_47208" x1="225.758" y1="52.154" x2="218.014" y2="65.5294" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80DAFE"/>
          <stop offset="1" stopColor="#008EE6"/>
        </linearGradient>
        <linearGradient id="paint27_linear_1382_47208" x1="197.567" y1="63.0413" x2="200.148" y2="77.3553" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9214"/>
          <stop offset="1" stopColor="#FF4E0D"/>
        </linearGradient>
        <linearGradient id="paint28_linear_1382_47208" x1="158.783" y1="135.421" x2="233.599" y2="136.959" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80DAFE"/>
          <stop offset="1" stopColor="#008EE6"/>
        </linearGradient>
        <linearGradient id="paint29_linear_1382_47208" x1="117.454" y1="127.101" x2="135.389" y2="82.5188" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBE07A"/>
          <stop offset="1" stopColor="#E81CA2"/>
        </linearGradient>
        <clipPath id="clip0_1382_47208">
          <rect width="273" height="273" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PartyIcon;


