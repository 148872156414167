import React from 'react';
import { isRule } from '@utils';

import {
  Container,
  LogicalOperatorIndicator,
  RuleIndicator,
  Title,
  FormulaRow,
  Negation,
  HideButton,
  HideIcon,
  NoData,
} from './styled';
import { OrderingTree } from './components';
import { useModalState, useTranslation } from '@hooks';

const resolveOppositeOperator = op => op === 'and' ? 'or' : 'and';

const FiltersPrecedentTable = ({ query: { query, ...other }, headerHidden, onLogicalOperatorChange, onChange }) => {
  const state = useModalState();
  const { t, p } = useTranslation('segments_page');

  const renderFormula = (query) => {
    return query.children.map((q, index) => {
      if (isRule(q)) {
        return (
          <>
            {index !== 0 && (
              <LogicalOperatorIndicator data-testid={`store-segment-scheme-formula-logical-operator-${q.name}`} onClick={() => onLogicalOperatorChange(q)(resolveOppositeOperator(query.logicalOperator))}>
                {p(query.logicalOperator)}
              </LogicalOperatorIndicator>
            )}
            {q.query.negation && (
              <Negation data-testid={`store-segment-scheme-formula-negation-${q.query.name}`}>
                {p('not')}
              </Negation>
            )}
            <RuleIndicator data-testid={`store-segment-scheme-formula-name-${q.query.name}`}><span>{q.query.name}</span></RuleIndicator>
          </>
        );
      }

      return (
        <>
          {index !== 0 && (
            <LogicalOperatorIndicator onClick={() => onLogicalOperatorChange(q)(resolveOppositeOperator(query.logicalOperator))}>
              {query.logicalOperator}
            </LogicalOperatorIndicator>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <svg width="7" height="22" viewBox="0 0 7 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.05513 10.9987C2.05513 17.6616 6.02085 20.1758 6.19228 20.2787C6.36116 20.3834 6.49135 20.5402 6.56315 20.7254C6.63495 20.9107 6.64445 21.1143 6.5902 21.3054C6.53596 21.4966 6.42094 21.6648 6.26253 21.7848C6.10412 21.9047 5.91096 21.9698 5.71228 21.9701C5.5481 21.9676 5.38712 21.9244 5.24371 21.8444C5.03799 21.7187 0.226562 18.7473 0.226562 10.9987C0.226562 3.25013 5.03799 0.278697 5.24371 0.152983C5.45339 0.0309494 5.70259 -0.00389261 5.9377 0.0559534C6.17281 0.1158 6.37503 0.265551 6.50085 0.472983C6.62231 0.680919 6.65684 0.92838 6.59692 1.16162C6.53701 1.39486 6.38749 1.59505 6.18085 1.7187C5.99799 1.83298 2.05513 4.34727 2.05513 10.9987Z" fill="#109DFB"/>
            </svg>
            {renderFormula(q.query)}
            <svg width="7" height="22" viewBox="0 0 7 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.94487 10.9987C4.94487 17.6616 0.979152 20.1758 0.807723 20.2787C0.638841 20.3834 0.508649 20.5402 0.43685 20.7254C0.365051 20.9107 0.355554 21.1143 0.409796 21.3054C0.464037 21.4966 0.579065 21.6648 0.737471 21.7848C0.895877 21.9047 1.08904 21.9698 1.28772 21.9701C1.4519 21.9676 1.61288 21.9244 1.75629 21.8444C1.96201 21.7187 6.77344 18.7473 6.77344 10.9987C6.77344 3.25013 1.96201 0.278697 1.75629 0.152983C1.54661 0.0309494 1.29741 -0.00389261 1.0623 0.0559534C0.827188 0.1158 0.624968 0.265551 0.499151 0.472983C0.377686 0.680919 0.343163 0.92838 0.403078 1.16162C0.462993 1.39486 0.612508 1.59505 0.819151 1.7187C1.00201 1.83298 4.94487 4.34727 4.94487 10.9987Z" fill="#109DFB"/>
            </svg>
          </div>
        </>
      );
    })
  }

  return (
    <Container fixed={headerHidden} data-testid={'store-segment-scheme-container'} $opened={state.opened}>
      <HideButton onClick={state.toggle} $opened={state.opened}>
        <HideIcon $opened={state.opened}>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.80078 11.0998C6.80078 10.8998 6.70078 10.6998 6.60078 10.5998L3.20078 7.0998L2.30078 5.9998L3.30078 4.89981L6.60078 1.4998C6.70078 1.3998 6.80078 1.1998 6.80078 0.999805C6.80078 0.799805 6.70078 0.599805 6.60078 0.499805C6.40078 0.299805 6.30078 0.299805 6.10078 0.299805C5.90078 0.299805 5.70078 0.399805 5.60078 0.499805L0.300781 5.9998L5.60078 11.5998C5.70078 11.6998 5.80078 11.6998 5.90078 11.7998C6.00078 11.7998 6.10078 11.8998 6.20078 11.8998C6.30078 11.8998 6.40078 11.8998 6.50078 11.7998C6.60078 11.7998 6.70078 11.6998 6.80078 11.5998C6.70078 11.3998 6.80078 11.2998 6.80078 11.0998Z" fill="#109DFB"/>
          </svg>
        </HideIcon>
      </HideButton>
      <Title data-testid={'store-segment-scheme-title'} >{p('segment_scheme')}</Title>

      <div style={{ overflowY: 'auto' }}>
        {query.children.length ? (
          <>
            {/*<FormulaRow className="show-scrollbar" data-testid={'store-segment-scheme-formula-row'}>*/}
            {/*  {renderFormula(query)}*/}
            {/*</FormulaRow>*/}
            <OrderingTree onLogicalOperatorChange={onLogicalOperatorChange} query={{ query, ...other }} onChange={onChange} />
          </>
        ) : (
          <NoData>
            {p('segment_scheme_nodata')}
          </NoData>
        )}
      </div>
    </Container>
  )
}

export default FiltersPrecedentTable;
