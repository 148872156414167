import styled from 'styled-components';
import { Device } from '@res';

export const MaintenanceContainer = styled.div`
  background-color: #FFFFFF;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and ${Device.desktopS} {
    padding: 20px 260px;
  }
`;

export const Image = styled.img`
  width: 748px;
  height: 500px;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  color: #303133;
  margin-bottom: 26px;
`;

export const Paragraph = styled.div`
  font-family: Rubik-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #909399;
  max-width: 600px;
  text-align: center;
`;



