import styled, { css } from 'styled-components';
import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  background: #fff;
  //width: 220px;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  flex: 1;
  max-width: 270px;
  min-height: 100px;
  overflow: hidden;
  padding: 2px;
  position: relative;

  ${styledProp('highlight', css`
    min-height: 230px;
    width: 270px;
    flex: 2;
    border: 1px solid #BDD1F6;
    background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 71.42%, #EDF8FF 90.83%);
  `)};

  ${styledProp('medium', css`
    min-height: 150px;
  `)};

  ${switchStyle('highlight', {
    primary: css`
      border: 1px solid #BDD1F6;
      background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 71.42%, #EDF8FF 90.83%);
    `,
    secondary: css`
      //background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 71.42%, #EDF8FF 90.83%);
    `,
    footer: css`
      border-radius: 10px;
      border-image-slice: 1;
      background: linear-gradient(213.29deg, #6EDEAD 11.15%, #3BCD9A 90.5%);
    `,
  })}
`;

export const Inner = styled.div`
  padding: 18px;
  z-index: 1;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  flex: 1;
  ${switchStyle('highlight', {
    primary: css`
      background: transparent;
    `,
    secondary: css`
      background: #fff;
    `,
    footer: css`
      background: #fff;
    `,
  })}
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  
  ${styledProp('exist', css`
    font-size: 26px;
    line-height: 31px;
    color: #909399;
  `)}
  
  ${styledProp('small', `
    margin-bottom: 5px;
  `)}

  ${styledProp('medium', `
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
    color: #303133;
  `)}

  ${switchStyle('highlight', {
    primary: css`
      color: #303133;
    `,
    secondary: css`
      color: #303133;
    `,
    footer: css`
      color: #303133;
    `,
  })}
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  
  ${styledProp('start', css`
    justify-content: flex-start;
  `)}
`;

export const Action = styled.div`
  cursor: pointer;
  border-radius: 7px;
  padding: 6px 16px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
  text-align: center;
  z-index: 1;
  background: linear-gradient(180deg, #4B88FF 18%, #5A56FD 106%);
  //border: 1px solid #109DFB;

  ${styledProp('static', css`
    //width: 100%;
  `)}
  
  ${styledProp('secondary', css`
    margin-left: 5px;
    background: transparent;
    color: #109DFB;
    border: 1px solid #109DFB;
  `)}

  ${switchStyle('highlight', {
    primary: css`
      //color: #109DFB;
      //border-color: #109DFB;
      //background: transparent;
    `,
    secondary: css`
      color: #109DFB;
      margin-left: 5px;
      background: transparent;
      border: 1px solid #109DFB;
    `,
    footer: css`
      background: #109DFB;
      color: #fff;
    `,
  })}
`;

export const Text = styled.div`
  font-size: 13px;
  font-family: Rubik-Regular;
  line-height: 120%;
  margin-bottom: 20px;
  z-index: 2;
  color: #909399;

  ${switchStyle('highlight', {
    primary: css`
      color: #909399;
    `,
    secondary: css`
    `,
    footer: css`
    `,
  })}
`;

export const Image = styled.div`
  position: absolute;

  & path {
    fill: #F2F6FC;
  }
  
  ${styledProp('small', css`
    bottom: -14px;
    right: -10px;
  `, css`
    bottom: -26px;
    right: -16px;
  `)}
 

  ${switchStyle('highlight', {
    primary: css`
      & path {
        fill: #E7F3FE;
      }
    `,
    secondary: css`
      color: #303133;
    `,
    footer: css`
      color: #303133;
    `,
  })}
`;

export const InfoIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 10;

  ${styledProp('exist', css`
    display: none;
  `)}
`;



export const Position = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #303133;
  
  ${styledProp('first', css`
    background: #E5E8ED;
  `, css`
    background: #F0F2F6;
  `)}
`;
