import styled, { css } from 'styled-components';
import { switchStyle } from '@utils';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  ${switchStyle('align', {
    column: css`
      flex-direction: column;
    `,
    fallback: css`
      align-items: center;
    `
  })}
`;


export const FieldRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 6px;
`;

export const FieldLabel = styled.div`
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  margin-right: 6px;
  color: #909399;
`;

