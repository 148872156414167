import React from 'react';

import { Container, Operator, OperatorsContainer } from './styled.js';
import { SegmentsFilterOperators } from '@constants';
import { useTranslation } from '@hooks';

const LogicOperatorSelect = ({ operator, index = 0, onOperatorChange }) => {
  const { p, t } = useTranslation('segments_page');

  return (
    <Container>
      <OperatorsContainer data-testid={`segment-logic-operator-container-${index}`}>
        <Operator
          data-testid={`segment-logic-operator-and-${index}`}
          onClick={() => onOperatorChange(SegmentsFilterOperators.AND)}
          selected={operator === SegmentsFilterOperators.AND || !operator}
        >
          {p('and')}
        </Operator>
        <Operator
          data-testid={`segment-logic-operator-or-${index}`}
          onClick={() => onOperatorChange(SegmentsFilterOperators.OR)}
          selected={operator === SegmentsFilterOperators.OR}
        >
          {p('or')}
        </Operator>
      </OperatorsContainer>
    </Container>
  );
}

export default LogicOperatorSelect;


