import { compose } from '@utils';
import { asCreator, withCallbacks } from '@store/lib';
import { DashboardActionTypes, UserActionTypes } from '@store/actions/types';

export const getDashboardSmsSummary = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_SMS_SUMMARY,
    payload: { range },
  }))
);

export const getDashboardViberSummary = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_VIBER_SUMMARY,
    payload: { range },
  }))
);

export const getDashboardEmailSummary = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_EMAIL_SUMMARY,
    payload: { range },
  }))
);

export const getDashboardWebpushSummary = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_WEBPUSH_SUMMARY,
    payload: { range },
  }))
);

export const getDashboardMobilePushSummary = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_MOBILE_PUSH_SUMMARY,
    payload: { range },
  }))
);

export const getGeneralActivityHourly = compose(
  withCallbacks,
  asCreator(({ range, type, ids, id }) => ({
    type: DashboardActionTypes.GET_GENERAL_ACTIVITY_HOURLY,
    payload: { range, type, ids, id },
  }))
);

export const getGeneralActivityDaily = compose(
  withCallbacks,
  asCreator(({ range, type, ids, id }) => ({
    type: DashboardActionTypes.GET_GENERAL_ACTIVITY_DAILY,
    payload: { range, type, ids, id },
  }))
);

export const getDatabaseEfficiency = compose(
  withCallbacks,
  asCreator(({ range, type, ids, id }) => ({
    type: DashboardActionTypes.GET_DATABASE_EFFICIENCY,
    payload: { range, type, ids, id },
  }))
);

export const getDatabaseEfficiencyPart2 = compose(
  withCallbacks,
  asCreator(({ range }) => ({
    type: DashboardActionTypes.GET_DATABASE_EFFICIENCY2,
    payload: { range },
  }))
)

export const changeDashboardAnalyticsOptions = compose(
  withCallbacks,
  asCreator(({ ids, id, type, range, app_id }) => ({
    type: UserActionTypes.UPDATE_DASHBOARD_ANALYTICS_OPTIONS,
    payload: { options: { ids, id, type, range }, app_id }
  }))
);

export const getDashboardAnalyticsEntities = compose(
  withCallbacks,
  asCreator(() => ({
    type: DashboardActionTypes.GET_ANALYTICS_ENTITIES,
  }))
);
