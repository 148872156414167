export const USER_SETTING = ['edit_users', 'delete_users'];

const UserPermissions = {
  USER_MANAGEMENT: 'user-management',
  USERS: 'users',
  CREATE_USERS: 'create_users',
  REVIEW_USERS: 'review_users',
  UPDATE_USERS: 'update_users',
  SUSPEND_USERS: 'suspend_users',
  UPDATE_OWN_PROFILE: 'update-own-profile',
  TURN_ON_2FA_OWN_ACCOUNT: 'turn-on-2fa-fow-own-account',
  ROLES: 'roles',
  CREATE_ROLE: 'create_roles',
  REVIEW_ROLES: 'review_roles',
  UPDATE_ROLES: 'update_roles',
  DELETE_ROLES: 'delete_roles',
  WORKSPACES: 'workspaces',
  CREATE_WORKSPACE: 'create_workspaces',
  REVIEW_WORKSPACE: 'review_workspaces',
  UPDATE_WORKSPACE: 'update_workspaces',
  SUSPEND_WORKSPACE: 'suspend_workspaces',
  INTEGRATIONS: 'integrations',
  SMS_INTEGRATIONS: 'sms_integrations',
  CREATE_SMS_INTEGRATIONS: 'create_sms_integrations',
  REVIEW_SMS_INTEGRATIONS: 'review_sms_integrations',
  UPDATE_SMS_INTEGRATIONS: 'update_sms_integrations',
  DELETE_SMS_INTEGRATIONS: 'delete_sms_integrations',
  EMAIL_INTEGRATIONS: 'email_integrations',
  CREATE_EMAIL_INTEGRATIONS: 'create_email_integrations',
  REVIEW_EMAIL_INTEGRATIONS: 'review_email_integrations',
  UPDATE_EMAIL_INTEGRATIONS: 'update_email_integrations',
  DELETE_EMAIL_INTEGRATIONS: 'delete_email_integrations',
  MOBILE_PUSH_INTEGRATIONS: 'mobile_push_integrations',
  CREATE_MOBILE_PUSH_INTEGRATIONS: 'create_mobile_push_integrations',
  REVIEW_MOBILE_PUSH_INTEGRATIONS: 'review_mobile_push_integrations',
  UPDATE_MOBILE_PUSH_INTEGRATIONS: 'update_mobile_push_integrations',
  DELETE_MOBILE_PUSH_INTEGRATIONS: 'delete_mobile_push_integrations',
  WEB_PUSH_PUSH_INTEGRATIONS: 'web_push_integrations',
  CREATE_WEB_PUSH_INTEGRATIONS: 'create_web_push_integrations',
  REVIEW_WEB_PUSH_INTEGRATIONS: 'review_web_push_integrations',
  UPDATE_WEB_PUSH_INTEGRATIONS: 'update_web_push_integrations',
  DELETE_WEB_PUSH_INTEGRATIONS: 'delete_web_push_integrations',
  API_INTEGRATION: 'api_integration',
  JS_INTEGRATION: 'js_integration',
  DATABASE_SYNC_INTEGRATIONS: 'database_sync_integrations',
  CREATE_DATABASE_SYNC_INTEGRATIONS: 'create_database_sync_integrations',
  REVIEW_DATABASE_SYNC_INTEGRATIONS: 'review_database_sync_integrations',
  UPDATE_DATABASE_SYNC_INTEGRATIONS: 'update_database_sync_integrations',
  DELETE_DATABASE_SYNC_INTEGRATIONS: 'delete_database_sync_integrations',

  API_INTEGRATIONS: 'api_integration',
  // CREATE_API_INTEGRATIONS: 'create_database_sync_integrations',
  // REVIEW_API_INTEGRATIONS: 'review_database_sync_integrations',
  // UPDATE_API_INTEGRATIONS: 'update_database_sync_integrations',
  // DELETE_API_INTEGRATIONS: 'delete_database_sync_integrations',

  JS_INTEGRATIONS: 'js_integration',
  // CREATE_API_INTEGRATIONS: 'create_database_sync_integrations',
  // REVIEW_API_INTEGRATIONS: 'review_database_sync_integrations',
  // UPDATE_API_INTEGRATIONS: 'update_database_sync_integrations',
  // DELETE_API_INTEGRATIONS: 'delete_database_sync_integrations',

  RESOURCES: 'resources',
  SEGMENTS: 'segments',
  CREATE_SEGMENTS: 'create_segments',
  REVIEW_SEGMENTS: 'review_segments',
  UPDATE_SEGMENTS: 'update_segments',
  DELETE_SEGMENTS: 'delete_segments',
  COMPLICATED_SEGMENTS: 'complicated_segments',
  EXPORT_SEGMENTS: 'export_segments',
  PROMO_CODE: 'promocodes',
  CREATE_PROMO_CODE: 'create_promocodes',
  REVIEW_PROMO_CODE: 'review_promocodes',
  UPDATE_PROMO_CODE: 'update_promocodes',
  DELETE_PROMO_CODE: 'delete_promocodes',
  EVENTS: 'events',
  CREATE_EVENTS: 'create_events',
  REVIEW_EVENTS: 'review_events',
  UPDATE_EVENTS: 'update_events',
  DELETE_EVENTS: 'delete_events',
  TEMPLATES: 'templates',
  EMAILS: 'emails',
  CREATE_EMAILS: 'create_emails',
  REVIEW_EMAILS: 'review_emails',
  UPDATE_EMAILS: 'update_emails',
  DELETE_EMAILS: 'delete_emails',
  MOBILE_PUSH: 'mobile_push',
  CREATE_MOBILE_PUSH: 'create_mobile_push',
  REVIEW_MOBILE_PUSH: 'review_mobile_push',
  UPDATE_MOBILE_PUSH: 'update_mobile_push',
  DELETE_MOBILE_PUSH: 'delete_mobile_push',
  ARCHIVE_MOBILE_PUSH: 'archive_mobile_push',
  UN_ARCHIVE_MOBILE_PUSH: 'unarchive_mobile_push',
  SMS: 'sms',
  CREATE_SMS: 'create_sms',
  REVIEW_SMS: 'review_sms',
  UPDATE_SMS: 'update_sms',
  DELETE_SMS: 'delete_sms',
  WEBPUSHES: 'webpushes',
  CREATE_WEBPUSHES: 'create_webpushes',
  REVIEW_WEBPUSHES: 'review_webpushes',
  UPDATE_WEBPUSHES: 'update_webpushes',
  DELETE_WEBPUSHES: 'delete_webpushes',
  API_REQUESTS: 'webhooks',
  CREATE_API_REQUESTS: 'create_webhooks',
  REVIEW_API_REQUESTS: 'review_webhooks',
  UPDATE_API_REQUESTS: 'update_webhooks',
  DELETE_API_REQUESTS: 'delete_webhooks',
  CONTACTS: 'customers',
  CONTACT_MANAGEMENT: 'customer-management',
  CREATE_CONTACTS: 'create_customers',
  REVIEW_CONTACTS: 'review_customers',
  UPDATE_CONTACTS: 'update_customers',
  DELETE_CONTACTS: 'delete_customers',
  CSV_IMPORT: 'csv-import',
  CONTACT_FIELDS: 'customer-fields',
  REVIEW_CONTACT_FIELDS: 'review_customer_fields',
  UPDATE_CONTACT_FIELDS: 'update_customer_fields',
  WORKFLOWS: 'workflows',
  WORKFLOW_MANAGEMENT: 'workflow-management',
  CREATE_WORKFLOWS: 'create_workflows',
  REVIEW_WORKFLOWS: 'review_workflows',
  UPDATE_WORKFLOWS: 'update_workflows',
  PUBLISH_WORKFLOWS: 'publish_workflows',
  DELETE_WORKFLOWS: 'delete_workflows',
  SMS_NODES: 'sms-nodes',
  CREATE_SMS_NODES: 'create_sms_nodes',
  REVIEW_SMS_NODES: 'review_sms_nodes',
  UPDATE_SMS_NODES: 'update_sms_nodes',
  DELETE_SMS_NODES: 'delete_sms_nodes',
  EMAIL_NODES: 'email-nodes',
  CREATE_EMAIL_NODES: 'create_email_nodes',
  REVIEW_EMAIL_NODES: 'review_email_nodes',
  UPDATE_EMAIL_NODES: 'update_email_nodes',
  DELETE_EMAIL_NODES: 'delete_email_nodes',
  WEBPUSH_NODES: 'webpush-nodes',
  CREATE_WEBPUSH_NODES: 'create_webpush_nodes',
  REVIEW_WEBPUSH_NODES: 'review_webpush_nodes',
  UPDATE_WEBPUSH_NODES:'update_webpush_nodes',
  DELETE_WEBPUSH_NODES: 'delete_webpush_nodes',
  MOBILE_PUSH_NODES: 'mobile-push-nodes',
  CREATE_MOBILE_PUSH_NODES: 'create_mobile_push_nodes',
  REViEW_MOBILE_PUSH_NODES: 'review_mobile_push_nodes',
  UPDATE_MOBILE_PUSH_NODES: 'update_mobile_push_nodes',
  DELETE_MOBILE_PUSH_NODES: 'delete_mobile_push_nodes',
  API_REQUEST_NODES: 'webhook-nodes',
  CREATE_API_REQUEST_NODES: 'create_webhook_nodes',
  REVIEW_API_REQUEST_NODES: 'review_webhook_nodes',
  UPDATE_API_REQUEST_NODES: 'update_webhook_nodes',
  DELETE_API_REQUEST_NODES: 'delete_webhook_nodes',
  WORKFLOW_ANALYTICS: 'workflow-analytics',
  REVIEW_WORKFLOW_ANALYTICS: 'review_workflow_analytics',
  EXPORT_WORKFLOW_ANALYTICS: 'export_workflow_analytics',
  NOTIFICATION_HISTORY: 'notifications-history',
  NOTIFICATION_HISTORY_SMS: 'notifications-history-sms',
  REVIEW_NOTIFICATION_HISTORY_SMS: 'review_notifications_history_sms',
  EXPORT_NOTIFICATION_HISTORY_SMS: 'export_notifications_history_sms',
  NOTIFICATION_HISTORY_EMAIL: 'notifications-history-emails',
  REVIEW_NOTIFICATION_HISTORY_EMAIL: 'review_notifications_history_emails',
  EXPORT_NOTIFICATION_HISTORY_EMAIL: 'export_notifications_history_emails',
  NOTIFICATION_HISTORY_WEBPUSHES: 'notifications-history-webpushes',
  REVIEW_NOTIFICATION_HISTORY_WEBPUSHES: 'review_notifications_history_webpushes',
  EXPORT_NOTIFICATION_HISTORY_WEBPUSHES: 'export_notifications_history_webpushes',
  NOTIFICATION_HISTORY_API_REQUESTS: 'notifications-history-webhooks',
  REVIEW_NOTIFICATION_HISTORY_API_REQUESTS: 'review_notifications_history_webhooks',
  REVIEW_NOTIFICATION_HISTORY_FACEBOOK: 'review_notifications_history_facebook-audiences',
  EXPORT_NOTIFICATION_HISTORY_API_REQUESTS: 'export_notifications_history_webhooks',
  NOTIFICATION_HISTORY_MOBILE_PUSHES: 'notifications-history-mobile-pushes',
  REVIEW_NOTIFICATION_HISTORY_MOBILE_PUSHES: 'review_notifications_history_mobile-pushes',
  EXPORT_NOTIFICATION_HISTORY_MOBILE_PUSHES: 'export_notifications_history_mobile-pushes',
  STRIPE: 'stripe',
  REVIEW_STRIPE: 'review_stripe',
  UPDATE_STRIPE: 'update_stripe',
  NOTIFICATIONS: 'notifications',
  WORKFLOW_STARTED: 'workflow-started',
  WORKFLOW_FINISHED: 'workflow-finished',
  WORKFLOW_ERROR: 'workflow_error',
  MYSQL_SYNC_ERROR: 'mysql_sync_error',
  MYSQL_SYNC_SUCCESS: 'mysql_sync_success',
  MYSQL_SYNC_UTILIZATION: 'mysql_sync_utilization',
  POSTGRESQL_SYNC_ERROR: 'postgresql_sync_error',
  POSTGRESQL_SYNC_SUCCESS: 'postgresql_sync_success',
  POSTGRESQL_SYNC_UTILIZATION: 'postgresql_sync_utilization',

  ARCHIVE_PROMO_CODES: 'archive_promocodes',
  UN_ARCHIVE_PROMO_CODES: 'unarchive_promocodes',

  ARCHIVE_EMAILS: 'archive_emails',
  UN_ARCHIVE_EMAILS: 'unarchive_emails',

  ARCHIVE_SMS: 'archive_sms',
  UN_ARCHIVE_SMS: 'unarchive_sms',

  ARCHIVE_WEBPUSH: 'archive_webpushes',
  UN_ARCHIVE_WEBPUSH: 'unarchive_webpushes',

  ARCHIVE_WEBHOOKS: 'archive_webhooks',
  UN_ARCHIVE_WEBHOOKS: 'unarchive_webhooks',

  CREATE_VIBER: 'create_vibers',
  REVIEW_VIBER: 'review_vibers',
  UPDATE_VIBER: 'update_vibers',
  DELETE_VIBER: 'delete_vibers',
  ARCHIVE_VIBER: 'archive_vibers',
  UN_ARCHIVE_VIBER: 'unarchive_vibers',

  CREATE_FOLDER: 'create_folders',
  REVIEW_FOLDER: 'review_folders',
  UPDATE_FOLDER: 'update_folders',
  DELETE_FOLDERS: 'delete_folders',

  CREATE_FUNNEL: 'create_funnels',
  REVIEW_FUNNEL: 'review_funnels',
  UPDATE_FUNNEL: 'update_funnels',
  DELETE_FUNNEL: 'delete_funnels',
  DOWNLOAD_CUSTOMERS_FUNNEL: 'export-customers-from-funnel',

  REVIEW_NH_VIBER: 'review_notifications_history_vibers',
  REVIEW_NH_DISCOUNT: 'review_notifications_history_discounts',

  ARCHIVE_WORKFLOWS: 'archive_workflows',
  UN_ARCHIVE_WORKFLOWS: 'unarchive_workflows',

  CREATE_VIBER_NODES: 'create_viber_nodes',
  REVIEW_VIBER_NODES: 'review_viber_nodes',
  UPDATE_VIBER_NODES: 'update_viber_nodes',
  DELETE_VIBER_NODES: 'delete_viber_nodes',
  CREATE_DISCOUNT_NODES: 'create_discount_nodes',
  REVIEW_DISCOUNT_NODES: 'review_discount_nodes',
  UPDATE_DISCOUNT_NODES: 'update_discount_nodes',
  DELETE_DISCOUNT_NODES: 'delete_discount_nodes',

  UPDATE_VIBER_INTEGRATIONS: 'update_viber_integrations',
  CREATE_VIBER_INTEGRATIONS: 'create_viber_integrations',
  DELETE_VIBER_INTEGRATIONS: 'delete_viber_integrations',
  REVIEW_VIBER_INTEGRATIONS: 'review_viber_integrations',

  CREATE_AGGREGATES: 'create_aggregates',
  UPDATE_AGGREGATES: 'update_aggregates',
  REVIEW_AGGREGATES: 'review_aggregates',
  DELETE_AGGREGATES: 'delete_aggregates',

  CREATE_UNSUBSCRIBE_GROUPS: 'create_unsubscribe_groups',
  DELETE_UNSUBSCRIBE_GROUPS: 'delete_unsubscribe_groups',
  REVIEW_UNSUBSCRIBE_GROUPS: 'review_unsubscribe_groups',
  UPDATE_UNSUBSCRIBE_GROUPS: 'update_unsubscribe_groups',
  DOWNLOAD_UNSUBSCRIBE_GROUPS: 'download_unsubscribe_groups',
  EXPORT_TO_GROUP_UNSUBSCRIBE_GROUPS: 'export_to_group_unsubscribe_groups',
  IMPORT_UNSUBSCRIBE_GROUPS: 'import_unsubscribe_groups',

  CREATE_CAMPAIGNS: 'create_campaigns',
  REVIEW_CAMPAIGNS: 'review_campaigns',
  DELETE_CAMPAIGNS: 'update_campaigns',
  UPDATE_CAMPAIGNS: 'delete_campaigns',
  PUBLISH_CAMPAIGNS: 'publish_campaigns',

  CREATE_RFM: 'create_rfm',
  REVIEW_RFM: 'review_rfm',
  DELETE_RFM: 'update_rfm',
  UPDATE_RFM: 'delete_rfm',
  PUBLISH_RFM: 'publish_rfm',

  CREATE_DYNAMIC_ATTRIBUTES: 'create_dynamic_variables',
  REVIEW_DYNAMIC_ATTRIBUTES: 'review_dynamic_variables',
  DELETE_DYNAMIC_ATTRIBUTES: 'update_dynamic_variables',
  UPDATE_DYNAMIC_ATTRIBUTES: 'delete_dynamic_variables',

  WORKFLOW_ANALYTICA_DOWNLOAD_CUSTOMERS: 'download_customers_workflow_analytics',

  EXPORT_CUSTOMERS: 'export_customers',
};

export default UserPermissions;
