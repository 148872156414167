import React from 'react';

import { Container, IconContainer } from './styled';
import { Tooltip } from 'antd';

const SortableTitle = ({ children, name, right, ordering, tooltip, style, textStyle = {} , testId, ...props}) => {
  const sortable = !!ordering;
  const order = ordering?.get(name);

  const handleChange = () => {
    ordering?.set(name, order);
  };

  return (
    <Container data-testid={testId} style={style} right={right} sortable={sortable} order={order} onClick={handleChange} >
      <div {...props} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ...textStyle }}>
        {children}
      </div>
      {sortable && (
        <IconContainer order={order}>
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.5" y="10.2001" width="4.5" height="1.4" rx="0.7" transform="rotate(-180 4.5 10.2001)" fill="#909399"/>
            <rect x="7" y="6.20007" width="7" height="1.4" rx="0.7" transform="rotate(-180 7 6.20007)" fill="#909399"/>
            <rect x="10" y="2.20007" width="10" height="1.4" rx="0.7" transform="rotate(-180 10 2.20007)" fill="#909399"/>
          </svg>
        </IconContainer>
      )}
      {tooltip && (
        <Tooltip placement="bottom" title={tooltip}>
          <div style={{ marginLeft: 4 }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.99961 0.95166C3.70742 0.95166 0.951172 3.70791 0.951172 7.0001C0.951172 10.2923 3.63086 13.0485 6.99961 13.0485C10.2918 13.0485 13.048 10.3688 13.048 7.0001C13.048 3.70791 10.2918 0.95166 6.99961 0.95166ZM6.99961 12.2063C4.1668 12.2063 1.79336 9.90947 1.79336 7.0001C1.79336 4.09072 4.09023 1.79385 6.99961 1.79385C9.83242 1.79385 12.2059 4.09072 12.2059 7.0001C12.2059 9.90947 9.83242 12.2063 6.99961 12.2063ZM7.30518 6.15787H6.69268C6.61611 6.15787 6.53955 6.15787 6.53955 6.23443V9.98599C6.53955 10.0626 6.61611 10.0626 6.61611 10.0626H7.30518C7.38174 10.0626 7.38174 9.98599 7.38174 9.98599V6.23443C7.38174 6.23443 7.38174 6.15787 7.30518 6.15787ZM6.97722 5.11883C7.30341 5.11883 7.56785 4.85439 7.56785 4.5282C7.56785 4.20201 7.30341 3.93758 6.97722 3.93758C6.65103 3.93758 6.3866 4.20201 6.3866 4.5282C6.3866 4.85439 6.65103 5.11883 6.97722 5.11883Z" fill="#109DFB"/>
            </svg>
          </div>
        </Tooltip>
      )}
    </Container>
  );
};

export default SortableTitle;
