import styled from 'styled-components';

export const EmojiContainer = styled.div`
  font-size: 22px;
  display: flex;
  height: 22px;
  line-height: 22px;
  color: #303133;
  align-items: center;
  cursor: pointer;
`;

export const CountriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  margin-left: 70px !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  @media screen and (max-width: 500px) {
    max-height: 380px;
    width: 100vw;
    margin-left: 0px !important;
  }
  overflow-y: auto;
  border-radius: 12px;
`;

export const Country = styled.div`
  cursor: pointer;
  display: flex;
  font-family: Rubik-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #303133;
  padding: 14px 20px;
  transition: all 330ms ease;
  background: transparent;

  &:hover {
    background: #F0F2F6;
  }
`;
