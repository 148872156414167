import React, { useState } from 'react';
import { StepperTabs } from '@components';

import { Container, Footer } from './styled.js';
import { Question } from './components';
import { Button } from '../../../RegistrationPage/components';

const steps = [
  {
    label: 'Goals',
    name: 'goals',
  },
  {
    label: 'Customers',
    name: 'contacts',
  },
  {
    label: 'Choosing Retainly',
    name: 'choosing',
  },
];

const questionnaire = {
  contacts: {
    question: 'Number of your customers:',
    variants: ['0 - 15K', '15K - 50K', '50K - 100K', '100K - 250K', '250K - 500K', '500K+',],
    customVariant: false,
  },
  goals: {
    question: 'My primary goal is:',
    variants: ['Send transactional emails', 'Onboard or retain customers', 'Convert marketing leads', 'All'],
    customVariant: 'I would like to',
  },
  choosing: {
    question: 'I heard about Retainly from:',
    variants: ['LinkedIn', 'Facebook', 'Google', 'Podcast', 'Article', 'Conference'],
    customVariant: true,
  },
};

const QuestionnaireForm = ({ value, onChange, onSubmit, onSkip }) => {
  const [step, setStep] = useState('goals');

  const handleAnswerChange = (value, isCustom) => {
    if (!isCustom) {
      return onChange(a => ({
        ...a,
        [step]: {
          ...(step === 'goals' ? a[step] : {}),
          [value]: !a[step]?.[value],
        }
      }));
    }

    onChange(a => ({
      ...a,
      [step]: {
        ...(step === 'goals' ? a[step] : {}),
        ['custom']: value,
      }
    }));
  };

  const handleNext = () => {
    setStep(s => {
      if (s === 'goals') {
        return 'contacts';
      }

      return 'choosing';
    })
  };

  return (
    <Container>
      <div>
        <StepperTabs
          style={{ width: 360 }}
          steps={steps}
          current={step}
        />
        <Question
          config={questionnaire[step]}
          value={value[step]}
          step={step}
          style={{ marginTop: 30, marginBottom: 30 }}
          onChange={handleAnswerChange}
        />
      </div>
      <Footer>
        <Button
          style={{ width: '100%', marginRight: 20 }}
          appearance="secondary"
          onClick={onSkip}
          data-testid="reg-questionnaire-skip"
        >
          Skip
        </Button>
        {!!~['contacts', 'goals'].indexOf(step) && (
          <Button
            style={{ width: '100%', marginLeft: 20 }}
            onClick={handleNext}
            disabled={!value[step]}
          >
            Next
          </Button>
        )}
        {step === 'choosing' && (
          <Button
            data-testid="reg-questionnaire-next"
            style={{ width: '100%', marginLeft: 20 }}
            onClick={onSubmit}
            disabled={!value[step]}
          >
            Go to my account
          </Button>
        )}
      </Footer>
    </Container>
  );
}

export default QuestionnaireForm;


