import { RolesActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  loading: false,
  roles: [],
  rolesList: {
    loading: false,
    data: [],
  },
  meta: {},
  permissions: [],
  currentRole: null,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RolesActionTypes.GET_ROLES:
      return {
        ...state,
        loading: true,
      };
    case RolesActionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        roles: [...action.payload.data],
        meta: { ...action.payload },
        loading: false,
      };
    case RolesActionTypes.GET_ROLES_ERROR:
      return {
        ...state,
        // ...action.payload,
        roles: [],
        meta: { ...action.payload },
        loading: false,
      };
    case RolesActionTypes.GET_ROLES_LIST:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          loading: true,
        },
      }
    case RolesActionTypes.GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        rolesList: {
          data: action.payload,
          loading: false,
        },
      }
    case RolesActionTypes.GET_ROLES_LIST_ERROR:
      return {
        ...state,
        rolesList: {
          data: state.rolesList.data,
          loading: false,
        },
      }
    case RolesActionTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: [ ...action.payload ],
      }
    case RolesActionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        currentRole: { ...action.payload },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return { ...state };
  }
};

export default templatesReducer;
