import styled from 'styled-components';

export const BodyContainer = styled.div`
  padding-top: 18px;
  min-width: 100px;
  max-width: 600px;
  position: relative;
`;

export const CopyButton = styled.div`
  position: absolute;
  top: -8px;
  right: -4px;
  padding-bottom: 2px;
  border-bottom: 1px solid #109DFB;
  box-sizing: border-box;
  color: #109DFB;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
`;
