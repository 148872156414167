import { compose } from '@utils';
import { asCreator, withCallbacks } from '@store/lib';

export const getStripeSecret = () => ({
  type: 'stripeTest/GET_SECRET',
});

export const subscribePaymentMethod = compose(
  withCallbacks,
  asCreator(({ paymentMethod }) => ({
    type: 'stripeTest/SUBSCRIBE',
    payload: { paymentMethod },
  }))
);
