import React, { useImperativeHandle } from 'react';
import { Icon, Slider } from '@components';
import { useReactFlow, useStore } from 'reactflow';

import { Container, ZoomButton } from './styled';
import { testId } from '@utils';

const ZoomSlider = ({ instance }, ref) => {
  const [x, y, zoom] = useStore(store => store.transform);
  const reactFlow = useReactFlow();

  useImperativeHandle(ref, () => ({
    getPositionAndZoom: () => ({ x, y, zoom }),
    zoomTo: instance?.zoomTo,
    setTransform: instance?.setTransform
  }), [x, y, zoom, instance]);

  const handleZoomIn = () => {
    reactFlow?.zoomTo(zoom + 0.2);
  };

  const handleZoomOut = () => {
    reactFlow?.zoomTo(zoom - 0.2);
  };

  return (
    <Container>
      <ZoomButton {...testId('workflow-actionsbar-zoom-minus')()} onClick={handleZoomOut}>
        <Icon size={16} name="Zoom-minus-icon" />
      </ZoomButton>
      <Slider
        {...testId('workflow-actionsbar-zoom-slider')()}
        step={0.1}
        value={zoom}
        onChange={reactFlow?.zoomTo}
        min={0.5}
        max={2}
      />
      <ZoomButton {...testId('workflow-actionsbar-zoom-plus')()} onClick={handleZoomIn}>
        <Icon size={16} name="Zoom-plus-icon" />
      </ZoomButton>
    </Container>
  );
};

export default React.forwardRef(ZoomSlider);
