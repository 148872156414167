import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${styledProp('padded', `
    padding-bottom: 8px;
  `)}
`;
