import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Modal, Multiselect, RadioButton, RadioGroup } from '@components';
import { useTranslation } from '@hooks';
import { extract } from '@utils';
import workflowEditorContext from '@contexts/WorkflowEditorContext/WorkflowEditorContext';
import { getExitPoints } from '@utils/workflows/refactored/getters';

import { Container, Divider } from './styled';
import uniqBy from 'lodash.uniqby';

const defaultValue = {
  exitPoints: [],
  workflowName: [],
  option: 'one',
};

const ExitPointsSelectionOptions = {
  ALL: 'all',
  CERTAIN: 'certain',
  ONE: 'one',
};

const AddWorkflowTriggerModal = ({ opened, onClose, disabled, id, onConfirm }) => {
  const { t, p } = useTranslation('workflow_page');
  const [value, setValue] = useState({ ...defaultValue, exitPoints: [id] });
  const [error, setError] = useState('');
  const editor = useContext(workflowEditorContext);

  useEffect(() => {
    if (!id) {
      return;
    }

    setValue({ ...defaultValue, exitPoints: [id] });
  }, [id]);

  const exitPoints = uniqBy(getExitPoints(editor.state.root), extract('id')).map(({ id, data }) => ({
    value: id,
    label: data.label,
  }));

  const handleSave = () => {
    onConfirm(value);
    setValue(defaultValue);
  };

  const handleClose = () => {
    onClose();
    setValue(defaultValue);
  };

  const handleExitPointsChange = (newExitPoints, option) => {
    setValue(v => ({ ...v, exitPoints: newExitPoints, option: option || ExitPointsSelectionOptions.CERTAIN }));
  };

  const handleWorkflowNameChange = ({ target: { value: workflowName } }) => {
    setValue(v => ({ ...v, workflowName }));
  };

  const handleOptionChange = (option) => {
    setValue(v => ({ ...v, option }));
  }

  const handleRadioChange = (value) => {
    handleOptionChange(value);

    switch (value) {
      case ExitPointsSelectionOptions.ONE:
        return handleExitPointsChange([id]);
      case ExitPointsSelectionOptions.CERTAIN:
        return handleExitPointsChange([]);
      case ExitPointsSelectionOptions.ALL:
        return handleExitPointsChange(exitPoints.map(extract('value')), ExitPointsSelectionOptions.ALL);
      default:
        break;
    }
  };

  return (
    <Modal
      {...{opened, onClose: handleClose}}
      title={p('start_workflow_from_node')}
      actions={(
        <>
          <Button width={140} onClick={handleClose} appearance="secondary">{t('actions.cancel')}</Button>
          <Button width={140} disabled={!!error || disabled} onClick={handleSave}>{t('actions.create')}</Button>
        </>
      )}
    >
      <Container>
        <RadioGroup onChange={handleRadioChange} value={value.option}>
          <RadioButton style={{ marginBottom: 18 }} name={ExitPointsSelectionOptions.ONE} title={p('use_only_selected_ep')} />
          <RadioButton name={ExitPointsSelectionOptions.CERTAIN} title={p('use_several_selected_ep')}>
            <Multiselect
              options={exitPoints}
              onChange={handleExitPointsChange}
              value={value.exitPoints}
              title={p('exit_points')}
              containerStyle={{ marginBottom: 18, marginLeft: 20, marginTop: 8 }}
            />
          </RadioButton>
          <RadioButton name={ExitPointsSelectionOptions.ALL} title={p('use_all_ep')} />
        </RadioGroup>
        <Divider />
        <Input
          title={p('workflow_name')}
          value={value.workflowName}
          wrapperStyles={{ marginBottom: 30 }}
          onChange={handleWorkflowNameChange}
        />
      </Container>
    </Modal>
  );
};

export default AddWorkflowTriggerModal;
