import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  
  ${styledProp('$disabled', css`
    pointer-events: none;
    opacity: 0.7;
  `)}
`;

export const SelectContainer = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #109DFB;
  user-select: none;
  cursor: pointer;
  
  ${styledProp('disabled', css`
    opacity: .5;
    cursor: default;
  `)}
`;
