import {useDispatch, useSelector} from "react-redux";
import {
    expressionSelector
} from '@store/selectors';

import {
    expressionTypeSelected
} from '@store/actions/creators';

import {Function, Constant, Linear, Array, Event} from "./Nodes";

import {NODE_TYPE_CONSTANT, NODE_TYPE_LINEAR, NODE_TYPE_FUNCTION, NODE_TYPE_ARRAY, NODE_TYPE_EVENT} from "../constants";

const Expression = (props) => {

    const dispatch = useDispatch();

    const renderExpression = (expression) => {
        if (expression.type === NODE_TYPE_LINEAR) {
            return <Linear key={NODE_TYPE_LINEAR+expression.id} expression={expression} for={props.for}/>
        } else if (expression.type === NODE_TYPE_ARRAY) {
            return <Array key={NODE_TYPE_LINEAR+expression.id} expression={expression} for={props.for}/>
        } else if (expression.type === NODE_TYPE_EVENT) {
            return <Event key={NODE_TYPE_LINEAR+expression.id} expression={expression} for={props.for}/>
        } else if (expression.type === NODE_TYPE_CONSTANT) {
            return <Constant key={NODE_TYPE_CONSTANT+expression.id}  expression={expression} for={props.for}/>
        }
        else if (expression.type === NODE_TYPE_FUNCTION)  {
            return <Function key={NODE_TYPE_FUNCTION+expression.id} expression={expression} for={props.for}/>
        }

        return <select value={props.expression.type} onChange={(e) => {dispatch(expressionTypeSelected({...props.expression, value:e.target.value}));}} onClick={(e) => e.stopPropagation()}>
            <option>---</option>
            <option value={NODE_TYPE_LINEAR} >Customer</option>
            <option value={NODE_TYPE_CONSTANT}>Constant</option>
            <option value={NODE_TYPE_FUNCTION}>Function</option>
            {props.for.type === 'array' ? (<option value={NODE_TYPE_ARRAY}>Array</option>) : ""}
            {props.for.type === 'event' ? (<option value={NODE_TYPE_EVENT}>Event</option>) : ""}
        </select>
    }


    return (
        <span>
            {renderExpression(props.expression)}
        </span>
    )


};

export default Expression;
