import styled from 'styled-components';
import { extract } from '@utils';

export const Container = styled.div`
  width: 480px;
`;

export const EmojiImage = styled.div`
  width: 30px;
  height: 30px;
  background: center / contain no-repeat url("${extract('$image')}");
`;

export const Emoji = styled.div`
  font-size: 30px;
  text-align: center;
`;
