import React, { useState } from 'react';
import { Button, Modal } from '@components';
import { useTranslation } from '@hooks';

import { DemoWorkspaceCard } from './components';
import { Container, Title, SubTitle, Cards, BorderDivider, CardContainer, DummyCard } from './styled';
import { by } from '@utils';

const height = 150;
const width = 140;

const CreateDemoWorkspaceModal = ({ welcome, opened, onClose, options, onSelect }) => {
  const { t, p } = useTranslation('dashboard_page');
  const [innerSelected, setInnerSelected] = useState('');

  const handleSubmit = () => {
    onSelect({ app_id: options.find(by('name', innerSelected))?.id });
  };

  const filledOptions = options.concat(Array.from({ length: 3 - (options.length % 3) }, () => ({ dummy: true, name: '' }) ));

  return (
    <Modal
      opened={opened}
      bigger
      onClose={onClose}
      title={p(welcome ? 'welcome_short' : 'view_demo_workspace')}
      actions={(
        <>
          <Button onClick={onClose} appearance="secondary" width={160}>{t('actions.cancel')}</Button>
          <Button style={{ marginLeft: 10 }} onClick={handleSubmit} disabled={!innerSelected} width={160}>{t('actions.view')}</Button>
        </>
      )}
    >
      <Container>
        <Title>{p('dw_title')}</Title>
        <SubTitle>{p('dw_subtitle')}</SubTitle>
        <Cards>
          {filledOptions.map((o, index, self) => (
            <CardContainer key={index}>
              {index % 3 !== 2 && self.length - index > 3 && <BorderDivider />}
              {o.dummy ? <DummyCard /> : (
                <DemoWorkspaceCard
                  name={o.name}
                  selected={innerSelected === o.name}
                  onSelect={setInnerSelected}
                />
              )}
            </CardContainer>
          ))}
        </Cards>
      </Container>
    </Modal>
  );
};

export default CreateDemoWorkspaceModal;
