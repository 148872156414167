import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    expressionSelector
} from '@store/selectors';

import Expression from './components/Expression';
import ValueSelector from "./components/ValueSelector";
import {createExpression, expressionNameChanged} from "@store/actions/creators";
import { getFieldSettingsList, getEventList } from '@store/actions/creators';
import For from './components/For';
const  ExpressionPage = () =>  {

    const dispatch = useDispatch();


    const expression = useSelector(expressionSelector);

    useEffect(() => {
        dispatch(getFieldSettingsList());
        dispatch(getEventList());
    }, []);

    return (
        <div>
            <input type="text" value={expression.name} onChange={(e) => {dispatch(expressionNameChanged({value: e.target.value}))}}/>
            <For expression={expression}/>
            <br/>

            <div>
                <Expression key={"expression"+expression.expression.id} expression={expression.expression} for={expression.for}/>
            </div>
            <br/>
            <ValueSelector key={"valueSelector"+expression.expression.id} expression={expression}/>
            <button onClick={() => {
                dispatch(createExpression({
                    id: null,
                    name:expression.name,
                    expression: {
                        expression: expression.expression,
                        for: expression.for,
                    },
                    aggregates: [],
                    onSuccess: (response) => {
                        alert("SUCCESS")
                    },
                    onError: () => {
                        alert("ERROR")
                    },
                }))
            }}>Save</button>
        </div>
    )
};

export default ExpressionPage;
