import styled, { css } from 'styled-components';
import { TimePicker } from 'antd';
import { styledProp } from '@utils';

export const StyledTimePicker = styled(TimePicker)`
  padding: 2px 0 !important;
  background: transparent !important;
  border: none !important;
  margin-left: 4px !important;
  width: 56px;
  
  ${styledProp('$smaller', css`
    width: 36px;
  `)}
  
  & input {
    font-family: Rubik-Regular !important;
    font-size: 13px !important;
    line-height: 120% !important;
    color: #909399 !important;
  }


  & .ant-picker-suffix {
    display: none;
    width: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  & .ant-picker-clear {
    margin: 0px !important;
    display: none;
    width: 0px !important;
    padding: 0px !important;
  }
`;
