import React, { useRef, useState, } from 'react';
import { Container, EditorContainer, Editor, PreviewTitle } from './styled.js';
import {
  EmailSampleData,
  Stripo,
  ToggleButton,
  ViewModeSwitch,
  WithOnboarding,
  OnboardingHighlight,
  Divider, TemplatesGeneralAttributesDrawer
} from '@components';
import { useModalState, useOnboarding, useTranslation } from '@hooks';
import { ViewModes } from '@constants';
import {findAttributes, notify, replaceKeysWithValues} from "@utils";
import {FallbackValuesModal} from "@components/modals/FallbackValuesModal";
import {useSelector} from "react-redux";
import { emailSampleDataSelector, fieldsSettingsListSelector } from "@store/selectors";

const HTMLEditor = ({ value, onChange, id, loaded, errors }) => {
  const fieldSettings = useSelector(fieldsSettingsListSelector)?.data || [];
  const fields = fieldSettings.map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const [fallbackBody, setFallbackBody] = useState('');
  const [fallbackCss, setFallbackCss] = useState('');
  const fallbackModal = useModalState();
  const exampleFields = useModalState();
  const discountFields = useModalState();
  const onboarding = useOnboarding('templates');
  const container = useRef(null);
  const [viewMode, setViewMode] = useState(ViewModes.DESKTOP);
  const { p, t } = useTranslation('templates');

  const handleToggleFieldsExample = () => {
    discountFields.close();
    exampleFields.toggle();
  };

  const handleDiscountToggle = () => {
    exampleFields.close();
    discountFields.toggle();
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['body'], { body: fallbackBody });
    onChange({ ...value, design: { css: fallbackCss, html: replaced.body } });
  }

  const handleClickFallback = async () => {
    if (!window.StripoApi) {
      notify('error', t('labels.error'), p('you_have_no_selected_attributes'));
      return;
    }

    const design = await new Promise(res => window.StripoApi.getTemplate((html, css) => res({ css, html })));

    if (design.html.indexOf('{{customer.') === -1) {
      notify('error', t('labels.error'), p('you_have_no_selected_attributes'));
      return;
    }

    setFallbackBody(design.html);
    setFallbackCss(design.css);
    fallbackModal.open();
  }

  const handleCloseFallback = () => {
    setFallbackCss('');
    setFallbackBody('');
    fallbackModal.close();
  }

  return (
    <Container>
      <PreviewTitle>{p('constructor')}</PreviewTitle>
      <div style={{ position: 'absolute', top: '-24px', right: 0, display: 'flex', alignItems: 'center' }} >
        {/*{value?.body?.indexOf('{{customer.') !== -1 && (*/}
        <ToggleButton
          testId="html-fallback-toggle"
          opened={fallbackModal.opened}
          style={{ marginRight: 4 }}
          fallback
          onToggle={handleClickFallback}
        >
          {p('fallback_values')}
        </ToggleButton>
        {/*)}*/}
        <ToggleButton
          id="codeEditor"
          testId="email-code-toggle"
          appearance="secondary"
          style={{
            width: 'auto',
            padding: 'auto 16px !important',
            height: '26px',
            fontSize: '12px',
            marginRight: 10,
          }}
        >
          {p('code')}
        </ToggleButton>
        <WithOnboarding next enabled={onboarding.enabled(10)} onboardKey="templates" text={p('it_will_allow_to_review_user_attributes')}>
          <OnboardingHighlight on={onboarding.enabled(10)} right={-10}>
              <ToggleButton
                testId="email-attributes-toggle"
                opened={exampleFields.opened}
                style={{ marginRight: 12 }}
                onToggle={handleToggleFieldsExample}
              >
                {p('customer_attributes')}
              </ToggleButton>
          </OnboardingHighlight>
        </WithOnboarding>
        <ToggleButton
          error={errors?.['unsubscribe_url'] || errors?.['subscribe_url']}
          testId="email-discount-toggle"
          style={{ marginRight: errors?.['unsubscribe_url'] || errors?.['subscribe_url'] ? 26 : 10 }}
          opened={discountFields.opened}
          onToggle={handleDiscountToggle}
        >
          {p('general_attributes')}
        </ToggleButton>
        <Divider vertical style={{ marginRight: 10, height: 16, background: '#909399' }} />
        <ViewModeSwitch value={viewMode} onChange={setViewMode} containerStyle={{ position: 'static' }} />
      </div>
      <EditorContainer>
        <WithOnboarding placement="top" next enabled={onboarding.enabled(9)} onboardKey="templates" text={p('use_constructor')}>
          <OnboardingHighlight radius={14} on={onboarding.enabled(9)} sytle={{ width: '100%', flex: 1, display: 'flex' }}>
            <Editor ref={container}>
              <EmailSampleData
                onClose={exampleFields.close}
                opened={exampleFields.opened}
                getContainer={container.current}
                onVisibilityChanged={exampleFields.setOpened}
              />
              <TemplatesGeneralAttributesDrawer
                onClose={discountFields.close}
                opened={discountFields.opened}
                small
                onVisibilityChanged={discountFields.setOpened}
              />
              <Stripo
                id={id}
                loaded={loaded}
                viewMode={viewMode}
                html={value.design?.html}
                css={value.design?.css}
                pluginId="35e7df46df0f480aa845d389534e5682"
                secretKey="7b8349fcb32148b8835b5ded6074571d"
              />
            </Editor>
          </OnboardingHighlight>
        </WithOnboarding>

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={handleCloseFallback}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([fallbackBody], fields)}
        />
      </EditorContainer>
    </Container>
  );
};

export default HTMLEditor;


