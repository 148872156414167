import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ActionButton,
  DateCell,
  Link,
  Modal,
  SortableTitle,
  Table,
  TableActionsBar,
  WithPermissions
} from '@components';
import {
  templateSelector,
  webpushLoadingSelector,
  webpushSelector
} from '@store/selectors';
import { StatusCell } from '../StatusCell';
import { Paths, UserPermissions, WebpushStatuses, WebpushStatusLabels } from '@constants';
import { useModalState, useTranslation } from '@hooks';
import { MacOSPushPreview } from '@components/lib/WebPushPreview/components';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';
import { IdType, Actions } from '@components/lib/styled';
import { extract } from '@utils';
const resolveStatus = status => WebpushStatusLabels[status];

const resolveColor = status => ({
  [WebpushStatuses.SENT]: '#909399',
  [WebpushStatuses.DISPLAYED]: '#24993E',
  [WebpushStatuses.DISMISS]: '#C8402F',
  [WebpushStatuses.CLICK]: '#24993E',
  [WebpushStatuses.FAILED]: '#C8402F',
  [WebpushStatuses.OPENED]: '#24993E',
})[status];

const WebpushTable = ({ keyExtractor, cols, ordering }) => {
  const webpush = useSelector(webpushSelector);
  const loading = useSelector(webpushLoadingSelector);
  const templates = useSelector(templateSelector);
  const [selectedWebpush, selectWebpush] = useState({
    actions: [],
    icon: '',
    image: '',
    web_push_name: '',
  });
  const previewState = useModalState(true);
  const webpushPreivewModal = useModalState(false);
  const { t, p } = useTranslation('notification_history')

  const openPreview = (data) => {
    selectWebpush(data);
    webpushPreivewModal.open();
  }

  const createWebpushTableColumns = (ordering, callbacks) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      ),
    },
    {
      title: () => <SortableTitle name="web_push_name" ordering={ordering}>{t('labels.template')}</SortableTitle>,
      dataIndex: 'template_id',
      className: `notification-history-cell-template`,
      key: 'template_id',
      render: (name, data) => (data.template_id && data.template_id !== 0 && data.template_id !== 1) ? <WithPermissions name={UserPermissions.REVIEW_WEBPUSHES}><Link to={`${Paths.TEMPLATES_WEBPUSH}/${data.template_id}?view=1`}>{templates?.web_push?.find(e => e.id === data.template_id)?.name}</Link></WithPermissions> : <div>{p('test_send')}</div>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'created_at'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      key: 'created_at',
      className: `notification-history-cell-date`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)} />
      }
    },
    {
      title: () => <SortableTitle right>{t('labels.actions')}</SortableTitle>,
      dataIndex: 'act',
      key: 'actions',
      className: `notification-history-cell-actions`,
      fixed: 'right',
      render: (f, d) => {
        return (
          <Actions>
            <TableActionsBar
              actionButtons={(
                <ActionButton testId="webpush-action-show-preview" tooltip={p('show_preview')} onClick={() => callbacks.openPreview(d)} icon="Show-icon" />
              )}
            />
          </Actions>
        );
      }
    },
  ];

  const columns = createWebpushTableColumns(ordering, { openPreview });

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NOTIFICATION_HISTORY_WEBPUSHES} hidden>
        <Table
          loading={loading}
          scroll={{ x: true }}
          key={webpush.data?.map?.(extract('id')).join()}
          rowClassName={(record) => `notification-history-row-${record.id}`}
          columns={columns}
          dataSource={webpush.data}
          rowKey={keyExtractor}
        />
      </WithPermissions>

      <Modal
        appearance="default"
        bigger
        title={p('preview', { name: selectedWebpush.web_push_name || '' })}
        opened={webpushPreivewModal.opened}
        onClose={webpushPreivewModal.close}
        contentStyles={{ padding: '30px 20px 30px 20px', maxHeight: '55vh', minHeight: '55vh' }}
      >
        <div style={{ position: 'relative', maxHeight: '46vh', minHeight: '45vh', display: 'flex' }}>
          <MacOSPushPreview
            style={{
              width: '60%',
              position: 'inherit',
              margin: 'auto',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
            }}
            title={selectedWebpush.title}
            text={selectedWebpush.b}
            icon={selectedWebpush.icon}
            image={selectedWebpush.image}
            company={p('chrome')}
            buttons={selectedWebpush.actions ? selectedWebpush.actions.map(a => ({ title: a.text })) : []}
            onToggle={previewState.toggle}
            opened={previewState.opened}
          />
        </div>
      </Modal>
    </>
  )
}

export default WebpushTable;

