import React from 'react';

import { Container, Label } from './styled.js';

const AnswerButton = ({ label, selected, onSelect }) => {

  const handleSelect = () => {
    onSelect(label);
  };

  return (
    <Container selected={selected} onClick={handleSelect}>
      <Label selected={selected}>{label}</Label>
    </Container>
  );
}

export default AnswerButton;


