import { isEdge, isNode } from 'reactflow';
import uniqBy from 'lodash.uniqby';
import { by, dist, extract } from '@utils';
import { WorkflowNodes, WorkflowNodeTypes } from '@constants';

export const getConnectors = (n, ns) => ns.filter(by('source', n.id));

export const getConnectedNodes = (n, ns) => {
  const cs = getConnectors(n, ns);

  return ns.filter(({ id }) => cs.some(by('target', id)));
};

export const getRoot = (ns) => {
  return ns.find(node => !ns.some(by('target', node.id)) && isNode(node));
};

export const getRoots = ns => {
  return ns.filter(node => !ns.some(by('target', node.id)) && isNode(node));
};

export const getParent = (n, ns) => {
  const connector = ns.find(by('target', n.id));

  if (!connector) {
    return null;
  }

  return ns.find(by(connector.source));
}

export const getBranchFrom = (n, ns) => {
  const nodes = [];

  const fill = (n, out) => {
    const cs = getConnectors(n, ns);
    const next = getConnectedNodes(n, ns);
    out.push(n, ...cs);
    next.forEach(nn => fill(nn, out));
  }
  fill(n, nodes);

  return nodes;
};

export const getClosestNode = (n, ns) => {
  const { position: np } = n;
  const nodes = ns.filter(isNode);

  const sorted = nodes.sort((a, b) => dist(a.position, np) - dist(b.position, np));

  return sorted.shift();
};

export const isLastNode = (n, ns) => {
  const cs = getConnectors(n, ns);

  if (cs.length !== 1) {
    return n.data.type !== 'entry_point' && n;
  }

  const [c] = cs;
  const next = ns.find(by(c.target));

  return next.type === WorkflowNodeTypes.FINISH ? next : false;
};

export const hasSplitters = (n) => {
  return n?.data?.name === WorkflowNodes.AB_SPLITTER || n?.data?.name === WorkflowNodes.SWITCH_FILTER;
}

export const getFirstSplitter = () => {
  // @TODO
};

export const getStartNodesInRange = (rns, ns) => {
  return rns.filter(n => {
    const parent = getParent(n, ns);

    if (!parent) {
      return true;
    }

    return !~rns.findIndex(by(parent.id));
  })
};

export const getLeavesInRange = (rns, ns) => {
  return rns.flatMap(n => {
    const next = getConnectedNodes(n, ns);

    if (!next.length) {
      return [];
    }

    return next.filter(nn => !~rns.findIndex(by(nn.id)));
  });
};

export const isConnectorInRange = range => connector => {
  return isEdge(connector) && !!range.find(by(connector.target)) && !!range.find(by(connector.source));
}

export const restoreNodesInRange = (rns, ns) =>
  uniqBy(rns.filter(isNode).flatMap(n => [
    n,
    ...ns.filter(by('source', n.id)).filter(isConnectorInRange(rns)),
    ...ns.filter(by('target', n.id)).filter(isConnectorInRange(rns))
  ]), extract('id'));
