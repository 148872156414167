import React, { useEffect } from 'react';

import { Container, Title } from './styled';
import { useTranslation } from '@hooks';
import { Input } from '@components';
import { by, mapEntries } from '@utils';

const DynamicVariablesSettings = ({ variables, value, onChange }) => {
  const { p } = useTranslation('workflow_page');

  useEffect(() => {
    onChange?.(vs => ({ ...vs, ...Object.fromEntries((variables || []).filter(({ name }) => !vs[name]).map(({ name, default_value }) => [name, default_value]))}), true);
  }, [(variables || []).join('.')]);

  if (!variables?.length) {
    return null;
  }

  const handleChange = (name) => ({ target: { value } }) => {
    onChange(vs => ({ ...vs, [name]: value }));
  };

  const handleBlur = (name) => () => {
    if (value[name]) {
      return;
    }

    onChange(vs => ({ ...vs, [name]: void 0 }));
  };

  return (
    <Container>
      <Title>{p('dynamic_attributes')}</Title>
      {variables.map(({ label, name }) => (
        <Input
          title={label}
          wrapperStyles={{ marginTop: 16 }}
          value={value[name] === undefined ? (variables || []).find(by('name', name))?.default_value : value[name] }
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
        />
      ))}
    </Container>
  );
};

export default DynamicVariablesSettings;
