import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  margin-top: 5px;
  overflow-x: hidden;
  
  ${styledProp('borderless', `
    border: none;
  `)}
`;
