import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, SearchSelect, Select, Switch } from '@components';
import { useModalState, useTranslation } from '@hooks';
import { notify, identity } from '@utils';
import omit from 'lodash.omit';

import { Container, Row } from './styled';

const getTypeOptions = (p) => [
  {
    value: 2,
    label: p('static'),
  },
  {
    value: 1,
    label: p('dynamic'),
  }
];

const alphabetOptions = (p) => [
  {
    value: 'QWERTYUIOPASDFGHJKLZXCVBNM',
    label: p('latin'),
  },
  // {
  //   value: 'ЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮ',
  //   label: 'Cyrillic',
  // }
];

const EditPromoCodeModal = ({ values, onSubmit, opened, onClose, errors, onSetErrors, onClearError, onClearErrors }) => {
  const [type, setType] = useState(values?.type || 2);
  const [fields, setFields] = useState({
    letters: 'QWERTYUIOPASDFGHJKLZXCVBNM',
    ...values,
    // allowed_to_share: true,
  });
  const numbers = useModalState(!!values?.numbers);
  const letters = useModalState(!!values?.symbols);
  const { p, t } = useTranslation('promo_codes_page');


  useEffect(() => {
    setFields({
      letters: 'QWERTYUIOPASDFGHJKLZXCVBNM',
      ...values,
    })
  }, [opened]);

  const handleInputChange = (name) => ({ target: { value } }) => {
    if (errors[name]) {
      onClearError(name);
    }

    setFields(f => ({ ...f, [name]: value }));
  };

  const handleSelectChange = (name) => (value) => {
    if (errors[name]) {
      onClearError(name);
    }

    setFields(f => ({ ...f, [name]: value }))
  };

  const handleSubmit = () => {
    const errors = Object.entries(omit(fields, ['name', 'updated_at', 'user', 'workers', 'created_at', 'promo_code_example', 'used'])).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value ? (!(/^[a-zA-Z\d\s]+$/.test(String(value).trim())) && p('validation')) || undefined : undefined,
    }), {});

    if (Object.values(errors).some(identity)) {
      return onSetErrors(errors);
    }

    onClearErrors();
    onSubmit({ ...fields, type, }, () => {
      setFields({
        letters: 'QWERTYUIOPASDFGHJKLZXCVBNM',
        ...values,
      });
      notify('success', `${values ? p('promo_code_updated') : p('promo_code_created')}`, fields.name)
    });
  };

  const handleCLose = () => {
    onClose();
    onClearErrors();
    setFields({
      letters: 'QWERTYUIOPASDFGHJKLZXCVBNM',
      ...values,
    });
  };

  console.log('getTypeOptions(p)', getTypeOptions(p))

  return (
    <Modal
      style={{ top: '40px' }}
      title={values ? p('edit_promo_code') : p('create_promo_code')}
      opened={opened}
      onClose={handleCLose}
      actions={(
        <>
          <Button onClick={handleCLose} appearance="secondary" width={160}>{t('actions.cancel')}</Button>
          <Button onClick={handleSubmit} width={160}>{values ? t('actions.edit') : t('actions.create')}</Button>
        </>
      )}
    >
      <Container>
        <Row>
          <Input
            title={t('labels.name')}
            maxLength={30}
            error={errors['name']}
            value={fields.name}
            onChange={handleInputChange('name')}
          />
          <SearchSelect
            error={errors['type']}
            style={{ width: '100%' }}
            value={type}
            onChange={setType}
            disabled={!!values}
            label={t('labels.type')}
            options={getTypeOptions(p)}
          />
        </Row>
        <Row single>
          {type === 2 && (
            <Input
              title={p('code')}
              maxLength={30}
              error={errors['code']}
              value={fields?.code}
              wrapperStyles={{ width: '100%' }}
              onChange={handleInputChange('code')}
            />
          )}
          {type === 1 && (
            <Input
              error={errors['length']}
              title={p('length')}
              maxLength={30}
              type="number"
              min={1}
              wrapperStyles={{ width: '100%' }}
              value={fields.length}
              onChange={handleInputChange('length')}
            />
          )}
          {/*<Switch onChange={handleSelectChange('allowed_to_share')} style={{ marginTop: 6 }} checked={fields.allowed_to_share} title="Allowed to share" />*/}
        </Row>
        {type === 1 && (
          <>
            <Row>
              <Input
                title={p('prefix')}
                maxLength={30}
                error={errors['prefix']}
                value={fields.prefix}
                onChange={handleInputChange('prefix')}
              />
              <Input
                title={p('suffix')}
                maxLength={30}
                error={errors['suffix']}
                value={fields.suffix}
                onChange={handleInputChange('suffix')}
              />
            </Row>
            <Row>
              <SearchSelect
                style={{ width: '100%' }}
                options={alphabetOptions(p)}
                value={fields.letters}
                error={errors['letters']}
                label={p('alphabet')}
                onChange={handleSelectChange('letters')}
              />
              <Input
                title={p('mask')}
                maxLength={30}
                error={errors['mask']}
                value={fields.mask}
                onChange={handleInputChange('mask')}
              />
            </Row>
            <Row style={{ marginBottom: '16px' }}>
              <Switch
                title={p('numbers')}
                style={{ marginLeft: 4 }}
                checked={numbers.opened}
                onChange={numbers.toggle}
              />
              <Switch
                title={p('symbols')}
                style={{ marginLeft: 4 }}
                checked={letters.opened}
                onChange={letters.toggle}
              />
            </Row>
            {(numbers.opened || letters.opened) && (
              <Row>
                <div>
                  {numbers.opened && (
                    <Input
                      error={errors['numbers']}
                      value={fields.numbers}
                      maxLength={30}
                      title={p('numbers_example')}
                      onChange={handleInputChange('numbers')}
                    />
                  )}
                </div>
                <div>
                  {letters.opened && (
                    <Input
                      error={errors['symbols']}
                      value={fields.symbols}
                      title={p('symbol_example')}
                      onChange={handleInputChange('symbols')}
                    />
                  )}
                </div>
              </Row>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default EditPromoCodeModal;
