import React, { useState } from 'react';
import { moment as momentTz } from '@utils';

import { Container, Month, Months, Header, Footer, YearButton } from './styled';
import { useTranslation } from '@hooks';

const months = [
  {
    month: 0,
    name: 'jan',
  },
  {
    month: 1,
    name: 'feb',
  },
  {
    month: 2,
    name: 'mar',
  },
  {
    month: 3,
    name: 'apr',
  },
  {
    month: 4,
    name: 'may',
  },
  {
    month: 5,
    name: 'jun',
  },
  {
    month: 6,
    name: 'jul',
  },
  {
    month: 7,
    name: 'aug',
  },
  {
    month: 8,
    name: 'sep',
  },
  {
    month: 9,
    name: 'oct',
  },
  {
    month: 10,
    name: 'nov',
  },
  {
    month: 11,
    name: 'dec'
  }
];

const CURRENT_YEAR = momentTz().get('year');

const MonthsPicker = ({ value: [startDate, endDate] = [], mode = 'range', onChange }) => {
  const { t } = useTranslation();
  const [year, setYear] = useState(CURRENT_YEAR);
  const [selecting, setSelecting] = useState('start');
  const [hoverSelection, setHoverSelection] = useState(null);
  const moment = mode === 'picker' ? momentTz.utc : momentTz;

  const handleMouseEnter = (month) => () => {
    setHoverSelection(month);
  };

  const handleMouseLeave = () => () => {
    setHoverSelection(null);
  };

  const handleClick = (month) => () => {
    if (mode === 'picker') {
      return onChange([moment(startDate).set('year', year).set('month', month).startOf('month'), moment(endDate).set('year', year).set('month', month).endOf('month')]);
    }

    let r;

    if (selecting === 'start') {
      setSelecting('end');
      r = [moment(startDate).set('year', year).set('month', month), endDate].sort((a, b) => a.unix() - b.unix());
    }

    if (selecting === 'end') {
      setSelecting('start');
      r = [startDate, moment(endDate).set('year', year).set('month', month)].sort((a, b) => a.unix() - b.unix());
    }

    const [d1, d2] = r;

    onChange([d1.startOf('month'), d2.endOf('month')]);
  };

  const isSelected = (month) => {
    const [startYear, endYear] = [moment(startDate).get('year'), moment(endDate).get('year')];
    const [startMonth, endMonth] = [moment(startDate).get('month'), moment(endDate).get('month')];

    const monthsDiff = (endYear - year) * 12;

    return startYear <= year && endYear >= year && startMonth <= month && (endMonth + monthsDiff) >= month;
  };

  const isHovered = (month) => {
    if (hoverSelection === null) {
      return false;
    }

    const [startYear, endYear] = [moment(startDate).get('year'), moment(endDate).get('year')];
    const [startMonth, endMonth] = [moment(startDate).get('month'), moment(endDate).get('month')];
    const startDiff = (startYear - year) * 12;
    const endDiff = (endYear - year) * 12;

    if (mode === 'picker') {
      return month === hoverSelection;
    }

    return (month >= hoverSelection && month < (startMonth + startDiff)) || (month <= hoverSelection && month > (endMonth + endDiff));
  };

  const isDisabled = (month) => {
    if (year < CURRENT_YEAR) {
      return false;
    }
    if (year > CURRENT_YEAR) {
      return true;
    }

    if (mode === 'picker') {
      return month > moment().get('month');
    }

    return month >= moment().get('month');
  };

  const handleLeftClick = () => {
    setYear(y => y - 1);
  };

  const handleRightClick = () => {
    if (year === CURRENT_YEAR) {
      return;
    }

    setYear(y => y + 1);
  };

  return (
    <Container>
      <Header>
        <YearButton onClick={handleLeftClick}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4492 15.4625C13.4492 15.2875 13.3617 15.1125 13.2742 15.025L10.2992 11.9625L9.51172 11L10.3867 10.0375L13.2742 7.06245C13.3617 6.97495 13.4492 6.79995 13.4492 6.62495C13.4492 6.44995 13.3617 6.27495 13.2742 6.18745C13.0992 6.01245 13.0117 6.01245 12.8367 6.01245C12.6617 6.01245 12.4867 6.09995 12.3992 6.18745L7.76172 11L12.3992 15.9C12.4867 15.9875 12.5742 15.9875 12.6617 16.075C12.7492 16.075 12.8367 16.1625 12.9242 16.1625C13.0117 16.1625 13.0992 16.1625 13.1867 16.075C13.2742 16.075 13.3617 15.9875 13.4492 15.9C13.3617 15.725 13.4492 15.6375 13.4492 15.4625Z" fill="#303133"/>
            <rect x="0.5" y="0.5" width="21" height="21" rx="5.5" stroke="#303133"/>
          </svg>
        </YearButton>
        <span>{year}</span>
        <YearButton onClick={handleRightClick}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.55066 15.4625C8.55066 15.2875 8.63816 15.1125 8.72566 15.025L11.7007 11.9625L12.4882 11L11.6132 10.0375L8.72566 7.06245C8.63816 6.97495 8.55066 6.79995 8.55066 6.62495C8.55066 6.44995 8.63816 6.27495 8.72566 6.18745C8.90066 6.01245 8.98816 6.01245 9.16316 6.01245C9.33816 6.01245 9.51316 6.09995 9.60066 6.18745L14.2382 11L9.60066 15.9C9.51316 15.9875 9.42566 15.9875 9.33816 16.075C9.25066 16.075 9.16316 16.1625 9.07566 16.1625C8.98816 16.1625 8.90066 16.1625 8.81316 16.075C8.72566 16.075 8.63816 15.9875 8.55066 15.9C8.63816 15.725 8.55066 15.6375 8.55066 15.4625Z" fill="#303133"/>
          <rect x="-0.5" y="0.5" width="21" height="21" rx="5.5" transform="matrix(-1 0 0 1 21 0)" stroke="#303133"/>
        </svg>
      </YearButton>
      </Header>
      <Months>
        {months.map(({ name, month }) => {
          const disabled = isDisabled(month);
          const hovered = isHovered(month);
          const selected = isSelected(month);

          return (
            <Month
              key={name}
              $hovered={hovered}
              $disabled={disabled}
              $selected={selected}
              onClick={handleClick(month)}
              onMouseEnter={handleMouseEnter(month)}
              onMouseLeave={handleMouseLeave(month)}
            >
              {t(`labels.${name}`)}
            </Month>
          );
        })}
      </Months>
      <Footer>Selected period: {[startDate, endDate].map(d => moment(d).format('DD-MM-YYYY')).join(' - ')}</Footer>
    </Container>
  );
};

export default MonthsPicker;
