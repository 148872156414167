import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 24px;
  align-items: center;
  padding: 2px;
  max-width: 200px;
`;

export const IconContainer = styled.div`
  height: 20px;
  min-height: 20px;
  width: 20px;
  border-radius: 1px 4px 4px 1px;
  background-color: #1E386A;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #fff;
  cursor: pointer;
`;

export const Text = styled.div`
  padding: 2px 6px;
  height: 20px;
  min-height: 20px;
  color: #fff;
  display: inline-block;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  align-items: center;
  background-color: #1E386A;
  border-radius: 4px 1px 1px 4px;
  font-size: 14px;
  line-height: 100%;

  ${styledProp('readonly', `
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  `)}
`;

