import styled from 'styled-components';

import { Colors } from '@res/theme';

export const Wrapper = styled.div`
  margin-top: 5px;
`;

export const InfoForm = styled.div`
  display: flex;
  padding: 14px 21px 0 21px;
  background: #F0F2F6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: 0.2s;
  overflow: visible;
  z-index: 2000;
`;

export const FieldValue = styled.p`
  margin: 0;
  font-size: 10px;
  color: ${Colors.Text.SECONDARY};
`;

export const NoIntegrations = styled.div`
  padding: 20px 14px;
  font-size: 13px;
  font-family: Rubik-Regular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
