import React from 'react';
import { Table as ANTDTable } from 'antd';

import { Container } from './styled';
import { TableLoader } from '@components';
import {testId as tid} from '@utils';

const Table = ({ testId, footer, containerStyles = {}, ...props }) => {
  return (
    <Container data-testid={testId} borderless={props.borderless} style={{ position: 'relative', ...containerStyles }}>
      <ANTDTable pagination={false} {...props} loading={{ spinning: !!props.loading, indicator: <TableLoader {...tid('table-loader')()} /> }}/>
      {footer}
    </Container>
  )
};

export default Table;
