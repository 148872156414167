import React, { useEffect } from 'react';
import 'dayjs/locale/ka';
import { Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as StoreProvider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { userSelector } from '@store/selectors/user';
import { useANTDLocale, useCurrentWorkspace, useTranslation } from '@hooks';
import { Paths } from './constants';
import {
  HomeScene,
  LoginPage,
  ResetPasswordPage,
  ResetPasswordFormPage,
  UnderConstructionPage,
  InviteUserFormPage,
  WhiteRegistrationPage,
  WhiteCreatePasswordPage,
  CreatePasswordPage,
  QuestionnaireSuccessPage,
  QuestionnairePage,
  WhiteEmailConfirmationSuccessPage,
  EmailConfirmationSuccessPage,
  VerifyEmailPage,
  RegistrationPage,
} from './pages';
import { RioService } from './services';
import { removeLeadingSlash, resolveAB, setTimezone } from '@utils';
import { ConfigProvider } from 'antd';
import i18n from '@res/languages'
import "@carbon/styles/css/styles.css";
import "@carbon/charts/styles.css";
import 'reactflow/dist/style.css';


import './index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { persistor, store } from '@store';
import { Routes } from 'react-router';
import AppRedirect from './pages/AppRedirect/AppRedirect';
import { ErrorBoundary } from '@components';

const AB_VERSION = resolveAB();

const ABEmailConfirmationSuccessPage = AB_VERSION === 'registration.white' ? WhiteEmailConfirmationSuccessPage : EmailConfirmationSuccessPage;
const ABCreatePasswordPage = AB_VERSION === 'registration.white' ? WhiteCreatePasswordPage : CreatePasswordPage;
const ABRegistrationPage = AB_VERSION === 'registration.white' ? WhiteRegistrationPage : RegistrationPage;

const App = (props) => {
  const workspace = useCurrentWorkspace();
  const user = useSelector(userSelector);
  const { i18n: i18nInstance } = useTranslation('app');
  const locale = useANTDLocale();

  useEffect(() => {
    if (!user?.language || !i18nInstance.isInitialized) {
      return;
    }

    i18nInstance.changeLanguage({ ge: 'ka-GE', en: 'en-US', [undefined]: 'en-US' }[user.language]);
  }, [user?.language, i18nInstance.isInitialized]);

  useEffect(() => {
    RioService.subscribe();
  }, []);

  useEffect(() => {
    if (workspace?.timezone) {
      setTimezone(workspace.timezone);
    }
  }, [workspace?.timezone]);

  return (
    <ConfigProvider locale={locale}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route path={removeLeadingSlash(Paths.LOGIN)} element={<LoginPage />} />
          <Route path={removeLeadingSlash(Paths.RESET_PASSWORD)} element={<ResetPasswordPage />} />
          <Route path={removeLeadingSlash(Paths.REGISTRATION)} element={<ABRegistrationPage />} />
          <Route path={removeLeadingSlash(Paths.REGISTRATION_SECOND_STEP)} element={<ABCreatePasswordPage />} />
          <Route path={removeLeadingSlash(Paths.EMAIL_VERIFICATION_SUCCESS_PAGE)} element={<ABEmailConfirmationSuccessPage />} />
          <Route path={removeLeadingSlash(Paths.VERIFY_EMAIL)} element={<VerifyEmailPage />} />
          <Route path={removeLeadingSlash(Paths.RESET_PASSWORD_FORM)} element={<ResetPasswordFormPage />} />
          <Route path={removeLeadingSlash(Paths.INVITE_USER_FORM)} element={<InviteUserFormPage />} />
          <Route path={removeLeadingSlash(Paths.UNDER_CONSTRUCTION)} element={<UnderConstructionPage />} />
          <Route path={removeLeadingSlash(Paths.QUESTIONNAIRE)} element={<QuestionnairePage />} />
          <Route path={removeLeadingSlash(Paths.QUESTIONNAIRE_SUCCESS)} element={<QuestionnaireSuccessPage />}/>
          <Route path={Paths.HOME} element={<HomeScene />} />
          <Route path="*" element={<AppRedirect />}/>
        </Routes>
      </QueryParamProvider>
    </ConfigProvider>
  );
};

const Wrapped = ({ ...props }) => {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <StoreProvider store={store}>
          <PersistGate persistor={persistor}>
            <GoogleReCaptchaProvider reCaptchaKey="6LehNk4gAAAAAAVC23VO81cGT0K8p-OygZjJ5CJO" language="en">
              <App {...props} />
            </GoogleReCaptchaProvider>
          </PersistGate>
        </StoreProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default Wrapped;
