import React, { useEffect } from 'react';
import { Icon, WithOnboarding, OnboardingHighlight } from '@components';
import { useModalState, useOnboarding, useTranslation } from '@hooks';

import { Container, TitleRow, Title, Category, CategoryName, Count, DropdownIcon, Option, ItemTitle } from './styled';

const CategoryMenu = ({ title, name, options, count, onFilterChange, filter, hasSubCategories }) => {
  const expandable = useModalState(true);
  const onboarding = useOnboarding('integrations');
  const { p, t } = useTranslation('integrations_page');

  useEffect(() => {
    if (options?.some(o => o.name === filter.name)) {
      expandable.open();
    }
  }, [filter.name]);

  if (!count) {
    return null;
  }

  const getHintParams = () => {
    if (name === 'api' && onboarding.enabled(1)) {
      return { hintText: p('choose_sdk'), hintIndex: null };
    }

    return { hintText: '', hintIndex: null };
  }

  const getOptionHintParams = () => {
    if (name === 'databases' && onboarding.enabled(4)) {
      return { optionHintText: p('choose_database'), optionHintIndex: 0 }
    }

    if (name === 'message_channels' && onboarding.enabled(7)) {
      return { optionHintText: p('choose_email'), optionHintIndex: 0 };
    }

    if (name === 'message_channels' && onboarding.enabled(10)) {
      return { optionHintText: p('choose_sms'), optionHintIndex: 1 };
    }

    if (name === 'message_channels' && onboarding.enabled(13)) {
      return { optionHintText: p('choose_webpush'), optionHintIndex: 3 };
    }

    return { optionHintText: '', optionHintIndex: null };
  }

  const { hintText } = getHintParams();
  const { optionHintText, optionHintIndex } = getOptionHintParams();

  const handleClick = (event) => {
    if (hasSubCategories) {
      expandable.toggle();
    } else {
      onFilterChange('category', name)(event);
    }
    if (hintText) {
      onboarding.nextStep();
    }
  }

  return (
      <Category
        $opened={expandable.opened}
        selected={filter.type === 'category' && filter.name === name}
      >
        <WithOnboarding enabled={!!hintText} text={hintText} onboardKey="integrations">
          <CategoryName
            selected={filter.name === name} onClick={hasSubCategories ? expandable.toggle : handleClick }>
            <OnboardingHighlight left={10} right={15} top={2} bottom={2} background="transparent" on={!hasSubCategories && !!hintText}>
              <ItemTitle>{title}</ItemTitle>
            </OnboardingHighlight>
            {!hasSubCategories ? (
              <Count>
                ({count})
              </Count>
            ) : (
              <DropdownIcon selected={expandable.opened}>
                <Icon size={12} name="Dropdown-arrow-icon" />
              </DropdownIcon>
            )}
          </CategoryName>
        </WithOnboarding>
        {options?.map(({ title, name, count }, index) => count ? (
            <Option selected={filter.name === name} key={name} onClick={e => { onFilterChange('sub-category', name)(e); if (optionHintText && index === optionHintIndex) { onboarding.nextStep(); }}}>
              <WithOnboarding enabled={hasSubCategories && !!optionHintText && index === optionHintIndex} text={optionHintText} onboardKey="integrations" getPopupContainer={() => document.body}>
                <OnboardingHighlight left={7} right={50} top={4.5} bottom={4.5} background="transparent" on={hasSubCategories && !!optionHintText && index === optionHintIndex}>
                  <ItemTitle>{title}</ItemTitle>
                </OnboardingHighlight>
              </WithOnboarding>
              <Count>({count})</Count>
            </Option>
        ) : null)}
      </Category>
  );
}

const MenuFilter = ({ options, title, filter, onFilterChange }) => {

  const handleFilterChange = (type, name) => () => {
    onFilterChange({ type, name });
  };

  return (
    <Container>
      <TitleRow>
        <Title>
          {title}
        </Title>
      </TitleRow>
      {options.map(({ title, name, options, count }) => (
        <CategoryMenu
          key={name}
          hasSubCategories={!!options?.length}
          title={title}
          name={name}
          options={options}
          count={count}
          filter={filter}
          onFilterChange={handleFilterChange}
        />
      ))}
    </Container>
  );
};

export default MenuFilter;
