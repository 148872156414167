import React from 'react';

import { Container, IconContainer } from './styled';
import { OnboardingHighlight } from '@components';
import { useTranslation } from '@hooks';

const SegmentContactsCaption = ({ total, filtered, onRefresh, loading, style, onboard }) => {
  const { p, t } = useTranslation('segments_page');

  return (
    <Container style={style} data-testid={'store-segment-filter-caption-container'}>
      <OnboardingHighlight on={onboard} style={{ display: 'flex', alignItems: 'center' }} top={5} bottom={5} right={5} left={5}>
        {p('customers')}: {filtered?.toLocaleString?.('ru-RU') || '0'} {p('of')} {total?.toLocaleString?.('ru-RU') || '0'}
        <IconContainer loading={loading} onClick={onRefresh} data-testid={'store-segment-reload-filter-icon'}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 5.7631e-07C3.445 5.7631e-07 1.3085 1.58 0.4375 3.8125L1.3595 4.1875C2.088 2.32 3.855 1 6 1C7.62 1 9.067 1.795 9.969 3H8V4H11.5V0.500001H10.5V2.047C9.93989 1.4039 9.24837 0.888398 8.47209 0.535277C7.69581 0.182156 6.85282 -0.000374315 6 5.7631e-07ZM10.64 7.8125C9.912 9.68 8.145 11 6 11C4.362 11 2.9215 10.194 2.015 9H4V8H0.5V11.5H1.5V9.953C2.595 11.193 4.1975 12 6 12C8.555 12 10.6915 10.42 11.5625 8.1875L10.6405 7.8125H10.64Z" fill="#109DFB"/>
          </svg>
        </IconContainer>
      </OnboardingHighlight>
    </Container>
  );
};

export default SegmentContactsCaption;
