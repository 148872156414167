import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from 'antd';

import { DiscountAttributes, DynamicAttributes, SubscriptionAttributes } from './components';
import { Container, Tabs, Tab, TabIndicator } from './styled';
import { useTranslation } from '@hooks';

const tabs = [
  {
    name: 'subscription',
    title: 'labels.subscription',
  },
  {
    name: 'discount',
    title: 'labels.discount',
  },
  {
    name: 'dynamic',
    title: 'labels.dynamic_attributes',
  },
];

const TemplatesGeneralAttributesDrawer = ({
  small,
  opened,
  onClose,
  onVisibilityChanged,
  width = null,
  customClassName,
  getContainer = false,
  bodyStyles = {},
  hiddenTabs = [],
}) => {
  const { t } = useTranslation('templates');
  const [tab, setTab] = useState(!!~hiddenTabs.indexOf('subscription') ? 'discount' : 'subscription');
  const refs = useRef(Object.fromEntries(tabs.map(({ name }) => [name, null])));
  const [tabEl, setTabEl] = useState(null);
  const [updater, setUpdater] = useState(false);

  const afterVisibleChange = (visibility) => {
    if (onVisibilityChanged) {
      onVisibilityChanged(visibility);
    }
  };

  useEffect(() => {
    setTimeout(() => setUpdater(u => !u), 200);
  }, [opened]);

  useEffect(() => {
    if (!refs.current[tab]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tab]);
  }, [updater, tab, refs.current[tab]]);

  return (
    <Drawer
      className={customClassName}
      placement="right"
      onClose={onClose}
      visible={opened}
      width={width || (small ? '617px' : '100%')}
      getContainer={getContainer}
      bodyStyle={{ padding: 0, overflow: 'hidden', borderRadius: 10, ...bodyStyles }}
      mask={false}
      style={{ position: 'absolute', height: '100%', top: 0, bottom: 0, left: 'auto', right: 0, width: width || (small ? '617px' : '100%'), pointerEvents: opened ? 'auto' : 'none' }}
      zIndex={1000}
      closable={false}
      afterVisibleChange={afterVisibleChange}
    >
      <Container>
        <Tabs>
          {tabs.filter(({ name }) => !~hiddenTabs.indexOf(name)).map(({ name, title }) => (
            <Tab
              key={name}
              id={`wpp-tab-${name}`}
              selected={name === tab}
              ref={rr => refs.current[name] = rr}
              onClick={() => setTab(name)}
            >
              {t(title)}
            </Tab>
          ))}
          <TabIndicator tab={tab} $tab={tabEl} />
        </Tabs>
        {tab === 'subscription' && (
          <SubscriptionAttributes />
        )}
        {tab === 'discount' && (
          <DiscountAttributes />
        )}
        {tab === 'dynamic' && (
          <DynamicAttributes />
        )}
      </Container>
    </Drawer>
  );
};

export default TemplatesGeneralAttributesDrawer;
