import { isNode } from 'reactflow';
import omit from 'lodash.omit';
import {
  by,
  createEdge,
  createFinishNode,
  deepEqual,
  extract,
  getConnectedNodes,
  getConnectors,
  getParent,
  getRoots,
  hasSplitters,
} from '@utils';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowDiffTypes, WorkflowNodes, WorkflowNodeTypes } from '@constants';
import uniqBy from 'lodash.uniqby';

const getPositioningId = n => n.data?.positioningId || n.id;

const getPositioningIndex = (n, ns) => {
  const index = ns.findIndex(by(getPositioningId(n)));

  if (index === -1) {
    return ns.findIndex(nn => nn.type === WorkflowNodeTypes.ARRAY && nn.data?.nodes?.findIndex(by(getPositioningId(n))))
  }

  return index;
};

export const insertNewBranchAfter = (n, ns, ...[nn, ...other]) => {
  const next = getConnectedNodes(n, ns);

  if (hasSplitters(n) && [nn.type, ...other.map(extract('type'))].indexOf(WorkflowNodeTypes.SPLITTER) === -1) {

    if (!next.length) {
      return ns;
    }

    return insertNewBranchAfter(next[0], ns, ...[nn, ...other]);
  }

  return ns.concat(
    createEdge({
      id: uuidv4(),
      source: n.id,
      weight: next.length + 1,
      target: nn.id,
    }),
    nn,
    ...other
  );
}

export const insertAfter = (n, ns, ...[nn, ...other]) => {
  if (!nn) {
    return ns;
  }

  if (hasSplitters(n) && [nn.type, ...other.map(extract('type'))].indexOf(WorkflowNodeTypes.SPLITTER) === -1) {
    const next = getConnectedNodes(n, ns);

    if (!next.length) {
      return ns;
    }

    return insertAfter(next[0], ns, ...[nn, ...other]);
  }

  const nc = ns.find(by('source', n.id));

  if (!nc) {
    return insertNewBranchAfter(n, ns, nn, ...other);
  }

  return ns
    .filter(({ id }) => id !== nc.id)
    .concat(
      { ...nc, id: `${n.id}-${nn.id}`, target: nn.id },
      nn,
      { ...nc, id: `${nn.id}-${nc.target}`, source: nn.id },
      ...other,
    ).sort((a, b) => getPositioningIndex(a, ns) - getPositioningIndex(b, ns));
};

export const insertBefore = (n, ns, nn) => {
  if (!ns.length) {
    return [nn];
  }

  if (n.type === WorkflowNodeTypes.SPLITTER) {
    return insertAfter(n, ns, nn);
  }

  const pc = ns.find(by('target', n.id));
  const parent = getParent(n, ns);

  if (parent?.data?.type === 'entry-point') {
    return insertAfter(n, ns, nn);
  }

  if (!pc) {
    return ns.concat(
      createEdge({
        id: `${nn.id}-${n.id}`,
        target: n.id,
        source: nn.id,
      }),
      nn
    );
  }

  return ns
    .filter(({ id }) => id !== pc?.id)
    .concat(
      { ...pc, id: `${pc?.source}-${nn.id}`, target: nn.id },
      nn,
      { ...pc, id: `${nn.id}-${pc?.target}`, source: nn.id, target: pc?.target },
    );
};

export const appendFinishNodes = (ns) => {
  const fns = removeUnusedConnectors(ns.filter(n => n.type !== WorkflowNodeTypes.FINISH));
  const res = [];

  const append = (fromN, ns, out) => {
    if (!fromN || !!out.find(by(fromN.id))) {
      return;
    }

    const cs = getConnectors(fromN, ns);
    const next = getConnectedNodes(fromN, ns);
    out.push(fromN, ...cs);

    if (!next.length && fromN.type !== 'finish_node' && fromN.data?.type !== 'entry_point') {
      const id = uuidv4();
      out.push(
        createFinishNode({
          id,
          data: { id },
          position: { x: fromN.position.x + 110, y: fromN.position.y }
        }),
        createEdge({
          // id: `${fromN.id}-${id}`,
          source: fromN.id,
          target: id,
        })
      );
    }

    next.forEach(nn => {
      append(nn, ns, out);
    });
  };
  getRoots(fns).forEach(root => append(root, fns, res));
  // append(getRoot(fns), fns, res);

  return uniqBy(res, extract('id'));
};

export const getDiff = (ns, compareWith, isSource, enabledDiffTypes) => {
  return ns.map(n => {
    if (!isNode(n) || n.type === WorkflowNodeTypes.FINISH || n.type === WorkflowNodeTypes.ARRAY) {
      return n;
    }

    const compareWithNode = compareWith.find(({ data }) => data?.originId === n.data.originId);

    if (!compareWithNode) {
      const diffType = isSource ? WorkflowDiffTypes.DELETED : WorkflowDiffTypes.CREATED
      return {
        ...n,
        data: {
          ...n.data,
          diff: {
            type: diffType,
            disabled: enabledDiffTypes.indexOf(diffType) === -1,
          }
        }
      };
    }

    const equal = deepEqual(omit(n.data, ['id', 'arrayStart', 'serverId', 'temp', 'renderId']), omit(compareWithNode.data, ['id', 'arrayStart', 'serverId', 'temp', 'renderId']));

    let diffType;

    if (equal) {
      diffType = WorkflowDiffTypes.NOT_CHANGED;
    } else {
      diffType = WorkflowDiffTypes.UPDATED;
    }

    return {
      ...n,
      data: {
        ...n.data,
        compareWithNode,
        diff: {
          type: diffType,
          disabled: enabledDiffTypes.indexOf(diffType) === -1,
        }
      }
    };
  });
};

export const removeUnusedConnectors = (ns) => {
  return ns.filter(({ source, target }) => {
    if (!source && !target) {
      return true;
    }

    return ns.some(by(source)) && ns.some(by(target));
  });
};

export const copyState = (root, styles, newOrigin = false) => {
  const idMap = {};
  let newStyles = {};

  const copyRoot = (root) => {
    const [nodes, nodesStyles] = root.data?.nodes ? copyState(root.data?.nodes, styles, newOrigin) : [undefined, {}];
    const children = root.data?.children ? root.data.children.map(child => copyState(child, styles, newOrigin)[0]) : []

    newStyles = { ...newStyles, ...nodesStyles, ...children.map((_, s) => s).reduce((a, b) => ({ ...a, ...b }), {}) };

    if (idMap[root.id]) {
      return {
        ...root,
        id: idMap[root.id],
        data: {
          ...root.data,
          id: idMap[root.id],
          originId: newOrigin ? idMap[root.id] : root.data?.originId,
          serverId: uuidv4(),
          cloneOf: idMap[root.data?.cloneOf],
          children,
          nodes,
        },
        children: (root.children || []).map(child => copyRoot(child)),
      };
    }

    const newId = uuidv4();
    idMap[root.id] = newId;

    return {
      ...root,
      id: newId,
      data: {
        ...root.data,
        id: newId,
        originId: newOrigin ? idMap[root.id] : root.data?.originId,
        serverId: uuidv4(),
        nodes,
        children,
      },
      children: (root.children || []).map(child => copyRoot(child)),
    };
  };

  const newRoot = copyRoot(root);

  newStyles = {
    ...newStyles,
    ...styles,
    ...Object.fromEntries(
      Object.entries(styles)
        .filter(([, s]) => !!s)
        .map(([id, style]) => [
          idMap[id],
          { ...style, id: idMap[id] }
        ])
    )
  };

  return [newRoot, newStyles];
};
