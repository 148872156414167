import React from 'react';

import { Container, Field } from './styled.js';
import { useSelector } from 'react-redux';
import { fieldsSettingsListSelector } from '@store/selectors';
import { SegmentsRuleTypes } from '@constants';
import { NoData } from '@components';

const LinearFilterPanel = ({ onSelect, hidePreview }) => {
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const linearFields = fieldSettings?.data?.filter(f => f.type !== 'array');

  if (!linearFields?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-linear-tab-content'}>
      {linearFields?.map((lf, idx) => <Field key={idx} data-testid={`store-segment-filter-panel-linear-field-${lf.field}`} onClick={() => onSelect(lf, SegmentsRuleTypes.LINEAR)}>{lf.label}</Field>)}
    </Container>
  );
}

export default LinearFilterPanel;


