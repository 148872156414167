import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 40px;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  height: 18px;
`;

export const In = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #F0F2F6;
`;

export const Out = styled.div`
  width: 100%;
  height: 100%;
  background: #F0F2F6;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid transparent;
`;

export const InText = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;

export const OutText = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #24993E;
  display: flex
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
`;
