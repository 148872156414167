import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 4px;
  cursor: pointer;
  font-family: Rubik-Regular;
  line-height: 130%;
  text-transform: uppercase;
  color: #31C447;
`;
