export const StatusColors = {
  sent: '#813DC3',
  dropped: '#F97900',
  queued: '#F97900',
  deferred: '#4F51C4',
  bounce: '#A2E2AC',
  blocked: '#A860DA',
  delivered: '#FAAD3D',
  open: '#DD72D4',
  processed: '#FFD953',
  click: '#F49389',
  spam_report: '#746EC4',
  unsubscribed: '#01AE73',
  failed: '#EEA7B4',
  resubscribed: '#C8B9ED',
  sending: '#A2E2AC',
  undelivered: '#A860DA',
  dismiss: '#4F51C4',
};
