const EmailStatuses = {
  SENT: 'sent',
  DROPPED: 'dropped',
  DEFERRED: 'deferred',
  BOUNCE: 'bounce',
  BLOCKED: 'blocked',
  DELIVERED: 'delivered',
  OPEN: 'open',
  PROCESSED: 'processed',
  CLICK: 'click',
  SPAM_REPORT: 'spam_report',
  UNSUBSCRIBED: 'unsubscribed',
  FAILED: 'failed',
  RESUBSCRIBED: 'resubscribed',
};

const EmailStatusLabels = {
  [EmailStatuses.SENT]: 'statuses.sent',
  [EmailStatuses.DROPPED]: 'statuses.dropped',
  [EmailStatuses.DEFERRED]: 'statuses.deferred',
  [EmailStatuses.BOUNCE]: 'statuses.bounce',
  [EmailStatuses.BLOCKED]: 'statuses.blocked',
  [EmailStatuses.DELIVERED]: 'statuses.delivered',
  [EmailStatuses.PROCESSED]: 'statuses.processed',
  [EmailStatuses.OPEN]: 'statuses.open',
  [EmailStatuses.CLICK]: 'statuses.click',
  [EmailStatuses.SPAM_REPORT]: 'statuses.spam_report',
  [EmailStatuses.UNSUBSCRIBED]: 'statuses.unsubscribed',
  [EmailStatuses.FAILED]: 'statuses.failed',
  [EmailStatuses.RESUBSCRIBED]: 'statuses.resubscribed',
};

const EmailStatusOptions = [
  { values: Object.keys(EmailStatusLabels), label: 'statuses.all_statuses' },
  ...Object.entries(EmailStatusLabels).map(([value, label]) => ({ value, label }))
];

export { EmailStatuses, EmailStatusLabels, EmailStatusOptions }
