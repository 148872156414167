import WorkflowNodes from './nodeNames';
import { UserPermissions } from '@constants';

const WorkflowNodeItems = [
  {
    title: 'entry_points',
    type: 'entry_point',
    disabledTooltip: 'disabled_tt_entry',
    options: [
      {
        title: 'segment',
        name: WorkflowNodes.RESOURCE,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'event',
        name: WorkflowNodes.EVENT,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
      {
        title: 'email',
        name: WorkflowNodes.EMAIL,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
      {
        title: 'page_view',
        name: WorkflowNodes.PAGE_VIEW,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
      {
        title: 'customer_addition',
        name: WorkflowNodes.CONTACT_CREATED,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
      {
        title: 'data_change',
        name: WorkflowNodes.DATA_CHANGE,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
      {
        title: 'entry_from_workflow',
        name: WorkflowNodes.ENTRY_ANOTHER_WF,
        permissions: [UserPermissions.REVIEW_WORKFLOWS]
      },
    ],
  },
  {
    title: 'channels',
    type: 'connection_channel',
    disabledTooltip: 'disabled_tt_channel',
    options: [
      {
        title: 'send_sms',
        name: WorkflowNodes.SEND_SMS,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_SMS_NODES],
      },
      {
        title: 'send_email',
        name: WorkflowNodes.SEND_EMAIL,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_EMAIL_NODES],
      },
      {
        title: 'push_discount',
        name: WorkflowNodes.PUSH_DISCOUNT,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_DISCOUNT_NODES],
      },
      {
        title: 'mobile_push',
        name: WorkflowNodes.MOBILE_PUSH,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_MOBILE_PUSH_NODES],
      },
      {
        title: 'web_push',
        name: WorkflowNodes.WEBPUSH,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_WEBPUSH_NODES],
      },
      {
        title: 'viber',
        name: WorkflowNodes.VIBER,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_VIBER_NODES],
      },
      {
        title: 'webhook',
        name: WorkflowNodes.API_REQUEST,
        permissions: [UserPermissions.REVIEW_WORKFLOWS, UserPermissions.CREATE_API_REQUEST_NODES],
      },
    ],
  },
  {
    title: 'actions',
    type: 'actions',
    disabledTooltip: 'disabled_tt_channel',
    options: [
      {
        title: 'wait',
        name: WorkflowNodes.WAIT,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'change_attribute',
        name: WorkflowNodes.CUSTOMER_CHANGE,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'exclude_filter',
        name: WorkflowNodes.EXCLUDE_FILTER,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'quick_filter',
        name: WorkflowNodes.QUICK_FILTER,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'switch_filter',
        name: WorkflowNodes.SWITCH_FILTER,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'ab_testing',
        name: WorkflowNodes.AB_SPLITTER,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
      {
        title: 'retargeting',
        name: WorkflowNodes.RETARGETING,
        permissions: [UserPermissions.REVIEW_WORKFLOWS],
      },
    ],
  },
];

export default WorkflowNodeItems;
