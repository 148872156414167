import styled from 'styled-components';

export const Container = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
  padding-bottom: 30px;
`;
