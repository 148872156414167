import React from 'react';
import { Tooltip } from 'antd';

import {
  Container,
  Inner,
  Title,
  Text,
  Action,
  Image,
  InfoIconContainer,
  Position,
  ActionsContainer
} from './styled.js';
import { Icon } from '@components';
import { useTranslation } from '@hooks';

const Block = ({ style, title, text, action, onAction, image, small, medium, onSkip, exist, step, blockKey, highlight, tooltip, displayText, position, first }) => {
  const { t, p } = useTranslation('dashboard_page');

  const imageInner = highlight === 'footer' || highlight === 'secondary';
  return (
    <Container small={small} medium={medium} style={style} highlight={highlight}>
      {(!displayText && small) && (
        <Tooltip placement="bottom" title={tooltip}>
          <InfoIconContainer exist={exist}>
            <Icon name="Info-icon" size={12} color="#909399" />
          </InfoIconContainer>
        </Tooltip>
      )}
      <Inner highlight={highlight}>
        <div style={{ zIndex: 1 }}>
          <Title small={small} medium={medium} exist={exist} highlight={highlight}>{title}</Title>
          <Text highlight={highlight}>{text}</Text>
        </div>
        <ActionsContainer start={blockKey !== 'integrations' || medium}>
          {((action && !small && onAction && highlight) || medium) && (<Action static={blockKey === 'integrations'} highlight={highlight} secondary={medium} onClick={onAction}>{action}</Action>)}
          {(blockKey === 'integrations' && !medium && !exist) && (step === 'integrations') && (<Action static secondary onClick={onSkip}>{p('setup_later')}</Action>)}
        </ActionsContainer>
        {imageInner && (
          <Image highlight={highlight} small={small}>{small ? image.small : image.large}</Image>
        )}
      </Inner>
      {!imageInner && (
        <Image highlight={highlight} small={small}>{small ? image.small : image.large}</Image>
      )}
    </Container>
  );
};

export default Block;


