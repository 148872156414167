import { ReducerKeys } from '@constants';
import { createSelector } from 'reselect';
import { nestedAggregatesListSelector } from '@store/selectors';

export const eventsListSelector = store => store[ReducerKeys.EVENTS].events;

export const eventsLoadingSelector = store => store[ReducerKeys.EVENTS].loading;

export const eventListWithNestedAggregaesSelector = createSelector(
    nestedAggregatesListSelector,
    eventsListSelector,
    (nestedAggregates, events) => {
      if (!events?.data || !nestedAggregates?.data) return [];
    
      const eventList = events.data;
      const nestedAggregatesList = nestedAggregates.data;
      const mapNestedAggregates = (event) => {
        if (Array.isArray(event.nested_aggregates)) {
          const nested_aggregates = event.nested_aggregates?.map(
            (nestedAggregateId) =>
              nestedAggregatesList.find(({ id }) => id === nestedAggregateId)
          );
          return { ...event, nested_aggregates };
        }
        return event;
      };
      return {
        ...events,
        data: eventList.map(mapNestedAggregates),
      };
    }
  );