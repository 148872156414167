import React from 'react';

import { Container, Tab } from './styled';
import { OnboardingHighlight, WithOnboarding } from '@components';
import { useTranslation } from '@hooks';
import { identity } from '@utils';

const SliderTabs = ({ tabs, tab, onTabChange, tabStyle, onboardParams, localize, ...props }) => {
  const { t } = useTranslation('');

  const handleSelect = name => () => {
    onTabChange(name);
  };

  const l = localize || identity;

  return (
   <Container {...props}>
     {tabs.map(({ name, label }, index, arr) => onboardParams ? (
       <WithOnboarding enabled={onboardParams.enabled && index === onboardParams.index} text={onboardParams.text} onboardKey={onboardParams.key} next={onboardParams.key}>
         <OnboardingHighlight radius={7} on={onboardParams.enabled && index === onboardParams.index}>
          <Tab
            key={index}
            style={{ marginRight: index !== arr.length - 1 ? '8px' : '0px', ...(tabStyle || {}) }}
            selected={tab === name}
            data-testid={`slider-tabs-${name}`}
            onClick={handleSelect(name)}
          >
            {l(label)}
          </Tab>
         </OnboardingHighlight>
       </WithOnboarding>
     ) : (
       <Tab
         key={index}
         style={{ marginRight: index !== arr.length - 1 ? '8px' : '0px', ...(tabStyle || {}) }}
         selected={tab === name}
         onClick={handleSelect(name)}
       >
         {l(label)}
       </Tab>
     ))}
   </Container>
  );
};

export default SliderTabs;
