import { Paths, UserPermissions } from '../../constants';

export default [
  // {
  //   path: Routes.EXPRESSIONS,
  //   name: 'expressions',
  //   icon: 'Sidebar-Dashboard',
  // },
  {
    path: Paths.DASHBOARD,
    name: 'dashboard',
    icon: 'Sidebar-Dashboard',
  },
  {
    name: 'analytics',
    icon: 'Analytic',
    path: '/analytics',
    fallback: Paths.FUNNEL_ANALYTICS,
    pages: [
      {
        name: 'funnel',
        path: Paths.FUNNEL_ANALYTICS,
        isBeta: true
      },
      {
        name: 'rfm',
        path: Paths.RFM_ANALYTICS,
        permission: UserPermissions.REVIEW_RFM,
      },
    ],
  },
  {
    path: Paths.CAMPAIGNS,
    name: 'campaigns',
    icon: 'Campaign',
    permission: UserPermissions.REVIEW_WORKFLOWS,
  },
  {
    path: Paths.WORKFLOWS,
    name: 'workflows',
    icon: 'Sidebar-Workflow',
    permission: UserPermissions.REVIEW_WORKFLOWS,
  },
  {
    name: 'segments',
    icon: 'Sidebar-Resources',
    path: Paths.RESOURCES_SEGMENTS,
    permission: UserPermissions.REVIEW_SEGMENTS,
    // pages: [
    //   {
    //     name: 'Files',
    //     path: Routes.RESOURCES_FILES
    //   },
    //   {
    //     name: 'Queries',
    //     path: Routes.RESOURCES_QUERIES
    //   },
    //   {
    //     name: 'Segments',
    //     path: Routes.RESOURCES_SEGMENTS
    //   },
    //   {
    //     name: 'Combined Segments',
    //     path: Routes.RESOURCES_COMBINED_SEGMENTS
    //   },
    // ],
  },
  {
    name: 'contacts',
    icon: 'Sidebar-Contacts',
    path: Paths.CONTACTS_ALL,
    pages: [
      {
        name: 'all',
        path: Paths.CONTACTS_ALL,
        permission: UserPermissions.REVIEW_CONTACTS,
      },
      {
        name: 'import',
        permission: UserPermissions.CSV_IMPORT,
        path: `${Paths.CONTACTS_IMPORT}/import`
      },
      // {
      //   name: 'Import History',
      //   path: Routes.CONTACTS_IMPORT_HISTORY
      // },
      {
        name: 'field_settings',
        permission: UserPermissions.REVIEW_CONTACT_FIELDS,
        path: Paths.CONTACTS_FIELDS_SETTINGS
      },
      {
        name: 'subscription_groups',
        permission: UserPermissions.REVIEW_UNSUBSCRIBE_GROUPS,
        path: Paths.SUBSCRIPTION_GROUPS
      },
    ],
  },
  {
    name: 'templates',
    icon: 'Sidebar-Templates',
    path: '/templates',
    fallback: `${Paths.TEMPLATES_EMAILS}/list/default`,
    pages: [
      {
        name: 'email',
        path: `${Paths.TEMPLATES_EMAIL}/list/default`,
        permission: UserPermissions.REVIEW_EMAILS,
      },
      {
        name: 'sms',
        path: `${Paths.TEMPLATES_SMSES}/default`,
        permission: UserPermissions.REVIEW_SMS,
      },
      {
        name: 'viber',
        path: `${Paths.TEMPLATES_VIBERS}/default`,
      },
      {
        name: 'webpush',
        path: `${Paths.TEMPLATES_WEBPUSHES}/default`,
        permission: UserPermissions.REVIEW_WEBPUSHES,
      },
      {
        name: 'mobile_push',
        path: `${Paths.TEMPLATES_MOBILE_PUSHES}/default`,
        permission: UserPermissions.REVIEW_MOBILE_PUSH,
      },
      {
        name: 'api_request',
        path: `${Paths.TEMPLATES_API_REQUESTS}/default`,
        permission: UserPermissions.REVIEW_API_REQUESTS,
      },
    ],
  },
  // {
  //   path: Routes.EVENTS,
  //   name: 'events',
  //   icon: 'Sidebar-Events',
  //   permission: UserPermissions.REVIEW_EVENTS,
  // },
  {
    path: Paths.PROMO_CODES,
    name: 'promo_codes',
    icon: 'Sidebar-Promo-codes',
  },
  {
    name: 'notification_history',
    icon: 'Sidebar-Notifications-history',
    path: Paths.NOTIFICATIONS,
    permission: UserPermissions.NOTIFICATION_HISTORY,
    // pages: [
    //   {
    //     name: 'Sms',
    //     path: Routes.NOTIFICATIONS_SMS
    //   },
    //   {
    //     name: 'Email',
    //     path: Routes.NOTIFICATIONS_EMAIL
    //   },
      // {
      //   name: 'Mobile pushes',
      //   path: Routes.NOTIFICATIONS_MOBILE_PUSHES
      // },
      // {
      //   name: 'Browser pushes',
      //   path: Routes.NOTIFICATIONS_BROWSER_PUSHES
      // },
    // ],
  },
  // {
  //   path: Routes.UPDATES_HISTORY,
  //   name: 'Updates history',
  //   icon: 'Sidebar-Update-history',
  // },
  // {
  //   path: Routes.APPLICATIONS,
  //   name: 'Applications',
  //   icon: 'Sidebar-Applications',
  // },
]
