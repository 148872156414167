import {
    FUNCTIONS, NODE_TYPE_ARRAY, NODE_TYPE_EVENT,
} from './../constants';

import { useDispatch, useSelector } from 'react-redux';
import {
    expressionSelector
} from '@store/selectors';
import {
    expressionValueSelected
} from '@store/actions/creators';

import {NODE_TYPE_CONSTANT, NODE_TYPE_FUNCTION, NODE_TYPE_LINEAR} from "../constants";

import {eventsSelector, fieldsSettingsListSelector} from "@store/selectors";

const MOCK_CUSTOMER_FIELDS = ['first_name','last_name', 'age','email','gender','phone', 'aaaaaaaaaaaaaaa','bbbbbbbbbbbbbb','ccccccccccccc','ddddddddddddd','eeeeeeeeeeeeee','fffffffffffff', 'created_at'];

const ValueSelector = (props) => {

    const fieldSettings = useSelector(fieldsSettingsListSelector);

    const events = useSelector(eventsSelector);

    const dispatch = useDispatch();

    const renderedSelector = () => {
        if(props.expression.selected.type === NODE_TYPE_CONSTANT) {
            return <input type="text" value={props.expression.selected.value}  onChange={(e) => dispatch(expressionValueSelected({...props.expression.selected, value:e.target.value}))}/>
        } else if(props.expression.selected.type === NODE_TYPE_FUNCTION) {
            return <select name="" id="" value={props.expression.selected.value} onChange={(e) => dispatch(expressionValueSelected({...props.expression.selected, value:e.target.value}))}>
                <option value={null}>---</option>
                {FUNCTIONS.map(val => <option value={val}>{val}</option>)}
            </select>
        } else if(props.expression.selected.type === NODE_TYPE_LINEAR && fieldSettings?.data?.length) {
            return <select name="" id="" value={props.expression.selected.value} onChange={(e) => dispatch(expressionValueSelected({...props.expression.selected, value:e.target.value}))}>
                <option value={null}>---</option>
                {fieldSettings.data.map(val => {
                    if(val.type === 'array') {return}
                    return <option key={val.field} value={val.field}>{val.label}</option>
                })}
            </select>
        }  else if(props.expression.selected.type === NODE_TYPE_ARRAY && fieldSettings?.data?.length) {
            return <select name="" id="" value={props.expression.selected.value} onChange={(e) => dispatch(expressionValueSelected({...props.expression.selected, value:e.target.value}))}>
                <option value={null}>---</option>
                {
                    fieldSettings
                    .data
                    .find(item => item.field === props.expression.for.value)
                    .payload
                    .map(val => {
                    return <option key={val.field} value={val.field}>{val.label}</option>
                })}
            </select>
        }else if(props.expression.selected.type === NODE_TYPE_EVENT && events?.data?.length) {
            return <select name="" id="" value={props.expression.selected.value} onChange={(e) => dispatch(expressionValueSelected({...props.expression.selected, value:e.target.value}))}>
                <option value={null}>---</option>
                {
                    events
                        .data
                        .find(item => item.name === props.expression.for.value)
                        .payload
                        .map(val => {
                            return <option key={val.field} value={val.field}>{val.label}</option>
                        })}
            </select>
        }else {
            return "SELECT TYPE"
        }
    }

    return (<div>{renderedSelector()}</div>)
};

export default ValueSelector;