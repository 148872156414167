import { useCallback, useEffect } from 'react';
import { useOrdering, usePagination, useFilters } from './index';

const useTable = ({ filtersConfig = {}, paginationConfig, requestDispatcher, metaDispatcher, metaDeps = [], deps = [], outerFilters = null, dynamicFilters = [], initialOrdering = '' } = {}) => {
  const pagination = usePagination(paginationConfig);
  const tableFilters = useFilters(filtersConfig, dynamicFilters);
  const ordering = useOrdering(initialOrdering);

  const filters = outerFilters || tableFilters;

  const dynamicFiltersChanger = dynamicFilters.map(df => filters.filters[df] || '').map(fv => {
    if (Array.isArray(fv)) {
      return fv.join(',');
    }

    if (typeof fv === 'object' && fv !== null) {
      return Object.entries(fv).map(e => e.join(':')).join(',');
    }

    return String(fv);
  }).join(';');

  useEffect(() => {
    metaDispatcher?.({ ...ordering.params, ...filters.filters });
  }, [
    dynamicFiltersChanger,
    ...metaDeps,
  ]);

  useEffect(() => {
    requestDispatcher?.({
      page: isNaN(pagination.page) ? 0 : pagination.page + 1,
      perPage: isNaN(pagination.perPage) ? 20 : pagination.perPage,
      ...ordering.params,
      ...filters.filters
    });
  }, [
    pagination.page,
    pagination.perPage,
    ordering.params.orderCol,
    ordering.params.orderDir,
    dynamicFiltersChanger,
    ...deps,
  ]);

  const applyFilters = () => {
    pagination.handlers.onPageChange(0);
    requestDispatcher?.({
      page: 1,
      perPage: isNaN(pagination.perPage) ? 20 : pagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {}),
      ...filters.filters
    });
    metaDispatcher?.({ ...(outerFilters || {}), ...(tableFilters?.filters || {}), ...filters.filters })
  };

  const discardFilters = () => {
    filters.clear();
    pagination.handlers.onPageChange(0);
    requestDispatcher?.({
      page: 1,
      perPage: isNaN(pagination.perPage) ? 20 : pagination.perPage,
      ...filtersConfig,
      ...ordering.params
    });
    metaDispatcher?.({})
  };

  const discardWithoutRefresh = () => {
    filters.clear();
    pagination.handlers.onPageChange(0);
  };

  const changePage = (page) => {
    pagination.handlers.onPageChange(page);
    // requestDispatcher({ page, perPage: pagination.perPage, ...ordering.params, ...(outerFilters || {}), ...(tableFilters?.filters || {})  });
  };

  const changePerPage = (perPage) => {
    pagination.handlers.onPerPageChange(perPage);
    pagination.handlers.onPageChange(0);
  };

  const refresh = useCallback(() => {
    requestDispatcher?.({
      page: isNaN(pagination.page) ? 0 : pagination.page + 1,
      perPage: isNaN(pagination.perPage) ? 20 : pagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {})
    });
    metaDispatcher?.({ ...(outerFilters || {}), ...(tableFilters?.filters || {}) });
  }, [requestDispatcher, metaDispatcher]);

  return {
    applyFilters,
    discardFilters,
    changePage,
    changePerPage,
    discardWithoutRefresh,
    refresh,
    ordering,
    filters,
    pagination,
    requestParams: {
      page: isNaN(pagination.page) ? 0 : pagination.page + 1,
      perPage: isNaN(pagination.perPage) ? 20 : pagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {})
    }
  };
};

export default useTable;
