import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ConnectionLine = styled.div`
  height: 1px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #DCDFE6;
  left: -16px;
  z-index: -1;

  ${styledProp('$a', css`
    background: #909399;
  `, css`
    background: #DCDFE6;
  `)}
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  border-radius: 50%;
  
  ${styledProp('$a', css`
    background: #31C447;
  `, css`
    background: #D3DBE7;
  `)}
`;

export const WorkflowsList = styled.div`
  border-radius: 7px;
  border: 1px solid var(--stroke, #DCDFE6);
  background: var(--white, #FFF);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
  position: relative;
  padding: 20px 14px;
  padding-bottom: 56px;
  overflow: hidden;
`;

export const WorkflowListTitle = styled.div`
  color: var(--gray, #909399);
  font-family: Rubik-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 16px;
`;

export const AddWorkflowButton = styled.div`
  padding: 11px 14px;
  position: absolute;
  border-top: 1px solid var(--stroke, #DCDFE6);
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
