import { all, takeLatest, put } from 'redux-saga/effects';
import { IntegrationsActionTypes } from '../../actions/types';
import { Endpoints } from '@constants';
import { createRequestAction } from '@store/lib/utils/creators';

function* changeIntegrationStatusSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/enable',
    body: {
      enabled: action.payload.enabled,
      app_integration_id: action.payload.app_integration_id,
    }
  }));
}

function* checkMySQLConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/mysql/check-connection`,
    body: {
      port: action.payload.port,
      host: action.payload.host,
      database: action.payload.database,
      username: action.payload.username,
      password: action.payload.password
    },
    disableTimeout: true,
  }));
}

function* checkPostgresConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/postgresql/check-connection`,
    body: {
      port: action.payload.port,
      host: action.payload.host,
      database: action.payload.database,
      username: action.payload.username,
      password: action.payload.password
    },
    disableTimeout: true,
  }));
}

function* deleteIntegrationConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/delete',
    body: {
      app_integration_id: action.payload.app_integration_id,
    }
  }));
}

function* generateFacebookLoginUrlSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/facebook/login-url',
    body: {
      redirect_url: action.payload.redirect_url,
      state: encodeURIComponent(JSON.stringify(action.payload.state)),
    },
  }));
}

function* generateFacebookTokenSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/facebook/generate-token',
    body: {
      redirect_url: action.payload.redirect_url,
      app_integration_id: action.payload.app_integration_id,
      code: action.payload.code,
      entity_id: action.payload.entity_id,
      granted_access: action.payload.granted_scopes,
      denied_access: action.payload.denied_scopes
    },
  }));
}

function* getFacebookAdAccountSaga(action) {
  yield put(createRequestAction(action, {
    endpoint: '/api/integrations/facebook/ad-account',
    method: 'get',
    params: {
      entity_id: action.payload.entity_id,
      limit: action.payload.limit,
      offset: action.payload.offset,
    },
  }));
}

function* getFacebookAudiencesSaga(action) {
  const params = { app_integration_id: action.payload.app_integration_id };

  if (action.payload.limit) {
    params.limit = action.payload.limit;
  }

  if (action.payload.after) {
    params.limit = action.payload.after;
  }

  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/integrations/facebook/audience',
    params,
  }));
}

function* getFacebookUserInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/integrations/facebook/user-info',
    params: {
      entity_id: action.payload.entity_id,
    }
  }));
}

function* getJSIntegrationMetaSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.INTEGRATIONS}/javascript`,
  }));
}

function* getMysqlDatasyncLogSaga(action) {
  yield put(createRequestAction(action, {
    endpoint: `/api/integrations/mysql/datasync-log/${action.payload.entityId}`,
    method: 'get'
  }));
}

function* getPostgresDatasyncLogSaga(action) {
  yield put(createRequestAction(action, {
    endpoint: `/api/integrations/postgresql/datasync-log/${action.payload.entityId}`,
    method: 'get',
  }));
}

function* listIntegrationsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.INTEGRATIONS,
  }));
}

function* logoutFromFacebookSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/facebook/log-out',
    body: {
      entity_id: action.payload.entity_id,
      app_integration_id: action.payload.app_integration_id,
    }
  }));
}

function* myListIntegrationsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.MY_INTEGRATIONS,
  }));
}

function* previewMySQLConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/mysql/preview`,
    body: {
      sql: action.payload.sql,
      port: action.payload.port,
      host: action.payload.host,
      database: action.payload.database,
      username: action.payload.username,
      password: action.payload.password
    }
  }));
}

function* previewPostgresConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/postgresql/preview`,
    body: action.payload,
  }));
}

function* requestFacebookLoginUrlSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/facebook/rerequest-login-url',
    body: {
      redirect_url: action.payload.redirect_url,
      entity_id: action.payload.entity_id,
      state: encodeURIComponent(JSON.stringify(action.payload.state)),
    },
  }));
}

function* saveConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.INTEGRATIONS,
    body: {
      app_integration_id: action.meta.app_integration_id,
      type: action.payload.type,
      name: action.payload.name,
      integration_key: action.payload.integration_key,
      enabled: action.payload.enabled,
      ...action.payload.config,
    },
  }));
}

function* saveFacebookIntegrationConnectionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/integrations/facebook/integration',
    body: action.payload,
  }));
}

function* updateDatabaseIntegrationConnectionsMetaSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.MY_INTEGRATIONS,
  }));
}

function* updateJSIntegrationSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/javascript`,
    body: action.payload,
  }));
}

function* updateMySQLIntegrationSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/mysql`,
    body: action.payload,
  }));
}

function* updatePostgresIntegrationSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.INTEGRATIONS}/postgresql`,
    body: action.payload,
  }));
}

export default function* integrationsRootSaga() {
  yield all([
    takeLatest(IntegrationsActionTypes.LIST, listIntegrationsSaga),
    takeLatest(IntegrationsActionTypes.MY_LIST, myListIntegrationsSaga),
    takeLatest(IntegrationsActionTypes.SAVE_CONNECTION, saveConnectionSaga),
    takeLatest(IntegrationsActionTypes.GET_JS_INTEGRATION_META, getJSIntegrationMetaSaga),
    takeLatest(IntegrationsActionTypes.UPDATE_JS_INTEGRATION, updateJSIntegrationSaga),
    takeLatest(IntegrationsActionTypes.CHECK_POSTGRES_CONNECTION, checkPostgresConnectionSaga),
    takeLatest(IntegrationsActionTypes.CHECK_MYSQL_CONNECTION, checkMySQLConnectionSaga),
    takeLatest(IntegrationsActionTypes.PREVIEW_POSTGRES_CONNECTION, previewPostgresConnectionSaga),
    takeLatest(IntegrationsActionTypes.PREVIEW_MYSQL_CONNECTION, previewMySQLConnectionSaga),
    takeLatest(IntegrationsActionTypes.UPDATE_POSTGRES_INTEGRATION, updatePostgresIntegrationSaga),
    takeLatest(IntegrationsActionTypes.UPDATE_MYSQL_INTEGRATION, updateMySQLIntegrationSaga),
    takeLatest(IntegrationsActionTypes.GET_POSTGRES_DATASYNC_LOG, getPostgresDatasyncLogSaga),
    takeLatest(IntegrationsActionTypes.GET_MYSQL_DATASYNC_LOG, getMysqlDatasyncLogSaga),
    takeLatest(IntegrationsActionTypes.UPDATE_DB_INTEGRATION_CONNECTIONS_META, updateDatabaseIntegrationConnectionsMetaSaga),
    takeLatest(IntegrationsActionTypes.GENERATE_FACEBOOK_LOGIN_URL, generateFacebookLoginUrlSaga),
    takeLatest(IntegrationsActionTypes.GENERATE_FACEBOOK_TOKEN, generateFacebookTokenSaga),
    takeLatest(IntegrationsActionTypes.GET_FACEBOOK_AD_ACCOUNT, getFacebookAdAccountSaga),
    takeLatest(IntegrationsActionTypes.GET_FACEBOOK_AUDIENCES, getFacebookAudiencesSaga),
    takeLatest(IntegrationsActionTypes.CHANGE_INTEGRATION_CONNECTION_STATUS, changeIntegrationStatusSaga),
    takeLatest(IntegrationsActionTypes.DELETE_INTEGRATION_CONNECTION, deleteIntegrationConnectionSaga),
    takeLatest(IntegrationsActionTypes.SAVE_FACEBOOK_INTEGRATION_CONNECTION, saveFacebookIntegrationConnectionSaga),
    takeLatest(IntegrationsActionTypes.GET_FACEBOOK_USER_INFO, getFacebookUserInfoSaga),
    takeLatest(IntegrationsActionTypes.LOGOUT_FROM_FACEBOOK, logoutFromFacebookSaga),
    takeLatest(IntegrationsActionTypes.REQUEST_FACEBOOK_LOGIN_URL, requestFacebookLoginUrlSaga),
  ]);
}
