import React, { useMemo } from 'react';

import { Select } from '@components';
import { Timezones } from '@constants';

const TimezoneSelect = ({ value, onChange, onboarding, onboard, component = Select, ...props }) => {

  const Component = useMemo(() => component, [component]);

  return (
    <Component
      options={Object.entries(Timezones).map(([value, label]) => ({ value, label }))}
      onboard={onboard}
      {...{ value, onChange, ...props}} />
  );
}

export default TimezoneSelect;


