import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1F1F24;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 236px;
  width: 400px;
`;

export const Title = styled.div`
  font-family: Rubik-Light;
  font-size: 42px;
  line-height: 110%;
  color: #FFFFFF;
`;

export const Hint = styled.div`
  font-family: Rubik-Light;
  font-size: 20px;
  line-height: 120%;
  color: #7C8C9E;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const Timer = styled.div`
  font-family: Rubik-Regular;
  font-size: 30px;
  line-height: 36px;
  color: #FFFFFF;
`;

export const Image = styled.div`
  width: 400px;
  height: 400px;
`;
