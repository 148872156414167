import React, { useContext } from 'react';

import RadioGroupContext from './RadioGroupContext';
import { Container, CircleContainer, Circle, Title } from './styled';

const RadioButton = ({ name, title, children, ...props }) => {
  const context = useContext(RadioGroupContext);
  const selected = context.value === name;
  const disabled = context.disabled;

  const handleChange = () => {
      if (!disabled) {
          context.onChange(name);
      }
  };

  return (
    <>
    <Container onClick={handleChange} {...props}>
      <CircleContainer>
        <Circle disabled={disabled} selected={selected} />
      </CircleContainer>
      <Title disabled={disabled && !selected}>{title}</Title>
    </Container>
      {children}
    </>
  );
};

export default RadioButton;
