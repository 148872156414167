import React from 'react';

import { Container, Value } from './styled.js';
import { useSelector } from 'react-redux';
import { fieldsSettingsListSelector } from '@store/selectors';
import { SegmentsRuleTypes } from '@constants';
import { NoData } from '@components';
import { Suffix } from '@components/inputs/Select/styled';
import { getArrayOptions, resolveDropdownFieldType } from '@utils';
import { useTranslation } from '@hooks';

const ArrayFilterPanel = ({ onSelect, hidePreview, hideArrays, nested }) => {
  const { p } = useTranslation('segments_page');
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const arrayFields = nested ? getArrayOptions(fieldSettings) : fieldSettings?.data?.filter(f => f.type === 'array').map(a => ({ ...a, value: a.field }));

  if (!arrayFields?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-array-tab-content'}>
      {arrayFields?.filter(({ type }) => !hideArrays || type !== 'array')?.map((af, idx) => (
        <div key={idx} style={{ display: 'flex' }}>
          <Value data-testid={`store-segment-filter-panel-array-field-${af.field}`} onClick={() => onSelect(af, SegmentsRuleTypes.ARRAY)}>{af.prefix ? <div style={{ color: '#909399' }}>{af.prefix}.</div> : ''}{af.label}</Value>
          {nested && <Suffix>{resolveDropdownFieldType(af.prefix, af.type, p)}</Suffix>}
        </div>
      ))}
    </Container>
  );
}

export default ArrayFilterPanel;


