import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser, login, twoFAVerifyCode } from '@store/actions/creators';
import { Paths } from '../../constants';
import { Button, Input } from '../../components';
import { useErrors, useQuery, useTranslation } from '@hooks';
import logo from '@res/images/logo.svg';
import whiteThemeLogo from '@res/images/logo-dark-text.svg';
import { Link } from '../../components/text/Link';
import { hashAppId, notify, resolveAB } from '@utils';

import { Container, Image, Title, InnerContainer, LinkedText } from './styled';
import { useNavigate } from 'react-router';

const whiteTheme = resolveAB() === 'registration.white';

const ABLogo = whiteTheme ? whiteThemeLogo : logo;

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { t, p } = useTranslation('login_page');
  const errors = useErrors([], [429, 403]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowRecoveryCodeForm, showRecoveryCodeForm] = useState(false);
  const [code, setCode] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [twoFA, setTwoFA] = useState(false);

  const handleChange = (onChangeFn) => ({ target }) => {
      errors.clear();
      if (codeError) {
        setCodeError('');
      }
      onChangeFn(target.value);
  };

  const resetState = () => {
    setEmail('');
    setPassword('');
    showRecoveryCodeForm(false);
    setCode('');
    setRecoveryCode('');
    setTwoFA(false);
    errors.clear();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    errors.clear();

    if (codeError) {
      setCodeError();
    }

    dispatch(
      login({
        email,
        type: 'retainly',
        password,
        onSuccess: (res) => {
          if (res.two_factor) {
            setTwoFA(true);
          } else {
            switch (res.registration_step) {
              case 1:
              case 2:
                navigate(Paths.REGISTRATION_SECOND_STEP);
                break;
              default:
                navigate(`/app/${hashAppId(res.user?.app?.id)}/dashboard`);
                // redirect({
                //   trigger: AppRedirects.LOG_IN,
                //   action: 'replace',
                //   fallback: Routes.DASHBOARD,
                // })
            }
          }
        },
        onError: errors.handleRequestError,
      })
    );
  };

  const sendCode = (event) => {
    event.preventDefault();
    errors.clear();

    dispatch(
      twoFAVerifyCode({
        code: isShowRecoveryCodeForm ? undefined : code,
        recoveryCode: isShowRecoveryCodeForm ? recoveryCode : undefined,
        onSuccess: (res) => {
          dispatch(getUser());
          navigate(query.get('r') || `/app/${hashAppId(res.user?.app?.id)}/dashboard`);
        },
        onError: (e) => {
          notify('error', 'Error', e?.response?.data?.errors?.code?.[0]);
          resetState();
          if (e?.response?.data?.errors?.code?.length) {
            setCodeError(e?.response?.data?.errors?.code?.[0]);
          } else {
            setCodeError(t('something_went_wrong'));
          }
        },
      })
    );
  };

  return (
    <Container whiteTheme>
      <Image src={ABLogo} alt="logo" />

      {!twoFA ? (
        <form onSubmit={handleSubmit}>
          <InnerContainer>
            <Title>{p('title')}</Title>
            <Input
              type="email"
              title={t('labels.email')}
              autoComplete="on"
              data-testid="login-email"
              error={errors.errors['email']}
              style={{ width: '258px' }}
              onChange={handleChange(setEmail)}
            />
            <Input
              style={{ width: '258px' }}
              type="password"
              data-testid="login-password"
              autoComplete="on"
              error={errors.errors['password']}
              onChange={handleChange(setPassword)}
              title={t('labels.password')}
              renderRightTitle={
                <Link tabIndex={-1} to={Paths.RESET_PASSWORD}>
                  {p('forgot_password')}
                </Link>
              }
            />
            <Button data-testid="login-submit" style={{ marginTop: '20px', width: '258px', justifyContent: 'center' }} htmlType="submit">
              Sign In
            </Button>
            {/*<Caption>*/}
            {/*  {p('sign_up_caption')}&nbsp;*/}
            {/*  <Link underline to={Routes.REGISTRATION}>*/}
            {/*    {p('sign_up')}*/}
            {/*  </Link>*/}
            {/*</Caption>*/}
          </InnerContainer>
        </form>
      ) : !isShowRecoveryCodeForm ? (
        <form onSubmit={sendCode}>
          <InnerContainer>
            <Title>{p('2fa_hint')}</Title>
            <Input
              style={{ width: '258px' }}
              wrapperStyles={{ width: '258px' }}
              type="text"
              onChange={handleChange(setCode)}
              value={code}
              title={t('labels.verification_code')}
              error={codeError}
            />

            <div style={{ marginTop: '5px' }}>
              Can't enter your 2FA code?
            </div>

            <LinkedText onClick={() => showRecoveryCodeForm(true)}>
              Enter recovery code
            </LinkedText>


            <Button
              style={{ marginTop: '20px', width: '258px', justifyContent: 'center' }}
              htmlType="button"
              disabled={code.length === 0}
              onClick={sendCode}
            >
              Submit
            </Button>
          </InnerContainer>
        </form>
      ) : (
        <form onSubmit={sendCode}>
          <InnerContainer>
            <Title>To login enter your recovery code</Title>
            <Input
              style={{ width: '258px' }}
              wrapperStyles={{ width: '258px' }}
              type="text"
              onChange={handleChange(setRecoveryCode)}
              value={recoveryCode}
              title="Recovery code"
              error={codeError}
            />

            <div style={{ marginTop: '5px' }}>
              Can't enter your recovery code?
            </div>

            <LinkedText onClick={() => showRecoveryCodeForm(false)}>
              Enter 2FA code
            </LinkedText>


            <Button
              style={{ marginTop: '20px', width: '258px', justifyContent: 'center' }}
              htmlType="button"
              disabled={recoveryCode.length === 0}
              onClick={sendCode}
            >
              Submit
            </Button>
          </InnerContainer>
        </form>
      )}
    </Container>
  );
};

export default LoginPage;
