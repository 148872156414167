import styled, { css } from 'styled-components';
import * as Colors from '@res/theme/colors';
import { Device } from '@res';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${Colors.Common.DARK_BG};
  
  ${styledProp('whiteTheme', css`
    background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 90.83%);
  `)}
`;

export const Title = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  max-width: 300px;
  font-family: Rubik-Regular;
  font-size: 20px;
  line-height: 23.7px;
  margin-bottom: 54px;
`;

export const InnerContainer = styled.div`
  background: ${Colors.Common.APP_BG};
  display: flex;
  flex-direction: column;
  padding: 46px 20px 40px 20px;
  width: 400px;
  //height: 400px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  align-items: center;
`;

export const Image = styled.img`
  width: 171px;
  height: 38px;
  margin-bottom: 40px;
  margin-top: 50px;

   @media screen and ${Device.laptopL} {
    width: 207px;
    height: 46px;
    margin-bottom: 70px;
    margin-top: 80px;
  }

  @media screen and ${Device.desktopS} {
    width: 270px;
    height: 60px;
    margin-bottom: 111px;
    margin-top: 120px;
  }

  @media screen and ${Device.desktop} {
    width: 270px;
    height: 60px;
    margin-bottom: 111px;
    margin-top: 120px;
  }
`;

export const Caption = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  display: flex;
  line-height: 15.6px;
  margin-top: 44px;
`;

export const LinkedText = styled.div`
  text-align: center;
  color: rgba(95, 193, 249, 1);
  text-decoration: underline;
  cursor: pointer;
`;
