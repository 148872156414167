import styled from 'styled-components';

export const Container = styled.div`
  background: #F9FBFF;
  position: relative;
  border: 1px solid #C1CEFC;
  border-radius: 7px;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #109DFB;
  padding: 10px 0;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  width: 100%;
`;

export const Option = styled.div`
  padding: 4px 14px;
  cursor: pointer;
  transition: 280ms ease all;

  &:hover {
    background: rgba(208, 207, 205, 0.43);
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 60px;
  padding: 7px 0;
  width: 100%;

  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  max-height: 70vh;
  overflow-y: scroll;
`;
