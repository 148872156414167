import React from  'react';
import { Col, Container, Row } from './styled';
import { Input, RadioButton, RadioGroup, SearchSelect, Select } from '@components';
import { useTranslation } from '@hooks';

const entityOptions = (p) => [
  { value: 'years', label: p('labels.years') },
  { value: 'months', label: p('labels.months') },
  { value: 'weeks', label: p('labels.weeks') },
  { value: 'days', label: p('labels.days') },
  // { value: 'year_days', label: p('day_of_year') },
  // { value: 'week_days', label: p('day_of_week') },
  { value: 'hours', label: p('labels.hours') },
  { value: 'minutes', label: p('labels.minutes') }
];

const RelativeRangeSettings = ({ value, onChange }) => {
  const { p, t } = useTranslation('segments_page');

  const handleRangeTypeChange = (type) => {
    onChange({ ...value, range: { ...value.range, type }});
  };

  const handleRangeQuantityChange = ({ target: { value: quantity } }) => {
    onChange({ ...value, range: { ...value.range, quantity: +quantity, }});
  };

  const handleRangeUnitChange = (unit) => {
    onChange({ ...value, range: { ...value.range, unit }});
  };

  const handleGapTypeChange = (type) => {
    onChange({ ...value, gap: { ...value.gap, type }});
  };

  const handleGapUnitChange = (unit) => {
    onChange({ ...value, gap: { ...value.gap, unit }});
  };

  const handleGapQuantityChange = ({ target: { value: quantity } }) => {
    onChange({ ...value, gap: { ...value.gap, quantity: +quantity }});
  };

  return (
    <Container>
      <Row>
        <RadioGroup value={value.range.type} onChange={handleRangeTypeChange}>
          <Col>
            <RadioButton style={{ marginBottom: 8 }} name="next" title={t('actions.next')} />
            <RadioButton name="last" title={t('actions.last')} />
          </Col>
        </RadioGroup>
        <Input
          type="number"
          style={{ marginBottom: 0, width: 66 }}
          wrapperStyles={{ marginRight: 10 }}
          value={value.range.quantity}
          min={1}
          onChange={handleRangeQuantityChange}
        />
        <SearchSelect
          style={{ width: 100 }}
          options={entityOptions(t)}
          value={value.range.unit}
          onChange={handleRangeUnitChange}
        />
      </Row>
      <Row>
        <RadioGroup value={value.gap.type} onChange={handleGapTypeChange}>
          <Col>
            <RadioButton name="before" style={{ marginBottom: 8 }} title={t('labels.before')} />
            <RadioButton name="after" title={t('labels.after')} />
          </Col>
        </RadioGroup>
        <Input
          type="number"
          min={0}
          style={{ marginBottom: 0, width: 66 }}
          wrapperStyles={{ marginRight: 10 }}
          value={value.gap.quantity}
          onChange={handleGapQuantityChange}
        />
        <SearchSelect
          style={{ width: 100 }}
          options={entityOptions(t)}
          value={value.gap.unit}
          onChange={handleGapUnitChange}
        />
      </Row>
    </Container>
  )
};

export default RelativeRangeSettings;
