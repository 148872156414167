import React, { useEffect, useState } from 'react';

import { Modal } from '@components';

import { Container, PreviewWrapper, Body, PreviewData, Icon, Button } from './styled';
import { stringTemplate } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { emailSampleDataSelector } from '@store/selectors';
import { getTemplatePreviewInfo } from '@store/actions/creators';
import { useTranslation } from '@hooks';

const ViewViberTemplateModal = ({ opened, onClose, data }) => {
  const [body, setBody] = useState(data?.body);
  const [buttonName, setButtonName] = useState(data?.btn_name);
  const dispatch = useDispatch();
  const { id: sampleContactId } = useSelector(emailSampleDataSelector);
  const { p } = useTranslation('notification_history')

  useEffect(() => {
    setBody(data?.body);
    setButtonName(data?.btn_name);
  }, [data]);

  useEffect(() => {
    if (opened) {
      dispatch(getTemplatePreviewInfo({
        fields: {
          body: data?.body,
          buttonName: data?.btn_name,
        },
        customer_id: sampleContactId,
        onSuccess: (d) => {
          setBody(d.templates.body)
          setButtonName(d.templates.buttonName)
        },
      }))
    }
  }, [opened]);

  const formatMatches = () => {
    const cfg = {
      '\\`\\`\\`': props => <code {...props} />,
      '\\*': props => <b {...props} />,
      '_': props => <i {...props} />,
      '~': props => <del {...props} />,
    };

    return Object.entries(cfg)
      .flatMap(([matcher, render]) => (data.body
        ?.match(new RegExp(`(${matcher}.*${matcher})`, 'g')) || [])?.map(match => [matcher, match, render])
      ).reduce((acc, [matcher, match, render]) => ({
        ...acc,
        [match]: render({ children: match.replaceAll(new RegExp(matcher, 'g'), '') })
      }), {
        '\n': <br />
      });
  };

  return (
    <Modal
      appearance="default"
      title={p('preview', { name: data.name })}
      contentStyles={{ padding: '20px', paddingTop: '14px', maxHeight: '60vh' }}
      opened={opened}
      onClose={onClose}
    >
      <Container>
        <PreviewWrapper>
          <PreviewData>
            <Body>{stringTemplate(formatMatches(), body)}</Body>
            {data.image_url && <Icon src={data.image_url} />}
            {(!!data.btn_name && data.btn_name !== 'null') ? <Button>{buttonName}</Button> : null}
          </PreviewData>
        </PreviewWrapper>
      </Container>
    </Modal>
  );
};

export default ViewViberTemplateModal;
