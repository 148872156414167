import React from 'react';
import { Button, Modal } from '@components';

import { NodeDiff } from '../NodeDiff';
import { Container, ActionsContainer, TitleContainer, Title } from './styled';
import { useTranslation } from '@hooks';

const NodeDiffModal = ({ opened, onClose, source, origin, sourceLabel, originLabel, options, sourceDescription, originDescription, invertDiff }) => {
  const { t, p } = useTranslation('workflow_page')
  const wide = Object.keys(origin).length > 0 && source.diff?.type !== 'not-changed';
  const hasOrigin = Object.keys(origin).length > 0;

  const double = hasOrigin && source.diff?.type !== 'not-changed' && source.diff?.type !== 'created';
  const invert = source.diff?.type !== 'created' && invertDiff;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      wide={wide}
      fullScreen
      title={(
        <TitleContainer>
          {double && <Title>
            {origin.label}
          </Title>}
          <Title>
            {source.label}
          </Title>
        </TitleContainer>
      )}
      style={{ top: '0px', maxHeight: '100vh', paddingBottom: '0px' }}
      actions={(
        <ActionsContainer>
          <Button onClick={onClose} width={160}>{t('actions.close')}</Button>
        </ActionsContainer>
      )}
    >
      <Container>
        <NodeDiff
          options={options}
          source={invert ? origin : source}
          origin={double && (invert ? source : origin)}
          originLabel={invert ? sourceLabel : originLabel}
          originDescription={invert ? sourceDescription : originDescription}
          sourceDescription={invert ? originDescription : sourceDescription}
          sourceLabel={invert ? originLabel : sourceLabel}
        />
      </Container>
    </Modal>
  );
};

export default NodeDiffModal;
