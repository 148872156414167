import React from 'react';
import { Paths, WorkflowNodes } from '@constants';
import { by } from '@utils';
import { useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';
import { Dropdown } from 'antd';

import { Container, Type, Overlay } from './styled';
import { Link } from 'react-router-dom';

const resolveType = (type) => {
  return {
    [WorkflowNodes.EMAIL]: 'labels.email',
    [WorkflowNodes.EVENT]: 'labels.event',
    [WorkflowNodes.RESOURCE]: 'labels.segment',
    [WorkflowNodes.DATA_CHANGE]: 'labels.data_change',
    [WorkflowNodes.CONTACT_CREATED]: 'labels.customer_addition',
    [WorkflowNodes.PAGE_VIEW]: 'labels.page_view',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'labels.workflow',
  }?.[type];
};

const resolveName = (type, data, options, segmentOptions) => {
  const id = {
      [WorkflowNodes.EMAIL]: data?.email_id,
      [WorkflowNodes.EVENT]: data?.event_id,
      [WorkflowNodes.RESOURCE]: data?.segment_ids?.find(Boolean),
      [WorkflowNodes.ENTRY_ANOTHER_WF]: data?.trigger_workflow_id,
    }?.[type];

  const entity = (type === WorkflowNodes.RESOURCE ? segmentOptions : options)?.[
    {
      [WorkflowNodes.EMAIL]: 'emails',
      [WorkflowNodes.EVENT]: 'events',
      [WorkflowNodes.RESOURCE]: 'segments',
      [WorkflowNodes.ENTRY_ANOTHER_WF]: 'workflows',
    }?.[type]
  ]?.find(by(id));

  return entity?.label || entity?.name;
};

const resolveLink = (type, data, options) => {
  const id = data?.[{
    [WorkflowNodes.EMAIL]: 'email_id',
    [WorkflowNodes.EVENT]: 'event_id',
    [WorkflowNodes.RESOURCE]: 'segment_ids',
    [WorkflowNodes.ENTRY_ANOTHER_WF]: 'trigger_workflow_id',
  }?.[type]];

    return {
      [WorkflowNodes.EMAIL]: id => `/templates/emails/code/${id}`,
      [WorkflowNodes.EVENT]: id => `/customers/fields/event/${id}`,
      [WorkflowNodes.RESOURCE]: id => `/resources/segments/${id?.[0]}`,
      [WorkflowNodes.ENTRY_ANOTHER_WF]: id => `${Paths.EDIT_WORKFLOW}/${options?.workflows?.find(wf => wf.id === id)?.program_id}/editor?w=${id}`,
    }?.[type]?.(id);
  };

const EntryPointLink = ({ tree_map, options, linkOnly }) => {
  const { t } = useTranslation();
  const segmentOptions = useSelector(segmentsOptionsSelector);

  return (
    <Container>
      {!linkOnly && (
        <Type data-testid={`workflow-entry-point-type-${t(resolveType(tree_map?.type))}`} $nb>
          {t(resolveType(tree_map?.type))}
        </Type>
      )}
      <Link data-testid={`workflow-entry-point-value-${resolveName(tree_map?.type, tree_map?.data, options, segmentOptions)}`} style={{ maxWidth: 200 }} to={resolveLink(tree_map?.type, tree_map?.data, options)}>{resolveName(tree_map?.type, tree_map?.data, options, segmentOptions)}</Link>
    </Container>
  );
};

const EntryPointLinkWithDropdown = (props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Dropdown
        overlay={
          <Overlay>
            <EntryPointLink {...props} />
          </Overlay>
        }
      >
        <Type>{t(resolveType(props.tree_map?.type))}</Type>
      </Dropdown>
    </Container>
  );
};

export {
  EntryPointLink,
  EntryPointLinkWithDropdown
};
