import React from 'react';
import Lottie from 'react-lottie';
import { useTransition, config } from '@react-spring/web';

import { Container, TextContainer, Title, Description } from './styled';

const GuideItem = ({ options, isPlaying, title, description, width, height, listeners, ...props }) => {
  const transition = useTransition(!isPlaying, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });

  return (
    <Container {...props}>
      <Lottie
        options={options}
        eventListeners={listeners}
        isClickToPauseDisabled
        width={width}
        height={height}
        isPaused={!isPlaying}
      />
      {transition(({ opacity }, show) => show && (
        <TextContainer style={{ opacity, transform: opacity.to({  range: [1.0, 0.0], output: [0, 10] }).to(y => `translate3d(0, ${y}px, 0)`) }}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextContainer>
      ))}
    </Container>
  );
};

export default GuideItem;
