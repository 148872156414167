import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Container, Overlay, IconContainer, Locked, Option, OverlayContent } from './styled';
import { Icon } from '@components';
import { useDispatch } from 'react-redux';
import {
  changeFieldDisplayingStatus,
  getAggregatesList,
  getFieldSettingsList,
  reorderFields
} from '@store/actions/creators';
import { by, extract } from '@utils';
import uniqBy from 'lodash.uniqby';

const composeType = type => type === 'array' ? 'Array' : (type === 'aggregate' ? 'Aggregate' : 'Linear');

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  boxShadow: isDragging ? '0px 0px 10px 3px rgba(3,9,61,0.14)' : 'none',
  ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DisplayingDropdown = ({ options, onAggregatesChange, testId, title, ...props }) => {
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAggregatesList());
  }, []);


  const value = options.filter(({ active }) => active).map(extract('value'));

  const handleOptionClick = (f) => (event) => {
    const field = options.find(by('value', f));

    dispatch(changeFieldDisplayingStatus({
      type: field.type,
      field: field.value,
      active: !field.active,
      onSuccess: () => {
        if (field.type === 'aggregate') {
          dispatch(getAggregatesList());
          onAggregatesChange?.();
        } else {
          dispatch(getFieldSettingsList());
        }
      }
    }))

    event.stopPropagation();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reordered = reorder(options, result.source.index, result.destination.index);

    dispatch(reorderFields({
      fields: reordered.filter(({ locked }) => !locked).map(({ value, type }, index) => ({ field: value, type, sort_order: index })),
      onSuccess: () => {
        dispatch(getFieldSettingsList());
        dispatch(getAggregatesList());
      }
    }))
  };

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={setOpened}
      placement="bottomCenter"
      overlay={(
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
                <Overlay
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <OverlayContent className="show-scrollbar">
                    {uniqBy(options, extract('value')).map(({ title, value: curr, locked, type }, index) => (
                      <Draggable style={{ pointerEvents: locked ? 'none' : 'auto' }} key={curr} draggableId={curr} index={index}>
                        {(provided, snapshot) => (
                          <Option
                            data-testid={'display-option'}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                                pointerEvents: locked ? 'none' : 'auto'
                              }}
                            onClick={e => e.stopPropagation()}
                          >
                            {!locked ? (
                              <Checkbox
                                key={title}
                                style={{ marginLeft: 0, flex: 1, }}
                                data-testid={`customers-displaying-${curr}`}
                                checked={value.indexOf(curr) !== -1}
                                onClick={handleOptionClick(curr)}
                              >
                                {title}
                              </Checkbox>
                            ) : (
                              <Locked>
                                <Icon name="Lock-icon" size={16} />
                                <span>{title}</span>
                              </Locked>
                            )}
                            <div style={{ color: '#909399', fontSize: 10, display: 'flex', alignItems: 'center' }}>
                              {composeType(type)}
                            </div>
                            <div style={{ marginLeft: 6, display: 'flex', alignItems: 'center' }}>
                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="8.5" y="2.30003" width="7" height="1.4" rx="0.7" transform="rotate(-180 8.5 2.30003)" fill="#DCDFE6"/>
                                <rect x="8.5" y="5.70003" width="7" height="1.4" rx="0.7" transform="rotate(-180 8.5 5.70003)" fill="#DCDFE6"/>
                                <rect x="8.5" y="9.10002" width="7" height="1.4" rx="0.7" transform="rotate(-180 8.5 9.10002)" fill="#DCDFE6"/>
                              </svg>
                            </div>
                          </Option>
                        )}
                      </Draggable>
                    ))}
                  </OverlayContent>
                </Overlay>
            )}
          </Droppable>
        </DragDropContext>
      )}
    >
      <Container data-testid={testId} $opened={opened} {...props}>
        {title}
        <IconContainer $opened={opened}>
          <Icon name="Dropdown-arrow" size={10} />
        </IconContainer>
      </Container>
    </Dropdown>
  );
};

export default DisplayingDropdown;
