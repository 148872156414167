import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  padding-bottom: 30px;
  align-items: flex-start;
`;

export const AddNewFilterContainer = styled.div`
  position: relative;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  @media screen and (max-width: 1289px) {
    margin-right: 0;
  }
`;
export const PrecedentTableContainer = styled.div`
  width: 320px;
  position: relative;

  @media screen and (max-width: 1289px) {
    position: absolute;
    right: 0;
  }
  
`;



