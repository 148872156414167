import React from 'react';

import { Container, Border } from './styled.js';

const OnboardingHighlight = ({
  children,
  on,
  sidebar = false,
  background = '#fff',
  radius = 9,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  ...props
  }) => {

  if (!on) {
    return children;
  }

  return (
    <Container {...props}>
      <Border
        sidebar={sidebar}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        on={on}
        color={background}
        radius={radius}
      />
      {children}
    </Container>
  );
}

export default OnboardingHighlight;


