import React from 'react';
import { useTranslation } from '@hooks';
import { renderProp, zeroIfNaN } from '@utils';

import {
  Container,
  TitleRow,
  StyledInput,
  Title,
  Error,
  NumberArrowsContainer,
  NumberArrowUp,
  NumberArrowDown,
  EmojiContainer,
  InfoIconContainer,
} from './styled';
import { EmojiPicker, OnboardingHighlight, WithOnboarding } from '@components';
import { Tooltip } from 'antd';
import { Icon } from '../../ui';

const Input = ({ title, Component = StyledInput, richRef = {}, fieldKey = '', template = {}, tooltipStyle, titleStyle, oneRowTitle, error, renderRightTitle, renderRight, hideErrorText, className, showArrowButtons = true, emoji = false, onboard, showHint, onEmojiChange, wrapperStyles, tooltip, testId, ...props }) => {
  const { e, p } = useTranslation('common');

  const handleArrowUpClick = (event) => {
    event.stopPropagation();

    if (props.disabled) {
      return;
    }

    if (props.max !== undefined && (+zeroIfNaN(props?.value) + 1) > props.max) {
      return;
    }

    props.onChange?.({ target: { value: +zeroIfNaN(props?.value) + 1 } });
  };

  const handleArrowDownClick = (event) => {
    event.stopPropagation();

    if (props.disabled) {
      return;
    }

    if (props.min !== undefined && (+zeroIfNaN(props?.value) - 1) < props.min) {
      return;
    }

    props.onChange?.({ target: { value: +zeroIfNaN(props?.value) - 1 } });
  };

  return (
    <Container data-testid={testId} style={wrapperStyles}>
      {title && tooltip ? (
        <Tooltip overlayInnerStyle={tooltipStyle} title={tooltip}>
          <TitleRow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Title>{title}</Title>
              <InfoIconContainer>
                <Icon color="#909399" name="Info-icon" size={10} />
              </InfoIconContainer>
            </div>
            {renderProp(renderRightTitle)}
          </TitleRow>
        </Tooltip>
      ) : title && (
        <TitleRow>
          <Title style={titleStyle} $nowrap={oneRowTitle}>{title}</Title>
          {renderProp(renderRightTitle)}
        </TitleRow>
      )}
      <div style={{ position: 'relative' }}>
        { showHint ? (
          <WithOnboarding
            text={p('fill_in_required_fields')}
            onboardKey="contacts"
            enabled={showHint}
            placement="bottom"
          >
            <OnboardingHighlight bottom={-16} radius={13} on={onboard}>
              <Component
                richRef={richRef}
                fieldKey={fieldKey}
                className={className || ''}
                hasError={!!error}
                autoComplete="off"
                invalid={!!error}
                {...props}
              />
            </OnboardingHighlight>
          </WithOnboarding>
        ) : (
          <OnboardingHighlight bottom={-16} radius={13} on={onboard}>
            <Component richRef={richRef} fieldKey={fieldKey} hasError={!!error} autoComplete="off" invalid={!!error} {...props} />
          </OnboardingHighlight>
        )}
        <div style={{ position: 'absolute', right: 8, display: 'flex', top: 8 }}>
          {renderProp(renderRight)}
        </div>
        {props.type === 'number' && showArrowButtons && (
          <NumberArrowsContainer>
            <NumberArrowUp onClick={handleArrowUpClick}>
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.7334 5.40033L4.00007 1.66699L0.266734 5.40033L1.20007 6.33366L4.00007 3.53366L6.80007 6.33366L7.7334 5.40033Z" fill="#909399"/>
              </svg>
            </NumberArrowUp>
            <NumberArrowDown onClick={handleArrowDownClick}>
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.7334 2.59967L4.00007 6.33301L0.266734 2.59967L1.20007 1.66634L4.00007 4.46634L6.80007 1.66634L7.7334 2.59967Z" fill="#909399"/>
              </svg>
            </NumberArrowDown>
          </NumberArrowsContainer>
        )}

      </div>
      {emoji ? (
        <EmojiContainer>
          <EmojiPicker onEmojiChange={onEmojiChange} />
        </EmojiContainer>
      ) : null}
      {(error && !hideErrorText) && <Error>{e(error)}</Error>}
    </Container>
  );
};

export default Input;
