import React, {useState} from "react";

import {expressionForTypeSelected} from '@store/actions/creators';
import {useDispatch, useSelector} from "react-redux";
import {eventsSelector, fieldsSettingsListSelector} from "@store/selectors";
import {expressionForValueSelected} from "../../../../../store/actions/creators";

const EXPRESSION_FOR_LINEAR = 'linear';
const EXPRESSION_FOR_ARRAY = 'array';
const EXPRESSION_FOR_EVENT = 'event';
const EXPRESSION_FORS = [EXPRESSION_FOR_LINEAR, EXPRESSION_FOR_ARRAY, EXPRESSION_FOR_EVENT]

const ForValue = (props) => {

    const dispatch = useDispatch();

    const fieldSettings = useSelector(fieldsSettingsListSelector);

    const events = useSelector(eventsSelector);

    if(props?.expression?.for?.type === 'linear') {
        return "";
    }

    if(props?.expression?.for?.type === 'array' && fieldSettings?.data?.length ) {
        return <select name="" value={props?.expression?.for?.value} onChange={(e) => {dispatch(expressionForValueSelected({value: e.target.value})); e.stopPropagation();}}>
            <option value={null}>---</option>
            {fieldSettings.data.map(val => {
                if(val.type !== 'array') {return}
                return <option key={val.field} value={val.field}>{val.label}</option>
            })}
        </select>
    }

    if(props?.expression?.for?.type === 'event' && events?.data?.length) {
        return <select name="" value={props?.expression?.for?.value} onChange={(e) => {dispatch(expressionForValueSelected({value: e.target.value})); e.stopPropagation();}}>
            <option value={null}>---</option>
            {events.data.map(val => <option key={val.name} value={val.name}>{val.label}</option>)}
        </select>
    }

};

export default ForValue;
