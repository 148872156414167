import React from 'react';
import { formatNumberString } from '@utils';

import { Container, In, Out } from './styled.js';
import { useTranslation } from '@hooks';

const AnalyticsSection = ({ analytics }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <In>{t('labels.in')} {formatNumberString(analytics.in)}</In>
      <Out>{t('labels.out')} {formatNumberString(analytics.out)}</Out>
    </Container>
  );
}

export default AnalyticsSection;


