import styled, { css } from 'styled-components';

import { DatePicker as ANTDDatePicker } from 'antd';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
  margin-left: 9px;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
`;

export const StyledDatePicker = styled(ANTDDatePicker)`
  border: 1px solid #DCDFE6;
  ${styledProp('error', css`
    border: 1px solid #C8402F;
  `)}
  border-radius: 9px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin-bottom: 16px;
  box-shadow: none;
  overflow: hidden;
  position: relative;


  & > div {
    padding-left: 11px;
    height: 100%;
  }

  & > div > span {
    width: 37px;
    border-left: 1px solid #DCDFE6;
    height: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background:  linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
    color: #909399;
  }

  & > div > span > span {
    justify-content: center;
  }

  &:focus {
    box-shadow: none !important;
    border-color: #109DFB;
  }
`;
