import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { ReducerKeys } from '@constants';

import appReducer from './app';
import userReducer from './user';
import contactsReducer from './contacts';
import promoCodesReducer from './promoCodes';
import segmentsReducer from './segments';
import workflowReducer from './workflow';
import templatesReducer from './templates';
import integrationsReducer from './integrations';
import rolesReducer from './roles';
import workspacesReducer from './workspaces';
import eventsReducer from './events';
import historyNotificationReducer from './historyNotifications';
import notificationReducer from './notifications'
import dashboardReducer from './dashboard';
import workflowEditorReducer from './workflowEditor';
import accountReducer from './account';
import campaignsReducer from './campaigns';
import expressionReducer from './expression';
import funnelsReducer from './funnels';
import subscriptionGroupsReducer from './subscriptionGroups';
import fieldsReducer from './fields';
import rfmAnalyticsReducer from './rfmAnalytics';

const rootReducer = combineReducers({
  [ReducerKeys.APP]: persistReducer(
    {
      key: ReducerKeys.APP,
      storage,
      blacklist: ['trialBarShown'],
    },
    appReducer
  ),
  [ReducerKeys.CONTACTS]: persistReducer(
    {
      key: ReducerKeys.CONTACTS,
      storage,
      blacklist: ['contacts', 'fieldSettings', 'count'],
    },
    contactsReducer
  ),
  [ReducerKeys.USER]: persistReducer(
    {
      key: ReducerKeys.USER,
      storage,
      blacklist: ['twoFA', 'refreshing'],
    },
    userReducer
  ),
  [ReducerKeys.PROMO_CODES]: persistReducer(
    {
      key: ReducerKeys.PROMO_CODES,
      storage,
      blacklist: ['loading'],
    },
    promoCodesReducer
  ),
  [ReducerKeys.SEGMENTS]: persistReducer(
    {
      key: ReducerKeys.SEGMENTS,
      storage,
      blacklist: ['loading'],
    },
    segmentsReducer
  ),
  [ReducerKeys.WORKFLOW]: persistReducer(
    {
      key: ReducerKeys.WORKFLOW,
      storage,
      blacklist: ['analytics', 'programAnalytics', 'nodeAnalytics', 'workflowLogs', 'smsNodeAnalytics', 'emailNodeAnalytics', 'editMetadata'],
    },
    workflowReducer
  ),
  [ReducerKeys.TEMPLATES]: persistReducer(
    {
      key: ReducerKeys.TEMPLATES,
      storage,
      blacklist: ['analytics'],
    },
    templatesReducer
  ),
  [ReducerKeys.INTEGRATIONS]: persistReducer(
    {
      key: ReducerKeys.INTEGRATIONS,
      storage,
      blacklist: ['integrations', 'categories', 'sub_categories', 'loading', 'dbRequestPreview'],
    },
    integrationsReducer
  ),
  [ReducerKeys.ROLES]: persistReducer(
    {
      key: ReducerKeys.ROLES,
      storage,
      blacklist: ['roles', 'permissions'],
    },
    rolesReducer
  ),
  [ReducerKeys.WORKSPACES]: persistReducer(
    {
      key: ReducerKeys.WORKSPACES,
      storage,
      blacklist: ['workspaces', 'viewDemoModalOpened'],
    },
    workspacesReducer
  ),
  [ReducerKeys.EVENTS]: persistReducer(
    {
      key: ReducerKeys.EVENTS,
      storage,
      blacklist: ['events'],
    },
    eventsReducer,
  ),
  [ReducerKeys.HISTORY_NOTIFICATIONS]: persistReducer(
    {
      key: ReducerKeys.HISTORY_NOTIFICATIONS,
      storage,
      blacklist: [],
    },
    historyNotificationReducer
  ),
  [ReducerKeys.NOTIFICATIONS]: persistReducer(
    {
      key: ReducerKeys.NOTIFICATIONS,
      storage,
      blacklist: ['notifications'],
    },
    notificationReducer
  ),
  [ReducerKeys.DASHBOARD]: persistReducer(
    {
      key: ReducerKeys.DASHBOARD,
      storage,
      blacklist: [],
    },
    dashboardReducer
  ),
  [ReducerKeys.WORKFLOW_EDITOR]: persistReducer(
    {
      key: ReducerKeys.WORKFLOW_EDITOR,
      storage,
    },
    workflowEditorReducer,
  ),
  [ReducerKeys.ACCOUNT]: persistReducer(
    {
      key: ReducerKeys.ACCOUNT,
      storage,
    },
    accountReducer,
  ),
  [ReducerKeys.CAMPAIGNS]: persistReducer(
    {
      key: ReducerKeys.CAMPAIGNS,
      storage,
      blacklist: ['editMetadata']
    },
    campaignsReducer,
  ),
  [ReducerKeys.EXPRESSION]: persistReducer(
    {
        key: ReducerKeys.EXPRESSION,
        storage,
    },
    expressionReducer,
  ),
  [ReducerKeys.FUNNELS]: persistReducer(
    {
      key: ReducerKeys.FUNNELS,
      storage,
      blacklist: ['funnel']
    },
    funnelsReducer,
  ),
  [ReducerKeys.FIELDS]: persistReducer(
    {
      key: ReducerKeys.FIELDS,
      storage,
      blacklist: [],
    },
    fieldsReducer,
  ),
  [ReducerKeys.SUBSCRIPTION_GROUPS]: persistReducer(
    {
      key: ReducerKeys.SUBSCRIPTION_GROUPS,
      storage,
      blacklist: [],
    },
    subscriptionGroupsReducer,
  ),
  [ReducerKeys.RFM_ANALYTICS]: persistReducer({
      key: ReducerKeys.RFM_ANALYTICS,
      storage,
      blacklist: [],
    },
    rfmAnalyticsReducer
  )
});

export default rootReducer;
