import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;

  ${styledProp('$appearance', css`
    width: 100%;
    padding: 16px 10px 10px 10px;
  `)}
`;

export const FooterOptionLabel = styled.div`
  margin-left: 5px;
`;

export const FooterOption = styled.div`
  display: flex;
  margin-left: -12px;
  padding: 10px 14px;
`;

export const Row = styled.div`
  display: flex;
  ${styledProp('$appearance', css`
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
  `)}
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 14px;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  ${styledProp('disabled', css`
    color: #909399;
  `)}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #DCDFE6;
  margin: 20px 0;
`;
