import React, { useEffect, useRef, useState } from 'react';
import { AccordionItem } from './components';
import { OnboardingHighlight, WithOnboarding } from '@components';
import { useTranslation } from '@hooks';

import { Container, SettingsContainer, Tabs, Tab, TabIndicator } from './styled';

const tabs = [
  {
    title: 'required_settings',
    name: 'required',
  },
  {
    title: 'description',
    name: 'description',
  },
  {
    title: 'additional_settings',
    name: 'additional',
  },
];

const NodeSettingsAccordion = ({ requiredSettings, descriptionSettings, matchingPreset, additionalSettings, requiredContainerStyle, onboarding = {} }) => {
  const { p } = useTranslation('workflow_page');
  const [tab, setTab] = useState('required');
  const refs = useRef(Object.fromEntries(tabs.map(({ name }) => [name, null])));
  const [tabEl, setTabEl] = useState(null);

  const ts = tabs.filter(({ name }) => {
    if (name === 'required') {
      return !!requiredSettings;
    }
    if (name === 'additional') {
      return !!additionalSettings;
    }

    return true;
  });

  useEffect(() => {
    if (!refs.current[tab]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tab]);
  }, [tab, refs.current[tab]]);

  return (
    <Container>
      <Tabs>
        {ts.map(({ name, title }) => (
          <Tab
            key={name}
            id={`nsm-tab-${name}`}
            selected={name === tab}
            ref={rr => refs.current[name] = rr}
            onClick={() => setTab(name)}
          >
            {p(title)}
          </Tab>
        ))}
        <TabIndicator tab={tab} $tab={tabEl} />
      </Tabs>
      {tab === 'required' && (
        <OnboardingHighlight
          style={{ zIndex: 100000 }}
          top={6}
          bottom={6}
          left={6}
          right={6}
          on={onboarding?.required?.enabled}
        >
          <SettingsContainer style={requiredContainerStyle}>
            {requiredSettings}
            {!!matchingPreset && (
              <AccordionItem initialOpened={true} title={'Matching preset'}>
                {matchingPreset}
              </AccordionItem>
            )}
          </SettingsContainer>
        </OnboardingHighlight>
      )}
      {tab === 'description' && (
        <WithOnboarding
          enabled={onboarding?.additional?.enabled}
          onboardKey="workflows"
          text={onboarding?.additional?.text}
          placement="bottomLeft"
          next={onboarding?.additional?.next}
        >
          <OnboardingHighlight
            top={6}
            bottom={6} left={6}
            right={6}
            on={onboarding?.additional?.enabled}
          >
            <SettingsContainer>
              {descriptionSettings}
            </SettingsContainer>
          </OnboardingHighlight>
        </WithOnboarding>
      )}
      {tab === 'additional' && (
        <SettingsContainer>
          {additionalSettings}
        </SettingsContainer>
      )}
    </Container>
  );
};

export default NodeSettingsAccordion;
