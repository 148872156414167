import React from 'react';

import { Container, CopyButton, TableCell, TableRow } from './styled';
import { CopyHandle } from '@components';
import { useDateFormat, useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import { emailSampleDataSelector } from '@store/selectors';

const discountOptions = [
  {
    value: 'promo_code',
    label: 'Promo code',
  },
  {
    value: 'discount_value',
    label: 'Discount value',
  },
  {
    value: 'discount_type',
    label: 'Discount type',
  },
  {
    value: 'discount_start_date',
    label: 'Discount start date',
  },
  {
    value: 'discount_end_date',
    label: 'Discount end date',
  },
  {
    value: 'discount_available_tries',
    label: 'Discount quantity',
  },
  {
    value: 'discount_priority',
    label: 'Discount priority',
  },
];

const DiscountAttributes = () => {
  const { t, p } = useTranslation('templates');

  const resolveCopyData = (name, value) => {
    return `{{${value}}}`;
  };

  return (
    <Container>
      <TableRow>
        <TableCell widthPercent={50} $header>
          {t('labels.name')}
        </TableCell>
        <TableCell widthPercent={50} alignEnd $header>
          {t('labels.action')}
        </TableCell>
      </TableRow>
      {discountOptions.map(({ label, value }) => (
        <TableRow key={label}>
          <TableCell widthPercent={50}>{label}</TableCell>
          <TableCell widthPercent={50} alignEnd>
            <CopyHandle align="flex-end" data={resolveCopyData(label, value)}>
              <CopyButton>{p('copy')}</CopyButton>
            </CopyHandle>
          </TableCell>
        </TableRow>
      ))}
    </Container>
  );
};

export default DiscountAttributes;
