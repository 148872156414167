import React, {useMemo, useState} from 'react';
import {Container} from "./styled";
import {SmsTemplatesRow, TemplatesHeader, TemplatesInner} from "../../styled";
import {Button, Link, WithPermissions, ApiRequestStatisticsModal} from "@components";
import {Paths, UserPermissions} from "@constants";
import {SmallTemplateItem} from "@components/ui/SmallTemplateItem";
import {archiveWebhookTemplate, getApiRequestTemplates, getSingleApiRequest} from "@store/actions/creators";
import {notify} from "@utils";
import {useDispatch, useSelector} from "react-redux";
import {templateApiRequestSelector} from "@store/selectors";
import {useModalState, useTranslation} from "@hooks";
import { useNavigate } from "react-router";

const WebhookSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { p, t } = useTranslation('dashboard_page');
  const apiRequests = useSelector(templateApiRequestSelector);
  const [statisticWebhookId, setWebhookStatisticsId] = useState(null);
  const statisticsWebhookModal = useModalState();

  const shouldRender = useMemo(() => {
    return apiRequests.data?.filter(t => t.status !== 3)?.length;
  }, [apiRequests.data])

  const handleWebhookArchive = (id, name) => {
    dispatch(archiveWebhookTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.template_archived'), name);
        dispatch(getApiRequestTemplates({ page: 1, perPage: 6, all: true }));
      }
    }))
  }

  const handleCreateApiRequest = () => {
    navigate(`${Paths.TEMPLATES_API_REQUEST}/new`);
  };

  const handleWebhookStatisticsClick = (id) => {
    setWebhookStatisticsId(id);
    statisticsWebhookModal.open();
  };

  const handleViewApiRequest = (id) => {
    navigate(`${Paths.TEMPLATES_API_REQUEST}/${id}?view=1`);
  };

  const handleEditApiRequest = (id, used) => {
    if (used === 1) {
      return;
    }
    navigate(`${Paths.TEMPLATES_API_REQUEST}/${id}`);
  };

  const handleDuplicateApiRequest = (id) => {
    dispatch(getSingleApiRequest({ id }))
    navigate(`${Paths.TEMPLATES_API_REQUEST}/duplicate`);
  };

  return (
    <Container data-testid="dashboard-templates-section-webhook">
      {shouldRender ? (
        <TemplatesInner style={{ paddingBottom: 0 }}>
          <TemplatesHeader>
            <Link to={`${Paths.TEMPLATES_API_REQUESTS}/default`} icon>{p('api_requests')}</Link>
            <WithPermissions name={UserPermissions.CREATE_API_REQUESTS}>
              <Button
                style={{
                  padding: '6px 14px',
                  height: '26px',
                  fontSize: '12px',
                  lineHeight: '14px'
                }}
                testId="dashboard-templates-section-webhook-create"
                width="none"
                onClick={handleCreateApiRequest}
              >
                {t('actions.create')}
              </Button>
            </WithPermissions>
          </TemplatesHeader>
          <SmsTemplatesRow>
            {apiRequests.data?.filter(t => t.status !== 3)?.slice(0, 3).map(({ id, used, name, created_at, workers, status }, i, arr) => (
              <SmallTemplateItem
                key={id}
                status={status}
                testId={`webhook-templates-item-${i}`}
                used={used}
                type="api_request"
                title={name}
                onArchive={() => handleWebhookArchive(id, name)}
                fromDashboard
                onStatisticsClick={() => handleWebhookStatisticsClick(id)}
                onDeleteClick={() => {}}
                small
                workflows={workers}
                style={{ ...(i === (arr.length - 1) ? { marginRight: '0px' } : {})}}
                date={created_at}
                onViewClick={() => handleViewApiRequest(id)}
                onEditClick={() => handleEditApiRequest(id)}
                onDuplicateClick={() => handleDuplicateApiRequest(id)}
              />
            ))}
          </SmsTemplatesRow>
        </TemplatesInner>
      ) : null}
      <ApiRequestStatisticsModal id={statisticWebhookId} opened={statisticsWebhookModal.opened} onClose={statisticsWebhookModal.close} />
    </Container>
  )
}

export default React.memo(WebhookSection);
