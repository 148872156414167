import React from 'react';
import { useModalState } from '@hooks';
import { Icon } from '@components';

import { Container, TitleContainer, IconContainer, Title, Line, Content } from './styled';

const AccordionItem = ({ title, testId, children, initialOpened, renderIfClosed = true }) => {
  const state = useModalState(initialOpened);

  return (
    <Container>
      <TitleContainer data-testid={testId} onClick={state.toggle}>
        <Title>{title}</Title>
        <Line />
        <IconContainer $opened={state.opened}>
          <Icon size={16} name="Dropdown-arrow-icon" />
        </IconContainer>
      </TitleContainer>
      <Content $opened={state.opened}>
        {(state.opened || renderIfClosed) && children}
      </Content>
    </Container>
  );
}

export default AccordionItem;
