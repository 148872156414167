import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import uniqBy from 'lodash.uniqby';
import { DateCell, Link, SortableTitle, Table, WithPermissions } from '@components';
import { by, extract, tryMongoDateObject, testId } from '@utils';

import { useDispatch, useSelector } from 'react-redux';
import { resolveAggregateType, Paths, UserPermissions } from '@constants';
import { getFieldSettingsList } from '@store/actions/creators';
import {
  eventsListSelector,
  fieldsSettingsListSelector,
  segmentContactsExampleLoadingSelector, segmentsOptionsSelector
} from '@store/selectors';
import { Icon } from '../../ui';
import { useTranslation, useNavigate } from '@hooks';

const resolveLabel = (l) => {
  switch (l) {
    case 'email':
      return 'Email';
    case 'phone':
      return 'Phone number';
  }

  return l;
};

const templateOptionsMap = {
  '_vb': 'viber',
  '_em': 'email',
  '_wp': 'web_push',
  '_sm': 'sms',
  '_mp': 'mobile_push',
};

const createTemplateLink = (type, id) => {
  switch (type) {
    case '_vb':
      return `${Paths.TEMPLATES_VIBER}/${id}`;
    case '_em':
      return `${Paths.TEMPLATES_EMAIL}/code/${id}`;
    case '_wp':
      return `${Paths.TEMPLATES_WEBPUSH}/${id}`;
    case '_sm':
      return `${Paths.TEMPLATES_SMS}/${id}`;
    case '_mp':
      return `${Paths.TEMPLATES_MOBILE_PUSH}/${id}`;
  }
};

const SegmentContactTable = ({ contacts, total, cols, inModal, ordering, aggregates, query }) => {
  const dispatch = useDispatch();
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const loading = useSelector(segmentContactsExampleLoadingSelector);
  const events = useSelector(eventsListSelector);
  const navigate = useNavigate();
  const options = useSelector(segmentsOptionsSelector);
  const { t, p } = useTranslation('segments_page');

  useEffect(() => {
    dispatch(getFieldSettingsList());
  }, [])

  const redirectToArray = (fieldId, userId) => {
    navigate(`${Paths.CONTACT}/${userId}?arrayId=${fieldId}`)
  }

  const dynamicColumns = fieldSettings.data?.map?.((field) => ({
    title: () => <SortableTitle {...testId('segment-example-table-columns-title-dynamic')()} name={field.type !== 'array' ? field.field : null} ordering={field.type !== 'array' ? ordering : null}>{typeof field.label === 'boolean' ? JSON.stringify(field.label) : resolveLabel(field.label)}</SortableTitle>,
    dataIndex: field.field,
    key: field.field,
    render: (d, s) => {
      if (field.type === 'aggregate' && (d === undefined || d === null || d === 'null' || d === 'NaN')) {
        return (
          <Tooltip title="Aggregate couldn't be calculated for this customer">
            null
          </Tooltip>
        );
      }

      if (field.type === 'array' || (field.type === 'aggregate' && !!d && typeof d === 'object')) {
        return <div onClick={() => redirectToArray(field.id, s.id)} style={{ cursor: 'pointer' }}><Icon name="Export" color="#109DFB" size={18} /></div>;
      }

      if (field.type === 'date' || field.type === 'datetime') {
        return d ? <DateCell customers datetime={field.type === 'datetime'} date={d} /> : '';
      }

      return typeof d === 'boolean' ? JSON.stringify(d) : d;
    }
  })) || [];

  const aggregateColumns = aggregates?.map((aggregate) => ({
    title: () => <SortableTitle {...testId('segment-example-table-columns-title-aggregate')()}>{aggregate.label}</SortableTitle>,
    dataIndex: aggregate.name,
    key: aggregate.name,
    render: (d) => {
      const type = resolveAggregateType(aggregate, fieldSettings.data, events.data);

      if ((type === 'date' || type === 'datetime') && d !== null) {
        return (
          <DateCell
            customers
            datetime={type === 'datetime'}
            date={tryMongoDateObject(d)}
          />
        );
      }

      if (d === undefined || d === null || d === 'null' || d === 'NaN') {
        return (
          <Tooltip title="Aggregate couldn't be calculated for this customer">
            null
          </Tooltip>
        );
      }

      if (aggregate.attribute === '_te') {
        const templateType = (aggregate.filters || []).find(extract('hide'))?.value?.value;
        const templateOptions = options?.fields?.[templateOptionsMap[templateType]];
        const template = (templateOptions || []).find(by('field', d));

        if (!template) {
          return typeof d === 'boolean' || (!!d && typeof d === 'object') ? JSON.stringify(d) : d;
        }

        return (
          <Link to={createTemplateLink(templateType, template.field)}>{template.label}</Link>
        )
      }

      return typeof d === 'boolean' || (!!d && typeof d === 'object') ? JSON.stringify(d) : d;
    }
  })) || [];

  const columns = [
    {
      title: () => <SortableTitle {...testId('segment-example-table-columns-title-id')()} name="id" ordering={ordering}>{p('unique_id')}</SortableTitle>,
      dataIndex: 'id',
      key: 'id',
      render: (id, d) => <Link target="_blank" to={`${Paths.CONTACT}/${id}`}>{id}</Link>,
    },
    {
      title: () => <SortableTitle {...testId('segment-example-table-columns-title-email')()} name="email" ordering={ordering}>{t('labels.email')}</SortableTitle>,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: () => <SortableTitle {...testId('segment-example-table-columns-title-phone')()} name="phone" ordering={ordering}>{t('labels.phone_number')}</SortableTitle>,
      dataIndex: 'phone',
      key: 'phone',
    },
    ...dynamicColumns,
    ...aggregateColumns,
  ];

  const compareColumns = ({ key: k1 }, { key: k2 }) => {
    if ((cols || []).find(by('value', k1))?.locked && !(cols || []).find(by('value', k2))?.locked) {
      return -1;
    }

    if (!(cols || []).find(by('value', k1))?.locked && (cols || []).find(by('value', k2))?.locked) {
      return 1;
    }

    return (cols || []).find(by('value', k1))?.sort_order - (cols || []).find(by('value', k2))?.sort_order;
  }

  const uniqCols = uniqBy(columns, extract('key')).filter(({ key }) => cols?.findIndex?.(by('value', key)) !== -1).sort(compareColumns);

  return (
    <WithPermissions name={UserPermissions.REVIEW_CONTACTS} hidden>
      <Table
        columns={uniqCols}
        dataSource={contacts}
        loading={loading}
        containerStyles={inModal ? { marginTop: 0, border: 'none', borderTop: 0, borderTopRightRadius: 0, borderTopLeftRadius: 0 } : {}}
        rowKey={extract('id')}
        scroll={{ x: true }}
      />
    </WithPermissions>
  );
};

export default SegmentContactTable;
