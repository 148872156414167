import { WebpushStatuses } from './webpushStatuses';

const WebhookStatuses = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  CLICK: 'click',
  DROPPED: 'dropped',
};

const WebhookStatusLabels = {
  [WebhookStatuses.SENT]: 'statuses.sent',
  [WebhookStatuses.DELIVERED]: 'statuses.delivered',
  [WebpushStatuses.CLICK]: 'statuses.click',
  [WebhookStatuses.DROPPED]: 'statuses.dropped',
};

const WebhookStatusOptions = [
  { values: Object.keys(WebhookStatusLabels), label: 'statuses.all_statuses' },
  ...Object.entries(WebhookStatusLabels).map(([value, label]) => ({ value, label }))
];

export { WebhookStatusOptions, WebhookStatuses, WebhookStatusLabels };
