import styled, { css } from 'styled-components';
import { extract, styledProp } from '@utils';
import { animated } from '@react-spring/web';
import { Link as RouterLink } from 'react-router-dom';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  padding: 8px 6px 8px 6px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  
  &:before {
    display: block;
    content: "";
    width: 5px;
    top: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 4px 0 0 4px;
    position: absolute;
    ${styledProp('$error', css`
      background: #C8402F;
    `, css`
      background: #24B277;
    `)}
  }
  

  ${({ isLast }) => isLast ? '' : css`
    margin-bottom: 6px;
  `}
  
  ${styledProp('$read', css`
    background: #F9FBFF;
  `, css`
  `)}
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
  white-space: nowrap;
  width: 168px;
  height: 17px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  //color: #000000;
  // ${({ error }) => error ? 'color: #C8402F' : 'color: #24993E'}
`;

export const BodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 25px;
  font-size: 12px;
  line-height: 14px;
`;

export const Body = styled.div`
  //margin-left: 5px;
  font-size: 14px;
  line-height: 17px;

  /* Black text */

  color: #303133;


  * {
    //margin-left: 1.5px;
    //margin-right: 1.5px;
  }
`;

export const Link = styled.div`
  color: #109DFB;
  overflow: hidden;
  height: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  cursor: pointer;
  ${({ $underline }) => $underline ? `
    text-decoration: underline;
  ` : ''}
`;

export const Point = styled.div`
  margin-top: 9px;
  width: 5px;
  min-width: 5px;
  min-height: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #303133;
`;

export const StaticPoint = styled.div`
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #109DFB;
`;

export const Time = styled.div`
  position: absolute;
  right: 6px;
  top: 10px;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  font-family: Rubik-Regular;
`;

export const Image = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 7px;
  margin-left: 6px;
  border-radius: 50%;
  background: center / contain url("${extract('$url')}") no-repeat;
`;

export const Row = styled.div`
  display: flex;
`;



