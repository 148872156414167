import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  ${styledProp('bordered', css`
    border-bottom: 1px solid #DCDFE6;
  `)}
  margin-bottom: 16px;
  flex: 0 0 auto;
`;

export const CommentText = styled.div`
  padding: 14px 0 16px 0;
`;

export const CommentUser = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #303133;
`;


export const CommentDateSmall = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;

export const CommentDateLarge = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  margin-bottom: 2px;
`;

export const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextArea = styled.textarea`
  display: flex;
  flex: 1;
  width: 100%;
  height: min-content;
  resize: none;
  overflow: auto;
  min-height: 36px;
  max-height: 400px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  
  &:focus {
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
  }
`;

export const MessageBox = styled.div`
  padding: 10px 20px;
  border-top: 1px solid #DCDFE6;
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column; 
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 16px 15px 0 15px;
`;
