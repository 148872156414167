import React, {useMemo} from 'react';
import {Paths} from "@constants";
import {SendingInfo} from "../SendingInfo";
import {useSelector} from "react-redux";
import {dashboardWebpushSummarySelector} from "@store/selectors/dashboard";
import {zeroIfNaN} from "@utils";
import {useTranslation} from "@hooks";

const WebpushSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const webpushSummary = useSelector(dashboardWebpushSummarySelector);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  const webpushClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(webpushSummary?.click / webpushSummary?.total * 100),
      count: webpushSummary?.click
    };
  }, [webpushSummary?.click, webpushSummary?.total]);

  const webpushOpens = useMemo(() => {
    return {
      percentage: zeroIfNaN(webpushSummary?.open / webpushSummary?.total * 100),
      count: webpushSummary?.open
    };
  }, [webpushSummary?.open, webpushSummary?.total]);

  return (
    <SendingInfo
      total={webpushSummary?.total}
      to={`${Paths.NOTIFICATIONS}?tab=webpush`}
      link={p('web_push')}
      clicks={webpushClicks}
      testId="dashboard-webpush-sendings"
      openedCount={webpushOpens}
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(WebpushSendingInfo);
