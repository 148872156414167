import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { extract } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  padding: 0 4px 0 10px;
  min-width: ${extract('$mw')}px;
`;

export const InnerContainer = styled(animated.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  top: -18px;
  bottom: -18px;
  background-color: transparent !important;
  padding-left: 6px;
  z-index: 1000;
`;

export const ActionButtons = styled(animated.div)`
  display: flex;
  align-items: center;
`;
