import { TemplatesActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  sms: {
    page: 0,
    loading: false,
    data: null,
  },
  singleSmsItem: {
    loading: false,
  },
  emailSampleData: {
    loading: false,
    sampleData: {},
    fields: {},
  },
  viber: {
    page: 0,
    loading: false,
    data: null,
  },
  mobilePushSummary: null,
  viberSummary: null,
  singleViberItem: {
    loading: false,
  },
  mobilePush: {
    page: 0,
    loading: false,
    data: null,
  },
  singleMobilePush: {
    loading: false,
  },
  emails: {
    page: 0,
    loading: false,
    data: null,
  },
  apiRequest: {
    page: 0,
    loading: false,
    data: null,
  },
  singleApiRequestItem: {
    loading: false,
  },
  webpush: {
    page: 0,
    loading: false,
    data: null,
    new_webpush_integration_id: {
      data: {
        app_integration_id: '',
      }
    },
  },
  singleWebpushItem: {
    loading: false,
  },
  apiRequestPreview: {
    loading: false,
  },
  smsPreview: {
  },
  viberPreview: {
  },
  emailPreview: {
  },
  mobilePushPreview: {
  },
  webpushPreview: {
  },
  singleEmailItem: {
    loading: false,
  },
  folders: {
    loading: false,
  },
  foldersList: {
    loading: false,
  },
  emailDuplicate: null,
  smsDuplicate: null,
  newEmail: null,
  newSms: null,
  statistics: {
    loading: false,
  },
  subscriptionGroups: {},
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TemplatesActionTypes.GET_SMS:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_SMS_SUCCESS:
      return {
        ...state,
        sms: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SMS_ERROR:
      return {
        ...state,
        sms: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_SMS:
      return {
        ...state,
        singleSmsItem: {
          loading: true,
        },
      };
    // case TemplatesActionTypes.GET_TEMPLATE_PREVIEW_INFO_SUCCESS:
    //   return {
    //     ...state,
    //     apiRequestPreview: {
    //       ...state.apiRequestPreview,
    //       ...action.payload,
    //     },
    //   };
    case TemplatesActionTypes.CLEAR_API_REQUEST_PREVIEW:
      return {
        ...state,
        apiRequestPreview: {
          ...initialState.apiRequestPreview,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_VIBER:
      return {
        ...state,
        singleViberItem: {
          ...state.singleViberItem,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_VIBER_SUCCESS:
      return {
        ...state,
        singleViberItem: {
          ...state.singleViberItem,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_VIBER_ERROR:
      return {
        ...state,
        singleViberItem: {
          ...state.singleViberItem,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY:
    case TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY_ERROR:
      return {
        ...state,
        mobilePushSummary: initialState.mobilePushSummary,
      }
    case TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY_SUCCESS:
      return {
        ...state,
        mobilePushSummary: action.payload,
      }
    case TemplatesActionTypes.GET_VIBER_SUMMARY:
    case TemplatesActionTypes.GET_VIBER_SUMMARY_ERROR:
      return {
        ...state,
        viberSummary: initialState.viberSummary,
      }
    case TemplatesActionTypes.GET_VIBER_SUMMARY_SUCCESS:
      return {
        ...state,
        viberSummary: action.payload,
      }
    case TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH:
      return {
        ...state,
        singleMobilePush: {
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH_SUCCESS:
      return {
        ...state,
        singleMobilePush: {
          ...state.singleMobilePush,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH_ERROR:
      return {
        ...state,
        singleMobilePush: {
          ...state.singleMobilePush,
          loading: false,
        },
      };
    case TemplatesActionTypes.CLEAR_SINGLE_MOBILE_PUSH:
      return {
        ...state,
        singleMobilePush: {
          loading: false,
        },
      }
    case TemplatesActionTypes.GET_MOBILE_PUSH:
      return {
        ...state,
        mobilePush: {
          ...state.viber,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_MOBILE_PUSH_SUCCESS:
      return {
        ...state,
        mobilePush: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_MOBILE_PUSH_ERROR:
      return {
        ...state,
        mobilePush: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_VIBERS:
      return {
        ...state,
        viber: {
          ...state.viber,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_VIBERS_SUCCESS:
      return {
        ...state,
        viber: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_VIBERS_ERROR:
      return {
        ...state,
        viber: {
          loading: false,
        },
      };
    case TemplatesActionTypes.SET_NEW_VIBER:
      return {
        ...state,
        viber: {
          ...state.viber,
          new_viber_integration_id: {
            data: {
              ...action.payload.data,
            }
          },
        }
      }
    case TemplatesActionTypes.SET_NEW_MOBILE_PUSH:
      return {
        ...state,
        mobilePush: {
          ...state.mobilePush,
          new_mobile_push_integration_id: {
            data: {
              ...action.payload.data,
            }
          },
        }
      }
    case TemplatesActionTypes.SET_NEW_WEBPUSH:
      return {
        ...state,
        webpush: {
          ...state.webpush,
          new_webpush_integration_id: {
            data: {
              ...action.payload.data,
            }
          },
        }
      }
    case TemplatesActionTypes.GET_SINGLE_SMS_SUCCESS:
      return {
        ...state,
        singleSmsItem: {
          ...state.singleSmsItem,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_SMS_ERROR:
      return {
        ...state,
        singleSmsItem: {
          ...state.singleSmsItem,
          loading: false,
        },
      };
    case TemplatesActionTypes.CLEAR_SINGLE_SMS:
      return {
        ...state,
        singleSmsItem: {
          loading: false,
        },
      };
    case TemplatesActionTypes.CLEAR_SINGLE_EMAIL:
      return {
        ...state,
        singleEmailItem: {
          loading: false,
        },
      };
    case TemplatesActionTypes.CLEAR_SINGLE_WEBPUSH:
      return {
        ...state,
        singleWebpushItem: {
          loading: false,
        },
      };
    case TemplatesActionTypes.CLEAR_SINGLE_VIBER:
      return {
        ...state,
        singleViberItem: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_WEBPUSH:
      return {
        ...state,
        singleWebpushItem: {
          loading: true,
        }
    }
    case TemplatesActionTypes.GET_SINGLE_WEBPUSH_SUCCESS:
      return {
        ...state,
        singleWebpushItem: {
          ...state.singleWebpushItem,
          ...action.payload,
          loading: false,
        }
      }
    case TemplatesActionTypes.GET_SINGLE_WEBPUSH_ERROR:
      return {
        ...state,
        singleWebpushItem: {
          ...state.singleWebpushItem,
          loading: false,
        }
      }
    case TemplatesActionTypes.GET_SINGLE_API_REQUEST:
      return {
        ...state,
        singleApiRequestItem: {
          loading: true,
        }
      }
    case TemplatesActionTypes.GET_SINGLE_API_REQUEST_SUCCESS:
      return {
        ...state,
        singleApiRequestItem: {
          ...state.singleApiRequestItem,
          ...action.payload,
          loading: false,
        }
      }
    case TemplatesActionTypes.GET_SINGLE_API_REQUEST_ERROR:
      return {
        ...state,
        singleApiRequestItem: {
          ...state.singleApiRequestItem,
          loading: false,
        }
      }
    case TemplatesActionTypes.GET_EMAILS:
      return {
        ...state,
        emails: {
          ...state.emails,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_EMAILS_SUCCESS:
      return {
        ...state,
        emails: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_EMAILS_ERROR:
      return {
        ...state,
        emails: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA:
      return {
        ...state,
        emailSampleData: {
          loading: true,
          ...state.emailSampleData,
        },
      };
    case TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA_SUCCESS:
      return {
        ...state,
        emailSampleData: {
          ...state.emailSampleData,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA_ERROR:
      return {
        ...state,
        emailSampleData: {
          ...state.emailSampleData,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_EMAIL:
      return {
        ...state,
        singleEmailItem: {
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_EMAIL_SUCCESS:
      return {
        ...state,
        singleEmailItem: {
          ...state.singleEmailItem,
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SINGLE_EMAIL_ERROR:
      return {
        ...state,
        singleEmailItem: {
          ...state.singleEmailItem,
          loading: false,
        },
      };
    case TemplatesActionTypes.SET_EMAIL_DUPLICATE:
      return {
        ...state,
        emailDuplicate: action.payload.data,
      };
    case TemplatesActionTypes.SET_SMS_DUPLICATE:
      return {
        ...state,
        smsDuplicate: action.payload.data,
      };
    case TemplatesActionTypes.SET_NEW_SMS:
      return {
        ...state,
        newSms: action.payload.data,
      };
    case TemplatesActionTypes.UPDATE_NEW_SMS:
      return {
        ...state,
        newSms: {
          ...state.newSms,
          ...action.payload.data,
        },
      };
    case TemplatesActionTypes.SET_NEW_EMAIL:
      return {
        ...state,
        newEmail: action.payload.data,
      };
    case TemplatesActionTypes.UPDATE_NEW_EMAIL:
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          ...action.payload.data,
        },
      };
    case TemplatesActionTypes.GET_WEBPUSH:
      return {
        ...state,
        webpush: {
          ...state.webpush,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_WEBPUSH_SUCCESS:
      return {
        ...state,
        webpush: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_WEBPUSH_ERROR:
      return {
        ...state,
        webpush: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_API_REQUEST:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_API_REQUEST_SUCCESS:
      return {
        ...state,
        apiRequest: {
          ...action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_API_REQUEST_ERROR:
      return {
        ...state,
        apiRequest: {
          loading: false,
        },
      };
    case TemplatesActionTypes.SET_EMAIL_PREVIEW:
      return {
        ...state,
        emailPreview: {
          ...state.emailPreview,
          data: action.payload.data.templates.data,
        }
      };
    case TemplatesActionTypes.SET_SMS_PREVIEW:
      return {
        ...state,
        smsPreview: {
          ...state.smsPreview,
          data: action.payload.data.templates.data,
        },
      };
    case TemplatesActionTypes.SET_VIBER_PREVIEW:
      return {
        ...state,
        viberPreview: {
          ...state.viberPreview,
          ...action.payload.data.templates,
        },
      };
    case TemplatesActionTypes.SET_MOBILE_PUSH_PREVIEW:
      const newMobilePushPreview = {
        ...state.mobilePushPreview,
        ...action.payload.data.templates,
      };

      return {
        ...state,
        mobilePushPreview: newMobilePushPreview,
      };
    case TemplatesActionTypes.SET_WEBPUSH_PREVIEW:
      const newWebpushPreview = {
        ...state.webpushPreview,
        ...action.payload.data.templates,
      };

      if (action.payload.data.templates.actions) {
        newWebpushPreview.actions = action.payload.data.templates.actions;
      }

      return {
        ...state,
        webpushPreview: newWebpushPreview,
      };
    case TemplatesActionTypes.SET_API_REQUEST_PREVIEW:
      return {
        ...state,
        apiRequestPreview: {
          ...state.apiRequestPreview,
          ...action.payload.data.templates,
        },
      };
    case TemplatesActionTypes.CLEAR_EMAIL_PREVIEW:
      return {
        ...state,
        emailPreview: {
          ...initialState.emailPreview,
        }
      };
    case TemplatesActionTypes.CLEAR_SMS_PREVIEW:
      return {
        ...state,
        smsPreview: {
          ...initialState.smsPreview,
        },
      };
    case TemplatesActionTypes.CLEAR_VIBER_PREVIEW:
      return {
        ...state,
        viberPreview: {
          ...initialState.viberPreview,
        },
      };
    case TemplatesActionTypes.CLEAR_WEBPUSH_PREVIEW:
      return {
        ...state,
        webpushPreview: {
          ...initialState.webpushPreview,
        },
      };
    case TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS:
      return {
        ...state,
        subscriptionGroups: {
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS_SUCCESS:
      return {
        ...state,
        subscriptionGroups: {
          ...state.subscriptionGroups,
          data: action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS_ERROR:
      return {
        ...state,
        subscriptionGroups: {
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS:
      return {
        ...state,
        folders: {
          ...state.folders,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS_SUCCESS:
      return {
        ...state,
        folders: {
          loading: false,
          ...action.payload,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS_ERROR:
      return {
        ...state,
        folders: {
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS_LIST:
      return {
        ...state,
        foldersList: {
          ...state.folders,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS_LIST_SUCCESS:
      return {
        ...state,
        foldersList: {
          loading: false,
          ...action.payload,
        },
      };
    case TemplatesActionTypes.GET_FOLDERS_LIST_ERROR:
      return {
        ...state,
        foldersList: {
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_TEMPLATE_STATISTICS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          loading: true,
        },
      };
    case TemplatesActionTypes.GET_TEMPLATE_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          [action.meta.type]: action.payload,
          loading: false,
        },
      };
    case TemplatesActionTypes.GET_TEMPLATE_STATISTICS_ERROR:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          loading: false,
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return { ...state };
  }
};

export default templatesReducer;
