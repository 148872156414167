import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 71.42%, #EDF8FF 90.83%);
  border-radius: 16px;
  padding: 30px 40px 28px;
  border: 1px solid #C1CEFC;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 160px;
  width: 300px
`;

export const FolderIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(193, 206, 252, 0.4);
  box-shadow: 0px 4px 6px rgba(193, 206, 252, 0.4);
`;

export const Description = styled.div`
  margin-top: 20px;
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
`;
