import React from 'react';
import { useSelector } from 'react-redux';
import { fieldsSettingsListSelector } from '@store/selectors';
import { Select, OperatorSelect, SearchSelect } from '@components';
import { by } from '@utils';

import { Container } from './styled';
import { useTranslation } from '@hooks';

const LinearSelect = ({ query, onChange, showErrors, appearance }) => {
  const { t, p } = useTranslation('segments_page');

  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const options = fieldSettings.data?.filter?.(f => f.type !== 'array').map(f => ({ value: f.field, label: f.label }));
  const field = fieldSettings.data?.find?.(by('field', query.field));

  const handleFieldChange = (field) => {
    onChange(q => ({ ...q, query: { ...q.query, field, operator: '', value: { value: '', type: 'scalar' }  } }));
  };

  const handleTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, type: type === 'common' ? field.type : type } }));
  };

  const handleValueChange = (value, isField) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value, type: isField ? 'variable' : 'scalar' } } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, query: { ...q.query, operator, type: type === 'common' ? field.type : type, value: { ...q.query.value, value: '', type: 'scalar' } } }));
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-linear-container-${query.name}`}>
      <SearchSelect
        testId={`store-segment-field-linear-select-${query.name}`}
        style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
        options={options}
        label={t('labels.field')}
        value={query?.field}
        tooltipError={showErrors && query.errors?.['field']}
        onChange={handleFieldChange}
      />
      <OperatorSelect
        testId={`store-segment-field-linear-operator-select-${query.name}`}
        disabled={false}
        wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
        style={{ width: appearance !== 'column' ? 186 : 'auto' }}
        value={{ ...query, value: query.value.value, errors: showErrors && query.errors }}
        type={query.type}
        autocomplete={query.type === 'numeric' ? undefined : {
          fields: [query.field],
        }}
        field={query.field}
        additionalOptions={options}
        initialType={field?.type}
        initialIsField={query.value.type === 'variable'}
        appearance={appearance}
        fullWidth={appearance === 'column'}
        onTypeChange={handleTypeChange}
        onValueChange={handleValueChange}
        onOperatorChange={handleOperatorChange}
        autoComplete={field?.autocomplete || []}
        labeled
      />
    </Container>
  );
};

export default LinearSelect;
