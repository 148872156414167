import React from 'react';
import { moment } from '@utils';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';

import { Container } from './styled.js';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { useTranslation } from '@hooks';

const actions = {
  0: 'wait_day',
  1: 'wait_duration'
};

const days = {
  0: 'labels.sunday',
  1: 'labels.monday',
  2: 'labels.tuesday',
  3: 'labels.wednesday',
  4: 'labels.thursday',
  5: 'labels.friday',
  6: 'labels.saturday',
};

const durationUnits = {
  minutes: 'labels.minutes',
  hours: 'labels.hours',
  days: 'labels.day'
};

const WaitNodeSettingsInfo = ({ node, options }) => {
  const durationUnit = durationUnits[node.data?.duration_unit];
  const duration = node.data?.duration;
  const type = actions[node.data?.type];
  const day = days[node.data?.day];
  const send_at = node.data?.send_at;
  const { t, p } = useTranslation('workflow_page');

  if (!durationUnit && !duration && !type && !day && !send_at) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow padded condition={type !== undefined}>
        <InfoTitle>
          {t('labels.action')}:
        </InfoTitle>
        <InfoText>
          {p(type)}
        </InfoText>
      </InfoRow>
      {node.data?.type === 0 ? (
        <InfoRow padded={send_at !== undefined} condition={day !== undefined}>
          <InfoTitle>
            {t('labels.day')}:
          </InfoTitle>
          <InfoText>
            {t(day)}
          </InfoText>
        </InfoRow>
      ) : (
        <InfoRow padded={send_at !== undefined} condition={duration !== undefined}>
          <InfoTitle>
            {t('labels.duration')}:
          </InfoTitle>
          <InfoText>
            {!!+node.data.days && node.data.days + ' day' + (node.data.days > 1 ? 's ' : '')}&nbsp;
          </InfoText>
          <InfoText>
            {!!+node.data.hours && node.data.hours + ' hour' + (node.data.hours > 1 ? 's ' : '')}&nbsp;
          </InfoText>
          <InfoText>
            {!!+node.data.minutes && node.data.minutes + ' minute' + (node.data.minutes > 1 ? 's ' : '')}
          </InfoText>
        </InfoRow>
      )}
      <InfoRow condition={send_at !== undefined && type === 0}>
        <InfoTitle>
          {t('labels.send_at')}:
        </InfoTitle>
        <InfoText>
          {moment(send_at).format('hh:mm A')}
        </InfoText>
      </InfoRow>
      {node.data.analytics && (
        <AnalyticsSection
          analytics={{
            ...(node.data.analytics || {}),
            // out: zeroIfNaN(node.data.analytics?.out / nextLength)
          }}
        />
      )}
    </Container>
  );
}

export default WaitNodeSettingsInfo;


