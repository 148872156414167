import React from 'react';

import { Wrapper, Container, Title } from './styled';

const BulkActionModal = ({ text, actions, opened, ...props }) => {
  return (
    <Wrapper $opened={opened}>
      <Container>
        <Title>
          {text}
        </Title>
        {actions}
      </Container>
    </Wrapper>
  );
};

export default BulkActionModal;
