import { ReducerKeys } from '@constants';
import { createSelector } from 'reselect';
import { extract, identity } from '@utils';

export const dashboardSmsSummarySelector = store => store[ReducerKeys.DASHBOARD].smsSummary;

export const dashboardEmailSummarySelector = store => store[ReducerKeys.DASHBOARD].emailSummary;

export const dashboardMobilePushSummarySelector = store => store[ReducerKeys.DASHBOARD].mobilePushSummary;

export const dashboardViberSummarySelector = store => store[ReducerKeys.DASHBOARD].viberSummary;

export const dashboardWebpushSummarySelector = store => store[ReducerKeys.DASHBOARD].webpushSummary;

export const dashboardDatabaseEfficiencySelector = store => store[ReducerKeys.DASHBOARD].databaseEfficiency;

export const dashboardGeneralActivityDailySelector = store => store[ReducerKeys.DASHBOARD].generalActivityDaily;

export const dashboardGeneralActivityHourlySelector = store => store[ReducerKeys.DASHBOARD].generalActivityHourly;

export const dashboardDataLoadedSelector = createSelector(
  dashboardSmsSummarySelector,
  dashboardEmailSummarySelector,
  dashboardWebpushSummarySelector,
  dashboardDatabaseEfficiencySelector,
  dashboardGeneralActivityDailySelector,
  dashboardGeneralActivityHourlySelector,
  (...data) => data.map(extract('loading')).every((loading, i) => !loading),
);

export const dashboardSummaryLoadedSelector = createSelector(
  dashboardSmsSummarySelector,
  dashboardEmailSummarySelector,
  dashboardWebpushSummarySelector,
  dashboardMobilePushSummarySelector,
  dashboardViberSummarySelector,
  (...data) => data.filter(identity).map(extract('loading')).every((loading, i) => !loading),
);

export const dashboardAnalyticsEntitiesSelector = store => store[ReducerKeys.DASHBOARD].analyticsEntities;
