const AppRedirects = {
  INTEGRATION_CONNECTION_CREATION: 'integration_connection_creation',
  SEGMENT_CREATION: 'segment_creation',
  EMAIL_TEMPLATE_CREATION: 'email_template_creation',
  EMAIL_TEMPLATE_UPDATE: 'email_template_update',
  SMS_TEMPLATE_CREATION: 'sms_template_creation',
  SMS_TEMPLATE_UPDATE: 'sms_template_update',
  WEB_PUSH_TEMPLATE_CREATION: 'web_push_template_creation',
  WEB_PUSH_TEMPlATE_UPDATE: 'web_push_template_update',
  MOBILE_PUSH_TEMPLATE_CREATION: 'mobile_push_creation',
  VIBER_TEMPLATE_CREATION: 'viber_template_creation',
  API_REQUEST_TEMPLATE_CREATION: 'api_request_template_creation',
  API_REQUEST_TEMPLATE_UPDATE: 'api_request_template_update',
  ROLES_CREATION: 'roles_creation',
  EVENT_CREATION: 'event_creation',
  PROMO_CODE_CREATION: 'promo_code_creation',
  LOG_IN: 'log_in',
};

export default AppRedirects;
