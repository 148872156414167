import { compose } from '@utils';
import { asCreator } from '@store/lib';
import { ExpressionTypes } from '@store/actions/types';
import {withCallbacks} from "../../lib";
import {ContactsActionTypes} from "../types";

export const expressionNameChanged = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_NAME_CHANGED,
        payload: payload
    })),
);

export const expressionValueSelected = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_VALUE_SELECTED,
        payload: payload
    })),
);

export const expressionTypeSelected = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_TYPE_SELECTED,
        payload: payload
    })),
);

export const expressionClicked = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_CLICKED,
        payload
    })),
);

export const expressionResetType = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_RESET_TYPE,
        payload
    })),
);

export const expressionFunctionAdded = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_FUNCTION_ADDED,
        payload
    })),
);

export const createExpression = compose(
    withCallbacks,
    asCreator((payload) => ({
        type: ContactsActionTypes.CREATE_EXPRESSION,
        payload: payload,
        meta: []
    })),
);

export const expressionForTypeSelected = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_FOR_TYPE_SELECTED,
        payload
    })),
);

export const expressionForValueSelected = compose(
    asCreator((payload) => ({
        type: ExpressionTypes.EXPRESSION_FOR_VALUE_SELECTED,
        payload
    })),
);