import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { Paths } from '@constants';
import { useNavigate } from '@hooks';

import { Container, Content, Text, Title, Hint, Timer, Image } from './styled';

const WhiteEmailConfirmationSuccessPage = () => {
  const navigate = useNavigate();

  const timer = useTimer({
    expiryTimestamp: new Date().getTime() + 15 * 1000,
    onExpire: () => {
      navigate(Paths.DASHBOARD);
    }
  });

  useEffect(() => {
    timer.start();
  }, []);

  return (
   <Container>
      <Content>
        <Text>
          <Title>
            Thank you for confirming your mail!
          </Title>
          <Hint>
            You well be automatically redirected to Retainly.app after:
          </Hint>
          <Timer>
            00:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
          </Timer>
        </Text>
        <Image>
          <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.41">
              <path d="M492.7 293C493.2 285.2 493 277.3 492.1 269.5C487.9 232.8 469.1 198.3 441.5 173.9C431.6 165.2 420.4 157.4 414 145.9C403.2 126.6 404 104.3 390.6 85.1999C377.2 66.0999 357.6 51.1999 335.5 43.4999C269.6 20.6999 177.4 57.7999 185.7 137.2C185.7 137.5 185.8 137.9 185.8 138.4C186.9 154.9 174 168.8 157.5 169.1C96.5001 170.7 44.2001 217.5 33.0001 276.9C25.8001 315.1 37.6001 356.9 65.5001 383.9C86.3001 404.1 114.3 415.1 142.3 422.7C198.4 438 257.5 441.2 315.3 435.3C369.5 429.8 426.6 414.3 461.6 372.6C480.3 350.5 491 322 492.7 293Z" fill="#E1E2FE"/>
            </g>
            <g opacity="0.81">
              <path d="M254.1 347.2H231C230.4 347.2 230 346.8 230 346.2C230 345.6 230.4 345.2 231 345.2H254.1C254.7 345.2 255.1 345.6 255.1 346.2C255.1 346.8 254.6 347.2 254.1 347.2Z" fill="white"/>
              <path d="M254.1 371.2H231C230.4 371.2 230 370.8 230 370.2C230 369.6 230.4 369.2 231 369.2H254.1C254.7 369.2 255.1 369.6 255.1 370.2C255.1 370.8 254.6 371.2 254.1 371.2Z" fill="white"/>
              <path d="M271.7 395.4H128.7C127.2 395.4 126 394.2 126 392.7V318.3C126 316.8 127.2 315.6 128.7 315.6H271.7C273.2 315.6 274.4 316.8 274.4 318.3V392.7C274.4 394.2 273.2 395.4 271.7 395.4ZM128.7 317.6C128.3 317.6 128 317.9 128 318.3V392.7C128 393.1 128.3 393.4 128.7 393.4H271.7C272.1 393.4 272.4 393.1 272.4 392.7V318.3C272.4 317.9 272.1 317.6 271.7 317.6H128.7Z" fill="white"/>
              <path opacity="0.66" d="M271.7 395.4H128.7C127.2 395.4 126 394.2 126 392.7V318.3C126 316.8 127.2 315.6 128.7 315.6H271.7C273.2 315.6 274.4 316.8 274.4 318.3V392.7C274.4 394.2 273.2 395.4 271.7 395.4ZM128.7 317.6C128.3 317.6 128 317.9 128 318.3V392.7C128 393.1 128.3 393.4 128.7 393.4H271.7C272.1 393.4 272.4 393.1 272.4 392.7V318.3C272.4 317.9 272.1 317.6 271.7 317.6H128.7Z" fill="white"/>
              <path d="M143.501 380.3H179.701C180.401 380.3 180.901 379.7 180.901 379.1V330.1C180.901 329.4 180.301 328.9 179.701 328.9H143.501C142.801 328.9 142.301 329.5 142.301 330.1V379.1C142.301 379.8 142.901 380.3 143.501 380.3Z" fill="white"/>
              <path d="M254.1 335.5H190.6C190 335.5 189.6 335.1 189.6 334.5C189.6 333.9 190 333.5 190.6 333.5H254.1C254.7 333.5 255.1 333.9 255.1 334.5C255.1 335.1 254.6 335.5 254.1 335.5Z" fill="white"/>
              <path d="M254.1 358.8H190.6C190 358.8 189.6 358.4 189.6 357.8C189.6 357.2 190 356.8 190.6 356.8H254.1C254.7 356.8 255.1 357.2 255.1 357.8C255.1 358.4 254.6 358.8 254.1 358.8Z" fill="white"/>
              <path d="M224.8 347.2H190.6C190 347.2 189.6 346.8 189.6 346.2C189.6 345.6 190 345.2 190.6 345.2H224.8C225.4 345.2 225.8 345.6 225.8 346.2C225.8 346.8 225.3 347.2 224.8 347.2Z" fill="white"/>
              <path d="M224.8 371.2H190.6C190 371.2 189.6 370.8 189.6 370.2C189.6 369.6 190 369.2 190.6 369.2H224.8C225.4 369.2 225.8 369.6 225.8 370.2C225.8 370.8 225.3 371.2 224.8 371.2Z" fill="white"/>
              <path d="M213.6 382.3H190.5C189.9 382.3 189.5 381.9 189.5 381.3C189.5 380.7 189.9 380.3 190.5 380.3H213.6C214.2 380.3 214.6 380.7 214.6 381.3C214.6 381.9 214.2 382.3 213.6 382.3Z" fill="white"/>
            </g>
            <g opacity="0.81">
              <path d="M452.901 219.1H429.801C429.201 219.1 428.801 218.7 428.801 218.1C428.801 217.5 429.201 217.1 429.801 217.1H452.901C453.501 217.1 453.901 217.5 453.901 218.1C453.901 218.7 453.401 219.1 452.901 219.1Z" fill="white"/>
              <path d="M452.901 243.1H429.801C429.201 243.1 428.801 242.7 428.801 242.1C428.801 241.5 429.201 241.1 429.801 241.1H452.901C453.501 241.1 453.901 241.5 453.901 242.1C453.901 242.7 453.401 243.1 452.901 243.1Z" fill="white"/>
              <path d="M470.501 267.3H327.501C326.001 267.3 324.801 266.1 324.801 264.6V190.2C324.801 188.7 326.001 187.5 327.501 187.5H470.501C472.001 187.5 473.201 188.7 473.201 190.2V264.6C473.201 266.1 472.001 267.3 470.501 267.3ZM327.501 189.5C327.101 189.5 326.801 189.8 326.801 190.2V264.6C326.801 265 327.101 265.3 327.501 265.3H470.501C470.901 265.3 471.201 265 471.201 264.6V190.2C471.201 189.8 470.901 189.5 470.501 189.5H327.501Z" fill="white"/>
              <path opacity="0.66" d="M470.501 267.3H327.501C326.001 267.3 324.801 266.1 324.801 264.6V190.2C324.801 188.7 326.001 187.5 327.501 187.5H470.501C472.001 187.5 473.201 188.7 473.201 190.2V264.6C473.201 266.1 472.001 267.3 470.501 267.3ZM327.501 189.5C327.101 189.5 326.801 189.8 326.801 190.2V264.6C326.801 265 327.101 265.3 327.501 265.3H470.501C470.901 265.3 471.201 265 471.201 264.6V190.2C471.201 189.8 470.901 189.5 470.501 189.5H327.501Z" fill="white"/>
              <path d="M342.4 252.2H378.6C379.3 252.2 379.8 251.6 379.8 251V202C379.8 201.3 379.2 200.8 378.6 200.8H342.4C341.7 200.8 341.2 201.4 341.2 202V251C341.1 251.7 341.7 252.2 342.4 252.2Z" fill="white"/>
              <path d="M452.9 207.5H389.4C388.8 207.5 388.4 207.1 388.4 206.5C388.4 205.9 388.8 205.5 389.4 205.5H452.9C453.5 205.5 453.9 205.9 453.9 206.5C453.9 207.1 453.4 207.5 452.9 207.5Z" fill="white"/>
              <path d="M452.9 230.8H389.4C388.8 230.8 388.4 230.4 388.4 229.8C388.4 229.2 388.8 228.8 389.4 228.8H452.9C453.5 228.8 453.9 229.2 453.9 229.8C453.9 230.3 453.4 230.8 452.9 230.8Z" fill="white"/>
              <path d="M423.6 219.1H389.4C388.8 219.1 388.4 218.7 388.4 218.1C388.4 217.5 388.8 217.1 389.4 217.1H423.6C424.2 217.1 424.6 217.5 424.6 218.1C424.6 218.7 424.2 219.1 423.6 219.1Z" fill="white"/>
              <path d="M423.6 243.1H389.4C388.8 243.1 388.4 242.7 388.4 242.1C388.4 241.5 388.8 241.1 389.4 241.1H423.6C424.2 241.1 424.6 241.5 424.6 242.1C424.6 242.7 424.2 243.1 423.6 243.1Z" fill="white"/>
              <path d="M412.5 254.2H389.4C388.8 254.2 388.4 253.8 388.4 253.2C388.4 252.6 388.8 252.2 389.4 252.2H412.5C413.1 252.2 413.5 252.6 413.5 253.2C413.5 253.8 413 254.2 412.5 254.2Z" fill="white"/>
            </g>
            <path d="M301.2 427.5H273.6C270 427.5 267 424.5 267 420.9V273H310V418.7C310 423.6 306.1 427.5 301.2 427.5Z" fill="#3E479B"/>
            <path d="M303.7 427.5H286V273H310V421.2C310 424.7 307.2 427.5 303.7 427.5Z" fill="#4C65BA"/>
            <path d="M212.3 131H349.8C380.5 131 405.4 155.9 405.4 186.6V284.4C405.4 286.6 403.6 288.5 401.3 288.5H160C159.7 288.5 159.5 288.3 159.5 288V183.8C159.5 154.6 183.1 131 212.3 131Z" fill="#599AF2"/>
            <path opacity="0.07" d="M229.3 152.5H333.2C363.9 152.5 388.8 177.4 388.8 208.1V284.4C388.8 286.6 387 288.5 384.7 288.5H177C176.7 288.5 176.5 288.3 176.5 288V205.3C176.5 176.1 200.1 152.5 229.3 152.5Z" fill="#ECF1F7"/>
            <path opacity="0.65" d="M400.9 289H249V276H405.5V284.4C405.5 286.9 403.4 289 400.9 289Z" fill="#4C65BA"/>
            <path opacity="0.65" d="M400.9 289H249V276H405.5V284.4C405.5 286.9 403.4 289 400.9 289Z" fill="#4C65BA"/>
            <path d="M402 288.5H332.9V276.4H405.5V285C405.5 286.9 403.9 288.5 402 288.5Z" fill="#3E479B"/>
            <path opacity="0.07" d="M405.5 187H256.5V276H405.5V187Z" fill="#373449"/>
            <path d="M250.5 285H165.5C162.2 285 159.5 282.3 159.5 279V182.5C159.5 155.7 181.2 134 208 134C234.8 134 256.5 155.7 256.5 182.5V279C256.5 282.3 253.8 285 250.5 285Z" fill="#4C65BA"/>
            <path d="M249.5 285H164.5V183.5C164.5 160 183.5 141 207 141C230.5 141 249.5 160 249.5 183.5V285Z" fill="#373449"/>
            <path d="M247.1 303H104.2C98.8 303 94.5 298.6 94.5 293.3V285H258.5V291.6C258.5 297.9 253.4 303 247.1 303Z" fill="#599AF2"/>
            <path d="M247.1 303H202.2C196.8 303 192.5 298.6 192.5 293.3V285H258.5V291.6C258.5 297.9 253.4 303 247.1 303Z" fill="#4C65BA"/>
            <path d="M295.5 204C303.232 204 309.5 197.732 309.5 190C309.5 182.268 303.232 176 295.5 176C287.768 176 281.5 182.268 281.5 190C281.5 197.732 287.768 204 295.5 204Z" fill="#3E479B"/>
            <path d="M295.5 194.5C293 194.5 291 192.5 291 190V101C291 98.5 293 96.5 295.5 96.5C298 96.5 300 98.5 300 101V190C300 192.5 298 194.5 295.5 194.5Z" fill="#3E479B"/>
            <path d="M297.5 202C304.127 202 309.5 196.627 309.5 190C309.5 183.373 304.127 178 297.5 178C290.873 178 285.5 183.373 285.5 190C285.5 196.627 290.873 202 297.5 202Z" fill="#4C65BA"/>
            <path d="M301.5 122.7V101H342.5L301.5 122.7Z" fill="#6D6CD4"/>
            <path d="M301.5 122.7V101H342.5L301.5 122.7Z" fill="#6D6CD4"/>
            <path d="M297.5 194.5C295 194.5 293 192.5 293 190V101C293 98.5 295 96.5 297.5 96.5C300 96.5 302 98.5 302 101V190C302 192.5 300 194.5 297.5 194.5Z" fill="#4C65BA"/>
            <path d="M297.501 197C293.501 197 290.301 193.7 290.301 189.8C290.301 185.9 293.601 182.6 297.501 182.6C301.401 182.6 304.701 185.9 304.701 189.8C304.701 193.7 301.501 197 297.501 197ZM297.501 183.5C294.101 183.5 291.301 186.3 291.301 189.7C291.301 193.1 294.101 195.9 297.501 195.9C300.901 195.9 303.701 193.1 303.701 189.7C303.701 186.3 300.901 183.5 297.501 183.5Z" fill="#3E479B"/>
            <path d="M205.7 248.4H98.5004C95.4004 248.4 92.9004 245.9 92.9004 242.8V176.6C92.9004 173.1 95.7004 170.2 99.3004 170.2H203.4C207.6 170.2 211 173.6 211 177.8V243.2C210.9 246.1 208.6 248.4 205.7 248.4Z" fill="#DCB84F"/>
            <path d="M205.6 248.4H151.9H98.3004C95.3004 248.4 92.9004 246 92.9004 243V177.7C92.9004 176.9 92.9004 174.3 93.7004 173.4C93.7004 173.4 95.4004 174.7 96.1004 175.3L137.1 209.3L145.6 216.3C148.7 218.8 155.4 218.6 158.1 216.2L208.5 173.4C208.8 173.2 208.7 173.2 209.2 172.9C211 175.1 211 177.5 211 177.5V243C210.9 246 208.5 248.4 205.6 248.4Z" fill="#F4DF98"/>
            <path d="M145.601 213C150.301 216.8 153.501 216.6 158.101 212.9L207.301 171.3C207.301 171.3 205.701 170.2 203.401 170.2H99.2008C96.8008 170.2 96.3008 170.8 94.8008 172C95.3008 172.4 145.601 213 145.601 213Z" fill="#F4DF98"/>
            <path d="M205.602 246.1H205.802C208.702 246.1 211.002 243.8 211.002 240.9V240.7C210.902 243.7 208.502 246.1 205.602 246.1Z" fill="#F4DF98"/>
            <path d="M95.9996 248C95.6996 248 95.3996 247.9 95.2996 247.7C94.8996 247.3 94.9996 246.7 95.3996 246.3L138 208.5C138.4 208.1 139 208.2 139.4 208.6C139.8 209 139.7 209.6 139.3 210L96.6996 247.8C96.4996 247.9 96.1996 248 95.9996 248Z" fill="#DCB84F"/>
            <path d="M207 248C206.8 248 206.5 247.9 206.3 247.8L163.2 210.3C162.8 209.9 162.7 209.3 163.1 208.9C163.5 208.5 164.1 208.4 164.5 208.8L207.6 246.3C208 246.7 208.1 247.3 207.7 247.7C207.6 247.9 207.3 248 207 248Z" fill="#DCB84F"/>
            <circle cx="397" cy="115" r="45" fill="#28BC79"/>
            <path d="M425.301 93.6751C423.528 91.9995 420.748 92.0249 419.006 93.7327L386.391 125.706L375.937 115.47C374.207 113.777 371.446 113.762 369.697 115.435C367.838 117.215 367.855 120.19 369.733 121.949L384.317 135.606C385.48 136.695 387.292 136.685 388.443 135.582L425.323 100.252C427.198 98.4563 427.187 95.4578 425.301 93.6751Z" fill="white"/>
            <path d="M426.633 92.3265C424.805 90.5993 421.94 90.6255 420.144 92.3858L386.526 125.343L375.749 114.792C373.967 113.047 371.12 113.031 369.318 114.756C367.402 116.591 367.419 119.658 369.355 121.471L384.452 135.607C385.615 136.696 387.427 136.686 388.578 135.584L426.656 99.1055C428.588 97.2548 428.577 94.164 426.633 92.3265Z" fill="white"/>
          </svg>
        </Image>
      </Content>
   </Container>
  );
};

export default WhiteEmailConfirmationSuccessPage;
