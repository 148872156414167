import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 9px;
  font-weight: 400;
  font-family: Rubik-Regular;  
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  text-align: center;
  color: #303133;
  user-select: none;
  transition: all 270ms ease;
  cursor: pointer;

  ${styledProp('$selected', css`
    color: #109DFB;
    border-color: #109DFB;
  `)}
`;
