import React, { useEffect, useState } from 'react';

import { Container } from './styled';
import { Button, Input, Modal } from '@components';
import { useTranslation } from '@hooks';
import { Tooltip } from 'antd';

const labelToVariableName = (label) => label
  .replace(/[^a-zA-Z0-9\s]/g, '')
  .replace(/\s+/g, '_')
  .toLowerCase()
  .replace(/^[0-9]+/, '');

const CreateDynamicVariableModal = ({
  opened,
  onClose,
  onConfirmCreate,
  onConfirmUpdate,
  isUpdate,
  errors,
  value,
}) => {
  const { t, p } = useTranslation('templates');
  const [innerValue, setInnerValue] = useState({ name: '', label: '', default_value: '' });

  useEffect(() => {
    if (!value) {
      return;
    }

    if (isUpdate) {
      setInnerValue(value);
    } else {
      setInnerValue({ name: '', label: '', default_value: '' });
    }
  }, [value, isUpdate]);

  const handleLabelChange = ({ target: { value: label } }) => {
    errors.clearError('name');
    errors.clearError('label');
    if (!isUpdate) {
      return setInnerValue(v => ({ ...v, label, name: labelToVariableName(label) }));
    }

    return setInnerValue(v => ({ ...v, label }));
  };

  const handleDefaultValueChange = ({ target: { value: default_value } }) => {
    errors.clearError('default_value');
    setInnerValue(v => ({ ...v, default_value }));
  };

  const handleSubmit = () => {
    if (isUpdate) {
      return onConfirmUpdate(innerValue, () => {
        setInnerValue({ name: '', label: '', default_value: '' });
      });
    }

    return onConfirmCreate(innerValue, () => {
      setInnerValue({ name: '', label: '', default_value: '' });
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isUpdate ? p('update_dynamic_attribute') : p('create_dynamic_attribute')}
      actions={(
        <>
          <Button testId="customers-create-close" onClick={onClose} appearance="secondary" width={140}>{t('actions.close')}</Button>
          <Button onClick={handleSubmit} testId="customers-create-save" width={140}>{isUpdate ? t('actions.update') : t('actions.create')}</Button>
        </>
      )}
    >
      <Container>
        {isUpdate ? (
          <Tooltip title={p('update_dynamic_attribute_name_disclaimer')}>
            <div>
              <Input
                title="Label"
                value={innerValue.label}
                disabled={isUpdate}
                error={errors.errors['label'] || errors.errors['name']}
                onChange={handleLabelChange}
              />
            </div>
          </Tooltip>
        ) : (
          <Input
            title="Label"
            value={innerValue.label}
            error={errors.errors['label'] || errors.errors['name']}
            onChange={handleLabelChange}
          />
        )}

        <Input
          title="Default value"
          value={innerValue.default_value}
          error={errors.errors['default_value']}
          onChange={handleDefaultValueChange}
        />
      </Container>
    </Modal>
  );
};

export default CreateDynamicVariableModal;
