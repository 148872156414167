const NotificationsActionTypes = {
  GET_NOTIFICATIONS: 'notifications/GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'notifications/GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_ERROR: 'notifications/GET_NOTIFICATIONS_ERROR',

  MARK_AS_READ: 'notifications/MARK_AS_READ',
  MARK_AS_READ_SUCCESS: 'notifications/MARK_AS_READ_SUCCESS',
  MARK_AS_READ_ERROR: 'notifications/MARK_AS_READ_ERROR',

  ADD_NOTIFICATION: 'notifications/ADD_NOTIFICATION',
};

export default NotificationsActionTypes;
