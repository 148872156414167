import styled, { css } from 'styled-components';

import { animated } from '@react-spring/web';
import { extract, styledProp } from '@utils';

export const Container = styled.div`

`;

export const SelectsContainer = styled.div`
  display: flex;
  margin-top: 14px;
`;

export const FiltersContainer = styled.div`
  margin-left: 190px;
`;
