import React from 'react';

import { setCachedUsersToInvite, updateRedirects } from '@store/actions/creators';
import NavigationPrompt from 'react-router-prompt';
import { useDispatch, useSelector } from 'react-redux';
import { appRedirectsSelector } from '@store/selectors/app';
import { ChangesNotSavedDisclaimer, LeavePageConfirmationModal } from '@components';

const NavigationConfirmation = ({ isEdit, hidden, backClick, onSave, redirectKey, target = '' }) => {
  const appRedirects = useSelector(appRedirectsSelector);
  const dispatch = useDispatch();
  const isRedirect = !!appRedirects[redirectKey];

  return (
    <NavigationPrompt
      when={(fromLocation, toLocation, action) => {
        if (fromLocation?.pathname === toLocation?.pathname) {
          return false;
        }

        return action && action !== 'REPLACE' && (isRedirect || isEdit) && !hidden && !(isRedirect && backClick);
      }}
      afterConfirm={() => {
        if (target === 'users') {
          dispatch(setCachedUsersToInvite({ cachedUsers: null }))
        }

        dispatch(updateRedirects({ [redirectKey]: null }));
      }}
    >
      {({ onConfirm, onCancel }) => isRedirect ? (
        <LeavePageConfirmationModal
          onClose={onCancel}
          hidden={hidden}
          target={target}
          onSubmit={onConfirm}
        />
      ) : (
        <ChangesNotSavedDisclaimer
          onClose={onCancel}
          onLeave={onConfirm}
          hidden={hidden}
          onSubmit={() => { onSave({ onConfirm, onCancel }) }}
        />
      )}
    </NavigationPrompt>
  );
}

export default NavigationConfirmation;


