import styled, { css } from 'styled-components';
import { Link } from '@components';
import { styledProp } from '@utils';
import { Device } from '@res';
import { TextVariants } from '@constants';
import { Text } from '@components';

export const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  
  transition: opacity 270ms ease;
`;

export const AnalyticSection = styled.div`
  background: linear-gradient(251.17deg, #E6F1FC 2.11%, #ECF8FF 90.83%);
  border-bottom: 1px solid #C1CEFC;

  padding: 0 0 26px 0;

  @media screen and ${Device.desktopS} {
    padding: 0 142px 26px 242px;
  }
`;

export const EntitiesSection = styled.div`
  padding: 0 0px 26px 0px;

  @media screen and ${Device.desktopS} {
    padding: 0 142px 26px 242px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  padding-top: 26px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const SkipModalTitle = styled.div`
  margin: 10px 0 40px 0;
  text-align: center;
  font-size: 16px;
`;

export const SkipModalText = styled.div`
  color: #909399;
  font-size: 13px;
  margin-bottom: 30px;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 20px;
  color: #303133;
  margin-right: 4px;
`;

export const ContactsSection = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const RecentActivitySection = styled.div`
  display: flex;
  padding-top: 24px;
  flex: 0 0 auto;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  position: relative;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const ContactsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 36px 0 30px;
  flex: 1;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-left: 10px;
  position: relative;
`;

export const ContactsChartsTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const ContactsChartTitle = styled(Text).attrs({ variant: TextVariants.PARAGRAPH_2 })`
  color: #909399;
`;

export const SendingSection = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 18px 30px 24px;
`;

export const TemplatesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const SendingTitle = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin-bottom: 12px;
`;

export const SendingContainer = styled.div`
  display: flex;
  position: relative;
`;

export const SendingItemsContainer = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-right: 10px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TemplatesTitle = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin-bottom: 12px;
`;

export const ActivityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  ${styledProp('bordered', css`
    border-bottom: 1px solid #DCDFE6;
  `)}
`;

export const WorkflowName = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const DateCell = styled.div`
`;

export const TemplatesContainer = styled.div`
  display: flex;
  flex: 1;
  
  ${styledProp('onlyEmails', `
    display: block;
  `)}
`;

export const TemplatesCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TemplatesInner = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 15px 20px;
`;

export const TemplatesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EmailTemplatesRow = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3,1fr);

  @media screen and (max-width: 1185px) {
    grid-template-columns: repeat(2,1fr);
  }

  ${styledProp('$empty', css`
    grid-template-columns: none;
  `)}

  grid-gap: 14px;
`;

export const SmsTemplatesRow = styled.div`
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  align-content: flex-start;
`;

export const GettingStartedTitle = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin: 20px 0 41px 0;
`;

export const SendingChartContainer = styled.div`
  display: flex;
  flex: 1; 
  marginLeft: 10;
`;
