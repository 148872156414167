import styled from 'styled-components';

import { Colors } from '@res/theme';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  height: 75%;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${({ marginRightPx }) => `${marginRightPx}px`};
  width: ${({ widthPer, marginRightPx }) =>
    marginRightPx ? `calc(${widthPer}% - ${marginRightPx}px)` : `${widthPer}%`};
  height: 100%;
`;

export const Title = styled.p`
  
  margin: 9.6px 0;
  font-size: 18px;
  width: 100%;
  color: ${Colors.Text.PRIMARY};
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  background-color: ${Colors.Common.APP_BG};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 12px;
  font-size: 13px;
  line-height: 120%;
  color: ${Colors.Text.PRIMARY};
  border: none;
  outline: none;
  resize: none;
`;

export const PreviewSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${Colors.Common.APP_BG};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;

  .EmailSampleData-drawer {
    position: absolute;
    width: 100% !important;
  }
`;

export const Preview = styled.div`
  position: relative;
`;

export const PhoneImage = styled.img``;

export const PreviewPlaceholder = styled.p`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 150px;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: ${Colors.Text.LIGHT};
  transform: translate(-50%, -50%);
`;

export const Message = styled.p`
  position: absolute;
  bottom: 5%;
  left: 10%;
  width: 80%;
  max-height: 80%;
  padding: 4px 6px;
  font-size: 10px;
  color: ${Colors.Text.WHITE};
  white-space: pre-line;
  word-wrap: break-word;
  background-color: #109DFB;
  border-radius: 5px 5px 5px 0px;
  overflow-y: scroll;
`;

export const Counter = styled.p`
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0;
  font-size: 12px;
  color: ${({ isError }) => (isError ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
`;
