import React from 'react';

import { Container, AddFilterButton, FieldRow, SelectRow, FieldLabel } from './styled';
import { AutoComplete, Select, OperatorSelect } from '@components';
import { ActionButton } from '@components';
import {
  DeleteFilterButton
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/EventNodeSettings/styled';
import { useTranslation } from '@hooks';
import {
  SegmentsRuleTypes,
  AGGREGATE_FUNCTION_AVG,
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_SUM
} from '@constants';
import { useSelector } from 'react-redux';
import { eventsListSelector, fieldsSettingsListSelector } from '@store/selectors';

const TypeLabel = {
  'array': 'array',
  'event': 'event',
};

const SubFilterRule = ({
  testId,
  appearance,
  options,
  value,
  type,
  query,
  onChange,
  autocomplete,
  disableFirstDelete,
  nestedAggregates = [],
  strictPayload = true,
  showErrors
}) => {
  const { p, t } = useTranslation('segments_page');
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const events = useSelector(eventsListSelector);

  const handleAddFilter = () => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        wasPerformed: false,
        filters: [...q.query.filters, { field: '', type: '', value: { type: 'scalar', value: '' } }]
      }
    }));
  };

  const handleFieldChange = (index) => (field, _, option) => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        type,
        filters: q.query.filters.map((f, i) => i !== index ? f : {
          ...f,
          field,
          filterType: option.type === 'aggregate' ? 'aggregate' : 'default',
          aggregateId: option.type === 'aggregate' ? option.id : null,
          operator: '',
          value: {
            value: '',
            type: 'scalar',
          },
          nested: option?.nested,
        }),
      },
    }));
  };

  const handleFieldOperatorChange = (index) => (operator, type) => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        filters: q.query.filters.map((f, i) => i !== index ? f : {
          ...f,
          type,
          operator,
          value: {
            ...f.value,
            value: '',
          }
        }),
      },
    }));
  };

  const handleFieldValueChange = (index) => (value) => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        filters: q.query.filters.map((f, i) => i !== index ? f : {
          ...f,
          value: {
            ...f.value,
            value,
          },
        }),
      },
    }));
  };

  const handleFieldTypeChange = (index) => (type) => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        filters: q.query.filters.map((f, i) => i !== index ? f : {
          ...f,
          type,
          value: {
            ...f.value,
          },
        }),
      },
    }));
  };

  const handleRemoveFilter = (index) => () => {
    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        filters: q.query.filters.filter((f, i) => i !== index),
      },
    }));
  };

  const getInitialType = (nested, field) => {
    const agr = nestedAggregates.find(na => na.name === field);
    const p = type === 'event' ? events?.data?.find(e => e.name === query.field)?.payload : fieldSettings?.data?.filter(f => f.type === 'array')?.find(a => a.field === query.field)?.payload;

    const t = nested ? p?.find?.(f => f.field === nested)?.payload?.find?.(f => f.field === field) : p?.find?.(p => p.field === (agr?.nested_field || field));

    if (!!agr) {
      return (agr.aggregate === AGGREGATE_FUNCTION_COUNT || agr.aggregate === AGGREGATE_FUNCTION_SUM || agr.aggregate === AGGREGATE_FUNCTION_AVG)
        ? 'numeric'
        : t?.payload?.find?.(i => i.field === agr.attribute)?.type;
    }

    return t?.type || 'numeric';
  }

  return (
    <Container $appearance={appearance} data-testid={`${testId}-container`}>
      {value.map(({ field, hidden, value, type: valueType, operator, errors, nested }, index, self) => {
        if (hidden) {
          return null;
        }

        const hiddenCount = self.reduce((acc, next) => acc + +!!next.hidden, 0);

        const first = index === hiddenCount;

        const renderFirstInput = () => {
          if (appearance === 'column') {
            return strictPayload ? (
              <SelectRow>
                <FieldLabel>
                  {type === SegmentsRuleTypes.EVENT ? `${p('event_field')} ${index + 1}` : `${p('array_field')} ${index + 1}`}
                </FieldLabel>
                <Select
                  testId={`${testId}-${index}-select`}
                  options={options}
                  label={null}
                  // showTypes
                  style={{ height: 26, width: '100%' }}
                  containerStyle={{ width: '100%' }}
                  wrapperStyles={{ width: '100%' }}
                  tooltipError={showErrors && errors?.['field']}
                  onChange={handleFieldChange(index)}
                  value={field}
                  valueKey={nested ? `${nested}.${field}` : void 0}
                />
              </SelectRow>
            ) : (
              <SelectRow>
                <FieldLabel>
                  {type === SegmentsRuleTypes.EVENT ? `${p('event_field')} ${index + 1}` : `${p('array_field')} ${index + 1}`}
                </FieldLabel>
                <AutoComplete
                  smaller
                  showTypes
                  style={{ marginBottom: 0, width: '100%' }}
                  innerStyle={{ height: 26, width: '100%' }}
                  testId={`${testId}-${index}-select`}
                  title={null}
                  options={options.map(o => ({ ...o, label: <><span className="prefix">{o.prefix ? `${(o.prefix)}.` : ''}</span>{(o.label)}</> })) }
                  error={showErrors && errors?.['field']}
                  onChange={handleFieldChange(index)}
                  value={field}
                  valueKey={nested ? `${nested}.${field}` : void 0}
                />
              </SelectRow>
            )
          }

          return strictPayload ? (
            <Select
              testId={`${testId}-${index}-select`}
              options={options}
              showTypes
              page="segments_page"
              label={first ? (type === SegmentsRuleTypes.EVENT ? p('event_field') : p('array_field')) : null}
              style={{ height: 26, width: 186 }}
              tooltipError={showErrors && errors?.['field']}
              onChange={handleFieldChange(index)}
              value={field}
              valueKey={nested ? `${nested}.${field}` : void 0}
            />
          ) : (
            <AutoComplete
              smaller
              showTypes
              page="segments_page"
              popupClassName="show-types"
              style={{ marginBottom: 0 }}
              innerStyle={{ height: 26 }}
              testId={`${testId}-${index}-select`}
              title={first ? (type === SegmentsRuleTypes.EVENT ? p('event_field') : p('array_field')) : null}
              options={options.map(o => ({ ...o, label:  o.prefix ? <><span className="prefix">{o.prefix ? `${(o.prefix)}.` : ''}</span>{(o.label)}</> : o.label })) }
              error={showErrors && errors?.['field']}
              onChange={handleFieldChange(index)}
              value={field}
              valueKey={nested ? `${nested}.${field}` : void 0}
            />
          )
        };

        return (
          <FieldRow $bordered={appearance === 'column' && index > 0} $appearance={appearance} key={index}>
            {appearance === 'column' && (
              <DeleteFilterButton onClick={handleRemoveFilter(index)}>
                {p('delete_filter')}
              </DeleteFilterButton>
            )}
            {renderFirstInput()}
            <OperatorSelect
              testId={`${testId}-${index}-operator-select`}
              smaller
              disabled={false}
              initialType={getInitialType(nested, field) || options?.find(o => o.value === field)?.type || 'numeric'}
              wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
              value={{ value: value.value, type, operator, errors: showErrors && errors }}
              autocomplete={valueType === 'numeric' ? undefined : {
                event: type === 'event' && query.field,
                fields: [type === 'array' && query.field, nested, field],
                nested: nested && field,
              }}
              type={valueType}
              field={field}
              fullWidth={appearance === 'column'}
              appearance={appearance}
              onTypeChange={handleFieldTypeChange(index)}
              onValueChange={handleFieldValueChange(index)}
              onOperatorChange={handleFieldOperatorChange(index)}
              autoComplete={nested ? autocomplete?.[nested]?.[field] : autocomplete?.[field] || []}
              labeled={!!first && appearance !== 'column'}
            />
            {appearance === 'column' ? <div /> : (
              <ActionButton
                data-testid={`${testId}-${index}-remove`}
                iconSize={14}
                appearance="danger"
                size={20}
                disabledTooltip={p('at_least_one_filter')}
                disabled={disableFirstDelete && (self.length - 1 === hiddenCount)}
                style={{ marginLeft: 6, marginTop: first ? 23 : 3 }}
                icon="Delete-icon"
                tooltip={p('remove_filter')}
                onClick={handleRemoveFilter(index)}
              />
            )}
          </FieldRow>
        )
      })}
      <AddFilterButton
        $bordered={!!value.length && appearance === 'column'}
        $appearance={appearance}
        onClick={handleAddFilter}
        data-testid={`${testId}-add-button`}
      >
        + {p('add')} {p(TypeLabel[type])} {p('filter').toLowerCase()}
      </AddFilterButton>
    </Container>
  );
};

export default SubFilterRule;
