import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #1F1F24;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 22px;
  left: 20px;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 14px;
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #7C8C9E;
  margin-bottom: 42px;
`;

export const PrizeText = styled.div`
  font-size: 16px;
  line-height: 140%;
  color: #FFFFFF;
  margin-bottom: 50px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
`;
