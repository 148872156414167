export const CONTACTS_IMPORT = `Id,Email,Phone,First Name,Last name,Country,City,Age,Birth date
887274,test@gmail.com,12345678,Anna,Smith,Austria,Vienna,30,12.13.1980
887345,test1@gmail.com,12345679,John,Dalin,USA,Washington,31,12.13.1981
887483,test2@gmail.com,12345680,Amily,Linsen,Canada,Ottawa,32,12.13.1982
887627,test3@gmail.com,12345681,Jason,Gilbert,France,Paris,33,12.13.1983
887403,test4@gmail.com,12345682,Anna,Heath,Finland,Helsinki,34,12.13.1984
887563,test5@gmail.com,12345683,Davie,Osborne,Germany,Berlin,35,12.13.1985
887697,test6@gmail.com,12345684,Helen,Sharp,Greece,Athens,36,12.13.1986
887753,test7@gmail.com,12345685,Jeremy,Walters,Poland,Warsaw,37,12.13.1987
887807,test8@gmail.com,12345686,Jack,Quinn,Ukraine,Kyiv,38,12.13.1988
887879,test9@gmail.com,12345687,Alexandra,Gibbs,Italy,Rome,39,12.13.1989`;
