import { useDispatch, useSelector } from 'react-redux';
import { hintModeSelector } from '@store/selectors';
import { updateOnboarding } from '@store/actions/creators';
import { ReducerKeys } from '@constants';

export const useOnboarding = (key) => {
  const hintMode = useSelector(hintModeSelector);
  const step = useSelector(state => state[ReducerKeys.APP].onboarding[key]?.step);
  const skipped = useSelector(state => state[ReducerKeys.APP].onboarding[key]?.skipped);
  const dispatch = useDispatch();

  const nextStep = () => {
    dispatch(updateOnboarding(key, { step: step + 1 }))
  }

  const end = () => {
    dispatch(updateOnboarding(key, { step: null, passed: true }))
  }

  return {
    step,
    skipped,
    nextStep,
    end,
    enabled: (st) => step === st && hintMode,
  }
};

export default useOnboarding;
