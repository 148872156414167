import { useEffect } from 'react';
import { call } from '@utils';

const defaultKeyFilter = key => e => e.key === key;
const ctrlKeyFilter = e => e.ctrlKey;
const shiftKeyFilter = e => e.shiftKey;
const commandKeyFilter = e => e.metaKey;

const resolveKeyFilter = (key) => {
  switch (key) {
    case 'ctrl':
      return ctrlKeyFilter;
    case 'command':
      return commandKeyFilter;
    case 'shift':
      return shiftKeyFilter;
    default:
      return defaultKeyFilter(key);
  }
}

const useKeydown = (handlers, deps = [], target = document, condition = true) => {
  useEffect(() => {
    if (!condition) {
      return () => {};
    }

    const listener = (event) => {
      handlers.forEach(([keys, handler]) => {
        if (keys.map(resolveKeyFilter).every(call(event))) {
          handler(event);
        }
      })
    };

    target.addEventListener('keydown', listener, true);

    return () => {
      target.removeEventListener('keydown', listener);
    }
  }, [handlers, ...deps, condition]);
};

export default useKeydown;
