import { compose } from '@utils';
import {asCreator, withCallbacks, withFilters, withOrdering, withPagination} from '../../lib';
import {WorkflowActionTypes} from '../types';

export const listWorkflows = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'status', 'user', 'resource', 'start_date', 'end_date', 'execution_range']),
  asCreator(() => ({
    type: WorkflowActionTypes.LIST,
  })),
)

export const saveWorkflow = compose(
    withCallbacks,
    asCreator(({ program, worker }) => ({
        type: WorkflowActionTypes.SAVE,
        payload: { program, worker },
        meta: program.id,
    }))
);

export const saveWorkflowImage = compose(
  withCallbacks,
  asCreator(({ image, id }) => ({
    type: WorkflowActionTypes.SAVE_IMAGE,
    payload: { image, id },
  }))
);

export const publishWorkflow = compose(
    withCallbacks,
    asCreator(({ id, schedule, fake }) => ({
        type: WorkflowActionTypes.PUBLISH,
        payload: { id, schedule, fake },
        meta: id,
    }))
);

export const getWorkflowOptions = () => ({
  type: WorkflowActionTypes.GET_OPTIONS,
});

export const getWorkflowEditMetadata = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: WorkflowActionTypes.GET_EDIT_METADATA,
    meta: { id },
  })),
);

export const getWorkflowProgram = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: WorkflowActionTypes.GET_WORKFLOW_PROGRAM,
    meta: { id },
  }))
)

export const getWorkflowWorker = compose(
  withCallbacks,
  asCreator(({ program_id, id }) => ({
    type: WorkflowActionTypes.GET_WORKFLOW_WORKER,
    payload: { program_id, worker_id: id },
  }))
);

export const stopWorkflow = compose(
    withCallbacks,
    asCreator(({ id, full_stop }) => ({
        type: WorkflowActionTypes.UN_PUBLISH,
        payload: { id, full_stop },
        meta: id,
    }))
);

export const deleteVersionWorkflow = compose(
    withCallbacks,
    asCreator(({ id }) => ({
        type: WorkflowActionTypes.DELETE_VERSION,
        payload: { id },
        meta: id,
    }))
);


export const deleteWorkflow = compose(
    withCallbacks,
    asCreator(({ id }) => ({
        type: WorkflowActionTypes.DELETE,
        payload: { id },
        meta: id,
    }))
);

export const archiveWorkflow = compose(
    withCallbacks,
    asCreator(({ id }) => ({
        type: WorkflowActionTypes.ARCHIVE,
        payload: { id },
        meta: id,
    }))
);

export const unArchiveWorkflow = compose(
    withCallbacks,
    asCreator(({ id }) => ({
        type: WorkflowActionTypes.UN_ARCHIVE,
        payload: { id },
        meta: id,
    }))
);


export const participationWorkflow = compose(
    withCallbacks,
    asCreator(({ id, participation }) => ({
        type: WorkflowActionTypes.PARTICIPATION_WORKFLOW,
        payload: { id, participation },
        meta: id,
    }))
);

export const exitCriterionWorkflow = compose(
    withCallbacks,
    asCreator(({ id, exit_criterion }) => ({
        type: WorkflowActionTypes.EXIT_CRITERION_WORKFLOW,
        payload: { id, exit_criterion },
        meta: id,
    }))
);

export const getNodeAnalytics = compose(
    withCallbacks,
    asCreator(({ range, node_id = null, withLoader = true }) => ({
        type: WorkflowActionTypes.GET_NODE_ANALYTICS,
        payload: { range, node_id },
        meta: { withLoader, node_id },
    })),
);

export const getWorkflowAnalytics = compose(
    withCallbacks,
    asCreator(({ id, range, withLoader = true }) => ({
        type: WorkflowActionTypes.GET_PROGRAM_ANALYTICS,
        payload: { range },
        meta: { id, withLoader },
    })),
);

export const getWorkflowVersionAnalytics = compose(
  withCallbacks,
  asCreator(({ id, range, workflow_id, withLoader = true }) => ({
    type: WorkflowActionTypes.GET_ANALYTICS,
    payload: { range, workflow_id },
    meta: { id, withLoader },
  })),
);

export const getWorkflowLogs = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: WorkflowActionTypes.GET_LOGS,
    meta: { id },
  })),
);

export const getWorkflowPublishHistory = compose(
  withPagination,
  withCallbacks,
  asCreator(({ id }) => ({
    type: WorkflowActionTypes.GET_PUBLISH_HISTORY,
    meta: { id },
  })),
);

export const downloadContactByNode = compose(
    asCreator(({ node_id, range, additionalNodes = [] }) => ({
        type: WorkflowActionTypes.DOWNLOAD_CONTACT_BY_NODE,
        payload: { node_id, range, additionalNodes },
    })),
);

export const setDefaultEntryPoint = ({ node }) => ({
  type: WorkflowActionTypes.SET_DEFAULT_ENTRY_POINT,
  payload: { node },
});

export const setDefaultNodes = ({ nodes }) => ({
  type: WorkflowActionTypes.SET_DEFAULT_NODES,
  payload: { nodes },
});

export const clearDefaultEntryPoint = () => ({
  type: WorkflowActionTypes.CLEAR_DEFAULT_ENTRY_POINT,
});

export const getEmailNodeAnalytics = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range }) => ({
    type: WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS,
    payload: { id, node_id, range },
    meta: { withLoader, node_id },
  }))
);

export const getSmsNodeAnalytic = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range }) => ({
    type: WorkflowActionTypes.GET_SMS_NODE_ANALYTICS,
    payload: { id, node_id, range },
    meta: { withLoader, node_id }
  }))
);

export const getWebpushNodeAnalytic = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range }) => ({
    type: WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS,
    payload: { id, node_id, range },
    meta: { withLoader, node_id }
  })),
);

export const getMobilePushNodeAnalytic = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range, additionalNodes }) => ({
    type: WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS,
    payload: { id, node_id, range, additionalNodes },
    meta: { withLoader, node_id }
  })),
);

export const getWebhookNodeAnalytic = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range }) => ({
    type: WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS,
    payload: { id, node_id, range },
    meta: { withLoader, node_id }
  })),
);

export const getViberNodeAnalytic = compose(
  withCallbacks,
  asCreator(({ id, node_id, withLoader = true, range }) => ({
    type: WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS,
    payload: { id, node_id, range },
    meta: { withLoader, node_id }
  })),
);

export const clearAnalytics = () => ({
  type: WorkflowActionTypes.CLEAR_ANALYTICS,
});

export const clearWorkflowLogs = () => ({
  type: WorkflowActionTypes.CLEAR_WORKFLOW_LOGS,
});

export const downloadNodeAnalyticsByChannel = compose(
  withCallbacks,
  asCreator(({ worker_id, node_id, status, additionalNodes = [], channel_type }) => ({
    type: WorkflowActionTypes.DOWNLOAD_NODE_ANALYTICS_BY_CHANNEL,
    payload: { worker_id, node_id, status, channel_type, additionalNodes }
  }))
);

export const clearWorkflowWorker = ({ id }) => ({
  type: WorkflowActionTypes.CLEAR_WORKFLOW_WORKER,
  payload: { id },
});

export const updateQuietHours = compose(
  withCallbacks,
  asCreator(({ id, quite_hours }) => ({
    type: WorkflowActionTypes.UPDATE_QUIET_HOURS,
    payload: { id, quite_hours },
  }))
);

export const getEntryCount = compose(
  withCallbacks,
  asCreator(({ id, filter }) => ({
    type: WorkflowActionTypes.GET_ENTRY_COUNT,
    payload: { id, filter },
    meta: { id },
  }))
);

export const clearEntryCount = compose(
  withCallbacks,
  asCreator(({}) => ({
    type: WorkflowActionTypes.CLEAR_ENTRY_COUNT,
  }))
);

