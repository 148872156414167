import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 71.42%, #EDF8FF 90.83%);
  border-radius: 16px;
  padding: 30px 60px 28px;
  border: 1px solid #CCD7FF;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid rgba(193, 206, 252, 0.4);
  box-shadow: 0px 4px 6px rgba(193, 206, 252, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Caption = styled.div`
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #303133;
  margin-top: 30px;
  margin-bottom: 20px;
`;
