import React from 'react';

import { StyledTimePicker, Container, Title, TitleContainer, Error } from './styled';
import { useTranslation } from '@hooks';

const TimePicker = ({ title, style, error, ...props }) => {
  const { e } = useTranslation();
  return (
    <Container style={style}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
      )}
      <StyledTimePicker $error={!!error} {...props} />
      {error && (<Error>{e(error)}</Error>)}
    </Container>
  );
};

export default TimePicker;
