import React, { useContext } from 'react';

import { Container } from './styled.js';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { WorkflowEditorContext } from '@contexts';
import { useTranslation, useNavigate } from '@hooks';
import { updateRedirects } from '@store/actions/creators';
import { AppRedirects, Paths, UserPermissions } from '@constants';
import { WorkflowActionTypes } from '@store/actions/types';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { by, diffFields, removeAppFromPath, testId, update } from '@utils';
import { DynamicVariablesSettings, NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';
import { Row } from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/SendEmailNodeSettings/styled';
import { ActionButton, IconButton, Input, SearchSelect, Textarea, WithPermissions } from '@components';
import {
  FooterOption,
  FooterOptionLabel
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/WebpushNodeSettings/styled';

const MobilePushNodeSettings = ({
 value,
 label,
 description,
 onChange,
 options,
 onLabelChange,
 onDescriptionChange,
 errors,
 onClearError,
 onEditTemplate,
 editable = true,
 compareWithValue,
}) => {
  const templateOptions = options?.mobile_push?.filter(t => t.status !== 3)?.map?.(({ id, name }) => ({ value: id, label: name }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');
  const { t, p } = useTranslation('workflow_page');
  const mobile_push = options?.mobile_push?.find?.(by(value.mobile_push_id));

  const handleTemplateChange = (mobile_push_id) => {
    onClearError('mobile_push_id');
    onChange(s => ({ ...s, mobile_push_id }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handleCreateNew = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.MOBILE_PUSH_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'mobile_push_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/new`);
  };

  const handleCustomChange = (updater) => {
    onChange(s => ({ ...s, dynamic: update(s.dynamic || {}, updater) }));
  };

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <Row style={{ alignItems: 'flex-end' }}>
              <SearchSelect
                {...testId('mobile-push-node-settings-required-template')()}
                highlight={diff['mobile_push_id'] === false}
                disabled={!editable}
                error={errors['mobile_push_id']}
                containerStyle={{ width: '100%' }}
                style={{ width: '100%' }}
                title={t('labels.template')}
                value={value.mobile_push_id}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                placeholder={t('actions.select')}
                onChange={handleTemplateChange}
                options={templateOptions || []}
                footerOption={(
                  <WithPermissions name={UserPermissions.CREATE_MOBILE_PUSH}>
                    <FooterOption onClick={handleCreateNew}>
                      <ActionButton {...testId('mobile-push-node-settings-required-template-add')()} size={22} icon="Plus-icon" />
                      <FooterOptionLabel>
                        {p('create_template')}
                      </FooterOptionLabel>
                    </FooterOption>
                  </WithPermissions>
                )}
              />
              <IconButton
                {...testId('mobile-push-node-settings-required-template-edit')()}
                icon="Edit-icon"
                style={{ marginLeft: 6 }}
                onClick={onEditTemplate}
                disabled={!value.mobile_push_id}
              />
            </Row>
            <DynamicVariablesSettings
              value={value.dynamic || {}}
              onChange={handleCustomChange}
              variables={mobile_push?.dynamicVariables || []}
            />
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('mobile-push-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              disabled={!editable}
              value={label}
              maxLength={40}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('mobile-push-node-settings-description-text')()}
              highlight={diff['description'] === false}
              disabled={!editable}
              value={description}
              style={{ height: '150px' }}
              wrapperStyles={{ height: '150px' }}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default MobilePushNodeSettings;


