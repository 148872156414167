import React from 'react';

import { Container } from './styled.js';
import { Body } from '../NotificationItem/styled';

const SystemNotificationBody = ({ body, style = {}, tid }) => {
  return (
    <Container style={style}>
      <Body {...tid('body')}>
        {typeof body === 'string' ? body : body.map((bi, idx) => (
          <span key={idx}>{bi}</span>
        ))}
      </Body>
    </Container>
  );
}

export default SystemNotificationBody;


