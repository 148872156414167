import WorkflowEditorActionTypes from '@store/actions/types/workflowEditor';
import { UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const initialState = {
  workflows: {
    new: {
      nodes: [],
    },
  },
  openedNode: null,
  currentVersion: null,
  applyNodesChanges: [],
};

const workflowEditorReducer = (state = initialState, action) => {

  switch (action.type) {
    case WorkflowEditorActionTypes.SET_NODES: {
      const { id } = action.meta;
      const { nodes, name, description } = action.payload;
      const { [id]: workflow } = state.workflows;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [id]: {
            ...(workflow || {}),
            nodes,
            name,
            description
          },
        },
      };
    }
    case WorkflowEditorActionTypes.SET_CURRENT_VERSION:
      return {
        ...state,
        currentVersion: action.payload.version,
      };
    case WorkflowEditorActionTypes.DISCARD_NEW: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          new: {
            nodes: [],
          },
        },
      };
    }
    case WorkflowEditorActionTypes.DISCARD: {
      const { id } = action.meta;

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [id]: {
            nodes: [],
          },
        },
      };
    }
    case WorkflowEditorActionTypes.UPDATE_OPENED_NODE: {
      const { id, data, ...rest } = action.payload;

      return {
        ...state,
        // workflows: Object.fromEntries(Object.entries(state.workflows).map(([id, w]) => [id, ({
        //   ...w,
        //   nodes: w.nodes.map(n => n.id === id ? {
        //     ...n,
        //     ...rest,
        //     ...data,
        //   } : n),
        // })])),
        openedNode: {
          ...state.openedNode,
          id: action.payload.id || state.openedNode?.id,
          node: {
            ...(state.openedNode?.node || {}),
            ...rest,
            ...data,
          },
        },
      };
    }
    case WorkflowEditorActionTypes.DISCARD_OPENED_NODE: {
      return {
        ...state,
        openedNode: null,
      }
    }
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default workflowEditorReducer;
