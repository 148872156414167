import React from 'react';
import { Icon } from '@components';

import { Container } from './styled';
import { Tooltip } from 'antd';

const IconButton = ({ disabledTooltip, testId, icon, active, tooltip, appearance, size, disabled, ...props }) => {
  if (disabled && disabledTooltip) {
    return (
      <Tooltip title={disabledTooltip}>
        <Container
          data-testid={testId}
          disabled={disabled}
          appearance={appearance}
          active={active}
          {...props}
          onClick={disabled ? () => {} : props.onClick}
        >
          <Icon color="#000000" style={{ lineHeight: `${size || 16}px` }} name={icon} size={size || 16} />
        </Container>
      </Tooltip>
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <Container
          data-testid={testId}
          disabled={disabled}
          appearance={appearance}
          active={active}
          {...props}
          onClick={disabled ? () => {} : props.onClick}
        >
          <Icon color="#000000" style={{ lineHeight: `${size || 16}px` }} name={icon} size={size || 16} />
        </Container>
      </Tooltip>
    );
  }

  return (
    <Container
      data-testid={testId}
      disabled={disabled}
      appearance={appearance}
      active={active}
      {...props}
      onClick={disabled ? () => {} : props.onClick}
    >
      <Icon color="#000000" style={{ lineHeight: `${size || 16}px` }} name={icon} size={size || 16} />
    </Container>
  );
};

export default IconButton;
