import FieldsActionTypes from '@store/actions/types/fields';
import { updateAt } from '@utils';
import uniq from 'lodash.uniq';
import { UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const ORDINARY = Symbol('ordinary');

const initialState = {
  autocomplete: {
    [ORDINARY]: { },
  },
  dynamic_variables: {
    loading: false,
    data: [],
  },
};

const createPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

const fieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FieldsActionTypes.GET_AUTOCOMPLETE: {
      const { path, filter } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, `${p}.loading`, true),
      };
    }
    case FieldsActionTypes.GET_AUTOCOMPLETE_SUCCESS: {
      const { path, filter, offset } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, p, s => ({
          ...s,
          loading: false,
          data: uniq([...(action.payload || []), ...((offset !== 0 && s?.data) || [])]),
        })),
      }
    }
    case FieldsActionTypes.GET_AUTOCOMPLETE_ERROR: {
      const { path, filter } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, `${p}.loading`, false)
      };
    }
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: true,
        },
      };
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES_SUCCESS:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: false,
          data: action.payload?.data || [],
        },
      };
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES_ERROR:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: false,
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH:
      return initialState;
    default:
      return state;
  }
};

export default fieldsReducer;
