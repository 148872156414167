import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
`;

export const StyledTextarea = styled.textarea`
  border: 1px solid #DCDFE6;
  width: 100%;
  box-sizing: border-box;
  border-radius: 9px;
  height: 150px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: 13px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: none !important;
  transition: all .3s;
  resize: none;
  outline: none;
  
  ${styledProp('autoResizable', `
    height: auto;
    min-height: 36px;
    overflow-y: auto;
  `)}
  
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  ${styledProp('disabled', `
    cursor: not-allowed;
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0,0,0,.25);
  `)}

  &:focus {
    box-shadow: none !important;
    border-color: #109DFB !important;
  }
  &:focus-visible {
    box-shadow: none !important;
    border-color: #109DFB !important;
  }
  &:hover {
    box-shadow: none !important;
    border-color: #109DFB !important;
  }
`;


