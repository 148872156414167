import React, { useEffect } from 'react';

import { Container, FieldRow, AddFieldButton, Labels, Label, DeleteIcon, RowItem } from './styled';
import { useTranslation } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { fieldsSettingsListSelector } from '@store/selectors';
import { getFieldSettingsList } from '@store/actions/creators';
import { SearchSelect, Select } from '@components';
import { by } from '@utils';
import { Tooltip } from 'antd';
import {Error} from "@components/inputs/Select/styled";

export const FACEBOOK_FIELDS = [
  { value: 'EMAIL', label: 'E-mail' },
  { value: 'PHONE', label: 'Phone' },
  { value: 'GEN', label: 'Gender' },
  { value: 'DOBY', label: 'Year of birth' },
  { value: 'DOBM', label: 'Month of birth' },
  { value: 'DOB', label: 'Date of birth' },
  { value: 'DOBD', label: 'Day of birth' },
  { value: 'LN', label: 'Last name' },
  { value: 'FN', label: 'First name' },
  { value: 'FI', label: 'FI' },
  { value: 'CT', label: 'City' },
  { value: 'ST', label: 'State/Province' },
  { value: 'ZIP', label: 'ZIP code' },
  { value: 'COUNTRY', label: 'Country' },
  { value: 'MADID', label: 'Mobile Advertiser ID' },
];

const FieldsMappingEditor = ({ error, value = [], onChange }) => {
  const { t, p, e } = useTranslation('integrations_page');
  const fieldSettings = useSelector(fieldsSettingsListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFieldSettingsList());
  }, [])

  const fieldOptions = (fieldSettings?.data || []).filter(({ type }) => type !== 'array').map(({ field, label }) => ({ label, value: field }));

  const handleFieldChange = (index) => (field) => {
    onChange(fm => (fm || []).map((m, i) => index !== i ? m : {
      ...m,
      field,
    }));
  };

  const handleMapToChange = (index) => (mapTo) => {
    onChange(fm => (fm || []).map((m, i) => index !== i ? m : {
      ...m,
      mapTo,
    }));
  };

  const filterOptions = ({ value: v }) => !~(value || []).findIndex(by('mapTo', v));
  const facebookOptions = FACEBOOK_FIELDS.filter(filterOptions);

  const handleAddNewField = () => {
    if (!facebookOptions.length) {
      return;
    }

    onChange(fm => ([...fm, { field: '', mapTo: '' }]));
  };

  const handleDeleteRow = (index) => () => {
    onChange(fm => fm.filter((_, i) => i !== index));
  }

  return (
    <Container>
      {!!value.length && (
        <Labels>
          <Label style={{ marginRight: 10 }}>{p('fields_from_facebook')}</Label>
          <Label style={{ marginLeft: 10 }}>{p('your_fields')}</Label>
        </Labels>
      )}
      {value.map(({ field, mapTo }, index, self) => (
        <FieldRow>
          <SearchSelect
            getPopupContainer={() => document.body}
            placeholder={t('actions.choose_field')}
            value={mapTo}
            wrapperStyles={{ marginRight: 10, width: '100%' }}
            containerStyle={{ width: '100%' }}
            style={{ width: '100%' }}
            onChange={handleMapToChange(index)}
            onClear={() => handleMapToChange(index)('')}
            filterOptions={filterOptions}
            options={FACEBOOK_FIELDS}
          />
          <RowItem style={{ marginLeft: 10 }}>
            <SearchSelect
              getPopupContainer={() => document.body}
              placeholder={t('actions.choose_field')}
              value={field}
              wrapperStyles={{ width: '100%' }}
              containerStyle={{ width: '100%' }}
              style={{ width: '100%' }}
              onChange={handleFieldChange(index)}
              onClear={() => handleFieldChange(index)('')}
              options={fieldOptions}
            />
            {index > 0 && (
              <Tooltip title={t('actions.delete')}>
                <DeleteIcon onClick={handleDeleteRow(index)}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5706 8.99755C14.5717 9.35048 14.3215 9.63718 14.0127 9.6369L9.5554 9.6329L8.44398 9.6329L3.98649 9.6369C3.67747 9.63718 3.42684 9.35096 3.42684 8.9978C3.42684 8.64464 3.67747 8.35843 3.98649 8.35871L8.44398 8.36271L9.5554 8.36271H14.0131C14.3202 8.36271 14.5695 8.64658 14.5706 8.99755Z" fill="#C8402F"/>
                    <circle cx="9" cy="9" r="8.35" stroke="#C8402F" strokeWidth="1.3"/>
                  </svg>
                </DeleteIcon>
              </Tooltip>
            )}
          </RowItem>
        </FieldRow>
      ))}
      <AddFieldButton
        $disabled={!facebookOptions.length}
        onClick={handleAddNewField}
      >
        <svg style={{ marginRight: 8 }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.375 8H9V5.625C9 5.55625 8.94375 5.5 8.875 5.5H8.125C8.05625 5.5 8 5.55625 8 5.625V8H5.625C5.55625 8 5.5 8.05625 5.5 8.125V8.875C5.5 8.94375 5.55625 9 5.625 9H8V11.375C8 11.4437 8.05625 11.5 8.125 11.5H8.875C8.94375 11.5 9 11.4437 9 11.375V9H11.375C11.4437 9 11.5 8.94375 11.5 8.875V8.125C11.5 8.05625 11.4437 8 11.375 8Z" fill="#109DFB"/>
          <path d="M8.5 1.5C4.63438 1.5 1.5 4.63438 1.5 8.5C1.5 12.3656 4.63438 15.5 8.5 15.5C12.3656 15.5 15.5 12.3656 15.5 8.5C15.5 4.63438 12.3656 1.5 8.5 1.5ZM8.5 14.3125C5.29063 14.3125 2.6875 11.7094 2.6875 8.5C2.6875 5.29063 5.29063 2.6875 8.5 2.6875C11.7094 2.6875 14.3125 5.29063 14.3125 8.5C14.3125 11.7094 11.7094 14.3125 8.5 14.3125Z" fill="#109DFB"/>
        </svg>
        {t('actions.add_new_field')}
      </AddFieldButton>
      {error && <Error>{e(error)}</Error>}
    </Container>
  );
}

export default FieldsMappingEditor;
