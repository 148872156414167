import React from 'react';

import { Container } from './styled';
import { TableLoader } from '@components';

const BaseActivityLoader = ({ active, ...props }) => {
  if (!active) {
    return null;
  }

  return (
    <Container $a={active}>
      <TableLoader {...props} />
    </Container>
  );
};

export default React.memo(BaseActivityLoader);
