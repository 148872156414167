import React, { useState } from 'react';

import { Container, RangeContainer, EditButton } from './styled';
import { TimePicker } from './components';
import { useTranslation } from '@hooks';

const formatDate = (date) => {
  return date.format('ddd, DD-MM-YY [at]')
}

const DateTimeDisplay = ({ range, smaller, onChange, disabled }) => {
  const { p, t } = useTranslation('date_picker')
  const [active, setActive] = useState(false);

  const handleRightChange = (right) => {
    onChange([range[0], right])
  };

  const handleLeftChange = (left) => {
    onChange([left, range[1]])
  };

  return (
    <Container $smaller={smaller} style={{ justifyContent: disabled || smaller ? 'center' : 'space-between' }}>
      <RangeContainer $smaller={smaller}>
        {formatDate(range[0])}
        <TimePicker format="HH:mm" smaller={smaller} disabled={disabled} value={range[0]} active={active} onChange={handleLeftChange} />
        {' - '}
        {formatDate(range[1])}
        <TimePicker format="HH:mm" smaller={smaller} disabled={disabled} value={range[1]} onChange={handleRightChange} />
      </RangeContainer>
      {!disabled && (
        <EditButton onClick={() => setActive(a => !a)}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 6 }}>
            <g clipPath="url(#clip0_9808_28928)">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.2002 8.63744L7.2002 4.7524C7.2002 4.60916 7.25392 4.46971 7.35276 4.36522C7.45195 4.26036 7.58894 4.19922 7.73429 4.19922C7.87964 4.19922 8.01663 4.26036 8.11583 4.36522C8.21466 4.46971 8.26839 4.60916 8.26839 4.7524V8.31975L10.3462 9.57494C10.4661 9.65135 10.5499 9.77226 10.5836 9.90937C10.6173 10.0463 10.5993 10.1921 10.5321 10.3164C10.4648 10.4409 10.3522 10.5359 10.2156 10.5771C10.0786 10.6185 9.93198 10.6012 9.80804 10.5304L9.80381 10.528L7.4652 9.11521C7.38303 9.0656 7.3161 8.99475 7.27007 8.91096C7.22408 8.82722 7.20022 8.73286 7.2002 8.63744Z" fill="#109DFB"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M13.091 13.092C11.7407 14.4422 9.90936 15.2008 7.9998 15.2008C6.09025 15.2008 4.2589 14.4422 2.90864 13.092C1.55837 11.7417 0.799805 9.91034 0.799805 8.00078C0.799805 6.09122 1.55837 4.25987 2.90864 2.90961C4.2589 1.55935 6.09025 0.800781 7.9998 0.800781C9.90936 0.800781 11.7407 1.55935 13.091 2.90961C14.4412 4.25987 15.1998 6.09122 15.1998 8.00078C15.1998 9.91034 14.4412 11.7417 13.091 13.092ZM12.1894 12.1904C13.3006 11.0792 13.9248 9.57219 13.9248 8.00078C13.9248 6.42937 13.3006 4.92233 12.1894 3.81117C11.0783 2.70002 9.57121 2.07578 7.9998 2.07578C6.4284 2.07578 4.92135 2.70002 3.8102 3.81117C2.69904 4.92233 2.0748 6.42937 2.0748 8.00078C2.0748 9.57219 2.69904 11.0792 3.8102 12.1904C4.92135 13.3015 6.4284 13.9258 7.9998 13.9258C9.57121 13.9258 11.0783 13.3015 12.1894 12.1904Z" fill="#109DFB"/>
            </g>
            <defs>
              <clipPath id="clip0_9808_28928">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          {p('select_time')}
        </EditButton>
      )}
    </Container>
  )
};

export default DateTimeDisplay;
