import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const FilterContainer = styled.div`
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 4px 0 0 0;
  background: #FAFAFA;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DeleteRuleButton = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #C8402F;
  display: flex;
  width: auto;
  flex-shrink: 1;
  flex-grow: 0;
  justify-content: center;
  padding: 14px;
  user-select: none;
  cursor: pointer;
`;

export const AddAttributeButton = styled.div`
  font-family: Rubik-Regular;
  padding: 8px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #F9FBFF;
  border: 1px solid #C1CEFC;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #109DFB;
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-top: 5px;
  margin-bottom: 12px;
  line-height: 120%;
  color: #C8402F;
`;
