import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateStatistics } from '@store/actions/creators';
import { Button, Modal } from '@components';
import { templatesStatisticsSelector } from '@store/selectors';

import { Container, Row, TotalText, Text, Header, HeaderText } from './styled.js';
import { zeroIfNaN } from '@utils';
import { useTranslation } from '@hooks';
import { EmailStatuses } from '@constants';

const EmailStatisticsModal = ({ id, opened, onClose }) => {
  const { p, t } = useTranslation('templates');
  const dispatch = useDispatch();
  const statistics = useSelector(templatesStatisticsSelector);
  const s = statistics?.['emails'] || {};
  const summary = Object.fromEntries(Object.values(EmailStatuses).map(status => [status, s[status] || 0]).concat([['total', s.total || 0]]));

  useEffect(() => {
    if (!id || !opened) {
      return;
    }

    dispatch(getTemplateStatistics({ template_id: [id], type: 'emails' }));
  }, [id, opened]);

  const calcPercentage = (value, from) => {
    return from ? zeroIfNaN(value / from * 100).toFixed(2) : '0.00';
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      style={{ maxWidth: '360px' }}
      title={p('template_statistics')}
      statistics={true}
      actions={(
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button onClick={onClose} appearance="secondary" width={140}>{t('actions.close')}</Button>
        </div>
      )}
    >
      <Container>
        {(summary?.total !== undefined && summary?.total !== null) && (
          <Row>
            <TotalText>{t('labels.total')}</TotalText>
            <TotalText>{summary?.total}</TotalText>
          </Row>
        )}
        {((summary?.failed !== undefined && summary?.failed !== null) || (summary?.delivered !== undefined && summary?.delivered !== null)) && (
          <Header>
            <HeaderText>{t('labels.status')}</HeaderText>
            <HeaderText>% {p('from_total')}</HeaderText>
            <HeaderText right>{p('amount')}</HeaderText>
          </Header>
        )}
        {summary?.sent !== undefined && summary?.sent !== null && (
          <Row>
            <Text>{t('statuses.sent')}</Text>
            <Text>{calcPercentage(summary?.sent, summary?.total)}</Text>
            <Text right>{summary?.sent}</Text>
          </Row>
        )}
        {summary?.failed !== undefined && summary?.failed !== null && (
          <Row>
            <Text>{p('failed')}</Text>
            <Text>{calcPercentage(summary?.failed, summary?.total)}</Text>
            <Text right>{summary?.failed}</Text>
          </Row>
        )}
        {summary?.delivered !== undefined && summary?.delivered !== null && (
          <Row>
            <Text>{p('delivered')}</Text>
            <Text>{calcPercentage(summary?.delivered, summary?.total)}</Text>
            <Text right>{summary?.delivered}</Text>
          </Row>
        )}
        {/*{(summary?.open !== undefined && summary?.open !== null) && (*/}
        {/*  <Header>*/}
        {/*    <HeaderText>{t('labels.status')}</HeaderText>*/}
        {/*    <HeaderText>% {p('from_delivered')}</HeaderText>*/}
        {/*    <HeaderText right>{p('amount')}</HeaderText>*/}
        {/*  </Header>*/}
        {/*)}*/}
        {summary?.open !== undefined && summary?.open !== null && (
          <Row>
            <Text>{p('opened')}</Text>
            <Text>{calcPercentage(summary?.open, summary?.total)}</Text>
            <Text right>{summary?.open}</Text>
          </Row>
        )}
        {/*{((summary?.click !== undefined && summary?.click !== null) || (summary?.spam_report !== undefined && summary?.spam_report !== null) || (summary?.unsubscribed !== undefined && summary?.unsubscribed !== null) || (summary?.resubscribed !== undefined && summary?.resubscribed !== null)) && (*/}
        {/*  <Header>*/}
        {/*    <HeaderText>{t('labels.status')}</HeaderText>*/}
        {/*    <HeaderText>% {p('from_opened')}</HeaderText>*/}
        {/*    <HeaderText right>{p('amount')}</HeaderText>*/}
        {/*  </Header>*/}
        {/*)}*/}
        {summary?.click !== undefined && summary?.click !== null && (
          <Row>
            <Text>{p('click')}</Text>
            <Text>{calcPercentage(summary?.click, summary?.total)}</Text>
            <Text right>{summary?.click}</Text>
          </Row>
        )}
        {summary?.spam_report !== undefined && summary?.spam_report !== null && (
          <Row>
            <Text>{p('spam_report')}</Text>
            <Text>{calcPercentage(summary?.spam_report, summary?.total)}</Text>
            <Text right>{summary?.spam_report}</Text>
          </Row>
        )}
        {summary?.unsubscribed !== undefined && summary?.unsubscribed !== null && (
          <Row>
            <Text>{p('unsubscribed')}</Text>
            <Text>{calcPercentage(summary?.unsubscribed, summary?.total)}</Text>
            <Text right>{summary?.unsubscribed}</Text>
          </Row>
        )}
        {summary?.resubscribed !== undefined && summary?.resubscribed !== null && (
          <Row>
            <Text>{p('resubscribed')}</Text>
            <Text>{calcPercentage(summary?.resubscribed, summary?.total)}</Text>
            <Text right>{summary?.resubscribed}</Text>
          </Row>
        )}
      </Container>
    </Modal>
  );
}

export default EmailStatisticsModal;


