import React from 'react';
import { TextIcon, TextIconContainer, TextOverlay } from '../../styled';
import { useModalState } from '@hooks';
import { Dropdown } from 'antd';

const TextDetails = ({ text }) => {
  const textModal = useModalState(false);

  return (
     <Dropdown
       trigger={['hover']}
       onOpenChange={textModal.toggle}
       // placement="bottomCenter"
       overlay={(
         <TextOverlay>
           {text}
         </TextOverlay>
       )}
     >
       <TextIconContainer>
         <TextIcon />
         <TextIcon />
         <TextIcon />
       </TextIconContainer>
     </Dropdown>
  );
}

export default TextDetails;
