import React from 'react';

import { Col, Link, StyledPopover } from './styled';
import { useTranslation } from '@hooks';
import { WithPermissions, Switch } from '@components';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { sentOnboardParams } from '@store/actions/creators';
import { removeLeadingSlash } from '@utils';

const Popover = ({ title, pages, children, overlayClassName, larger, isSettings, setHovered = () => {}, hintMode, setHintMode }) => {
  const { p } = useTranslation('sidebar');
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChangeHintMode = () => {
    setHintMode(!hintMode);
    dispatch(sentOnboardParams({ enabled: !hintMode }));
  }

  return (
    <StyledPopover onVisibleChange={setHovered} overlayClassName={overlayClassName} placement="rightBottom" content={(
      <Col isSettings={isSettings}>
        {pages.map(({ name, path, permission }, index) => permission ? (
          <WithPermissions key={path} name={permission}>
            <Link data-testid={path} active={location.pathname === path} isSettings={isSettings} isFirst={index === 0} isLast={index === pages.length - 1} larger={larger} white={overlayClassName === 'stt'} key={path} to={removeLeadingSlash(path)}>
              {p(name)}
            </Link>
          </WithPermissions>
        ) : (
          <Link data-testid={path} active={location.pathname === path} isSettings={isSettings} isFirst={index === 0} isLast={index === pages.length - 1} larger={larger} white={overlayClassName === 'stt'} key={path} to={removeLeadingSlash(path)}>
            {p(name)}
          </Link>
        ))}
        {/*<AccordionRow accordionOpened={opened}>*/}
        {isSettings && (
          <div style={{ display: 'flex', padding: '10px 12px' }}>
            <div>Onboarding Assistant</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={hintMode === true}
                defaultChecked={hintMode}
                testId={`sidebar-onboarding-toggle`}
                onChange={handleChangeHintMode}
                style={{ backgroundColor: hintMode ? '#19B645' : '#CCC', marginLeft: '15px', borderRadius: '9px', width: '26px', height: '14px' }}
              />
            </div>
          </div>
        )}
        {/*</AccordionRow>*/}
      </Col>
    )}>
      {children}
    </StyledPopover>
  );
};

export default Popover;
