import React from 'react';
import { useModalState } from '@hooks';
import { Link } from '@components';

import { Container } from './styled';
import { EventDetailsModal } from '../EventDetailsModal';
import { Tooltip } from 'antd';
import { resolveEventName } from '@utils/events';

const NameCell = ({ name, payload, dataTestId = '' }) => {
  const detailsModal = useModalState(false);

  const renderName = () => {
    if (!payload) {
      return <Container data-testid={dataTestId}>{resolveEventName(name)}</Container>;
    }

    return (
      <Container data-testid={dataTestId}>
        <Link onClick={detailsModal.open}>{name}</Link>
        <EventDetailsModal
          payload={payload}
          name={resolveEventName(name)}
          opened={detailsModal.opened}
          onClose={detailsModal.close}
        />
      </Container>
    );
  }

  if (name?.length > 20) {
    return (
      <Tooltip data-testid={dataTestId} title={resolveEventName(name)}>
        {renderName()}
      </Tooltip>
    );
  }

  return renderName();
};

export default NameCell;
