import React, {useState} from "react";

import {expressionForTypeSelected} from '@store/actions/creators';
import {useDispatch} from "react-redux";

const EXPRESSION_FOR_LINEAR = 'linear';
const EXPRESSION_FOR_ARRAY = 'array';
const EXPRESSION_FOR_EVENT = 'event';
const EXPRESSION_FORS = [EXPRESSION_FOR_LINEAR, EXPRESSION_FOR_ARRAY, EXPRESSION_FOR_EVENT]

const ForType = (props) => {

    const dispatch = useDispatch();

    return (
        <select name="" value={props?.expression?.for?.type} onChange={(e) => {dispatch(expressionForTypeSelected({value: e.target.value})); e.stopPropagation();}}>
            {EXPRESSION_FORS.map(expFor => (<option value={expFor}>{expFor}</option>))}
        </select>)

};

export default ForType;
