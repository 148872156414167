import styled, { css } from 'styled-components';
import { extract, styledProp } from '@utils';

export const Container = styled.div`
  position: absolute;
  bottom: 50px;
  right: 10px;
  width: 258px;
  background: #202020;
  backdrop-filter: blur(23.861px);
`;

export const Image = styled.div`
  background: url(${extract('image')}) center / cover no-repeat;
  width: 100%;
  height: 124px;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 20px;
  right: 13px;
  height: 20px;
  transition: opacity 0.1s ease 0s;

  ${styledProp('show', css`
    opacity: 1;
  `, css`
    opacity: 0;
  `)}
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 14px 10px 16px 10px;
`;

export const TextCol = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 14px 10px;
`;

export const Button = styled.div`
  background: #434343;
  border: 1px solid #888888;
  display: flex;
  justify-content: center;
  width: 114px;
  font-size: 12px;
  line-height: 120%;
  color: #FBFBFB;
  font-family: unquote("Segoe UI");
  padding: 7px 0;
  cursor: pointer;
`;

export const Company = styled.div`
  color: #A4A4A4;
  font-family: unquote("Segoe UI");
  font-size: 11px;
`;

export const Text = styled.div`
  color: #A4A4A4;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: unquote("Segoe UI");
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
`;

export const Title = styled.div`
  color: #fff;
  font-family: unquote("Segoe UI");
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`;

export const CompanyIcon = styled.div`
  background: url(${extract('image')}) center center no-repeat;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  height: 38px;
  margin-right: 10px;
  margin-top: 10px;
`;
