import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const ViewModeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 24px;
  padding: 0px;
  border: none;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
  ${styledProp('active', css`
    background-color: #DCDFE6;
  `, css`
    background-color: transparent;
  `)}
`;
