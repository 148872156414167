import NotificationsActionTypes from '../actions/types/notifications';

const initialState = {
  notifications: {
    data: [],
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case NotificationsActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: [
            ...state.notifications.data,
            { ...action.payload.notification },
          ]
        },
      };
    case NotificationsActionTypes.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
