import React, {useMemo} from 'react';
import {GettingStarted} from "../GettingStarted";
import {
  contactsLargeSVG,
  contactsSmallSVG,
  integrationsLargeSVG,
  integrationsSmallSVG,
  segmentsLargeSVG,
  segmentsSmallSVG,
  templatesLargeSVG,
  templatesSmallSVG,
  workflowLargeSVG,
  workflowSmallSVG
} from "@res/images/getting-started";
import {Paths} from "@constants";
import {getUser, updateAppOnboarding, updateOnboarding} from "@store/actions/creators";
import {RioService} from "../../../../../../services";
import {useModalState, useTranslation} from "@hooks";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router";
import {
  hintModeSelector, integrationsSelector,
  segmentsSelector, templateApiRequestSelector,
  templateEmailsSelector, templateMobilePushSelector,
  templateSmsSelector,
  templateViberSelector, templateWebpushSelector, userSelector, workflowListSelector
} from "@store/selectors";
import { SkipModalText, SkipModalTitle } from "../../styled";
import { ConfirmationModal } from "@components";
import { dashboardDatabaseEfficiencySelector } from "@store/selectors/dashboard";

const FakeIntegrations = [
  "fake_sms",
  "test_sms",
  "fake_emails",
  "test_emails",
  "fake_webpushes",
  "fake_mobile_pushes",
  "fake_viber",
  "test_webpushes",
  "retainly_webpushes"
];

const OnboardingSection = () => {
  const { p, t, i18n } = useTranslation('dashboard_page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const smsTemplates = useSelector(templateSmsSelector);
  const viberTemplates = useSelector(templateViberSelector);
  const emailTemplates = useSelector(templateEmailsSelector);
  const webpushes = useSelector(templateWebpushSelector);
  const mobilePushes = useSelector(templateMobilePushSelector);
  const apiRequests = useSelector(templateApiRequestSelector);
  const segments = useSelector(segmentsSelector);
  const hintMode = useSelector(hintModeSelector);
  const user = useSelector(userSelector);
  const integrations = useSelector(integrationsSelector);
  const workflows = useSelector(workflowListSelector);
  const databaseEfficiency = useSelector(dashboardDatabaseEfficiencySelector);
  const skipIntegrationModal = useModalState(false);

  const skipIntegrations = user?.app?.on_boarding?.integrationsSkipped && !integrations.data?.filter(i => ![...FakeIntegrations, 'javascript', 'retainly_webpushes'].includes(i.integration_key)).length;

  const renderContacts = useMemo(() => {
    return !!databaseEfficiency?.total;
    // return !!user?.has_customers;
  }, [databaseEfficiency?.total]);

  const handleCreateEmailTemplateClick = (fromLastTemplates) => {
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
      dispatch(updateOnboarding('templates', {
        step: 1,
      }));
    } else {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
    }
    RioService.track('templates_dash');
  };

  const handleSegmentsCreateClick = (fromLastTemplates) => {
    RioService.track('segments_dash');
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(`${Paths.RESOURCES_SEGMENTS}/new`);
      dispatch(updateOnboarding('segments', {
        step: 2,
      }));
    } else {
      navigate(`${Paths.RESOURCES_SEGMENTS}/new`);
    }
  }

  const handleCreateWorkflowClick = (fromLastTemplates) => {
    RioService.track('workflow_dash');
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(Paths.CREATE_WORKFLOW);
      dispatch(updateOnboarding('workflows', {
        step: 2,
      }));
    } else {
      navigate(Paths.CREATE_WORKFLOW);
    }
  };

  const handleNavigateToContacts = () => {
    RioService.track('contacts_csv_dash');
    if (hintMode) {
      navigate(`${Paths.CONTACTS_IMPORT}/import`);
      dispatch(updateOnboarding('contactsImport', {
        step: 1,
      }));
    } else {
      navigate(`${Paths.CONTACTS_IMPORT}/import`);
    }
  };

  const handleNavigateToIntegrations = () => {
    RioService.track('integrations_dash');
    if (hintMode) {
      navigate(Paths.INTEGRATIONS);
      dispatch(updateOnboarding('integrations', {
        step: 1,
      }));
    } else {
      navigate(Paths.INTEGRATIONS);
    }
  };

  const handleSkipIntegrationsStep = () => {
    dispatch(updateAppOnboarding({
      id: user.app.id,
      params: { integrationsSkipped: true, ...(user?.app?.on_boarding || {}) },
      onSuccess: () => {
        dispatch(getUser());
      },
    }))
  };

  // const renderContacts = !!user?.has_customers;
  const renderTemplates = !!emailTemplates?.meta?.total || !!viberTemplates?.meta?.total || !!smsTemplates?.meta?.total || !!webpushes?.meta?.total || mobilePushes?.meta?.total || apiRequests?.meta?.total || user?.app?.on_boarding?.templateCreated;
  const renderIntegrations = user?.app?.on_boarding?.integrationsSkipped || skipIntegrations || !!integrations.data?.filter(i => ![...FakeIntegrations, 'javascript', 'retainly_webpushes'].includes(i.integration_key)).length;

  const renderWorkflows = !!workflows?.meta?.total || user?.app?.on_boarding?.workflowCreated;
  const renderSegments = !!segments?.meta?.total || user?.app?.on_boarding?.segmentCreated;

  const getCreatedEmails = () => {
    let templatesCount = emailTemplates?.meta?.total;

    if (!!smsTemplates?.meta?.total) {
      templatesCount += smsTemplates?.meta?.total;
    }

    if (!!apiRequests?.meta?.total) {
      templatesCount += apiRequests?.meta?.total;
    }

    if (!!webpushes?.meta?.total) {
      templatesCount += webpushes?.meta?.total;
    }

    if (!!mobilePushes?.meta?.total) {
      templatesCount += mobilePushes?.meta?.total;
    }

    if (!!viberTemplates?.meta?.total) {
      templatesCount += viberTemplates?.meta?.total;
    }

    return templatesCount || 0;
  }

  const getStartedStep = () => {
    if (renderWorkflows) return 'done';
    if (!renderContacts) return 'contacts';
    if (!renderSegments) return 'segments';
    if (!renderIntegrations) return 'integrations';
    if (!renderTemplates) return 'templates';
    if (!renderWorkflows) return 'workflows';
    return 'done';
  }

  const startedStep = getStartedStep();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const GettingStartedBlocks = useMemo(() => ({
    contacts: {
      key: 'contacts',
      priority: 0,
      image: {
        large: contactsLargeSVG,
        small: contactsSmallSVG,
      },
      title: renderContacts ? databaseEfficiency?.total : p('add_base'),
      action: p('add_customers'),
      exist: renderContacts,
      text: !renderContacts && startedStep !== 'contacts' ? '' : (renderContacts ? p('customers_you_added') : p('scv_import_description')),
      onAction: handleNavigateToContacts,
    },
    integrations: {
      key: 'integrations',
      priority: 2,
      image: {
        large: integrationsLargeSVG,
        small: integrationsSmallSVG,
      },
      tooltip: p('setup_templates'),
      title: (renderIntegrations && !skipIntegrations) ? integrations.data?.filter(i => ![...FakeIntegrations, 'javascript', 'retainly_webpushes'].includes(i.integration_key)).length : p('setup_channels'),
      action: p('set_up_now'),
      exist: renderIntegrations,
      text: (!renderIntegrations && startedStep !== 'integrations') || skipIntegrations ? '' : (renderIntegrations ? p('integrations_you_activated') : p('message_channels_description')),
      onAction: handleNavigateToIntegrations,
    },
    templates: {
      key: 'templates',
      priority: 3,
      image: {
        large: templatesLargeSVG,
        small: templatesSmallSVG,
      },
      tooltip: p('setup_integrations'),
      title: renderTemplates ? getCreatedEmails() : p('create_templates'),
      action: p('create_template'),
      exist: renderTemplates,
      text: !renderTemplates && startedStep !== 'templates' ? '' : (renderTemplates ? p('templates_you_created') : p('templates_description')),
      onAction: handleCreateEmailTemplateClick,
    },
    segments: {
      key: 'segments',
      priority: 1,
      image: {
        large: segmentsLargeSVG,
        small: segmentsSmallSVG,
      },
      title: renderSegments ? segments?.meta?.total : p('create_first_segment'),
      tooltip: p('should_create_segments'),
      action: p('create_segment'),
      exist: renderSegments,
      text: !renderSegments && startedStep !== 'segments' ? '' : (renderSegments ? p('segments_you_created') : p('segments_description')),
      onAction: handleSegmentsCreateClick,
    },
    workflows: {
      key: 'workflows',
      priority: 4,
      image: {
        large: workflowLargeSVG,
        small: workflowSmallSVG,
      },
      title: renderWorkflows ? workflows?.meta?.total : p('build_workflow'),
      tooltip: p('workflow_tooltip'),
      action: p('create_workflow'),
      exist: renderWorkflows,
      text: !renderWorkflows && startedStep !== 'workflows' ? '' : (renderWorkflows ? p('workflows_you_created') : p('workflow_description')),
      onAction: handleCreateWorkflowClick,
    },
  }), [handleSegmentsCreateClick, navigate, hintMode]);

  return (
   <>
     <GettingStarted
       blocks={GettingStartedBlocks}
       skipIntegrations={skipIntegrations}
       onSkip={skipIntegrationModal.open}
       step={startedStep}
     />
     <ConfirmationModal
       opened={skipIntegrationModal.opened}
       onClose={skipIntegrationModal.close}
       title={p('skip_channels')}
       appearance="default"
       text={p('edit_aggregate_description')}
       content={(
         <div>
           <SkipModalTitle>{p('skip_channels_description_line_1')}</SkipModalTitle>
           <SkipModalText>{p('skip_channels_description_line_2')}</SkipModalText>
         </div>
       )}
       onCancel={skipIntegrationModal.close}
       onSubmitClick={handleSkipIntegrationsStep}
       buttonsParams={{
         cancelAppearance: 'secondary',
         submitAppearance: 'default',
         cancelLabel: t('actions.cancel'),
         submitLabel: t('actions.setup_later'),
       }}
     />
   </>
  )
}

export default React.memo(OnboardingSection);
