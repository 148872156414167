import React from 'react';
import { by, testId } from '@utils';
import { useTranslation } from '@hooks';
import { OperatorSelect } from '@components';

import { DeleteButton } from '../DeleteButton';
import { HiddenFilter } from './components';
import { Container, Title, Content, SimplifiedTitle } from './styled';

const EventFilter = ({ value, event, onChange, onDelete, index, className, light, isSimplified, isHidden, isSystem, editable, errors, onClearError }) => {
  const { p } = useTranslation('segments_page');
  const fieldOptions = event?.payload?.map?.(({ field, label, type }) => ({ value: field, label: label, type })) || [];
  const fieldTitle = fieldOptions.find(by('value', value.field))?.label;

  const handleDelete = () => {
    onDelete(index);
  };

  const handleValueChange = (value) => {
    onClearError('filter_builder');
    onChange(s => ({ ...s, value: { type: 'scalar', value } }));
  };

  const handleOperatorChange = (operator) => {
    onClearError('filter_builder');
    onChange(s => ({
      ...s,
      operator,
      value: { type: 'scalar', value: '' },
    }));
  };

  if (isHidden) {
    return (
      <HiddenFilter
        title={fieldTitle}
        value={value}
        fieldOptions={fieldOptions}
        autocomplete={{
          fields: [value.field],
          event: event.name
        }}
      />
    );
  }

  return (
    <Container $s={isSimplified} $l={light}>
      {!isSimplified && (
        <Title>
          {fieldTitle}
        </Title>
      )}
      <Content>
        {isSimplified && (
          <SimplifiedTitle>
            {p('filtering_by', { by: (fieldTitle || '') })}
          </SimplifiedTitle>
        )}
          <OperatorSelect
            {...testId('event-node-settings-required-filter-operator')()}
            appearance="row"
            fullWidth
            hideOperator={isSimplified}
            disabled={!editable}
            className={className}
            wrapperStyle={{ width: '100%' }}
            initialType={value.type}
            type={value.type}
            value={{
              operator: value.operator,
              value: value.value?.value || '',
              type: value.type,
              errors: { value: errors('filter_builder')?.[index]?.value },
            }}
            onValueChange={handleValueChange}
            onOperatorChange={handleOperatorChange}
            field={value.field}
            autocomplete={value.type === 'numeric' ? undefined : {
              fields: [value.field],
              event: event.name
            }}
          />
        <DeleteButton disabled={!editable} style={{ marginLeft: 10 }} onClick={handleDelete} />
      </Content>
    </Container>
  );
};

export default EventFilter;
