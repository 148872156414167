import React from 'react';

import { Container } from './styled';
import { NodeSettings } from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components';

const NodeDiff = ({ sourceLabel, originLabel, source, origin, sourceDescription, originDescription, ...props }) => {

  return (
    <Container>
      <NodeSettings
        errors={{}}
        type={source.name}
        value={source}
        editable={false}
        compareWithValue={origin}
        label={sourceLabel}
        description={sourceDescription}
        {...props}
      />
      {(Object.keys(origin).length > 0 &&  source.diff?.type !== 'not-changed' && source.diff?.type !== 'created') && <div style={{ width: '30px', height: '100%' }}/>}
      <NodeSettings
        errors={{}}
        type={origin.name}
        value={origin}
        editable={false}
        compareWithValue={source}
        label={originLabel}
        description={originDescription}
        {...props}
      />
    </Container>
  )
};

export default NodeDiff;
