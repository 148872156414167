export const isRequired = v => (!String(v).length || v === undefined) && 'field_is_required';

export const isValidUrl = v => !new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i').test(v) && 'invalid_url';

export const min = m => v => v < m && `greater_than_${m}`;
export const max = m => v => v > m && `less_than_${m}`;

export const maxLength = (n, message) => s => s.length > n && message;

export const minLength = (n, message) => s => s.length < n && message;

export const oneOf = (...xs) => v => !!~xs.indexOf(v);

export const fullPercentage = vs => vs.reduce((acc, { value }) => acc + Number(value), 0) !== 100 && 'sum_segments';
