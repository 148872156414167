import React from 'react';

import { Container } from './styled';
import { IntegrationPresets, Paths } from '@constants';
import { useNavigate } from '@hooks';

const FacebookIntegrationDisclaimer = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${Paths.INTEGRATIONS}/${IntegrationPresets.FACEBOOK_ADS}`)
  };

  return (
    <Container>
      <svg style={{ marginRight: 8 }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.98336 1.51552C8.84914 1.23601 8.63868 1.00011 8.37622 0.834993C8.11377 0.669879 7.81001 0.582275 7.49994 0.582275C7.18987 0.582275 6.88611 0.669879 6.62366 0.834993C6.36121 1.00011 6.15075 1.23601 6.01653 1.51552L1.28219 10.8284C0.715777 11.9403 1.43153 13.4167 2.76503 13.4167H12.2343C13.5684 13.4167 14.2829 11.9409 13.7177 10.8284L8.98336 1.51552ZM7.49994 4.66669C7.65465 4.66669 7.80303 4.72815 7.91242 4.83755C8.02182 4.94694 8.08328 5.09531 8.08328 5.25002V8.16669C8.08328 8.3214 8.02182 8.46977 7.91242 8.57917C7.80303 8.68857 7.65465 8.75002 7.49994 8.75002C7.34523 8.75002 7.19686 8.68857 7.08746 8.57917C6.97807 8.46977 6.91661 8.3214 6.91661 8.16669V5.25002C6.91661 5.09531 6.97807 4.94694 7.08746 4.83755C7.19686 4.72815 7.34523 4.66669 7.49994 4.66669ZM7.49994 9.62502C7.65465 9.62502 7.80303 9.68648 7.91242 9.79588C8.02182 9.90527 8.08328 10.0536 8.08328 10.2084V10.5C8.08328 10.6547 8.02182 10.8031 7.91242 10.9125C7.80303 11.0219 7.65465 11.0834 7.49994 11.0834C7.34523 11.0834 7.19686 11.0219 7.08746 10.9125C6.97807 10.8031 6.91661 10.6547 6.91661 10.5V10.2084C6.91661 10.0536 6.97807 9.90527 7.08746 9.79588C7.19686 9.68648 7.34523 9.62502 7.49994 9.62502Z" fill="#FFBB00"/>
      </svg>
      Please refresh your Facebook login to ensure uninterrupted access to Retainly's Facebook integration features.&nbsp;<span onClick={handleNavigate}>Facebook integration</span>
    </Container>
  );
};

export default FacebookIntegrationDisclaimer;
