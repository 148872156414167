import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppRedirects, useErrors, useModalState, useTable } from '@hooks';
import {
  Button,
  DateCell,
  DatePicker,
  Header,
  HeaderPagination,
  Icon, IconButton,
  Input,
  Multiselect,
  SortableTitle,
  Table,
  UserSelect, WithPermissions, SegmentStatus
} from '@components';
import { getPromoCodes, savePromoCode } from '@store/actions/creators';
import { promoCodesLoadingSelector, promoCodesSelector } from '@store/selectors';
import { identity, testId } from '@utils';

import { EditPromoCodeModal, TableActions, TitleCell } from './components';
import {
  Container,
  FilterActions,
  SearchFilter,
  DateFilter,
  DateInputsRow,
  FiltersCol,
  FiltersRow,
  Example,
} from './styled';
import { moment } from '@utils';
import { AppRedirects, UserPermissions } from '@constants';
import { Link } from '@components';
import { Paths } from '@constants';
import { useTranslation } from '@hooks';
import { StringParam, useQueryParam } from 'use-query-params';

const typeOptions = [
  {
    value: "2",
    label: 'Static',
  },
  {
    value: "1",
    label: 'Dynamic',
  },
];

const statusOptions = [
  {
    value: 0,
    label: 'Draft',
  },
  {
    value: 1,
    label: 'Used',
  }
];

const getTabs = (p, t) => [
  {
    title: p('your_promo_codes'),
    name: 'all',
  },
  {
    title: t('actions.archive'),
    name: 'archive',
  }
];


const PromoCodesPage = () => {
  const dispatch = useDispatch();
  const promoCodes = useSelector(promoCodesSelector);
  const loading = useSelector(promoCodesLoadingSelector);
  const [tab, setTab] = useState('all');
  const { p, t } = useTranslation('promo_codes_page');
  const [create] = useQueryParam('create', StringParam);
  const createModal = useModalState(false);
  const redirect = useAppRedirects();

  const tabs = getTabs(p, t);

  useEffect(() => {
    if (create) {
      createModal.open();
    }
  }, [create]);

  useEffect(() => {
    if (tab === 'archive') {
      table.filters.onChange('status', identity)([3]);
    } else {
      table.filters.onChange('status', identity)([0, 1]);
    }
  }, [tab]);

  const table = useTable({
    filtersConfig: {
      name: '',
      '$type': [],
      user_id: [],
      start_date: '',
      end_date: '',
      status: [],
    },
    dynamicFilters: ['status'],
    initialOrdering: 'created_at',
    requestDispatcher: params => {
      dispatch(getPromoCodes(params));
    },
  });
  const { errors, handleRequestError, clearError, clear, setErrors } = useErrors();

  const handleCreatePromoCode = (fields, onSuccess) => {
    dispatch(savePromoCode({
      fields,
      onSuccess: ({ data }) => {
        table.refresh();
        createModal.close();
        onSuccess?.();
        redirect({
          trigger: AppRedirects.PROMO_CODE_CREATION,
          data: { id: data?.id },
        })
      },
      onError: handleRequestError
    }));
  };

  const columns = [
    {
      title: () => <SortableTitle name="name" ordering={table.ordering}>{t('labels.name')}</SortableTitle>,
      dataIndex: 'name',
      key: 'name',
      className: `promo-code-page-cell-name`,
      render: (name, promoCode) => (
        <TitleCell
          {...promoCode}
          title={name}
          onEditSuccess={table.refresh}
        />
      ),
    },
    {
      title: () => <SortableTitle>{t('labels.type')}</SortableTitle>,
      dataIndex: 'type',
      className: `promo-code-page-cell-type`,
      key: 'type',
      render: (type) => type === 2 ? p('static') : p('dynamic'),
    },
    {
      title: () => <SortableTitle>{p('example')}</SortableTitle>,
      dataIndex: 'promo_code_example',
      className: `promo-code-page-cell-example`,
      key: 'example',
      render: (example, { code }) => <Example>{example || code}</Example>
    },
    {
      title: () => <SortableTitle name="created_at" ordering={table.ordering}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'created_at',
      className: `promo-code-page-cell-created-at`,
      key: 'date',
      render: (date) => <DateCell date={date} />
    },
    {
      title: () => <SortableTitle>{t('labels.user')}</SortableTitle>,
      dataIndex: 'user',
      className: `promo-code-page-cell-user`,
      key: 'user',
      render: (user) => user?.name ? <Link to={`${Paths.USERS_SETTINGS}/${user?.id}`}>{user?.name}</Link> : <span className="greytext">{t('labels.user_deleted')}</span>
    },
    {
      title: () => <SortableTitle>{t('labels.status')}</SortableTitle>,
      dataIndex: 'used',
      className: `promo-code-page-cell-status`,
      key: 'used',
      render: (used, { workers }) => <SegmentStatus draft={t('statuses.draft')} used={used} workflows={workers} />
    },
    {
      title: () => <SortableTitle right>{t('labels.actions')}</SortableTitle>,
      dataIndex: 'id',
      className: `promo-code-page-cell-actions`,
      key: 'actions',
      render: (id, { used, name, status, workers }) => {

        return (
          <TableActions id={id} used={used} status={status} workers={workers} name={name} updateTable={table.refresh} onRefresh={table.applyFilters} />
        );
      }
    },
  ];

  const keyExtractor = ({ id }) => id;

  return (
    <Container>
      <Header
        title={p('title')}
        tabs={tabs}
        tab={tab}
        onTabChange={setTab}
        left={(
          <HeaderPagination
            onPageChange={table.changePage}
            onPerPageChange={table.changePerPage}
            page={table.pagination.page}
            perPage={table.pagination.perPage}
            total={promoCodes.meta?.total}
          />
        )}
        right={(
          <>
            <IconButton
              active={table.filters.opened}
              onClick={table.filters.toggle}
              {...testId('promo-codes-filters-toggle')()}
              style={{ marginRight: '10px' }}
              icon="Filters"
            />
            <WithPermissions name={UserPermissions.CREATE_PROMO_CODE}>
              <Button
                {...testId('promo-codes-create')()}
                onClick={createModal.open}
                icon={<Icon style={{ marginRight: '4px' }} size={16} name="Add-new" />}
                width={107}
              >
                <div>{t('actions.create')}</div>
              </Button>
            </WithPermissions>
            <EditPromoCodeModal
              onClearError={clearError}
              errors={errors}
              opened={createModal.opened}
              onClearErrors={clear}
              onSetErrors={setErrors}
              onSubmit={handleCreatePromoCode}
              onClose={createModal.close}
            />
          </>
        )}
        opened={table.filters.opened}
        content={(
          <FiltersCol>
            <FiltersRow>
              <SearchFilter>
                <Input
                  {...testId('promo-codes-filters-name')()}
                  value={table.filters.filters['name']}
                  onChange={table.filters.onChange('name')}
                  title={p('search')}
                />
              </SearchFilter>
              <DateInputsRow>
                <DateFilter>
                  <Multiselect
                    {...testId('promo-codes-filters-type')()}
                    onChange={table.filters.onChange('$type', identity)}
                    options={typeOptions}
                    value={table.filters.filters['$type']}
                    title={t('labels.type')}
                    style={{ backgroundColor: '#fff' }}
                  />
                </DateFilter>
                <DateFilter>
                  <Multiselect
                    placeholder={t('statuses.all_statuses')}
                    {...testId('promo-codes-filters-status')()}
                    onChange={table.filters.onChange('status', identity)}
                    options={statusOptions}
                    title={t('labels.status')}
                    value={table.filters.filters['status']}
                    style={{ backgroundColor: '#fff', marginLeft: 5 }}
                  />
                </DateFilter>
                <DateFilter>
                  <UserSelect
                    isMulti
                    title={t('labels.user')}
                    {...testId('promo-codes-filters-user-id')()}
                    onChange={table.filters.onChange('user_id', identity)}
                    value={table.filters.filters['user_id']}
                    label={t('labels.user')}
                    containerStyle={{ marginLeft: 5 }}
                    style={{ backgroundColor: '#fff', marginLeft: 5 }}
                  />
                </DateFilter>
              </DateInputsRow>
            </FiltersRow>
            <FilterActions>
              <div style={{ display: 'flex' }}>
              <DateFilter>
                <DatePicker
                  onChange={table.filters.onChange('start_date', (_, d) => d)}
                  value={table.filters.filters['start_date'] ? moment(table.filters.filters['start_date']): undefined}
                  type="date"
                  title={t('labels.date_from')}
                />
              </DateFilter>
              <DateFilter>
                <DatePicker
                  onChange={table.filters.onChange('end_date', (_, d) => d)}
                  value={table.filters.filters['end_date'] ? moment(table.filters.filters['end_date']): undefined}
                  type="date"
                  title={t('labels.date_to')}
                />
              </DateFilter>
              </div>
              <div style={{ display: 'flex' }}>
                <Button {...testId('promo-codes-filters-search')()} onClick={table.applyFilters} width={100} style={{ marginRight: '10px' }}>{t('actions.search')}</Button>
                <Button {...testId('promo-codes-filters-discard')()} appearance="discard" onClick={table.discardFilters} width={100}>{t('actions.discard')}</Button>
              </div>
            </FilterActions>
          </FiltersCol>
        )}
      />
      <WithPermissions name={UserPermissions.REVIEW_PROMO_CODE} hidden>
        <Table
          loading={loading}
          columns={columns}
          dataSource={promoCodes?.data || []}
          rowKey={keyExtractor}
        />
      </WithPermissions>
    </Container>
  );
};

export default PromoCodesPage;
