import React, { useEffect, useState } from 'react';
import uniqBy from 'lodash.uniqby';

import {
  Container,
  StyledAutoComplete,
  Error,
  Title,
  PlusContainer,
  IconContainer,
  Overlay,
  DropdownItem, Loader
} from './styled';
import { extract, zeroIfNaN, resolveDropdownFieldType, identity, sequence } from '@utils';
import { Dropdown, Spin, Tooltip } from 'antd';
import { Icon } from '../../ui';
import { useSelector } from 'react-redux';
import { segmentsOptionsSelector } from '@store/selectors';
import { InfoIconContainer } from '../Input/styled';
import { Suffix } from '../Select/styled';
import { useTranslation } from '@hooks';
import useAutocomplete from '@hooks/useAutocomplete';

const allowedOperatorsList = [
  'equals', 'does_not_equal', 'greater_than', 'less_than',
]

const shouldRenderPlus = (operator) => allowedOperatorsList.indexOf(operator) !== -1;

const AutoComplete = ({
  testId,
  title,
  large,
  error,
  type,
  value,
  style,
  operator,
  operatorType,
  fullWidth,
  setFieldSelected,
  additionalOptions,
  fieldSelected,
  onPlusChange,
  valueKey,
  disabled,
  field,
  suggestionsOptions,
  options,
  showTypes = false,
  tooltip = null,
  smaller,
  displaySelf = true,
  altSelection,
  onChange,
  ...props
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const fieldOptions = useSelector(segmentsOptionsSelector);
  const { t, p, e } = useTranslation('segments_page');
  const [inputFocused, setIsInputFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [autocompleteEffectEnabled, setAutocompleteEffectEnabled] = useState(false);
  const autocomplete = useAutocomplete({ ...(suggestionsOptions || {}), query: value });

  useEffect(() => {
    if (!autocompleteEffectEnabled || !suggestionsOptions) {
      return;
    }

    autocomplete.dispatcher();
  }, [value, autocompleteEffectEnabled, !!suggestionsOptions]);

  const handleChange = (newValue, option) => {
    if (type === 'number' && zeroIfNaN(String(newValue)?.length) > zeroIfNaN(String(value)?.length)) {
      if (/^-?\d+\.?\d*$/.test(newValue) || newValue === '') {
        onChange(newValue, null, option)
      }
      return;
    }

    setFieldSelected?.(false);
    onChange(newValue, null, option);
  }

  const popup = document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');

  useEffect(() => {
    if (!autocompleteEffectEnabled) {
      return;
    }

    let p = popup || document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');

    const listener = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
        autocomplete.loadMore();
      }
    };

    if (!p) {
      return () => {
        p?.removeEventListener?.('scroll', listener);
      }
    }

    p?.addEventListener?.('scroll', listener);

    return () => {
      p?.removeEventListener?.('scroll', listener);
    }
  }, [popup, autocomplete.suggestions.length, autocompleteEffectEnabled]);

  useEffect(() => {
    if (!autocompleteEffectEnabled) {
      popup?.removeAllListeners?.('scroll');
    }
  }, [autocompleteEffectEnabled]);

  const handlePlusChange = (value) => {
    if (onPlusChange) {
      onPlusChange(value);
      setFieldSelected?.(true);
      setDropdownOpened(false);
    }
  }

  const getOptions = () => {
    if (!options) {
      return (autocomplete.suggestions || []).map(o => ({ ...o, label: !!~String(o.label).indexOf(':') ? o.label : t(o.label) }));
    }


    const opt = (displaySelf ? uniqBy(
      props.value ? [ { value: props.value, label: t(props.value) }, ...(options || [])] : options,
      ({ key, value, nested }) => nested ? key : value) : options)
      .map(({ label, ...o }) => ({ label: typeof label !== 'object' ? t(label) : label, ...o }));

    return showTypes ? opt.map((o, idx) => ({ ...o, label: (
        <div key={idx} style={{ display: 'flex', justifyContent: 'space-between', padding: '0' }}>
          <div style={{ fontSize: 13 }}>{o.label}</div>
          <Suffix style={{ marginRight: 5 }}>{resolveDropdownFieldType(o.nested, o.type, p)}</Suffix>
        </div>
      )})) : opt;
  }

  const getValue = () => {
    const opt = options || autocomplete.suggestions;

    if (fieldSelected) {
      return fieldOptions?.fields?.attribute?.find(a => a.field === value)?.label || value;
    }

    if (valueKey) {
      return opt.find(o => o.key === valueKey)?.label || value;
    }

    if (!value) {
      return value;
    }

    return opt.find(o => o.value === value)?.label || value;
  }


  const v = getValue();

  const filterOption = (inputValue, option) => String(option.value).toUpperCase().indexOf(String(inputValue).toUpperCase()) !== -1;
  const sortOption = (a, b) => String(a.value).toLowerCase().localeCompare(String(b.value).toLowerCase());

  const renderAutocomplete = () => {
    if (typeof v === 'string' || typeof v === 'number' || !v) {
      const val = v ? (!!~String(v).indexOf(':') ? String(v) : t(String(v))) : v === 0 ? '0' : String(v);
      const largeValue = val.length > 15;

      return (
        <Tooltip open={hovered && largeValue && !inputFocused} title={val}>
          <StyledAutoComplete
            {...props}
            style={props.innerStyle}
            onChange={handleChange}
            disabled={disabled}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            smaller={smaller}
            htmlType={type}
            popupClassName={'acp'}
            onFocus={sequence(props.onFocus || identity, () => { setIsInputFocused(true); setAutocompleteEffectEnabled(true) })}
            onBlur={sequence(props.onBlur || identity, () => { setIsInputFocused(false); setAutocompleteEffectEnabled(false) })}
            large={large && !fullWidth}
            fullWidth={fullWidth}
            placeholder={(altSelection && !fieldSelected) ? '' : t('labels.select')}
            value={val}
            error={error}
            options={getOptions().sort(sortOption)}
            filterOption={filterOption}
          />
        </Tooltip>
      )
    }

    return (
      <StyledAutoComplete
        {...props}
        style={props.innerStyle}
        onChange={handleChange}
        disabled={disabled}
        smaller={smaller}
        htmlType={type}
        popupClassName={'acp'}
        onFocus={sequence(props.onFocus || identity, () => setAutocompleteEffectEnabled(true))}
        onBlur={sequence(props.onBlur || identity, () => setAutocompleteEffectEnabled(false))}
        large={large && !fullWidth}
        fullWidth={fullWidth}
        placeholder={v ? '' : t('labels.select')}
        value={v}
        error={error}
        options={getOptions().sort(sortOption)}
        filterOption={filterOption}
      >
        {(!!v && (typeof v === 'string' || typeof v === 'number')) ? null : <div>{v}</div>}
      </StyledAutoComplete>
    );
  }

  return (
    <Container disabled={disabled} style={style} fullWidth={fullWidth} data-testid={`${testId}-container`}>
      {title && altSelection && !fieldSelected ? (
        <Tooltip placement="topLeft" title={p('you_can_write_your_own_value')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{title}</Title>
            <InfoIconContainer style={{ marginBottom: 7 }}>
              <Icon color="#909399" name="Info-icon" size={10} />
            </InfoIconContainer>
          </div>
        </Tooltip>
      ) : title && (
        <Title>{title}</Title>
      )}
      {renderAutocomplete()}
      {altSelection && shouldRenderPlus(operator) && (
        <Dropdown
          trigger={['click']}
          open={dropdownOpened}
          onOpenChange={setDropdownOpened}
          placement="bottomRight"
          overlay={(
            <Overlay>
              {additionalOptions.map((opt, idx) => (
                <DropdownItem key={idx} onClick={() => handlePlusChange(opt.value)}>
                  {field === '_te' ? (null) : opt.label}
                </DropdownItem>
              ))}
            </Overlay>
          )}
        >
          <PlusContainer>
            <IconContainer>
              <Icon name={fieldSelected ? 'Dropdown-arrow-icon' : 'Plus-icon'} color="#909399" size={fieldSelected ? 12 : 16} />
            </IconContainer>
          </PlusContainer>
        </Dropdown>
      )}
      {autocomplete.loading && (
        <Loader smaller={smaller} left={altSelection && shouldRenderPlus(operator)} $error={error}>
          <Spin size={smaller ? 'small' : 'default'} spinning />
        </Loader>
      )}
      {error && (
        <Tooltip title={e(error)} placement="bottom">
          <Error smaller={smaller} left={altSelection && shouldRenderPlus(operator)}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7" cy="7" r="7" fill="#C8402F"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
            </svg>
          </Error>
        </Tooltip>
      )}
    </Container>
  );
};

export default AutoComplete;
