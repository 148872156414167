import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const Container = styled.div`
  width: 156px;
  height: 96px;
  position: relative;
  display: flex;
  align-items: flex-start;
  
  & > div {
    margin-left: 0px !important;
  }
`;

export const TextContainer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  background: #FAFAFA;
`;

export const Title = styled.div`
  font-family: Rubik-Regular;
  font-size: 11px;
  line-height: 13px;
  color: #303133;
`;

export const Description = styled.div`
  font-family: Rubik-Regular;
  font-size: 9px;
  line-height: 130%;
  color: #909399;
`;
