import styled, { css } from 'styled-components';
import { styledProp, switchStyle } from '@utils';
import { WorkflowNodes } from '@constants';
import { Handle } from 'reactflow';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 94px;
  height: 70px;
  padding-top: 5px;
  background: transparent;
`;

export const PointerContainer = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 40px;
  background: transparent;
  pointer-events: none;
  ${styledProp('selected', `
    border: 1px dashed #31C447;
    border-radius: 6px;
  `, `
    border: 1px solid transparent;
  `)}
`;

export const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${switchStyle('name', {
    [WorkflowNodes.INCLUDE]: `
      background: #B2DCBC;
    `,
    [WorkflowNodes.EXCLUDE]: `
      background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%);
    `,
    [WorkflowNodes.AB_TEST]: `
      background: #DCDFE6;
    `,
  })}
`;

export const ABText = styled.div`
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: #303133;
`;

export const NodeAnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: -28px;
`;

export const StyledHandle = styled(Handle)`
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  background: #A9A7A7;
  cursor: copy !important;
  border-radius: 50%;
  transition: border-width 280ms ease, transform 280ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  ${styledProp('disabled', css`
    pointer-events: none;
  `)}

  & > * {
    opacity: 0;
    transition: all 280ms ease;
    pointer-events: none;
  }

  :hover {
    transform: scale(1.5) translateY(-1.5px);
    border-width: 0px;
    & > * {
      opacity: 1;
      //transform: scale(1.5) translateY(-0.1px);
      //transform: translateY(1.5px);
    }
  }
`;
