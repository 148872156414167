import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border-bottom-right-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  border-bottom-left-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  //overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${styledProp('backButton', css`
    padding: 0 14px 0 20px;
  `, css`
    padding: 0 20px;
  `)}
  height: 60px;
  overflow: hidden;
  border-radius: 2px;
  border-top-right-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  border-top-left-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};

  ${styledProp('bottomBordered', css`
    border-bottom-right-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
    border-bottom-left-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  `)}
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  transition: 300ms ease all;
  ${({ $opened }) =>
    $opened
      ? `
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  `
      : ''}

  background: #FFFFFF;
`;

export const TabsContainer = styled.div`
  height: 46px;
  border-top: 1px solid #DCDFE6;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  align-items: center;
  border-bottom-right-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  border-bottom-left-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  
  ${styledProp('lightTabs', css`
    background-color: #F9FBFF;
  `)}
  
  ${styledProp('backButton', css`
    padding: 0 48px;
  `, css`
    padding: 0 22px;
  `)}
  position: relative;
`;

export const Tab = styled.div`
  padding: 10px 0;
  margin-right: 30px;
  cursor: pointer;
  
  ${styledProp('active', css`
    color: #109DFB;
  `)}
  
  ${styledProp('disabled', css`
    cursor: default;
    opacity: .5;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  height: 3px;
  background-color: #109DFB;
  width: 110px;
  left: 0;
  transition: all 280ms ease;
  border-radius: 4px 4px 0 0;
  bottom: 0px;
  
  ${({ $tab: el }) => {
    const rect = el?.getBoundingClientRect?.();

    return css`
      transform: translateX(${el?.offsetLeft || 0}px);
      width: ${rect?.width}px;
    `;
  }}
`;

export const ContentContainer = styled.div`
  transition: max-height 300ms ease;
  max-height: ${({ $opened }) => $opened ? 1000 : 0}px;
  width: 100%;
  overflow: hidden;
  background: #F0F2F6;
  border-bottom-right-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
  border-bottom-left-radius: ${({ withoutBottomRounded }) => (withoutBottomRounded ? '0px' : '10px')};
`;

export const BackButtonContainer = styled.div``;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  font-family: Rubik-Regular;
  font-size: 20px;
`;

export const Shadow = styled.div`
  display: flex;
  flex-direction: column;
  ${styledProp('$opened', css`
    border-bottom: 1px solid #DCDFE6;
  `)}
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0px !important;
  align-self: center;
  height: 100%;
  margin-right: 14px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #DCDFE6;
`;
