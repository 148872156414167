import { useContext } from 'react';
import { PageLoaderContext } from '../contexts';

const usePageLoader = () => {
  const ctx = useContext(PageLoaderContext);

  return ctx;
};

export default usePageLoader;
