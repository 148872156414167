import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: Rubik-Regular;
  font-size: 18px;
`;

export const PreviewWrapper = styled.div`
  max-width: 100%;
`;

export const PreviewData = styled.div`
  min-width: 260px;
  background: #EFEFEF;
  border-radius: 5px 5px 5px 0px;
  padding-bottom: 1px;
`;

export const Button = styled.div`
  background: #7062C8;
  border-radius: 58px;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;
  padding: 6px;
  margin: 5px;
  margin-bottom: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  font-size: 17px;
  line-height: 120%;
  font-family: Rubik-SemiBold;
  padding: 8px 6px 0 11px;
`;

export const Body = styled.div`
  font-size: 17px;
  line-height: 120%;
  padding: 8px 6px 15px 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
`;

export const Icon = styled.img`
  width: 260px;
  max-height: 280px;
  border-bottom-right-radius: 5px;
`;
