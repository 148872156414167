import React from 'react';
import { Modal, WithPermissions } from '@components';

import { DeleteAction } from './components';
import { Container, ActionsContainer, TextContainer } from './styled';
import { UserPermissions, WorkflowNodes } from '@constants';
import { Tooltip } from 'antd';
import { useTranslation } from '@hooks';
import { testId } from '@utils';

const DeleteNodeModal = ({ opened, deleteBranchDisabled, onClose, onDeleteNode, onDeleteBranch, toRemove }) => {
  const name = toRemove?.data?.name;
  const { t, p } = useTranslation('workflow_page');

  const getPermissionName = () => {
    let permission = '';
    switch (name) {
      case WorkflowNodes.SEND_SMS:
        permission = UserPermissions.DELETE_SMS_NODES;
        break;
      case WorkflowNodes.SEND_EMAIL:
        permission = UserPermissions.DELETE_EMAIL_NODES;
        break;
      case WorkflowNodes.WEBPUSH:
        permission = UserPermissions.DELETE_WEBPUSH_NODES;
        break;
      case WorkflowNodes.MOBILE_PUSH:
        permission = UserPermissions.DELETE_MOBILE_PUSH_NODES;
        break;
      case WorkflowNodes.API_REQUEST:
        permission = UserPermissions.DELETE_API_REQUEST_NODES;
        break;
      case WorkflowNodes.VIBER:
        permission = UserPermissions.DELETE_VIBER;
        break;
      case WorkflowNodes.PUSH_DISCOUNT:
        permission = UserPermissions.DELETE_DISCOUNT_NODES;
        break;
      default:
        permission = '';
    }

    return {
      permissionName: permission,
      shouldCheck: name === WorkflowNodes.SEND_SMS || name === WorkflowNodes.MOBILE_PUSH || name === WorkflowNodes.EMAIL || name === WorkflowNodes.WEBPUSH || name === WorkflowNodes.API_REQUEST || name === WorkflowNodes.VIBER || name === WorkflowNodes.PUSH_DISCOUNT,
    }
  }

  const handleDeleteNode = () => {
    onDeleteNode();
  }

  const handleDeleteBranch = () => {
    onDeleteBranch();
  }

  const { shouldCheck, permissionName } = getPermissionName();

  return (
    <Modal
      appearance="danger"
      title={p('delete_node')}
      bigger
      {...{ opened, onClose }}
    >
      <Container>
        <TextContainer>
          {p('node_or_branch')}
        </TextContainer>
        {shouldCheck ? (
          <WithPermissions name={permissionName}>
            <ActionsContainer>
              <DeleteAction testId={`workflow-delete-node`} {...testId('edit-workflow-page-delete-node')()} style={{ marginRight: '10px' }} onClick={handleDeleteNode} />
              <DeleteAction testId={`workflow-delete-branch`} {...testId('edit-workflow-page-delete-branch')()} disabled={deleteBranchDisabled} style={{ marginLeft: '10px' }} deleteBranch onClick={handleDeleteBranch} />
            </ActionsContainer>
          </WithPermissions>
        ) : (
          <ActionsContainer>
            <DeleteAction testId={`workflow-delete-node`} {...testId('edit-workflow-page-delete-node')()} style={{ marginRight: '10px' }} onClick={handleDeleteNode} />
            <DeleteAction testId={`workflow-delete-branch`} {...testId('edit-workflow-page-delete-branch')()} disabled={deleteBranchDisabled} style={{ marginLeft: '10px' }} deleteBranch onClick={handleDeleteBranch} />
          </ActionsContainer>
        )}
      </Container>
    </Modal>
  );
};

export default DeleteNodeModal;
