const SegmentsActionTypes = {
  GET: 'segments/GET',
  GET_SUCCESS: 'segments/GET_SUCCESS',
  GET_ERROR: 'segments/GET_ERROR',

  GET_EDIT_METADATA: 'segments/GET_EDIT_METADATA',
  GET_EDIT_METADATA_SUCCESS: 'segments/GET_EDIT_METADATA_SUCCESS',
  GET_EDIT_METADATA_ERROR: 'segments/GET_EDIT_METADATA_ERROR',

  PUBLISH: 'segments/PUBLISH',
  PUBLISH_SUCCESS: 'segments/PUBLISH_SUCCESS',
  PUBLISH_ERROR: 'segments/PUBLISH_ERROR',

  UN_PUBLISH: 'segments/UN_PUBLISH',
  UN_PUBLISH_SUCCESS: 'segments/UN_PUBLISH_SUCCESS',
  UN_PUBLISH_ERROR: 'segments/UN_PUBLISH_ERROR',

  DELETE: 'segments/DELETE',
  DELETE_SUCCESS: 'segments/DELETE_SUCCESS',
  DELETE_ERROR: 'segments/DELETE_ERROR',

  REFRESH: 'segments/REFRESH',
  REFRESH_SUCCESS: 'segments/REFRESH_SUCCESS',
  REFRESH_ERROR: 'segments/REFRESH_ERROR',

  GET_SINGLE_AGGREGATE: 'segments/GET_SINGLE_AGGREGATE',
  GET_SINGLE_AGGREGATE_SUCCESS: 'segments/GET_SINGLE_AGGREGATE_SUCCESS',
  GET_SINGLE_AGGREGATE_ERROR: 'segments/GET_SINGLE_AGGREGATE_ERROR',


  CREATE_AGGREGATE: 'segments/CREATE_AGGREGATE',
  CREATE_AGGREGATE_SUCCESS: 'segments/CREATE_AGGREGATE_SUCCESS',
  CREATE_AGGREGATE_ERROR: 'segments/CREATE_AGGREGATE_ERROR',

  DELETE_AGGREGATE: 'segments/DELETE_AGGREGATE',
  DELETE_AGGREGATE_SUCCESS: 'segments/DELETE_AGGREGATE_SUCCESS',
  DELETE_AGGREGATE_ERROR: 'segments/DELETE_AGGREGATE_ERROR',

  GET_AGGREGATE: 'segments/GET_AGGREGATES',
  GET_AGGREGATE_SUCCESS: 'segments/GET_AGGREGATES_SUCCESS',
  GET_AGGREGATE_ERROR: 'segments/GET_AGGREGATES_ERROR',

  GET_OPTIONS: 'segments/GET_OPTIONS',
  GET_OPTIONS_SUCCESS: 'segments/GET_OPTIONS_SUCCESS',
  GET_OPTIONS_ERROR: 'segments/GET_OPTIONS_ERROR',

  SAVE: 'segments/SAVE',
  SAVE_SUCCESS: 'segments/SAVE_SUCCESS',
  SAVE_ERROR: 'segments/SAVE_ERROR',

  EDIT: 'segments/EDIT',
  EDIT_SUCCESS: 'segments/EDIT_SUCCESS',
  EDIT_ERROR: 'segments/EDIT_ERROR',

  GET_FILTER_RESULTS: 'segments/GET_FILTER_RESULTS',
  CLEAR_FILTER_RESULTS: 'segments/CLEAR_FILTER_RESULTS',
  GET_FILTER_RESULTS_SUCCESS: 'segments/GET_FILTER_RESULTS_SUCCESS',
  GET_FILTER_RESULTS_ERROR: 'segments/GET_FILTER_RESULTS_ERROR',

  GET_CONTACTS_EXAMPLE: 'segments/GET_CONTACTS_EXAMPLE',
  GET_CONTACTS_EXAMPLE_SUCCESS: 'segments/GET_CONTACTS_EXAMPLE_SUCCESS',
  GET_CONTACTS_EXAMPLE_ERROR: 'segments/GET_CONTACTS_EXAMPLE_ERROR',

  EXPORT_CUSTOMERS: 'segments/EXPORT_CUSTOMERS',
  EXPORT_CUSTOMERS_SUCCESS: 'segments/EXPORT_CUSTOMERS_SUCCESS',
  EXPORT_CUSTOMERS_ERROR: 'segments/EXPORT_CUSTOMERS_ERROR',

  SAVE_NEW_SEGMENT_PRESET: 'segments/SAVE_NEW_SEGMENT_PRESET',
  CLEAR_NEW_SEGMENT_PRESET: 'segments/CLEAR_NEW_SEGMENT_PRESET',

  LIST_NESTED_AGGREGATES: 'segments/LIST_NESTED_AGGREGATES',
  LIST_NESTED_AGGREGATES_SUCCESS: 'segments/LIST_NESTED_AGGREGATES_SUCCESS',
  LIST_NESTED_AGGREGATES_ERROR: 'segments/LIST_NESTED_AGGREGATES_ERROR',
};

export default SegmentsActionTypes;

