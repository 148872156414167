import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 9px;

  ${styledProp('$selected', css`
    border-color: #109DFB;
  `)}
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: #DCDFE6;
`;

export const Label = styled.div`
  font-family: Rubik-Regular;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 17px;
  color: #303133;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  ${styledProp('$selected', css`
    color: #109DFB;
  `)}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
  padding: 7px 0;
`;

export const Option = styled.div`
  padding: 7px 14px;
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  cursor: pointer;
`;
