import React from 'react';
import { Tag } from 'antd';
import { by, identity, moment } from '@utils';
import { ActionButton } from '@components';

import { StyledSelect, Container, Title, Row } from './styled';
import { useTranslation } from '@hooks';

const earliest = moment('0001-01-01');
const latest = moment('2030-01-01');

const Options = {
  years: ([start, end]) => Array.from({ length: Math.abs((start || earliest).diff((end || latest), 'years')) }, (_, i) => i + 1).reverse(),
  months: () => Array.from({ length: 12 }, (_, i) => i + 1),
  days: () => Array.from({ length: 31 }, (_, i) => i + 1),
  week_days: () => Array.from({ length: 7 }, (_, i) => i + 1),
  hours: () => Array.from({ length: 24 }, (_, i) => i),
  minutes: () => Array.from({ length: 60 }, (_, i) => i),
};

const LabelMappers = {
  years: identity,
  months: (i) => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][i - 1],
  days: identity,
  week_days: (i) => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][i - 1],
  hours: identity,
  minutes: identity,
}

const shouldRenderTag = (tag, value) => {
  return !value.includes(tag - 1);
};

const resolveTagLabel = (tag, value, options) => {
  let last = tag;
  while (value.includes(last + 1)) {
    last++;
  }

  if (last === tag) {
    return options.find(by('value', tag))?.label;
  }

  return `${options.find(by('value', tag))?.label} - ${options.find(by('value', last))?.label}`
};

const getIncludedTags = (tag, value) => {
  const res = [tag];
  while (value.includes(res[res.length - 1] + 1)) {
    res.push(res[res.length - 1] + 1);
  }

  return res;
};

const resolveTitle = (type) => {
  return {
    years: 'labels.year',
    months: 'labels.month',
    week_days: 'labels.week_day',
    days: 'labels.month_day',
    hours: 'labels.hour',
    minutes: 'labels.minute',
  }[type];
}

const ConditionFilterSelect = ({ type, range, value, onChange, onDelete, ...props }) => {
  const options = Options[type](range).map(value => ({ value, label: LabelMappers[type](value) }));
  const { t } = useTranslation();

  const tagRender = ({ value: v, closable }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleClose = () => {
      const included = getIncludedTags(v, value);
      onChange(value.filter(v => !included.includes(v)));
    };

    if (!shouldRenderTag(v, value)) {
      return null;
    }

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={handleClose}
        style={{ marginRight: 3, borderRadius: 6 }}
      >
        {resolveTagLabel(v, value, options)}
      </Tag>
    );
  };

  return (
    <Container>
      <Title>{t(resolveTitle(type))}</Title>
      <Row>
        <StyledSelect
          {...props}
          tagRender={tagRender}
          value={value}
          onChange={onChange}
          mode="multiple"
          showArrow
          suffixIcon={(
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.7334 2.5987L4.00007 6.33203L0.266734 2.5987L1.20007 1.66537L4.00007 4.46536L6.80007 1.66537L7.7334 2.5987Z" fill="#909399"/>
            </svg>
          )}
          maxTagCount={8}
          options={options}
        />
        <ActionButton
          tooltip={t('actions.delete')}
          appearance="danger"
          icon="Delete-icon"
          onClick={onDelete}
        />
      </Row>
    </Container>
  )
};

export default ConditionFilterSelect;
