import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 6px;
`;

export const In = styled.div`
  font-family: Rubik-Regular;
  margin-right: 14px;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  color: #24993E;
`;

export const Out = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 120%;
  color: #C8402F;
`;

