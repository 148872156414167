import React, { useRef } from 'react';

import { Body, Button, Container, Content, Icon, Image, ImageContainer, PreviewData } from './styled.js';
import { EmailSampleData, ToggleButton } from '@components';
import { useModalState, useTranslation } from '@hooks';

import mobilePreview from '../../../res/images/mobile-preview.svg';
import {findAttributes, replaceKeysWithValues, stringTemplate} from '@utils';
import { TemplatesGeneralAttributesDrawer } from '@components';
import {FallbackValuesModal} from "@components/modals/FallbackValuesModal";
import {useSelector} from "react-redux";
import { emailSampleDataSelector, fieldsSettingsListSelector } from "@store/selectors";

const ViberPreview = ({ body, onChange, integrated, previewData, hideActions = false, icon, buttonName }) => {
  const fieldSettings = useSelector(fieldsSettingsListSelector)?.data || [];
  const fields = fieldSettings.map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const fallbackModal = useModalState();
  const exampleFields = useModalState();
  const subscriptionFields = useModalState();
  const discountFields = useModalState();
  const container = useRef(null);
  const { p, t } = useTranslation('templates');

  const handleToggleFieldsExample = () => {
    subscriptionFields.close();
    discountFields.close();
    exampleFields.toggle();
  };

  const handleDiscountToggle = () => {
    exampleFields.close();
    discountFields.toggle();
    subscriptionFields.close();
  };

  const formatMatches = () => {
    const cfg = {
      '\\`\\`\\`': props => <code {...props} />,
      '\\*': props => <b {...props} />,
      '_': props => <i {...props} />,
      '~': props => <del {...props} />,
    };

    return Object.entries(cfg)
      .flatMap(([matcher, render]) => (body
        ?.match(new RegExp(`(${matcher}.*${matcher})`, 'g')) || [])?.map(match => [matcher, match, render])
      ).reduce((acc, [matcher, match, render]) => ({
        ...acc,
        [match]: render({ children: match.replaceAll(new RegExp(matcher, 'g'), '') })
      }), {
        '\n': <br />
      });
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['body', 'buttonName'], { body, buttonName });
    onChange?.(t => ({ ...t, ...replaced }));
  }

  return (
    <Container>
      {!hideActions && (
        <div style={{ display: 'flex',  marginBottom: 10, justifyContent: 'flex-end' }}>
          {(body?.indexOf('{{customer.') !== -1 || buttonName?.indexOf('{{customer.') !== -1) && (
            <ToggleButton
              testId="viber-fallback-toggle"
              opened={fallbackModal.opened}
              style={{ marginRight: 4 }}
              fallback
              onToggle={fallbackModal.open}
            >
              {p('fallback_values')}
            </ToggleButton>
          )}
          <ToggleButton
            testId="viber-attributes-toggle"
            opened={exampleFields.opened}
            style={{ marginRight: 12 }}
            onToggle={handleToggleFieldsExample}
          >
            {p('customer_attributes')}
          </ToggleButton>
          <ToggleButton
            testId="email-discount-toggle"
            style={{ marginRight: 10 }}
            opened={discountFields.opened}
            onToggle={handleDiscountToggle}
          >
            {p('general_attributes')}
          </ToggleButton>
        </div>
      )}
      <Content $integrated={integrated} ref={container}>
        <ImageContainer>
          <Image src={mobilePreview} />
          <PreviewData>
            {/*<Title>{title}</Title>*/}
            <Body>{stringTemplate(formatMatches(), (previewData?.body || body))}</Body>
            {icon && <Icon src={icon} />}
            {!!buttonName ? <Button>{previewData?.buttonName || buttonName}</Button> : null}
          </PreviewData>
        </ImageContainer>

        <EmailSampleData
          onClose={exampleFields.close}
          opened={exampleFields.opened}
          getContainer={() => container.current}
          width={'100%'}
          smaller
          onVisibilityChanged={exampleFields.setOpened}
        />
        <TemplatesGeneralAttributesDrawer
          onClose={discountFields.close}
          opened={discountFields.opened}
          getContainer={() => container.current}
          width={'100%'}
          hiddenTabs={['subscription']}
          small
          onVisibilityChanged={discountFields.setOpened}
        />

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={fallbackModal.close}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([body, buttonName], fields)}
        />
      </Content>
    </Container>
  );
}

export default ViberPreview;


