import { useDispatch, useSelector } from 'react-redux';
import { dynamicVariablesSelector } from '@store/selectors/fields';
import { by, extract, identity } from '@utils';
import {
  eventsListSelector,
  fieldsSettingsListSelector,
  nestedAggregatesListSelector,
  segmentOptionsAggregatesListSelector
} from '@store/selectors';
import { useEffect, useMemo } from 'react';
import uniqBy from 'lodash.uniqby';
import { getFieldSettingsList, listNestedAggregates } from '@store/actions/creators';

const discountEntities = [
  {
    name: '{{promo_code}}',
    label: 'Promo code',
  },
  {
    name: '{{discount_value}}',
    label: 'Discount value',
  },
  {
    name: '{{discount_type}}',
    label: 'Discount type',
  },
  {
    name: '{{discount_start_date}}',
    label: 'Discount start date',
  },
  {
    name: '{{discount_end_date}}',
    label: 'Discount end date',
  },
  {
    name: '{{discount_available_tries}}',
    label: 'Discount quantity',
  },
  {
    name: '{{discount_priority}}',
    label: 'Discount priority',
  },
];

const traverseEvents = (events, nestedAggregates, namePrefix = 'payload', labelPrefix = '') => {
  let result = [];

  events.forEach((item) => {
    if (item.payload && item.payload?.length) {
      const newNamePrefix = namePrefix ? `${namePrefix}` : item.field || item.name;
      const newLabelPrefix = labelPrefix ? `${labelPrefix}.${item.label}` : 'Event';
      result = result.concat(traverseEvents(item.payload, newNamePrefix, newLabelPrefix));
    } else {
      const name = namePrefix ? `{{${namePrefix}.${item.field || item.name}}}` : `{{${item.field || item.name}}}`;
      const label = labelPrefix ? `${labelPrefix}.${item.label}` : item.label;
      result.push({ name, label });
    }
    if (item.nested_aggregates?.length) {
      result.push(...item.nested_aggregates.map(item => ({
        name: namePrefix ? `{{${namePrefix}.${item.name}}}` : `{{${item.name}}}`,
        label: labelPrefix ? `${labelPrefix}.${item.label}` : item.label,
      })))
    }
  });

  return uniqBy(result, extract('name'));
};


const useTemplateEntities = () => {
  const dynamicVariables = useSelector(dynamicVariablesSelector);
  const events = useSelector(eventsListSelector);
  const eventEntities = (events.data || []).filter(({ payload }) => payload && Object.keys(payload).length);
  const aggregates = useSelector(segmentOptionsAggregatesListSelector);
  const fieldSettings = useSelector(fieldsSettingsListSelector)?.data || [];
  const fields = fieldSettings.map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const dispatch = useDispatch();
  // const nestedAggregates = useSelector(nestedAggregatesListSelector);

  useEffect(() => {
    // dispatch(listNestedAggregates());
    dispatch(getFieldSettingsList());
  }, []);

  const discount = discountEntities.map(e => ({ ...e, kind: 'discount' }));
  const dynamic = dynamicVariables.map(({ name, label }) => ({ name: `{{dynamic.${name}}}`, label, kind: 'dynamic_variable' }));
  const aggregate = aggregates.map(({ name, label }) => ({ name: `{{customer.${name}}}`, label, kind: 'aggregate' }));
  const linear = fields.map(({ name, key, label }) => ({ name: `{{customer.${key}}}`, label, kind: 'field' }));
  const event = traverseEvents(eventEntities).map(e => ({ ...e, kind: 'event' }));

  const entities = useMemo(() => {
    return [discount, dynamic, aggregate, linear, event].flatMap(identity);
  }, [[discount, dynamic, aggregate, linear, event].flatMap(es => es.map(extract('name'))).join()]);

   return entities;
};

export default useTemplateEntities;
