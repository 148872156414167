import React, { useContext, useEffect, useState } from 'react';
import { EditableTitle, Icon } from '@components';
import { Handle, isNode } from 'reactflow';

import { AddWorkflowTriggerModal, NextWorkflowTrigger } from './components';
import { Wrapper, Container } from './styled';
import { useModalState, useNavigate, useTranslation } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { workflowOptionsSelector, workflowWorkerSelector } from '@store/selectors';
import workflowEditorContext from '@contexts/WorkflowEditorContext/WorkflowEditorContext';
import { isRealtime } from '@utils/workflows/refactored/getters';
import { createNode } from '@utils/workflows/refactored/creators';
import { Paths, WorkflowNodes } from '@constants';
import { workflowEditorSetNodes } from '@store/actions/creators/workflowEditor';
import { unwrapArrays } from '@utils/workflows/refactored/updaters';
import { StringParam, useQueryParam } from 'use-query-params';
import { NodeAnalyticsContainer } from '@components/lib/WorkflowEditor/components/Node/styled';
import { NodeAnalytics } from '@components/lib/WorkflowEditor/components';
import { by } from '@utils';

const FinishNode = ({ data }) => {
  const { p } = useTranslation('workflow_page')
  const addTriggerModal = useModalState();
  const options = useSelector(workflowOptionsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useContext(workflowEditorContext);
  const [wid] = useQueryParam('w', StringParam);
  const [label, setLabel] = useState(data.label);
  const workers = useSelector(workflowWorkerSelector);
  const worker = workers?.[wid];
  const isTriggeringAllowed = isRealtime(editor.state.root) && wid && worker?.status === 1;

  const nextWorkflows = (options.workflows || [])
    .filter(({ tree_map }) => (tree_map.children || []).some(({ data: childData }) => (childData.trigger_workflow_node_ids || []).includes(data.id)));

  useEffect(() => {
    setLabel(data.label);
  }, [data.label])

  const handleLabelChange = ({ target: { value } }) => {
    setLabel(value);
  };

  const handleLabelBlur = () => {
    editor.changeNodeData(data.id, { ...data, label });
  };

  const handleAddTriggerModalClose = () => {
    addTriggerModal.close();
  };

  const handleCreateTriggeringWorkflow = ({ workflowName, exitPoints }) => {
    const [tree_map, style] = createNode({
      type: WorkflowNodes.ENTRY_ANOTHER_WF,
      actionType: 'entry_point',
      translate: p,
      data: {
        trigger_workflow_id: wid,
        trigger_workflow_node_ids: exitPoints,
        validated: true,
        validation: { valid: true, errors: {} }
      },
    });

    dispatch(workflowEditorSetNodes({
      id: 'new',
      tree_map: unwrapArrays(tree_map, true),
      styles: {
        [style.id]: style,
      }
    }))

    navigate(`${Paths.CREATE_WORKFLOW}?n=${workflowName}`);
  };

  return (
    <Wrapper>
      <Container>
        {data.analytics && (
          <NodeAnalyticsContainer style={{ top: -28 }}>
            <NodeAnalytics
              data={{
                ...(data.analytics || {}),
              }}
              testId={`workflow-node-${(editor.renderNodes || []).filter(isNode).findIndex(by('id', data.id))}`}
              type={data.name}
            />
          </NodeAnalyticsContainer>
        )}
        <Handle
          type="target"
          position="left"
          id="a"
          isConnectable
          style={{ transform: !editor.editable ? 'translateY(-3px)' : 'translateY(-2px)', opacity: 0, position: 'absolute', left: 0 }}
        />
        <Icon name="Nodes-Finish" size={18} color="#fff" />
        {isTriggeringAllowed && (
          <>
            <AddWorkflowTriggerModal
              opened={addTriggerModal.opened}
              id={data.id}
              onConfirm={handleCreateTriggeringWorkflow}
              onClose={handleAddTriggerModalClose}
            />
            <NextWorkflowTrigger
              workflows={nextWorkflows}
              onCreate={addTriggerModal.open}
            />
          </>
        )}
      </Container>
      <EditableTitle
        style={{ fontSize: 10, width: 'auto', display: 'flex', flex: 0, minWidth: 60 }}
        containerStyle={{ marginTop: 6, overflow: 'hidden', justifyContent: 'center' }}
        value={label}
        onChange={handleLabelChange}
        onBlur={handleLabelBlur}
        smaller
        disabled={!editor.editable}
        size={14}
        iconStyle={{ minWidth: 14, minHeight: 14, fontSize: 10  }}
      />
    </Wrapper>
  );
};

export default FinishNode;
