import React, {useMemo} from 'react';
import {Paths} from "@constants";
import {SendingInfo} from "../SendingInfo";
import {useSelector} from "react-redux";
import {dashboardSmsSummarySelector} from "@store/selectors/dashboard";
import {zeroIfNaN} from "@utils";
import {useTranslation} from "@hooks";

const SmsSendingInfo = () => {
  const { p, t } = useTranslation('dashboard_page');
  const smsSummary = useSelector(dashboardSmsSummarySelector);

  const smsClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(smsSummary?.click / smsSummary?.total * 100),
      count: smsSummary?.click
    };
  }, [smsSummary?.click, smsSummary?.total]);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  return (
    <SendingInfo
      total={smsSummary?.total}
      link={p('sms')}
      clicks={smsClicks}
      to={`${Paths.NOTIFICATIONS}?tab=sms`}
      openedCount={null}
      testId="dashboard-sms-sendings"
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(SmsSendingInfo);
