import React from 'react';
import { Platforms } from '@utils/platform';
import { useModalState } from '@hooks';


import Img from '@res/images/phone.svg';
import MobilePushBackground from '@res/images/mobile-push-background.png';

import { AndroidWebPushPreview, IOSWebPushPreview, MacOSPushPreview, WindowsPushPreview } from './components';
import { Container, PhoneImage, Blur } from './styled.js';
import { WindowsBackground } from '@components/lib/WebPushPreview/components/WindowsBackground';
import { MacOSBackground } from '@components/lib/WebPushPreview/components/MacOSBackground';

const WebPushPreview = ({ platform, mobilePush, ...props }) => {
  const state = useModalState();
  console.log('pact', props.buttons)

  const resolvePreview = () => {
    switch (platform) {
      case Platforms.ANDROID:
        return <AndroidWebPushPreview mobilePush={mobilePush} opened={state.opened} onToggle={state.toggle} {...props} />;
      case Platforms.IOS:
        return <IOSWebPushPreview mobilePush={mobilePush} opened={state.opened} onToggle={state.toggle} {...props} />;
      case Platforms.MACOS:
        return <MacOSPushPreview opened={state.opened} onToggle={state.toggle} {...props} />;
      case Platforms.WINDOWS:
        return <WindowsPushPreview opened={state.opened} onToggle={state.toggle}  {...props} />;
      default:
        return null;
    }
  };

  return (
    <Container integrated={props.integrated}>
      {(state.opened && platform === Platforms.IOS) && <Blur />}
      {((platform === Platforms.ANDROID || platform === Platforms.IOS || mobilePush)) && (
        <PhoneImage image={true ? MobilePushBackground : Img} />
      )}
      {platform === Platforms.WINDOWS && (
        <WindowsBackground />
      )}
      {platform === Platforms.MACOS && (
        <MacOSBackground />
      )}
      {resolvePreview()}
    </Container>
  );
}

export default WebPushPreview;


