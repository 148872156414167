import React from 'react';

import { Container, Language, Overlay } from './styled';

const options = [
  { value: 'en', label: 'EN' },
  { value: 'ge', label: 'GE' },
];

const LanguageSelect = ({ language, onChange }) => {
  return (
    <Container>
      <Overlay data-testid="dashboard-language-select-dropdown">
        {options.sort(({ value }) => value === language ? 1 : -1).map(({ value, label }) => (
          <Language data-testid={`dashboard-language-select-item-${value}`} key={value} onClick={() => onChange(value)}>
            {label}
          </Language>
        ))}
      </Overlay>
      <Language data-testid="dashboard-language-selected">
        {(language || 'en').toUpperCase()}
      </Language>
    </Container>
  );
};

export default LanguageSelect;
