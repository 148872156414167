import { by, identity, update } from '@utils';
import { WorkflowNodes, WorkflowNodeTypes } from '@constants';
import { createNode } from '@utils/workflows/refactored/creators';
import { iterate, toArray } from '@utils/workflows/refactored/structureParser';
import { excludeInclude, isSplitter, notEnd, notSplitter } from '@utils/workflows/refactored/common';
import { v4 as uuidv4 } from 'uuid';
import { getExitPoints, getLastNode } from '@utils/workflows/refactored/getters';

export const updateNode = (root, id, updater) => {
  if (root.id === id) {
    return update(root, updater);
  }

  return {
    ...root,
    data: {
      ...(root.data || {}),
      children: root.data?.children ? root.data?.children?.map?.(child => updateNode(child, id, updater)) : root.data?.children,
    },
    children: root?.children?.map?.(child => updateNode(child, id, updater)),
  };
};

export const appendChild = (root, id, node) => {
  if (root.id === id) {
    if (root.type === WorkflowNodes.AB_TEST || root.type === WorkflowNodes.SWITCH_FILTER) {
      return appendChild(root, root.children[0].id, node);
    }

    return {
      ...root,
      children: [
        ...(root.children || []).filter(({ type }) => type !== 'end'),
        { ...node, data: { ...node.data, serverId: uuidv4() } }
      ],
    };
  }

  return {
    ...root,
    children: root?.children?.map(child => appendChild(child, id, node)),
  };
};

export const insertAfter = (root, id, node) => {
  if (root.id === id) {
    if (root.type === WorkflowNodes.AB_TEST || root.type === WorkflowNodes.SWITCH_FILTER) {
      return insertAfter(root, root.children[0].id, node);
    }

    if (node.type === WorkflowNodes.AB_TEST || node.type === WorkflowNodes.SWITCH_FILTER) {
      node.children = node.children.map((child, index) => ({
        ...child,
        children: (root.children[index] ? [root.children[index]] : []).filter(({ type }) => type !== 'end'),
      })).filter(({ type }) => type !== 'end');
    } else {
      node.children = [...node.children, ...(root.children || [])];
    }

    return {
      ...root,
      children: [node],
    };
  }

  return {
    ...root,
    children: root.children?.map?.(child => insertAfter(child, id, node)),
  };
};

// export const insertBefore = (root, id, ...children) => {
//   const child = root.children?.find?.(by(id));
//
//   if (child) {
//     children[children.length - 1].children = [child, ...(children[children.length - 1]?.children || []).filter(({ type }) => type !== 'include' && type !== 'exclude')]
//     const chain = children.reverse().map(child => ({ ...child, data: { ...child.data, serverId: uuidv4() } })).reduce((curr, next) => ({ ...next, children: [...next.children || [], curr] }));
//
//     return {
//       ...root,
//       children: (root.children || []).filter(({ id }) => id !== child.id).concat(chain),
//     };
//   }
//
//   return {
//     ...root,
//     children: root.children?.map?.(child => insertBefore(child, id, ...children)),
//   };
// };

export const appendFinishNodes = (root, translate, first, add = 0) => {
  const firstRoot = first || root;

  if (!root) {
    return null;
  }

  if ((root.children || []).length === 1 && root.children[0].type === WorkflowNodes.END_PATH) {
    return root;
  }


  const next = Math.max(...[0, ...getExitPoints(firstRoot).map(({ data }) => parseInt(data.label?.match(/^Exit point (\d+)$/)?.[1], 10) || 0)]) + 1 + add;

  const updated = (root.children || []).map((child, index, self) => {
    const mutated = self.filter((r, i) => i < index && !getLastNode(r).children[0]);

    return appendFinishNodes(child, translate, firstRoot, mutated.length);
  });

  return {
    ...root,
    children: updated?.length ? updated : [createNode({ type: WorkflowNodes.END_PATH, data: { source: root.id, label: `Exit point ${next}` } })[0]],
  }
};

export const removeChild = (root, id) => {
  if (root.id === id) {
    if (root.children?.[0]?.type === 'end') {
      return null;
    }

    return root.children?.[0] || null;
  }

  const child = root.children?.find?.(by(id));
  if (child) {
    let removed = {
      ...root,
      children: root.children.filter(({ id: curr }) => curr !== id).concat(child.children),
    };

    for (const cc of child.children) {
      if (isSplitter(cc) || excludeInclude(cc)) {
        removed = removeChild(removed, cc.id)
      }
    }

    if (removed.children.length > 1) {
      removed.children = removed.children.filter(({ type }) => type !== 'end');
    }

    return removed;
  }

  return {
    ...root,
    children: root.children?.map?.(child => removeChild(child, id)),
  };
};

export const removeBranchFrom = (root, id) => {
  if (root.id === id) {
    return null;
  }

  const child = root.children?.find?.(by(id));
  if (child) {
    return {
      ...root,
      children: root.children.filter(({ id: curr }) => curr !== id),
    };
  }

  return {
    ...root,
    children: root.children?.map?.(child => removeBranchFrom(child, id)),
  };
};

export const clearTempNodes = (root, styles) => {
  if (!root) {
    return null;
  }

  let newRoot = root;
  let temp = toArray(root).find(({ id }) => Object.values(styles).find(by(id))?.temp);

  while (temp) {
    newRoot = removeChild(newRoot, temp.id);
    temp = toArray(newRoot).find(({ id }) => Object.values(styles).find(by(id))?.temp)
  }

  return newRoot;
};

export const clearStyles = (root, styles) => {
  let res = {};

  iterate(root, ({ id, type, data }) => {
    res[id] = styles[id];

    if (type === 'array') {
      res = { ...res, ...clearStyles(data.nodes, styles) };
    }
  })

  return res;
};

export const insertLast = (root, child) => {
  if (!(root.children || []).filter(notEnd).length) {
    return {
      ...root,
      children: [child],
    };
  }

  return {
    ...root,
    children: (root.children || []).map(ch => insertLast(ch, child)),
  };
};

export const createArrayFrom = (root, id, translate = identity) => {
  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.id === id) {
        return createNode({ nodeType: WorkflowNodeTypes.ARRAY, type: 'array', actionType: 'array', data: { nodes: child }, translate })[0];
      }

      return child;
    }).map(child => createArrayFrom(child, id, translate))
  };
};

export const findArray = (root) => {
  if (!root) {
    return null;
  }

  if (root?.type === 'array') {
    return root.id;
  }

  let res = null;

  for (const child of (root?.children || [])) {
    if (child?.type === 'array') {
      res = findArray(child);
    }
  }

  return res;
}

export const openArrayAt = (root, id) => {
  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.id === id) {
        if (child.data.nodes.data.renderId) {
          return {
            ...child.data.nodes,
            id: child.data.nodes.data.renderId,
            data: {
              ...child.data.nodes.data,
              serverId: child.data.nodes.id,
              id: child.data.nodes.data.renderId,
              arrayStart: false,
            }
          };
        }

        return {
          ...child.data.nodes,
          data: {
            ...child.data.nodes.data,
            arrayStart: false,
          }
        };
      }

      return child;
    }).map(child => openArrayAt(child, id))
  };
}

export const wrapArrays = (root, translate = identity) => {
  if (root.multinode || (root && root.id === null)) {
    const child = root.children[0];
    const children = child?.children || [];

    return wrapArrays({
      ...child,
      multinode: false,
      data: {
        ...(child?.data || []),
        children: root.children,
      },
      children,
    });
  }

  if (root.type === WorkflowNodes.QUIET_HOURS) {
    const ch = root.children[0];
    root.children = ch.children || [];
    ch.before = root;

    return wrapArrays(ch);
  }

  let id = root.id;

  if (root.data.renderId) {
    id = root.data.renderId;
  }

  return {
    ...root,
    id,
    data: {
      ...root.data,
      id,
      serverId: root.data.renderId ? root.data.id : undefined,
    },
    children: (root.children || []).map(child => {
      if (child.data.arrayStart) {
        return createNode({ translate, nodeType: WorkflowNodeTypes.ARRAY, type: 'array', actionType: 'array', data: { nodes: wrapArrays(child, translate) } })[0];
      }

      if (child.data.renderId) {
        return {...child, id: child.data.renderId, data: { ...child.data, serverId: child.data.id, id: child.data.renderId } };
      }

      return child;
    }).map(n => wrapArrays(n, translate)),
  };
};

export const duplicateDataToChild = (root, id, update) => {
  if (root.id === id) {
    const  withDuplicatedDataChildren = root.children.map((child) => {
        if(child.type === WorkflowNodes.INCLUDE) {
            return {
              ...child,
              data: {
                ...child.data,
                filter_by: root.data?.filter_by,
                segment_id: root.data?.segment_id,
                rule_segment_id: root.data?.rule_segment_id,
                aggregates: root.data?.aggregates,
                funnels: root.data?.funnels,
                query: root.data?.query,
              }
            }
        }
        return child;
    });

    return {
      ...root,
      children: withDuplicatedDataChildren,
    }
  }

  return {
    ...root,
    children: (root.children || []).map(child => duplicateDataToChild(child, id, update))
  }
}

export const updateSplitters = (root, id, styles, updateStyles, translate = identity) => {
  if (root.id === id) {
    const segments = styles[root.id].data.segments.map(({ value }) => createNode({ type: 'ab', data: { value, name: value }, actionType: WorkflowNodes.AB_TEST, nodeType: WorkflowNodeTypes.SPLITTER, translate }));

    const segmentsStyles = segments.reduce((acc, [{ id }, style]) => ({ ...acc, [id]: style }), {});

    (root.children || []).filter(({ type }) => type === 'ab').forEach((child, index) => {
      if (segments[index]?.[0]) {
        segments[index][0].children = (child.children || []);
      }
    });

    segments[0][0].children = [...(segments[0][0].children || []), ...(root.children || [])].filter(notEnd).filter(notSplitter);

    updateStyles({ ...styles, ...segmentsStyles });
    return {
      ...root,
      children: segments.map(([node]) => node),
    }
  }

  return {
    ...root,
    children: (root.children || []).map(child => updateSplitters(child, id, styles, updateStyles))
  }
};

const cloneChildren = (children) => {
  return (children || []).map(child => {
    const newId = uuidv4();

    return {
      ...child,
      id: newId,
      data: {
        ...child.data,
        id: newId,
        cloneOf: child.id,
        children: cloneChildren(child.children),
      },
    };
  })
}

export const unwrapArrays = (root, skipClone) => {
  if (!root) {
    return null;
  }

  if (root.data?.children) {
    return unwrapArrays({
      ...root,
      type: null,
      data: null,
      id: null,
      children: root.data.children.map((child, index) => ({
        ...child,
        id: index === 0 ? root.id : child.id,
        data: {
          ...child.data,
          id: index === 0 ? root.id : child.id,
          renderId: index === 0 ? root.renderId : child.renderId,
          serverId: index === 0 ? root.serverId : child.serverId,
          cloneOf: index === 0 ? void 0 : root.id,
          originId: index === 0 ? root.originId : child.originId,
        },
        children: (index === 0 || skipClone) ? root.children : cloneChildren(root.children),
      })),
    })
  }

  if (root.before) {
    const bf = root.before;
    bf.children = root;
    root.before = void 0;

    return unwrapArrays(root.before);
  }

  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.type === 'array') {
        if (child.data.nodes.serverId) {
          return {
            ...child.data.nodes,
            id: child.data.nodes.serverId,
            data: {
              ...(child.data.nodes.data || {}),
              renderId: child.data.nodes.id,
              id: child.data.nodes.serverId,
              arrayStart: true,
            }
          };
        }

        return unwrapArrays({ ...child.data.nodes, data: { ...(child.data.nodes.data || {}), arrayStart: true } });
      }

      if (child.data?.serverId) {
        return { ...child, id: child.data.serverId, data: { ...child.data, renderId: child.data.id, id: child.data.serverId } };
      }

      return child;
    }),
  };
};

const fieldMap = {
  [WorkflowNodes.EMAIL]: 'email_id',
  [WorkflowNodes.SEND_EMAIL]: 'email_id',
  [WorkflowNodes.SEND_SMS]: 'sms_id',
  [WorkflowNodes.WEBPUSH]: 'webpush_id',
  [WorkflowNodes.MOBILE_PUSH]: 'mobile_push_id',
  [WorkflowNodes.API_REQUEST]: 'api_request_id',
  [WorkflowNodes.VIBER]: 'template_id',
}

export const removeDataFields = (root, idMap, onUpdate = identity) => {
  if (root.type in idMap) {
    const updateAt = fieldMap[root.type];

    if (!~idMap[root.type].indexOf(root.data?.[updateAt])) {
      return {
        ...root,
        children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
      };
    }

    onUpdate(root.id);

    return {
      ...root,
      data: {
        ...(root.data || {}),
        [fieldMap[root.type]]: undefined,
      },
      children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
    };
  }

  return {
    ...root,
    children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
  };
};

const SendingNodes = [
  WorkflowNodes.SEND_SMS,
  WorkflowNodes.SEND_EMAIL,
  WorkflowNodes.WEBPUSH,
  WorkflowNodes.MOBILE_PUSH,
  WorkflowNodes.VIBER,
  WorkflowNodes.API_REQUEST,
]

export const updateQuietHours = (root, qhSettings) => {
  if (!root) {
    return root;
  }

  if (!!~SendingNodes.indexOf(root.type)) {
    const [node] = createNode({
      position: { x: 0, y: 0 },
      type: WorkflowNodes.QUIET_HOURS,
      data: qhSettings,
    })

    return {
      ...root,
      before: node,
      children: (root.children || []).map(child => updateQuietHours(child, qhSettings)),
    };
  }

  return {
    ...root,
    children: (root.children || []).map(child => updateQuietHours(child, qhSettings)),
  }
};
