import { notification } from 'antd';
import Cookies from 'js-cookie';
import { mapEntries, pipe } from './functions';
import { moment } from '@utils';
import sha256 from 'sha256';

export const renderProp = (prop, props = {}) => {
  if (!prop) {
    return null;
  }

  return typeof prop === 'function' ? prop(props) : prop;
};

export const mergeMean = (xs) => {
  return xs.reduce((avg, x, i) => {
    return Object.keys(x).reduce((acc, key) => {
      if (typeof x[key] === 'object') {
        return {
          ...acc,
          [key]: mergeMean(xs.map(i => i[key]))
        };
      }

      return {
        ...acc,
        [key]: avg[key] + (x[key] - avg[key]) / (i + 1),
      };
    }, {})
  }, xs[0]);
};

export const styledProp = (name, ifCss, elseCss) => ({ [name]: prop }) => (typeof prop === 'number' ? String(prop) : prop ) ? ifCss : (elseCss || '');

export const switchStyle = (name, map) => ({ [name]: prop }) => map[prop] || map['fallback'] || '';

const sizes = [
  'B',
  'KB',
  'MB',
  'GB'
]

export const formatFileSize = (size, label = 0) => {
  if (size / 1024 < 1) {
    return `${size.toFixed(0)}${sizes[label]}`;
  }

  return formatFileSize(size / 1024, label + 1);
};

export const fileToBinary = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      resolve(target.result);
    }
    reader.onerror = ({ type }) => {
      reject(type);
    }

    reader.readAsBinaryString(file);
  });
};

export const notify = (type, title, message) => notification[type]({
  description: message,
  message: title,
  placement: 'bottom-right',
  closeIcon: (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.20685 9.20677C8.97447 9.44079 8.59613 9.44131 8.36311 9.20793L4.99985 5.83938L1.63642 9.20809C1.40325 9.44163 1.02487 9.44178 0.791515 9.20843C0.558163 8.97507 0.558312 8.59669 0.791847 8.36352L4.16056 5.00009L0.791847 1.63667C0.558312 1.4035 0.558163 1.02511 0.791515 0.79176C1.02487 0.558407 1.40325 0.558556 1.63642 0.792092L4.99985 4.16081L8.36592 0.794736C8.59768 0.562974 8.97344 0.562974 9.2052 0.794736C9.43697 1.0265 9.43697 1.40226 9.2052 1.63402L5.83913 5.00009L9.20537 8.36633C9.43728 8.59824 9.43794 8.97404 9.20685 9.20677Z" fill="#909399"/>
    </svg>
  ),
  icon: ({
    success: (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="#24993E"/>
        <path d="M13.613 5.63676C13.3212 5.36097 12.8636 5.36516 12.5769 5.64624L7.20881 10.9087L5.48809 9.22407C5.20342 8.94536 4.74894 8.94281 4.46116 9.21831C4.15521 9.5112 4.15791 10.0009 4.46707 10.2904L6.51746 12.2104C6.90521 12.5735 7.50916 12.5701 7.89275 12.2026L13.6167 6.71921C13.9252 6.42371 13.9235 5.93018 13.613 5.63676Z" fill="white"/>
      </svg>
    ),
    error: (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="#C8402F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0093 10.0236C10.0093 10.5986 9.54156 11.0663 8.96663 11.0663C8.39169 11.0663 7.92393 10.5986 7.92393 10.0236V5.1428C7.92393 4.56786 8.39169 4.1001 8.96663 4.1001C9.54156 4.1001 10.0093 4.56786 10.0093 5.1428V10.0236ZM10.0068 12.8043C10.0068 13.3793 9.53902 13.847 8.96409 13.847C8.38915 13.847 7.92139 13.3793 7.92139 12.8043C7.92139 12.2294 8.38915 11.7616 8.96409 11.7616C9.53902 11.7616 10.0068 12.2294 10.0068 12.8043Z" fill="white"/>
      </svg>
    )
  })[type],
});

export const dist = (p1, p2) => Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));

export const deepEqual = (o1, o2) => {
  if (o1 === o2) {
    return true;
  }

  if (o1 === null || o2 === null || o1 === undefined || o2 === undefined) {
    return false;
  }

  return Object.keys(o1).every(key => typeof o1[key] === 'object' ? deepEqual(o1[key], o2[key]) : o1[key] === o2[key]);
};

export const diffFields = (o1, o2) => {
  if (o1 === o2) {
    return true;
  }

  if (o1 === null || o2 === null || o1 === undefined || o2 === undefined) {
    return false;
  }

  return Object.keys(o1).reduce((acc, key) => ({
    ...acc,
    [key]: typeof o1[key] === 'object' ? diffFields(o1[key], o2[key]) : o1[key] === o2[key]
  }), {});
};

export const zeroIfNaN = n => isNaN(+n) ? 0 : n;

export const shortNumber = pipe(zeroIfNaN, n => n > 999 ? (n / 1000).toFixed(1) + 'k' : n);

export const formatTranslation = (translation, ...args) => args.reduce((a, b, i) => a.replace(`{{${i + 1}}}`, b), translation);

export const getLocalePrefix = locale => locale.split('-').shift();

export const formatNumberString = (n, locale = 'en-US') => zeroIfNaN(+n).toLocaleString(locale);

export const removeTrailing0 = (n, toFixed = 2) => (+n).toFixed(toFixed).replace(/\.?0+$/, "");

export const formatNumberByK = (n, toFixed = 1, gt = 100) => +n > gt ? (+n / 1000).toFixed(toFixed) + 'k' : +n;

export const stringTemplate = (cfg, scheme) => {
  const parsePart = (acc, [literal, value]) => acc.map(part => {
    if (typeof part === 'string') {
      return part.split(literal).flatMap((sp, i, arr) => i === arr.length - 1 ? [sp] :[sp, value]);
    }
    if (Array.isArray(part)) {
      return parsePart(part, [literal, value]);
    }

    return part;
  });

  return Object.entries(cfg).reduce(parsePart, [scheme]).flat(Object.keys(cfg).length + 1);
}

export const calculateTickAmount = ([d1, d2], md = 12) => {
  const daysDiff = Math.abs(moment(d1).diff(moment(d2), 'days'));
  const monthDiff = Math.abs(moment(d1).diff(moment(d2), 'months'));

  return (daysDiff / monthDiff * (md / 12)).toFixed(0);
};

export const testId = (prefix) => {
  return function self(...parts) {
    const next = (...nextParts) => {
      return self(...parts, ...nextParts);
    };

    next['data-testid'] = [prefix, ...parts].join('-');
    next.toString = function () {
      return this['data-testid'];
    }

    return next;
  };
};

export const formatQueryParams = (params) => {
  return Object.entries(params).map(([key, val]) => {
    if (typeof val === 'object') {
      if (Array.isArray(val)) {
        return val.reduce((acc, part, i) => `${acc}&${key}[${i}]=${part}`, '');
      }

      return Object.entries(val).reduce((acc, [partKey, partValue]) => {
        if (typeof partValue === 'object') {
          return `${acc}&${key}[${partKey}]=${JSON.stringify(partValue)}`;
        }

        return `${acc}&${key}[${partKey}]=${partValue}`;
      }, '')
    }

    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  }).join("&").replace(/%20/g, "+");
};

export const downloadFileFromUrl = (endpoint, params, fname = 'customers.csv') => {
  const link = document.createElement('a');

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    link.href = `https://stage.core.retainly.app${endpoint}?${formatQueryParams(params)}`;
  } else {
    link.href = `${window.location.origin}${endpoint}?${formatQueryParams(params)}`;
  }

  link.setAttribute('download', fname);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const gaFlowEventEvent = (name, event, isLast = false) => {
  const passed = localStorage.getItem(`ga-flow-${name}`);

  if (!passed) {
    window.dataLayer?.push?.({ event });
  }

  if (isLast) {
    localStorage.setItem(`ga-flow-${name}`, 'YES');
  }
};

export const resolveAB = () => {
  const version = Cookies.get('app_version');

  return {
    'stable': 'registration.white',
    'canary': 'registration.white',
  }[version];
};

export const stringParams = (name, obj) => {
  return mapEntries(obj, ([key, val]) => [`${name}[${key}]`, val]);
}

export const tryHumanizeResponse = (r) => {
  try {
    return JSON.stringify(JSON.parse(r), null, 2);
  } catch (e) {
    return r;
  }
}

export const sumOf = (prop) => (acc, { [prop]: v }) => acc + v;

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
};

export const resolveFilters = filters => {
  return Object.keys(filters || {}).reduce?.((a, f) => {
    if (filters[f] === '') {
      return a;
    }

    if (~f.toLocaleLowerCase().indexOf('start_date') && filters[f]) {
      return {
        ...a,
        [f]: moment(filters[f]).startOf('day'),
      };
    }


    if (~f.toLocaleLowerCase().indexOf('end_date') && filters[f]) {
      return {
        ...a,
        [f]: moment(filters[f]).endOf('day'),
      }
    }

    if (f?.startsWith?.('$')) {
      return {
        ...a,
        [f.substring(1)]: filters[f],
      };
    }

    return {
      ...a,
      [f]: filters[f],
    }
  }, {});
};

export const removeLeadingSlash = (str) => {
  if (str.startsWith('/')) {
    return str.slice(1);
  }
  return str;
}

const shortHash = input => {
  return sha256(String(input)).substr(0, 6);
}

export const hashAppId = (appId) => {
  return shortHash(String(appId));
}

export const isSameAppId = (appId, hashStr) => {
  return String(shortHash(String(appId))) === String(hashStr);
};

export const removeAppFromPath = (path) => {
  const match = /^\/app\/([a-zA-Z\d]+)/.exec(path);

  if (match) {
    return path.substr(match[0].length);
  }

  return path;
}

export const tryParse = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    console.log(e);
    return v;
  }
};
