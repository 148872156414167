import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(180deg, #DCDFE6 0%, #CDD3DF 100%);
  border-radius: 9px;
  
  width: 36px;
  height: 36px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 26px;
    height: 26px;
  }
  
  cursor: pointer;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 9px;
  padding: 20px 20px 16px 20px;
  width: 240px;
`;

export const RadioTitle = styled.div`
  font-family: Rubik-Regular;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  margin-bottom: 16px;
`;

export const Label = styled.div`
  font-family: Rubik-Regular;
  font-size: 13px;
  line-height: 100%;
  margin-bottom: 7px;
  color: #909399;
  margin-left: 4px;
`;

