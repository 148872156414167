import React, { useState } from 'react';
import { GroupBackground, GroupContainer } from '../../styled';
import { childHeight, childLength, isPrevGroup } from '@utils';

const GroupBrackets = ({ testId, item, depth, tree, provided }) => {
  // const [dragOver, setDragOver] = useState(false);


  return (
    <GroupContainer
      data-testid={testId}
      key={item.data}
      $prevGroup={isPrevGroup(tree.items, item.data.name)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
        // onDragOver={() => setDragOver(true)}
        // onDragLeave={() => setDragOver(false)}
    >
      <GroupBackground
        // onMouseEnter={console.log}
        // $dragOver={dragOver}
        $depth={depth}
        $height={childHeight(tree.items, item.data.name)}
        $children={childLength(tree.items, item.data.name)}
      />
    </GroupContainer>
  );
};

export default GroupBrackets;
