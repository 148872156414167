import React from 'react';
import { Menu } from 'antd';
import { moment } from '@utils';
import { UserPermissions } from '@constants';

import { MoreButton, WithPermissions } from '@components';

import { Wrapper, Info, Title, Date, MenuItem } from './styled';
import { usePermissions, useTranslation } from '@hooks';
import { useParams } from 'react-router';
import { by, notify, sequence } from '@utils';
import { setFolder } from '@store/actions/creators';
import { useDispatch, useSelector } from 'react-redux';
import { foldersListSelector } from '@store/selectors';

const handleClick = fn => sequence(e => e?.stopPropagation?.(), e => e?.domEvent?.stopPropagation?.(), fn);

const SmallTemplateItem = ({
  title,
  date,
  id,
  used,
  testId,
  workflows,
  onViewClick,
  fromDashboard = false,
  onEditClick,
  onDuplicateClick,
  onStatisticsClick,
  onArchive,
  onUnarchive,
  status,
  type,
  dragging,
  onRefresh,
  refreshFolders,
  onDeleteClick,
  style,
  small,
}) => {
  // const { t, p } = useTranslation('sms_page')
  const { t, p } = useTranslation('templates');
  const { folder } = useParams();
  const dispatch = useDispatch();
  const foldersList = useSelector(foldersListSelector);
  const permissions = usePermissions();

  const handleMoveToFolder = (folder_id) => () => {
    const name = foldersList.data?.find(by(+folder_id))?.name;

    dispatch(setFolder({
      id,
      type,
      folder_id,
      onSuccess: () => {
        notify('success', p('template_moved'), name || p('default'));
        refreshFolders?.();
        onRefresh?.();
      }
    }))
  };

  const archiveDisabled = !!used;
  const deleteDisabled = workflows?.[0] !== undefined;

  const isDragging = dragging?.snapshot?.isDragging;
  const draggingOver = dragging?.snapshot?.draggingOver && dragging.snapshot.draggingOver !== 'self';

  const stopPropagation = e => e.stopPropagation();

  const getCreatePermission = () => {
    switch (type) {
      case 'sms':
        return UserPermissions.CREATE_SMS;
      case 'webpush':
        return UserPermissions.CREATE_WEBPUSHES;
      case 'api_request':
        return UserPermissions.CREATE_API_REQUESTS;
    }
  }

  const getDeletePermission = () => {
    switch (type) {
      case 'sms':
        return UserPermissions.DELETE_SMS;
      case 'webpush':
        return UserPermissions.DELETE_WEBPUSHES;
      case 'api_request':
        return UserPermissions.DELETE_API_REQUESTS;
    }
  }

  const getArchivePermission = () => {
    switch (type) {
      case 'sms':
        return UserPermissions.ARCHIVE_SMS;
      case 'webpush':
        return UserPermissions.ARCHIVE_WEBPUSH;
      case 'api_request':
        return UserPermissions.ARCHIVE_WEBHOOKS;
    }
  }

  const getUnarchivePermission = () => {
    switch (type) {
      case 'sms':
        return UserPermissions.UN_ARCHIVE_SMS;
      case 'webpush':
        return UserPermissions.UN_ARCHIVE_WEBPUSH;
      case 'api_request':
        return UserPermissions.UN_ARCHIVE_WEBHOOKS;
    }
  }

  return (
    <Wrapper
      $isDragging={isDragging}
      $draggingOverFolder={draggingOver}
      data-testid={testId}
      style={style}
      small={small}
      onClick={workflows?.length ? onViewClick : onEditClick}
    >
      <Info small={small}>
        <Title data-testid={`${title}`}>
          {title}
        </Title>

        <Date>{moment(date).format('YYYY-MM-DD')}</Date>
      </Info>
      <div onClick={stopPropagation}>
        <MoreButton
          color="#303133"
          testId={`${testId}-more`}
          getPopupContainer={t => t.parentElement}
          menu={
            <Menu style={{ borderRadius: '10px', overflow: 'hidden' }}>
              {+status !== 3 && (
                <>
                  {/*<Menu.Item key="0">*/}
                  {/*  <WithPermissions name={UserPermissions.REVIEW_SMS}>*/}
                  {/*    <MenuItem data-testid={`${testId}-view`} onClick={sequence(stopPropagation, onViewClick)}>{p('view')}</MenuItem>*/}
                  {/*  </WithPermissions>*/}
                  {/*</Menu.Item>*/}
                  {/*<Menu.Item disabled={workflows?.used === 1} key="1">*/}
                  {/*  <WithPermissions name={UserPermissions.UPDATE_SMS}>*/}
                  {/*    <MenuItem data-testid={`${testId}-edit`} disabled={workflows?.used === 1} onClick={sequence(stopPropagation, onEditClick)}>{t('actions.edit')}</MenuItem>*/}
                  {/*  </WithPermissions>*/}
                  {/*</Menu.Item>*/}
                  <Menu.Item key="2">
                    <WithPermissions name={getCreatePermission()}>
                      <MenuItem data-testid={`${testId}-duplicate`} onClick={sequence(stopPropagation, onDuplicateClick)}>{t('actions.duplicate')}</MenuItem>
                    </WithPermissions>
                  </Menu.Item>
                  {!fromDashboard && (
                    <WithPermissions name={UserPermissions.UPDATE_FOLDER}>
                      <div onClick={e => e.stopPropagation()}>
                        <Menu.SubMenu disabled={folder === 'default' && foldersList.data?.filter?.(({ id }) => String(id) !== String(folder)).length === 0} title={t('actions.move_to_folder')}>
                          {folder !== 'default' && (
                            <Menu.Item onClick={handleClick(handleMoveToFolder(null))}>
                              <MenuItem>{p('all_templates')}</MenuItem>
                            </Menu.Item>
                          )}
                          {foldersList.data?.filter?.(({ id }) => String(id) !== String(folder))?.map?.(({ name, id }) => (
                            <Menu.Item key={id} onClick={handleClick(handleMoveToFolder(id))}>
                              <MenuItem>{name}</MenuItem>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      </div>
                    </WithPermissions>
                  )}
                  {onStatisticsClick && (
                    <Menu.Item key="4">
                      <MenuItem data-testid={`${testId}-stats`} onClick={onStatisticsClick}>{t('actions.statistics')}</MenuItem>
                    </Menu.Item>
                  )}
                </>
              )}
              {(onUnarchive && status === 3) && (
                <Menu.Item key="3">
                  <WithPermissions name={getUnarchivePermission()}>
                    <MenuItem data-testid={`${testId}-delete`} onClick={onUnarchive}>
                      {t('actions.unarchive')}
                    </MenuItem>
                  </WithPermissions>
                </Menu.Item>
              )}
              {(onDeleteClick) && (
                <Menu.Item disabled={used} key="5">
                  <WithPermissions name={+status === 3 ? getDeletePermission() : getArchivePermission()}>
                    <MenuItem data-testid={`${testId}-delete`} disabled={+status === 3 ? deleteDisabled : archiveDisabled} onClick={+status === 3 ? onDeleteClick : onArchive} danger>
                      {+status === 3 ? t('actions.delete') : t('actions.archive')}
                    </MenuItem>
                  </WithPermissions>
                </Menu.Item>
              )}
            </Menu>
          }
        />
      </div>
    </Wrapper>
  )
};

export default SmallTemplateItem;
