import styled, {css} from "styled-components";
import {styledProp} from "@utils";
import {Link} from "@components";

export const ActivityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  ${styledProp('bordered', css`
    border-bottom: 1px solid #DCDFE6;
  `)}
`;

export const Container = styled.div`
  width: 50%;
`;

export const SegmentName = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const DateCell = styled.div`
`;