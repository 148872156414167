import styled from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 9px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: 13px;
  margin-bottom: 16px;
  box-shadow: none !important;
  min-height: 36px;
  height: auto;
  
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}

  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}

  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus-within {
    box-shadow: none !important;
    border-color: #109DFB;
  }
`;
