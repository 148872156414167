import styled, { css } from 'styled-components';
import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #109DFB;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #C1CEFC;
  padding: 14px;
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #109DFB;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ $tab: tab }) => {
    return css`
        width: ${tab?.getBoundingClientRect()?.width}px;
        left: ${tab?.offsetLeft}px;
      `;
  }}
`;

