import React from 'react';

import { Container, Header, IconContainer } from './styled';
import { SidebarSection } from '../SidebarSection';
import {  Icon } from '@components';
import { WorkflowNodeItems } from '@constants';
import { useTranslation } from '@hooks';
import { useSpring } from '@react-spring/web';

const Sidebar = ({ onItemClick, opened, onOpenedChange, entryPointSelected, disabled = false }) => {
  const { p } = useTranslation('workflow_page');

  const handleOpenedChange = () => {
    onOpenedChange(!opened);
  };

  const props = useSpring({ width: opened ? 190 : 42 });

  return (
    <Container disabled={!disabled} style={props}>
      <Header>
        {opened && <div>{p('workflow_components')}</div>}
        <IconContainer
          onClick={handleOpenedChange}
        >
          <Icon size='12' style={{fontSize: '14px', transform: !opened ? 'rotate(180deg)' : '' }} name='Back-arrow'/>
        </IconContainer>
      </Header>
      {WorkflowNodeItems.map((section => (
        <SidebarSection
          preventClick={disabled}
          expand={opened}
          onItemClick={onItemClick}
          disabledTooltip={p(section.disabledTooltip)}
          entryPointSelected={entryPointSelected}
          type={section.type}
          title={section.title}
          options={section.options}
        />
      )))}
    </Container>
  );
};

export default Sidebar;
