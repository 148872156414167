import React, { useState } from 'react';
import { useTranslation } from '@hooks';
import { Dropdown } from 'antd';

import { Container, Overlay, Option, NoFilters } from './styled';

const AddFilterButton = ({ options, onSelect, disabled, noFilters, style }) => {
  const { p } = useTranslation('segments_page');
  const [open, setOpen] = useState(false);

  if (noFilters && disabled) {
    return (
      <NoFilters>
        {p('no_filters_added')}
      </NoFilters>
    );
  }

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      disabled={disabled}
      trigger={['click']}
      overlay={(
        <Overlay>
          {options.map(({ value, label, type }) => (
            <Option key={value} onClick={() => (onSelect(value, type), setOpen(false))}>
              {label}
            </Option>
          ))}
        </Overlay>
      )}
    >
      <Container $d={disabled} style={style}>
        {p('add_filter_plus')}
      </Container>
    </Dropdown>
  );
};

export default AddFilterButton;
