import { SystemEvents } from '@constants/systemEvents';

export const EventPresets = [
  {
    events: [SystemEvents.VIBER, SystemEvents.EMAIL, SystemEvents.SMS, SystemEvents.MOBILE_PUSH, SystemEvents.WEBPUSH],
    presetFields: ['_te', '_s'],
  },
  {
    events: [SystemEvents.FIRST_SESSION, SystemEvents.START_SESSION, SystemEvents.END_SESSION],
    presetFields: ['_de', '_d', '_br', '_os'],
  },
  {
    events: [SystemEvents.PAGE_VIEW],
    presetFields: ['_d', '_u'],
  },
  {
    events: [SystemEvents.RFM_SEGMENT_TRANSITION],
    presetFields: ['ri', 'rs', 'rps'],
  },
];
