const EventsActionTypes = {
  LIST: 'events/LIST',
  LIST_SUCCESS: 'events/LIST_SUCCESS',
  LIST_ERROR: 'events/LIST_ERROR',

  CREATE: 'events/CREATE',
  CREATE_SUCCESS: 'events/CREATE_SUCCESS',
  CREATE_ERROR: 'events/CREATE_ERROR',

  EDIT: 'events/EDIT',
  EDIT_SUCCESS: 'events/EDIT_SUCCESS',
  EDIT_ERROR: 'events/EDIT_ERROR',


  DELETE: 'events/DELETE',
  DELETE_SUCCESS: 'events/DELETE_SUCCESS',
  DELETE_ERROR: 'events/DELETE_ERROR',
};

export default EventsActionTypes;
