import { by, extract, identity } from '@utils';
import { WorkflowEdgeTypes, WorkflowNodeItems, WorkflowNodes, WorkflowNodeTypes } from '@constants';
import { v4 as uuidv4 } from 'uuid';

const nodeOptions = WorkflowNodeItems.flatMap(extract('options'));

const DEFAULT_VALID = [WorkflowNodes.CONTACT_CREATED, WorkflowNodes.AB_SPLITTER];

export const createNode = (node, skipValidated = false, p = identity) => {
  if (node.data.name === WorkflowNodes.AB_SPLITTER) {
    node.data = {
      segments: [{ id: uuidv4(), value: 50, label: 'A' }, { id: uuidv4(), value: 50, label: 'B' }],
      ...node.data,
    };
  }

  if (node.data.name === WorkflowNodes.CUSTOMER_CHANGE) {
    node.data = {
      ...node.data,
      field_values: !Array.isArray(node.data.field_values) ? Object.entries(node.data.field_values || {}).map(([field, value]) => ({ field, value })) : node.data.field_values,
    };
  }

  if (node.data.name === WorkflowNodes.WAIT) {
    node.data = {
      // time: moment().set('hours', 0).set('minutes', 0).set('seconds', 0),
      ...node.data,
    };
  }

  return {
    type: WorkflowNodeTypes.DEFAULT,
    position: { x: 150, y: 150 },
    ...node,
    ...(node?.position ? {
      position: { x: +node?.position?.x, y: +node.position.y },
    } : {}),
    data: {
      originId: uuidv4(),
      validated: skipValidated ? undefined : DEFAULT_VALID.indexOf(node.data.name) !== -1,
      label: p(nodeOptions.find(by('name', node.data?.name))?.title),
      ...node.data
    }
  };
};

export const createSplitterNode = (node) => {
  return {
    type: WorkflowNodeTypes.SPLITTER,
    position: { x: 150, y: 150 },
    ...node,
    ...(node?.position ? {
      position: { x: +node?.position?.x, y: +node.position.y },
    } : {}),
    data: {
      ...(node?.data || {}),
      id: node?.id || node?.data?.id,
      originId: uuidv4()
    },
  };
};

export const createEdge = (edge) => {
  return {
    sourceHandle: 'b',
    targetHandle: 'a',
    style: { stroke: '#909399', zIndex: 10 },
    type: 'default_edge',
    data: { displayIndicator: false },
    id: uuidv4(),
    ...edge,
  }
};

export const createFinishNode = (node) => {
  return {
    type: WorkflowNodeTypes.FINISH,
    style: { },
    ...node,
    ...(node?.position ? {
      position: { x: +node?.position?.x, y: +node.position.y },
    } : {}),
    data: {
      name: WorkflowNodes.END_PATH,
      ...node.data
    },
  };
}

export const parseNode = (node, p = identity) => {
  const { data, position, id, type } = node;

  switch (type) {
    case WorkflowNodeTypes.DEFAULT:
      return createNode({ data, position, id: data?.id || id, type }, true, p);
    case WorkflowNodeTypes.SPLITTER:
      return createSplitterNode({ data, position, id: data?.id || id, type }, false, p);
    case WorkflowNodeTypes.FINISH:
      return createFinishNode({ data, position, id: data?.id || id, type }, false, p);
    case WorkflowEdgeTypes.DEFAULT:
    default:
      return createEdge(node, false, p);
  }
}

export const parseEdge = (edge) => {
  return createEdge(edge);
};
