import React from 'react';
import { useTransition, config } from '@react-spring/web';
import { useModalState, useTranslation } from '@hooks';
import * as clipboard from 'clipboard-polyfill';

import { Container, Message } from './styled';

const CopyHandle = ({ data, children, align, message }) => {
  const { t } = useTranslation();
  const m = message || t('labels.copied');
  const popoverVisible = useModalState(false);

  const transition = useTransition(popoverVisible.opened, {
    from: { opacity: 0, scale: 0.5 },
    enter: { opacity: 1, scale: 1.3 },
    leave: { opacity: 0, scale: 0.5 },
    config: config.stiff,
    onRest: popoverVisible.close
  });

  const handleCopy = async () => {
    popoverVisible.open();
    await (new clipboard.writeText(data));
  };

  return (
    <Container $align={align} onClick={handleCopy}>
      {transition(({ opacity }, shown) => shown && (
        <Message style={{ opacity, transform: opacity.to({ range: [1.0, 0.0], output: [0, 10] }).to(y => `translate3d(0, ${y}px,0)`) }}>
          {m}
        </Message>
      ))}
      {children}
    </Container>
  );
};

export default CopyHandle;
