import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Icon, WithOnboarding, OnboardingHighlight } from '@components';
import { WorkflowIcons } from '@constants';
import WorkflowEditorContext from '../../../../../contexts/WorkflowEditorContext/WorkflowEditorContext';
import { workflowOptionsSelector } from '@store/selectors';
import {
  NodeAnalytics,
  NodeSettingsDropdown,
} from '@components/lib/WorkflowEditor/components';

import { Container, NodeThumbnail, Title, NotValidatedIcon, NodeAnalyticsContainer, StyledHandle } from './styled';
import { WorkflowDiffContext } from '../../../../../contexts';
import { useOnboarding, useTranslation } from '@hooks';
import { by, testId } from '@utils';
import { isNode } from 'reactflow';

const Node = ({ id, data, selected }) => {
  const options = useSelector(workflowOptionsSelector);
  const diff = useContext(WorkflowDiffContext);
  const editor = useContext(WorkflowEditorContext);
  const onboarding = useOnboarding('workflows');
  const { p } = useTranslation('workflow_page');

  const handleDoubleClick = () => {
    if (onboarding.enabled(3) && data.actionType === 'entry_point') {
      onboarding.nextStep();
    }

    if (data.diff) {
      return diff.setOpenedNodeDiff(id);
    }

    return editor.setOpenedNodeSettings(id);
  };

  return (
    <>
    <NodeSettingsDropdown
      node={{ data, id }}
      options={options}
      forceClose={editor.openedNodeSettings || editor.openedNodeDiff}
    >
      <WithOnboarding enabled={onboarding.enabled(3) && data.actionType === 'entry_point'} onboardKey="workflows" guideLink="workflow-creation/" text={p('double_click_to_setup')}>
        <OnboardingHighlight on={onboarding.enabled(3) && data.actionType === 'entry_point'}>
          <Container
            {...testId(`workflow-node-${(editor.renderNodes || []).filter(isNode).findIndex(by('id', data.id))}`)()}
            data-testNodeName={data.label}
            disabled={data.diff?.disabled}
            highlight={data.diff?.type}
            onDoubleClick={handleDoubleClick}
            key={id}
            selected={selected}
            temp={data.temp}
          >
            {data.analytics && (
              <NodeAnalyticsContainer>
                <NodeAnalytics
                  data={{
                    ...(data.analytics || {}),
                  }}
                  testId={`workflow-node-${(editor.renderNodes || []).filter(isNode).findIndex(by('id', data.id))}`}
                  type={data.name}
                />
              </NodeAnalyticsContainer>
            )}
            <NodeThumbnail type={data.actionType}>
              {data.actionType !== 'entry_point' && (
                <StyledHandle
                  type="target"
                  position="left"
                  id="a"
                  isConnectable
                  // style={{ top: '50%' }}
                >
                  <Icon name="Plus-icon" size={4} color="#fff" style={{ lineHeight: 4, transform: 'translateY(0.3px)'  }} />
                </StyledHandle>
              )}
              <StyledHandle
                type="source"
                position="right"
                id="b"
                isConnectable
                style={{ pointerEvents: data?.name === 'filter' || data.name === 'splitter' ? 'none' : 'auto' }}
              >
                <Icon name="Plus-icon" size={4} color="#fff" style={{ lineHeight: 4, transform: 'translateY(0.3px)' }} />
              </StyledHandle>
              <Icon size={20} name={WorkflowIcons[data.name]} color="#fff" />
            </NodeThumbnail>
            <Title>{data.label}</Title>
          </Container>
          {data.validated === false && (
            <NotValidatedIcon>
              <Icon size={8} name="info-symbol-1" color="#fff" />
            </NotValidatedIcon>
          )}
        </OnboardingHighlight>
      </WithOnboarding>
    </NodeSettingsDropdown>
  </>
  );
};

export default Node;
