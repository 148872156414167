import React, { useState, useEffect } from 'react';
import { useBreakpoint, useErrors, useGAPageTracking, useNavigate } from '@hooks';
import { TimezoneSelect, Input, Button } from '@components';
import logo from '@res/images/logo-dark-text.svg';

import { QuestionnaireForm } from './components';
import { Image, Container, InnerContainer, Title } from './styled';
import { sequence } from '@utils';
import { useDispatch } from 'react-redux';
import {
  getUser,
  startTrial,
  submitQuestionnaire
} from '@store/actions/creators';
import { StringParam, useQueryParam } from 'use-query-params';
import { Paths } from '@constants';
import { useDebouncedCallback } from 'use-debounce';
import useHotjar from '@hooks/useHotjar';
import { PhoneInput } from '../WhiteRegistrationPage/components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const WhiteCreatePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [step, setStep] = useState(1);
  const errors = useErrors([], [429]);
  const [isQuestionnaire, setIsQuestionnaire] = useState(false);
  const [brand, setBrand] = useState('');
  const [phone, setPhone] = useState('');
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [answers, setAnswers] = useState({});
  const [email] = useQueryParam('email', StringParam);
  const [hash] = useQueryParam('hash', StringParam);
  const isMobile = useBreakpoint(500);

  useGAPageTracking();
  useHotjar();

  useEffect(() => {
    dispatch(getUser({
      onSuccess: (user) => {
        if (user?.data?.registration_step === 2) {
          setIsQuestionnaire(true);
        }
      }
    }));
  }, []);

  const handleChange = (onChangeFn, name) => ({ target }) => {
    errors.clearError(name);
    onChangeFn(target.value);
  };

  const handleTimezoneChange = (v) => {
    errors.clearError('timezone');
    setTimezone(v);
  };

  const handlePhoneChange = (phone) => {
    errors.clearError('number');
    setPhone(phone);
  };

  const handleCreatePasswordSubmit = useDebouncedCallback(async (event) => {
    event.preventDefault();

    errors.clear();

    if (brand.length < 2) {
      errors.setError('app_name', 'Min length is 2 characters');
    }

    if (brand.length > 20) {
      errors.setError('app_name', 'Max length is 20 characters');
    }

    if (!/^[a-zA-Z\d\s]+$/.test(brand.trim())) {
      errors.setError('app_name', 'Only latin letters and digits are allowed');
    }

    if (phone.length < 8 || phone.length > 20) {
      errors.setError('number', 'Invalid phone number');
    }

    if (!brand) {
      errors.setError('app_name', 'Company name is required');
    }

    if (brand.length < 2 || brand.length > 20 || !/^[a-zA-Z\d\s]+$/.test(brand) || phone.length < 8 || phone.length > 16) {
      return;
    }
    const token = await executeRecaptcha();

    dispatch(startTrial({
      email,
      timezone,
      hash,
      token,
      number: phone,
      app_name: brand,
      onSuccess: () => {
        // window.gtag?.('event', 'register_second_step', {
        //   event_category: 'registration',
        //   event_label: 'Registration: complete second step'
        // });
        window.dataLayer?.push?.({ event: 'register_second_step' });
        setIsQuestionnaire(true);
      },
      onError: sequence(errors.handleRequestError),
    }))
  }, 1000, { leading: true, trailing: false });

  const handleSubmitQuestionnaire = async (isSkip) => {
    const token = await executeRecaptcha();

    dispatch(submitQuestionnaire({
      token,
      questionnaire: Object.entries(answers || {}),
      onSuccess: () => {
        // window.gtag?.('event', 'questionnaire_pass', {
        //   event_category: 'registration',
        //   event_label: 'Registration: questionnaire pass'
        // });
        if (!isSkip) {
          window.dataLayer?.push?.({ event: 'questionnaire_pass' });
        }
        navigate(`${Paths.DASHBOARD}?welcome`);
      }
    }))
  };

  const handleSkipQuestionnaire = () => {
    // window.gtag?.('event', 'questionnaire_skip', {
    //   event_category: 'registration',
    //   event_label: 'Registration: questionnaire skip'
    // });
    window.dataLayer?.push?.({ event: 'questionnaire_skip' });

    handleSubmitQuestionnaire();

    try {
      document.body.removeChild(document.getElementById('hotjar_connection'));
    } catch (e) {}
    // navigate(`${Routes.DASHBOARD}?welcome`);
  };

  return (
    <Container>
      <Image src={logo} alt="logo" />
      {!isQuestionnaire && (
        <form onSubmit={e => {
          e.preventDefault();
          handleCreatePasswordSubmit(e);
        }}>
          <InnerContainer>
            <Title>Creating your account</Title>
            <Input
              containerStyle={{ marginBottom: isMobile ? 24 : 36, width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              style={{ width: '100%' }}
              type="text"
              error={errors.errors['app_name']}
              data-testid="reg-app"
              placeholder="Enter your company name"
              autoComplete="off"
              onChange={handleChange(setBrand, 'app_name')}
              title="Company name"
            />
            <PhoneInput
              value={phone}
              component={Input}
              wrapperStyles={{ width: '100%' }}
              title="Phone number"
              data-testid="reg-in-phone"
              placeholder="Enter your phone number"
              error={errors.errors?.['number']}
              containerStyle={{ marginBottom: isMobile ? 24 : 36 }}
              onChange={handlePhoneChange}
            />
            <TimezoneSelect
              theme="dark"
              showInfoIcon
              hint="You can change this settings later"
              dropdownProps={{ overlayClassName: 'show-scrollbar' }}
              label="Choose timezone"
              tooltipColor="#909399"
              tooltip="Set the time zone according
 to which notifications
will be sent to your customers"
              error={errors.errors['timezone']}
              wrapperStyles={{ width: '258px' }}
              onChange={handleTimezoneChange}
              value={timezone}
              style={{ width: '100%' }}
            />
            <Button
              data-testid="reg-submit"
              style={{ marginTop: '50px', justifyContent: 'center' }}
              htmlType="submit"
            >
              Submit
            </Button>
          </InnerContainer>
        </form>
      )}
      {isQuestionnaire && (
        <form onSubmit={e => e.preventDefault()}>
          <InnerContainer style={{ minHeight: 480 }}>
            <Title style={{ marginBottom: 40 }}>You're almost here!</Title>
            <QuestionnaireForm
              value={answers}
              onChange={setAnswers}
              onSubmit={handleSubmitQuestionnaire}
              onSkip={handleSkipQuestionnaire}
            />
          </InnerContainer>
        </form>
      )}
    </Container>
  );
}

export default WhiteCreatePasswordPage;


