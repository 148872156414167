import { ReducerKeys } from '@constants';

export const workflowListSelector = store => store[ReducerKeys.WORKFLOW].workflows;

export const workflowOptionsSelector = store => store[ReducerKeys.WORKFLOW].options;

export const workflowEditMetadataSelector = store => store[ReducerKeys.WORKFLOW].editMetadata;

export const workflowAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].analytics;

export const workflowProgramAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].programAnalytics;

export const workflowNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].nodeAnalytics;

export const workflowPublishHistorySelector = store => store[ReducerKeys.WORKFLOW].publishHistory;

export const defaultNodesSelector = store => store[ReducerKeys.WORKFLOW].defaultNodes;

export const workflowLogsSelector = store => store[ReducerKeys.WORKFLOW].workflowLogs;

export const smsNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].smsNodeAnalytics;

export const emailNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].emailNodeAnalytics;

export const webpushNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webpushNodeAnalytics;

export const viberNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].viberNodeAnalytics;

export const mobilePushNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].mobilePushAnalytics;

export const webhookNodeAnalyticsSelector = store => store[ReducerKeys.WORKFLOW].webhookNodeAnalytics;

export const workflowProgramSelector = store => store[ReducerKeys.WORKFLOW]?.program;

export const workflowWorkerSelector = store => store[ReducerKeys.WORKFLOW]?.workers;

export const workflowEntryCountSelector = store => store[ReducerKeys.WORKFLOW]?.entry_count;
