import React, { Component } from 'react';
import Cookies from 'js-cookie';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const isReloadDelay = Cookies.get('reload_delay'); 
  
    if (error?.name === "ChunkLoadError" && !isReloadDelay) {
      let date = new Date();
      date.setTime(date.getTime() + (60 * 1000)); //for a 1 min
      Cookies.set('reload_delay', true , { expires: date }); 
      window.location.reload();
    }
  }


  render() {
    if (this.state.hasError) {
      return (//TODO: need UI Stab
       <h1>
        ...Oops, Something went wrong, please try to reload page
       </h1>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
