import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #1F1F24;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 22px;
  left: 20px;
`;

export const ProgressIndicatorContainer = styled.div`
  position: absolute;
  top: 48px;
  bottom: 48px;
  right: 40px;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 80px;
`;

export const InnerContainer = styled.div`
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 110px;
`;
