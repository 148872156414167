import React, { useEffect } from 'react';

import { CreateDemoWorkspaceModal, NotificationsList, Sidebar } from '@components';
import sidebarRoutes from '../../sidebarRoutes';
import {
  addNotification,
  getDemoWorkspaces, getNotifications,
  getUser,
  listWorkspaces,
  setViewDemoWorkspacesModalOpened,
  switchToDemoWorkspace, switchWorkspaces
} from '@store/actions/creators';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSelector } from '@store/selectors/notifications';
import {
  accessTokenSelector,
  demoWorkspacesSelector,
  loggedInSelector, sidebarOpenedSelector,
  viewDemoWorkspacesModalOpenedSelector
} from '@store/selectors';
import { useModalState, useNavigate, useTranslation } from '@hooks';
import { Paths } from '@constants';
import initPusher from '@utils/initPusher';
import { translateNotification } from '@components/lib/NotificationsList/components/NotificationItem/NotificationItem';
import { notify, testId } from '@utils';
import { SystemNotificationBody } from '@components/lib/NotificationsList/components/SystemNotificationBody';
import { Outlet, useLocation } from 'react-router';
import { PageContent, Container } from './styled';

const LayoutWithSidebar = () => {
  const dispatch = useDispatch();
  const opened = useSelector(sidebarOpenedSelector);
  const viewDemoWorkspacesModalOpened = useSelector(viewDemoWorkspacesModalOpenedSelector);
  const welcome = window.location.search.indexOf('welcome') !== -1;
  const notificationsState = useModalState(false);
  const navigate = useNavigate();
  const demoWorkspaces = useSelector(demoWorkspacesSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { p } = useTranslation('system_notifications');
  const user = useSelector(loggedInSelector);
  const location = useLocation();
  const hideSidebar = ~location.pathname.indexOf('/workflows/templates') || ~location.pathname.indexOf('/workflows/configure')

  useEffect(() => {
    if (user?.id) {
      dispatch(getNotifications());
      initPusher({
        accessToken,
        onReceiveNotification: (notification) => {
          const composedNotification = { ...notification, ...notification.data, data: notification.data };
          dispatch(addNotification(composedNotification));

          const handleNavigate = (path) => {
            if (user?.app?.id === composedNotification.data.app.app_id) {
              navigate(path);
            } else {
              dispatch(switchWorkspaces({
                id: composedNotification.data.app.app_id,
                onSuccess: () => {
                  navigate(path);
                }
              }))
            }
          }

          const { body, title } = translateNotification(composedNotification, p, handleNavigate);
          if (!body || !title) return;
          notify(composedNotification.status === 'success' ? 'success' : 'error', title, <SystemNotificationBody tid={testId('websocket-push')} style={{ marginLeft: -5 }} body={body} />);
        },
        user,
      })
    }
  }, []);

  const handleViewDemoWorkspace = ({ app_id }) => {
    dispatch(switchToDemoWorkspace({
      app_id,
      onSuccess: () => {
        dispatch(getUser());
        dispatch(listWorkspaces());
        dispatch(getDemoWorkspaces());
        navigate(Paths.DASHBOARD, { replace: true });
        dispatch(setViewDemoWorkspacesModalOpened({ opened: false }));
      }
    }));
  };
  const notifications = useSelector(notificationsSelector);

  return (
    <Container>
      {!hideSidebar && (
        <>
          <Sidebar
            notificationsOpened={notificationsState.opened}
            toggleNotifications={notificationsState.toggle}
            routes={sidebarRoutes}
            notifications={notifications}
          />
          <NotificationsList
            close={notificationsState.close}
            opened={notificationsState.opened}
            notifications={notifications}
          />
          <CreateDemoWorkspaceModal
            opened={viewDemoWorkspacesModalOpened}
            onClose={() => {
              // navigate(Routes.DASHBOARD, { replace: true });
              dispatch(setViewDemoWorkspacesModalOpened({ opened: false }));
            }}
            options={demoWorkspaces?.data || []}
            welcome={welcome}
            onSelect={handleViewDemoWorkspace}
          />
        </>
      )}
      <PageContent sidebarOpened={opened} $hideSidebar={hideSidebar}>
        <Outlet />
      </PageContent>
    </Container>
  )
};

export default LayoutWithSidebar;
