import styled from "styled-components";
import {Text} from "@components";
import {TextVariants} from "@constants";
import {styledProp} from "@utils";

export const TemplatesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const TemplatesTitle = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin-bottom: 12px;
`;

export const TemplatesCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TemplatesContainer = styled.div`
  display: flex;
  flex: 1;
  
  ${styledProp('onlyEmails', `
    display: block;
  `)}
`;

