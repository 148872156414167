import React, { useEffect, useState } from 'react';
import { Button, Modal, TimePicker } from '@components';
import { useTranslation } from '@hooks';
import { moment, sequence } from '@utils';

import { Container, Disclaimer, Error } from './styled';

const UTCToLocalMoment = (time) => {
  if (!time) {
    return null;
  }

  const [h, m] = time.split(':');
  return moment.utc().set('hours', h).set('minutes', m).add(moment().utcOffset(), 'minutes');
};

const localMomentToUTC = (localMoment) => {
  if (!localMoment) {
    return null;
  }

  return localMoment.add(-1 * moment().utcOffset(), 'minutes').format('HH:mm');
};

const QuiteHoursSettingsModal = ({ opened, onClose, value, disabled, onChange }) => {
  const { t, p, e } = useTranslation('workflow_page');
  const [start, setStart] = useState(value.start);
  const [end, setEnd] = useState(value.end);
  const [error, setError] = useState('');

  useEffect(() => {
    setStart(UTCToLocalMoment(value.start));
    setEnd(UTCToLocalMoment(value.end));
  }, [[value.start, value.end].join()]);

  const handleSave = () => {
    if ((!start ? 1 : 0) ^ (!end ? 1 : 0)) {
      return setError('booth_fields_should_be_filed_or_empty');
    }

    onChange({
      start: localMomentToUTC(start),
      end: localMomentToUTC(end)
    });
    onClose();
  };

  const handleClose = () => {
    setStart(value.start);
    setEnd(value.end);
    onClose();
  };

  return (
    <Modal
      title={p('quite_hours')}
      opened={opened}
      onClose={handleClose}
      actions={(
        <>
          <Button width={140} onClick={handleClose} appearance="secondary">{t('actions.cancel')}</Button>
          <Button width={140} disabled={!!error || disabled} onClick={handleSave}>{t('actions.save')}</Button>
        </>
      )}
    >
      <Container>
        <Disclaimer>
          {p('quite_hours_disclaimer')}
        </Disclaimer>
        <TimePicker
          title={p('start_of_qh')}
          value={start}
          disabled={disabled}
          onChange={sequence(setStart, () => setError(''))}
          format={"HH:mm"}
          style={{ marginBottom: 30 }}
        />
        <TimePicker
          title={p('end_of_qh')}
          value={end}
          disabled={disabled}
          format={"HH:mm"}
          onChange={sequence(setEnd, () => setError(''))}
          style={{ marginBottom: 30 }}
        />
        {error && <Error>{e(error)}</Error>}
      </Container>
    </Modal>
  );
};

export default QuiteHoursSettingsModal;
