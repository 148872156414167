import dagre from 'dagre';
import { getConnectors, iterate } from '@utils/workflows/refactored/structureParser';
import { zeroIfNaN } from '@utils';

const width = 94;
const height = 70;
const direction = 'LR';

export const layoutFlow = (root, styles) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep: 16, align: 'UL', nodesep: 40 });

  iterate(root, ({ id }) => {
    dagreGraph.setNode(id, { width, height });
  })
  getConnectors(root).forEach(connector => dagreGraph.setEdge(connector.source, connector.target));

  dagre.layout(dagreGraph);

  return {
    ...styles,
    ...Object.fromEntries(dagreGraph.nodes().map((id) => {
      const style = styles[id] || {};
      const dagreNode = dagreGraph.node(id);

      return [
        id,
        {
          ...style,
          targetPosition: isHorizontal ? 'left' : 'top',
          sourcePosition: isHorizontal ? 'right' : 'bottom',
          position: {
            x: zeroIfNaN(dagreNode?.x) - width / 2 + Math.random() / 1000,
            y: zeroIfNaN(dagreNode?.y) - height / 2,
          },
        }
      ];
    }))
  }
}
