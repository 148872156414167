import { all, takeLatest, put } from 'redux-saga/effects';
import { NotificationsActionTypes } from '../../actions/types';
import { Endpoints } from '@constants';
import { createRequestAction } from '@store/lib/utils/creators';

function* getNotificationsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATIONS,
  }));
}

function* markAsReadSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.NOTIFICATIONS_MARK_AS_READ,
    body: {
      ids: action.payload.ids,
    }
  }));
}


export default function* contactsRootSaga() {
  yield all([
    takeLatest(NotificationsActionTypes.GET_NOTIFICATIONS, getNotificationsSaga),
    takeLatest(NotificationsActionTypes.MARK_AS_READ, markAsReadSaga),
  ]);
}
