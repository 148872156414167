import styled from 'styled-components';

export const Container = styled.div``;


export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #dcdfe6;
`;

export const StepContainer = styled.div`
  position: relative;
`;

export const DeleteStepButton = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 22px;
  height: 22px;
  border: 1px solid #C8402F;
  border-radius: 6px;
`;
