import React, { useState } from 'react';

import { Container, LogoContainer, InnerContainer, Title, ActionsRow, ProgressIndicatorContainer } from './styled.js';
import { Logo, QuestionnaireSlider, ProgressIndicator } from './components';
import { Button, Input } from '../RegistrationPage/components';
import { Paths } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { submitTrialQuestionnaire } from '@store/actions/creators';
import { userSelector } from '@store/selectors';
import { by } from '@utils';
import { useNavigate } from '@hooks';

const questions = [
  {
    title: 'Please rate your overall experience using Retainly?',
    type: 'rate',
  },
  {
    title: 'How easy was it to use Retainly?',
    type: 'rate',
  },
  {
    title: 'What do you like the most from  Retainly?',
    type: 'answer',
  },
  {
    title: 'What task or issue you’ve tried to solve with Retainly?',
    type: 'answer',
  },
  {
    title: <>Please share the challenges you'd like Retainly <br/>to solve in future</>,
    descriptor: 'Please share the challenges you\'d like Retainly to solve in future',
    type: 'answer',
  },
];

const QuestionnairePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState(0);
  const [values, setValues] = useState(questions.map(q => q.type === 'rate' ? 1 : ''));
  const canNext = question < questions.length - 1;
  const canPrevious = question > 0;
  const [error, setError] = useState('');
  const user = useSelector(userSelector);

  const handleNext = () => {
    if (questions[question].type === 'answer' ? !values[question].trim() : !values[question]) {
      return setError('This field is required');
    }
    setQuestion(q => q + 1);
  };

  const handlePrevious = () => {
    setError('');
    setQuestion(q => q - 1);
  };

  const handleSliderValueChange = (value) => {
    setValues(vs => vs.map((v, i) => i === question ? value : v));
  };

  const handleInputValueChange = ({ target: { value } }) => {
    setError('');
    setValues(vs => vs.map((v, i) => i === question ? value : v));
  };

  const handleSubmit = () => {
    if (!values[question]) {
      return setError('This field is required');
    }

    const firstPassed = user?.questionnaires?.find(by('name', 'trial_first'))?.passed;

    dispatch(submitTrialQuestionnaire({
      type: firstPassed ? 'trial_second' : 'trial_first',
      answers: values.map(( value, index) => ({
        [questions[index].descriptor || questions[index].title]: value,
      })),
      onSuccess: () => {
        navigate(Paths.QUESTIONNAIRE_SUCCESS);
      }
    }))
  };

  return (
    <Container>
      <svg style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grid" width="50" height="50" patternUnits="userSpaceOnUse">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="49.5" y1="2.18556e-08" x2="49.5" y2="50" stroke="#28282D"/>
              <line x1="8.74228e-08" y1="49.5" x2="50" y2="49.5" stroke="#28282D"/>
            </svg>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>

      <ProgressIndicatorContainer>
        <ProgressIndicator progress={question} max={questions.length - 1} />
      </ProgressIndicatorContainer>

      <LogoContainer>
        <Logo />
      </LogoContainer>

      <InnerContainer>
        <Title>{questions[question].title}</Title>
        {questions[question].type === 'rate' && (
          <QuestionnaireSlider
            value={values[question]}
            onChange={handleSliderValueChange}
          />
        )}
        {questions[question].type === 'answer' && (
          <Input
            title="Your answer"
            containerStyle={{ width: 485 }}
            placeholder="Your answer"
            error={error}
            value={values[question]}
            onChange={handleInputValueChange}
          />
        )}
      <ActionsRow>
        {canPrevious && (
          <Button style={{ width: 200, marginRight: 15 }} appearance="secondary" onClick={handlePrevious}>Previous question</Button>
        )}
        {canNext ? (
          <Button style={{ width: 200, marginLeft: 15 }} onClick={handleNext}>Next</Button>
        ) : (
          <Button style={{ width: 200, marginLeft: 15 }} onClick={handleSubmit}>Complete questionnaire</Button>
        )}
      </ActionsRow>
      </InnerContainer>
    </Container>
  );
}

export default QuestionnairePage;


