import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga-gtm';
import { Tooltip } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useErrors, useGAPageTracking, useModalState } from '@hooks';
import { register, resendRegistrationEmail } from '@store/actions/creators';
import { isRequired, maxLength, minLength } from '@utils/validators';
import { extract, identity, notify } from '@utils';
import { useDebouncedCallback } from 'use-debounce';
import useHotjar from '@hooks/useHotjar';
import { Paths } from '@constants';
import { TooltipContainer, TooltipItem, TooltipTitle } from '../WhiteCreatePasswordPage/styled';

import { useNavigate } from '@hooks';
import { Input, Button, SuccessModal } from './components';
import { Container, FormContainer, Form, ImageContainer, Image, Hint, Title, LoginHint, LoginLink } from './styled.js';
import image from '@res/images/registration.png';

const validationConfig = {
  name: [
    isRequired,
    minLength(2, 'Name should be at least 2 characters long'),
    maxLength(20, 'Name should be no more than 20 characters long'),
    name => !(/^[a-zA-Z\s]+$/.test(name.trim())) && 'Only latin characters allowed'
  ],
  surname: [
    isRequired,
    minLength(2, 'Name should be at least 2 characters long'),
    maxLength(20, 'Name should be no more than 20 characters long'),
    name => !(/^[a-zA-Z\s]+$/.test(name.trim())) && 'Only latin characters allowed'
  ],
  email: [
    isRequired,
    maxLength(50, 'Email should be less than 50 characters long'),
    name => name.match(/[а-яА-Я]+/g) !== null && 'Only latin characters allowed',
    name => (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(name)) || ~name.indexOf('+')) && 'Invalid email'
  ],
};

const passwordValidation = [
  {
    tooltip: 'min 6 - max 32 characters',
    matcher: v => v?.length < 33 && v?.length >= 6,
  },
  {
    error: 'At least 6 characters required',
    matcher: v => v?.length > 5,
  },
  {
    error: 'No more than 32 characters allowed',
    matcher: v => v?.length < 33,
  },
  {
    error: 'No spaces allowed',
    matcher: v => !v?.includes(' '),
  },
  {
    tooltip: '1 lowercase(s) (a-z)',
    error: 'At least one lowercase letter required',
    matcher: v => /[a-z]/.test(v),
  },
  {
    tooltip: '1 uppercase(s) (A-Z)',
    error: 'At least one uppercase letter required',
    matcher: v => /[A-Z]/.test(v),
  },
  {
    tooltip: '1 number(s) (0-9)',
    error: 'At least one number required',
    matcher: v => /\d/.test(v),
  },
  {
    tooltip: '1 special character(s) (#)',
    error: 'At least one special character required',
    matcher: v => /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(v),
  },
];

const validate = (name, value) => validationConfig[name].map(validate => validate(value)).find(identity);

const WhiteRegistrationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [wantToReceiveOffers, setWantReceiveOffers] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(true);
  const [passwordValidationVisible, setPasswordValidationVisible] = useState(false);
  const { setError, errors, handleRequestError, clearError, clear } = useErrors([], [429]);
  const successModal = useModalState();
  const resendErrors = useErrors();

  useGAPageTracking();
  useHotjar();

  useEffect(() => {
    document.body.appendChild((() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'facebookAnalytic';
      script.innerHTML = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '252045463659122');
        fbq('track', 'PageView');`;

      return script;
    })());

    return () => {
      // document.removeChild(document.querySelector('#facebookAnalytic'));
    }
  }, []);

  const handleInputValueChange = (changeFn, name) => ({ target: { value } }) => {
    clearError(name);
    changeFn(value);
  };

  const handleSubmit = useDebouncedCallback(async (e) => {
    e.preventDefault();
    clear();

    const nameError = validate('name', firstName);
    const surnameError = validate('surname', lastName);
    const emailError = validate('email', email);
    const passwordError = passwordValidation
      .filter(extract('error'))
      .find(({ matcher }) => !matcher(password))?.error;
    const passwordConfirmError = password !== passwordConfirm && 'Password don\'t match';

    if ([nameError, surnameError, emailError, passwordError, passwordConfirmError].some(identity)) {
      setError('name', nameError);
      setError('surname', surnameError);
      setError('email', emailError);
      setError('password', passwordError);
      setError('passwordConfirm', passwordConfirmError);
      return;
    }

    try {
      const token = await executeRecaptcha();

      dispatch(register({
        name: firstName,
        surname: lastName,
        email,
        step: 'step-one',
        token,
        password,
        onSuccess: () => {
          // window.gtag?.('event', 'register_first_step', {
          //   event_category: 'registration',
          //   event_label: 'Registration: complete first step'
          // });
          window.dataLayer?.push?.({ event: 'register_first_step' });

          navigate(Paths.REGISTRATION_SECOND_STEP);
        },
        onError: (err) => {
          if (err?.response?.data?.message === "Captcha data doesn't exist" || err?.response?.data?.message === "Wrong captcha data" || err?.response?.data?.message === "Low client scoring") {
            notify('error', 'Error', 'Oops. Something went wrong. Please try again later')
          } else {
            handleRequestError(err);
          }
        },
      }));
    } catch (err) {
      notify('error', 'Error', 'Recaptcha failed');
    }
  }, 1000, { leading: true, trailing: false });

  const handleChangeReceiveOffers = (event) => {
    const checked = event.target.checked;
    setWantReceiveOffers(checked);
  }

  const handleClose = () => {
    successModal.close();
    setFirstName('');
    setLastName('');
    setPassword('');
    setPasswordConfirm('');
    setEmail('');
    setResendEnabled(true);
  };

  const handleResend = () => {
    dispatch(resendRegistrationEmail({
      email,
      onSuccess: () => {
        setResendEnabled(false);
      },
      onError: resendErrors.handleRequestError,
    }));
  };

  return (
    <Container>
      <FormContainer>
        <form className="registration" autoComplete="off" onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}>
          <input type="email" disabled style={{ display: 'none' }} />
          <input type="password" disabled style={{ display: 'none' }} />
          <Form>
            <Title style={{ marginBottom: 54 }}>Registration</Title>
            <Input
              value={firstName}
              data-testid="reg-in-fname"
              title="First name"
              placeholder=""
              error={errors['name']}
              onChange={handleInputValueChange(setFirstName, 'name')}
              blackLabel
              containerStyle={{ marginBottom: errors['name'] ? 52 : 34 }}
            />
            <Input
              value={lastName}
              title="Last name"
              data-testid="reg-in-lname"
              autoComplete="off"
              placeholder=""
              blackLabel
              error={errors['surname']}
              containerStyle={{ marginBottom: errors['surname'] ? 52 : 34 }}
              onChange={handleInputValueChange(setLastName, 'surname')}
            />
            <Input
              value={email}
              title="Email"
              htmlType="email"
              autoComplete="off"
              data-testid="reg-in-email"
              autoComplete="off"
              type="email"
              placeholder=""
              blackLabel
              error={errors['email']}
              containerStyle={{ marginBottom: errors['email'] ? 52 : 34 }}
              onChange={handleInputValueChange(setEmail, 'email')}
            />
            <Tooltip trigger={['click']} placement={'top'} visible={passwordValidationVisible} title={(
              <TooltipContainer>
                {passwordValidation.filter(extract('tooltip')).map(({ tooltip, matcher }) => {
                  const valid = matcher(password);

                  return (
                    <TooltipItem>
                      {valid ? (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.65 3.45C10.875 3.225 10.875 2.925 10.65 2.7C10.425 2.475 10.125 2.475 9.89997 2.7L4.12498 8.4L2.17498 6.45C1.94998 6.225 1.64998 6.225 1.42498 6.45C1.19998 6.675 1.19998 6.975 1.42498 7.2L3.67498 9.3C3.89998 9.525 4.27498 9.525 4.49998 9.3L10.65 3.45Z" fill="#909399"/>
                        </svg>
                      ) : (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="9.50039" cy="5.90005" r="1.1" fill="#909399"/>
                        </svg>
                      )}
                      <TooltipTitle valid={valid}>{tooltip}</TooltipTitle>
                    </TooltipItem>
                  );
                })}
              </TooltipContainer>
            )}>
              <Input
                value={password}
                title="Password"
                htmlType="password"
                autoComplete="new-password"
                autoFill="none"
                data-testid="reg-password"
                blackLabel
                onFocus={() => setPasswordValidationVisible(true)}
                onBlur={() => setPasswordValidationVisible(false)}
                type="password"
                onFocus={() => setPasswordValidationVisible(true)}
                onBlur={() => setPasswordValidationVisible(false)}
                error={errors['password']}
                containerStyle={{ marginBottom: errors['password'] ? 52 : 34 }}
                onChange={handleInputValueChange(setPassword, 'password')}
              />
            </Tooltip>
            <Input
              value={passwordConfirm}
              title="Confirm password"
              htmlType="password"
              autoComplete="off"
              blackLabel
              data-testid="reg-in-confirm-password"
              type="password"
              error={errors['passwordConfirm']}
              containerStyle={{ marginBottom: 28 }}
              onChange={handleInputValueChange(setPasswordConfirm, 'passwordConfirm')}
            />
            <Hint style={{ marginBottom: 30, flexDirection: 'column' }}>
              By clicking the button below you agree with
              <a
                tabIndex={-1}
                target="_blank"
                data-testid="reg-a-terms"
                href="https://retainly.app/wp-content/uploads/2022/07/terms-of-service.pdf"
                style={{fontSize: '16px', lineHeight: '140%', textAlign: 'center', color: '#4986FD', fontFamily: 'Rubik-Regular' }}
              >
                &nbsp;Terms and Conditions
              </a>
            </Hint>
            <Button data-testid="reg-submit" htmlType="submit">Register</Button>
            <LoginHint>
              Already have an account?<LoginLink to="/login" underline>Sign in</LoginLink>
            </LoginHint>
            <SuccessModal resendErrors={resendErrors} resendEnabled={resendEnabled} onResend={handleResend} email={email} opened={successModal.opened} onClose={handleClose} />
          </Form>
        </form>
      </FormContainer>
      <ImageContainer>
        <Image url={image} />
      </ImageContainer>
    </Container>
  );
}

export default WhiteRegistrationPage;


