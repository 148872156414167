import React from 'react';
import { ActionButton, Icon, TableActionsBar } from '@components';
import { Container } from './styled';
import { useTranslation } from '@hooks';

const SmsActions = ({ onSmsInfoClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TableActionsBar
        actionButtons={(
          <ActionButton testId="sms-action-show-info" tooltip={t('labels.show_info')} icon={'Info-icon'} iconSize={14} onClick={onSmsInfoClick} />
        )}
      />
    </Container>
  )
}

export default SmsActions;
