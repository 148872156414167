import React from 'react';

import { Modal } from '@components';
import { useTranslation } from '@hooks';
import { Container, Key, KeyBackground, KeyContainer, Row, Value } from "./styled";
import { FACEBOOK_FIELDS } from "@components/lib/FieldsMappingEditor/FieldsMappingEditor";
import { useSelector } from "react-redux";
import { fieldsSettingsListSelector } from "@store/selectors";

const ViewMappingModal = ({ opened, onClose, mapping = {} }) => {
  const { t, p } = useTranslation('notification_history')
  const fieldSettings = useSelector(fieldsSettingsListSelector);

  const fieldOptions = (fieldSettings?.data || []).filter(({ type }) => type !== 'array').map(({ field, label }) => ({ label, value: field }));

  return (
    <Modal
      appearance="default"
      title={p('mapping')}
      contentStyles={{ padding: '20px', paddingTop: '14px', maxHeight: '60vh' }}
      opened={opened}
      onClose={onClose}
    >
      <Container>
        <Row first>
          <KeyContainer>
            <Key first>{p('form_fields')}</Key>
          </KeyContainer>
          <Value>{p('your_data')}</Value>
        </Row>
        {Object.keys(mapping).map(key => (
          <Row>
            <KeyContainer>
              <KeyBackground><Key>{FACEBOOK_FIELDS.find(ff => ff.value === key)?.label || key}</Key></KeyBackground>
            </KeyContainer>
            <Value>{fieldOptions?.find(fo => fo.value === mapping[key])?.label || mapping[key]}</Value>
          </Row>
        ))}
      </Container>
    </Modal>
  );
};

export default ViewMappingModal;
