import React, {useMemo, useState} from 'react';
import { EmailTemplatesRow, TemplatesHeader, TemplatesInner } from "../../styled";
import {
  Button,
  Link,
  WithPermissions,
  WebpushStatisticsModal,
  LargeTemplateItem,
} from "@components";
import {Paths, UserPermissions} from "@constants";
import {archiveWebpushTemplate, getSingleWebpushTemplate, getWebpushTemplates} from "@store/actions/creators";
import {useDispatch, useSelector} from "react-redux";
import {templateWebpushSelector} from "@store/selectors";
import {Container} from "./styled";
import {notify} from "@utils";
import {useModalState, useTranslation} from "@hooks";
import { useNavigate } from "react-router";

const WebpushSection = () => {
  const { p, t } = useTranslation('dashboard_page');
  const webpushes = useSelector(templateWebpushSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statisticWebpushId, setWebpushStatisticsId] = useState(null);
  const statisticsWebpushModal = useModalState();

  const shouldRender = useMemo(() => {
    return !!webpushes.data?.filter(t => t.status !== 3)?.length;
  }, [webpushes.data])

  const handleViewWebpush = (id) => {
    navigate(`${Paths.TEMPLATES_WEBPUSH}/${id}?view=1`);
  };

  const handleEditWebpush = (id, used) => {
    if (used === 1){
      return;
    }
    navigate(`${Paths.TEMPLATES_WEBPUSH}/${id}`);
  };

  const handleDuplicateWebpush = (id) => {
    dispatch(getSingleWebpushTemplate({ id }))
    navigate(`${Paths.TEMPLATES_WEBPUSH}/duplicate`);
  };

  const handleCreateWebpush = () => {
    navigate(`${Paths.TEMPLATES_WEBPUSH}/new`);
  };

  const handleWebpushStatisticsClick = (id) => {
    setWebpushStatisticsId(id);
    statisticsWebpushModal.open();
  };

  const handleWebpushArchive = (id, name) => {
    dispatch(archiveWebpushTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.template_archived'), name);
        dispatch(getWebpushTemplates({ page: 1, perPage: 6, all: true }));
      }
    }))
  }

  return (
    <Container data-testid="dashboard-templates-section-webpush">
      {shouldRender ? (
        <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!webpushes?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
          <TemplatesHeader>
            <Link to={`${Paths.TEMPLATES_WEBPUSHES}/default`} icon>{p('web_push')}</Link>
            <WithPermissions name={UserPermissions.CREATE_WEBPUSHES}>
              <Button
                style={{
                  padding: '6px 14px',
                  height: '26px',
                  fontSize: '12px',
                  lineHeight: '14px'
                }}
                testId="dashboard-templates-section-webpush-create"
                width="none"
                onClick={handleCreateWebpush}
              >
                {t('actions.create')}
              </Button>
            </WithPermissions>
          </TemplatesHeader>
          <EmailTemplatesRow>
            {webpushes.data?.filter(t => t.status !== 3)?.slice(0, 3).map(({ id, name, used, created_at, workers, body, status, image }, i, arr) => (
              <LargeTemplateItem
                key={id}
                used={used}
                type="webpush"
                title={name}
                testId={`webpush-templates-item-${i}`}
                status={status}
                body={body}
                image_url={image.url}
                fromDashboard
                onDeleteClick={() => {}}
                onStatisticsClick={() => handleWebpushStatisticsClick(id)}
                onArchive={() => handleWebpushArchive(id, name)}
                small
                style={{ ...(i === (arr.length - 1) ? { marginRight: '0px' } : {})}}
                workflows={workers}
                date={created_at}
                onViewClick={() => handleViewWebpush(id)}
                onEditClick={() => handleEditWebpush(id)}
                onDuplicateClick={() => handleDuplicateWebpush(id)}
              />
            ))}
          </EmailTemplatesRow>
        </TemplatesInner>
      ) : null}
      <WebpushStatisticsModal id={statisticWebpushId} opened={statisticsWebpushModal.opened} onClose={statisticsWebpushModal.close} />
    </Container>
  )
}

export default React.memo(WebpushSection);
