import React, { useEffect } from 'react';

import { Logo } from '../QuestionnairePage/components';
import { Container, Title, LogoContainer, PrizeText, Description, InnerContainer } from './styled.js';
import { PartyIcon } from './components';
import { Button } from '../WhiteRegistrationPage/components';
import { Paths } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '@store/selectors';
import { by } from '@utils';
import { getUser } from '@store/actions/creators';
import { useNavigate } from '@hooks';

const QuestionnaireSuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const secondPassed = user?.questionnaires?.find(by('name', 'trial_second'))?.passed;

  useEffect(() => {
    dispatch(getUser());
  }, [])

  const handleSubmit = () => {
    navigate(!secondPassed ? Paths.DASHBOARD : Paths.ACCOUNT_SETTINGS_BILLING);
  };

  return (
    <Container>
      <svg style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grid" width="50" height="50" patternUnits="userSpaceOnUse">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="49.5" y1="2.18556e-08" x2="49.5" y2="50" stroke="#28282D"/>
              <line x1="8.74228e-08" y1="49.5" x2="50" y2="49.5" stroke="#28282D"/>
            </svg>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <InnerContainer>
        <div>
          <PartyIcon />
        </div>
        <Title>
          Thank you so much for the feedback!
        </Title>
        <Description>
          Everyday we work to be better for you, now you can get your gift:
        </Description>
        <PrizeText>
          🎁&nbsp;&nbsp;{secondPassed ? '10% discount for 6 months' : 'Free access by the end of trial period'}
        </PrizeText>
        <Button onClick={handleSubmit}>Get my gifts</Button>
      </InnerContainer>
    </Container>
  );
}

export default QuestionnaireSuccessPage;


