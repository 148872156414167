import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../Text';

export const Container = styled(Text)`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: #109DFB;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $underline }) => $underline ? `
    text-decoration: underline;
  ` : ''}
`;
