import React from 'react';
import { Paths } from '@constants';
import { stringTemplate, moment, by, compose } from '@utils';
import { SystemNotificationBody } from '../SystemNotificationBody';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInSelector, workspacesListSelector } from '@store/selectors';
import { getUser, switchWorkspaces } from '@store/actions/creators';

import { BodyContainer, Container, Link, StaticPoint, Title, Time, Image, Row } from './styled.js';
import { useTranslation, useNavigate } from '@hooks';

const createLink = (type, entity, navigate, programType) => {
  switch (type) {
    case 'workflow':
      return programType?.indexOf('campaign_') === -1 ? <Link key={entity.id} onClick={() => navigate(`${Paths.EDIT_WORKFLOW}/${entity.id}/editor`)}>{entity.name}</Link> : <Link onClick={() => navigate(`${Paths.CAMPAIGNS}/${entity.id}/analytic`)}>{entity.name}</Link>;
    case 'mysql':
      return <Link key={entity.id} onClick={() => navigate(`${Paths.INTEGRATIONS}/mysql/${entity.id}?t=logs`)}>{entity.name}</Link>;
    case 'postgresql':
      return <Link key={entity.id} onClick={() => navigate(`${Paths.INTEGRATIONS}/postgresql/${entity.id}?t=logs`)}>{entity.name}</Link>;
    case 'facebook_integration':
      return <Link key={entity.id} onClick={() => navigate(`${Paths.INTEGRATIONS}/facebook_ads/${entity.id}`)}>{entity.name}</Link>;
    case 'subscribe_group':
      return <Link key={entity.id} onClick={() => navigate(`${Paths.CONTACTS_ALL}/unsubscribe-groups/${entity.id}`)}>{entity.name}</Link>;
    case 'rfm_calculation':
      return <Link key={entity.id} onClick={() => navigate(`${Paths.RFM_ANALYTICS}/${entity.id}`)}>{entity.name}</Link>;
    case 'plain_link':
      return <Link key={entity.id} onClick={() => window.open(entity.id)}>{entity.id.split('/').pop()}</Link>;
    // case 'app':
    //   return <Link onClick={() => navigate(`${Routes.INTEGRATIONS}/postgresql/${entity.id}`)}>{entity.name}</Link>;
    default:
      return entity.name;
  }
}

const templateLinks = (str, vars, navigate, programType) => {
  return compose(...vars.map(({ type, ...entity }) => input => stringTemplate({ [`{{${type}}}`]: createLink(type, entity, navigate, programType) }, input)))(str);
};

export const translateNotification = (data, translate, navigate) => {
  const title = templateLinks(translate(`${data.type}.title`), data.variables, navigate, data.type);
  const body = templateLinks(translate(`${data.type}.body`), data.variables, navigate);

  return { title, body };
}

const NotificationItem = ({ isLast, date, readNotification, item, onClose, tid, }) => {
  const user = useSelector(loggedInSelector);
  const workspaces = useSelector(workspacesListSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, p } = useTranslation('system_notifications');

  if (!item.data.type) {
    return null;
  }

  const handleNavigate = (path) => {
    if (user?.app?.id === item.data.app.app_id) {
      onClose();
      navigate(path);
    } else {
      dispatch(switchWorkspaces({
        id: item.data.app.app_id,
        onSuccess: () => {
          dispatch(getUser());
          onClose();
          navigate(path);
        }
      }))
    }
  }

  const { body, title } = translateNotification(item.data, p, handleNavigate);

  const workspace = workspaces?.find?.(by(item.data?.app?.app_id));

  return (
    <Container
      {...tid}
      $read={!(item.read_at === null || item.read_at === undefined)}
      $error={item.data.status !== 'success'}
      isLast={isLast}
      onClick={() => readNotification(item)}
    >
      {/*<Title error={status !== 'success'}>{title}</Title>*/}
      <Row>
        <Image {...tid('image')} $url={workspace?.link || 'https://cdn.retainly.app/retainly_workspace_notification_placeholder.png'} />
        <BodyContainer>
          <div>
            <Title {...tid('title')}>{title}</Title>
            <SystemNotificationBody body={body} tid={tid} />
          </div>
        </BodyContainer>
      </Row>
      <Time {...tid('time')}>{moment(date).format('HH:mm')}</Time>
      {(item.read_at === null || item.read_at === undefined) && <StaticPoint {...tid('unread-indicator')} />}
    </Container>
  );
}

export default NotificationItem;




