import styled, { css } from 'styled-components';
import { extract, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${extract('size')}px;
  height: ${extract('size')}px;
  border-radius: 7px;
  cursor: pointer;
  transition: 230ms all ease;
  font-size: 12px;
  line-height: 14px;
  
  ${styledProp('danger', css`
    background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%) !important;
    background-color: transparent !important;
  `)}

  ${styledProp('disabled', css`
    ${styledProp('danger', css`
      background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%) !important;
      background-color: transparent !important;
      opacity: 0.5;
    `, css`
      opacity: 0.5;
    `)}
  `)}

  ${styledProp('hide', css`
    opacity: 0 !important;
    cursor: default;
    pointer-events: none;
  `)}

  background-color: ${({ appearance }) => {
    switch (appearance) {
      case 'danger':
        return '#EFD5D1';
      case 'darker':
        return '#DCDFE6';
      default:
        return '#F0F2F6';
    }
  }};
  
  &:hover {
    background-color: ${({ appearance }) => {
      switch (appearance) {
        case 'danger':
          return '#ECC3BD';
        default:
          return '#D7D9DC';
      }
    }};
  }
  
  color: ${({ appearance }) => {
    switch (appearance) {
      case 'danger':
        return '#C8402F';
      default:
        return '#303133';
    }
  }};
`;
