import React, { useEffect, useState } from 'react';
import { Container } from './styled';
import logo from '@res/images/logo.svg';
import { Button, Input, Link } from '@components';
import { Caption, Image, InnerContainer } from './styled';
import { resetPassword } from '@store/actions/creators';
import { useTimer } from 'react-timer-hook';
import {useErrors, useTranslation} from "@hooks";
import {useDispatch} from "react-redux";
import { moment, notify } from '@utils';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [done, setDone] = useState(false);
  const { t, p } = useTranslation('login_page');
  const errors = useErrors([], [429]);
  const dispatch = useDispatch();
  const {
    seconds,
    minutes,
    isRunning,
    start,
    restart,
  } = useTimer({ expiryTimestamp: +localStorage.getItem('password_reset_time') });
  const time = +localStorage.getItem('password_reset_time');

  useEffect(() => {
    if (time && moment(time).diff(moment(), 'seconds') > 0) {
      start();
    }
  }, []);

  const handleChange = (onChangeFn) => ({ target }) => {
    errors.clear();
    onChangeFn(target.value);
  };

  const handleSubmit = () => {
    if (moment(time).diff(moment(), 'seconds') > 0) {
      return notify('error', 'Error', 'Too many attempts, please try again later');
    }

    dispatch(
        resetPassword({
            email,
            type: 'retainly',
            onSuccess: (res) => {
                setDone(true);
            },
            onError: errors.handleRequestError,
        })
    );
  };

  const handleSendAgain = () => {
    dispatch(
      resetPassword({
        email,
        type: 'retainly',
        onSuccess: (res) => {
          localStorage.setItem('password_reset_time', +moment().add(90, 'seconds'));
          restart(+moment().add(90, 'seconds'), true);
        },
        onError: errors.handleRequestError,
      })
    );
  };

  return (
    <Container>
      <Image src={logo} alt="logo" />
      <InnerContainer>
        <h2>Reset Password</h2>
        {!done ? (
          <>
            <h4>Please, enter your email address below to receive a password reset link</h4>
            <Input
                style={{ width: '258px' }}
                title={t('labels.email')}
                error={errors.errors['email']}
                type="email"
                onChange={handleChange(setEmail)}
            />
            <Button onClick={handleSubmit}>Send</Button>
          </>
        ) : (
          <>
            <h5>We've just sent a letter to <br /><b>{email}</b> for password reset.</h5>
            <Caption>Didn't receive a letter?&nbsp;{isRunning && moment(time).diff(moment(), 'seconds') > 0 ? (
              <>Send again in&nbsp;<span>{minutes}:{seconds}</span></>
            ) : (
              <Link to="#" underline onClick={handleSendAgain}>Send Again</Link>
            )}</Caption>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default ResetPasswordPage;
