import React from 'react';
import { Input, RichTemplateEditor } from '@components';

import { Container } from './styled';
import useTemplateEntities from '@hooks/useTemplateEntities';

const InputWrapper = (props) => {
  return (
    <Container>
      <RichTemplateEditor {...props} style={{ padding: 0, marginTop: 4 }} />
    </Container>
  );
}

const RichTemplateEditorInput = (props) => {
  const entities = useTemplateEntities();

  return (
    <Input
      Component={InputWrapper}
      {...props}
      entities={entities}
    />
  );
};

export default RichTemplateEditorInput;
