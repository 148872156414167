import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const LinkItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #dcdfe6;
`;

export const Link = styled.p`
  width: 60%;
  margin: 0;
`;

export const Status = styled.p`
  width: 25%;
  padding: 0 15px;
  color: ${({ isSuccess }) => (isSuccess ? '#24993E' : '#C8402F')};
  font-size: 13px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 15%;
  padding: 0 15px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
`;

export const HeaderItem = styled.p`
  width: ${({ width }) => `${width}%`};
  color: #909399; 
  text-align: ${({ alignEnd }) => (alignEnd ? 'right' : 'left')};
  margin: 0;
  padding: ${({ withPadding }) => (withPadding ? '0 15px' : '0')};
`;
