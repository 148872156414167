import React, { useCallback, useMemo, useEffect, useRef, useImperativeHandle } from 'react';
import { createEditor, Editor, Transforms } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { Container } from './styled';
import { EntityBlock } from './components';
import usePrevious from 'use-previous';
import { extract } from '@utils';

const emptyValue = [{
  type: 'paragraph',
  attributes: { style: { display: 'flex' } },
  children: [
    { text: '' },
  ],
}];

const parseRowToSlateElements = (value = '', entities) => {
  let children = [];

  const entityNames = entities.map(({ name }) => name);
  const regex = new RegExp(`(${entityNames.join('|')})`, 'g');

  let lastIndex = 0;

  value.replace(regex, (matched, ...args) => {
    const start = args[args.length - 2];
    const end = start + matched.length;

    if (lastIndex < start) {
      children.push({ text: value.slice(lastIndex, start) });
    }

    const entity = entities.find(({ name }) => name === matched);

    if (entity) {
      const { name, label, kind } = entity;

      children.push({
        type: 'entity',
        children: [{ text: '' }],
        name,
        label,
        kind,
      });
    }

    lastIndex = end;
  });

  if (lastIndex < value.length) {
    children.push({ text: value.slice(lastIndex) });
  }

  return { type: 'paragraph', children };
};



const parseToSlateElements = (value = '', entities) => {
  return (value || '').split('\n').map(v => parseRowToSlateElements(v, entities));
};

const parseFromSlateElements = (state) => {
  return state
    .filter(({ type }) => type === 'paragraph')
    .map(extract('children'))
    .map(children => {
      return children.map(child => {
        if (child.type === 'entity') {
          return String(child.name).trim();
        } else if (typeof child.text === 'string') {
          return String(child.text).trim();
        }
        return '';
      }).join(' ')
    }).join('\n');
};


const withEntities = editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return element.type === 'entity' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'entity' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'entity' || markableVoid(element)
  }

  return editor
}

const RichTemplateEditor = ({ initialValue, richRef, fieldKey = '', onChange, entities, style, ...props }) => {
  const editor = useMemo(() => withEntities(withReact(createEditor())), []);
  const firstRender = useRef(null);
  const prevInitialValue = usePrevious(initialValue);

  useImperativeHandle(richRef, () => ({
    addEmoji(emoji) {
      Transforms.insertText(emoji);
    },
  }));

  const renderElement = useCallback(props => {
    switch (props.element.type) {
      case 'entity':
        return <EntityBlock {...props} />;
      default:
        return <p className={`slate-input-${fieldKey}`} {...props.attributes}>{props.children}</p>;
    }
  }, [entities]);

  useEffect(() => {
    if (!entities) {
      return;
    }

    const parsed = parseToSlateElements(initialValue, entities);

    if (!parsed || initialValue === prevInitialValue) {
      return;
    }

    if (!parsed.length) {
      return;
    }

    Transforms.deselect(editor);
    let error, i = 0;

    while (!error && i < 200) {
      try {
        editor.removeNodes(editor, { at: Editor.start(editor, []), mode: 'highest' });
        Transforms.removeNodes(editor, { at: Editor.start(editor, []), mode: 'highest' });
        i++;
      } catch (e) {
        console.log(e);
        error = e;
      }
    }

    Transforms.insertNodes(editor, parsed, { at: [0] });
  }, [prevInitialValue, initialValue, entities?.map?.(extract('name'))?.join?.()]);

  const generateOperations = (newEditorState, entities) => {
    const operations = [];
    const shouldAddOperation = newEditorState.some(node => node.children.some(child => entities.some(entity => new RegExp(`${entity.name}`, 'g').test(child.text))));

    newEditorState.forEach((p, at) => {
      if (p.type === 'paragraph') {
        let newParagraphChildren = [];
        let bufferText = '';

        for (const block of p.children) {
          if (block.type === 'entity') {
            if (bufferText) {
              newParagraphChildren.push({ text: bufferText });
              bufferText = '';
            }
            newParagraphChildren.push(block);
            continue;
          }


          bufferText += block.text;

          for (const entity of entities) {
            const regex = new RegExp(`${entity.name}`, 'g');
            let match;

            while ((match = regex.exec(bufferText)) !== null) {
              if (match.index > 0) {
                newParagraphChildren.push({
                  text: bufferText.slice(0, match.index),
                });
              }

              newParagraphChildren.push({
                type: 'entity',
                children: [{ text: '' }],
                name: entity.name,
                label: entity.label,
                kind: entity.kind,
              });

              bufferText = bufferText.slice(match.index + match[0].length);
            }
          }
        }

        if (bufferText) {
          newParagraphChildren.push({ text: bufferText });
        }

        if (shouldAddOperation) {
          operations.push({
            type: 'insert_node',
            at,
            node: {
              type: 'paragraph',
              children: newParagraphChildren,
            },
          });
        }
      }
    });

    return operations;
  };


  const handleEditorChange = (newEditorState) => {
    // editor.addMark('G')
    // prevent first render onChange cuz it breaks create template page logic and state
    if (!firstRender.current) {
      firstRender.current = true;
      return;
    }

    const operations = generateOperations(newEditorState, entities);

    if (operations.length) {
      if (editor.children && editor.children.length > 0) {
        Transforms.deselect(editor);
        let error;
        let i = 0;

        while (!error && i < 200) {
          try {
            Transforms.removeNodes(editor, { at: Editor.start(editor, []), mode: 'highest' });
            i++;
          } catch (e) {
            error = e;
          }
        }
      }

      applyCustomOperations(editor, operations);
    }

    const stringValue = parseFromSlateElements(newEditorState);
    onChange(stringValue);
  };

  const applyCustomOperations = (editor, operations) => {
    if (!Editor) {
      return;
    }

    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, operations.filter(({ type }) => type === "insert_node").map(extract('node')));
    });
  };

  return (
    <Container {...props}>
      <Slate initialValue={emptyValue} editor={editor} onChange={handleEditorChange}>
        <Editable
          renderElement={renderElement}
          onChange={(v) => console.log('kekw', v)}
          className={`rte-editable`}
          style={{ display: 'flex', flex: 1, width: '100%', minWidth: 10, outline: 'none', ...(style || {}) }}
          placeholder="Start typing"
        />
      </Slate>
    </Container>
  );
};


export default RichTemplateEditor;
