import React from 'react';

import { Container, Percentage, Row, Title, Value } from './styled.js';
import { formatNumberString, zeroIfNaN } from '@utils';
import uniq from 'lodash.uniq';
import {
  EmailStatuses,
  MobilePushStatuses,
  SmsStatuses,
  ViberStatuses,
  WebhookStatuses,
  WebpushStatuses
} from '@constants';
import capitalize from 'lodash.capitalize';
import { useTranslation } from '@hooks';

const SendingSection = ({ statuses, out }) => {
  const { t } = useTranslation();
  const data = (statuses || []).reduce((acc, { status, count }) => ({ ...acc, [status]: count }), {});

  const getPercentage = (v, out) => {
    if (!out) {
      return 0;
    }

    return zeroIfNaN(v / out * 100).toFixed(2);
  };

  const allDefaultStatuses = uniq([
    EmailStatuses,
    SmsStatuses,
    MobilePushStatuses,
    WebpushStatuses,
    WebhookStatuses,
    ViberStatuses
  ].map(s => Object.values(s)));

  return (
    <Container>
      {Object.entries(data || {}).map(([k, v]) => (
        <Row>
          <Title>{!!~allDefaultStatuses.indexOf(k) ? t(`statuses.${k}`) : capitalize(k)}: </Title>
          <Percentage>{getPercentage(v, out)}%</Percentage>
          <Value>({formatNumberString(v)})</Value>
        </Row>
      ))}
    </Container>
  );
}

export default SendingSection;


