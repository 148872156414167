import React, { useEffect, useState } from 'react';

import { Modal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { emailSampleDataSelector } from '@store/selectors';
import { getTemplatePreviewInfo } from '@store/actions/creators';
import { useModalState, useTranslation } from '@hooks';
import { IOSWebPushPreview } from '@components/lib/WebPushPreview/components';

const ViewMobilePushTemplateModal = ({ opened, onClose, data }) => {
  const [body, setBody] = useState(data?.body);
  const [title, setTitle] = useState(data?.title);
  const dispatch = useDispatch();
  const previewState = useModalState(true);
  const { id: sampleContactId } = useSelector(emailSampleDataSelector);
  const { t, p } = useTranslation('notification_history')

  useEffect(() => {
    setBody(data?.message);
    setTitle(data?.title);
  }, [data]);

  useEffect(() => {
    if (opened) {
      const fields = {};

      if (data?.message) {
        fields.message = data.message;
      }
      if (data?.title) {
        fields.title = data.title;
      }

      dispatch(getTemplatePreviewInfo({
        fields,
        customer_id: data?.customerId || sampleContactId,
        onSuccess: (d) => {
          setTitle(d.templates.title);
          setBody(d.templates.message)
        },
      }))
    }
  }, [opened]);

  return (
    <Modal
      appearance="default"
      title={p('preview', { name: data.name })}
      contentStyles={{ padding: '20px', paddingTop: '14px', maxHeight: '60vh' }}
      opened={opened}
      onClose={onClose}
    >
      <div style={{ position: 'relative', maxHeight: '46vh', minHeight: '45vh', display: 'flex', justifyContent: 'center' }}>
        <IOSWebPushPreview
          style={{
            width: '60%',
            position: 'inherit',
            margin: 'auto',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
          }}
          mobilePush
          preview
          title={title}
          text={body}
          icon={data.image}
          subtitle={data.subtitle}
          image={data.image}
          company={p('chrome')}
          buttons={data.actions || []}
          onToggle={previewState.toggle}
          opened={previewState.opened}
        />
      </div>
    </Modal>
  );
};

export default ViewMobilePushTemplateModal;
