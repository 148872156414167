import React, {useContext} from 'react';

import { Container, LeftBackground, RightBackground, NodeThumbnail, Title, CountContainer, Count } from './styled';
import {Handle, isEdge, isNode} from 'reactflow';
import { WorkflowNodeTypes } from '@constants';
import { getLength } from '@utils/workflows/refactored/getters';
import {by, testId} from '@utils';
import { useTranslation } from '@hooks';
import WorkflowEditorContext from "../../../../../contexts/WorkflowEditorContext/WorkflowEditorContext";

const NodeArray = ({ type, id, data, selected, sourcePosition, targetPosition, isConnectable }) => {
  const { p } = useTranslation('workflow_page');
  const editor = useContext(WorkflowEditorContext);

  return (
    <Container selected={selected} {...testId(`workflow-node-${(editor.renderNodes || []).filter(isNode).findIndex(by('id', data.id))}`)()}>
      <NodeThumbnail>
        <LeftBackground />
        <RightBackground />
        {data.type !== 'entry_point' && (
          <Handle
            {...testId(`workflow-node-${data.title}-handle-left`)()}
            type="target"
            position="left"
            id="a"
            isConnectable
            style={{ top: '50%', background: '#A9A7A7' }}
          />
        )}
        <Handle
          {...testId(`workflow-node-${data.title}-handle-right`)()}
          type="source"
          position="right"
          id="b"
          isConnectable
          style={{ top: '50%', background: '#A9A7A7' }}
        />
        <CountContainer>
          <Count>{getLength(data.nodes)}</Count>
        </CountContainer>
      </NodeThumbnail>
      <Title>{p('node_array')}</Title>
    </Container>
  );
};

export default NodeArray;
